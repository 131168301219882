import { createAction, createSlice } from '@reduxjs/toolkit';
import { updateFeatures } from '../../settings/data/widgetSettings-slice';
import { triggerResetAction } from '../../../../redux/shared-actions';
import { CHATBOT_CONNECT_SCHEDULING_SECTION, CHATBOT_CONNECT_SCHEDULING_KEY } from '../chatbot-constants';
import generalSettingsSlice from './general-settings-slice';
import combineSlices from '../../../../redux/combine-slices';
import {
  loadSettingsDataSuccess,
} from '../../../../shared/app-slice';
import { getProductFromProducts } from '../../../../shared/app-utils';
import {
  FREE_TIER,
  CHATBOT_SLUG,
  SCHEDULER_SLUG,
} from '../../../../shared/app-constants';
import { loadedChatbotSettingsData } from './chatbot-actions';
export const saveChatbotAction = createAction('chatbot/save');

const initialState = {
  tier: FREE_TIER,
  dirtySections: [],
  isDirty: false,
  invalidSections: [],
  isInvalid: false,
  hasReset: false,
  resetProductInfo: {},
  currentUserEmail: null,
  businessData: {},
  hasData: false,
  firstActivationDate: null,
  toggle: {
    isActive: false,
    isActivating: false,
    hasError: false,
  },
  showWarningModal: false,
  showChangesSaved: false,
  isSaving: false,
  scheduler: {
    isActive: false,
    tier: FREE_TIER,
  }
};

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySections.indexOf(dirtyForm) === -1) {
        state.dirtySections.push(dirtyForm);
        state.isDirty = true;
      }

      // If this form is now dirty, it can't be invalid anymore
      state.invalidSections = state.invalidSections.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;
      state.dirtySections = state.dirtySections.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySections.length > 0;

      state.invalidSections = state.invalidSections.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSections.indexOf(invalidForm) === -1) {
        state.invalidSections.push(invalidForm);
        state.isInvalid = true;
      }
    },
    save: (state) => {
      state.isSaving = true;
    },
    toggle: (state, action) => {
      state.toggle.isActivating = true;
    },
    saveChatbotSuccess: (state, action) => {
      // Clear out the isDirty state
      state.isSaving = false;
      state.showChangesSaved = true;
      state.isDirty = false;
      state.dirtySections = [];

      // Shouldn't have been able to make it here in this state but just in case
      state.isInvalid = false;
      state.invalidSections = [];

      // Update the reset cache
      state.resetProductInfo = Object.assign(state.resetProductInfo, action.payload.data);
      const linkSchedulerSection = action.payload.data[CHATBOT_CONNECT_SCHEDULING_SECTION];

      if (linkSchedulerSection) {
        state.resetProductInfo[CHATBOT_CONNECT_SCHEDULING_KEY] = linkSchedulerSection[CHATBOT_CONNECT_SCHEDULING_KEY];
      }
    },
    saveChatbotFailure: (state, _action) => {
      state.isSaving = false;
    },
    closeChangesSaved: (state) => {
      state.showChangesSaved = false;
    },
    cancelSave: (state) => {
      state.hasReset = false;
      state.showWarningModal = state.isDirty;
    },
    closeWarningModal: (state, action) => {
      state.showWarningModal = false;
      if (action.payload.forceClose) {
        state.isDirty = false;
        state.dirtySections = [];
      }
    },
    resetCompleteAction: (state) => {
      state.hasReset = true;
    },
    setAfterWizardComplete: (state, action) => {
      state.toggle.isActive = action.payload.isActive;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(triggerResetAction, (state) => {
      state.hasReset = false;
    });
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const { business, products, user } = action.payload;
      const schedulerOrder = getProductFromProducts(products, SCHEDULER_SLUG);

      if (business) {
        state.businessData = business;
      }

      if (schedulerOrder) {
        state.scheduler.isActive = schedulerOrder.active;
        state.scheduler.tier = schedulerOrder.productType.tier;
      }

      if (user) {
        state.currentUserEmail = user.email;
      }
    });
    builder.addCase(loadedChatbotSettingsData, (state, action) => {
      const { productInfo, active, productType, firstActivationDate } = action.payload;

      if (productInfo) {
        const copy = { ...productInfo };
        state.resetProductInfo = copy;
        state.toggle.isActive = active;
      }
      if (productType) {
        state.tier = productType.tier;
      }
      state.hasData = true;
      state.firstActivationDate = firstActivationDate;
    });
    builder.addCase(updateFeatures, (state, action) => {
      if (action.payload.formName === CHATBOT_SLUG) {
        state.toggle.isActive = action.payload.isSelected;
      }
    });
  },
});

export const {
  updateChatFieldAction,
  setStateClean,
  setStateDirty,
  setStateInvalid,
  save,
  saveChatbotSuccess,
  saveChatbotFailure,
  resetCompleteAction,
  closeWarningModal,
  cancelSave,
  closeChangesSaved,
  setAfterWizardComplete,
} = chatbotSlice.actions;
export default combineSlices(
  chatbotSlice.reducer,
  initialState,
  {
    generalSettings: generalSettingsSlice,
  }
);
