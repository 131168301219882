import { createSlice } from '@reduxjs/toolkit';
import { closeDetailsAction } from './admin-shared-actions';

const initialState = {
  isLoading: false,
  appointments: null,
  page: 0,
  resultTotal: 0,
};

const adminAppointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    retrieveAppointments: (state, action) => {
      state.isLoading = true;
      state.appointments = null;
      state.page = action.payload.page;
    },
    retrieveAppointmentsSuccess: (state, action) => {
      const { content: appointments, totalElements } = action.payload;
      if (Array.isArray(appointments) && appointments.length > 0) {
        state.appointments = appointments;
      }
      state.resultTotal = totalElements;
      state.isLoading = false;
    },
    retrieveAppointmentsFailure: (state) => {
      state.isLoading = false;
      state.appointments = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeDetailsAction, (state) => {
      state.appointments = null;
      state.page = 0;
      state.resultTotal = 0;
    });
  },
});

export const {
  retrieveAppointments,
  retrieveAppointmentsSuccess,
  retrieveAppointmentsFailure,
} = adminAppointmentsSlice.actions;
export default adminAppointmentsSlice;
