import { ajax } from 'rxjs/ajax';
import { saveNotificationsAction, saveNotificationsFailure, saveNotificationsSuccess } from './notifications-slice';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { resetCompleteAction, saveEngagementWidgetAction } from './widgetSettings-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const constructNotificationsBody = (notificationsStateObj) => {
  const { smsNotifications, notificationsSettings, customWelcomeMessage } = notificationsStateObj;
  const { notificationEmails, phoneNumberSMS } = notificationsSettings;

  return {
    leadNotifications: notificationEmails.map((ne) => ne.email),
    phoneNumberSMS: phoneNumberSMS.map((pN) => pN.phone),
    ...smsNotifications,
    customWelcomeMessage,
  }
};

const saveNotificationSettingsEpic = (action$, state$) => action$.pipe(
  filter(saveNotificationsAction.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}def-business/attributes`,
      method: 'PUT',
      crossDomain: true,
      withCredentials: true,
      body: constructNotificationsBody(state$.value.settings.notifications)
    }
  )
  .pipe(
    map(response => saveNotificationsSuccess(response.response)),
    catchError((error) => of(saveNotificationsFailure(error)))
  ))
);

const saveSuccessEpic = (action$, state$) => action$.pipe(
  filter(saveNotificationsSuccess.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.settings.resetProductInfo,
    })
  )),
);

const constructWidgetBody = (stateObj) => ({
  theme: stateObj.settings.engagementWidget.widgetColor,
  activation: stateObj.settings.engagementWidget.widgetFeatures,
  widgetLocation: stateObj.settings.engagementWidget.widgetLocation,
});

const saveColorSettingsEpic = (action$, state$) => action$.pipe(
  filter(saveEngagementWidgetAction.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}def-business/attributes`,
      method: 'PUT',
      crossDomain: true,
      withCredentials: true,
      body: constructWidgetBody(state$.value),
    }
  ).pipe(
    map(response => saveNotificationsSuccess(response.response)),
    catchError((error) => of(saveNotificationsFailure(error)))
  ))
);

export default [
  saveNotificationSettingsEpic,
  saveSuccessEpic,
  saveColorSettingsEpic
];