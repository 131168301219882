import { createSlice } from '@reduxjs/toolkit';
import { WIDGET_FEATURE_KEY } from '../wizard/wizard-constants';
import { loadWizardConfigSuccess } from './configuration-slice';
import { CALL_TRACKER_FEATURE, CHAT_FEATURE, CONTACT_FORM_FEATURE, SCHEDULER_FEATURE, STEP_STATUS } from './slice-constants';

const widgetFeaturesSlice = createSlice({
  name: 'widget_features',
  initialState: {
    [CALL_TRACKER_FEATURE]: true,
    [CHAT_FEATURE]: true,
    [SCHEDULER_FEATURE]: true,
    [CONTACT_FORM_FEATURE]: true,
    [STEP_STATUS]: '',
  },
  reducers: {
    saveSchedulerFeature: (state, action) => {
      const { payload } = action;
      state[SCHEDULER_FEATURE] = payload;
    },
    saveChatbotFeature: (state, action) => {
      const { payload } = action;
      state[CHAT_FEATURE] = payload;
    },
    saveCallTrackerFeature: (state, action) => {
      const { payload } = action;
      state[CALL_TRACKER_FEATURE] = payload;
    },
    saveContactFormFeature: (state, action) => {
      const { payload } = action;
      state[CONTACT_FORM_FEATURE] = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWizardConfigSuccess, (state, action) => {
      if (action.payload.length > 0) {
        const { data } = action.payload[0];
        if (data[WIDGET_FEATURE_KEY]) {
          state[SCHEDULER_FEATURE] = data[WIDGET_FEATURE_KEY][SCHEDULER_FEATURE];
          state[CHAT_FEATURE] = data[WIDGET_FEATURE_KEY][CHAT_FEATURE];
          state[CALL_TRACKER_FEATURE] = data[WIDGET_FEATURE_KEY][CALL_TRACKER_FEATURE];
          state[CONTACT_FORM_FEATURE] = data[WIDGET_FEATURE_KEY][CONTACT_FORM_FEATURE];
        }
      }
    });
  },
});

export const {
  saveSchedulerFeature,
  saveChatbotFeature,
  saveCallTrackerFeature,
  saveContactFormFeature,
} = widgetFeaturesSlice.actions;
export default widgetFeaturesSlice.reducer;
