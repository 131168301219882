import { of } from 'rxjs';
import {
  catchError, filter, map, mergeMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { retrieveBusinessData, retrieveBusinessDataFailure, retrieveBusinessDataSuccess } from './admin-business-slice';
import { verifyAdminUser, verifyAdminUserFailure, verifyAdminUserSuccess } from './admin-slice';
import { retrieveChatTranscript, retrieveChatTranscriptFailure, retrieveChatTranscriptSuccess } from './admin-chat-slice';
import { HTML_TYPE } from '../../../../shared/admin-constants';
import { retrieveAdditionalData, retrieveAdditionalDataFailure, retrieveAdditionalDataSuccess } from './admin-data-slice';
import { retrieveCallRecordings, retrieveCallRecordingsFailure, retrieveCallRecordingsSuccess } from './admin-call-slice';
import { retrieveAppointments, retrieveAppointmentsFailure, retrieveAppointmentsSuccess } from './admin-appointments-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_GATEWAY_CALENDAR_ROOT,
} = process.env;

const loadBusinessDataEpic = (action$, state$) => action$.pipe(
  filter(retrieveBusinessData.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}def-business/search?filter=${state$.value.admin.business.searchTerm}${state$.value.admin.business.captureCodeFilter ? `&captureCodeActive=${state$.value.admin.business.captureCodeFilter}` : ''}${state$.value.admin.business.activeFilter ? `&active=${state$.value.admin.business.activeFilter}` : ''}${state$.value.admin.business.testUserFilter ? `&ignoreTestAccounts=${state$.value.admin.business.testUserFilter}` : ''}${state$.value.admin.business.prodSlugFilter ? `&slug=${state$.value.admin.business.prodSlugFilter}` : ''}${state$.value.admin.business.prodTierFilter ? `&tier=${state$.value.admin.business.prodTierFilter}` : ''}${state$.value.admin.business.activationDateFilter ? `&startDate=${state$.value.admin.business.activationDateFilter}&endDate=${state$.value.admin.business.activationDateFilter}` : ''}&page=${state$.value.admin.business.page}&size=${state$.value.admin.business.rowLimit}&sort=${state$.value.admin.business.sortColumn},${state$.value.admin.business.sortDirection}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => {
      const { content, totalElements } = response.response;
      return retrieveBusinessDataSuccess({
        content,
        resultTotal: totalElements,
      });
    }),
    catchError((error) => (
      of(retrieveBusinessDataFailure(error))
    )),
  )),
);

const verifyUserEpic = (action$, state$) => action$.pipe(
  filter(verifyAdminUser.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}users/me`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => (
      verifyAdminUserSuccess(response.response)
    )),
    catchError((error) => (
      of(verifyAdminUserFailure(error))
    )),
  )),
);

const loadChatTranscriptEpic = (action$) => action$.pipe(
  filter(retrieveChatTranscript.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}diy-chatbot/transcript?gmaid=${action.payload.gmaid}&type=${action.payload.type}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: action.payload.type === HTML_TYPE ? 'html' : 'text',
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => (
      retrieveChatTranscriptSuccess({
        type: action.payload.type,
        data: response.response,
      })
    )),
    catchError((error) => (
      of(retrieveChatTranscriptFailure(error))
    )),
  )),
);

const loadCallRecordingsEpic = (action$) => action$.pipe(
  filter(retrieveCallRecordings.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}call-tracking/download?gmaid=${action.payload.gmaid}&type=${action.payload.type}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: action.payload.type === HTML_TYPE ? 'html' : 'text',
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => (
      retrieveCallRecordingsSuccess({
        type: action.payload.type,
        data: response.response,
      })
    )),
    catchError((error) => (
      of(retrieveCallRecordingsFailure(error))
    )),
  )),
);

const loadAppointmentsEpic = (action$) => action$.pipe(
  filter(retrieveAppointments.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_CALENDAR_ROOT}calendar/appointments/list?gmaid=${action.payload.gmaid}&page=${action.payload.page}&size=${action.payload.size}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => (
      retrieveAppointmentsSuccess(response.response)
    )),
    catchError((error) => (
      of(retrieveAppointmentsFailure(error))
    )),
  )),
);

const loadUserOrdersEpic = (action$) => action$.pipe(
  filter(retrieveAdditionalData.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}orders/ui/external/2/user/${action.payload.email}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: ['BUSINESS', 'LOCATIONS', 'ORDER_ITEMS', 'USER', 'PRODUCT_INFO'],
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => (retrieveAdditionalDataSuccess(response.response))),
    catchError((error) => (
      of(retrieveAdditionalDataFailure(error))
    )),
  )),
);

export default [
  loadBusinessDataEpic,
  verifyUserEpic,
  loadChatTranscriptEpic,
  loadCallRecordingsEpic,
  loadAppointmentsEpic,
  loadUserOrdersEpic,
];
