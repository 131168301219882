import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, useTheme } from '@mui/material';
import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryButton } from '../../../../../shared/settings-shared-components';
import { captureEmailSubmitClick, sendCaptureEmailAction } from '../../data/account-slice';
import { ReactComponent as FileCopyIcon } from '../../../../../images/email-icon.svg';
import { dismissToggleModal } from '../../../../configuration/data/localiqCode-slice';
import { trackCaptureCodeInstructionSent } from '../../../../../shared/analytics-utils';

const EmailSectionWrapper = styled('div')(({ theme, modalMode }) => ({
  width: '100%',
  display: modalMode && 'flex',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const NeedHelpTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.common.black,
  fontFamily: 'Unify Sans Semibold',
  margin: '24px 0 24px 0'
}));

const EmailInstruction = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontFamily: 'Unify Sans Semibold',
  marginBottom: '8px'
}));

const InstructionSubtitle = styled(Typography)(({ theme, modalMode }) => ({
  fontSize: 16,
  margin: modalMode && '24px 0',
  float: 'left'
}));

const InstructionContentWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'modalMode' })(({ modalMode }) => ({
  padding: modalMode && '1rem 24px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const EmailWrapper = styled('div')(({ theme, modalMode }) => ({
  margin: '16px 0',
  display: 'flex',
  flexDirection: modalMode ? 'column' : 'row',
  width: modalMode ? '100%' : '55%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    gap: 24
  },
}));

const SendEmailButton = styled(PrimaryButton)(({ theme, modalMode }) => ({
  marginLeft: modalMode ? 0 : '16px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const SendEmailWrapper = styled('div')(({ theme, modalMode }) => ({
  display: modalMode && "flex",
  marginTop: modalMode && '24px'
}));

const CAPTURE_CODE_EMAIL = 'captureCodeEmail';

export default function CaptureCodeEmailCard({ scriptID, modalMode, source }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userEmail = useSelector(state => state.appSettings.user?.email);
  const [email, setEmail] = useState(modalMode ? userEmail : '')
  const schema = yup.object().shape({
    [CAPTURE_CODE_EMAIL]: yup.string().email('Invalid Email address')
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      [CAPTURE_CODE_EMAIL]: ''
    }
  })

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(captureEmailSubmitClick())
    trackCaptureCodeInstructionSent(source);

    if (scriptID) {
      dispatch(sendCaptureEmailAction({
        email: email,
        captureCode: scriptID
      }))
      dispatch(dismissToggleModal({ dismiss: true }))
    }
  }

  const { formState: { isValid, isDirty } } = methods;
  const disableButtonValidation = !isValid || !isDirty;

  // NOTE: At some point we may want to break out the form and modal here
  // as there are lots of modalMode checks.

  return (
    <EmailSectionWrapper modalMode={modalMode}>
      {!modalMode && (
        <>
          <NeedHelpTitle> Need help getting set up? </NeedHelpTitle>
          <EmailInstruction> Email Instructions </EmailInstruction>
        </>
      )}
      <InstructionContentWrapper modalMode={modalMode}>
        <InstructionSubtitle modalMode={modalMode}> The instructions for installing LocaliQ Code will be sent to the email address provided. </InstructionSubtitle>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit}>
              <EmailWrapper modalMode={modalMode}>
                <div style={{ width: '100%' }}>
                  <IQFormInput
                    id="capture-code-email"
                    name={CAPTURE_CODE_EMAIL}
                    onBlur={(e) => setEmail(e.target.value)}
                    placeholder="webmaster@businessname.com"
                    fullWidth
                  />
                </div>
                <SendEmailWrapper modalMode={modalMode}>
                  <SendEmailButton variant={modalMode ? "contained" : "outlined"} modalMode={modalMode} type="submit" disabled={disableButtonValidation}> Send Email <FileCopyIcon fill={modalMode ? theme.palette.common.white : theme.palette.primary.main} style={{ marginLeft: '.7rem' }} /></SendEmailButton>
                </SendEmailWrapper>
              </EmailWrapper>
            </form>
          </FormProvider>
        </ValidationProvider>
      </InstructionContentWrapper>
    </EmailSectionWrapper>
  );
}

CaptureCodeEmailCard.propTypes = {
  scriptID: PropTypes.string.isRequired,
  source: PropTypes.string,
  modalMode: PropTypes.bool,
};
