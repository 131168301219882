import { Helmet } from 'react-helmet';
import styled from "@emotion/styled";
import { Container, Typography } from "@mui/material";

const StyledContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  margin: '0 auto',
  paddingTop: theme.spacing(14),
  color: theme.palette.common.black,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
    fontFamily: 'Unify Sans Bold',
    fontSize: 60,
    paddingBottom: theme.spacing(5),
}));

const StyledSubTitle = styled(Typography)(() => ({
  fontFamily: 'Unify Sans SemiBold',
  fontSize: 18,
  paddingBottom: 10,
}));

const StyledDescription = styled(Typography)(() => ({
  fontSize: 16,
}));

export default function NotFoundPage() {
  return (
    <StyledContainer>
      <Helmet>
        <title>404 | LocaliQ</title>
      </Helmet>
      <StyledTitle variant="h1">404</StyledTitle>
      <StyledSubTitle variant="h2">The page you're looking for cannot be found.</StyledSubTitle>
      <StyledDescription variant="body1">The URL may have been mispelled, or the page may have moved.</StyledDescription>
    </StyledContainer>
  );
}
