import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card, CardActions, Typography } from '@mui/material';
import { IQLabelTooltip } from '@gannettdigital/shared-react-components';

const CustomCalendarCard = styled(Card)(({ theme, disabled }) => ({
  borderRadius: 4,
  border: '1px solid #D7D7D7',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  '& label .MuiSvgIcon-root': {
    opacity: disabled ? '40%' : '100%',
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const ImageWrapper = styled('img')(() => ({
  width: 48,
}));

const TitleText = styled(Typography)(() => ({
  fontSize: 24,
  fontFamily: 'Unify Sans SemiBold',
  lineHeight: '30px',
  maxWidth: 230,
}));

const CalendarCardActions = styled(CardActions)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    '& label': {
      maxHeight: 'none',
    },
  },
}));

/**
 * Component for the view state of an appointment type
 */
export default function CalendarConnectCard({
  headerText, calendarImg, disabled, calendarButton, tooltipText,
}) {
  return (
    <CustomCalendarCard disabled={disabled}>
      <HeaderWrapper>
        <ImageWrapper src={calendarImg} alt="calendar-icon" />
        <TitleText>{headerText}</TitleText>
      </HeaderWrapper>
      <CalendarCardActions>
        {calendarButton}
        <IQLabelTooltip
          tooltipPlacement="right"
          hasError={false}
          tooltipText={tooltipText}
          required={false}
        />
      </CalendarCardActions>
    </CustomCalendarCard>
  );
}

CalendarConnectCard.propTypes = {
  headerText: PropTypes.string.isRequired,
  calendarImg: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  calendarButton: PropTypes.node.isRequired,
  tooltipText: PropTypes.string.isRequired,
};
