import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { InputAdornment, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SchedulerSelectDropdownWrapper = styled(Select)(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingLeft: 0,
  '.MuiInputAdornment-root .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '.MuiSelect-select': {
    fontWeight: 600,
    fontSize: '1rem',
    textDecoration: 'underline',
    fontSize: 14

  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  }
}));

const DefaultMenuItem = styled(MenuItem)(() => ({
  display: 'none'
}));

const SchedulerText = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 12,
  marginRight: 8
}));

const MenuProps = {
  PaperProps: {
    sx: {
      '& li': {
        fontSize: '0.8rem',
      },
      '& li:hover': {
        backgroundColor: '#EBF4FF',
      },
      '& li:first-child': {
        fontWeight: 600,
        color: '#404040',
        opacity: 1,
      },
    }
  }
};

export default function SchedulerSelectDropdown({ name, defaultValue, startIcon, dropdownHeader, items, onChange }) {

  return (
    <SchedulerSelectDropdownWrapper
      name={name}
      defaultValue={defaultValue}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={MenuProps}
      onChange={onChange}
      startAdornment={(
        <InputAdornment position="start">
          <SchedulerText>View in:</SchedulerText>{startIcon}
        </InputAdornment>
          )}
      >
        <DefaultMenuItem disabled value="" />
        {items.map(({ value, description }) => (
          <MenuItem key={value} value={value}>
            {description}
          </MenuItem>
        ))}
    </SchedulerSelectDropdownWrapper>
  )
}

SchedulerSelectDropdown.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  startIcon: PropTypes.object,
  dropdownHeader: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({ 
    value: PropTypes.string,
    description: PropTypes.string,
  })),
  onChange: PropTypes.func,
};
