import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Typography } from '@mui/material';
import { SELECTED_CARD_ID } from './ChangeCreditCardForm';
import { CARD_BRAND_TO_ICON_MAP } from '../../../pages/products/account/account-constants';

const CreditCardRadioWrapper = styled('div')(({ theme, isChecked }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingRight: theme.spacing(1),
  border: isChecked ? `3px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.text.primary}`,
  cursor: 'pointer',
}));

export const CardDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '5px',
  height: '5px',
  backgroundColor: theme.palette.info.dark,
  borderRadius: '50%',
}));

const CardLabelWrapper = styled('label')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  'svg': {
    width: '40px',
    height: '25px',
  }
}));

const CardIconWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  margin: theme.spacing(0.5, 0),
  borderRadius: '2px',
}));

const CardLastDigitsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.3),
}));

const CardExpirationDateWrapper = styled('div')(() => ({
  marginLeft: 'auto',
}));

export default function CreditCardRadio({ creditCard, register, selectedPaymentMethodId }) {
  const { paymentMethodId, brand, last4, expMonth, expYear } = creditCard;
  const isChecked = selectedPaymentMethodId === paymentMethodId;
  const expMonthDisplay = expMonth?.padStart(2, '0');
  const CardIcon = CARD_BRAND_TO_ICON_MAP[brand]
    ? CARD_BRAND_TO_ICON_MAP[brand]
    : <CreditCardIcon />;

  return (
    <CreditCardRadioWrapper isChecked={isChecked}>
      <input id={paymentMethodId} {...register(SELECTED_CARD_ID)} name={SELECTED_CARD_ID} type='radio' value={paymentMethodId} />
      <CardLabelWrapper htmlFor={paymentMethodId}>
        <CardIconWrapper>
          {CardIcon}
        </CardIconWrapper>
        <CardLastDigitsWrapper>
          <CardDot />
          <CardDot />
          <CardDot />
          <CardDot />
        </CardLastDigitsWrapper>
        <Typography>{last4}</Typography>
        <CardExpirationDateWrapper>
          <Typography variant='body1'>Expires {expMonthDisplay}/{expYear}</Typography>
        </CardExpirationDateWrapper>
      </CardLabelWrapper>
    </CreditCardRadioWrapper>
  )
}

CreditCardRadio.propTypes = {
  creditCard: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  selectedPaymentMethodId: PropTypes.string.isRequired,
}