import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { IQCheckbox } from '@gannettdigital/shared-react-components';
import { Grid, Divider } from '@mui/material';
import { SubHeader } from '../settings-shared-components';
import NestedDayTimeSelector from './NestedDayTimeSelector';
import { DAY_TIME_SLOTS_KEY } from './availability-constants';

const DividingLine = styled(Divider)(({ theme }) => ({
  border: '1px solid #D7D7D7',
  margin: theme.spacing(3, 0),
}));

const DenseDividingLine = styled(DividingLine)(({ theme }) => ({
  margin: theme.spacing(0),
  flexGrow: 1,
}));

export default function NonMatchingHours({
  dayTimeSlots,
  getValues,
  individualDayTimeOnChange,
  individualDayDelete,
  updateDayTimeSlots,
  sectionTitle,
}) {
  const theme = useTheme();

  return (
    <Grid container direction="column" gap={3} marginTop="-4px">
      <SubHeader sx={{ marginTop: 0 }}>{ sectionTitle || 'Weekly Schedule' }</SubHeader>
      {dayTimeSlots.map((dayTime, dayIndex) => {
        const fieldName = `dayTimeSlots[${dayIndex}]`;
        const isEnabled = getValues(`${fieldName}.selected`);
        return (
          <Grid key={dayTime.id} container direction="column">
            {!isMobile
            && (
            <Grid container item md={12} display="flex" direction="row" alignItems="center" wrap="nowrap">
              <Grid item md={2}>
                <IQCheckbox checkboxBorderColor={theme.palette.info.dark} label={dayTime.day} field={`${fieldName}.selected`} onChange={(event) => updateDayTimeSlots(event, dayTime.day)} />
              </Grid>
              <Grid container item md={10} display="flex" direction="row" sx={{ gap: '16px' }}>
                {isEnabled && (
                <NestedDayTimeSelector
                  nestIndex={dayIndex}
                  parentName={DAY_TIME_SLOTS_KEY}
                  defaultValue={dayTime.schedules}
                  onChangeCallback={
                  (
                    index,
                    key,
                    value,
                  ) => individualDayTimeOnChange(dayTime.day, isEnabled, index, key, value)
                }
                  onDeleteCallback={(index) => individualDayDelete(dayTime.day, index)}
                />
                )}
              </Grid>
            </Grid>
            )}
            {isMobile
            && (
            <Grid container>
              <Grid container item direction="row" alignItems="center">
                <IQCheckbox checkboxBorderColor={theme.palette.info.dark} label={dayTime.day} field={`${fieldName}.selected`} onChange={(event) => updateDayTimeSlots(event, dayTime.day)} />
                <DenseDividingLine />
              </Grid>
              {isEnabled && (
              <NestedDayTimeSelector
                nestIndex={dayIndex}
                parentName={DAY_TIME_SLOTS_KEY}
                defaultValue={dayTime.schedules}
                onChangeCallback={
                  (
                    index,
                    key,
                    value,
                  ) => individualDayTimeOnChange(dayTime.day, isEnabled, index, key, value)
                }
                onDeleteCallback={(index) => individualDayDelete(dayTime.day, index)}
              />
              )}
            </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

NonMatchingHours.propTypes = {
  dayTimeSlots: PropTypes.array.isRequired,
  individualDayTimeOnChange: PropTypes.func.isRequired,
  individualDayDelete: PropTypes.func.isRequired,
  updateDayTimeSlots: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string,
};
