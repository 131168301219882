import BusinessDetailsCard from '../components/cards/BusinessDetailsCard';
import BusinessLogoCard from '../components/cards/BusinessLogoCard';
import SectionTitle from './SectionTitle';

export default function BusinessDetails() {
  return (
    <section>
      <SectionTitle title='Business Details' />
      <BusinessDetailsCard />
      <BusinessLogoCard />
    </section>
  )
}