import SectionLayout from '../../../../shared/section/SectionLayout';
import WithDisabled from '../../../tools/components/WithDisabled';
import BusinessNumberCard from '../cards/BusinessNumberCard';
import CallRecordingCard from '../cards/CallRecordingCard';

export default function CallSettingsGeneralSettings({ isDisabled }) {
  return (
    <div>
      <SectionLayout
        headerText="Click-to-Call"
        headerDescription="The Click-to-Call widget enables easy connections without dialing. Paired with our Call Recorder, it automatically records and logs calls, while saving caller ID contact info when available."
        name="generalSettings"
      />
      <WithDisabled isDisabled={isDisabled}>
        <BusinessNumberCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <CallRecordingCard />
      </WithDisabled>
    </div>
  );
}
