import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { OUTLOOK_AUTH_FINISHED_EVENT } from '../app-constants';
import { PrimaryButton } from '../settings-shared-components';

const {
  REACT_APP_MANAGEMENT_UI_ROOT,
  REACT_APP_MICROSOFT_CLIENT_ID,
} = process.env;

const CustomButton = styled(PrimaryButton)(() => ({
  borderRadius: 0,
  paddingTop: 10,
  paddingBottom: 10,
  lineHeight: '24px',
  width: '70%',
  height: 48,
  '&.MuiButton-root': {
    fontSize: 16,
    '&:hover': {
      fontSize: 16,
    },
  },
}));

/**
 * MS "sign in" button that is authing our request for a calendar scoped code.
 * Uses a popup window referencing OutlookAuthRedirect.jsx
 */
export default function MicrosoftSignInButton({
  onClick, onComplete, onEnded, disabled,
}) {
  const handleLogin = () => {
    if (onClick) {
      onClick();
    }
    // Pop up the modal in the center of the screen
    const y = window.top.outerHeight / 2 + window.top.screenY - (750 / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - (750 / 2);

    const authWindow = window.open(
      `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${REACT_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_MANAGEMENT_UI_ROOT}auth/callback&response_mode=query&scope=offline_access%20https://graph.microsoft.com/Calendars.ReadWrite%20https://graph.microsoft.com/User.Read&state=12345`,
      'MicrosoftAuth',
      `popup=yes,width=750,height=750,top=${y},left=${x}`,
    );

    // Listener for the "done" flow
    const authDone = (event) => {
      if (event.data && event.data.key === OUTLOOK_AUTH_FINISHED_EVENT) {
        authWindow.close();
        onComplete(event.data);
        window.removeEventListener('message', authDone);
      }
    };

    // Listen for the popup to tell use when its "done"
    window.addEventListener('message', authDone);

    // Monitor the popup for when it closes
    const checkClosed = setInterval(() => {
      if (authWindow.closed) {
        onEnded();
        clearInterval(checkClosed);
      }
    }, 500);
  };

  return (
    <CustomButton variant="contained" onClick={handleLogin} disabled={disabled}>Sign in with Microsoft</CustomButton>
  );
}

MicrosoftSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onEnded: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
