import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Fade, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ResultWrapper } from './styles/competitor-result-styles';
import { DIGITAL_ADVERTISING, FADE_TIMING, PRESENCE, WEBSITE_QUALITY } from '../../data/grader-constants';
import ScoreDisplay from '../ScoreDisplay/ScoreDisplay';
import { adjustScore, determineScoreObject, trimDisplayUrl } from '../../grader-utils';
import { SECTION_TITLES } from '../GraderSection/data/grader-section-constants';

/**
 * Handle displaying competitor results
 */
export default function CompetitorResult({ scoreData, handleClose }) {
  const { score, subCategories, domain } = scoreData;
  const { color: scoreColor, level } = determineScoreObject(score);
  const adjustedScore = adjustScore(score);
  const displayDomain = trimDisplayUrl(domain);
  const description = isMobile ? 'Their level:' : 'Their online presence level is:';

  return (
    <Fade in timeout={FADE_TIMING}>
      <ResultWrapper score={adjustedScore}>
        <div className="overallScore">
          <ScoreDisplay score={score} scoreColor={scoreColor} />
          <div className="scoreDescription">
            <Typography className="compLink" component="h2">
              <a href={`http://${domain}`} target="_blank" rel="noreferrer">{displayDomain}</a>
            </Typography>
            <Typography className="levelDescription" component="h2">
              {description}
              <br />
              <span className="level">{level}</span>
            </Typography>
          </div>
          <div className="scoreCancel">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />
        <div className="scoreBreakdown">
          <div className="scoreBox">
            <Typography className="scoreTitle">{SECTION_TITLES[WEBSITE_QUALITY]}</Typography>
            {subCategories[WEBSITE_QUALITY]}
            /100
          </div>
          <div className="scoreBox">
            <Typography className="scoreTitle">{SECTION_TITLES[PRESENCE]}</Typography>
            {subCategories[PRESENCE]}
            /100
          </div>
          <div className="scoreBox">
            <Typography className="scoreTitle">{SECTION_TITLES[DIGITAL_ADVERTISING]}</Typography>
            {subCategories[DIGITAL_ADVERTISING]}
            /100
          </div>
        </div>
      </ResultWrapper>
    </Fade>
  );
}

CompetitorResult.propTypes = {
  scoreData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
