import { useEffect } from 'react';

/**
 * An implementation of the React useEffect hook that will only fire on first render.  Basically
 * the equivalent of a class componentDidMount lifecycle method. This is done by passing
 * an empty array as a second parameter to the effect which accepts the array of properties
 * to listen to changes for in order to re-run. If this is empty it will never run the effect
 * again.  Easier to read this than making sure to check for
 * an empty array passed in. Also less likely to have someone accidentally pass something in
 * the array and change the behavior.
 *
 * See: https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once
 */
export const useMountEffect = (fun) => useEffect(fun, []);
