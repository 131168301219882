import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonRow from './SkeletonRow';
import SkeletonText from '../SkeletonText';

const StyledHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));

export default function LoadingSkeletonPerformance() {
  return (
    <div>
      <StyledHeadWrapper>
        <SkeletonText animation="wave" variant="string" width={116} height={30} />
        <SkeletonText animation="wave" variant="string" width={148} height={40} />
      </StyledHeadWrapper>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </div>
  );
}
