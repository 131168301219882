import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import UpcomingAppointmentsDesktop from '../../assets/no-upcoming-appointments-desktop.png';
import UpcomingAppointmentsMobile from '../../assets/no-upcoming-appointments-mobile.png';
import { ReactComponent as PlusIcon } from '../../assets/add-appointment-plus-icon.svg';
import BookingsCTA from '../../../../shared/BookingsCTA';

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  marginBottom: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export default function NoUpcomingAppointments({ onAddAnAppointmentClick, schedulingIsActive }) {
  return (
    <StyledContainer>
      <img src={isMobile ? `${UpcomingAppointmentsMobile}` : `${UpcomingAppointmentsDesktop}` } alt='no appointments img' />
      <StyledText>
        You currently have no appointments.
      </StyledText>
      {
        schedulingIsActive && (
          <BookingsCTA
            text="Add an Appointment"
            endIcon={<PlusIcon />}
            onClickHandler={onAddAnAppointmentClick}
            style={{
              fontSize: '14px',
              padding: '11px 20px'
            }}
          />
        )
      }
    </StyledContainer>
  );
};

NoUpcomingAppointments.propTypes = {
  onAddAnAppointmentClick: PropTypes.func,
  schedulingIsActive: PropTypes.bool,
};
