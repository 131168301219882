import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography, Button } from '@mui/material';
import SectionTitle from './SectionTitle';
import CreditCardList from '../components/CreditCardList';
import PaymentNotification from '../components/PaymentNotification';
import { toggleActionBanner, loadCreditCards, setActionBanner, resetSuccess } from '../data/credit-cards-slice';
import { getCreditCardActionBanner } from '../account-utils';
import { HAS_CARD_DESCRIPTION, HAS_NO_CARD_DESCRIPTION, EDIT_CARD_SUCCESS, EDIT_CARD_FAILURE, ADD_CARD_SUCCESS, ADD_CARD_FAILURE, DELETE_CARD_FAILURE, DELETE_CARD_SUCCESS } from '../account-constants';
import AddCreditCardModal from '../../../../shared/modal/AddCreditCardModal';
import EditCreditCardModal from '../../../../shared/modal/EditCreditCardModal';
import ActionCard from '../../../../shared/ActionCard';
import { ActionDescription, PrimaryButton } from '../../../../shared/settings-shared-components';
import { ActionIcons } from '../../../../shared/app-constants';
import DeleteCreditCardModal from '../../../../shared/modal/DeleteCreditCardModal';
import withStripe from '../../../../shared/hocs/withStripe';
import { trackCreditCardAction, trackCreditCardUpdateSuccess, trackCreditCardDeleteSuccess } from '../../../../shared/analytics-utils';
import SectionCard from '../../../../shared/SectionCard';
import EmptyStateCard from '../../../../shared/emptyState/EmptyStateCard';

const CreditCardsDescription = styled(Typography)(() => ({
  fontSize: '18px',
  lineHeight: '27px',
}));

const AddCreditCardBtn = styled(PrimaryButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

const CreditCardTitleWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

let type;

const CreditCardsWrapper = ({ hasCards, addCreditCard, children }) =>
   hasCards ? 
    (<section>
        <SectionTitle title='Credit Cards' actionButton={
          <AddCreditCardBtn onClick={addCreditCard}> + Add a Credit Card </AddCreditCardBtn>
        } showButton={hasCards}/>{children}
    </section>) : 
    (<SectionCard title={
        <CreditCardTitleWrapper>
          <Typography variant='h5'>Credit Cards</Typography>
          </CreditCardTitleWrapper>
        } showDividingLine={false}>{children}</SectionCard>)

function CreditCards() {
  const [clicked, setClicked] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");

  const dispatch = useDispatch();
  const creditCards = useSelector((state) => state.account.creditCards.cards);
  const toggleEditModal = useSelector((state) => state.account.creditCards.openEditModal);
  const toggleDeleteModal = useSelector((state) => state.account.creditCards.openDeleteModal);
  const hasCards = creditCards.length > 0;
  const [isOpen, setIsOpen] = useState(false);
  const editCardSuccessful = useSelector((state) => state.account.creditCards.editCardSuccess);
  const addCardSuccessful = useSelector((state) => state.account.creditCards.addCardSuccess);
  const deleteCardSuccessful = useSelector((state) => state.account.creditCards.deleteCardSuccess);
  const showActionBanner = useSelector((state) => state.account.creditCards.showActionBanner);
  const editPaymentId = useSelector((state) => state.account.creditCards.editPaymentId);
  const bannerText = useSelector((state) => state.account.creditCards.bannerText);
  const bannerType = useSelector((state) => state.account.creditCards.bannerType);
  const description = hasCards ? <>{HAS_CARD_DESCRIPTION}</> : <> {HAS_NO_CARD_DESCRIPTION} </>;

  function dismissActionBanner() {
    dispatch(toggleActionBanner({ toggleActionBanner: false }))
  }

  function addCreditCard() {
    trackCreditCardAction("add CC")
    setIsOpen(!isOpen)
    setClicked(false)
  }

  if (addCardSuccessful) {
    type = ADD_CARD_SUCCESS
  } else if (!addCardSuccessful && addCardSuccessful !== null) {
    type = ADD_CARD_FAILURE
  }

  if (editCardSuccessful && clicked) {
    type = EDIT_CARD_SUCCESS
  } else if (!editCardSuccessful && editCardSuccessful !== null && clicked) {
    type = EDIT_CARD_FAILURE
  }

  if (deleteCardSuccessful) {
    type = DELETE_CARD_SUCCESS
  } else if (!deleteCardSuccessful && deleteCardSuccessful !== null) {
    type = DELETE_CARD_FAILURE
  }

  function editSubmit(paymentId, clickedSave) {
    setClicked(clickedSave)
    setSelectedPaymentId(paymentId)
  }

  useEffect(() => {
    if (selectedPaymentId && selectedPaymentId !== editPaymentId) {
      setClicked(false)
      dispatch(toggleActionBanner({ toggleActionBanner: false }));
    }

    if (editCardSuccessful && (editPaymentId === selectedPaymentId) && clicked) {
      dispatch(toggleActionBanner({ toggleActionBanner: true }));
      trackCreditCardUpdateSuccess()
    }
  }, [editPaymentId, editCardSuccessful, clicked]);

  useEffect(() => {
    const { actionType, actionText } = getCreditCardActionBanner(type);

    if (actionType, actionText) {
      dispatch(setActionBanner({ bannerText: actionText, bannerType: actionType }))
    }
  }, [editCardSuccessful, addCardSuccessful, clicked, type]);

  useEffect(() => {
    if (deleteCardSuccessful) {
      trackCreditCardDeleteSuccess()
      dispatch(loadCreditCards())
      dispatch(toggleActionBanner({ toggleActionBanner: true }));
    }
  }, [deleteCardSuccessful]);

  useEffect(() => {
    let dismissId;

    if (showActionBanner) {
      dismissId = setTimeout(dismissActionBanner, 3000);
    }

    return () => {
      if (dismissId) {
        clearInterval(dismissId);
      }
    };
  }, [showActionBanner]);

  useEffect(() => {
    dispatch(resetSuccess())
  }, [editCardSuccessful, addCardSuccessful, deleteCardSuccessful])

  return (
    <CreditCardsWrapper hasCards={hasCards} addCreditCard={addCreditCard}>
      {( bannerType && bannerText && !isOpen && showActionBanner && !toggleEditModal) && (
        <ActionCard
          id="stripe-action-banner"
          type={bannerType}
          icons={ActionIcons}
        >
          <ActionDescription> {bannerText} </ActionDescription>
          <Button onClick={dismissActionBanner}> Dismiss </Button>
        </ActionCard>
      )}
      <PaymentNotification showCTA={false} />
      {
        hasCards ? (
          <CreditCardsDescription variant='body1'>
            {description}
          </CreditCardsDescription>
        ) : (
            <EmptyStateCard text={description} />
        )
      }
      <CreditCardList creditCards={creditCards} />
      <AddCreditCardModal isOpen={isOpen} toggleModal={() => setIsOpen(!isOpen)} />
      {toggleEditModal && <EditCreditCardModal isOpen={toggleEditModal} selectedCard={editSubmit} />}
      {toggleDeleteModal && <DeleteCreditCardModal isOpen={toggleDeleteModal} />}
    </CreditCardsWrapper>
  )
};

export default withStripe(CreditCards);

CreditCards.propTypes = {
  clientSecret: PropTypes.string,
}