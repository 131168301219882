import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import {
  CHATBOT_SLUG,
  SCHEDULER_SLUG,
  CALL_TRACKER_SLUG,
  PREMIUM_LISTINGS_SLUG,
  PRO_TIER,
  FREE_TIER,
  LITE_TIER,
  PRO_REVIEW_TIER,
} from './app-constants';

const SLUG_TO_GTM_CONVERSION_MAP = {
  [CHATBOT_SLUG]: 'Chat',
  [PREMIUM_LISTINGS_SLUG]: 'Listings',
  [SCHEDULER_SLUG]: 'Scheduling',
  [CALL_TRACKER_SLUG]: 'CallRecorder',
};

const TIER_TO_GTM_CONVERSION_MAP = {
  [FREE_TIER]: 'Free',
  [PRO_TIER]: 'Pro',
  [PRO_REVIEW_TIER]: 'Review',
  [LITE_TIER]: 'Free',
};

const buildDataLayer = (action, label, category, additionalParams) => {
  const dataLayer = {
    event: 'gaProductTrigger',
    gaEventCategory: category,
    gaEventAction: action,
    gaEventLabel: label,
    ...additionalParams,
  };

  return {
    dataLayer,
    dataLayerName: 'Freemium',
  };
};

function trackEvent(action, label, category, additionalParams) {
  TagManager.dataLayer(buildDataLayer(action, label, category, additionalParams));
}

export const trackProductActivation = (slug, tier, currency) => {
  const convertedSlug = SLUG_TO_GTM_CONVERSION_MAP[slug];
  const convertedTier = TIER_TO_GTM_CONVERSION_MAP[tier];

  const additionalInfo = {
    'currency': currency,
    'productName': convertedSlug,
    'productTier': convertedTier,
    'cidValue': Cookies.get('cid') ? Cookies.get('cid') : undefined,
  };

  trackEvent(
    'Activation',
    `${convertedSlug}${convertedTier}`,
    tier === FREE_TIER || tier === LITE_TIER ? 'SelfServeFreeProducts' : 'SelfServeProducts',
    additionalInfo,
  );
};

export const trackTrialStarted = () => {
  const additionalInfo = {
    startDate: new Date(Date.now()).toLocaleString('en-us'),
    cidValue: Cookies.get('cid') ? Cookies.get('cid') : undefined,
  };

  trackEvent(
    'FreeTrialStarted',
    'LeadCaptureToolkit',
    'SelfServeProducts',
    additionalInfo,
  );
};
