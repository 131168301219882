import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NeedHelp from '../shared/needHelp/NeedHelp';

const BodyContentWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  paddingLeft: theme.spacing(6),
  height: '90vh',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
  },
}));

const SplitColumnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

/**
 * Layout for a left side and a right side layout.
 * Right side takes all leftover space
 */
export default function SplitColumnLayout({ leftSideContent, rightSideContent, rightContentRef, needHelpText }) {
  return (
    <>
      <SplitColumnWrapper>
        {leftSideContent}
        {needHelpText && <NeedHelp
          title='Need Help?'
          content={needHelpText}
        />}

      </SplitColumnWrapper>
      <BodyContentWrapper ref={rightContentRef}>
        {rightSideContent}
      </BodyContentWrapper>
    </>
  );
}

SplitColumnLayout.propTypes = {
  leftSideContent: PropTypes.node.isRequired,
  rightSideContent: PropTypes.node.isRequired,
  rightContentRef: PropTypes.object,
};
