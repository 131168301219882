import styled from '@emotion/styled';
import { Skeleton, Hidden } from '@mui/material';
import { isMobile } from 'react-device-detect';

const AppointmentListSkeletonHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  '> div': {
    width: '35%',
  },
  '> span': {
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '&.empty': {
      backgroundColor: 'transparent',
      border: '2px solid rgba(0, 0, 0, 0.04)',
    }
  }
}));

const AppointmentListSkeletonCard = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  '> span': {
    borderRadius: '4px',
  },
  '.skeleton-card-header': {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}`,
  },
  '.skeleton-card-content': {
    border: '2px solid rgba(0, 0, 0, 0.04)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    '.skeleton-card-content-upper': {
      display: 'flex',
      justifyContent: 'space-between',
      '> div': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
      }
    },
    '.skeleton-card-content-bottom': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
    }
  }
}));

const AppointmentListSkeletonFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '25%',
  position: 'absolute',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  '> span': {
    borderRadius: '4px',
  },
  [theme.breakpoints.down('md')]: {
    width: '60%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    float: 'right',
    position: 'inherit',
  }
}));

export default function AppointmentListSkeleton() {
  const cards = [...Array(4).keys()];

  return (
    <>
      <AppointmentListSkeletonHeader>
        <div>
          <Skeleton variant="rectangular" width={isMobile ? 70 : 141} height={24} />
        </div>
        <Skeleton variant="rectangular" width={isMobile ? 30 : 78} height={18} />
        <Hidden smDown>
          <Skeleton variant="rectangular" className="empty" width={isMobile ? 60 : 155} height={36} />
        </Hidden>
        <Skeleton variant="rectangular" className="empty" width={isMobile ? 60 : 92} height={36} />
        <Skeleton variant="rectangular" width={isMobile ? 80 : 210}  height={40} />
      </AppointmentListSkeletonHeader>
      <div style={{flex: 1}}>
        {
          cards.map((key) => (
            <AppointmentListSkeletonCard key={key}>
              <div className="skeleton-card-header">
                <Skeleton variant="text" width={127} height={16} />
                <Skeleton variant="rectangular" height={2} sx={{ marginLeft: '8px', width: '100%' }} />
              </div>
              <div className="skeleton-card-content">
                <div className="skeleton-card-content-upper">
                  <div>
                    <Skeleton variant="text" width={42} height={27} />
                    <Skeleton variant="text" width={83} height={16} />
                  </div>
                  <Skeleton variant="text" width={24} height={24} />
                </div>
                <div className="skeleton-card-content-bottom">
                  <Skeleton variant="text" width={323} height={24} />
                  <Skeleton variant="text" width={2} height={24} />
                  <Skeleton variant="text" width={120} height={24} />
                  <Skeleton variant="text" width={2} height={24} />
                  <Skeleton variant="text" width={216} height={24} />
                </div>
              </div>
            </AppointmentListSkeletonCard>
          ))
        }
      </div>
      <AppointmentListSkeletonFooter>
        <Skeleton variant="rectangular" width={isMobile ? 70 : 134} height={16} />
        <Skeleton variant="rectangular" width={isMobile ? 40 : 52} height={16} />
        <Skeleton variant="rectangular" width={isMobile ? 70 : 120} height={16} />
      </AppointmentListSkeletonFooter>
    </>
  )
}
