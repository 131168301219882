import { IQFormPhoneInput } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { GridRow } from '../../../../shared/settings-shared-components';
import { SMS_PHONE_NUMBER_KEY } from '../settings-constants';
import { ReactComponent as RemovePhoneNumberIcon } from '../assets/remove-email-trash-icon.svg';

const PhoneInputContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'hasError'})(({ theme, hasError }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  '> svg': {
    marginTop: theme.spacing(3),
    cursor: 'pointer',
  },
  '& .MuiFormControl-root > div': {
    marginRight: '4px',
    color: hasError ? theme.palette.error.main : 'inherit',
    borderColor: hasError ? theme.palette.error.main : 'inherit',
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  color: theme.palette.error.main,
  fontSize: '0.75rem',
}));

export default function PhoneInputArrayField({ item, index, handleOnPhoneInputChange, handleOnRemovePhone, errors, isSMSChecked }) {
  const phoneFieldId = `${SMS_PHONE_NUMBER_KEY}.${item.id}`;
  const phoneFieldName= `${SMS_PHONE_NUMBER_KEY}.${index}.phone`;
  const invalidPhone = errors?.[SMS_PHONE_NUMBER_KEY]?.[index];

  return (
    <GridRow key={item.id} item md={12} sm={12} xs={12}>
      <GridRow  item md={6} sm={12} xs={12}>
          <PhoneInputContainer hasError={!!invalidPhone}>
            <IQFormPhoneInput
              id={phoneFieldId}
              name={phoneFieldName}
              labelText={`Phone Number ${index + 1} ${index === 0 ? '*' : ''}`}
              onChange={(e) => handleOnPhoneInputChange(e, index)}
              disabled={!isSMSChecked}
              fullWidth={false}
            />
            {
              index !== 0 ? (
                <RemovePhoneNumberIcon onClick={() => handleOnRemovePhone(index)} />
              ) : (
                <div style={{ visibility: 'hidden' }}>
                  <RemovePhoneNumberIcon />
                </div>
              )
            }
          </PhoneInputContainer>
          {
            invalidPhone && (
              <ErrorMessage>{invalidPhone.phone.message}</ErrorMessage>
            )
          }
      </GridRow>
      <GridRow item md={6} sm={6} xs={6} />
    </GridRow>
  )
}