import { createSlice } from '@reduxjs/toolkit';
import { closeDetailsAction } from './admin-shared-actions';

const initialState = {
  isLoading: false,
  callHTML: null,
};

const adminCallSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    retrieveCallRecordings: (state) => {
      state.isLoading = true;
      state.callHTML = null;
    },
    retrieveCallRecordingsSuccess: (state, action) => {
      const { data } = action.payload;
      // Confirm we have real data
      const foundData = data.indexOf(`"ct_tr_row"`);
      state.callHTML = foundData > -1 ? data : null;
      state.isLoading = false;
    },
    retrieveCallRecordingsFailure: (state) => {
      state.isLoading = false;
      state.callHTML = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeDetailsAction, (state) => {
      state.callHTML = null;
    });
  },
});

export const {
  retrieveCallRecordings,
  retrieveCallRecordingsSuccess,
  retrieveCallRecordingsFailure,
} = adminCallSlice.actions;
export default adminCallSlice;
