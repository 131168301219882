import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeChangesSaved } from './data/account-slice';
import SaveCancelWidget from '../../../shared/saveCancel/SaveCancelWidget';
import { SECTION_TO_SAVE_ACTION_CONFIG } from './account-config';

// For future functionalities - this will dispatch different actions based on the section it's at
export default function AccountSaveCancelWidget({ currentScreen }) {
  const dispatch = useDispatch();
  const { 
    isSaving,
    isInvalid,
    isDirty,
    showChangesSaved 
  } = useSelector((state) => state.account);
  const saveAction = SECTION_TO_SAVE_ACTION_CONFIG[currentScreen];

  const handleSave = useCallback(() => {
    dispatch(
      saveAction()
    )
  }, [saveAction]);

  const handleSaveComplete = () => {
    dispatch(
      closeChangesSaved(),
    );
  };

  return (
    <SaveCancelWidget
      isInvalid={isInvalid}
      isDirty={isDirty}
      isSaving={isSaving}
      showChangesSaved={showChangesSaved}
      onSave={handleSave}
      onSaveComplete={handleSaveComplete}
    />
  );
}

AccountSaveCancelWidget.propTypes = {
  currentScreen: PropTypes.string,
};
