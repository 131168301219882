import styled from '@emotion/styled';
import SkeletonText from '../SkeletonText';

const StyledCardWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: '8px',
  overflow: 'hidden',
  background: theme.palette.common.white,
  width: '33%',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledCardHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(5, 3),
  backgroundColor: theme.palette.background.default,
}));

const StyledCardBodyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const StyledCardBodyInfoAreaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

}));
const StyledCardButtonsAreaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),

}));

export default function Card() {
  return (
    <StyledCardWrapper>
      <StyledCardHeadWrapper>
        <SkeletonText animation="wave" variant="rectangular" width={228} height={120} />
      </StyledCardHeadWrapper>
      <StyledCardBodyWrapper>
        <StyledCardBodyInfoAreaWrapper>
          <SkeletonText animation="wave" variant="string" width="100%" height={25} />
          <SkeletonText animation="wave" variant="string" width="100%" height={16} />
          <SkeletonText animation="wave" variant="string" width="40%" height={16} />
        </StyledCardBodyInfoAreaWrapper>
        <StyledCardButtonsAreaWrapper>
          <SkeletonText animation="wave" variant="string" width={177} height={40} />
          <SkeletonText animation="wave" variant="string" width={52} height={40} />
        </StyledCardButtonsAreaWrapper>
      </StyledCardBodyWrapper>
    </StyledCardWrapper>
  );
}
