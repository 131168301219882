import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ReactComponent as RecentActivityTemplate } from '../../assets/recent-activity-template.svg';
import { ReactComponent as RecentActivityTemplateMobile } from '../../assets/recent-activity-template-mobile.svg';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(7),
}));

export default function NoLeads() {
  return (
    <StyledContainer>
      { isMobile ? <RecentActivityTemplateMobile /> : <RecentActivityTemplate /> }
      <Typography fontSize="18px" width={isMobile ? '70%' : 'auto'} textAlign="center">
        You currently have no leads.
      </Typography>
    </StyledContainer>
  );
}
