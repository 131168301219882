import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
  of,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  getAddressOptions,
  getAddressOptionsFailure,
  getAddressOptionsSuccess,
} from './address-autocomplete-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const getAutocompleteAddressSuggestionsEpic = (action$, state$) => action$.pipe(
  filter(getAddressOptions.match),
  debounceTime(500),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}address/suggestions?query=${action.payload.query}&country=${action.payload.country}`,
    method: 'GET',
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    mergeMap((response) => [
      getAddressOptionsSuccess(response.response),
    ]),
    catchError((error) => (
      of(getAddressOptionsFailure(error))
    )),
  )),
);

export default [
  getAutocompleteAddressSuggestionsEpic,
];
