import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQColorWidget, ValidationProvider } from '@gannettdigital/shared-react-components';
import { COLOR_TO_THEME, COLOR_WIDGET_COLORS, DEFAULT_WIDGET_COLOR, THEME_TO_COLOR } from '../../../../../shared/app-constants';
import WidgetSettingsStateChangedHook from '../../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook';
import { updateColorField } from '../../data/widgetSettings-slice';
import { WIDGET_COLOR_KEY } from '../../../../configuration/wizard/wizard-constants';

const FORM_KEYS = [WIDGET_COLOR_KEY];

export default function ColorWidgetCard({ existingTheme, originalTheme, disabled }) {
  const dispatch = useDispatch();

  const defaultColor = originalTheme || existingTheme;
  const schema = yup.object().shape({
    [WIDGET_COLOR_KEY]: yup.string(),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [WIDGET_COLOR_KEY]: THEME_TO_COLOR[defaultColor] || DEFAULT_WIDGET_COLOR,
    })),
  });

  const handleColorChange = (e) => {
    const theme = COLOR_TO_THEME[e.target.value];
    dispatch(
      updateColorField({ theme }),
    );
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <IQColorWidget
          name={WIDGET_COLOR_KEY}
          colors={COLOR_WIDGET_COLORS}
          onChange={handleColorChange}
          optionsPerRow={8}
          labelText=''
        />
        <WidgetSettingsStateChangedHook formName="newColorForm" formKeys={FORM_KEYS} subsectionKey="engagementWidget" />
      </FormProvider>
    </ValidationProvider>
  );
}

ColorWidgetCard.propTypes = {
  existingTheme: PropTypes.string,
  originalTheme: PropTypes.string,
  disabled: PropTypes.bool,
};
