import { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import SidePanel from './components/SidePanel';
import Header from './components/Header';
import Footer from './components/Footer';
import { StepWrapper } from './components/wizard-shared-components';
import {
  saveWizardStep,
  setWizardStatusAction,
  triggerStep,
  resetSaveStatus,
  saveWizardStepError,
} from '../data/configuration-slice';
import StepFrame from './components/StepFrame';
import {
  STEPS_WITHOUT_SAVING,
  WELCOME_KEY,
  WIDGET_FEATURE_KEY,
  WIDGET_SETUP_COMPLETE_PAGE_KEY,
  WIZARD_STEP_SAVING_STATUS_IN_PROGRESS,
  WIZARD_STEP_SAVING_STATUS_SUCCESS,
  WIZARD_STEP_SAVING_STATUS_FAILURE,
} from './wizard-constants';
import { ActionDescription, ViewInstructionsLink } from '../../../shared/settings-shared-components';
import {
  ActionIcons,
  ERROR_TYPE,
  CONFIG_PATH,
  DASHBOARD_PATH,
} from '../../../shared/app-constants';
import ActionCard from '../../../shared/ActionCard';
import { useBackListener } from '../../../shared/hooks/useBackListener';
import { checkInvalidStep, getMainPanelForStep } from './wizard-utils';
import { trackConfigWizardSaveAndExit, trackWizardDone } from '../../../shared/analytics-utils';

const WizardFrame = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
  '& div.right-side': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& div.action-card': {
    width: '95%',
    marginLeft: '2.5%',
    marginTop: '2rem'
  },
  [theme.breakpoints.down('md')]: {
    flexFlow: 'column',
    flexWrap: 'nowrap',
  },
}));

/**
 * Primary component for the configuration wizard.
 * Uses currentStep values to render side panel and main window.
 */
export default function WizardComponent({ allSteps }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentStep, savedStep } = useSelector((state) => state.configuration);
  const wizardSteps = useSelector((state) => state.configuration.wizardSteps);
  const {
    id, sideText, nextStep,
    orderIndex, completeWizardStep, hideBackButton,
  } = currentStep;
  const PanelComponent = getMainPanelForStep(currentStep.id);
  const wizardStepSavingStatus = useSelector((state) => state.configuration.wizardStepSavingStatus);
  const exitAfterSave = useSelector((state) => state.configuration.exitAfterSave);
  let { previousStep } = currentStep;
  const stepCount = Object.keys(allSteps).length - 1;
  const progress = Math.round(((orderIndex - 1) / stepCount) * 100);
  const totalNumberOfSteps = Object.keys(wizardSteps).length;
  // Don't show back button if on widget features step
  previousStep = id === WIDGET_FEATURE_KEY ? null : previousStep;
  const isLoadingConfig = useSelector((state) => state.configuration.isLoading);
  const completeErrStatus = useSelector((state) => state.configuration.submitError);
  const invalidWizardSteps = useSelector((state) => state.configuration.invalidSteps);
  const isInvalidStep = checkInvalidStep(id, invalidWizardSteps);
  const isOnCompletePage = id === WIDGET_SETUP_COMPLETE_PAGE_KEY;
  const [showStepSavingLoader, setShowStepSavingLoader] = useState(false);
  const [showStepSaveError, setShowStepSaveError] = useState(false);
  const stepSavingTimerDelayTimeout = useRef(null);

  const handleSaveAndExit = () => {
    if (!isInvalidStep) {
      dispatch(saveWizardStep({ exitAfterSave: true }));
      trackConfigWizardSaveAndExit(currentStep.id);
    } else {
      dispatch(saveWizardStepError({ saveError: true }))
    }
  };

  const handleNext = () => {
    dispatch(saveWizardStep({ nextStepId: nextStep }));
  };

  const handleBack = () => {
    // Move to the next step and also add a reference to this change to the nav history by
    // navigating to the same page (no refresh will happen) but the browser will know
    // it's another page view.  Pass in state variables to support navigating between
    // wizard screens and having a reference to where to go next.
    dispatch(triggerStep({ stepId: previousStep }));
    navigate(CONFIG_PATH, { state: { stepId: previousStep, previousStepId: currentStep.id } });
  };

  const handleDone = () => {
    dispatch(resetSaveStatus());
    navigate(DASHBOARD_PATH);
    trackWizardDone(currentStep.id);
  };

  const retrySuccess = () => {
    dispatch(setWizardStatusAction());
  };

  // Reloads the browser, used for easy user support refreshing
  const refreshPage = () => {
    window.location.reload();
  };

  useBackListener(({ location }) => {
    // Handles the brower back button to behave similar to
    // our back button but not quite.  It grabs the
    // step from the query string that is being navigated to
    // This may not be the previous step if a user used our
    // back button first.
    if (location.state?.stepId) {
      dispatch(triggerStep({ stepId: location.state.stepId }));
    } else {
      // No step state, we must be back to the beginning where we started, use the savedStep
      dispatch(triggerStep({ stepId: savedStep || WELCOME_KEY }));
    }
  });

  // This handles moving to the next step by waiting for the wizard to return a success
  // response.  This will also allow us to handle error states and not move forward
  // if any errors occur.  Will need additional tickets to highlight error behavior.
  useEffect(() => {
    if (wizardStepSavingStatus === WIZARD_STEP_SAVING_STATUS_SUCCESS) {
      clearTimeout(stepSavingTimerDelayTimeout.current);

      if (exitAfterSave) {
        dispatch(resetSaveStatus());
        navigate(DASHBOARD_PATH);
      } else {
        // Successful save, move to next step
        // Move to the next step and also add a reference to this change to the nav history by
        // navigating to the same page (no refresh will happen) but the browser will know
        // it's another page view.  Pass in state variables to support navigating between
        // wizard screens and having a reference to where to go next.
        dispatch(triggerStep({ stepId: nextStep }));
        navigate(CONFIG_PATH, { state: { stepId: nextStep, previousStepId: currentStep.id } });
        setShowStepSavingLoader(false);
        setShowStepSaveError(false);
      }
    } else if (wizardStepSavingStatus === WIZARD_STEP_SAVING_STATUS_FAILURE) {
      // TODO: Future ticket to handle failure/error states.
      clearTimeout(stepSavingTimerDelayTimeout.current);
      setShowStepSavingLoader(false);
      setShowStepSaveError(true);
    } else if (wizardStepSavingStatus === WIZARD_STEP_SAVING_STATUS_IN_PROGRESS) {
      // In progress, delay setting a timer of 1/2 second to show the loading spinner
      // If the save finishes before this timeout, it will clear the timeout
      stepSavingTimerDelayTimeout.current = setTimeout(() => {
        setShowStepSavingLoader(true);
        setShowStepSaveError(false);
      }, 200);
    }
  }, [wizardStepSavingStatus]);

  return (
    <WizardFrame container>
      <Header showSaveExit={!STEPS_WITHOUT_SAVING.includes(id) && !showStepSaveError} onSaveAndExit={handleSaveAndExit} />
      <Grid item xs={12} md={4} sx={{ flexGrow: { xs: 0, md: 1 } }}>
        <SidePanel>
          {sideText}
        </SidePanel>
      </Grid>
      <Grid className="right-side" item xs={12} md={8} flexGrow={1}>
        {isLoadingConfig || showStepSavingLoader
          ? (
            <StepWrapper>
              <IQLoadingSpinner />
            </StepWrapper>
          )
          : (
            <StepFrame>
              {(completeErrStatus && isOnCompletePage)
                && (
                  <div className="action-card">
                    <ActionCard
                      id="widget-complete-error"
                      type={ERROR_TYPE}
                      icons={ActionIcons}
                    >
                      <ActionDescription>
                        Oh no! It seems your features didn&#39;t submit.
                        No worries! Just hit that link again to give it another shot.
                      </ActionDescription>
                      <ViewInstructionsLink
                        onClick={retrySuccess}
                      >
                        Try Again
                      </ViewInstructionsLink>
                    </ActionCard>
                  </div>
                )}
              {(showStepSaveError)
                && (
                  <div style={{ width: '95%', marginLeft: '2.5%' }}>
                    <ActionCard
                      id="wizard-save-error"
                      type={ERROR_TYPE}
                      icons={ActionIcons}
                    >
                      <ActionDescription>
                        Oh no! Something went wrong. Please try again.
                      </ActionDescription>
                      <ViewInstructionsLink
                        onClick={refreshPage}
                      >
                        Try Again
                      </ViewInstructionsLink>
                    </ActionCard>
                  </div>
                )}
              <PanelComponent stepData={currentStep} />
            </StepFrame>
          )}
        <Footer
          progress={progress}
          onNext={handleNext}
          onBack={handleBack}
          onDone={handleDone}
          showNext={progress !== 100}
          showBack={!!previousStep && !completeWizardStep && !hideBackButton}
          showDone={progress === 100}
          disableNext={isInvalidStep || wizardStepSavingStatus === WIZARD_STEP_SAVING_STATUS_IN_PROGRESS}
          currentIndex={orderIndex}
          totalSteps={totalNumberOfSteps}
        />
      </Grid>
    </WizardFrame>
  );
}

WizardComponent.propTypes = {
  allSteps: PropTypes.object.isRequired,
};
