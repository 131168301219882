import { createSlice } from '@reduxjs/toolkit';
import {
  NINE_AM,
  FIVE_PM,
  All_DAY_KEYS,
  daysSortLookup,
} from '../../../shared/availability/availability-constants';
import { loadWizardConfigSuccess } from './configuration-slice';
import { APPOINTMENT_SCHEDULE_KEY } from '../wizard/wizard-constants';
import { getLocalTimezone } from '../../../shared/util/get-local-timezone.utils';

// State keys
export const TIMEZONE_KEY = 'availabilityTimezone';
export const SAME_TIME_KEY = 'availabilitySameTime';
export const SAME_SCHEDULE_KEY = 'availabilitySameSchedule';
export const DAYS_SCHEDULE_KEY = 'availabilityDays';

export const CONVERTED_SAME_SCHEDULE_KEY = 'convertedSameSchedule';
export const CONVERTED_DAYS_SCHEDULE_KEY = 'convertedDays';
export const SPECIFIC_DAYS_CLOSED_KEY = 'daysClosed';
export const START_DATE_KEY = 'startDate';
export const END_DATE_KEY = 'endDate';
export const CONVERTED_SPECIFIC_DAYS_CLOSED_KEY = 'convertedDaysClosed';

// factory function that creates day with default time blocks
function createDayFactory(dayKey) {
  return {
    day: dayKey,
    selected: false,
    schedules: [
      {
        startTime: '09:00:00',
        endTime: '17:00:00',
      },
    ],
  };
}

const appointmentScheduleSlice = createSlice({
  name: 'appointment_schedule',
  initialState: {
    [TIMEZONE_KEY]: null,
    [SAME_TIME_KEY]: true,
    [SAME_SCHEDULE_KEY]: {},
    [DAYS_SCHEDULE_KEY]: [],
    [CONVERTED_SAME_SCHEDULE_KEY]: [],
    [CONVERTED_DAYS_SCHEDULE_KEY]: [],
    [SPECIFIC_DAYS_CLOSED_KEY]: [],
    [CONVERTED_SPECIFIC_DAYS_CLOSED_KEY]: [],
  },
  reducers: {
    updateAvailabilityField: (state, action) => {
      state[action.payload.fieldName] = action.payload.fieldValue;
    },
    updateAvailabilitySameScheduleDays: (state, action) => {
      const currentValue = state[SAME_SCHEDULE_KEY];
      const { fieldValue: onOff, fieldName: dayKey } = action.payload;
      let newValue = [];
      const { days } = currentValue;

      if (onOff) {
        if (Array.isArray(days) && days.length >= 1) {
          newValue = [...days];
          newValue.push(dayKey.toUpperCase());
        } else {
          newValue = [dayKey.toUpperCase()];
        }
      } else if (Array.isArray(days) && days.length >= 1) {
        newValue = days.filter((day) => day !== dayKey.toUpperCase());
      }
      state[SAME_SCHEDULE_KEY].days = newValue;
    },
    updateAvailabilitySameSchedule: (state, action) => {
      const currentValue = state[action.payload.fieldName];
      const saveData = action.payload.fieldValue;
      /**
       * Same schedule has the following format:
       * {
       *    "days": [
       *        "MONDAY",
       *        "TUESDAY"
       *    ],
       *    "schedules": [
       *        {
       *            "startTime": "09:00:00",
       *            "endTime": "18:00:00"
       *        }
       *    ]
       * },
       */
      const { schedules } = currentValue;
      if (Array.isArray(schedules) && schedules.length > 0) {
        const currentField = schedules[saveData.index];
        if (currentField) {
          currentField[saveData.key] = saveData.value;
        } else {
          schedules[saveData.index] = {
            [saveData.key]: saveData.value,
          };
        }
      } else {
        currentValue.schedules = [
          {
            [saveData.key]: saveData.value,
          },
        ];
      }
      state[action.payload.fieldName] = currentValue;
      state[CONVERTED_SAME_SCHEDULE_KEY] = currentValue.schedules;
    },
    addNewSlotAvailabilitySameSchedule: (state, action) => {
      const currentValue = state[SAME_SCHEDULE_KEY];
      const { schedules } = currentValue;
      if (schedules.length < 2) {
        schedules.push(action.payload.fieldValue);
      }

      state[SAME_SCHEDULE_KEY].schedules = schedules;
      state[CONVERTED_SAME_SCHEDULE_KEY] = schedules;
    },
    deleteAvailabilitySameSchedule: (state, action) => {
      const currentValue = state[SAME_SCHEDULE_KEY];
      const { schedules } = currentValue;
      const removeIndex = action.payload.index;
      schedules.splice(removeIndex, 1);

      state[SAME_SCHEDULE_KEY].schedules = schedules;
      state[CONVERTED_SAME_SCHEDULE_KEY] = schedules;
    },
    updateAvailabilityDaysSchedule: (state, action) => {
      const currentValue = state[action.payload.fieldName];
      const currentValueConverted = state[CONVERTED_DAYS_SCHEDULE_KEY];
      const saveData = action.payload.fieldValue;
      const dayField = currentValue.find((dayObj) => dayObj.day === saveData.dayKey);
      const dayFieldConverted = currentValueConverted
        .find((dayObj) => dayObj.day.toUpperCase() === saveData.dayKey.toUpperCase());
      if (dayField) {
        const currentDayTimeField = dayField.schedules[saveData.index];
        const currentDayTimeFieldConverted = dayFieldConverted.schedules[saveData.index];
        if (currentDayTimeField) {
          currentDayTimeField[saveData.key] = saveData.value;
          currentDayTimeFieldConverted[saveData.key] = saveData.value;
        } else {
          dayField.schedules.splice(saveData.index, 0, {
            [saveData.key]: saveData.value,
          });
          dayFieldConverted.schedules.splice(saveData.index, 0, {
            [saveData.key]: saveData.value,
          });
        }
      } else {
        // No existing day data, add first block
        // Build schedule payload
        const schedules = [];
        if (saveData.index === 1) {
          // This indicates they are adding a new timeblock without
          // having edited the defaults. Add 9-5 defaults, then handle user edit.
          schedules.push({
            startTime: NINE_AM.value,
            endTime: FIVE_PM.value,
          });
        }
        // Its possible that they are editing a singular time off the defaults.
        // Add both default values to prevent saving only half a value.
        const newTimePairing = {
          startTime: NINE_AM.value,
          endTime: FIVE_PM.value,
        };

        // If they edit the 2nd value, it will hit the above IF instead of here.
        newTimePairing[saveData.key] = saveData.value;
        schedules.push(newTimePairing);

        currentValue.push({
          day: saveData.dayKey,
          selected: saveData.isEnabled,
          schedules,
        });
      }
      state[action.payload.fieldName] = currentValue;
      state[CONVERTED_DAYS_SCHEDULE_KEY] = currentValueConverted;
    },
    deleteAvailabilityDaysSchedule: (state, action) => {
      const currentValue = state[DAYS_SCHEDULE_KEY];
      const currentValueConverted = state[CONVERTED_DAYS_SCHEDULE_KEY];
      const saveData = action.payload;
      const dayField = currentValue.find((dayObj) => dayObj.day === saveData.dayKey);
      const dayFieldConverted = currentValueConverted
        .find((dayObj) => dayObj.day.toUpperCase() === saveData.dayKey.toUpperCase());
      if (dayField) {
        const foundTimeBlock = dayField.schedules[saveData.index];
        if (foundTimeBlock) {
          dayField.schedules.splice(saveData.index, 1);
          dayFieldConverted.schedules.splice(saveData.index, 1);
          state[DAYS_SCHEDULE_KEY] = currentValue;
          state[CONVERTED_DAYS_SCHEDULE_KEY] = currentValueConverted;
        }
      }
    },
    updateSelectOnAvailabilityDaysSchedule: (state, action) => {
      const { dayKey, isSelected } = action.payload;
      const currentValue = state[DAYS_SCHEDULE_KEY];
      const currentValueConverted = state[CONVERTED_DAYS_SCHEDULE_KEY];

      // Find the day then turned on or off.
      const dayField = currentValue.find((dayObj) => dayObj.day === dayKey);
      const dayFieldConverted = currentValueConverted
        .find((dayObj) => dayObj.day.toUpperCase() === dayKey.toUpperCase());
      if (dayField) {
        dayField.selected = isSelected;
        dayFieldConverted.selected = isSelected;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWizardConfigSuccess, (state, action) => {
      if (action.payload.length > 0) {
        const { data } = action.payload[0];
        if (data[APPOINTMENT_SCHEDULE_KEY]) {
          const {
            [TIMEZONE_KEY]: availabilityTimezone,
            [SAME_TIME_KEY]: availabilitySameTime,
            [DAYS_SCHEDULE_KEY]: availabilityDays,
            [SAME_SCHEDULE_KEY]: availabilitySameSchedule,
          } = data[APPOINTMENT_SCHEDULE_KEY];

          if (availabilityTimezone) {
            state[TIMEZONE_KEY] = availabilityTimezone;
          } else {
            state[TIMEZONE_KEY] = getLocalTimezone();
          }

          state[SAME_TIME_KEY] = availabilitySameTime;

          if (availabilityDays && availabilityDays.length > 0) {
            // if theres is a missing day in days object create array with the missing day keys
            // ex: missing = [MONDAY, SATURDAY] | []
            const missingDays = All_DAY_KEYS.reduce((acc, dayKey) => {
              const exists = availabilityDays.find((d) => d.day === dayKey);
              if (!exists) {
                return [...acc, dayKey];
              }
              return acc;
            }, []);

            const modifiedAvailabilityDays = [...availabilityDays];
            // if there is a missing day create the specific day and add it to already
            // existing once
            if (missingDays.length > 0) {
              missingDays.forEach((dayKey) => {
                modifiedAvailabilityDays.push(createDayFactory(dayKey));
              });
            }
            state[DAYS_SCHEDULE_KEY] = [...modifiedAvailabilityDays];

            state[CONVERTED_DAYS_SCHEDULE_KEY] = modifiedAvailabilityDays
              .map((dayObject) => {
                const day = dayObject
                  .day.charAt(0).toUpperCase() + dayObject.day.toLowerCase().slice(1);
                return {
                  ...dayObject,
                  day,
                  sortKey: daysSortLookup[dayObject.day],
                };
              })
              .sort((a, b) => a.sortKey - b.sortKey);
          }

          if (availabilitySameSchedule && availabilitySameSchedule.schedules) {
            state[CONVERTED_SAME_SCHEDULE_KEY] = availabilitySameSchedule.schedules;
            state[SAME_SCHEDULE_KEY] = availabilitySameSchedule;
          }
        }
      }
    });
  },
});

export const {
  updateAvailabilityField,
  updateAvailabilitySameScheduleDays,
  updateAvailabilitySameSchedule,
  addNewSlotAvailabilitySameSchedule,
  deleteAvailabilitySameSchedule,
  updateAvailabilityDaysSchedule,
  deleteAvailabilityDaysSchedule,
  updateSelectOnAvailabilityDaysSchedule,
} = appointmentScheduleSlice.actions;
export default appointmentScheduleSlice.reducer;
