import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const SUCCESS_BACKGROUND = 'success';
export const WARNING_BACKGROUND = 'warning';

const StatusIndicatorContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(1),
  border: `1px solid ${theme.palette.text.primary}`,
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    gap: 'unset',
  }
}));

const IconWrapper = styled('div')(({ theme, background }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  // TODO: Both colors are not in IQThemeV2
  background: background === SUCCESS_BACKGROUND
    ? 'rgba(125, 190, 80, 1)'
    : 'rgba(240, 60, 75, 1)',
}));

const TextWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  textTransform: 'uppercase',
  letterSpacing: '1px',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '15px',
}));

const MainTextWrapper = styled(TextWrapper)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderLeft: `1px solid ${theme.palette.action.disabledBackground}`,
  fontFamily: 'Unify Sans Semibold',
}));

const SecondaryTextWrapper = styled(TextWrapper)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export default function StatusIndicator({
  id, mainText, secondaryText, icon, background,
}) {
  return (
    <StatusIndicatorContainer id={id}>
      <IconWrapper background={background}>
        <img src={icon} alt="status indicator icon" id={`${id}-icon`} />
      </IconWrapper>
      <SecondaryTextWrapper variant="overline" id={`${id}-label`}>
        {secondaryText}
      </SecondaryTextWrapper>
      <MainTextWrapper variant="overline" id={`${id}-value`}>
        {mainText}
      </MainTextWrapper>
    </StatusIndicatorContainer>
  );
}

StatusIndicator.propTypes = {
  id: PropTypes.string,
  mainText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  icon: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};
