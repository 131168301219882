import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MiniCard from '../components/getTheMostCards/MiniCard';
import TurnOnAllWidgetsBG from '../assets/get-the-most-cards-turn-on-all-widgets-BG.svg';
import AddListingsBG from '../assets/get-the-most-cards-add-listings-BG.svg';
import ConnectToCalendarsBG from '../assets/get-the-most-cards-connect-calendars-BG.svg';
import TurnOnAllWidgetsIMG from '../assets/get-the-most-cards-turn-on-all-widgets-img.png';
import AddListingsIMG from '../assets/get-the-most-cards-add-listings-img.png';
import ConnectToCalendarsIMG from '../assets/get-the-most-cards-connect-calendars-img.png';
import { ADD_CALENDAR_MINI_CARD_KEY, ADD_LISTINGS_MINI_CARD_KEY, TURN_ON_ALL_FEATURES_MINI_CARD_KEY } from '../dashboard-constants';
import LoadingSkeletonGetTheMostCards from '../components/loadingSkeletons/getTheMostCards/LoadingSkeletonGetTheMostCards';
import { CALENDAR_QUERY_SECTION_NAME, CALENDAR_QUERY_SECTION_VALUE } from '../../products/bookings/bookings-constants';
import { trackDashboardCardClick } from '../../../shared/analytics-utils';
import { dismissBanner } from '../../../shared/business-attributes-slice';
import { hasAnyNonActivatedWidgets } from '../../../shared/app-utils';
import { setCurrentScreen } from '../../../shared/navigation/navigation-slice';
import { WIDGET_SETTINGS_PATH, TOOLS_PATH, DISMISS_BANNER_SECTION } from '../../../shared/app-constants';
import {
  ENGAGEMENT_WIDGET_PAGE, WIDGET_SETTINGS_STEPS, TOOL_NAV_STEPS, SCHEDULING_PAGE,
} from '../../../shared/navigation/navigation-config';

const StyledContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.common.white,
  height: '100%',
  padding: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 3),
  },
}));

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '30px',

  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
}));

const StyledCardsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  '&.get-the-most-wrap-items': {
    flexWrap: 'wrap',
  },
  '&.get-the-most-no-wrap-items': {
    flexWrap: 'nowrap',
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const { REACT_APP_LISTINGS_URL } = process.env;

export default function GetTheMostCards() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schedulingIsActive = useSelector((state) => state.bookings.toggle.isActive);
  const chatbotIsActive = useSelector((state) => state.chatbot.toggle.isActive);
  const callSettingsIsActive = useSelector((state) => state.callSettings.isWidgetActive);
  const contactFormIsActive = useSelector((state) => state.contactForm.toggle.isActive);
  const dismissStatus = useSelector((state) => state.businessAttributes[DISMISS_BANNER_SECTION]);
  const isLoadingSettingsData = useSelector((state) => state.appSettings.isLoadingSettingsData);

  // Mini cards display status variables
  const turnOnFeaturesCardDisplay = hasAnyNonActivatedWidgets(
    [schedulingIsActive, chatbotIsActive, callSettingsIsActive, contactFormIsActive],
  );
  const googleCalendarCardDisplay = useSelector((state) => state.googleCalendar.isConnected);
  const outlookCalendarCardDisplay = useSelector((state) => state.outlookCalendar.isConnected);
  const calendarsDisplay = !outlookCalendarCardDisplay && !googleCalendarCardDisplay;
  const listingsCardDisplay = useSelector((state) => state.dashboard.hasListingsSubscription);

  const activateWidgetPath = `${WIDGET_SETTINGS_PATH}/${WIDGET_SETTINGS_STEPS[ENGAGEMENT_WIDGET_PAGE].url}`;
  const settingsToolsSchedulingPath = `${TOOLS_PATH}/${TOOL_NAV_STEPS[SCHEDULING_PAGE].url}?${CALENDAR_QUERY_SECTION_NAME}=${CALENDAR_QUERY_SECTION_VALUE}`;

  const onGoToWidgetSettingsClick = () => {
    trackDashboardCardClick('Get The Most','Turn on all Widget Features');
    dispatch(setCurrentScreen({ currentScreen: ENGAGEMENT_WIDGET_PAGE }));
    navigate(activateWidgetPath);
  };

  const onListingsClick = () => {
    trackDashboardCardClick('Get The Most','Add Listings');
    window.open(REACT_APP_LISTINGS_URL);
  };

  const onConnectToCalendarsClick = () => {
    trackDashboardCardClick('Get The Most','Connect to Google Calendar');
    dispatch(setCurrentScreen({ currentScreen: SCHEDULING_PAGE }));
    navigate(settingsToolsSchedulingPath);
  };

  const onDismissClick = (cardKey) => {
    trackDashboardCardClick('Get The Most',`Dismiss ${cardKey}`);
    dispatch(dismissBanner({ key: cardKey }));
  };

  const allMiniCards = [
    {
      key: TURN_ON_ALL_FEATURES_MINI_CARD_KEY,
      display: turnOnFeaturesCardDisplay && !dismissStatus[TURN_ON_ALL_FEATURES_MINI_CARD_KEY],
      cardHeadBG: TurnOnAllWidgetsBG,
      cardHeadBGImage: TurnOnAllWidgetsIMG,
      title: 'Turn on all Widget Features',
      text: 'Give your website visitors more options to connect with you.',
      buttonText: 'Turn on Features',
      clickHandler: onGoToWidgetSettingsClick,
    },
    {
      key: ADD_LISTINGS_MINI_CARD_KEY,
      display: listingsCardDisplay && !dismissStatus[ADD_LISTINGS_MINI_CARD_KEY],
      cardHeadBG: AddListingsBG,
      cardHeadBGImage: AddListingsIMG,
      title: 'Add Listings',
      text: 'Make sure your business gets found. We\'ll help you get accurately listed where it matters.',
      buttonText: 'Learn More About Listings',
      clickHandler: onListingsClick,
    },
    {
      key: ADD_CALENDAR_MINI_CARD_KEY,
      display: calendarsDisplay && !dismissStatus[ADD_CALENDAR_MINI_CARD_KEY],
      cardHeadBG: ConnectToCalendarsBG,
      cardHeadBGImage: ConnectToCalendarsIMG,
      title: 'Connect Your Calendar',
      text: 'Connect your Google or Outlook calendar for seamless appointment management.',
      buttonText: 'Connect Calendar',
      clickHandler: onConnectToCalendarsClick,
    },
  ];

  // Check that determines which version of
  // "Get the Most out of LocaliQ" section to be displayed
  const numberOfCardsToBeDisplayed = allMiniCards.filter((mC) => mC.display).length;

  if (isLoadingSettingsData) {
    return (
      <StyledContainer>
        <LoadingSkeletonGetTheMostCards />
      </StyledContainer>
    );
  }

  return (
    numberOfCardsToBeDisplayed > 0 && (
      <StyledContainer item xs={12}>
        <StyledSectionTitle>Get the Most out of LocaliQ</StyledSectionTitle>
        <StyledCardsContainer className={numberOfCardsToBeDisplayed > 3 ? 'get-the-most-wrap-items' : 'get-the-most-no-wrap-items'}>
          {
              allMiniCards
                .filter((mC) => mC.display)
                .map((mC) => (
                  <MiniCard
                    key={mC.key}
                    cardKey={mC.key}
                    buttonClickHandler={mC.clickHandler}
                    dismissClickHandler={onDismissClick}
                    BG={mC.cardHeadBG}
                    BGImage={mC.cardHeadBGImage}
                    title={mC.title}
                    text={mC.text}
                    buttonText={mC.buttonText}
                    cardsCount={numberOfCardsToBeDisplayed}
                  />
                ))
            }
        </StyledCardsContainer>
      </StyledContainer>
    )
  );
}
