import { useState } from 'react'
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ERROR_TYPE, ActionIcons, BACKDROP_CLICK } from '../app-constants';
import { PrimaryButton, ModalBackground, StripeActionCard, ActionDescription } from '../settings-shared-components';
import { ReactComponent as CloseIcon } from '../../images/close-x-icon.svg';
import { addCreditCardSuccess, loadCreditCards, toggleActionBanner } from '../../pages/products/account/data/credit-cards-slice';
import { getSetupIntentAction, handleStripeError } from '../../pages/products/account/data/stripe-slice';
import { trackCreditCardAddSuccess } from '../analytics-utils';
import {
  CloseModal,
  ModalDivider,
  CreditCardHeader,
  CCSectionWrapper,
  HeaderWrapper,
  CreditCardTitle,
  CancelButton,
  ButtonWrapper,
  TitleWrapper,
} from '../../pages/products/account/components/account-shared-components';

const GENERIC_DECLINE_MSG = 'The card you are attempting to add has been declined. Please contact your bank or use another card.';

const StripeCardWrapper = styled('div')(({ theme }) => ({
  padding: '10px 24px',
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: '2px',
  display: 'inline-grid',
  width: '90%',
  marginLeft: '24px',
  [theme.breakpoints.down('md')]: {
    width: '84%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '73%',
  },
}));

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  disableLink: true,
  style: {
    base: {
      padding: '1rem',
      iconColor: '#292928',
      color: '#000000',
      fontFamily: 'Tahoma,Helvetica,Arial',
      fontSize: '16px',
      '::placeholder': {
        color: '#000000',
      },
    },
    invalid: {
      color: '#C20F1E',
    },
  },
};

export default function AddCreditCardModal({ isOpen, toggleModal }) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [isValidCC, setIsValidCC] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const stripeErrorMsg = useSelector((state) => state.account.stripe.paymentError);
  const clientSecret = useSelector((state) => state.account.stripe.clientSecret);

  const dismissModal = (ev, reason) => {
    if (reason === BACKDROP_CLICK) {
      return;
    }
    toggleModal();
    dispatch(handleStripeError({ error: null }));
    setIsValidCC(false);
  };

  const handlePaymentChange = (event) => {
    if (event.error) {
      dispatch(handleStripeError({ error: event.error.message }));
    } else dispatch(handleStripeError({ error: null }));

    if (event.complete) {
      setIsValidCC(true);
      // get client secret only if valid card is entered
      dispatch(getSetupIntentAction({ uuid: uuidv4() }))
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Stripe.js has not yet loaded.
    if (!stripe || !elements) {
      return;
    }

    setStripeLoading(true);
    await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    }).then((result) => {
      if (result.setupIntent) {
        dispatch(addCreditCardSuccess({ status: true }));
        dispatch(loadCreditCards());
        toggleModal();
        dispatch(toggleActionBanner({ toggleActionBanner: true }));
        trackCreditCardAddSuccess();
      } else {
        if (result.error) {
          dispatch(handleStripeError({ error: GENERIC_DECLINE_MSG }));
        }
        dispatch(addCreditCardSuccess({ status: false }));
      }
      setStripeLoading(false);
    });
  };

  return (
    <Modal open={isOpen} onClose={dismissModal}>
      <ModalBackground>
        <HeaderWrapper>
          <TitleWrapper>
            <CreditCardHeader> Add New Credit Card</CreditCardHeader>
          </TitleWrapper>
          <div>
            <CloseModal onClick={dismissModal}><CloseIcon /></CloseModal>
          </div>
        </HeaderWrapper>
        <ModalDivider />
        {stripeErrorMsg && (
          <StripeActionCard
            id="stripe-error-modal-action-banner"
            type={ERROR_TYPE}
            icons={ActionIcons}
          >
            <ActionDescription>{stripeErrorMsg}</ActionDescription>
          </StripeActionCard>
        )}
        <form onSubmit={handleSubmit} id="payment-form">
          <CCSectionWrapper>
            <CreditCardTitle> Credit Card </CreditCardTitle>
            <StripeCardWrapper>
              <CardElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(event) => handlePaymentChange(event)}
                onReady={(e) => e.focus()}
              />
            </StripeCardWrapper>
          </CCSectionWrapper>
          <ButtonWrapper>
            <CancelButton className="stripe-save-btn" variant="outlined" onClick={dismissModal}>
              Cancel
            </CancelButton>
            <PrimaryButton className="stripe-save-btn" variant="contained" disabled={!isValidCC || stripeLoading || !clientSecret} type="submit">
              Save
            </PrimaryButton>
          </ButtonWrapper>
        </form>
      </ModalBackground>
    </Modal>
  );
}

AddCreditCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
