import { createAction, createSlice } from "@reduxjs/toolkit";
import { 
  APPT_REMINDER_SMS,
  APPT_RESCHEDULE_CANCEL_SMS, 
  NEW_APPT_SMS, 
  NOTIFICATION_EMAILS_KEY, 
  SMS_PHONE_NUMBER_KEY, 
  SMS_UNANSWERED_NOTIFICATIONS, 
  SMS_MESSAGES_DASH,
  AUTO_REPLY_KEY,
  SMS_LEAD_NOTIFICATIONS_SMS,
  SMS_LEAD_NOTIFICATIONS_EMAIL,
} from "../settings-constants";
import { loadSettingsDataSuccess } from "../../../../shared/app-slice";

export const NOTIFICATIONS_SLICE_NAME = 'notifications';
export const SMS_NOTIFICATIONS_SECTION = 'smsNotifications';
export const SMS_NOTIFICATIONS_DASH_SECTION = 'smsDashNotifications';
export const NOTIFICATIONS_SETTINGS_SECTION = 'notificationsSettings';
export const AUTO_REPLY_SETTINGS_SECTION = 'notificationsSettings';

export const saveNotificationsAction = createAction(`${NOTIFICATIONS_SLICE_NAME}/saveNotifications`);

const initialState = {
  [SMS_NOTIFICATIONS_SECTION]: {
    [NEW_APPT_SMS]: false,
    [APPT_REMINDER_SMS]: false,
    [APPT_RESCHEDULE_CANCEL_SMS]: false,
    [SMS_MESSAGES_DASH]: false,
    [SMS_UNANSWERED_NOTIFICATIONS]: false,
    [SMS_LEAD_NOTIFICATIONS_SMS]: false,
    [SMS_LEAD_NOTIFICATIONS_EMAIL]: false,
  },
  [NOTIFICATIONS_SETTINGS_SECTION]: {
    [NOTIFICATION_EMAILS_KEY]: [],
    [SMS_PHONE_NUMBER_KEY]: [],
  },
  [AUTO_REPLY_KEY]: "",
};

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_SLICE_NAME,
  initialState,
  reducers: {
    updateSmsNotifications: (state, action) => {
      const { payload } = action;

      state[SMS_NOTIFICATIONS_SECTION][payload.fieldName] = payload.isSelected;
    },
    updateSmsDashNotifications: (state, action) => {
      const { payload } = action;

      state[SMS_NOTIFICATIONS_SECTION][payload.fieldName] = payload.isSelected;
    },
    updateEmailNotifications: (state, action) => {
      const { payload } = action;
      const emailField = state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY][payload.index];
      emailField.email = payload.value;
    },
    removeEmailNotifications: (state, action) => {
      const { payload } = action;
      const { index } = payload;

      if (index >= 0 && index < state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY].length) {
        state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY].splice(index, 1);
      }
    },
    addEmailNotifications: (state) => {
      state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY].push({ email: '' });
    },
    updateSmsPhoneNumber: (state, action) => {
      const { payload } = action;
      const phoneField = state[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY][payload.index];
      if (payload.value === null) {
        phoneField.phone = '';
      } else {
        phoneField.phone = payload.value;
      }
    },
    removeSmsPhoneNumber: (state, action) => {
      const { payload } = action;
      const { index } = payload;

      if (index >= 0 && index < state[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY].length) {
        state[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY].splice(index, 1);
      }
    },
    addSmsPhoneNumber: (state) => {
      state[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY].push({ phone: '' });
    },
    updateAutoResponseText: (state, action) => {
      state[AUTO_REPLY_KEY] = action.payload;
    },
    saveNotificationsSuccess: (_state, _action) => {},
    saveNotificationsFailure: (_state, _action) => {}
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      if (action.payload?.business?.attributes) {
        const attributes = action.payload.business.attributes;
        state[SMS_NOTIFICATIONS_SECTION][NEW_APPT_SMS] = attributes[NEW_APPT_SMS] || false;
        state[SMS_NOTIFICATIONS_SECTION][APPT_REMINDER_SMS] = attributes[APPT_REMINDER_SMS] || false;
        state[SMS_NOTIFICATIONS_SECTION][APPT_RESCHEDULE_CANCEL_SMS] = attributes[APPT_RESCHEDULE_CANCEL_SMS] || false;
        state[SMS_NOTIFICATIONS_SECTION][SMS_MESSAGES_DASH] = attributes[SMS_MESSAGES_DASH] || false;
        state[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_SMS] = attributes[SMS_LEAD_NOTIFICATIONS_SMS] || false;
        state[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_EMAIL] = attributes[SMS_LEAD_NOTIFICATIONS_EMAIL] || false;
        state[AUTO_REPLY_KEY] = attributes[AUTO_REPLY_KEY] || "";

        state[SMS_NOTIFICATIONS_SECTION][SMS_UNANSWERED_NOTIFICATIONS] = attributes[SMS_UNANSWERED_NOTIFICATIONS] || false;
        const phoneNumbers = attributes[SMS_PHONE_NUMBER_KEY] || [];

        if (attributes.leadNotifications && attributes.leadNotifications.length > 0) {
          state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = attributes.leadNotifications.map((e) => ({ email: e }));
        } else {
          // Set the current user email when the page loads for the first time
          const { user } = action.payload;
          state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = [{ email: user.email }];
        }

        if (phoneNumbers && phoneNumbers.length > 0) {
          state[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY] = phoneNumbers.map((pN) => ({ phone: pN }));
        } else {
          state[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY] = [{ phone: '' }];
        }
      } else {
        // Set the current user email when the page loads for the first time
        const { user } = action.payload;
        state[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = [{ email: user.email }];
      }
    })
  },
});

export const {
  updateSmsNotifications,
  updateEmailNotifications,
  removeEmailNotifications,
  addEmailNotifications,
  updateSmsPhoneNumber,
  addSmsPhoneNumber,
  removeSmsPhoneNumber,
  saveNotificationsSuccess,
  saveNotificationsFailure,
  updateSmsDashNotifications,
  updateAutoResponseText,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;