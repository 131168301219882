import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { loadedBookingData, resetCompleteAction } from './bookings-slice';

// Field keys. These keys need to be used both here and in the
// forms themselves to allow for easy save/load
export const ADVANCED_SETTINGS_SECTION = 'advancedSettings';

export const ALLOW_BOOKING_TO_BE_MADE_NAME_KEY = 'allowBookings';
export const POLICY_TEXT_NAME_KEY = 'policyText';

const advancedSettingsSlice = createSlice({
  name: 'advanced_settings',
  initialState: {
    [ADVANCED_SETTINGS_SECTION]: {
      [ALLOW_BOOKING_TO_BE_MADE_NAME_KEY]: null,
      [POLICY_TEXT_NAME_KEY]: null,
    },
  },
  reducers: {
    updateAdvancedSettingsField: (state, action) => {
      const { payload } = action;

      state[ADVANCED_SETTINGS_SECTION][payload.fieldName] = payload.fieldValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetCompleteAction, (state, action) => {
      const { resetData } = action.payload;

      state[ADVANCED_SETTINGS_SECTION][ALLOW_BOOKING_TO_BE_MADE_NAME_KEY] = resetData[ADVANCED_SETTINGS_SECTION][ALLOW_BOOKING_TO_BE_MADE_NAME_KEY];
      state[ADVANCED_SETTINGS_SECTION][POLICY_TEXT_NAME_KEY] = resetData[ADVANCED_SETTINGS_SECTION][POLICY_TEXT_NAME_KEY];
    });
    builder.addCase(loadedBookingData, (state, action) => {
      const { productInfo } = action.payload;

      if (productInfo && productInfo.advancedSettings) {
        state[ADVANCED_SETTINGS_SECTION] = productInfo.advancedSettings;
      }
    });
  },
});

export const {
  updateAdvancedSettingsField,
} = advancedSettingsSlice.actions;
export default advancedSettingsSlice.reducer;
