import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SectionTitle from './SectionTitle';
import { toggleChangePaymentModal, resetActionBanner, toggleCancelSubscriptionModal, toggleCancellationSalvageOfferModal, resetCancelSubscriptionModalData } from '../data/subscriptions-slice';
import SubscriptionCardList from '../components/SubscriptionCardList';
import PaymentNotification from '../components/PaymentNotification';
import { CANCEL_SUBSCRIPTION_SUCCESS, CANCELED_SUB_TYPE_STRING, CANCEL_CURRENT_DATE_REPLACEMENT_TEXT, CANCEL_END_DATE_REPLACEMENT_TEXT, CHANGE_PAYMENT_SUCCESS, CHANGE_PAYMENT_SUCCESS_DISMISS_MILLISECONDS, LOAD_SUBSCRIPTIONS_FAILED } from '../account-constants';
import { getSubscriptionsActionBanner, formatTimestampIntoMMDDYYYY } from '../account-utils';
import SectionCard from '../../../../shared/SectionCard';
import EmptyStateCard from '../../../../shared/emptyState/EmptyStateCard';
import ActionCard from '../../../../shared/ActionCard';
import ChangeCreditCardModal from '../../../../shared/modal/changeCreditCard/ChangeCreditCardModal';
import CancelSubscriptionModal from '../../../../shared/modal/cancelSubscription/CancelSubscriptionModal';
import CancellationSalvageOfferModal from '../../../../shared/modal/cancelSubscription/CancellationSalvageOfferModal';
import { ActionDescription } from '../../../../shared/settings-shared-components';

export default function Subscriptions() {
  const [actionBannerText, setActionBannerText] = useState('');
  const [actionBannerType, setActionBannerType] = useState('');
  const dispatch = useDispatch();
  const isLoadingSubscriptions = useSelector((state) => state.account.subscriptions.isLoading);
  const isPaymentModalOpen = useSelector((state) => (
    state.account.subscriptions.changePaymentModal.isOpen
  ));
  const { isSubscriptionModalOpen, isOfferModalOpen } = useSelector((state) => (
    state.account.subscriptions.cancelSubscriptionModal
  ));
  const subscriptions = useSelector((state) => state.account.subscriptions.list);
  const bannerType = useSelector((state) => state.account.subscriptions.bannerType);
  const cancelledSubType = useSelector((state) => state.account.subscriptions.cancelledSubType);
  const timestampSubCancellation = useSelector((state) => (
    state.account.subscriptions.subCanceledAtTimestamp
  ));
  const timestampEndOfBillingDate = useSelector((state) => (
    state.account.subscriptions.endOfBillingDateTimestamp
  ));
  const trials = useSelector((state) => state.account.trials);
  const hasTrials = Array.isArray(trials) && trials.length > 0;

  const hasSubscriptions = useMemo(() => (
    subscriptions.length > 0
  ), [subscriptions.length]);

  const togglePaymentModal = () => {
    dispatch(
      toggleChangePaymentModal(),
    );
  };

  const dismissActionBanner = () => {
    setActionBannerText('');
    setActionBannerType('');
    dispatch(
      resetActionBanner(),
    );
  };

  const toggleCancelSubscriptionModalHandler = () => {
    dispatch(resetCancelSubscriptionModalData());
    dispatch(toggleCancelSubscriptionModal());
  };
  
  const toggleOfferModalHandler = () => {
    dispatch(resetCancelSubscriptionModalData());
    dispatch(toggleCancellationSalvageOfferModal());
  };

  useEffect(() => {
    let dismissId;
    const { actionType, actionText } = getSubscriptionsActionBanner(bannerType);

    if (bannerType === CHANGE_PAYMENT_SUCCESS && !isLoadingSubscriptions) {
      dismissId = window.setTimeout(
        dismissActionBanner,
        CHANGE_PAYMENT_SUCCESS_DISMISS_MILLISECONDS,
      );
    }

    if (actionType && actionText) {
      setActionBannerType(actionType);
      setActionBannerText(actionText);
    }

    return () => {
      if (dismissId) {
        clearInterval(dismissId);
      }
    };
  }, [bannerType, isLoadingSubscriptions]);

  useEffect(() => {
    if (timestampSubCancellation
      && timestampEndOfBillingDate
      && bannerType === CANCEL_SUBSCRIPTION_SUCCESS
    ) {
      const { actionText } = getSubscriptionsActionBanner(bannerType);
      const canceledAt = formatTimestampIntoMMDDYYYY(timestampSubCancellation);
      const cancelAt = formatTimestampIntoMMDDYYYY(timestampEndOfBillingDate);
      const formattedText = actionText
        .replace(CANCEL_CURRENT_DATE_REPLACEMENT_TEXT, canceledAt)
        .replace(CANCEL_END_DATE_REPLACEMENT_TEXT, cancelAt)
        .replace(CANCELED_SUB_TYPE_STRING, cancelledSubType);
      setActionBannerText(formattedText);
    }
  }, [timestampSubCancellation, timestampEndOfBillingDate]);

  if (!hasSubscriptions && !hasTrials && bannerType !== LOAD_SUBSCRIPTIONS_FAILED) {
    return (
      <SectionCard title="Subscriptions" showDividingLine={false}>
        <EmptyStateCard text="This is where information about your paid subscriptions will live." />
      </SectionCard>
    );
  }

  return (
    <section>
      {(bannerType && actionBannerType && actionBannerText) && (
        <ActionCard
          id="subscriptions-action-banner"
          type={actionBannerType}
        >
          <ActionDescription>{actionBannerText}</ActionDescription>
          <Button onClick={dismissActionBanner}> Dismiss </Button>
        </ActionCard>
      )}
      <SectionTitle title="Subscriptions" />
      <PaymentNotification showCTA />
      <SubscriptionCardList subscriptions={subscriptions} trials={trials} />
      <ChangeCreditCardModal isOpen={isPaymentModalOpen} toggleModal={togglePaymentModal} />
      <CancelSubscriptionModal
        isOpen={isSubscriptionModalOpen}
        toggleModal={toggleCancelSubscriptionModalHandler}
      />
      <CancellationSalvageOfferModal
        isOpen={isOfferModalOpen}
        toggleModal={toggleOfferModalHandler}
      />
    </section>
  );
}
