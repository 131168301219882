import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/sections/iq-logo.svg';
import { ReactComponent as Blur } from '../../assets/sections/callout-blur.svg';
import { SERVICE_TITLE, SERVICE_DESCRIPTION, SERVICE_CALLOUT as SERVICES} from './data/grader-section-constants';
import { ServiceCalloutBox } from './styles/grader-section-styles';
import { PrimaryButton } from '../../../../../shared/settings-shared-components';

/**
 * Displays the callout card linking to other LocaliQ products
 */
export default function ServiceCallout({ section }) {
  const {
    [SERVICE_TITLE]: title,
    [SERVICE_DESCRIPTION]: description,
  } = SERVICES[section];

  const handleClick = () => {
    if (window.drift) {
      drift.api.startInteraction({ interactionId: 302004 });
    }
  };

  return (
    <ServiceCalloutBox>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Logo />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography className="serviceTitle">
            {title}
          </Typography>
          <Typography className="serviceCallout">
            We can help with this!
          </Typography>
          <Typography className="serviceDescription">
            {description}
          </Typography>
          <PrimaryButton className="actionButton" onClick={handleClick}>Let&#39;s Talk</PrimaryButton>
        </Grid>
      </Grid>
      <div className="backgroundBlock">
        <div className="blurWrapper">
          <Blur />
        </div>
      </div>
    </ServiceCalloutBox>
  );
}

ServiceCallout.propTypes = {
  section: PropTypes.string.isRequired,
};
