import PropTypes from 'prop-types';
import { CardHeader } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import BannerGradient from '../../images/expired-banner.svg';
import BannerGradientMobile from '../../images/expired-banner-mobile.svg';
import BookingsCTA from '../BookingsCTA';
import { ExpiredCardContent, FrameBannerCard } from '../settings-shared-components';
import { CONFIG_PATH } from '../app-constants';
import { isUserTrialEligible } from './trial-utils';
import { TRIAL_SOURCES, TRIAL_SOURCE_KEY } from '../analytics-utils';

/**
 * Banner to show when a user's trial has expired
 */
export default function TrialStartBanner({}) {
  const navigate = useNavigate();
  const trials = useSelector((state) => state.account.trials);
  const orders = useSelector((state) => state.appSettings.orders);
  const showBanner = isUserTrialEligible(trials, orders);

  //TODO: pending UX for this, may merge with Expired banner for single banner
  const handleTrial = () => {
    sessionStorage.setItem(TRIAL_SOURCE_KEY, TRIAL_SOURCES.SETTINGS);
    navigate(CONFIG_PATH);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <FrameBannerCard BannerGradient={BannerGradient} MobileBannerGradient={BannerGradientMobile}>
      <CardHeader title="Start your 30-day free trial now!" />
      <ExpiredCardContent>
        <div className="bodyWrapper">
          Activate your trial to unlock the features below and help your business thrive.
        </div>
        <div className="paymentWrapper">
          <BookingsCTA
            text="Activate Trial"
            onClickHandler={handleTrial}
          />
        </div>
      </ExpiredCardContent>
    </FrameBannerCard>
  );
}
