import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SCHEDULER_US_TIMEZONES } from '../bookings-constants';
import { PrimaryButton } from '../../../../shared/settings-shared-components';
import SchedulerSelectDropdown from '../../../../shared/SchedulerSelectDropdown';
import {
  modifyUrl,
  openNewTab,
  hasRestrictedAccess,
} from '../../../../shared/app-utils';
import TimeZoneGlobe from "../assets/timezone-globe.svg"

const SchedulerControlsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginRight: theme.spacing(2),
  height: theme.spacing(5),
  '.MuiButton-root': {
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: '#1665CF',
    fontWeight: 600,
    fontSize: 16,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'inherit',
    width: '100%',
    '.MuiButton-root': {
      order: 1,
      width: '100%',
    },
    '.MuiToggleButtonGroup-root': {
      order: 2,
    },
    '.MuiOutlinedInput-root': {
      order: 3,
    },
  }
}));

const SchedulerToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
}));

const SchedulerToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '30px',
  padding: '5px',
  '& .MuiToggleButton-root.Mui-selected': {
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.common.white}`,
  },
  '& .MuiToggleButton-root.Mui-selected: hover': {
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.common.white}`,
  },
  '.MuiButtonBase-root': {
    fontFamily: 'Unify Sans',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: theme.spacing(1, 1.5),
    border: '3px solid white',
    borderRadius: '4px',
    color: theme.palette.primary.main,
  },
  '.Mui-selected': {
    borderRadius: '28px',
    padding: '13px',
    margin: '-5px'
  },
  '.MuiButtonBase-root:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '28px',
    borderLeft: 'none'
  },
  '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '28px',
    marginRight: '0.3rem'
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignSelf: 'flex-start',
    width: '97%',
    '.MuiButtonBase-root': {
      padding: 0,
    },
  },
}));

export default function SchedulerListControlsCard({
  name, appointments, handleTimezonesOnChange, handleAppointmentTypeOnChange, hasAnyAppointments,
}) {
  const schedulerLink = useSelector((state) => state.bookings.links.main);
  const trials = useSelector((state) => state.account.trials);
  const orders = useSelector((state) => state.appSettings.orders);
  const disablePageElements = hasRestrictedAccess(trials, orders);

  return hasAnyAppointments && (
    <SchedulerControlsWrapper>
      <SchedulerSelectDropdown
        name={name}
        defaultValue={appointments.timezone || 'America/New_York'}
        startIcon={<img src={TimeZoneGlobe} alt="Timezone Globe." />}
        dropdownHeader="Display appointments in:"
        onChange={handleTimezonesOnChange}
        items={SCHEDULER_US_TIMEZONES}
      />
      <SchedulerToggleButtonGroup
        size="large"
        value={appointments.type || 'UPCOMING'}
        onChange={handleAppointmentTypeOnChange}
        exclusive
      >
        <SchedulerToggleButton value="UPCOMING">
          Upcoming
        </SchedulerToggleButton>
        <SchedulerToggleButton value="PAST">
          Past
        </SchedulerToggleButton>
      </SchedulerToggleButtonGroup>
      <PrimaryButton
        startIcon={<AddIcon />}
        disabled={!schedulerLink || disablePageElements}
        onClick={() => openNewTab(modifyUrl(schedulerLink.url, "appointments"))} >
        Add an Appointment
      </PrimaryButton>
    </SchedulerControlsWrapper>
  );
}

SchedulerListControlsCard.propTypes = {
  name: PropTypes.string,
  appointments: PropTypes.object,
  handleTimezonesOnChange: PropTypes.func,
  handleAppointmentTypeOnChange: PropTypes.func,
  hasAnyAppointments: PropTypes.bool,
};
