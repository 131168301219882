import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ScoreValue } from './styles/score-display-styles';

/**
 * Component for just displaying score/100 with a color border
 */
export default function ScoreDisplay({ score, scoreColor }) {
  return (
    <ScoreValue
      className="scoreDisplayBox"
      style={{
        borderColor: scoreColor,
      }}
    >
      <Typography className="numerator">{score}</Typography>
      <Typography className="denominator">100</Typography>
    </ScoreValue>
  );
}

ScoreDisplay.propTypes = {
  score: PropTypes.number.isRequired,
  scoreColor: PropTypes.string.isRequired,
};
