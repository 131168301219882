import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SectionCard } from '@gannettdigital/shared-react-components';
import { TablePagination, Typography, Divider, Button } from '@mui/material';
import { changeSchedulerAppointmentsType, changeSchedulerTimezone, loadSchedulerAppointments, dismissBanner, setSchedulerAppointmentsChanges } from '../data/bookings-slice';
import SchedulerListControlsCard from '../cards/SchedulerListControlsCard';
import AppointmentCard from '../cards/AppointmentCard';
import AppointmentListSkeleton from '../cards/components/AppointmentListSkeleton';
import SchedulerContentSkeleton from '../cards/components/SchedulerContentSkeleton';
import { timezoneAbbrLookup } from '../../../../shared/app-utils';
import BannerCard from '../cards/BannerCard';
import SchedulerListEmptyCard from '../cards/SchedulerListEmptyCard';
import ActionCard from '../../../../shared/ActionCard';
import { ActionDescription } from '../../../../shared/settings-shared-components';
import { ERROR_TYPE, INFO_TYPE, SUPPORT_EMAIL } from '../../../../shared/app-constants';

const SchedulerListWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const SchedulerTablePagination = styled(TablePagination)(({ theme }) => ({
  marginTop: theme.spacing(10),
  '.MuiToolbar-root .MuiInputBase-root': {
    color: theme.palette.primary.main,
    ' .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    }
  },
  '.MuiToolbar-root .MuiTablePagination-actions .MuiButtonBase-root': {
    color: '#1665CF',
  },
  '.MuiToolbar-root .MuiTablePagination-actions .Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26);',
  },
  '.MuiToolbar-root .MuiTablePagination-selectLabel': {
    color: theme.palette.common.black,
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0),
    '.MuiToolbar-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const SchedulerTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginTop: "-.2rem",
  gap: theme.spacing(2),
  color: theme.palette.common.black,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const SchedulerDividerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  margin: theme.spacing(1.5, 0),
  paddingLeft: theme.spacing(2),
  '.MuiTypography-root': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: theme.spacing(2),
    color: '#404040',
  },
  '.MuiDivider-root': {
    flex: 2,
    marginLeft: theme.spacing(1),
  },

  [theme.breakpoints.down('md')]: {
    '.MuiTypography-root': {
      fontSize: 12,
      flex: 1,
      marginLeft: 0,
    },
    '.MuiDivider-root': {
      display: 'none',
    },
  },
}));

const TIMEZONE_KEY = 'timezone';
const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];

const alertTypes = {
  RESCHEDULE: 'rescheduled',
  CANCEL: 'cancelled',
};

export default function SchedulerAppointmentsList({ handleOpenSettings }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);
  const appointments = useSelector((state) => state.bookings.appointments);
  const links = useSelector((state) => state.bookings.links);
  const toggle = useSelector((state) => state.bookings.toggle);
  const newAlert = useSelector((state) => state.bookings.alerts);

  const showSchedulerList = !appointments.hasError && appointments.hasAnyAppointments;
  const showSchedulerEmptyState = !appointments.hasError && !showSchedulerList;
  const showSchedulerChangesBanner = appointments.hasChanges;
  const appointmentAlertType = alertTypes[newAlert?.type];

  const handlePageChange = useCallback((_e, newPage) => {
    dispatch(
      loadSchedulerAppointments({
        page: newPage,
        pageSize: rowsPerPage,
        filter: appointments.type,
      }),
    );
    setPage(newPage);
  }, [setPage, appointments]);

  const handleRowsPerPageChange = useCallback((e) => {
    const pageSize = Number(e.target.value);
    dispatch(
      loadSchedulerAppointments({
        page: 0,
        pageSize,
        filter: appointments.type,
      }),
    );
    setRowsPerPage(pageSize);
    setPage(0);
  }, [setPage, setRowsPerPage, loadSchedulerAppointments, page, rowsPerPage, appointments]);

  const handleAppointmentTypeOnChange = useCallback((e) => {
    const newAppointmentType = e.target.value;
    dispatch(
      loadSchedulerAppointments({
        page,
        pageSize: rowsPerPage,
        filter: newAppointmentType,
      }),
    );
    dispatch(
      changeSchedulerAppointmentsType({
        type: newAppointmentType,
      }),
    );
  }, [changeSchedulerAppointmentsType, loadSchedulerAppointments]);

  const dismissSaveBanner = () => {
    dispatch(dismissBanner());
  };

  const handleTimezonesOnChange = useCallback((e) => {
    const timezone = e.target.value;
    dispatch(
      changeSchedulerTimezone({ timezone }),
    );
  }, [appointments]);

  const handleRefreshOnClick = () => {
    dispatch(
      loadSchedulerAppointments({
        page: page,
        pageSize: rowsPerPage,
        filter: appointments.type,
      })
    );
    dispatch(
      setSchedulerAppointmentsChanges({ hasChanges: false })
    );
  };

  useEffect(() => {
    // Call dismiss when we have an alert and it isn't read
    if (newAlert && Array.isArray(newAlert.listOfAlertIDs)
      && newAlert.length > 0 && newAlert.isRead) {
      dispatch(dismissBanner());
    }
  }, [newAlert, window.location.pathname]);

  if (!loadedSettings) {
    return (
      <SchedulerContentSkeleton />
    );
  }

  if (appointments.isLoading) {
    return (
      <AppointmentListSkeleton />
    );
  }

  return (
    <>
      <BannerCard
        id="scheduler-list-error-banner"
        title="Cannot connect to service."
        description={`Please refresh your page. If the issue persists, contact ${SUPPORT_EMAIL}`}
        showBanner={appointments.hasError || links.hasError || toggle.hasError}
        bannerType={ERROR_TYPE}
      />
      <BannerCard
        id="scheduler-list-appt-changed-banner"
        title="You have recent changes to your appointments"
        description={newAlert.alertDetails}
        showBanner={!newAlert.isRead && Boolean(appointmentAlertType)}
        bannerType={INFO_TYPE}
        dismissBanner={dismissSaveBanner}
      />
      {
        showSchedulerChangesBanner && (
          <ActionCard
            id="scheduler-refresh-banner"
            type={INFO_TYPE}
          >
            <ActionDescription>Your appointments have changed. Please refresh to see the updates.</ActionDescription>
            <Button onClick={handleRefreshOnClick}> Refresh </Button>
          </ActionCard>
        )
      }
      <SectionCard
        id="scheduler-appointment-list"
        shouldHaveLabelTooltip={false}
        title={(
          <SchedulerTitleWrapper>
            Appointments
            <SchedulerListControlsCard
              name={TIMEZONE_KEY}
              appointments={appointments}
              handleTimezonesOnChange={handleTimezonesOnChange}
              handleAppointmentTypeOnChange={handleAppointmentTypeOnChange}
              hasAnyAppointments={appointments.hasAnyAppointments}
            />
          </SchedulerTitleWrapper>
        )}
        showDividingLine={false}
      >
        {
          showSchedulerList && (
            <div style={{ marginBottom: '3rem' }}>
              <SchedulerListWrapper>
                {
                  appointments.content && (
                    Object.entries(appointments.content)
                      .map(([date, appts]) => (
                        <div key={date}>
                          <SchedulerDividerWrapper>
                            <Typography>{date}</Typography>
                            <Divider />
                          </SchedulerDividerWrapper>
                          {
                            appts.map((el) => (
                              <AppointmentCard
                                key={el.created}
                                name={`${el.name}`}
                                date={el.appointmentStartDate}
                                startTime={el.startTime}
                                endTime={el.endTime}
                                timezone={timezoneAbbrLookup(appointments.timezone) || "EST"}
                                duration={el.appointmentType.duration}
                                appointmentName={el.appointmentType.name}
                                appointmentType={el.appointmentType.locationType.icon}
                                unread={!el.checked}
                                status={el.status}
                                bookingID={el.id}
                                appointmentToken={el.token}
                              />
                            ))
                          }
                        </div>
                      ))
                  )
                }
              </SchedulerListWrapper>
              {
                !appointments.unformatted?.length && (
                  <SchedulerListEmptyCard
                    handleOpenSettings={handleOpenSettings}
                  />
                )
              }
              <SchedulerTablePagination
                component="div"
                count={appointments.totalElements || 0}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                showFirstButton
                showLastButton
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </div>
          )
        }
        {
          showSchedulerEmptyState && (
            <SchedulerListEmptyCard
              handleOpenSettings={handleOpenSettings} />
          )
        }
      </SectionCard>
    </>
  );
}

SchedulerAppointmentsList.propTypes = {
  handleOpenSettings: PropTypes.func.isRequired,
};
