import styled from '@emotion/styled';

export const StepWrapper = styled('div')(({ theme, desktopFlexDirection, gapBetweenItems }) => ({
  display: 'flex',
  flexDirection: desktopFlexDirection,
  gap: theme.spacing(gapBetweenItems),
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    flexGrow: 1,
  }
}));