import PropTypes from 'prop-types';
import { StyledButton } from './settings-shared-components';

export default function BookingsCTA({
  text, onClickHandler, startIcon, endIcon, disabled, variant = 'outlined', fullWidth, style, id
}) {
  const styledBtnProps = {
    startIcon: startIcon !== null ? startIcon : null,
    endIcon: endIcon !== null ? endIcon : null,
    sx: { height: '40px' },
  };

  return (
    <StyledButton
      variant={variant}
      onClick={onClickHandler}
      disabled={disabled}
      fullWidth={fullWidth}
      style={style}
      id={id}
      {...styledBtnProps}
    >
      {text}
    </StyledButton>
  );
}

BookingsCTA.propTypes = {
  text: PropTypes.string,
  onClickHandler: PropTypes.func,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
};
