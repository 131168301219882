import { useEffect } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSpring, animated, useSpringRef } from '@react-spring/web';
import { determineScoreObject } from '../../grader-utils';
import { ScoreValue } from './styles/score-display-styles';

/**
 * Animated component for displaying score/100 with a color border
 */
export default function AnimatedScoreDisplay({ score }) {
  const springApi = useSpringRef();
  const { number } = useSpring({
    ref: springApi,
    config: { mass: 1, tension: 20, friction: 10 },
    from: { number: 0 },
    number: score,
    delay: 10,
  });

  useEffect(() => {
    if (score) {
      springApi.call();
    }
  }, [score]);

  return (
    <ScoreValue
      className="scoreDisplayBox"
      style={{
        borderColor: number.to((n) => determineScoreObject(n.toFixed(0)).color)
      }}
    >
      <Typography className="numerator">
        <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
      </Typography>
      <Typography className="denominator">100</Typography>
    </ScoreValue>
  );
}

AnimatedScoreDisplay.propTypes = {
  score: PropTypes.number.isRequired,
};
