import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import SettingsDialog from '../../../layouts/dialogs/SettingsDialog';
import AccountNav from '../../../layouts/navBars/AccountNav';
import SplitColumnLayout from '../../../layouts/SplitColumnLayout';
import { displayCalc } from '../../../shared/app-utils';
import BusinessDetails from './sections/BusinessDetails';
import Invoices from './sections/Invoices';
import Subscriptions from './sections/Subscriptions';
import CreditCards from './sections/CreditCards';
import { MY_ACCOUNT_HEADER_TEXT } from './account-constants';
import {
  ACCOUNT_NAV_STEPS,
  BUSINESS_DETAILS_PAGE,
  convertProductToNav,
  CREDIT_CARDS_PAGE,
  INVOICES_PAGE,
  SUBSCRIPTIONS_PAGE,
} from '../../../shared/navigation/navigation-config';
import { SplitColumn } from '../../../shared/settings-shared-components';
import { ACCOUNT_PATH, ACCOUNT_SLUG } from '../../../shared/app-constants';
import { setCurrentScreen } from '../../../shared/navigation/navigation-slice';
import AccountSaveCancelWidget from './AccountSaveCancelWidget';
import CancelModal from '../../../shared/modal/CancelModal';
import { getListofErrorsAction } from './data/credit-cards-slice';
import {
  cancelSave,
  closeWarningModal,
  triggerResetAction,
} from './data/account-slice';
import DialogSkeleton from './components/skeletons/DialogSkeleton';

export default function AccountSettingsDialog({
  onClose,
  currentScreen,
  isLoading,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const showWarningModal = useSelector(
    (state) => state.account.showWarningModal
  );
  const isDirty = useSelector((state) => state.account.isDirty);
  const accountNav = convertProductToNav(ACCOUNT_SLUG);
  const navItems = useMemo(
    () =>
      accountNav.map((navKey) => {
        const lookupStep = ACCOUNT_NAV_STEPS[navKey];
        return {
          key: lookupStep.key,
          text: lookupStep.display,
          onClick: () => {
            dispatch(setCurrentScreen({ currentScreen: navKey }));
            navigate(`${ACCOUNT_PATH}/${navKey}`);
            scrollRef.current.scroll({ top: 0 });
          },
        };
      }),
    [accountNav]
  );
  const userEmail = useSelector((state) => state.appSettings.user?.email);

  const handleProceed = (proceed) => {
    if (proceed) {
      onClose();
    }
  };

  // Callback fired when closed with X button
  const handleClose = useCallback(() => {
    if (isDirty) {
      dispatch(cancelSave());
    } else {
      onClose();
    }
  });

  useEffect(() => {
    if (userEmail) {
      dispatch(getListofErrorsAction())
    }
  }, [userEmail]);

  return (
    <SettingsDialog
      open
      header={MY_ACCOUNT_HEADER_TEXT}
      onClose={handleClose}
      customHeader={<AccountSaveCancelWidget currentScreen={currentScreen} />}
    >
      <Helmet>
        <title>
          Settings | LocaliQ
        </title>
      </Helmet>
        <>
          <SplitColumnLayout
            leftSideContent={
              <AccountNav navItems={navItems} currentScreen={currentScreen} />
            }
            rightSideContent={
              isLoading ? (
                <DialogSkeleton currentScreen={currentScreen} isLoading={isLoading} />
              ) : (
                <SplitColumn>
                  {currentScreen === BUSINESS_DETAILS_PAGE && (
                    <BusinessDetails />
                  )}
                  {currentScreen === SUBSCRIPTIONS_PAGE && (
                    <Subscriptions />
                  )}
                  {currentScreen === INVOICES_PAGE && (
                    <Invoices />
                  )}
                  {currentScreen === CREDIT_CARDS_PAGE && (
                    <CreditCards />
                  )}
                </SplitColumn>
              )
            }
            rightContentRef={scrollRef}
          />
          <CancelModal
            closeWarningModal={closeWarningModal}
            triggerResetAction={triggerResetAction}
            isOpen={showWarningModal}
            proceedCallback={handleProceed}
          />
        </>
    </SettingsDialog>
  );
}

AccountSettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentScreen: PropTypes.string,
};
