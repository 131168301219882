import PropTypes from 'prop-types';
import { Card, CardActions, Typography, Button } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { CARD_BRAND_TO_ICON_MAP, CARD_SUBSCRIPTION_TO_ICON_MAP } from '../../account-constants';
import { ReactComponent as EditCardIcon } from '../../assets/edit-card-icon.svg';
import { ReactComponent as DeleteCardIcon } from '../../assets/delete-card-icon.svg';
import { ReactComponent as ExpiredCardWarningIcon } from '../../assets/expired-card-warning-icon.svg';
import { getEditCardInfo, toggleEditModal, toggleDeleteModal, getDeletePaymentId } from '../../data/credit-cards-slice';
import { trackCreditCardAction } from '../../../../../shared/analytics-utils';
import { ReactComponent as ErrorIcon } from "../../../../../images/error-icon.svg";

const CardWrapper = styled(Card)(({ theme, error }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '250px',
  width: '343px',
  gap: theme.spacing(1),
  padding: theme.spacing(3, 2),
  borderRadius: theme.spacing(1),
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.white,
  border: `1px solid ${error ? theme.palette.warning.main : theme.palette.info.dark}`,
  [theme.breakpoints.down('md')]: {
    flex: 'unset',
    width: 'unset',
    maxWidth: '343px',
  }
}));

const CardTypeWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '> svg': {
    width: '52px',
    height: '32px',
  }
}));

const CustomButton = styled(Button)(() => ({
  textDecoration: 'underline',
}));

const CardLastDigitsWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  fontSize: '24px',
  lineHeight: '30px',
}));

export const CardDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '7px',
  height: '7px',
  backgroundColor: theme.palette.info.dark,
  borderRadius: '50%',
}));

const ExpiredCard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.error.main,
}));

const CardSubscriptionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const CardSubscriptionsHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  color: theme.palette.info.dark,
  marginTop: theme.spacing(2),
}));

const CardSubscription = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  '> svg': {
    width: '20px',
    height: '20px',
  },
  '> p': {
    fontWeight: '18px',
    marginTop: `-3px`,
  }
}));

const CardActionsWrapper = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  marginTop: 'auto',
}));

const CardAction = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ButtonText = styled(Typography)(() => ({
  fontSize: 14,
  display: 'flex',
  alignItems: 'center'
}));

export default function CreditCardCard({ brand, last4, expMonth, expYear, subscriptions, expired, hasError, ...props }) {
  const CardIcon = CARD_BRAND_TO_ICON_MAP[brand]
    ? CARD_BRAND_TO_ICON_MAP[brand]
    : <CreditCardIcon />;
  const hasActiveSubscriptions = subscriptions?.length > 0;
  const expMonthDisplay = expMonth?.padStart(2, '0');

  const dispatch = useDispatch();

  function editCard() {
    trackCreditCardAction("edit CC")
    dispatch(getEditCardInfo({
      cardNumber: last4,
      cardMonth: expMonthDisplay,
      cardYear: expYear,
      paymentId: props.paymentMethodId
    }))
    dispatch(toggleEditModal({ toggleEditModal: true }));
  }

  function deleteCard() {
    trackCreditCardAction("delete CC")
    dispatch(getDeletePaymentId({
      paymentId: props.paymentMethodId,
    }))
    dispatch(toggleDeleteModal({ toggleDeleteModal: true }))
  }

  return (
    <CardWrapper error={hasError}>
      <CardTypeWrapper>
        {CardIcon}
        <CardLastDigitsWrapper>
          <CardDot />
          <CardDot />
          <CardDot />
          <CardDot />
          {last4}
        </CardLastDigitsWrapper>
        {hasError && <ErrorIcon style={{ marginLeft: 'auto' }} />}
      </CardTypeWrapper>
      <div>
        {
          expired ? (
            <ExpiredCard>
              <ExpiredCardWarningIcon />
              <Typography variant='body1'>Expired {expMonthDisplay}/{expYear}</Typography>
            </ExpiredCard>
          ) : (
            <Typography variant='body1'>Expires {expMonthDisplay}/{expYear}</Typography>
          )
        }
      </div>
      {
        subscriptions && subscriptions.length > 0 && (
          <CardSubscriptionsWrapper>
            <CardSubscriptionsHeader>Used For:</CardSubscriptionsHeader>
            {
              subscriptions.map((sub) => (
                <CardSubscription key={sub.id}>
                  {CARD_SUBSCRIPTION_TO_ICON_MAP[sub.slug]}
                  <Typography variant='body1'>{sub.displayName}</Typography>
                </CardSubscription>
              ))
            }
          </CardSubscriptionsWrapper>
        )
      }
      <CardActionsWrapper>
        <CardAction>
          <CustomButton onClick={editCard}><ButtonText variant='body1'> <EditCardIcon style={{ marginRight: '.5rem' }} /> Edit </ButtonText></CustomButton>
        </CardAction>
        {
          !hasActiveSubscriptions && (
            <CardAction>
              <CustomButton onClick={deleteCard}><ButtonText variant='body1'> <DeleteCardIcon style={{ marginRight: '.5rem' }} /> Delete </ButtonText></CustomButton>
            </CardAction>
          )
        }
      </CardActionsWrapper>
    </CardWrapper>
  )
}

CreditCardCard.propTypes = {
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  expMonth: PropTypes.string,
  expYear: PropTypes.string,
};