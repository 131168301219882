import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  retry
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { tracker } from '@gannettdigital/localiq-dms-analytics-tracker';
import {
  dismissBanner,
  loadSharedAlert,
  loadSchedulerAppointments,
  loadSchedulerAppointmentsFailure,
  loadSchedulerAppointmentsSuccess,
  loadSchedulerLinksAction,
  loadSchedulerLinksFailure,
  loadSchedulerLinksSuccess,
  resetCompleteAction,
  saveBookingsAction,
  saveBookingsFailure,
  saveBookingsSuccess,
  toggleBookingsStatusAction,
  toggleBookingsStatusFailure,
  toggleBookingsStatusSuccess,
  sharedAlertsFailure,
  loadSchedulerAllAppointmentsAction,
  loadSchedulerAllAppointmentsSuccess,
} from './bookings-slice';
import { SAVE_CONFIGURATION } from '../bookings-config';
import { constructBody } from '../../../../shared/app-utils';
import { SCHEDULER_SLUG } from '../../../../shared/app-constants';
import { triggerResetAction } from '../../../../redux/shared-actions';
import { setEnabledProducts, trackActivatedProduct } from '../../../../shared/analytics-utils';
import { emptyAction } from '../../../../shared/app-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_GATEWAY_CALENDAR_ROOT,
} = process.env;

const SAVE_KEYS = Object.keys(SAVE_CONFIGURATION);

/**
 * Wrap the general body construction with booking specific keys and config
 * @param {Object} stateObj
 * @returns Object
 */
const constructBookingBody = (stateObj) => (
  constructBody(SAVE_KEYS, SAVE_CONFIGURATION, stateObj)
)

const saveBookingsEpic = (action$, state$) => action$.pipe(
  filter(saveBookingsAction.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${SCHEDULER_SLUG}/${action.payload.tier}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Mixpanel-ID': tracker.getTrackingId(),
      },
      body: constructBookingBody(state$.value),
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => saveBookingsSuccess(response.response)),
    catchError((error) => (
      of(saveBookingsFailure(error))
    )),
  )),
);

const loadSchedulerLinksAfterSavingEpic = (action$) => action$.pipe(
  filter(saveBookingsSuccess.match),
  map(() => loadSchedulerLinksAction()),
);

const toggleBookingsStatusEpic = (action$, state$) => action$.pipe(
  filter(toggleBookingsStatusAction.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${SCHEDULER_SLUG}/${state$.value.bookings.tier}/active/${action.payload.isActive}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => {
      if (response.response.active) {
        trackActivatedProduct(
          SCHEDULER_SLUG,
          state$.value.bookings.tier,
          state$.value.bookings.firstActivationDate,
        );
      }
      setEnabledProducts(SCHEDULER_SLUG, state$.value.bookings.tier, response.response.active);
      return toggleBookingsStatusSuccess(response.response);
    }),
    catchError((error) => (
      of(toggleBookingsStatusFailure(error))
    )),
  )),
);

const loadSchedulerLinksAfterToggleEpic = (action$) => action$.pipe(
  filter(toggleBookingsStatusSuccess.match),
  filter((action) => action.payload.active),
  map(() => loadSchedulerLinksAction()),
);

const loadSchedulerAppointmentsEpic = (action$, state$) => action$.pipe(
  filter(loadSchedulerAppointments.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_CALENDAR_ROOT}calendar/appointments?onlyActive=false&`
       + `page=${action.payload.page}&size=${action.payload.pageSize}&identifier=${state$.value.bookings.identifier}&sort=appointmentStartDate%2Casc&`
       + `filter=${action.payload.filter}`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    retry({count: 3, delay: 5 * 1000}),
    map(response => loadSchedulerAppointmentsSuccess(response.response)),
    catchError((error) => (
      of(loadSchedulerAppointmentsFailure(error))
    )),
  )),
);

const loadSchedulerAllAppointmentsEpic = (action$, state$) => action$.pipe(
  filter(loadSchedulerAllAppointmentsAction.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_CALENDAR_ROOT}calendar/appointments?onlyActive=false&`
       + `page=0&size=10&identifier=${state$.value.bookings.identifier}&sort=appointmentStartDate%2Casc&`
       + `filter=ALL`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    retry({count: 3, delay: 5 * 1000}),
    map(response => loadSchedulerAllAppointmentsSuccess(response.response)),
    catchError((error) => (
      of(loadSchedulerAppointmentsFailure(error))
    )),
  )),
);

// Shows a list of cancelled or rescheduled appointments
const loadSharedAlerts = (action$, state$) => action$.pipe(
  filter(loadSchedulerAppointments.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}shared/alerts/${SCHEDULER_SLUG}/${state$.value.bookings.identifier}/list?group=Appointment`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => loadSharedAlert(response.response)),
    catchError((error) => (
      of(sharedAlertsFailure(error))
    )),
  )),
);

const dismissSharedAlerts = (action$, state$) => action$.pipe(
  filter(dismissBanner.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}shared/alerts/read`,
      method: 'PUT',
      body: state$.value.bookings.alerts.listOfAlertIDs,
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map(() => emptyAction()),
    catchError((error) => (
      of(sharedAlertsFailure(error))
    )),
  )),
);

const loadSchedulerLinksEpic = (action$, state$) => action$.pipe(
  filter(loadSchedulerLinksAction.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_CALENDAR_ROOT}calendar/settings/urls?code=${state$.value.bookings.identifier}`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    retry({count: 3, delay: 5 * 1000}),
    map((response) => loadSchedulerLinksSuccess(response.response)),
    catchError((error) => (
      of(loadSchedulerLinksFailure(error))
    )),
  )),
);

// Repack the reset action with a piece of state
const resetActionEpic = (action$, state$) => action$.pipe(
  filter(triggerResetAction.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.bookings.resetProductInfo,
    })
  )),
);

const saveSuccessEpic = (action$, state$) => action$.pipe(
  filter(saveBookingsSuccess.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.bookings.resetProductInfo,
    })
  )),
);

export default [
  loadSharedAlerts,
  saveBookingsEpic,
  resetActionEpic,
  toggleBookingsStatusEpic,
  dismissSharedAlerts,
  loadSchedulerAppointmentsEpic,
  loadSchedulerAllAppointmentsEpic,
  loadSchedulerLinksEpic,
  loadSchedulerLinksAfterSavingEpic,
  loadSchedulerLinksAfterToggleEpic,
  saveSuccessEpic,
];
