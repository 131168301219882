import TagManager from 'react-gtm-module';

/**
 * Initializes the tag manager tracking platform
 */
export const initializeGTM = () => {
  // Google Analytics
  const tagManagerArgs = {
    gtmId: 'GTM-W9SLPDB',
    events: {
      gaTriggerEvent: 'gaTriggerEvent',
    },
    dataLayerName: 'Freemium',
  };

  TagManager.initialize(tagManagerArgs);
};
