import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { useMountEffect } from '../../../shared/mountEffectHook/useMountEffect';
import { requestNewGraderLanding } from './data/grader-slice';
import { GRADER_PATH } from '../../../shared/app-constants';
import { isUserAuthenticated } from '../../../shared/app-utils';

const {
  REACT_APP_ACCOUNT_SERVICES_UI_ROOT,
  REACT_APP_MANAGEMENT_UI_ROOT,
} = process.env;

const LoaderWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}));

/**
 * Website grader redirect page
 */
export default function GraderRedirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectResponded = useSelector((state) => state.grader.redirectResponded);

  useMountEffect(() => {
    // Pull URL and email and kick off grader
    const paramEmail = searchParams.get('email');
    const paramUrl = searchParams.get('url');

    if (paramEmail && paramUrl) {
      dispatch(requestNewGraderLanding({
        email: paramEmail,
        websiteUrl: paramUrl,
      }));
    }
  });

  useEffect(() => {
    if (redirectResponded) {
      const userAuthed = isUserAuthenticated();
      if (userAuthed) {
        navigate({
          pathname: GRADER_PATH,
        });
      } else {
        const existingSearchParams = window.location.search;
        const returnTo = `${REACT_APP_MANAGEMENT_UI_ROOT}${GRADER_PATH.replace('/', '')}`;
        const returnToUrl = `${REACT_APP_ACCOUNT_SERVICES_UI_ROOT}register${existingSearchParams}&template=grader&return_to=${returnTo}`;
        window.location.replace(returnToUrl);
      }
    }
  }, [redirectResponded]);

  return (
    <LoaderWrapper>
      <IQLoadingSpinner size={150} />
    </LoaderWrapper>
  );
}
