import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledRowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledRowTopWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
}));

const StyledRowBottomWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledFirstCellTopWrapper = styled('div')(() => ({
  width: '100%',
}));

const StyledSecondCellTopWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledFirstCellBottomWrapper = styled('div')(() => ({}));

const SkeletonCircular = styled(Skeleton)(({ bgcolor = '#0000000A' }) => ({
  '&.MuiSkeleton-circular': {
    background: bgcolor,
  },
}));

export default function SkeletonRow({ isSecondRow }) {
  return (
    <StyledRowWrapper>
      <StyledRowTopWrapper>
        <StyledFirstCellTopWrapper>
          <SkeletonText animation="wave" variant="string" width="100%" height={30} />
        </StyledFirstCellTopWrapper>
        <StyledSecondCellTopWrapper>
          <SkeletonText animation="wave" variant="string" width={isSecondRow ? 83 : 23} height={30} />
          <SkeletonCircular animation="wave" variant="circular" width={20} height={20} />
        </StyledSecondCellTopWrapper>
      </StyledRowTopWrapper>
      <StyledRowBottomWrapper>
        <StyledFirstCellBottomWrapper>
          <SkeletonText animation="wave" variant="string" width={123} height={24} />
        </StyledFirstCellBottomWrapper>
      </StyledRowBottomWrapper>
    </StyledRowWrapper>
  );
}

SkeletonRow.propTypes = {
  isSecondRow: PropTypes.bool,
};
