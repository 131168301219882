import { CHATBOT_PAGE } from '../../../shared/navigation/navigation-config';
import SectionLayout from '../../../shared/section/SectionLayout';
import ChatbotCustomQuestionsCard from '../../products/chatbot/cards/general-settings/ChatbotCustomQuestionsCard';
import ChatbotNameCard from '../../products/chatbot/cards/general-settings/ChatbotNameCard';
import ChatbotToneCard from '../../products/chatbot/cards/general-settings/ChatbotToneCard';
import ChatbotVisitorContactCard from '../../products/chatbot/cards/general-settings/ChatbotVisitorCard';
import ConnectToSchedulingCard from '../../products/chatbot/cards/general-settings/ConnectToSchedulingCard';
import WithDisabled from '../components/WithDisabled';

export default function ChatbotToolsPage({ isDisabled }) {
  return (
    <>
      <SectionLayout
        headerText="Chatbot"
        headerDescription="The LocaliQ Chatbot allows you to provide answers to customer questions and collect customer information at any time, day or night."
        name={CHATBOT_PAGE}
      />
      <WithDisabled isDisabled={isDisabled}>
        <ChatbotNameCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <ChatbotToneCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <ChatbotVisitorContactCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <ChatbotCustomQuestionsCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <ConnectToSchedulingCard />
      </WithDisabled>
    </>
  );
}
