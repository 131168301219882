import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Card } from '@mui/material';
import SectionButton from './components/SectionButton';
import { DIGITAL_ADVERTISING, PRESENCE, WEBSITE_QUALITY } from './data/grader-constants';
import { setActiveSection } from './data/grader-slice';
import SectionHeader from './components/GraderSection/SectionHeader';
import ScoreDetails from './components/GraderSection/ScoreDetails';

const Body = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),

  '& .mainCard': {
    borderRadius: 8,
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: 'none',
    '& .sectionWrapper': {
      position: 'relative',
      display: 'flex',
      width: 'calc(100% - 48px)',
      margin: 'auto',
      borderBottom: '1px solid #000',

      '& .scoreTitle': {
        fontSize: 24,
        fontFamily: 'Unify Sans SemiBold',
        textWrap: 'wrap',
        lineHeight: '1.09375',
        letterSpacing: '-1.5px',
        textAlign: 'left',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
          letterSpacing: '-1px',
          marginLeft: 0,
          textAlign: 'center',
          marginTop: theme.spacing(1),
        },
      },
    },
  },
}));

/**
 * Component containing the 3 section body layout + contents
 */
export default function GraderBody({ categories }) {
  const dispatch = useDispatch();
  const activeSection = useSelector((state) => state.grader.activeSection);
  const websiteScore = categories[WEBSITE_QUALITY];
  const presenceScore = categories[PRESENCE];
  const advertisingScore = categories[DIGITAL_ADVERTISING];
  const [activeScore, setActiveScore] = useState(websiteScore);

  const handleClick = (section) => {
    dispatch(setActiveSection({
      section,
    }));
  };

  useEffect(() => {
    if (activeSection === WEBSITE_QUALITY) {
      setActiveScore(websiteScore);
    } else if (activeSection === PRESENCE) {
      setActiveScore(presenceScore);
    } else if (activeSection === DIGITAL_ADVERTISING) {
      setActiveScore(advertisingScore);
    }
  }, [activeSection]);

  return (
    <Body>
      <Card className="mainCard">
        <Box className="sectionWrapper">
          <SectionButton
            score={websiteScore}
            isSelected={activeSection === WEBSITE_QUALITY}
            metricCount={14}
            onClick={() => handleClick(WEBSITE_QUALITY)}
            title={(
              <Box className="scoreTitle" style={{ maxWidth: '125px' }}>
                Website Quality
              </Box>
            )}
          />
          <SectionButton
            score={presenceScore}
            metricCount={3}
            isSelected={activeSection === PRESENCE}
            onClick={() => handleClick(PRESENCE)}
            title={(
              <Box className="scoreTitle" style={{ maxWidth: '195px' }}>
                Social & Local Presence
              </Box>
            )}
          />
          <SectionButton
            score={advertisingScore}
            metricCount={3}
            isSelected={activeSection === DIGITAL_ADVERTISING}
            onClick={() => handleClick(DIGITAL_ADVERTISING)}
            title={(
              <Box className="scoreTitle" style={{ maxWidth: '172px' }}>
                Digital Advertising
              </Box>
            )}
          />
        </Box>
        <Box>
          <SectionHeader section={activeSection} score={activeScore} />
          <ScoreDetails section={activeSection} />
        </Box>
      </Card>
    </Body>
  );
}

GraderBody.propTypes = {
  categories: PropTypes.object.isRequired,
};
