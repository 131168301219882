import { createSlice } from '@reduxjs/toolkit';
import combineSlices from '../../../../redux/combine-slices';
import { USER_ADMIN_ROLE } from '../../../../shared/admin-constants';
import adminBusinessSlice from './admin-business-slice';
import adminChatSlice from './admin-chat-slice';
import adminDataSlice from './admin-data-slice';
import adminCallSlice from './admin-call-slice';
import adminAppointmentsSlice from './admin-appointments-slice';

const initialState = {
  isAdminUser: false,
  isLoading: true,
  showError: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    verifyAdminUser: (state) => {
      state.isLoading = true;
    },
    verifyAdminUserSuccess: (state, action) => {
      const { userRoles } = action.payload;
      if (Array.isArray(userRoles) && userRoles.indexOf(USER_ADMIN_ROLE) > -1) {
        state.isAdminUser = true;
      } else {
        state.showError = true;
      }
      state.isLoading = false;
    },
    verifyAdminUserFailure: (state, action) => {
      state.isLoading = false;
      state.showError = true;
    },
  },
});

export const {
  verifyAdminUser,
  verifyAdminUserSuccess,
  verifyAdminUserFailure,
} = adminSlice.actions;

export default combineSlices(adminSlice.reducer, initialState, {
  [adminBusinessSlice.name]: adminBusinessSlice.reducer,
  [adminChatSlice.name]: adminChatSlice.reducer,
  [adminDataSlice.name]: adminDataSlice.reducer,
  [adminCallSlice.name]: adminCallSlice.reducer,
  [adminAppointmentsSlice.name]: adminAppointmentsSlice.reducer,
});
