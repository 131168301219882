import styled from "@emotion/styled";

export const DisabledWrapper = styled('div')(() => ({
  pointerEvents: 'none',
  opacity: 0.5,
}));

const WithDisabled = ({ isDisabled, children }) => (isDisabled ?
  <DisabledWrapper>
    {children}
  </DisabledWrapper>
  : children
);

export default WithDisabled;