import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { WIDGET_SETTINGS_PATH } from '../app-constants';
import BookingsCTA from '../BookingsCTA';
import ViewCodeInstructionsGradient from '../../images/view-code-instructions-gradient.svg';
import { ReactComponent as TrackingCodeSvg } from '../../images/tracking-code.svg';
import { trackViewInstructionsClicked } from '../analytics-utils';

const ViewCodeInstructionsCardWrapper = styled(Card)(({ theme }) => ({
  maxWidth: 288,
  boxShadow: 'none',
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: '8px',
  background: `url(${ViewCodeInstructionsGradient})`,

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
    backgroundPosition: 'right',
    marginTop: theme.spacing(2),
  }
}));

const ViewCodeInstructionsCardHeader = styled(CardHeader)(({ theme }) => ({
  color: 'white',
  padding: theme.spacing(1, 1, 0, 1),
}));

const ViewCodeInstructionsCardContentWrapper = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  '> svg': {
    width: 100,
    height: 100,
    justifySelf: 'center',
    alignSelf: 'center',
  },

  [theme.breakpoints.down('md')]: {
    '> svg': {
      width: 'unset',
      height: 'unset',
    }
  }
}));

const ViewCodeInstructionsCardContent = styled(Typography)(() => ({
  color: 'white',
  lineHeight: '27px',
}));

export default function ViewCodeInstructionsCard() {
  const navigate = useNavigate();

  const goToInstructionsPage = () => {
    trackViewInstructionsClicked();
    navigate(`${WIDGET_SETTINGS_PATH}/localiq-code`)
  };

  return (
    <ViewCodeInstructionsCardWrapper>
      <ViewCodeInstructionsCardHeader title='LocaliQ Code Required' />
      <ViewCodeInstructionsCardContentWrapper>
        <TrackingCodeSvg />
        <ViewCodeInstructionsCardContent variant='body1'>
          Install the LocaliQ Code to display this widget on your website.
        </ViewCodeInstructionsCardContent>
        <BookingsCTA
          text='View Instructions'
          onClickHandler={goToInstructionsPage}
        />
      </ViewCodeInstructionsCardContentWrapper>
    </ViewCodeInstructionsCardWrapper>
  )
}