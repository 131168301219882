import {
  catchError,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  connectGoogleCalendarAction,
  connectGoogleCalendarFailure,
  updateCalendarIntegrationFailure,
} from './google-calendar-slice';
import { convertDateToFormat } from '../../../../shared/app-utils';
import { trackCalendarConnect } from '../../../../shared/analytics-utils';
import { SCHEDULER_SLUG } from '../../../../shared/app-constants';
import { authRequestFailure, authRequestSuccess, saveOutlookAuth } from './outlook-calendar-slice';
import { updateCalendarIntegrationAction, updateCalendarIntegrationActionSuccess } from './calendar-actions';
import { GOOGLE_TYPE, GOOGLE_VENDOR, OUTLOOK_VENDOR } from '../bookings-constants';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_GATEWAY_CALENDAR_ROOT,
  REACT_APP_GATEWAY_ROOT,
} = process.env;

const connectGoogleCalendarEpic = (action$) => action$.pipe(
  filter(connectGoogleCalendarAction.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_CALENDAR_ROOT}acs/google/url-callback?code=${action.payload.code}&scope=${action.payload.scope}&authuser=0&prompt=consent&redirect=postmessage`,
    method: 'GET',
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => {
      trackCalendarConnect(GOOGLE_VENDOR);
      return updateCalendarIntegrationAction(
        {
          type: GOOGLE_TYPE,
          accountKey: response.response.payload.email,
          connectedDate: convertDateToFormat(new Date().toString()),
        },
      );
    }),
    catchError(() => of(connectGoogleCalendarFailure())),
  )),
);

const connectOutlookCalendarEpic = (action$) => action$.pipe(
  filter(saveOutlookAuth.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_ROOT}acs/microsoft/url-callback?code=${action.payload.code}`,
    method: 'GET',
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => {
      trackCalendarConnect(OUTLOOK_VENDOR);
      return authRequestSuccess({
        accountKey: response.response.userId,
      });
    }),
    catchError(() => of(authRequestFailure())),
  )),
);

const updateCalendarIntegrationEpic = (action$, state$) => action$.pipe(
  filter(updateCalendarIntegrationAction.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${SCHEDULER_SLUG}/${state$.value.bookings.tier}/?email=${state$.value.bookings.currentUserEmail}`,
    method: 'PUT',
    body: {
      calendarIntegration: Object.keys(action.payload).length <= 1 ? [] : [
        {
          type: action.payload.type,
          connectedDate: action.payload.connectedDate,
          accountKey: action.payload.accountKey,
        },
      ],
    },
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => updateCalendarIntegrationActionSuccess(
      {
        type: action.payload.type,
        ...response.response,
      },
    )),
    catchError(() => (
      of(updateCalendarIntegrationFailure())
    )),
  )),
);

export default [
  connectGoogleCalendarEpic,
  connectOutlookCalendarEpic,
  updateCalendarIntegrationEpic,
];
