// Form constants
export const CHATBOT_NAME_FORM = 'nameForm';
export const CHATBOT_TONE_FORM = 'toneForm';
export const CHATBOT_VISITOR_FORM = 'visitorForm';
export const CHATBOT_CUSTOM_QUESTION_FORM = 'customQuestionForm';
export const CHATBOT_CONNECT_SCHEDULING_FORM = 'connectSchedulingForm';

// Tones
export const CHATBOT_PRO_NEUTRAL_VALUE = 'SMBREGULAR';
export const CHATBOT_PRO_FRIENDLY_VALUE = 'SMBFRIENDLY';
export const CHATBOT_PRO_PROFESSIONAL_VALUE = 'SMBPROFESSIONAL';
export const CHATBOT_FREE_TONE = 'FREEMIUM';

export const CALL_VISITORS = "I'll call visitors";
export const EMAIL_VISITORS = "I'll email visitors";
export const TEXT_VISITORS = "I'll text visitors";

// Sections
export const CHATBOT_NAME_SECTION = 'botNameSection';
export const CHATBOT_TONE_SECTION = 'botToneSection';
export const CHATBOT_VISITOR_SECTION = 'botVisitorSection';
export const CHATBOT_CUSTOM_QUESTION_SECTION = 'botCustomQuestionSection';
export const CHATBOT_CONNECT_SCHEDULING_SECTION = 'schedulerLink';

// Keys
export const CHATBOT_NAME_KEY = 'botName';
export const CHATBOT_BOT_TONE_KEY = 'botTone';
export const CHATBOT_VISITOR_TEXT_KEY = 'commPrefText';
export const CHATBOT_VISITOR_CALL_KEY = 'commPrefCall';
export const CHATBOT_VISITOR_EMAIL_KEY = 'commPrefEmail';
export const CHATBOT_NEED_CUSTOM_QUESTION_KEY = 'needsCustomQuestions';
export const CHATBOT_CUSTOM_QUESTION_KEY = 'customQuestions';
export const CHATBOT_LEAD_EMAILS_KEY = 'leadsEmails';
export const CHATBOT_CONNECT_SCHEDULING_KEY = 'active';

// Chatbot Tone
export const CHATBOT_NEUTRAL_TONE = 'Neutral';
export const CHATBOT_FRIENDLY_TONE = 'Friendly';
export const CHATBOT_PROFESSIONAL_TONE = 'Professional';
