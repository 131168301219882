import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledRowWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.common.black}`,

  '& .MuiGrid-item': {
    border: 'none',
  },
  '&.recentActivity-skeleton-mobile-lastRow': {
    borderBottom: 'none',
  },
}));

const StyledCellWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(0.5),
}));

const StyledFirstCellWrapper = styled(StyledCellWrapper)(() => ({}));
const StyledSecondCellWrapper = styled(StyledCellWrapper)(() => ({}));
const StyledThirdCellWrapper = styled(StyledCellWrapper)(() => ({}));
const StyledForthCellWrapper = styled(StyledCellWrapper)(() => ({}));

export default function SkeletonMobileRow({ isLastRow }) {
  return (
    <StyledRowWrapper container className={isLastRow && 'recentActivity-skeleton-mobile-lastRow'}>
      <StyledFirstCellWrapper container item md={4} lg={4}>
        <SkeletonText animation="wave" variant="string" width={18} height={24} />
        <SkeletonText animation="wave" variant="string" width={273} height={24} />
      </StyledFirstCellWrapper>
      <StyledSecondCellWrapper container item md={4} lg={4}>
        <SkeletonText animation="wave" variant="string" width={46} height={24} />
        <SkeletonText animation="wave" variant="string" width={128} height={24} />
      </StyledSecondCellWrapper>
      <StyledThirdCellWrapper container item md={3} lg={3}>
        <SkeletonText animation="wave" variant="string" width={166} height={24} />
      </StyledThirdCellWrapper>
      <StyledForthCellWrapper container item md={1} lg={1}>
        <SkeletonText animation="wave" variant="string" width={91} height={24} />
      </StyledForthCellWrapper>
    </StyledRowWrapper>
  );
}

SkeletonMobileRow.propTypes = {
  isLastRow: PropTypes.bool,
};
