import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setStateClean, setStateDirty, setStateInvalid } from '../../pages/products/settings/data/widgetSettings-slice';
import StateChangedHook from './StateChangedHook';

/**
 * Specific state changed implementation for widget settings
 */
export default function WidgetSettingsStateChangedHook({
  formName,
  formKeys = [],
  subsectionKey,
  nestedKey
}) {
  const dispatch = useDispatch();

  const resetData = useSelector((state) => {
    if (subsectionKey && !nestedKey) {
      return state.settings.resetProductInfo[subsectionKey]
    } else if (subsectionKey && nestedKey) {
      return state.settings.resetProductInfo[subsectionKey][nestedKey]
    }

    return state.settings.resetProductInfo;
  });

  const hasReset = useSelector((state) => state.settings.hasReset);
  const onDirtyForm = () => {
    dispatch(setStateDirty({ formName }));
  };

  const onCleanForm = () => {
    dispatch(setStateClean({ formName }));
  };

  const onInvalid = () => {
    dispatch(setStateInvalid({ formName }));
  };

  return (
    <StateChangedHook
      formName={formName}
      formKeys={formKeys}
      resetData={resetData}
      hasReset={hasReset}
      onDirty={onDirtyForm}
      onClean={onCleanForm}
      onInvalid={onInvalid}
    />
  );
}

WidgetSettingsStateChangedHook.propTypes = {
  formName: PropTypes.string.isRequired,
  formKeys: PropTypes.array.isRequired,
  subsectionKey: PropTypes.string,
};
