import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import GradientBackground from '../assets/rb-blobs.svg';
import GradientMobile from '../assets/rb-blobs-mobile.svg';
import ShoppingImage from '../assets/shopping.png';
import { determineContent } from '../dashboard-utils';
import { dismissBanner } from '../../../shared/business-attributes-slice';
import { DISMISS_WELCOME_CARD } from '../dashboard-constants';
import LoadingSkeletonWelcomeCardDesktop from '../components/loadingSkeletons/welcome/LoadingSkeletonWelcomeCardDesktop';
import LoadingSkeletonWelcomeCardMobile from '../components/loadingSkeletons/welcome/LoadingSkeletonWelcomeCardMobile';

const WelcomeCardContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${GradientBackground})`,
  backgroundSize: 'cover',
  display: 'flex',
  padding: theme.spacing(6, 14),
  position: 'relative',
  alignItems: 'center',
  '& .body-wrapper': {
    paddingLeft: theme.spacing(8),

    '& .welcome-title': {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 32,
      lineHeight: '40px',
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
    '& .welcome-description': {
      fontSize: 18,
      lineHeight: '27px',
      padding: theme.spacing(3, 0),
    },
  },

  '& .close-button': {
    padding: 0,
    marginTop: `-${theme.spacing(2)}`,
    '& svg': {
      height: 30,
      width: 30,
    },
  },
  
  '& .button-wrapper': {
    position: 'absolute',
    top: 24,
    right: 24,
    cursor: 'pointer',
  },

  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${GradientMobile})`,
    '&&.MuiBox-root': {
      padding: theme.spacing(3),
    },

    '& .image-wrapper': {
      display: 'none',
    },

    '& .body-wrapper': {
      paddingLeft: 0,
    },
  },
}));

export default function WelcomeCard({
  userTemplate,
}) {
  const dispatch = useDispatch();
  const isLoadingSettingsData = useSelector((state) => state.appSettings.isLoadingSettingsData);
  const { title, description } = determineContent(userTemplate);

  const handleDismiss = () => {
    dispatch(dismissBanner({ key: DISMISS_WELCOME_CARD }));
  };

  if (isLoadingSettingsData) {
    return (isMobile
      ? <LoadingSkeletonWelcomeCardMobile />
      : <LoadingSkeletonWelcomeCardDesktop />
    );
  }

  return (
    <WelcomeCardContainer>
      <div className="image-wrapper">
        <img src={ShoppingImage} alt="shopping" />
      </div>
      <div className="body-wrapper">
        <Typography className="welcome-title">
          {title}
        </Typography>
        <Typography className="welcome-description">
          {description}
        </Typography>
      </div>
      <div className="button-wrapper">
        <CloseIcon onClick={handleDismiss} id="close-welcome" />
      </div>
    </WelcomeCardContainer>
  );
}

WelcomeCard.propTypes = {
  userTemplate: PropTypes.string,
};
