import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { ActionIcons, ERROR_TYPE } from '../../../../shared/app-constants';
import ActionCard from '../../../../shared/ActionCard';
import { ActionDescription } from '../../../../shared/settings-shared-components';
import { getStripeErrorMessage } from '../account-utils';

const StripeActionCard = styled(ActionCard)(() => ({
  margin: '16px 0',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: 'underline',
  fontSize: 14,
}));

const unpackMessage = (errorProps) => {
  if (errorProps && errorProps.last_payment_error
      && (errorProps.last_payment_error.payment_method || errorProps.last_payment_error.source)) {
    return getStripeErrorMessage(
      errorProps.last_payment_error.code,
      errorProps.last_payment_error.payment_method?.card?.last4 || errorProps.last_payment_error.source?.last4,
    );
  }
  return null;
};

export default function PaymentNotification({ showCTA }) {
  const notifications = useSelector((state) => state.account.creditCards.listOfStripeErrors);

  function updatePayment() {
    window.location.replace('/account/credit-cards');
  }

  return (
    <>
      {notifications && notifications.map((err) => {
        const message = unpackMessage(err.properties);
        if (!message) {
          return <></>;
        }
        return (
          <StripeActionCard
            id="stripe-action-banner"
            type={ERROR_TYPE}
            icons={ActionIcons}
          >
            <ActionDescription> {message} </ActionDescription>
            {showCTA &&  <ActionButton onClick={updatePayment}> Update Payment Method </ActionButton>}
          </StripeActionCard>
        )
      })}
    </>
  )
}

PaymentNotification.propTypes = {
  showCTA: PropTypes.bool,
};
