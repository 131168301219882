import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 7, 6, 6),

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  '& .headerTitle': {
    fontSize: 40,
    lineHeight: '1.25',
    color: theme.palette.common.black,
    fontFamily: 'Unify Sans SemiBold',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginBottom: theme.spacing(6),
    },
  },

  '& .subTitle': {
    fontSize: 16,
    lineHeight: '1.5',
    color: theme.palette.common.black,
    fontFamily: 'Unify Sans SemiBold',
    marginBottom: theme.spacing(1),
  },

  '& .headerDescription': {
    fontSize: 20,
    lineHeight: '1.5',
  },
}));

export const ScoreWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'scoreColor',
})(({ scoreColor, theme }) => ({
  borderRadius: 8,
  border: '1px solid rgb(174, 174, 173)',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),

  '& .blockWrapper': {
    position: 'relative',
    display: 'inline-block',
    width: 120,
    height: 96,
    textAlign: 'center',
    color: scoreColor,
    flexShrink: 0,

    '& .badgeCircle': {
      position: 'absolute',
      inset: 0,
      margin: 'auto',
      width: 96,
      height: 96,
      backgroundColor: 'currentcolor',
      borderRadius: '50%',
    },

    '& .badgeImageWrapper': {
      position: 'absolute',
      width: '100%',
      height: 32,
      top: 'calc(50% - 8px)',
      left: 0,
      right: 0,
      textAlign: 'center',
      transform: 'translateY(-50%)',
      color: theme.palette.common.white,
    },

    '& .badgeTextWrapper': {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: 24,
      left: 0,
      right: 0,
      bottom: 8,
      background: theme.palette.common.white,
      border: '2px solid currentcolor',
      boxSizing: 'border-box',
      borderRadius: 4,

      '& .badgeText': {
        fontSize: 12,
        fontFamily: 'Unify Sans SemiBold',
        lineHeight: '1.66667',
        textAlign: 'center',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        color: theme.palette.text.primary,
        margin: 0,
      },
    },
  },
}));

export const ServiceCalloutBox = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.text.primary}`,
  overflow: 'hidden',
  position: 'relative',
  zIndex: 2,
  color: theme.palette.common.white,
  padding: theme.spacing(5, 3, 3),

  '& .serviceTitle': {
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'Unify Sans SemiBold',
    lineHeight: '1.25',
    letterSpacing: '1px',
    marginBottom: theme.spacing(3),
  },

  '& .serviceCallout': {
    fontSize: 24,
    fontFamily: 'Unify Sans SemiBold',
    lineHeight: '1.25',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },

  '& .serviceDescription': {
    fontSize: 18,
    lineHeight: '1.5',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },

  '& .actionButton': {
    padding: '0.642857em 4.44444em',
  },

  '& .backgroundBlock': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: 0,
    zIndex: '-1',
    backgroundColor: theme.palette.common.black,
    '& .blurWrapper': {
      position: 'absolute',
      transform: 'translate(-50%, -50%) rotate(22deg) scale(1)',
      transformOrigin: 'center center',
      opacity: 1,
      filter: 'blur(200px)',
      color: '#be1e00',
      top: '95%',
      left: '95%',
    },
  },
}));

export const DetailWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})(({ bgColor, theme }) => ({
  boxShadow: 'none',
  border: '1px solid #aeaead',
  borderRadius: 4,
  marginBottom: 12,

  '& .detailAccordion': {
    paddingLeft: theme.spacing(1),

    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(2, 0, 3),

      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(2),
      },

      '& .detailTitle': {
        textTransform: 'uppercase',
        fontSize: 12,
        color: theme.palette.text.primary,
        fontFamily: 'Unify Sans SemiBold',
      },

      '& .detailRecommend': {
        fontSize: 18,
        marginTop: theme.spacing(1),
        color: theme.palette.common.black,
        fontFamily: 'Unify Sans SemiBold',
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
      },
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.primary.main,
      width: 30,
      height: 30,
      fontSize: '1.5rem',
      '& svg': {
        width: 30,
        height: 30,
      },
    },

    '& .detailsGrid': {
      padding: theme.spacing(4),
      borderTop: '1px solid #aeaead',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 0),
      },

      '& .detailGridTitle': {
        color: theme.palette.text.primary,
        fontSize: 16,
        fontFamily: 'Unify Sans SemiBold',

        '& svg': {
          color: bgColor,
          marginRight: theme.spacing(1),
          verticalAlign: 'text-bottom',
          marginBottom: '-2px',
        },
      },
      '& .detailGridExplain': {
        fontSize: 16,
        paddingTop: theme.spacing(1),
        '& a': {
          textDecoration: 'none',
        },
      },
    },

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: theme.spacing(1),
      backgroundColor: bgColor,
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
      boxSizing: 'border-box',
    },
  },

}));

export const ScoreDetailsSectionWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const ScoreDetailsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})(({ bgColor, theme }) => ({
  position: 'relative',
  padding: theme.spacing(3, 2, 2, 5),
  backgroundColor: bgColor,
  border: '1px solid rgba(215, 215, 215, 0.25)',
  marginTop: theme.spacing(3),
  color: 'red',

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(3),
  },

  '& .detailsTitle': {
    fontSize: 30,
    lineHeight: '1.25',
    color: theme.palette.text.primary,
    fontFamily: 'Unify Sans SemiBold',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
}));
