import { TRIAL_EXPIRED } from '../app-constants';

export const isUserTrialEligible = (trials, orders) => (
  Array.isArray(trials) && trials.length === 0 && Array.isArray(orders) && orders.length === 0
);

/**
 * Whether a single trial is expired
 * @param {Object} trial
 */
export const isTrialExpired = (trial) => {
  if (!trial.active && trial.status === TRIAL_EXPIRED) {
    return true;
  }
  return false;
};

/**
 * Whether the user has any expired trials
 * TODO: this probably should use slug + tier combo for looking up
 * a specific trial
 * @param {Object[]} trials
 * @returns Whether an expired trial exists
 */
export const hasExpiredTrial = (trials) => {
  if (Array.isArray(trials)) {
    for (let index = 0; index < trials.length; index += 1) {
      const trial = trials[index];
      if (!trial.active && trial.status === TRIAL_EXPIRED) {
        return true;
      }
    }
  }
  return false;
};