import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonRow from './SkeletonRow';
import SkeletonText from '../SkeletonText';

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(3),
  padding: theme.spacing(4, 5, 4, 5),
  background: theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 3, 2, 3),
  },
}));

const StyledHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const SkeletonCircular = styled(Skeleton)(({ bgcolor = '#0000000A' }) => ({
  '&.MuiSkeleton-circular': {
    background: bgcolor,
  },
}));

export default function LoadingSkeletonWidgetStatusDesktop() {
  return (
    <StyledWrapper>
      <StyledHeadWrapper>
        <SkeletonText animation="wave" variant="string" width={167} height={30} />
        <SkeletonCircular animation="wave" variant="circular" width={16} height={16} />
      </StyledHeadWrapper>
      <SkeletonRow />
      <SkeletonRow isSecondRow />
    </StyledWrapper>
  );
}
