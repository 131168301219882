import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CardHeader } from '@mui/material';
import BannerGradient from '../../images/expired-banner.svg';
import BannerGradientMobile from '../../images/expired-banner-mobile.svg';
import BookingsCTA from '../../shared/BookingsCTA';
import { ExpiredCardContent, FrameBannerCard } from '../../shared/settings-shared-components';
import { BUNDLE_SLUG, INITIAL_BUNDLE_TIER } from '../../shared/app-constants';
import { hasExpiredTrial } from '../../shared/trials/trial-utils';

const {
  REACT_APP_BUY_ONLINE_UI_ROOT,
} = process.env;

/**
 * Banner to show when a user's trial has expired
 */
export default function TrialExpiredBanner({}) {
  const trials = useSelector((state) => state.account.trials);
  const showBanner = hasExpiredTrial(trials);

  const handlePurchase = () => {
    const buyLink = `${REACT_APP_BUY_ONLINE_UI_ROOT}?product=${BUNDLE_SLUG}&tier=${INITIAL_BUNDLE_TIER}`;
    window.open(buyLink);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <FrameBannerCard BannerGradient={BannerGradient} MobileBannerGradient={BannerGradientMobile}>
      <CardHeader title="Your Trial Has Expired" />
      <ExpiredCardContent>
        <div className="bodyWrapper">
          Thank you for trying out LocaliQ! Upgrading to a subscription will unlock
          more features and help your business thrive.
        </div>
        <div className="paymentWrapper">
          <BookingsCTA
            text="Purchase Subscription"
            onClickHandler={handlePurchase}
          />
        </div>
      </ExpiredCardContent>
    </FrameBannerCard>
  );
}
