import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { CustomCard } from '../../shared/settings-shared-components';
import {
  CustomDivider,
  ContactHeaderWrapper,
  ContactWrapper,
  HeaderImageWrapper,
  HeaderListWrapper,
  ContactSection
} from './ContactUsPage';

const CardHeaderSection = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '1rem',
  marginLeft: '1rem',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
}));

const ListWrapper = styled('div')(() => ({
  display: 'flex',
  marginTop: '1rem',
  flexDirection: 'row',
  alignItems: 'center'
}));

const CardWrapper = styled(CustomCard)(({ theme }) => ({
  width: 688,
  padding: 0,
  [theme.breakpoints.down('md')]: {
    width: 500
  },
  [theme.breakpoints.down('sm')]: {
    width: 350
  },
}));

const SkeletonCircularList = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  marginRight: '1rem'
}));

const SkeletonRectangularList = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  borderRadius: 12
}));

const SkeletonRightContact = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 12
}));

const SkeletonLeftTopContact = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  borderRadius: 8,
  marginBottom: '.5rem'
}));

const SkeletonLeftBottomContact = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  borderRadius: 8,
}));

export default function ContactSkeleton() {
  const contactLeftContent = (
    <div>
      <SkeletonLeftTopContact variant="rectangular" width={88} height={24} />
      <SkeletonLeftBottomContact variant="rectangular" width={112} height={16} />
    </div>
  );

  return (
    <ContactWrapper>
      <CardWrapper>
        <CardHeaderSection>
          <ContactHeaderWrapper>
            <HeaderImageWrapper><Skeleton variant="circular" width={150} height={150} sx={{ bgcolor: '#AEAEAD' }} /> </HeaderImageWrapper>
            <HeaderListWrapper>
              <div>
                <Skeleton variant="rectangular" width={354} height={24} sx={{ bgcolor: '#AEAEAD', borderRadius: '12px', marginBottom: '1rem' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <SkeletonCircularList variant="circular" width={16} height={16} />
                <SkeletonRectangularList variant="rectangular" width={376} height={16} />
              </div>
              <ListWrapper>
                <SkeletonCircularList variant="circular" width={16} height={16} />
                <SkeletonRectangularList variant="rectangular" width={344} height={16} />
              </ListWrapper>
              <ListWrapper>
                <SkeletonCircularList variant="circular" width={16} height={16} />
                <SkeletonRectangularList variant="rectangular" width={376} height={16} />
              </ListWrapper>
            </HeaderListWrapper>
          </ContactHeaderWrapper>
        </CardHeaderSection>
        <ContactSection>
          {contactLeftContent}
          <SkeletonRightContact variant="rectangular" width={176} height={16} />
        </ContactSection>
        <CustomDivider />
        <ContactSection>
          {contactLeftContent}
          <SkeletonRightContact variant="rectangular" width={128} height={16} />
        </ContactSection>
        <CustomDivider />
        <ContactSection>
          {contactLeftContent}
          <SkeletonRightContact variant="rectangular" width={128} height={16} />
        </ContactSection>
        <CustomDivider />
        <ContactSection>
          {contactLeftContent}
          <Skeleton variant="rectangular" width={128} height={16} sx={{ bgcolor: '#AEAEAD', borderRadius: '12px' }} />
        </ContactSection>
        <CustomDivider />
      </CardWrapper>
    </ContactWrapper>
  );
}
