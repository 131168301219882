import { useCallback } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQFormSelect, ValidationProvider } from '@gannettdigital/shared-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { GridRow } from '../../../../shared/settings-shared-components';
import SchedulerStateChangedHook from '../../../../shared/stateChangedHook/SchedulerStateChangedHook';
import { FUTURE_APPOINTMENT_AVAILABILITY_FORM, BOOKING_PERIODS } from '../bookings-constants';
import {
  ADVANCED_SETTINGS_SECTION,
  ALLOW_BOOKING_TO_BE_MADE_NAME_KEY,
  updateAdvancedSettingsField,
} from '../data/advanced-settings-slice';
import SectionCard from '../../../../shared/SectionCard';

const FORM_KEYS = [ALLOW_BOOKING_TO_BE_MADE_NAME_KEY];
const CARD_TITLE = 'Future Appointment Availability';

const SmallText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  position: 'relative',
  top: 14,
  color: theme.palette.text.primary,

  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    top: 3,
  }
}));

const FutureBookingsAvailability = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { allowBookings } = useSelector((state) => state[ADVANCED_SETTINGS_SECTION][ADVANCED_SETTINGS_SECTION]);

  const schema = yup.object().shape({
    [ALLOW_BOOKING_TO_BE_MADE_NAME_KEY]: yup.number(),
  });

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      [ALLOW_BOOKING_TO_BE_MADE_NAME_KEY]: allowBookings || 30,
    },
  });

  const handleFieldChange = useCallback((fieldKey, newValue) => {
    dispatch(updateAdvancedSettingsField({
      fieldName: fieldKey,
      fieldValue: newValue,
    }));
  }, [dispatch, updateAdvancedSettingsField]);

  return (
    <SectionCard
      id="future-booking-availability-card"
      title={CARD_TITLE}
      tooltipText="Manage how far in advance you'll accept appointments."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form>
            <Grid container alignItems="center" marginTop={theme.spacing(3)}>
              <GridRow item md={4} sm={5} xs={12}>
                <IQFormSelect
                  id="allow_booking"
                  name={ALLOW_BOOKING_TO_BE_MADE_NAME_KEY}
                  labelText="Allow bookings to be made"
                  items={BOOKING_PERIODS}
                  onChange={(event) => handleFieldChange(ALLOW_BOOKING_TO_BE_MADE_NAME_KEY, event.target.value)}
                />
              </GridRow>
              <Grid item md={8} sm={7} xs={12}>
                <SmallText variant="caption">into the future</SmallText>
              </Grid>
            </Grid>
          </form>
          <SchedulerStateChangedHook
            formName={FUTURE_APPOINTMENT_AVAILABILITY_FORM}
            formKeys={FORM_KEYS}
            subsectionKey={ADVANCED_SETTINGS_SECTION}
          />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
};

export default FutureBookingsAvailability;
