import { Container, Typography } from '@mui/material';
import styled from '@emotion/styled';

const CustomContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
}));

export default function NoAccess() {
  return (
    <CustomContainer>
      <Typography variant="h1" sx={{ pt: '25%', pb: 2 }}>404</Typography>
      <Typography variant="h3" sx={{ pb: 2 }}>The page you&apos;re looking for cannot be found.</Typography>
      <Typography variant="body1">The URL may have been mispelled, or the page may have moved.</Typography>
    </CustomContainer>
  );
}
