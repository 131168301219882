import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { IQFormInput, IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { CHATBOT_NAME_FORM, CHATBOT_NAME_KEY, CHATBOT_NAME_SECTION, } from '../../chatbot-constants';
import { updateChatbotNameField } from '../../data/general-settings-slice';
import ChatbotStateChangedHook from '../../../../../shared/stateChangedHook/ChatbotStateChangedHook';
import { CharacterCount, InputGridWrapper } from '../../../../../shared/settings-shared-components';
import SectionCard from '../../../../../shared/SectionCard';

const FORM_KEYS = [CHATBOT_NAME_KEY];

export default function ChatbotNameCard() {
  const botName = useSelector((state) => state.chatbot.generalSettings[CHATBOT_NAME_SECTION][CHATBOT_NAME_KEY]);
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    [CHATBOT_NAME_KEY]: yup.string().required("Chatbot name can't be empty"),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [CHATBOT_NAME_KEY]: botName || "Chatbot Assistant",
    }
  });

  const chatBotNameLength = 36;
  const { watch, formState: {errors} } = methods;
  const chatbotNameVal = watch(CHATBOT_NAME_KEY);
  const chatbotNameCharCount = chatbotNameVal ? chatbotNameVal.length : 0

  const handleNameOnChange = (event) => {
    const trimmedChatbotName = event.target.value.trim();
    dispatch(
      updateChatbotNameField({ name: trimmedChatbotName })
    );
  };

  const hasErrors = errors[CHATBOT_NAME_KEY];

  return (
    <SectionCard
      id="chatbot-tool-name-card"
      title="Chatbot Name"
      hasError={hasErrors}
      tooltipText='We recommend naming your Chatbot with your "Company Name + Chatbot" or "Shortened Company Name + Chatbot Assistant."'
      showDividingLine={false}>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container item sm={9} alignItems="center" spacing={3}>
            <InputGridWrapper item xs={12} sm={6} sx={{ marginTop: '24px'}}>
              <IQFormInput
                id="chatbot-tool-name"
                name={CHATBOT_NAME_KEY}
                theme={IQTheme}
                onBlur={handleNameOnChange}
                maxLength={chatBotNameLength}
              />
              <CharacterCount>
                {chatbotNameCharCount} / {chatBotNameLength}
              </CharacterCount>
            </InputGridWrapper>
          </Grid>
          <ChatbotStateChangedHook formName={CHATBOT_NAME_FORM} formKeys={FORM_KEYS} />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
}

