import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import {
  loadWizardConfig,
  loadWizardConfigSuccess,
  loadWizardConfigFailure,
  saveWizardStep,
  saveWizardStepCompletedFailure,
  saveWizardStepFailure,
  saveWizardStepSuccess,
  setWizardStatusAction,
  setWizardStatusSuccess,
  startFreeTrial,
  startFreeTrialFailure,
  startFreeTrialSuccess,
} from './configuration-slice';
import { constructConfigBody, generateIdempotencyKey } from '../wizard/wizard-utils';
import { SAVE_CONFIGURATION } from '../wizard/wizard-constants';
import { INITIAL_BUNDLE_TIER } from '../../../shared/app-constants';
import {
  SUITE_USER_TYPES, TRIAL_SOURCES, TRIAL_SOURCE_KEY, setSuiteUserType,
} from '../../../shared/analytics-utils';
import { trackTrialStarted } from '../../../shared/tag-manager-utils';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_TRIAL_PRICE_ID,
  REACT_APP_TRIAL_TEST_PRICE_ID,
} = process.env;

// This is for the feature step
const saveConfigurationStep = (action$, state$) => action$.pipe(
  filter(saveWizardStep.match),
  switchMap(() => ajax(
    {
      // TODO: temporarily hardcoding the params
      url: `${REACT_APP_GATEWAY_EXT_ROOT}wizards?slug=bundle&tier=${INITIAL_BUNDLE_TIER}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: constructConfigBody(state$.value.configuration.currentStep.id, state$.value, 'configuration', SAVE_CONFIGURATION),
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => saveWizardStepSuccess(response.response)),
    catchError((error) => (
      of(saveWizardStepFailure(error))
    )),
  )),
);

const loadWizardConfiguration = (action$, state$) => action$.pipe(
  filter(loadWizardConfig.match),
  mergeMap(() => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}wizards?slug=bundle&tier=${INITIAL_BUNDLE_TIER}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => loadWizardConfigSuccess(response.response)),
    catchError((error) => (
      of(loadWizardConfigFailure(error))
    )),
  )),
);

// This is for the wizard completed page. Once user hits this page,
// will automatically trigger endpoint to activate widget
const saveWizardCompletedConfig = (action$, state$) => action$.pipe(
  filter(setWizardStatusAction.match),
  mergeMap(() => ajax({
    // TODO: temporarily hardcoding the params
    url: `${REACT_APP_GATEWAY_EXT_ROOT}wizards/submit?slug=bundle&tier=${INITIAL_BUNDLE_TIER}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => {
      const jsonResponse = response?.response?.wizard?.content;
      const parsedResponse = JSON.parse(jsonResponse);

      return setWizardStatusSuccess(
        { wizard: parsedResponse, dismissBanner: response.response.welcomeBannerDismissed }
      );
    }),
    catchError((error) => (
      of(saveWizardStepCompletedFailure(error))
    )),
  )),
);

const startFreeTrialEpic = (action$, state$) => action$.pipe(
  filter(startFreeTrial.match),
  mergeMap(() => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/subscription/trial`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    withCredentials: true,
    body: {
      // TODO: temporarily hardcoding the payload
      priceIds: [state$.value.appSettings?.user?.internal ? REACT_APP_TRIAL_TEST_PRICE_ID : REACT_APP_TRIAL_PRICE_ID],
      idempotencyKey: generateIdempotencyKey('free-trial-sub', state$.value.appSettings.user.id),
      trialEndBehavior: 'PAUSE',
      trialPeriodDays: 30,
      trialSource: sessionStorage.getItem(TRIAL_SOURCE_KEY) || TRIAL_SOURCES.DASHBOARD,
    },
  }).pipe(
    map((response) => {
      setSuiteUserType(SUITE_USER_TYPES.FREE_TRIAL);
      trackTrialStarted();
      return startFreeTrialSuccess(response.response);
    }),
    catchError((err) => of(startFreeTrialFailure(err))),
  )),
);

export default [
  saveConfigurationStep,
  loadWizardConfiguration,
  startFreeTrialEpic,
  saveWizardCompletedConfig,
];
