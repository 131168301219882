import styled from '@emotion/styled';
import { animated } from '@react-spring/web';

export const ScoreValue = styled(animated.div)(({ theme }) => ({
  width: 96,
  height: 96,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  borderWidth: 4,
  borderStyle: 'solid',
  backgroundColor: 'rgba(255, 255, 255, 0)',
  textAlign: 'center',
  zIndex: 5,
  transition: 'border-color 0.25s linear 0s',

  '& .numerator': {
    fontSize: 42,
    borderBottom: `3px solid ${theme.palette.text.primary}`,
    transform: 'translateY(-3px)',
    lineHeight: 1,
    paddingBottom: 5,
  },
  '& .denominator': {
    fontSize: 20,
    marginTop: 5,
    lineHeight: 1,
    transform: 'translateY(-3px)',
  },

  '& .MuiTypography-root': {
    fontFamily: 'Unify Sans SemiBold',
  },
}));
