import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LocalPhoneIcon from "./assets/appointment-phone-icon.svg"
import BusinessLocation from './assets/business-location.svg';
import WebConference from './assets/web-conference.svg';
import LocationPin from './assets/location.svg';
import Phone from './assets/phone.svg';

// Product name
export const SCHEDULER_NAME = 'Scheduling';

// Form constants
export const BUSINESS_DETAILS_FORM = 'businessDetailsForm';
export const TIMEZONE_FORM = 'timezoneForm';
export const DAYS_AND_TIMES_FORM = 'daysAndTimes';
export const SPECIFIC_DAYS_CLOSED_FORM = 'specificDaysClosedForm';
export const EMAIL_NOTIFICATIONS_FORM = 'emailNotificationsForm';
export const APPOINTMENT_TYPES_FORM = 'appointmentTypesForm';
export const FUTURE_APPOINTMENT_AVAILABILITY_FORM = 'futureAppointmentAvailabilityForm';
export const POLICY_ADVANCED_SETTINGS_FORM = 'policyAdvancedSettingsForm';
export const COMMUNICATION_PREF_FORM = 'communicationPrefForm';

// Form keys
// Add here if they need to be used in multiple reducers

export const SCHEDULER_US_TIMEZONES = [
  {
    value: 'America/New_York',
    description: 'Eastern (EST)',
  },
  {
    value: 'America/Chicago',
    description: 'Central (CST)',
  },
  {
    value: 'America/Denver',
    description: 'Mountain (MST)',
  },
  {
    value: 'America/Los_Angeles',
    description: 'Pacific (PST)',
  },
  {
    value: 'America/Anchorage',
    description: 'Alaskan (AKST)',
  },
  {
    value: 'Pacific/Honolulu',
    description: 'Hawaii-Aleutian (HST)',
  },
];

/**
 * Advanced Settings
 * Future Bookings Availability options
 */
export const BOOKING_PERIODS = [
  {
    "value": 14,
    "description": "14 calendar days"
  },
  {
    "value": 30,
    "description": "30 calendar days"
  },
  {
    "value": 60,
    "description": "60 calendar days"
  },
  {
    "value": 120,
    "description": "120 calendar days"
  },
  {
    "value": 365,
    "description": "365 calendar days"
  }
];

/**
 * Advanced Settings
 * Booking Policy default text
 */
export const DEFAULT_POLICY_TEXT = "When you make an appointment, we are reserving space on our calendar " +
  "that is no longer open to our other clients. If you are unable to make your appointment time, " +
  "please cancel or reschedule as soon as possible.";


// Appointment card constants
export const ZERO_MINS = { value: 0, description: '0 Minutes' };
const THIRTY_MINS = { value: 30, description: '30 Minutes' };
const SIXTY_MINS = { value: 60, description: '60 Minutes' };
const NINETY_MINS = { value: 90, description: '90 Minutes' };
const HUNDRED_TWENTY_MINS = { value: 120, description: '120 Minutes' };

export const DURATION_VALUES = [
  THIRTY_MINS,
  SIXTY_MINS,
  NINETY_MINS,
  HUNDRED_TWENTY_MINS
];

export const BUFFER_TIMES = [
  ZERO_MINS,
  THIRTY_MINS,
  SIXTY_MINS,
  NINETY_MINS,
  HUNDRED_TWENTY_MINS
];

export const THREE_HOURS = { value: 180, description: '3 hours ahead' };
// Store as minutes
export const LEAD_TIMES = [
  { value: 60, description: '1 hour ahead' },
  THREE_HOURS,
  { value: 360, description: '6 hours ahead' },
  { value: 720, description: '12 hours ahead' },
  { value: 1440, description: '24 hours ahead' }
];

export const PHONE_LOCATION = { value: 'PHONE_CALL', description: 'I will call the customer', icon: Phone };
export const OFFICE_LOCATION = { value: 'MEET_AT_BUSINESS', description: 'Meet at my business location', icon: BusinessLocation };
export const SITE_LOCATION = { value: 'MEET_AT_INVITEE', description: 'Meet customer at their provided location',  icon: LocationPin };
export const ONLINE_LOCATION = { value: 'WEB_CONFERENCE', description: 'Web Conference',  icon: WebConference };
export const LOCATIONS = [
  PHONE_LOCATION,
  OFFICE_LOCATION,
  SITE_LOCATION,
  ONLINE_LOCATION,
];

// SMS & Email Keys
export const NEW_APPT_EMAIL = 'newAppointmentConfirmationEmail';
export const APPT_REMINDER_EMAIL = 'appointmentRemindernEmail';
export const APPT_RESCHEDULE_CANCEL_EMAIL = 'reschedulerORcancellationEmail';
export const NEW_APPT_SMS = 'newAppointmentConfirmationSMS';
export const APPT_REMINDER_SMS = 'appointmentReminderSMS';
export const APPT_RESCHEDULE_CANCEL_SMS = 'rescheduleOrCancelSMS';
export const SMS_PHONE_NUMBER = 'phoneNumberSMS';

export const SMS = "SMS";
export const EMAIL = "Email";
export const WEB_CONFERENCE_CUSTOM_LINK_VALUE = 'custom_link';
export const WEB_CONFERENCE_PERSONAL_LINK_VALUE = 'generic_meeting_link';

export const defaultAppointment = {
  name: 'Phone Consultation',
  duration: THIRTY_MINS.value,
  location: PHONE_LOCATION.value,
  description: 'We want to learn more about you! This 30-minute call is a free consultation that gives you time to let us know about your needs to see if our product is right for you.',
  bufferTimeBefore: ZERO_MINS.value,
  bufferTimeAfter: ZERO_MINS.value,
  leadTime: THREE_HOURS.value,
  customQuestions: [],
  virtualMeetingType: WEB_CONFERENCE_CUSTOM_LINK_VALUE,
  virtualMeetingVendor: '',
  virtualMeetingUrl: '',
};

export const APPOINTMENT_TYPE_TO_ICON_MAP = {
  'LocalPhone': {
    icon: <img src={LocalPhoneIcon} />,
    name: PHONE_LOCATION.description,
  },
  'Work': {
    icon: <PinDropIcon />,
    name: SITE_LOCATION.description,
  },
  'Business': {
    icon: <StoreMallDirectoryIcon />,
    name: OFFICE_LOCATION.description,
  },
  'OnlineMeeting': {
    icon: <img src={WebConference} />,
    name: ONLINE_LOCATION.description,
  }
};

export const CANCELLED_APPOINTMENT_LABEL = 'CANCELLED';
export const RESCHEDULED_APPOINTMENT_LABEL = 'RESCHEDULED';
export const CANCELLED_APPOINTMENT_BG_COLOR = '#C20F1E';
export const RESCHEDULED_APPOINTMENT_BG_COLOR = '#BA5012';

export const BLUE_JEANS_PLATFORM = { value: 'BLUE_JEANS', description: 'Blue Jeans' };
export const GOOGLE_HANGOUTS_PLATFORM = { value: 'GOOGLE_HANGOUTS', description: 'Google Hangouts' };
export const GOOGLE_MEET_PLATFORM = { value: 'GOOGLE_MEET', description: 'Google Meet' };
export const GOTO_MEETING_PLATFORM = { value: 'GOTO_MEETING', description: 'Goto Meeting' };
export const MICROSOFT_TEAMS_PLATFORM = { value: 'MICROSOFT_TEAMS', description: 'Microsoft Teams' };
export const SKYPE_PLATFORM = { value: 'SKYPE', description: 'Skype' };
export const WEBEX_PLATFORM = { value: 'WEBEX', description: 'Webex' };
export const ZOOM_PLATFORM = { value: 'ZOOM', description: 'Zoom' };
export const OTHER_PLATFORM = { value: 'OTHER', description: 'Other' };
export const WEB_CONFERENCE_PLATFORMS = [
  BLUE_JEANS_PLATFORM,
  GOOGLE_HANGOUTS_PLATFORM,
  GOOGLE_MEET_PLATFORM,
  GOTO_MEETING_PLATFORM,
  MICROSOFT_TEAMS_PLATFORM,
  SKYPE_PLATFORM,
  WEBEX_PLATFORM,
  ZOOM_PLATFORM,
  OTHER_PLATFORM,
];

// Google Calendar
export const GOOGLE_CALENDAR_SCOPE = 'https://www.googleapis.com/auth/calendar';
export const INITIAL_SCOPE = `${GOOGLE_CALENDAR_SCOPE} https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email`;

// General Calendar
export const GOOGLE_TYPE = 'google';
export const OUTLOOK_TYPE = 'microsoft';
export const OUTLOOK_ACCESS_ERROR_TYPE = 'access_denied';
export const GOOGLE_VENDOR = 'Google';
export const OUTLOOK_VENDOR = 'Outlook';

// Calendar Query Params
export const CALENDAR_QUERY_SECTION_NAME = 'section';
export const CALENDAR_QUERY_SECTION_VALUE = 'calendarIntegrations';

