import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { IQRadioBox } from '@gannettdigital/shared-react-components';
import { Chip, RadioGroup, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { formatPhoneNumberUtil } from '../../../../../shared/app-utils';
import ForwardingNumberLoadingComponent from './ForwardingNumberLoadingComponent';
import {
  CURRENT_FORWARDING_NUMBER,
  CURRENT_FORWARDING_UUID,
  FORWARDING_TYPE_MAP,
  SELECTABLE_FORWARDING_NUMBERS,
  SELECTED_FORWARDING_NUMBER_KEY,
  CURRENT_FORWARDING_TYPE,
  LOCAL_TYPE,
} from '../../call-settings-constants';
import { DescriptionText } from '../../../../../shared/settings-shared-components';
import { ReactComponent as ChipCircle } from '../../assets/chip-ellipse.svg';

const EditWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

const LabelWrapper = styled('span')(({ theme }) => ({
  '& .title': {
    fontFamily: 'Unify Sans SemiBold',
    fontSize: 20,
    lineHeight: '25px',
    letterSpacing: '0.18px',
  },
  '& .description': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  gap: theme.spacing(2),
  width: 'calc(100% + 16px)',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiBox-root': {
    borderColor: theme.palette.primary.main,
  },
  '& .customRadioBox .MuiBox-root': {
    padding: theme.spacing('6px', 2, '12px', 2),
    '&:first-of-type': {
      padding: 0,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& > div': {
      width: '100%',
    },
  },
}));

const RadioLabelWrapper = styled('div')(({ theme }) => ({
  paddingRight: '30px',
  marginTop: '-6px',
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
  },
}));

const TypeLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '15px',
  letterSpacing: '1px',
  fontWeight: 600,
  textTransform: 'uppercase',
  marginRight: theme.spacing(1),
  color: theme.palette.common.black,
  fontFamily: 'Unify Sans SemiBold',
}));

const PhoneRadioLabel = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  lineHeight: '27px',
  fontWeight: 400,
  marginTop: theme.spacing(1),
}));

const NumberChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  height: 16,
  border: '1px solid #AEAEAD',
  '& > .MuiChip-label': {
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontFamily: 'Unify Sans SemiBold',
  },
}));

const CircleWrapper = styled('span')(() => ({
  marginRight: '4px',
  verticalAlign: 'middle',
}));

const buildRadioDisplay = (numberObj, isCurrent) => {
  const { trackingNumber, type, isRecommended } = numberObj;
  const typeLookup = FORWARDING_TYPE_MAP[type];

  return (
    <RadioLabelWrapper>
      {typeLookup && <TypeLabel component="span">{typeLookup}</TypeLabel>}
      {isCurrent && (
        <NumberChip label={(
          <span>
            <CircleWrapper style={{ color: '#7DBE50' }}><ChipCircle /></CircleWrapper>
            Current
          </span>
        )}
        />
      )}
      {isRecommended && (
        <NumberChip label={(
          <span>
            <CircleWrapper style={{ color: '#009BFF' }}><ChipCircle /></CircleWrapper>
            Recommended
          </span>
        )}
        />
      )}
      <PhoneRadioLabel>{formatPhoneNumberUtil(trackingNumber)}</PhoneRadioLabel>
    </RadioLabelWrapper>
  );
};

const FORWARDING_DESC = `Calls to the forwarding number will be recorded. This is the phone number you will use on your website,
  printed marketing materials, and social media.`;

/**
 * Handle the editing state of the forwarding number card
 */
export default function ForwardingNumberEditComponent({ onChange }) {
  const { control, setValue } = useFormContext();
  const theme = useTheme();
  const {
    [SELECTABLE_FORWARDING_NUMBERS]: selectableForwardingNumbers,
    [CURRENT_FORWARDING_NUMBER]: currentForwardingNumber,
    [CURRENT_FORWARDING_UUID]: currentForwardingNumberUuid,
    [CURRENT_FORWARDING_TYPE]: currentForwardingNumberType,
  } = useSelector(
    (state) => state.callSettings.generalSettings,
  );
  const isGeneratingNumbers = useSelector((state) => state.callSettings.generalSettings.isGeneratingNumbers);
  const hasExistingForwardingNumber = !!currentForwardingNumber;
  const hasSelectableNumbers = Array.isArray(selectableForwardingNumbers)
    && selectableForwardingNumbers.length > 0;

  const selectedForwarding = useWatch({
    control,
    name: SELECTED_FORWARDING_NUMBER_KEY,
    defaultValue: currentForwardingNumber,
  });

  const defaultNumber = {
    type: currentForwardingNumberType,
    trackingNumber: currentForwardingNumber,
    trackingUuId: currentForwardingNumberUuid,
  };

  const handleChange = (clickEvent) => {
    const selectedNumber = clickEvent.target.value;
    let foundNumObj = {};
    // Lookup the selected object itself.
    if (selectedNumber === currentForwardingNumber) {
      foundNumObj = defaultNumber;
    } else {
      foundNumObj = selectableForwardingNumbers.find(
        (number) => number.trackingNumber === selectedNumber,
      );
    }
    onChange(foundNumObj);
  };

  useEffect(() => {
    if (hasSelectableNumbers && !currentForwardingNumber && !isGeneratingNumbers) {
      // Auto select a number
      let foundValue = false;
      for (let index = 0; index < selectableForwardingNumbers.length; index += 1) {
        const number = selectableForwardingNumbers[index];
        if (number.type === LOCAL_TYPE) {
          foundValue = number;
          break;
        }
      }
      // If no local, just assign to first
      if (!foundValue) {
        foundValue = selectableForwardingNumbers[0];
      }
      // Set it in the hook form
      setValue(SELECTED_FORWARDING_NUMBER_KEY, foundValue.trackingNumber, {
        shouldValidate: true,
      });
      // Set it in redux
      onChange(foundValue);
    }
  }, [
    selectableForwardingNumbers,
    hasSelectableNumbers,
    currentForwardingNumber,
    isGeneratingNumbers,
  ]);

  return (
    <>
      {(hasSelectableNumbers || isGeneratingNumbers) && (
        <LabelWrapper>
          <Typography className="title">
            Forwarding Number
          </Typography>
          <DescriptionText className="description">{FORWARDING_DESC}</DescriptionText>
        </LabelWrapper>
      )}
      <EditWrapper>
        <Controller
          control={control}
          name={SELECTED_FORWARDING_NUMBER_KEY}
          render={({ field }) => (
            <StyledRadioGroup
              {...field}
              onChange={(e) => {
                handleChange(e);
                field.onChange(e);
              }}
            >
              {hasExistingForwardingNumber && (
                <IQRadioBox
                  radioDisplayValue={buildRadioDisplay(defaultNumber, true)}
                  value={currentForwardingNumber}
                  isSelected={currentForwardingNumber === selectedForwarding}
                  theme={theme}
                  className="customRadioBox"
                />
              )}
              {isGeneratingNumbers && (
                <ForwardingNumberLoadingComponent
                  hasExistingNumber={hasExistingForwardingNumber}
                />
              )}
              {!isGeneratingNumbers && hasSelectableNumbers
                && selectableForwardingNumbers.map((newNumber) => (
                  <IQRadioBox
                    key={newNumber.trackingNumber}
                    radioDisplayValue={buildRadioDisplay(newNumber)}
                    value={newNumber.trackingNumber}
                    isSelected={newNumber.trackingNumber === selectedForwarding}
                    theme={theme}
                    className="customRadioBox"
                  />
                ))}
            </StyledRadioGroup>
          )}
        />
      </EditWrapper>
    </>
  );
}

ForwardingNumberEditComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
};
