import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { isMobile } from 'react-device-detect';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import {
  Box, Grid, Divider, Typography,
} from '@mui/material';
import {
  determineOverlap,
  startEqualToEnd,
  endBeforeStart,
  checkDay,
} from '../../../../shared/util/day-time.utils';
import {
  END_BEFORE_START,
  START_END_ARE_EQUAL,
  TIME_SLOT_OVERLAP,
  MONDAY_KEY,
  TUESDAY_KEY,
  WEDNESDAY_KEY,
  THURSDAY_KEY,
  FRIDAY_KEY,
  SATURDAY_KEY,
  SUNDAY_KEY,
  TIME_SLOTS_KEY,
  DAY_TIME_SLOTS_KEY,
} from '../../../../shared/availability/availability-constants';
import {
  updateAvailabilityField,
  updateAvailabilitySameScheduleDays,
  updateAvailabilitySameSchedule,
  addNewSlotAvailabilitySameSchedule,
  deleteAvailabilitySameSchedule,
  updateAvailabilityDaysSchedule,
  deleteAvailabilityDaysSchedule,
  updateSelectOnAvailabilityDaysSchedule,
  TIMEZONE_KEY,
  SAME_TIME_KEY,
  CONVERTED_SAME_SCHEDULE_KEY,
  CONVERTED_DAYS_SCHEDULE_KEY,
  SAME_SCHEDULE_KEY,
  DAYS_SCHEDULE_KEY,
} from '../../data/appointment-schedule.slice';
import TimezoneWidget from '../../../../shared/availability/TimezoneWidget';
import SameTime from '../../../../shared/availability/SameTime';
import { StepWrapper } from '../components/wizard-shared-components';
import MatchingHours from '../../../../shared/availability/MatchingHours';
import NonMatchingHours from '../../../../shared/availability/NonMatchingHours';
import WizardFeatureTitle from '../components/WizardFeatureTitle';
import SchedulingIcon from '../../../../images/scheduler-feature.svg';
import { getLocalTimezone } from '../../../../shared/util/get-local-timezone.utils';
import WizardStateChangedHook from '../../../../shared/stateChangedHook/WizardStateChangedHook';
import { APPOINTMENT_SCHEDULE_STEP_FORM } from '../wizard-constants';

const TimezoneWidgetWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: '-4px',
}));

const AppointmentScheduleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '80%',
  height: 'auto',
}));

const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StepTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '24px',
}));

const Question = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}));

const Main = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flexGrow: 1,
}));

const DividingLine = styled(Divider)(({ theme }) => ({
  border: '1px solid #D7D7D7',
  margin: theme.spacing(3, 0),
}));

export default function AppointmentScheduleStep() {
  const dispatch = useDispatch();
  const {
    [CONVERTED_SAME_SCHEDULE_KEY]: scheduleValue,
    [SAME_TIME_KEY]: sameValue,
    [CONVERTED_DAYS_SCHEDULE_KEY]: daysValue,
    [TIMEZONE_KEY]: savedTimezone,
  } = useSelector((state) => state.configuration.appointmentAvailability);
  const selectedDays = useSelector(
    (state) => state.configuration.appointmentAvailability[SAME_SCHEDULE_KEY].days,
  );

  const schedulesSchema = yup.object().shape({
    startTime: yup.string().required(),
    endTime: yup.string().required(),
  }).test({
    message: END_BEFORE_START,
    test: (val) => {
      if (val) {
        return !endBeforeStart(val);
      }
      return true;
    },
  }).test({
    message: START_END_ARE_EQUAL,
    test: (val) => {
      if (val) {
        return !startEqualToEnd(val);
      }
      return true;
    },
  });

  const daysSchema = {
    selected: yup.boolean(),
    schedules: yup.array().when('selected', {
      is: true,
      then: yup.array().of(schedulesSchema),
    })
      .test({
        message: TIME_SLOT_OVERLAP,
        test: (val) => {
          if (val) {
            return !determineOverlap(val);
          }
          return true;
        },
      }),
  };

  const schema = yup.object().shape({
    [TIMEZONE_KEY]: yup.string().required(),
    [SAME_TIME_KEY]: yup.boolean(),
    [MONDAY_KEY]: yup.boolean(),
    [TUESDAY_KEY]: yup.boolean(),
    [WEDNESDAY_KEY]: yup.boolean(),
    [THURSDAY_KEY]: yup.boolean(),
    [FRIDAY_KEY]: yup.boolean(),
    [SATURDAY_KEY]: yup.boolean(),
    [SUNDAY_KEY]: yup.boolean(),
    [TIME_SLOTS_KEY]: yup.mixed().when(SAME_TIME_KEY, {
      is: true,
      then: yup.array().of(schedulesSchema)
        .test({
          message: TIME_SLOT_OVERLAP,
          test: (val) => {
            if (val) {
              return !determineOverlap(val);
            }
            return true;
          },
        }),
    }),
    [DAY_TIME_SLOTS_KEY]: yup.mixed().when(SAME_TIME_KEY, {
      is: false,
      then: yup.array().of(yup.object().shape(daysSchema)),
    }),
  });

  // Just in case we didn't load config
  const getLocalTimezoneValue = useCallback(getLocalTimezone, [Date]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [TIMEZONE_KEY]: savedTimezone || getLocalTimezoneValue(),
      [SAME_TIME_KEY]: sameValue,
      [MONDAY_KEY]: selectedDays ? checkDay(MONDAY_KEY, selectedDays) : true,
      [TUESDAY_KEY]: selectedDays ? checkDay(TUESDAY_KEY, selectedDays) : true,
      [WEDNESDAY_KEY]: selectedDays ? checkDay(WEDNESDAY_KEY, selectedDays) : true,
      [THURSDAY_KEY]: selectedDays ? checkDay(THURSDAY_KEY, selectedDays) : true,
      [FRIDAY_KEY]: selectedDays ? checkDay(FRIDAY_KEY, selectedDays) : true,
      [SATURDAY_KEY]: selectedDays ? checkDay(SATURDAY_KEY, selectedDays) : false,
      [SUNDAY_KEY]: selectedDays ? checkDay(SUNDAY_KEY, selectedDays) : false,
      [TIME_SLOTS_KEY]: scheduleValue,
      [DAY_TIME_SLOTS_KEY]: daysValue,
    })),
  });

  const {
    control,
    getValues,
    watch,
    trigger,
    formState,
  } = methods;
  const sameTimeWatch = watch(SAME_TIME_KEY);

  const {
    fields: timeSlots,
    append: handleAppendTimeSlot,
    remove: handleRemoveTimeSlot,
  } = useFieldArray({
    control,
    name: TIME_SLOTS_KEY,
  });

  // To avoid having 7 field arrays, have a single days keyed field array
  const {
    fields: dayTimeSlots,
  } = useFieldArray({
    control,
    name: DAY_TIME_SLOTS_KEY,
  });

  const addTimeSlot = useCallback(() => {
    handleAppendTimeSlot({
      startTime: '',
      endTime: '',
    });

    // Since we are sending in values, need to update in backend too
    dispatch(addNewSlotAvailabilitySameSchedule({
      fieldName: SAME_SCHEDULE_KEY,
      fieldValue: {
        startTime: '',
        endTime: '',
      },
    }));
  });

  const removeTimeSlot = useCallback((index) => {
    dispatch(deleteAvailabilitySameSchedule({ index }));
    handleRemoveTimeSlot(index);
  });

  const handleTimezoneChange = useCallback((e) => {
    dispatch(updateAvailabilityField({
      fieldName: TIMEZONE_KEY,
      fieldValue: e.target.value,
    }));
  });

  const timeSwitchChanged = useCallback((changedValue) => {
    dispatch(updateAvailabilityField({
      fieldName: SAME_TIME_KEY,
      fieldValue: changedValue,
    }));
  });

  const sameTimeOnChange = useCallback((index, key, value) => {
    const arrayKeyPieces = key.split('.');
    const keyName = arrayKeyPieces[arrayKeyPieces.length - 1];

    dispatch(updateAvailabilitySameSchedule({
      fieldName: SAME_SCHEDULE_KEY,
      fieldValue: {
        index,
        key: keyName,
        value,
      },
    }));
    trigger(TIME_SLOTS_KEY);
  });

  const individualDayDelete = useCallback((dayKey, index) => {
    dispatch(deleteAvailabilityDaysSchedule({
      dayKey: dayKey.toUpperCase(),
      index,
    }));
  });

  const updateDayTimeSlots = useCallback((event, dayKey) => {
    // This is triggered before the form updates, so it is the previous true/false, so flip
    const isSelected = !(event.target.value === 'true');
    dispatch(updateSelectOnAvailabilityDaysSchedule({
      dayKey: dayKey.toUpperCase(),
      isSelected,
    }));
  });

  const individualDayTimeOnChange = useCallback((dayKey, isEnabled, index, key, value) => {
    const arrayKeyPieces = key.split('.');
    const keyName = arrayKeyPieces[arrayKeyPieces.length - 1];

    dispatch(updateAvailabilityDaysSchedule({
      fieldName: DAYS_SCHEDULE_KEY,
      fieldValue: {
        dayKey: dayKey.toUpperCase(),
        index,
        isEnabled,
        key: keyName,
        value,
      },
    }));
    trigger(DAY_TIME_SLOTS_KEY);
  });

  const updateDayOnChange = useCallback((event, key) => {
    // Event has the previous value
    dispatch(updateAvailabilitySameScheduleDays({
      fieldName: key,
      fieldValue: !(event.target.value === 'true'),
    }));
  });

  return (
    <StepWrapper desktopFlexDirection="column" gapBetweenItems={1}>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <AppointmentScheduleWrapper>
            <Head>
              <WizardFeatureTitle
                icon={SchedulingIcon}
                text="Scheduling"
              />
              <StepTitle>Appointment Schedule</StepTitle>
              <Question>
                Select the times that your business is available to take appointments. 
                This may be different from your standard business hours.
              </Question>
            </Head>
            <Main>
              <TimezoneWidgetWrapper container gap={2} wrap={isMobile ? 'wrap' : 'nowrap'}>
                <TimezoneWidget
                  timezoneKey={TIMEZONE_KEY}
                  handleTimezoneChange={handleTimezoneChange}
                />
                <SameTime sameTimeKey={SAME_TIME_KEY} handleSameTimeChange={timeSwitchChanged} />
              </TimezoneWidgetWrapper>
              <DividingLine />
              <div>
                {!sameTimeWatch
                  ? (
                    <NonMatchingHours
                      dayTimeSlots={dayTimeSlots}
                      getValues={getValues}
                      individualDayDelete={individualDayDelete}
                      individualDayTimeOnChange={individualDayTimeOnChange}
                      updateDayTimeSlots={updateDayTimeSlots}
                    />
                  )
                  : (
                    <MatchingHours
                      timeSlots={timeSlots}
                      updateDayOnChange={updateDayOnChange}
                      sameTimeOnChange={sameTimeOnChange}
                      removeTimeSlot={removeTimeSlot}
                      addTimeSlot={addTimeSlot}
                    />
                  )}
              </div>
            </Main>
          </AppointmentScheduleWrapper>
          <WizardStateChangedHook formName={APPOINTMENT_SCHEDULE_STEP_FORM} />
        </FormProvider>
      </ValidationProvider>
    </StepWrapper>
  );
}
