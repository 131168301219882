import { createSlice } from '@reduxjs/toolkit';
import { closeDetailsAction } from './admin-shared-actions';

const initialState = {
  isLoading: false,
  productData: [],
  hasError: false,
};

const adminDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    retrieveAdditionalData: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    retrieveAdditionalDataSuccess: (state, action) => {
      const orders = action.payload;
      const orderItems = orders.reduce((acc, order) => {
        const { items } = order;
        if (Array.isArray(items) && items.length === 1 && items[0].productInfo) {
          const mainItem = items[0];
          acc.push({
            productName: mainItem.offeringType,
            activeStatus: mainItem.active,
            productData: mainItem.productInfo,
          });
        }
        return acc;
      }, []);
      state.productData = orderItems;
      state.isLoading = false;
    },
    retrieveAdditionalDataFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeDetailsAction, (state) => {
      state.productData = [];
      state.hasError = false;
    });
  },
});

export const {
  retrieveAdditionalData,
  retrieveAdditionalDataSuccess,
  retrieveAdditionalDataFailure,
} = adminDataSlice.actions;
export default adminDataSlice;
