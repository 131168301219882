import { DateTime, Duration } from 'luxon';
import Cookies from 'js-cookie';
import { ADDRESS_1_KEY, CITY_KEY, COUNTRY_KEY, STATE_KEY, ZIP_CODE_KEY } from '../pages/products/bookings/data/general-settings-slice';
import {
  SCHEDULER_SLUG,
  CHATBOT_SLUG,
  TRIAL_EXPIRED,
  ORDER_STATUS_SUBMITTED,
  BUNDLE_SLUG,
  INITIAL_BUNDLE_TIER,
} from './app-constants';
import { CANCELLED_APPOINTMENT_LABEL } from '../pages/products/bookings/bookings-constants';
import {
  isUserTrialEligible,
  hasExpiredTrial,
} from './trials/trial-utils';

const { REACT_APP_BUY_ONLINE_UI_HOST } = process.env;

/**
 * Find a product by slug in a given product array
 * @param {String[]} products Product array
 * @param {String} slug Target slug
 * @returns The product associated with the slug or null
 */
export const getProductFromProducts = (products, slug) => {
  if (Array.isArray(products) && products.length > 0) {
    for (let index = 0; index < products.length; index += 1) {
      const product = products[index];
      const { productType } = product;
      if (productType && productType.slug === slug) {
        return product;
      }
    }
  }
  return null;
};

export const convertDateToFormat = (dateAsString) => {
  const date = new Date(dateAsString);

  return new Date(date.getTime() - (date.getTimezoneOffset()*60*1000)).toISOString().split('T')[0];
};

export const redirectToUpgrade = (productSlug) => {
  // change when Upgrate to Pro task is done
  window.location.replace(REACT_APP_BUY_ONLINE_UI_HOST + 'upgrade?product=' + productSlug);
};

export const timezoneAbbrLookup = (fullTimezone) => {
  switch(fullTimezone) {
    case 'America/New_York':
      return 'EST';
    case 'America/Chicago':
      return 'CST';
    case 'America/Denver':
      return 'MST';
    case 'America/Los_Angeles':
      return 'PST';
    case 'America/Anchorage':
      return 'AKST';
    case 'HST':
    case 'Pacific/Honolulu':
      return 'HST';
    case 'America/Denver':
    case 'America/Phoenix':
      return 'MDT';
    default:
      return null;
  }
};

/**
 * Convert from a timezone value to a UTC +-, formatted for the b/e
 * @param {string} timeValue
 * @param {string} timezoneValue
 */
export const convertTimezoneToUTC = (timeValue, timeZone) => {
  // This always puts the value in system time
  const switchedTime = DateTime.fromFormat(`${timeValue} ${timeZone}`, 'HH:mm:ss z', {
    zone: 0,
  });
  return switchedTime.toFormat('HH:mm:ssZZ');
};

// calculate number of hours left to determine the # of days left. If only 1 hour is left, round to nearest whole number
export const calculateNumberOfDaysLeft = (endDate) => {
  const daysLeft = DateTime.fromSQL(endDate).diff(DateTime.now(), 'hours').values.hours / 24;
  const roundUpDaysLeft = parseInt(Math.ceil(daysLeft));
    // in case user sees anything above 30.
  if (roundUpDaysLeft > 30) {
    return 30;
  };
  return roundUpDaysLeft;
}

/**
 * Convert from UTC to the given timezone
 * @param {string} timeValue
 * @param {string} timezoneValue
 */
export const convertUTCToTimezone = (timeValue, timeZone) => {
  const switchedTime = DateTime.fromFormat(timeValue, 'HH:mm:ssZZ', {
    zone: timeZone,
  });
  return switchedTime.toFormat('HH:mm:ss');
};

/**
 * Convert from UTC to the given timezone
 * @param {string} date
 * @param {string} nextZone
 * @param {string} prevZone
 * @param {string} format
 */
 export const convertDateToAnotherTimezone = (date, nextZone, prevZone, format) => {
  return DateTime.fromFormat(
    date,
    prevZone === 'UTC' ? 'yyyy-MM-dd h:mm' : format,
    {zone: prevZone}
  ).setZone(nextZone);
};


export const compareTime = (timeOne, timeTwo) => (
  DateTime.fromFormat(timeOne, 'HH:mm:ss') < DateTime.fromFormat(timeTwo, 'HH:mm:ss')
);

export const checkIfSameTime = (timeOne, timeTwo) => (
  DateTime.fromFormat(timeOne, 'HH:mm:ss').toMillis()
    === DateTime.fromFormat(timeTwo, 'HH:mm:ss').toMillis()
);

export const compareDateRange = (startTime, endTime) => (
  new Date(startTime).getTime() > new Date(endTime).getTime()
);

export const convertAppointmentDate = (appt, nextTimezone, prevTimezone) => {
  let { appointmentStartDate, startTime, endTime } = appt;
  if (prevTimezone === 'UTC') {
    startTime = startTime.substring(0, 5);
    endTime = endTime.substring(0, 5);
  }

  const nextTimeZoneLuxonStartDate = convertDateToAnotherTimezone(
    `${appointmentStartDate} ${startTime}`, nextTimezone, prevTimezone, 'EEEE MM/dd/yyyy h:mm a');
  const nextTimeZoneLuxonEndDate = convertDateToAnotherTimezone(
    `${appointmentStartDate} ${endTime}`, nextTimezone, prevTimezone, 'EEEE MM/dd/yyyy h:mm a');

  appt.groupDate = nextTimeZoneLuxonStartDate.toLocaleString({...DateTime.DATE_FULL, weekday: 'long' });
  appt.appointmentStartDate = nextTimeZoneLuxonStartDate
    .toLocaleString({...DateTime.DATE_SHORT, weekday: 'long', month: '2-digit', day: '2-digit' })
    .replace(',', '');
  appt.startTime = nextTimeZoneLuxonStartDate.toLocaleString(DateTime.TIME_SIMPLE);
  appt.endTime = nextTimeZoneLuxonEndDate.toLocaleString(DateTime.TIME_SIMPLE);
  return appt;
};

export const convertAppointmentStatus = (appt) => {
  if (appt.status === 'CANCELED') {
    appt.status = CANCELLED_APPOINTMENT_LABEL;
  }

  return appt;
};

export const convertDateStringToDate = (dateString) => (
  DateTime.fromSQL(dateString).toJSDate()
);

/**
 * Convert from a luxon date to a specific format
 * @param {string} date1
 * @param {string} date2 (should be after date 1)
 * @param {array} timeMeasurement (an array with "years", "months", "days", "hours" etc.)
 */
export const getDiffBetweenTwoDates = (date1, date2, measurements = [ "hours", "minutes" ]) => {
  const luxonDate1 = convertDateStringToDate(date1);
  const luxonDate2 = convertDateStringToDate(date2);

  const diff = luxonDate2.diff(luxonDate1, measurements);

  return diff.toObject();
}

export const copyToClipboard = (value) => {
  window.navigator.clipboard.writeText(value);
};

export const openNewTab = (url) => {
  window.open(url, '_blank');
};

// TODO: Might need to remove this in the future (if the url is returned with /scheduler)
export const modifyUrl = (url, source) => {
  if (url.includes(SCHEDULER_SLUG)) {
    return `${url}?apptSource=${source}`;
  }

  const indexOfHashtag = url.indexOf('#');
  return `${url.substring(0, indexOfHashtag)}${SCHEDULER_SLUG}${url.substring(indexOfHashtag)}?apptSource=${source}`
};

/**
 * Read the businessDetails object and validate it has non-null/non-empty info.
 * @param {Object} businessDetails
 * @returns bool
 */
export const hasValidBusiness = (businessDetails) => (
  businessDetails && businessDetails[ADDRESS_1_KEY] && businessDetails[CITY_KEY]
    && businessDetails[STATE_KEY] && businessDetails[ZIP_CODE_KEY] && businessDetails[COUNTRY_KEY]
);

/**
 * Construct a save body object
 * @param {String[]} saveKeys
 * @param {Object} saveConfig
 * @param {Object} stateObj
 * @returns Object
 */
export const constructBody = (saveKeys, saveConfig, stateObj) => {
  const saveBody = {};
  const saveSectionKeys = Object.keys(stateObj).filter(
    (stateKey) => saveKeys.indexOf(stateKey) >= 0,
  );

  let internalSaveData = {};
  // Loop through each section of state that is part of our save config
  saveSectionKeys.forEach((sectionKey) => {
    const saveSections = saveConfig[sectionKey];
    const subSection = stateObj[sectionKey];

    // Each save configuration section has multiple save blocks
    // e.g. General Settings has biz details, color, availability etc
    saveSections.forEach(({ storeKey, saveKey, skipKeys, allowNullValue }) => {
      const internalData = storeKey ? subSection[storeKey] : subSection;
      internalSaveData = {};

      // If a raw array, don't process key values, just remap directly
      if (Array.isArray(internalData)) {
        internalSaveData = internalData;
      } else {
        // Loop through the redux save data and transfer over any that is not null or undefined
        if (internalData) {
          Object.entries(internalData).forEach(([key, value]) => {
            if ((value !== undefined && value !== null)
              && (!skipKeys || skipKeys.indexOf(key) === -1)) {
              internalSaveData[key] = value;
            }

            if ((allowNullValue?.indexOf(key) !== -1) && value === null) {
              internalSaveData[key] = null;
            }
          });
        }
      }
      // If we actually copied anything to the saveData, run the transfer
      if (Object.keys(internalSaveData).length > 0) {
        // A select few fields do not have a parent section, so have to check
        if (saveKey) {
          saveBody[saveKey] = internalSaveData;
        } else {
          Object.assign(saveBody, internalSaveData);
        }
      }
    });
  });

  return saveBody;
};

export const minimumNumCheck = (obj, requiredNum) => {
  let counter = 0;
  Object.values(obj).forEach(bool => {
    if(bool) counter++
  });

  if(counter >= requiredNum) return true;
  return false;
};

/**
 * Formats the given string into intCode (xxx) yyy-zzzz
 * @param {String} phoneNumberString
 * @returns String
 */
export const formatPhoneNumberUtil = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

/**
 * Decides whether or not to show the section based on the navigation choice
 * @param {String} screenKey
 * @param {String} matchKey
 * @returns String (either 'block' or 'none')
 */
export const displayCalc = (screenKey, matchKey) => (
  screenKey === matchKey ? 'block' : 'none'
);

/**
 * Converts a file to base 64 string
 * @param {File} the file to be converted
 * @returns Promise
 */
export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const generateScriptId = (trackingId) => {
  const siteId = trackingId.trim().replace(/-/g, '');
  if (siteId.length !== 32) { return ''; }
  return `/${siteId.substr(0, 3)}/${siteId.substr(3, 3)}/${siteId.substr(6, 3)}/${siteId.substr(9)}.js`;
};

/**
 * Take an array of query string params (params) and build individual qs keys
 * and combine them.
 * e.g. params = ['myString', 'something']
 * qsKey = 'sample'
 * results: '&&sample=myString&&sample=something
 */
export const constructQueryString = (qsKey, params) => {
  const urlParams = new URLSearchParams();
  params.forEach((param) => {
    urlParams.append(qsKey, param);
  });

  return urlParams.toString();
};

/**
 * Formats a timestamp string to a relative time
 * @param {String} timestampString
 * @returns The relative time as a string
 */
export const formatTimestampToRelativeTime = (timestampString) => {
  const timestamp = DateTime.fromISO(timestampString);
  const now = DateTime.now();
  const diff = now.diff(timestamp);

  const units = {
    years: "year",
    months: "month",
    days: "day",
    hours: "hour",
    minutes: "minute",
    seconds: "second",
  };

  const largestUnit = diff
    .shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds')
    .toObject();

  for (const unit in largestUnit) {
    if (largestUnit[unit] > 0) {
      let label = units[unit];
      if (largestUnit[unit] > 1) {
        label += 's';
      }
      return `${Math.trunc(largestUnit[unit])} ${label} ago`;
    }
  }

  return 'Just now'; // If the timestamp is very recent
}

/**
 * Formats a timestamp string to a given date format
 * @param {String} timestampString
 * @param {Boolean} timestampInMillis, whether the timestamp is in milliseconds or not
 * @param {String} insertTimeFormat, defaults to 'HH:mm'
 * @param {String} insertDateFormat, defaults to 'MM/dd/yyyy'
 * @returns The formatted time string
 */
export const formatTimestampToCustomFormat = (timestampString, timestampInMillis = false, insertTimeFormat = 'hh:mma', insertDateFormat = 'MM/dd/yyyy') => {
  let timestamp;
  if (timestampInMillis) {
    timestamp = DateTime.fromMillis(parseInt(timestampString));
  } else {
    timestamp = DateTime.fromISO(timestampString);
  }

  // Format the timestamp to the desired format
  const formattedTime = timestamp.toFormat(`${insertTimeFormat} ${insertDateFormat}`).toLowerCase();

  return formattedTime;
}

/**
 * Formats a time duration in seconds to a word for e.g 63 seconds to 1m 3s
 * @param {Number} timeInSeconds
 * @returns The formatted time string
 */
export function formatDurationToRelativeTime(durationInSeconds = 0) {
  // Create a Luxon Duration object
  const duration = Duration.fromObject({ seconds: durationInSeconds });

  // Get the formatted duration
  const formattedDuration = duration.toFormat("h'h' m'm' s's'");

  return formattedDuration;
}

/**
 * Formats a duration in seconds to 00:00:00 string
 * @param {Number} duration in seconds
 * @returns The formatted time string
 */
export const secondsToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const convertTimestampToUTCDateString = (timestamp) => {
  return timestamp ? DateTime.fromSeconds(timestamp).toUTC().toFormat('yyyy-MM-dd hh:mm:ss.S') : '';
}

/**
 * Returns true if this has a matching bundle order.  Currently assumes
 * it is for the matching initial bundle tier.
 * @param {bool} requireActive - Whether or not this this is checking active orders only
 */
export const hasBundleOrder = (orders, requireActive) => {
  if (Array.isArray(orders)) {
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (order.bundleSlug === BUNDLE_SLUG && order.bundleTier === INITIAL_BUNDLE_TIER) {
        if (requireActive && order.status === ORDER_STATUS_SUBMITTED) {
          return true;
        } else if (!requireActive) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * Determine if this user has restricted access to settings and
 * other various areas of the application
 */
export const hasRestrictedAccess = (trials, orders) => {
  return isUserTrialEligible(trials, orders) || hasExpiredTrial(trials);
}

// Check to see if any product was activated during wizard process
export const hasAnyActivatedWidgets = (features) => {
  const featuresArr = Object.values(features);
  return featuresArr.includes(true)
}

// Check to see if any product was not activated during wizard process
export const hasAnyNonActivatedWidgets = (features) => {
  const featuresArr = Object.values(features);
  return featuresArr.includes(false);
}

/**
 * Basic way to determine if the current visitor is authenticated
 */
export const isUserAuthenticated = () => (
  Cookies.get('localiq_refresh_expiration')
);
