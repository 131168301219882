import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { DISMISS_BANNER_SECTION, BUSINESS_ATTRIBUTES_SECTION, COLOR_KEY } from './app-constants';
import {
  ADD_CALENDAR_MINI_CARD_KEY,
  ADD_LISTINGS_MINI_CARD_KEY,
  DISMISS_WELCOME_CARD,
  TURN_ON_ALL_FEATURES_MINI_CARD_KEY,
} from '../pages/dashboard/dashboard-constants';
import { saveBookingsSuccess } from '../pages/products/bookings/data/bookings-slice';
import { saveChatbotSuccess } from '../pages/products/chatbot/data/chatbot-slice';
import { saveCallSettingsSuccess } from '../pages/products/callSettings/data/call-settings-actions';
import { setWizardStatusSuccess } from '../pages/configuration/data/configuration-slice';
import { updateFeaturesSuccess } from '../pages/products/settings/data/widgetSettings-slice';

const BUSINESS_ATTRIBUTES_SLICE_NAME = 'business_attributes';

const businessAttributesSlice = createSlice({
  name: BUSINESS_ATTRIBUTES_SLICE_NAME,
  initialState: {
    [BUSINESS_ATTRIBUTES_SECTION]: {
      [COLOR_KEY]: '',
    },
    [DISMISS_BANNER_SECTION]: {
      [TURN_ON_ALL_FEATURES_MINI_CARD_KEY]: false,
      [ADD_LISTINGS_MINI_CARD_KEY]: false,
      [ADD_CALENDAR_MINI_CARD_KEY]: false,
      [DISMISS_WELCOME_CARD]: false,
    },
    gmaid: null,
  },
  reducers: {
    dismissBanner: (state, action) => {
      const { key } = action.payload;
      state[DISMISS_BANNER_SECTION][key] = true;
    },
    dismissBannerSuccess: () => {},
    dismissBannerFailure: () => {},
    updateColorField: (state, action) => {
      state[BUSINESS_ATTRIBUTES_SECTION][COLOR_KEY] = action.payload[COLOR_KEY];
    },
    loadedBusinessAttributes: (state, action) => {
      const { attributes } = action.payload;
      if (attributes) {
        state[BUSINESS_ATTRIBUTES_SECTION][COLOR_KEY] = attributes[COLOR_KEY];
        if (attributes[DISMISS_BANNER_SECTION]) {
          Object.keys(attributes[DISMISS_BANNER_SECTION]).forEach((key) => {
            state[DISMISS_BANNER_SECTION][key] = (
              JSON.parse(attributes[DISMISS_BANNER_SECTION][key])
            );
          });
        }
      }

      if (action.payload.gmaid) {
        state.gmaid = action.payload.gmaid;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setWizardStatusSuccess, (state, action) => {
      state[DISMISS_BANNER_SECTION][DISMISS_WELCOME_CARD] = action.payload.dismissBanner;
    });
    builder.addCase(updateFeaturesSuccess, (state) => {
      state[DISMISS_BANNER_SECTION][DISMISS_WELCOME_CARD] = true;
    });
    builder.addMatcher(isAnyOf(
      saveBookingsSuccess,
      saveChatbotSuccess,
      saveCallSettingsSuccess,
    ), (state, action) => {
      const { businessAttributes } = action.payload;

      if (businessAttributes) {
        state[BUSINESS_ATTRIBUTES_SECTION][COLOR_KEY] = businessAttributes[COLOR_KEY];
      }
    });
  },
});

export const {
  dismissBanner,
  dismissBannerSuccess,
  dismissBannerFailure,
  updateColorField,
  loadedBusinessAttributes,
} = businessAttributesSlice.actions;
export default businessAttributesSlice.reducer;
