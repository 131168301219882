import { IQFormInput } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { GridRow } from '../../../../shared/settings-shared-components';
import { NOTIFICATION_EMAILS_KEY } from '../settings-constants';
import { ReactComponent as RemoveEmailIcon } from '../assets/remove-email-trash-icon.svg';

const EmailInputContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'hasError'})(({ theme, hasError }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  '> svg': {
    marginTop: theme.spacing(3),
    cursor: 'pointer',
  },
  '& .MuiFormControl-root > div': {
    marginRight: '4px',
    color: hasError ? theme.palette.error.main : 'inherit',
    borderColor: hasError ? theme.palette.error.main : 'inherit',
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  color: theme.palette.error.main,
  fontSize: '0.75rem',
}));

export default function EmailInputArrayField({ item, index, handleOnEmailInputChange, handleOnRemoveEmail, errors }) {
  const emailFieldId= `${NOTIFICATION_EMAILS_KEY}.${item.id}`;
  const emailFieldName= `${NOTIFICATION_EMAILS_KEY}.${index}.email`;
  const invalidEmail = errors?.notificationEmails?.[index];

  return (
    <GridRow key={item.id} item md={12} sm={12} xs={12}>
      <GridRow  item md={6} sm={12} xs={12}>
          <EmailInputContainer hasError={!!invalidEmail}>
            <IQFormInput
                id={emailFieldId}
                name={emailFieldName}
                labelText={`Email ${index + 1} ${index === 0 ? '*' : ''}`}
                onBlur={(e) => handleOnEmailInputChange(e, index)}
            />
            {
              index !== 0 ? (
                <RemoveEmailIcon onClick={() => handleOnRemoveEmail(index)} />
              ) : (
                <div style={{ visibility: 'hidden' }}>
                  <RemoveEmailIcon />
                </div>
              )
            }
          </EmailInputContainer>
            {
              invalidEmail && (
                <ErrorMessage>{invalidEmail.email.message}</ErrorMessage>
              )
            }
      </GridRow>
      <GridRow item md={6} sm={6} xs={6} />
    </GridRow>
  )
}