import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CustomerEngagementWidgetSvg } from '../assets/customer-engagement-widget.svg';
import { StepWrapper } from '../components/wizard-shared-components';

const WelcomeStepTitle = styled(Typography)((({ theme }) => ({
  fontWeight: 600,
  fontSize: '2rem',
  lineHeight: '30px',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  }
})));

const WelcomeStepSubTitle = styled('div')((() => ({
  textAlign: 'left',
  fontSize: '1.25rem',
  '& li': {
    margin: '1rem'
  }
})));

export default function WelcomeStep({
  stepData,
}) {
  return (
    <StepWrapper desktopFlexDirection='column' gapBetweenItems={4}>
      <WelcomeStepTitle variant='h4'>Let's get your widgets set up in 3 quick steps:</WelcomeStepTitle>
      <CustomerEngagementWidgetSvg />
      <WelcomeStepSubTitle variant='body1'>
        <ol>
          <li>Customize your widgets</li>
          <li>Add your basic business information</li>
          <li>Install LocaliQ Code on your website</li>
        </ol>
      </WelcomeStepSubTitle>
    </StepWrapper>
  );
}

WelcomeStep.propTypes = {
  stepData: PropTypes.object.isRequired,
};
