import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import ServiceCallout from './ServiceCallout';
import { determineLevel, retrieveTakeaways } from './data/grader-section-utils';
import { SECTION_TITLES } from './data/grader-section-constants';
import ScoreBadge from './ScoreBadge';
import { SectionBox } from './styles/grader-section-styles';

/**
 * Displays the Key takeaways for a given section
 */
export default function SectionHeader({ score, section }) {
  const level = determineLevel(score);
  const takeawayDescription = retrieveTakeaways(level, section);
  const title = SECTION_TITLES[section];
  return (
    <SectionBox>
      <Grid container spacing={5}>
        <Grid container item md={7} xs={12}>
          <Typography className="headerTitle">{title}</Typography>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <ScoreBadge score={score} />
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography className="subTitle">Key Takeaway</Typography>
              <Typography className="headerDescription">{takeawayDescription}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <ServiceCallout section={section} />
        </Grid>
      </Grid>
    </SectionBox>
  );
}

SectionHeader.propTypes = {
  score: PropTypes.number,
  section: PropTypes.string,
};
