import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from '@emotion/styled';
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Icon,
} from '@mui/material';
import selectedArrow from '../../images/selected-arrow.svg';
import { NavLink, NavLinkWrapper } from '../../shared/settings-shared-components';
import ViewCodeInstructionsCard from '../../shared/viewCodeInstructions/ViewCodeInstructionsCard';

const SideNavWrapper = styled('div')(({ theme, distanceFromTop }) => ({
  maxHeight: `calc(100vh - ${distanceFromTop}px)`,
}));

const SelectLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Unify Sans SemiBold',
  paddingBottom: theme.spacing(1),
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  color: theme.palette.text.primary,
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.text.primary,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  '& .MuiSelect-icon': {
    color: theme.palette.text.primary,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
  },
  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.palette.common.white,
  },
}));

const MobileFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
}));

const generateLinkItem = (navItem, currentlySelected, product) => {
  const { text, key, onClick } = navItem;
  const isCurrentlySelected = currentlySelected === key;
  return (
    <NavLinkWrapper key={key}>
      {isCurrentlySelected && (
        <Icon>
          <img src={selectedArrow} alt="selected-arrow" />
        </Icon>
      )}
      <NavLink
        selected={isCurrentlySelected}
        component="button"
        onClick={onClick}
        underline="always"
        id={`${product}-${key}`}
      >
        {text}
      </NavLink>
    </NavLinkWrapper>
  );
};

const generateSelectItem = (navItem) => {
  const { text, key, onClick } = navItem;
  return (
    <MenuItem value={key} key={key} onClick={onClick}>{text}</MenuItem>
  );
};

/**
 * Component for a side nav. Displays as a vertical list of links in desktop and
 * a selector in mobile.
 */
export default function SideNav({
  navItems, currentSection, product, showTrackingCodeViewInstructions,
  distanceFromTop = 181, customOnClick,
}) {
  const [selectorVal, setSelectorVal] = useState(currentSection);
  const isTrackingCodeInstalled = useSelector(
    (state) => state.appSettings.trackingCode.isInstalled,
  );
  const onSelectorChange = (event, selectedEntity) => {
    const selectedKey = event.target.value;
    setSelectorVal(selectedKey);
    const entityCallback = selectedEntity.props.callback;
    if (entityCallback) {
      entityCallback();
    }
  };

  useEffect(() => {
    if (currentSection) {
      setSelectorVal(currentSection);
    }
  }, [currentSection]);

  return (
    <SideNavWrapper distanceFromTop={distanceFromTop}>
      {!isMobile && (
        <Box>
          {navItems.map((navItem) => {
            if (customOnClick) {
              navItem.onClick = () => customOnClick(navItem.key);
            }
            return generateLinkItem(navItem, currentSection, product);
          })}
        </Box>
      )}
      {isMobile && (
        <MobileFormControl>
          <SelectLabel>Select a Section</SelectLabel>
          <StyledSelect
            id="section-selector"
            value={selectorVal}
            onChange={onSelectorChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            {navItems.map((navItem) => {
              if (customOnClick) {
                navItem.onClick = () => customOnClick(navItem.key);
              }
              return generateSelectItem(navItem);
            })}
          </StyledSelect>
        </MobileFormControl>
      )}
      {
        showTrackingCodeViewInstructions && !isTrackingCodeInstalled && (
          <Box sx={{ pb: { md: 1 } }}>
            <ViewCodeInstructionsCard />
          </Box>
        )
      }
    </SideNavWrapper>
  );
}

SideNav.propTypes = {
  navItems: PropTypes.array.isRequired,
  currentSection: PropTypes.string,
  product: PropTypes.string,
  showTrackingCodeViewInstructions: PropTypes.bool,
  // Used to calculate when this side nav should start scrolling
  distanceFromTop: PropTypes.number,
  customOnClick: PropTypes.func,
};
