import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CALL_SETTINGS_PATH, CHATBOT_PATH, CONTACT_FORM_PATH, DASHBOARD_PATH, OLD_CALL_RECORDER_PATH,
  OLD_CHATBOT_PATH, TOOLS_PATH, WIDGET_SETTINGS_PATH
} from '../../../shared/app-constants';
import {
  CHATBOT_PAGE, CLICK_TO_CALL_PAGE, NOTIFICATIONS_PAGE
} from '../../../shared/navigation/navigation-config';
import withOriginLink from '../../../shared/hocs/withOriginLink';
import { FullPageSpinnerWrapper } from '../../../shared/settings-shared-components';

/**
 * A redirect landing page to handle old path forward.
 * Can be removed at a later date.
 */
function RedirectLanding() {
  const navigate = useNavigate();
  const curLoc = useLocation();

  const handleRedirect = (currentLocation) => {
    const curPath = currentLocation.pathname;
    if (curPath.indexOf(CALL_SETTINGS_PATH) > -1
      || curPath.indexOf(OLD_CALL_RECORDER_PATH) > -1) {
      navigate(`${TOOLS_PATH}/${CLICK_TO_CALL_PAGE}`);
    } else if (curPath.indexOf(CHATBOT_PATH) > -1
      || curPath.indexOf(OLD_CHATBOT_PATH) > -1) {
      navigate(`${TOOLS_PATH}/${CHATBOT_PAGE}`);
    } else if (curPath.indexOf(CONTACT_FORM_PATH) > -1) {
      // This page was lead notifications + message widget, this is the closest analogous page.
      navigate(`${WIDGET_SETTINGS_PATH}/${NOTIFICATIONS_PAGE}`);
    } else {
      // In case this component is used without updating the links
      navigate(DASHBOARD_PATH);
    }
  };

  useEffect(() => {
    handleRedirect(curLoc);
  }, [curLoc]);

  return (
    <FullPageSpinnerWrapper>
      <IQLoadingSpinner size={100} />
    </FullPageSpinnerWrapper>
  );
}

export default withOriginLink(RedirectLanding);
