export const USER_ADMIN_ROLE = 'ADMIN';
export const PRODUCTS_KEY = 'products';
export const GMAID_KEY = 'gmaid';
export const EMAIL_KEY = 'email';
export const CHATBOT_PRODUCT = 'Chatbot';
export const CALL_RECORDER_PRODUCT = 'Call Recorder';
export const SCHEDULER_PRODUCT = 'Scheduling';
export const SCHEDULER_PRODUCT_OLD = 'Scheduler';
export const HTML_TYPE = 'HTML';
export const TEXT_TYPE = 'TEXT';

// Fixed appointment retrieval size
export const RETRIEVE_APPT_SIZE = 10;

// Product filters
export const PROD_FILTER = 'PROD_FILTER';
export const DATE_FILTER = 'DATE_FILTER';
export const CAPTURE_CODE_FILTER = 'CAPTURE_CODE_FILTER';
export const ACTIVE_FILTER = 'ACTIVE_FILTER';
export const TEST_USER_FILTER = 'TEST_USER_FILTER';

export const DETAILS_KEY_CONVERSION = {
  businessId: { label: 'Business Id' },
  businessName: { label: 'Business Name' },
  businessUrl: { label: 'Business URL' },
  [GMAID_KEY]: { label: 'GMAID' },
  email: { label: 'Email' },
  firstName: { label: 'First Name' },
  lastName: { label: 'Last Name' },
  created: { label: 'Created Date', conversion: (val) => new Date(val).toDateString() },
  [PRODUCTS_KEY]: { label: 'Products' },
};

// Sorting keys
export const DESC_SORT = 'desc';
export const ASC_SORT = 'asc';
export const BUSINESS_NAME_SORT = 'businessName';
export const ACTIVATION_DATE_SORT = 'createdAt';
