import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import combineSlices from '../../../../redux/combine-slices';
import { closeWarningModal, triggerResetAction } from '../../../../redux/shared-actions';
import {
  BUSINESS_ATTRIBUTES_SECTION,
  LITE_TIER,
  CALL_TRACKER_SLUG,
} from '../../../../shared/app-constants';
import { loadSettingsDataSuccess } from '../../../../shared/app-slice';
import { loadedCallSettingsData, saveCallSettings, saveCallSettingsSuccess } from './call-settings-actions';
import {
  CALL_RECORDING_ACTIVE_KEY,
  CALL_VERIFICATION_ENABLED_KEY,
  CALL_VERIFICATION_PROMPT_KEY,
  NEW_BASE_NUMBER_KEY,
  ORIGINAL_NUMBER,
  SELECTED_FORWARDING_NUMBER_KEY,
} from '../call-settings-constants';
import { setWizardStatusSuccess } from '../../../configuration/data/configuration-slice';
import { updateFeatures } from '../../settings/data/widgetSettings-slice';
import generalSettingsSlice from './general-settings-slice';

const initialState = {
  tier: LITE_TIER,
  firstActivationDate: null,
  isGeneratingNumbers: false,
  advertiserId: null,
  campaignId: null,
  hasReset: false,
  resetProductInfo: {},
  dirtySections: [],
  invalidSections: [],
  isInvalid: false,
  isDirty: false,
  showWarningModal: false,
  showChangesSaved: false,
  isSaving: false,
  isWidgetActivating: false,
  isWidgetActive: false,
  hasToggleError: false,
  hasData: false,
};

const callSettingsSlice = createSlice({
  name: 'callSettings',
  initialState,
  reducers: {
    toggleCallRecorderStatus: (state) => {
      state.isWidgetActivating = true;
    },
    toggleCallRecorderStatusSuccess: (state, action) => {
      state.isWidgetActive = action.payload.active;
      state.isWidgetActivating = false;
    },
    toggleCallRecorderStatusFailure: (state) => {
      state.hasToggleError = true;
      state.isWidgetActivating = false;
    },
    saveCallRecorderFailure: (state) => {
      state.isSaving = false;
    },
    cancelCallRecorderSave: (state) => {
      state.showWarningModal = state.isDirty;
      state.hasReset = false;
    },
    closeCallRecorderChangesSaved: (state) => {
      state.showChangesSaved = false;
    },
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySections.indexOf(dirtyForm) === -1) {
        state.dirtySections.push(dirtyForm);
        state.isDirty = true;
      }
      // If this form is now dirty, it can't be invalid anymore
      state.invalidSections = state.invalidSections.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;
      state.dirtySections = state.dirtySections.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySections.length > 0;

      state.invalidSections = state.invalidSections.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSections.indexOf(invalidForm) === -1) {
        state.invalidSections.push(invalidForm);
        state.isInvalid = true;
      }
    },
    resetCallRecorderCompleteAction: (state) => {
      state.hasReset = true;
    },
    setAfterWizardComplete: (state, action) => {
      state.isWidgetActive = action.payload.isActive;
      state.generalSettings.originalNumber = action.payload.businessPhoneNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const { business } = action.payload;
      if (business) {
        // TODO: Update USA to use platform, need api to return that field.
        state.advertiserId = business.gmaid.replace('USA_', '');
      }
    });
    builder.addCase(loadedCallSettingsData, (state, action) => {
      const {
        productInfo,
        active,
        firstActivationDate,
        productType,
      } = action.payload;

      if (productInfo) {
        if (productInfo.advertiserId) {
          state.advertiserId = productInfo.advertiserId;
        }
        if (productInfo.campaignId) {
          state.campaignId = productInfo.campaignId;
        }

        const copy = { ...productInfo };
        state.resetProductInfo = copy;
        state.resetProductInfo[CALL_VERIFICATION_ENABLED_KEY] = copy[CALL_VERIFICATION_ENABLED_KEY] == null ? true : copy[CALL_VERIFICATION_ENABLED_KEY];
      }

      if (productType) {
        state.tier = productType.tier;
      }

      state.hasData = true;
      state.isWidgetActive = active;
      state.firstActivationDate = firstActivationDate;
    });
    builder.addCase(saveCallSettingsSuccess, (state, action) => {
      state.isDirty = false;
      state.dirtySections = [];
      state.showChangesSaved = true;
      state.isSaving = false;

      state.firstActivationDate = action.payload.firstActivationDate;

      // Update the reset cache
      const callData = action.payload.data;
      // Need to modify the phone number
      let adjustedPhone = callData[ORIGINAL_NUMBER];
      if (adjustedPhone && adjustedPhone.indexOf('+1') === -1) {
        adjustedPhone = `+1${adjustedPhone}`;
      }
      // Update reset info
      state.resetProductInfo[NEW_BASE_NUMBER_KEY] = adjustedPhone;
      state.resetProductInfo[CALL_RECORDING_ACTIVE_KEY] = callData[CALL_RECORDING_ACTIVE_KEY];
      state.resetProductInfo[SELECTED_FORWARDING_NUMBER_KEY] = callData[SELECTED_FORWARDING_NUMBER_KEY];
      state.resetProductInfo[CALL_VERIFICATION_ENABLED_KEY] = callData[CALL_VERIFICATION_ENABLED_KEY];
      state.resetProductInfo[CALL_VERIFICATION_PROMPT_KEY] = callData[CALL_VERIFICATION_PROMPT_KEY];
      state.resetProductInfo[BUSINESS_ATTRIBUTES_SECTION] = action.payload[BUSINESS_ATTRIBUTES_SECTION];
    });
    builder.addCase(triggerResetAction, (state) => {
      state.hasReset = false;
    });
    builder.addCase(closeWarningModal, (state, action) => {
      state.showWarningModal = false;
      if (action.payload.forceClose) {
        state.isDirty = false;
        state.dirtySections = [];
      }
    });
    builder.addCase(saveCallSettings, (state) => {
      state.isSaving = true;
    });
    builder.addCase(updateFeatures, (state, action) => {
      if (action.payload.formName === CALL_TRACKER_SLUG) {
        state.isWidgetActive = action.payload.isSelected;
      }
    });
  },
});

export const {
  setStateDirty,
  setStateClean,
  setStateInvalid,
  saveCallRecorderFailure,
  cancelCallRecorderSave,
  closeCallRecorderChangesSaved,
  resetCallRecorderCompleteAction,
  toggleCallRecorderStatus,
  toggleCallRecorderStatusSuccess,
  toggleCallRecorderStatusFailure,
  setAfterWizardComplete,
} = callSettingsSlice.actions;
export default combineSlices(
  callSettingsSlice.reducer,
  initialState,
  {
    generalSettings: generalSettingsSlice,
  }
);
