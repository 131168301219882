import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledRowWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(3, 0),
  borderTop: '1px solid #716F6F',
  flexWrap: 'nowrap',
  alignItems: 'center',

  '& .MuiGrid-item': {
    border: `none`,
  },
}));

const StyledFirstCellWrapper = styled(Grid)(() => ({}));
const StyledSecondCellWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(0.3),
}));

const StyledThirdCellWrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export default function SkeletonRow() {
  return (
    <StyledRowWrapper container>
      <StyledFirstCellWrapper item md={2}>
        <SkeletonText animation="wave" variant="string" width={48} height={24} />
      </StyledFirstCellWrapper>
      <StyledSecondCellWrapper item md={9}>
        <SkeletonText animation="wave" variant="string" width="100%" height={20} />
        <SkeletonText animation="wave" variant="string" width={48} height={12} />
      </StyledSecondCellWrapper>
      <StyledThirdCellWrapper item md={1}>
        <SkeletonText animation="wave" variant="string" width={24} height={24} />
      </StyledThirdCellWrapper>
    </StyledRowWrapper>

  );
}
