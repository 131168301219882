import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import { START_END_ARE_EQUAL, END_BEFORE_START, TIME_SLOT_OVERLAP } from './availability-constants';
import StartEndTimeSelector from './StartEndTimeSelector';

const checkError = (errors, parentKey, index, errorMessage) => (
  errors && errors[parentKey] && errors[parentKey][index]
    && errors[parentKey][index].schedules
    && errors[parentKey][index].schedules.message === errorMessage
);

const checkErrorInSchedules = (errors, parentKey, index, scheduleIndex, errorMessage) => (
  errors && errors[parentKey] && errors[parentKey][index]
    && errors[parentKey][index].schedules
    && errors[parentKey][index].schedules[scheduleIndex]
    && errors[parentKey][index].schedules[scheduleIndex].message === errorMessage
);

const NestedDayTimeSelectorWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default function NestedDayTimeSelector({
  nestIndex,
  parentName,
  onChangeCallback,
  onDeleteCallback,
}) {
  const { control, formState: { errors } } = useFormContext();
  const timesArrayName = `${parentName}[${nestIndex}].schedules`;
  const hasOverlapError = checkError(errors, parentName, nestIndex, TIME_SLOT_OVERLAP);
  const { fields, remove, append } = useFieldArray({
    control,
    name: timesArrayName,
  });

  const removeDayTime = (index) => {
    remove(index);
    onDeleteCallback(index);
  };

  const addDayTime = () => {
    append({
      startTime: '',
      endTime: '',
    });
  };

  return (
    <NestedDayTimeSelectorWrapper>
      {fields.map((dayTime, index) => {
        const timeFieldName = `${timesArrayName}[${index}]`;
        const { startTime, endTime } = dayTime;
        const showAddBtn = fields.length <= 1;
        const showDelete = index <= 2 && !showAddBtn;
        const hasEndError = checkErrorInSchedules(errors, parentName, nestIndex, index, END_BEFORE_START);
        const hasStartSameAsEndError = checkErrorInSchedules(errors, parentName, nestIndex, index, START_END_ARE_EQUAL);
        let errorCode = null;
        if (hasOverlapError && index === 1) {
          errorCode = TIME_SLOT_OVERLAP;
        } else if (hasEndError) {
          errorCode = END_BEFORE_START;
        } else if (hasStartSameAsEndError) {
          errorCode = START_END_ARE_EQUAL;
        }

        return (
          <div key={dayTime.id}>
            <StartEndTimeSelector
              id={dayTime.id}
              startName={`${timeFieldName}.startTime`}
              endName={`${timeFieldName}.endTime`}
              startTime={startTime}
              endTime={endTime}
              showAddBtn={showAddBtn}
              showDelete={showDelete}
              onAddClick={addDayTime}
              errorCode={errorCode}
              onDeleteClick={() => removeDayTime(index)}
              onChange={(key, value) => onChangeCallback(
                index,
                key,
                value,
              )}
            />
          </div>
        );
      })}
    </NestedDayTimeSelectorWrapper>
  );
}

NestedDayTimeSelector.propTypes = {
  nestIndex: PropTypes.number.isRequired,
  parentName: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
};
