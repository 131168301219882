import { createEpicMiddleware } from 'redux-observable';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootEpic from './root-epic';

// Reducers
import appSettings from '../shared/app-slice';
import navigationSettings from '../shared/navigation/navigation-slice';
import generalSettings from '../pages/products/bookings/data/general-settings-slice';
import advancedSettings from '../pages/products/bookings/data/advanced-settings-slice';
import googleCalendar from '../pages/products/bookings/data/google-calendar-slice';
import addressAutocomplete from '../shared/autoComplete/address-autocomplete-slice';
import bookings from '../pages/products/bookings/data/bookings-slice';
import appointmentTypes from '../pages/products/bookings/data/appointment-types-slice';
import callSettings from '../pages/products/callSettings/data/call-settings-slice';
import chatbot from '../pages/products/chatbot/data/chatbot-slice';
import outlookCalendar from '../pages/products/bookings/data/outlook-calendar-slice';
import admin from '../pages/products/admin/data/admin-slice';
import account from '../pages/products/account/data/account-slice';
import businessAttributes from '../shared/business-attributes-slice';
import contactForm from '../pages/products/contactForm/data/contactForm-slice';
import configuration from '../pages/configuration/data/configuration-slice';
import settings from '../pages/products/settings/data/widgetSettings-slice';
import tools from '../pages/tools/data/tools-slice';
import dashboard from '../pages/dashboard/data/dashboard-slice';
import grader from '../pages/graders/website/data/grader-slice';

export const history = createBrowserHistory();

const { REACT_APP_LABEL } = process.env;

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history,
  },
});

export const store = configureStore({
  reducer: {
    appSettings,
    navigationSettings,
    generalSettings,
    advancedSettings,
    googleCalendar,
    addressAutocomplete,
    bookings,
    appointmentTypes,
    callSettings,
    chatbot,
    outlookCalendar,
    admin,
    account,
    businessAttributes,
    contactForm,
    configuration,
    settings,
    tools,
    dashboard,
    grader,
  },
  middleware: [epicMiddleware],
  devTools: REACT_APP_LABEL !== 'PRODLABEL',
});

epicMiddleware.run(rootEpic);
