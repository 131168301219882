import styled from '@emotion/styled';
import { Box, Card } from '@mui/material';

export const InputCard = styled(Card)(({ theme }) => ({
  borderRadius: 8,
  border: '1px solid rgb(41, 41, 40)',
  boxShadow: 'none',
  position: 'relative',
  zIndex: 2,
  maxWidth: 1152,
  margin: '0 auto',
  marginTop: -200,
  overflow: 'visible',
  padding: theme.spacing(0, 4),

  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0, 3),
  },

  '& .inputCardContent': {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 4),

    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
      padding: 0,
    },

    '& .leftImages': {
      marginTop: `-${theme.spacing(8)}`,
      '& .imagesWrapper': {
        width: '100%',
        height: 'auto',
        '& svg': {
          width: '100%',
          height: 'auto',
        },
      },
    },

    '& .rightInput': {
      paddingLeft: theme.spacing(8),

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(8, 0, 0, 0),
      },

      '& .urlDescription': {
        fontSize: 24,
        lineHeight: '1.25',
        color: 'rgb(41, 41, 40)',
        marginBottom: theme.spacing(2),
        fontFamily: 'Unify Sans SemiBold',
      },
      '& .MuiFormControl-root.MuiFormControl-fullWidth div': {
        border: '1px solid #000000',
        '& input': {
          color: theme.palette.common.black,
          height: 'auto',
          padding: 12.5,
          width: 'calc(100% - 32px)',
          '&::placeholder': {
            opacity: 0.3,
          },
        },
        '&:focus-within': {
          borderColor: 'rgb(0, 116, 194)',
        },
      },
      '& .topSubmitWrapper': {
        width: '100%',
        paddingTop: theme.spacing(4),
        '& button': {
          width: '100%',
        },
      },
    },
  },

  '& .dividerBox': {
    paddingTop: theme.spacing(6),
    '& .lineBreak': {
      borderColor: '#aeaead',
      color: '#aeaead',
      borderWidth: '0px 0px thin',
    },
  },
  '& .descriptionSection': {
    padding: theme.spacing(3, 8),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 3, 0),
    },

    '& .descriptionContent': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(5, 0),

      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(3, 0, 2, 0),
      },

      '& svg': {
        color: theme.palette.success.main,
      },

      '& .descriptionTitle': {
        fontSize: 20,
        lineHeight: '1.25',
        fontFamily: 'Unify Sans SemiBold',
        letterSpacing: '0.18px',
        color: theme.palette.common.black,
        margin: theme.spacing(3, 0, 1, 0),
      },
      '& .descriptionText': {
        lineHeight: '1.5',
        color: theme.palette.text.primary,
      },
    },
  },
}));

export const InputHeaderWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 435,
  zIndex: 2,
  backgroundColor: theme.palette.common.black,
  overflow: 'hidden',

  '& .headerContent': {
    position: 'relative',
    paddingTop: theme.spacing(6),
    maxWidth: 1040,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 2, 0, 2),
    },

    '& .MuiTypography-root': {
      fontSize: 40,
      lineHeight: '1.25',
      fontFamily: 'Unify Sans SemiBold',
      textAlign: 'center',
      color: theme.palette.common.white,

      [theme.breakpoints.down('md')]: {
        fontSize: 28,
        letterSpacing: '-0.5px',
      },
    },
  },
  '& .inputHeaderBackground': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: 0,
    backgroundColor: theme.palette.common.black,

    '& .blur1': {
      position: 'absolute',
      transform: 'translate(-50%, -50%) rotate(100deg) scale(1)',
      transformOrigin: 'center center',
      opacity: 0.9,
      filter: 'blur(64px)',
      color: 'rgb(0, 70, 255)',
      top: '30%',
      left: '3%',
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-50%, -50%) rotate(100deg) scale(0.66)',
      },
    },
    '& .blur2': {
      position: 'absolute',
      transform: 'translate(-50%, -50%) rotate(110deg) scale(1.3)',
      transformOrigin: 'center center',
      opacity: 0.9,
      filter: 'blur(44px)',
      color: 'rgb(0, 155, 255)',
      top: '75%',
      left: '-3%',
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-50%, -50%) rotate(110deg) scale(0.858)',
      },
    },
    '& .blur3': {
      position: 'absolute',
      transform: 'translate(-50%, -50%) rotate(30deg) scale(1.5)',
      transformOrigin: 'center center',
      opacity: 0.9,
      filter: 'blur(52px)',
      color: 'rgb(255, 100, 55)',
      top: '55%',
      left: '45%',
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-50%, -50%) rotate(30deg) scale(0.99)',
      },
    },
    '& .blur4': {
      position: 'absolute',
      transform: 'translate(-50%, -50%) rotate(-38deg) scale(1.3)',
      transformOrigin: 'center center',
      opacity: 0.9,
      filter: 'blur(60px)',
      color: 'rgb(190, 30, 60)',
      top: '100%',
      left: '95%',
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-50%, -50%) rotate(-38deg) scale(0.858)',
      },
    },
  },
}));

export const GraderFooter = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(8, 0),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 0),
  },

  '& .footerPrompt': {
    fontFamily: 'Unify Sans SemiBold',
    fontSize: 32,
    lineHeight: '1.25',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  '& .footerSubtext': {
    fontSize: 18,
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
  },

  '& .scrollButton': {
    fontSize: 20,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
    borderRadius: '2.22222em',
    padding: '0.742857em 4.44444em',

    [theme.breakpoints.down('md')]: {
      lineHeight: '1.25',
    },

    '&:hover': {
      fontSize: 20,
    },
  },
}));
