import { useState } from 'react';
import styled from '@emotion/styled';
import { Snackbar } from '@mui/material';

const SNACKBAR_DEFAULT_DURATION_MILLISECONDS = 5000;
const SNACKBAR_DEFAULT_VERTICAL_POSITION = 'bottom';
const SNACKBAR_DEFAULT_HORIZONTAL_POSITION = 'center';

const CustomSnackbar = styled(Snackbar)(() => ({
  '> div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export const withSnackbar = WrappedComponent => {
  return props => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState(SNACKBAR_DEFAULT_DURATION_MILLISECONDS);
    const [vertical, setVertical] = useState(SNACKBAR_DEFAULT_VERTICAL_POSITION);
    const [horizontal, setHorizontal] = useState(SNACKBAR_DEFAULT_HORIZONTAL_POSITION);

    const showMessage = (
      message,
      duration = SNACKBAR_DEFAULT_DURATION_MILLISECONDS,
      vertical = SNACKBAR_DEFAULT_VERTICAL_POSITION,
      horizontal = SNACKBAR_DEFAULT_HORIZONTAL_POSITION,
    ) => {
      setMessage(message);
      setDuration(duration);
      setVertical(vertical);
      setHorizontal(horizontal);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    }

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <CustomSnackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          autoHideDuration={duration}
          onClose={handleClose}
          message={message}>
        </CustomSnackbar>
      </>
    )
  }
};
