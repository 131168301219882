import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PERFORMANCE_CARD_DAYS_SELECT, PERFORMANCE_CARD_DAYS } from '../../dashboard-constants';

const StyledDropDownWrapper = styled('div')(() => ({
  position: 'relative',
}));

const StyledDropDownHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
}));

const StyledDropDownHeadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '18px',
  textDecorationLine: 'none',
  whiteSpace: 'nowrap',
  textDecoration: 'underline',
}));

const StyledDropDownBodyWrapper = styled('div')(({ theme }) => ({
  border: '1px solid #000000',
  position: 'absolute',
  top: theme.spacing(3),
  background: theme.palette.common.white,
  zIndex: 1,
}));

const StyledDropDownBodyOption = styled('div')(({ theme }) => ({
  fontSize: '16px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  '&:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledDropDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default function DropDown({
  selectedDay,
  showDropDown,
  onDropDownClick,
  onSelectDay,
}) {
  return (
    <StyledDropDownWrapper name={PERFORMANCE_CARD_DAYS_SELECT}>
      <StyledDropDownHeadWrapper onClick={onDropDownClick}>
        <StyledDropDownHeadText>
          {selectedDay}
          {' '}
          Days
        </StyledDropDownHeadText>
        <StyledDropDownIcon />
      </StyledDropDownHeadWrapper>
      {showDropDown
        && (
        <StyledDropDownBodyWrapper>
          {
            PERFORMANCE_CARD_DAYS.map((value, i) => (
              <StyledDropDownBodyOption
                key={`performance-card-dropdown-${PERFORMANCE_CARD_DAYS[i]}`}
                onClick={() => onSelectDay(i)}
              >
                {value}
                {' '}
                Days
              </StyledDropDownBodyOption>
            ))
          }
        </StyledDropDownBodyWrapper>
        )}
    </StyledDropDownWrapper>
  );
}

DropDown.propTypes = {
  onDropDownClick: PropTypes.func.isRequired,
  onSelectDay: PropTypes.func.isRequired,
  selectedDay: PropTypes.number.isRequired,
  showDropDown: PropTypes.bool.isRequired,
};
