import * as yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../shared/settings-shared-components';
import { requestNewGrader } from './data/grader-slice';
import { InputCard } from './styles/grader-input-styles';
import GraderInputHeader from './components/GraderInputHeader';
import { ReactComponent as GraderSVG } from './assets/grader-sample.svg';
import { ReactComponent as ChecklistSVG } from './assets/input/checklist.svg';
import { ReactComponent as CompletedCheckSVG } from './assets/input/completed-checklist.svg';
import { ReactComponent as GearSVG } from './assets/input/gear-globe.svg';
import { ReactComponent as MetricsSVG } from './assets/input/metrics.svg';
import GraderSteps from './components/GraderSteps/GraderSteps';
import GraderInputFooter from './components/GraderInputFooter';

const WEBSITE_TO_GRADE = 'graderURL';
const URL_INPUT_ID = 'grader-url-input';
/**
 * Website grader input page
 */
export default function WebsiteGraderInput() {
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    [WEBSITE_TO_GRADE]: yup.string().required('Website URL is a required field'),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [WEBSITE_TO_GRADE]: '',
    },
  });
  const { handleSubmit } = methods;

  const sendGrader = (formData) => {
    const graderUrl = formData[WEBSITE_TO_GRADE];
    dispatch(requestNewGrader({
      websiteUrl: graderUrl,
    }));
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(sendGrader)}>
          <GraderInputHeader />
          <InputCard>
            <Grid container className="inputCardContent">
              <Grid item md={7} xs={12} className="leftImages">
                <div className="imagesWrapper">
                  <GraderSVG />
                </div>
              </Grid>
              <Grid item md={5} xs={12} className="rightInput">
                <Typography className="urlDescription">
                  See how your site and your online presence stack up
                  &nbsp;against competitors for free—in just minutes!
                </Typography>
                <IQFormInput
                  id={URL_INPUT_ID}
                  name={WEBSITE_TO_GRADE}
                  labelText="Website URL"
                  fullWidth
                  placeholder="www.example.com"
                />
                <div className="topSubmitWrapper">
                  <PrimaryButton type="submit">Get Your Free Score</PrimaryButton>
                </div>
              </Grid>
            </Grid>
            <Box className="dividerBox">
              <hr className="lineBreak" />
            </Box>
            <Box className="descriptionSection">
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box className="descriptionContent">
                    <ChecklistSVG />
                    <Typography className="descriptionTitle">
                      More Than Just A Website Grader
                    </Typography>
                    <Typography className="descriptionText">
                      Receive a grade for your overall online presence as well as scores for your 
                      website quality, social and local presence, and digital 
                      advertising.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="descriptionContent">
                    <CompletedCheckSVG />
                    <Typography className="descriptionTitle">
                      Compare Competitors
                    </Typography>
                    <Typography className="descriptionText">
                      See how other businesses perform in comparison to yours.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="descriptionContent">
                    <MetricsSVG />
                    <Typography className="descriptionTitle">
                      Individual Performance Metrics
                    </Typography>
                    <Typography className="descriptionText">
                      Get detailed feedback on how you rank across 17 data 
                      points such as mobile friendliness and code quality.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="descriptionContent">
                    <GearSVG />
                    <Typography className="descriptionTitle">
                      Actionable Recommendations
                    </Typography>
                    <Typography className="descriptionText">
                      Know exactly what to do to improve your online presence with 
                      clear “how to fix this” instructions.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </InputCard>
          <GraderSteps />
          <GraderInputFooter clickTarget={URL_INPUT_ID} />
        </form>
      </FormProvider>
    </ValidationProvider>
  );
}
