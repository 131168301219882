import { createAction, createSlice } from '@reduxjs/toolkit';
import { loadSettingsDataSuccess } from '../../../../shared/app-slice';

const BUSINESS_DETAILS_SLICE_NAME = 'business_details';

export const saveBusinessDetailsAction = createAction(`${BUSINESS_DETAILS_SLICE_NAME}/saveBusiness`);
export const saveBusinessDetailsSuccessAction = createAction(`${BUSINESS_DETAILS_SLICE_NAME}/saveBusinessSuccess`);
export const saveBusinessDetailsFailureAction = createAction(`${BUSINESS_DETAILS_SLICE_NAME}/saveBusinessFailure`);

export const BUSINESS_DETAILS_SECTION = 'businessDetails';
export const BUSINESS_ID_KEY = 'businessId';
export const BUSINESS_USER_ID_KEY = 'userId';
export const BUSINESS_LOCATION_ID_KEY = 'locationId';
export const BUSINESS_GMAID_KEY = 'gmaid';
export const BUSINESS_NAME_KEY = 'businessName';
export const BUSINESS_EMAIL_KEY = 'businessEmail';
export const BUSINESS_WEBSITE_URL_KEY = 'businessUrl';
export const BUSINESS_PHONE_NUMBER_KEY = 'businessPhone';
export const BUSINESS_STREET_ADDRESS_ONE_KEY = 'businessAddressOne';
export const BUSINESS_STREET_ADDRESS_TWO_KEY = 'businessAddressTwo';
export const BUSINESS_CITY_KEY = 'city';
export const BUSINESS_STATE_KEY = 'stateProvince';
export const BUSINESS_COUNTRY_KEY = 'country';
export const BUSINESS_POSTAL_CODE_KEY = 'postalCode';
export const SLOGAN_KEY = 'slogan';
export const BUSINESS_LOGO_SECTION = 'businessLogoSection';
export const BUSINESS_LOGO_UPLOADED_FILES = 'businessLogoFiles';
export const BUSINESS_LOGO_FILE_DATA = 'logoData';
export const BUSINESS_LOGO_FILE_NAME = 'logoFileName';
export const BUSINESS_LOGO_FILE_URL = 'logoUrl';

const businessDetailsSlice = createSlice({
  name: BUSINESS_DETAILS_SLICE_NAME,
  initialState: {
    isLoading: true,
    logoDeleteInitiated: false,
    [BUSINESS_DETAILS_SECTION]: {
      [BUSINESS_ID_KEY]: null,
      [BUSINESS_USER_ID_KEY]: null,
      [BUSINESS_LOCATION_ID_KEY]: null,
      [BUSINESS_GMAID_KEY]: null,
      [BUSINESS_NAME_KEY]: null,
      [BUSINESS_WEBSITE_URL_KEY]: null,
      [BUSINESS_PHONE_NUMBER_KEY]: null,
      [BUSINESS_STREET_ADDRESS_ONE_KEY]: null,
      [BUSINESS_STREET_ADDRESS_TWO_KEY]: null,
      [BUSINESS_CITY_KEY]: null,
      [BUSINESS_STATE_KEY]: null,
      [BUSINESS_COUNTRY_KEY]: null,
      [BUSINESS_POSTAL_CODE_KEY]: null,
      [SLOGAN_KEY]: null,
    },
    [BUSINESS_LOGO_SECTION]: {
      [BUSINESS_LOGO_UPLOADED_FILES]: [],
    }
  },
  reducers: {
    updateBusinessDetailsField: (state, action) => {
      state[BUSINESS_DETAILS_SECTION][action.payload.fieldName] = action.payload.fieldValue;
    },
    updateBusinessDetails: (state, action) => {
      const payload = action.payload;
      const mergedData = Object.assign(state[BUSINESS_DETAILS_SECTION], payload);
      state[BUSINESS_DETAILS_SECTION] = mergedData;
    },
    saveBusinessSuccess: (state, action) => {
      delete state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_NAME];
      delete state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_DATA];
      const {
        logoUploadId, logoFileName, logoFileSize, logoUrl,
      } = action.payload;
      if (logoFileName && logoFileSize && logoUrl) {
        const uploadedLogo = {
          id: logoUploadId,
          fileName: logoFileName,
          fileSize: logoFileSize,
          url: logoUrl,
        };
        
        state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_URL] = logoUrl;
        state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_NAME] = logoFileName;
        state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_UPLOADED_FILES] = [uploadedLogo];
        state.logoDeleteInitiated = false;
      }
    },
    uploadBusinessLogo: (state, action) => {
      const { files, data } = action.payload;
      // Clear out any old logo
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_URL] = null;
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_DATA] = data;
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_NAME] = files[0].path;
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_UPLOADED_FILES] = [];
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_UPLOADED_FILES].push(files[0]);
      state.logoDeleteInitiated = false;
    },
    deleteBusinessLogo: (state) => {
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_DATA] = null;
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_NAME] = null;
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_URL] = null;
      state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_UPLOADED_FILES] = [];
      state.logoDeleteInitiated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      state.isLoading = false;
      const { business } = action.payload;

      if (business) {
        const {
          name, businessUrl, businessPhone, locations,
          gmaid, userId, businessId, logoUploadId, logoFileName, logoFileSize, logoUrl, slogan,
        } = business;
        const {
          address, address2, city, province, postalCode, country, locationId,
        } = locations[0];
        state[BUSINESS_DETAILS_SECTION][BUSINESS_ID_KEY] = businessId;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_USER_ID_KEY] = userId;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_LOCATION_ID_KEY] = locationId;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_GMAID_KEY] = gmaid;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_NAME_KEY] = name;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_WEBSITE_URL_KEY] = businessUrl;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_PHONE_NUMBER_KEY] = businessPhone;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_STREET_ADDRESS_ONE_KEY] = address;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_STREET_ADDRESS_TWO_KEY] = address2;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_CITY_KEY] = city;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_STATE_KEY] = province;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_COUNTRY_KEY] = country;
        state[BUSINESS_DETAILS_SECTION][BUSINESS_POSTAL_CODE_KEY] = postalCode;
        state[BUSINESS_DETAILS_SECTION][SLOGAN_KEY] = slogan;

        if (logoUploadId && logoFileName && logoFileSize && logoUrl) {
          const uploadedLogo = {
            id: logoUploadId,
            fileName: logoFileName,
            fileSize: logoFileSize,
            url: logoUrl,
          };

          state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_URL] = logoUrl;
          state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_FILE_NAME] = logoFileName;
          state[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_UPLOADED_FILES] = [uploadedLogo];
        }
      }
    });
  },
});

export const {
  loadBusinessDataSuccess,
  updateBusinessDetailsField,
  updateBusinessDetails,
  uploadBusinessLogo,
  deleteBusinessLogo,
} = businessDetailsSlice.actions;
export default businessDetailsSlice.reducer;
