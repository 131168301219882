import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SectionLayout from '../../../../shared/section/SectionLayout';
import EmailNotificationsCard from '../components/cards/EmailNotificationsCard';
import NotificationsSettingsCard from '../components/cards/NotificationsSettingsCard';
import SMSNotificationsCard from '../components/cards/SMSNotificationsCard';
import WithDisabled from '../../../tools/components/WithDisabled';
import SMSNotificationDashCard from '../components/cards/SMSNotificationDashCard';
import AutoReplyCard from '../components/cards/AutoReplyCard';

export default function Notifications({ isDisabled }) {
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);
  const isSupportRole = useSelector((state) => state.appSettings.user.hasSupportRole);
  
  return (
    <WithDisabled isDisabled={isDisabled && !isSupportRole}>
      <div>
        <SectionLayout
          name="notifications"
          headerText="Notifications"
          headerDescription="Customize your notification settings below."
        />
        <EmailNotificationsCard />
        {
          loadedSettings && (
            <>
              <SMSNotificationsCard />
              {isSupportRole && <SMSNotificationDashCard />}
              <NotificationsSettingsCard />
            </>
          )
        }
        <AutoReplyCard />
      </div>
    </WithDisabled>
  );
}

Notifications.propTypes = {
  isDisabled: PropTypes.bool,
};
