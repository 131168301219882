import { ORDER_STATUS_CANCELLED } from '../../../shared/app-constants';
import { CREDIT_CARDS_PAGE, INVOICES_PAGE, SUBSCRIPTIONS_PAGE } from '../../../shared/navigation/navigation-config';
import { loadSubscriptions } from './data/subscriptions-slice';
import { loadInvoices } from './data/invoices-slice';
import { loadCreditCards } from './data/credit-cards-slice';
import { ERROR_TYPE, SUCCESS_TYPE, FREE_TIER, LITE_TIER, INFO_TYPE} from '../../../shared/app-constants';
import {
  SUBSCRIPTION_DISPLAY_TITLE_CONFIG,
  ADD_CC_SUCCESS_BANNER,
  ADD_CC_FAILURE_BANNER,
  EDIT_CC_SUCCESS_BANNER,
  EDIT_CC_FAILURE_BANNER,
  DELETE_CC_SUCCESS_BANNER,
  DELETE_CC_FAILURE_BANNER,
  EDIT_CARD_SUCCESS,
  EDIT_CARD_FAILURE,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
  EXPIRED_CARD,
  INSUFFICIENT_FUNDS,
  INCORRECT_NUMBER,
  INCORRECT_CVC,
  INVALID_ACCOUNT,
  DO_NOT_HONOR,
  TRANSACTION_NOT_ALLOWED,
  LOAD_SUBSCRIPTIONS_FAILED,
  LOAD_SUBSCRIPTIONS_FAILED_TEXT,
  CHANGE_PAYMENT_SUCCESS,
  CHANGE_PAYMENT_SUCCESS_TEXT,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS_TEXT,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_FAILED_TEXT,
  ACCEPT_SALVAGE_OFFER_SUCCESS,
  ACCEPT_SALVAGE_OFFER_SUCCESS_TEXT,
  JOOMLA_KEY,
  WORDPRESS_KEY,
  DRUPAL_KEY,
  JOOMLA_SUPPORT_LINK,
  DRUPAL_SUPPORT_LINK,
  WORDPRESS_SUPPORT_LINK
} from './account-constants';

export function createSubscriptionTitle(items, bundleSlug) {
  if (bundleSlug && items) {
    return `Lead Capture Toolkit`;
  }

  // Need to ignore cancelled items
  // to ensure upgrades show the correct title
  if (items && items.length > 0) {
    for (let i = 0; i < items?.length; i++) {
      if (items[i].status !== ORDER_STATUS_CANCELLED || (items[i].productType?.tier !== FREE_TIER && items[i].productType?.tier !== LITE_TIER)) {
        const slug = items[i].productType.slug;
        const tier = items[i].productType.tier;
        const productDisplayTitle = SUBSCRIPTION_DISPLAY_TITLE_CONFIG[slug][tier];
        if (productDisplayTitle) {
          return productDisplayTitle;
        }
      }
    }
  }

  return '';
}

export function addDayAbbreviation(day) {
  let lastDigit = day[day.length - 1];
  switch (lastDigit) {
    case '1':
      return day + 'st'
    case '2':
      return day + 'nd'
    case '3':
      return day + 'rd'
    default:
      return day + 'th'
  }
}


export function loadSectionData(section, dispatch, hasPaidSub) {
  switch (section) {
    case SUBSCRIPTIONS_PAGE:
      dispatch(
        loadSubscriptions()
      )
      break;
    case INVOICES_PAGE:
      dispatch(
        loadInvoices({
          currentPage: 0,
          totalDisplayPerPage: 10,
        })
      )
      break;
    case CREDIT_CARDS_PAGE:
      if (hasPaidSub) {
        dispatch(loadCreditCards())
      }
      break;
  }
}

export function getCreditCardActionBanner(type) {
  let actionType;
  let actionText;

  switch (type) {
    case ADD_CARD_SUCCESS:
      actionType = SUCCESS_TYPE;
      actionText = ADD_CC_SUCCESS_BANNER
      break;
    case ADD_CARD_FAILURE:
      actionType = ERROR_TYPE;
      actionText = ADD_CC_FAILURE_BANNER
      break;
    case EDIT_CARD_SUCCESS:
      actionType = SUCCESS_TYPE;
      actionText = EDIT_CC_SUCCESS_BANNER
      break;
    case EDIT_CARD_FAILURE:
      actionType = ERROR_TYPE;
      actionText = EDIT_CC_FAILURE_BANNER
      break;
    case DELETE_CARD_SUCCESS:
      actionType = SUCCESS_TYPE;
      actionText = DELETE_CC_SUCCESS_BANNER
      break;
    case DELETE_CARD_FAILURE:
      actionType = ERROR_TYPE;
      actionText = DELETE_CC_FAILURE_BANNER
      break;
    default:
      break;
  }

  return {
    actionType,
    actionText
  }
}

export function getStripeErrorMessage(err, cardNum) {
  let errMsg;
  switch (err) {
    case EXPIRED_CARD:
      errMsg = `Your card ending in (****${cardNum}) has expired. Please use another card.`;
      break;
    case INSUFFICIENT_FUNDS:
      errMsg = `Your card ending in (****${cardNum}) has insufficient funds. Please use another card..`;
      break;
    case INCORRECT_NUMBER:
      errMsg = `Your card ending in (****${cardNum}) has an incorrect card number. Please update your card number or use another card.`;
      break;
    case INCORRECT_CVC:
      errMsg = `Your card ending in (****${cardNum}) has expired. Please use another card.`;
      break;
    case INVALID_ACCOUNT:
      errMsg = `Your card ending in (****${cardNum}) is invalid. Please contact your bank or use another card.`;
      break;
    case DO_NOT_HONOR:
      errMsg = `Your card ending in (****${cardNum}) has been declined. Please contact your bank or use another card.`;
      break;
    case TRANSACTION_NOT_ALLOWED:
      errMsg = `Your card ending in (****${cardNum}) has been declined. Please contact your bank or use another card.`;
      break;
    default:
      errMsg = `Your card ending in (****${cardNum}) has been declined. Please use another card.`;
      break;
  }

  return errMsg;
}

export function getSubscriptionsActionBanner(type) {
  let actionType;
  let actionText;

  switch (type) {
    case LOAD_SUBSCRIPTIONS_FAILED:
      actionType = ERROR_TYPE;
      actionText = LOAD_SUBSCRIPTIONS_FAILED_TEXT;
      break;
    case CHANGE_PAYMENT_SUCCESS:
      actionType = SUCCESS_TYPE;
      actionText = CHANGE_PAYMENT_SUCCESS_TEXT;
      break;
    case CANCEL_SUBSCRIPTION_SUCCESS:
      actionType = INFO_TYPE;
      actionText = CANCEL_SUBSCRIPTION_SUCCESS_TEXT;
      break;
    case CANCEL_SUBSCRIPTION_FAILED:
      actionType = ERROR_TYPE;
      actionText = CANCEL_SUBSCRIPTION_FAILED_TEXT;
      break;
    case ACCEPT_SALVAGE_OFFER_SUCCESS:
      actionType = INFO_TYPE;
      actionText = ACCEPT_SALVAGE_OFFER_SUCCESS_TEXT;
      break;
    default:
      break;
  }

  return {
    actionText,
    actionType,
  }
}

export function formatNextPaymentTimestamp(timestamp) {
  const date = new Date(Number(timestamp) * 1000);
  return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
}

const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

/**
 * Returns the ordinal suffix for the given date.
 *
 * @param {Date} date
 * @returns {string} The ordinal suffix for the date (e.g. 1st, 2nd, 3rd, 4th, etc.)
 */
function getOrdinalSuffix(date) {
  const day = date.getDate();
  if (day % 10 === 1 && day !== 11) {
    return `${day}st`;
  }
  if (day % 10 === 2 && day !== 12) {
    return `${day}nd`;
  }
  if (day % 10 === 3 && day !== 13) {
    return `${day}rd`;
  }
  return `${day}th`;
}

/**
 * Returns date in MMMM Do, YYYY format
 *
 * @param {number} timestamp
 * @returns {string} The formatted date (e.g. Jan 1st, 2021)
 */
export function formatNextPaymentTimestampShort(timestamp) {
  const date = new Date(Number(timestamp) * 1000);
  const monthNameShort = monthShortNames[date.getMonth()];
  const suffix = getOrdinalSuffix(date);
  const year = date.getFullYear();
  return `${monthNameShort} ${suffix}, ${year}`;
}

/**
 * Returns date in MM/DD/YYYY format
 *
 * @param {number} timestamp
 * @returns {string} The formatted date (e.g. 01/01/2021)
 */
export function formatTimestampIntoMMDDYYYY(timestamp) {
  const date = new Date(Number(timestamp) * 1000);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const mm = month < 10 ? `0${month}` : month;
  const dd = day < 10 ? `0${day}` : day;
  const yyyy = date.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
}

export function getStepInfo(websiteType) {
  switch (websiteType) {
    case JOOMLA_KEY:
      return {
        stepTitle: "Get Joomla Extension",
        stepText: <> First, follow this link to find the Tracking Code Extension ("ReachEdge"). Download the latest release, install it, and enable it on your website. (Extension Website:
          <a href={JOOMLA_SUPPORT_LINK} target="_blank"> {JOOMLA_SUPPORT_LINK} </a>)</>
      }
    case WORDPRESS_KEY:
      return {
        stepTitle: "Get WordPress Plugin",
        stepText: <>First, follow this link to find the Tracking Code WordPress Plugin page ("LocaliQ: Tracking Code"), add it, and activate it on your website (Plugin Website: 
          <a href={WORDPRESS_SUPPORT_LINK} target="_blank" > {WORDPRESS_SUPPORT_LINK} </a>)</>
      }
    case DRUPAL_KEY:
      return {
        stepTitle: "Get Drupal Module",
        stepText: <>First, follow this link to find the Tracking Code Module ("LocaliQ"). Download the latest release, install it, and enable it on your website. (Module Website:
          <a href={DRUPAL_SUPPORT_LINK} target="_blank"> {DRUPAL_SUPPORT_LINK} </a>)</>
      }
  }
}