// Section constants
export const WEBSITE_QUALITY = 'websiteBuild';
export const PRESENCE = 'social';
export const DIGITAL_ADVERTISING = 'advertising';

// Grader result status constants
export const RUNNING_GRADER = 'running';
export const EXISTING_GRADER = 'existing';
export const COMPLETE_GRADER = 'complete';
export const ERROR_GRADER = 'error';

// New grader request types
export const MAIN = 'MAIN';
export const COMPETITOR = 'COMPETITOR';

export const FADE_TIMING = 1500;

// Analytics constants
export const IN_PRODUCT = 'In Product';
export const EXTERNAL = 'External';
export const MAIN_TYPE = 'Main';
export const COMPETITOR_TYPE = 'Competitor';
