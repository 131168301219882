import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_TYPE } from '../../../../shared/app-constants';
import { dismissErrorBanner } from '../data/outlook-calendar-slice';
import BannerCard from './BannerCard';

/**
 * Component for displaying the banner errors relates to Outlook Calendar
 */
export default function OutlookCalendarErrors() {
  const dispatch = useDispatch();
  const showErrorBanner = useSelector((state) => state.outlookCalendar.showErrorBanner);

  const onDismiss = useCallback(() => {
    dispatch(
      dismissErrorBanner(),
    );
  }, [dispatch, dismissErrorBanner]);

  return (
    <BannerCard
      id="outlook-calender-error-banner"
      title="Outlook Connection Failed"
      description="Please try again."
      showBanner={showErrorBanner}
      dismissBanner={onDismiss}
      bannerType={ERROR_TYPE}
    />
  );
}
