import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { PERFORMANCE_DECREASING, PERFORMANCE_INCREASING, PERFORMANCE_ICONS } from '../../dashboard-constants';
import { getPerformanceFontSize } from '../../dashboard-utils';

const StyledPerformanceCardRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: theme.spacing(2.5, 0),
  '& .MuiGrid-item': {
    border: `none`,
  },
  '&:hover': {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    strokeWidth: '1px',
  },
}));

const StyledName = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.15px',
}));

const StyledCount = styled(Typography, { shouldForwardProp: (prop) => prop !== 'countFontSize' })(({ countFontSize }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: `${countFontSize}px`,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '80%',
}));

const StyledNotActive = styled(StyledCount)(({ theme }) => ({
  fontSize: `24px`,
  color: theme.palette.text.secondary,
}));

const StyledProgressStatusBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledProgressPercentage = styled(Typography,
  {
    shouldForwardProp: (prop) => prop !== 'isUpwardTrend' && prop !== 'isZeroTrend'
  })(({ theme, isZeroTrend, isUpwardTrend }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '0.5px',
  color: isZeroTrend ? 'unset' : isUpwardTrend ? theme.palette.success.dark : theme.palette.error.main,
}));

const StyledProgressNotActive = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.text.secondary,
  fontWeight: 600,
}));

const StyledProductIcon = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}));

export default function PerformanceCardRow({ name, count, trend, isZeroTrend, isUpwardTrend, isActivated }) {
  const countFontSize = getPerformanceFontSize(count);
  const countFormatted = `${count}`.padStart(2, '0');
  const productIcon = PERFORMANCE_ICONS[name];
  // Don't show any icon if a trend has zero value
  const performanceIcon = isZeroTrend 
    ? null
    : PERFORMANCE_ICONS[isUpwardTrend ? PERFORMANCE_INCREASING : PERFORMANCE_DECREASING];
  const performancePercentage = `${trend}%`;

  return (
    <StyledPerformanceCardRow>
      { isActivated ? (
        <StyledCount countFontSize={countFontSize}>
          {countFormatted}
        </StyledCount>
      ) : (<StyledNotActive>N/A</StyledNotActive>)}
      <div>
        <StyledName>{name}</StyledName>
        <StyledProgressStatusBox>
          { isActivated
            ? (
              <>
                {performanceIcon}
                <StyledProgressPercentage isZeroTrend={isZeroTrend} isUpwardTrend={isUpwardTrend}>
                  {performancePercentage}
                </StyledProgressPercentage>
              </>
            )
            : (<StyledProgressNotActive>N/A</StyledProgressNotActive>
            )}
        </StyledProgressStatusBox>
      </div>
      <StyledProductIcon container item xs={1} md={1}>{productIcon}</StyledProductIcon>
    </StyledPerformanceCardRow>
  );
}

PerformanceCardRow.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  trend: PropTypes.number,
  isUpwardTrend: PropTypes.bool,
  isActivated: PropTypes.bool,
};
