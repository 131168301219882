import PropTypes from 'prop-types';
import CreditCardCard from './cards/CreditCardCard';
import styled from '@emotion/styled';

const CreditCardsListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  }
}));

export default function CreditCardList({ creditCards }) {
  return (
    <CreditCardsListWrapper>
      {creditCards.map((cc) => {
        return <CreditCardCard key={cc.paymentMethodId} hasError={cc.hasActiveCardAlert} {...cc} />
      })}
    </CreditCardsListWrapper>
  )
}

CreditCardList.propTypes = {
  creditCards: PropTypes.array.isRequired,
};

