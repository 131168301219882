import SuccessIcon from '../images/success-icon.svg';
import ErrorIcon from '../images/error-icon.svg';
import InfoIcon from '../images/informational-icon.svg';
import WarningIcon from '../images/warning-icon.svg';
import ColorSelectedBlackIcon from '../images/color-selected-black-icon.svg';
import ColorSelectedWhiteIcon from '../images/color-selected-white-icon.svg';
import { WidgetIconSvg as WidgetIconSvgScheduling } from '../pages/products/bookings/svg/WidgetIconSvg';
import { WidgetIconSvg as WidgetIconSvgChatbot } from '../pages/products/chatbot/svg/WidgetIconSvg';
import { WidgetIconSvg as WidgetIconSvgCallTracker } from '../pages/products/callSettings/svg/WidgetIconSvg';
import { WidgetIconSvg as WidgetIconSvgContactForm } from '../pages/products/contactForm/svg/WidgetIconSvg';

export const ORDER_STATUS_PENDING = 'PENDING';
export const ORDER_STATUS_SUBMITTED = 'SUBMITTED';
export const ORDER_STATUS_CANCELLED = 'CANCELLED';

export const OUTLOOK_AUTH_FINISHED_EVENT = 'outlookAuthFinished';

export const SUPPORT_EMAIL = 'mysupport@localiq.com';
export const SUPPORT_PHONE_NUMBER = '818.274.0225';
export const SCHEDULE_A_CALL = 'https://scheduler.localiq.com/scheduler#/3f7857f556e74a6'
export const CONTACT_TIMES = 'M-F 9AM-5PM EASTERN';

// Paths
export const SCHEDULER_PATH = '/scheduling';
export const OLD_SCHEDULER_PATH = '/scheduler';
export const CALL_SETTINGS_PATH = '/call_settings';
export const OLD_CALL_RECORDER_PATH = '/call_recorder';
export const CHATBOT_PATH = '/chatbot';
export const OLD_CHATBOT_PATH = '/settings/chatbot';
export const OUTLOOK_AUTH_CALLBACK_PATH = '/auth/callback';
export const CONTACT_US_PATH = '/contact_us';
export const ADMIN_PATH = '/product/admin';
export const ACCOUNT_PATH = '/account';
export const ACCOUNT_CC_PATH = `${ACCOUNT_PATH}/credit-cards`;
export const ACCOUNT_SUBSCRIPTIONS_PATH = `${ACCOUNT_PATH}/subscriptions`;
export const ACCOUNT_INVOICES_PATH = `${ACCOUNT_PATH}/invoices`;
export const CONFIG_PATH = '/configuration';
export const WIDGET_SETTINGS_PATH = '/settings/localiqsettings';
export const ENGAGEMENT_WIDGET_SETTINGS_PATH = `${WIDGET_SETTINGS_PATH}/engagement-widget`;
export const LOCALIQ_CODE_SETTINGS_PATH = `${WIDGET_SETTINGS_PATH}/localiq-code`;
export const TOOLS_PATH = '/settings/tools';
export const TOOL_PATH_SCHEDULER = `${TOOLS_PATH}/scheduling`;
export const LISTINGS_GRADER_PATH = '/listings/scan';
export const DASHBOARD_PATH = '/dashboard';
export const GRADER_PATH = '/website-grader';
export const GRADER_REDIRECT = '/grader/results';
export const NOT_FOUND = '/not-found';
// New Paths for suite
export const CONTACT_FORM_PATH = '/contactForm';

// Array of public paths
export const PUBLIC_PATHS = [
  GRADER_REDIRECT,
];

// Product constants
export const CHATBOT_SLUG = 'chat';
export const CALL_TRACKER_SLUG = 'calltracker';
export const PREMIUM_LISTINGS_SLUG = 'premium_listings';
export const SCHEDULER_SLUG = 'scheduler';
export const ACCOUNT_SLUG = 'account';
export const WIDGET_SETTINGS_SLUG = 'widgetSettings';
export const BUNDLE_SLUG = 'bundle';

export const TOOLS_KEY = 'tools';

// TODO: Contact Form Change Slug
export const CONTACT_FORM_SLUG = 'message';

// Pro Product constants
export const CHATBOT_PRO_SLUG = 'Chatbot Pro';
export const PREMIUM_LISTINGS_PRO_SLUG = 'Premium Listings Pro';
export const SCHEDULER_PRO_SLUG = 'Scheduling Pro';

// Product tiers
export const PRO_TIER = 'pro';
export const FREE_TIER = 'free';
export const PRO_REVIEW_TIER = 'pro_review';
export const LITE_TIER = 'lite';
export const INITIAL_BUNDLE_TIER = 'leadcapturetoolkit';

// action cards
export const SUCCESS_TYPE = 'SUCCESS_TYPE';
export const ERROR_TYPE = 'ERROR_TYPE';
export const INFO_TYPE = 'INFO_TYPE';
export const WARNING_TYPE = 'WARNING_TYPE';
export const VALID_TYPES = [SUCCESS_TYPE, ERROR_TYPE, INFO_TYPE, WARNING_TYPE];

// TrackJS
export const TRACKJS_USERID_METADATA_KEY = 'user_id';
export const TRACKJS_USER_INTEXT_METADATA_KEY = 'internal_external';

export const ActionIcons = {
  SuccessIcon: SuccessIcon,
  ErrorIcon: ErrorIcon,
  InfoIcon: InfoIcon,
  WarningIcon: WarningIcon,
  style: { width: '32px' }
}

// Currencies
export const USD_CURRENCY = 'USD';

export const NON_DIALOG_PATHS = [
  GRADER_PATH,
];

// modal constants
export const BACKDROP_CLICK = 'backdropClick';

// Color Widget Constants
export const DEFAULT_WIDGET_COLOR = '#444444';

export const COLOR_WIDGET_COLORS = [
  {
    color: '#444444',
    themeName: 'SLATEGREY',
    icon: <img src={ColorSelectedWhiteIcon} alt="slategrey color." />
  },
  {
    color: '#77D6FF',
    themeName: 'VIVIDCERULEAN',
    icon: <img src={ColorSelectedBlackIcon} alt="vividcerulean color." />
  },
  {
    color: '#FFBF3F',
    themeName: 'WARMGOLDENROD',
    icon: <img src={ColorSelectedBlackIcon} alt="warmgoldenrod color." />
  },
  {
    color: '#0065DB',
    themeName: 'CLASSICBLUE',
    icon: <img src={ColorSelectedWhiteIcon} alt="classicblue color." />
  },
  {
    color: '#004A60',
    themeName: 'MEDITERRANEANSHADE',
    icon: <img src={ColorSelectedWhiteIcon} alt="mediterranshade color." />
  },
  {
    color: '#D24600',
    themeName: 'BURNTORANGE',
    icon: <img src={ColorSelectedWhiteIcon} alt="burntorange color." />
  },
  {
    color: '#FFE2E3',
    themeName: 'CREAMYSALMON',
    icon: <img src={ColorSelectedBlackIcon} alt="creamysalmon color." />
  },
  {
    color: '#63F9D4',
    themeName: 'MIAMIGREEN',
    icon: <img src={ColorSelectedBlackIcon} alt="miamigreen color." />
  },
];

export const DARK_WIDGET_COLORS = ['#444444', '#0065DB', '#004A60', '#D24600'];

export const COLOR_TO_THEME = COLOR_WIDGET_COLORS.reduce((acc, colorObj) => {
  acc[colorObj.color] = colorObj.themeName;
  return acc;
}, {});

export const THEME_TO_COLOR = COLOR_WIDGET_COLORS.reduce((acc, colorObj) => {
  acc[colorObj.themeName] = colorObj.color;
  return acc;
}, {});

export const PRODUCT_TO_WIDGET_SVG = ({ product, color = '#FFBF3F', width = '81', height = '81' }) => {
  const svgProps = {
    color,
    width,
    height,
    fillColor: DARK_WIDGET_COLORS.includes(color) ? 'white' : 'black',
  };

  switch (product) {
    case SCHEDULER_SLUG:
      return <WidgetIconSvgScheduling {...svgProps} />
    case CHATBOT_SLUG:
      return <WidgetIconSvgChatbot {...svgProps} />
    case CALL_TRACKER_SLUG:
      return <WidgetIconSvgCallTracker {...svgProps} />
    case CONTACT_FORM_SLUG:
      return <WidgetIconSvgContactForm {...svgProps} />
  }
};

export const COLOR_KEY = 'theme';
export const COLOR_FORM = 'themeForm';
export const BUSINESS_ATTRIBUTES_SECTION = 'businessAttributes';
export const DISMISS_BANNER_SECTION = 'dismissedBanners';
export const COLOR_FORM_KEYS = [BUSINESS_ATTRIBUTES_SECTION];

export const BUSINESS_PHONE_ID = 'business-phone';

// Settings data keys
export const FREE_TRIAL_KEY = 'FREE_TRIAL';
export const BUSINESS_LOCATIONS_KEY = 'BUSINESS_LOCATIONS';
export const BUSINESS_ATTRIBUTES_KEY = 'BUSINESS_ATTRIBUTES';
export const BUSINESS_KEY = 'BUSINESS';
export const USER_KEY = 'USER';
export const ORDERS_KEY = 'ORDERS';
export const ORDER_ITEMS_KEY = 'ORDER_ITEMS';
export const PRODUCT_INFO_KEY = 'PRODUCT_INFO';
export const PRODUCT_PERMISSIONS_KEY = 'PRODUCT_PERMISSIONS';
export const SUBSCRIPTIONS_KEY = 'SUBSCRIPTIONS';
export const PAYMENT_METHODS_KEY = 'PAYMENT_METHOD';
export const SCHEDULER_URLS_KEY = 'SCHEDULER_URLS';
export const WIZARD_KEY = 'WIZARD';

// Trial status
export const TRIAL_EXPIRED = 'EXPIRED';

// Reg Template Options
export const REG_TEMPLATE_GROWTH_SUITE = 'growthsuite';

// Wizard Status
export const WIZARD_STATUS_ACTIVE = 'ACTIVE';
export const WIZARD_STATUS_COMPLETED = 'COMPLETED';

// Source Params
export const SOURCE_QUERY_PARAM_KEY = 'source';
export const SOURCE_CHECKOUT = 'checkout';

// Trial Status
export const TRIAL_STATUS_ACTIVE = 'ACTIVE';
export const TRIAL_STATUS_EXPIRED = 'EXPIRED';

// Roles
export const SUPPORT_ROLE = "SUPPORT";
export const ADMIN_ROLE = "ADMIN";