import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { IQSwitch } from '@gannettdigital/shared-react-components';
import { ReactComponent as SettingIcon } from '../assets/settings-gear.svg';
import { SwitchWrapperOverride } from '../../../../shared/settings-shared-components';

const StyledFeatureWrapper = styled('div')(() => (() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '1rem 0',
})));

const StyledFeatureInfoWrapper = styled('div')(() => (() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  alignItems: 'center',
})));

const StyledFeatureTextWrapper = styled('div')(() => (() => ({
  display: 'flex',
  flexDirection: 'column',
})));

const StyledFeatureTitle = styled(Typography)(() => (() => ({
  fontSize: 16,
  fontFamily: 'Unify Sans Semibold',
})));

const SettingsLinkTitle = styled('a')(() => (({ theme }) => ({
  fontSize: 16,
  color: theme.palette.primary.main,
  textDecoration: 'underline',
})));

const StyledSwitchWrapper = styled(SwitchWrapperOverride)(({ theme }) => ({
  display: 'flex',
  gap: '4.5rem',
  alignItems: 'center',
  '& .MuiFormControlLabel-root': {
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.down('sm')]: {
    gap: 0,
  },
}));

function WidgetFeatureItem({ id, title, description, icon, settingsLink, switchName, switchValue, switchOnChange, switchIsDisabled }) {
  const settingsLinkDesktop = settingsLink && !isMobile;
  const settingsLinkMobile = settingsLink && isMobile;

  return (
    <StyledFeatureWrapper>
      <StyledFeatureInfoWrapper>
        <img src={icon} style={{ width: '2.5rem' }} alt={`${title} icon`} id={`${id}-icon`} />
        <StyledFeatureTextWrapper>
          <StyledFeatureTitle id={`${id}-title`}> {title} </StyledFeatureTitle>
          <Typography id={`${id}-description`}> {description} </Typography>
          {settingsLinkMobile && <SettingsLinkTitle target="_self" href={settingsLink} id={`${id}-settings-link`}> Settings </SettingsLinkTitle>}
        </StyledFeatureTextWrapper>
      </StyledFeatureInfoWrapper>
      <StyledSwitchWrapper>
        {settingsLinkDesktop && <a aria-label={id} href={settingsLink} target="_blank" id={`${id}-settings-link`}><SettingIcon id={`${id}-settings-icon`} /></a>}
        <IQSwitch
          id={id}
          name={switchName}
          value={switchValue}
          onChange={(e) => switchOnChange(e, switchValue)}
          disabled={switchIsDisabled}
        />
      </StyledSwitchWrapper>
    </StyledFeatureWrapper>
  )
};

export default WidgetFeatureItem;

WidgetFeatureItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  settingsLink: PropTypes.string,
  switchName: PropTypes.string.isRequired,
  switchValue: PropTypes.string.isRequired,
  switchOnChange: PropTypes.func.isRequired,
  switchIsDisabled: PropTypes.bool,
};

