import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isMobile } from 'react-device-detect';
import { useCallback, useEffect, useState } from 'react';
import { ValidationProvider, SectionCard, IQSwitch } from '@gannettdigital/shared-react-components';
import { ReactComponent as ErrorIcon } from '../../../../images/error-icon-custom.svg';
import {
  CALL_FORWARDING_FORM,
  CALL_RECORDING_ACTIVE_KEY,
  CURRENT_FORWARDING_NUMBER,
  GENERATE_NUMBERS_FAILED,
  NEW_BASE_NUMBER_KEY,
  ORIGINAL_NUMBER,
  SELECTED_FORWARDING_NUMBER_KEY,
} from '../call-settings-constants';
import { AdjustCardWrapper, ErrorMessage, SettingsSwitchWrapper } from '../../../../shared/settings-shared-components';
import { generateForwardingNumbers, updateGeneralSettingsField } from '../data/general-settings-slice';
import CallSettingsStateChangedHook from '../../../../shared/stateChangedHook/CallSettingsStateChangedHook';
import { generateLabel } from '../call-recorder-utils';
import CallRecordingOptionsSection from './components/CallRecordingOptionsSection';
import { ReactComponent as InfoIcon } from '../../../../shared/assets/info-icon.svg';

const DESCRIPTION_TEXT = 'When Call Recording is "ON", calls to your forwarding number will be directed to your business number and recorded.';

const VIEW_DISPLAY = 'view';
const EDIT_DISPLAY = 'edit';

const labelBase = 'Record Incoming Calls is';

const FORM_KEYS = [CALL_RECORDING_ACTIVE_KEY, SELECTED_FORWARDING_NUMBER_KEY];

const CustomError = styled(ErrorIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  marginRight: theme.spacing(1),
  verticalAlign: 'bottom',
}));

export default function CallRecordingCard() {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [currentDisplay, setCurrentDisplay] = useState(VIEW_DISPLAY);

  const {
    [CURRENT_FORWARDING_NUMBER]: currentForwardingNumber,
    [NEW_BASE_NUMBER_KEY]: editingBaseNumber,
    [ORIGINAL_NUMBER]: originalNumber,
    [CALL_RECORDING_ACTIVE_KEY]: callRecorderEnabled,
    [GENERATE_NUMBERS_FAILED]: generateNumbersFailed,
  } = useSelector((state) => state.callSettings.generalSettings);

  const showChangesSaved = useSelector((state) => state.callSettings.showChangesSaved);
  const callRecordingLabel = generateLabel(callRecorderEnabled, labelBase, 'ON', 'OFF');

  const handleGenerate = useCallback((newVal) => {
    if (newVal) {
      dispatch(generateForwardingNumbers({
        originalNumber: editingBaseNumber || originalNumber,
      }));
      setCurrentDisplay(EDIT_DISPLAY);
    } else {
      setCurrentDisplay(VIEW_DISPLAY);
      dispatch(updateGeneralSettingsField({
        fieldName: CALL_RECORDING_ACTIVE_KEY,
        fieldValue: false,
      }));
    }
  });

  const schema = yup.object().shape({
    [CALL_RECORDING_ACTIVE_KEY]: yup.bool(),
    [SELECTED_FORWARDING_NUMBER_KEY]: yup.string(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [SELECTED_FORWARDING_NUMBER_KEY]: currentForwardingNumber,
      [CALL_RECORDING_ACTIVE_KEY]: callRecorderEnabled,
    },
  });

  const { setValue } = methods;

  useEffect(() => {
    // A way to go back to view state after save, may need to be more complex.
    if (showChangesSaved && isSaving && currentDisplay === EDIT_DISPLAY) {
      setCurrentDisplay(VIEW_DISPLAY);
      setIsSaving(false);
    }
  }, [showChangesSaved, currentDisplay, isSaving]);

  useEffect(() => {
    if (editingBaseNumber === null) {
      setCurrentDisplay(VIEW_DISPLAY);
    }
  }, [editingBaseNumber]);

  useEffect(() => {
    // If we failed and have no current number, we have to "reset" them by flipping switch off
    if (generateNumbersFailed && !currentForwardingNumber) {
      setValue(CALL_RECORDING_ACTIVE_KEY, false);
      dispatch(updateGeneralSettingsField({
        fieldName: CALL_RECORDING_ACTIVE_KEY,
        fieldValue: false,
      }));
    }
  }, [generateNumbersFailed, currentForwardingNumber]);

  return (
    <AdjustCardWrapper>
      <SectionCard
        id="call-recording-card"
        title="Call Recording"
        description={DESCRIPTION_TEXT}
        showDividingLine={false}
        shouldHaveLabelTooltip={false}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <SettingsSwitchWrapper>
              <IQSwitch
                id="callRecorder-activation-toggle"
                name={CALL_RECORDING_ACTIVE_KEY}
                label={callRecordingLabel}
                onChange={handleGenerate}
                disabled={!originalNumber && !editingBaseNumber}
                tooltipText={`Turning Call Recording "On" will update your Call Widget to display your forwarding number.`}
                tooltipIcon={InfoIcon}
                unboxed
                size={isMobile ? 'sm' : 'md'}
              />
              {(!originalNumber && !editingBaseNumber) && (
                <ErrorMessage>
                  <CustomError />
                  A valid business number is required to enable Call Recording features.
                </ErrorMessage>
              )}
            </SettingsSwitchWrapper>
            {(callRecorderEnabled || generateNumbersFailed) && <CallRecordingOptionsSection />}
            <CallSettingsStateChangedHook
              formName={CALL_FORWARDING_FORM}
              formKeys={FORM_KEYS}
            />
          </FormProvider>
        </ValidationProvider>
      </SectionCard>
    </AdjustCardWrapper>
  );
}
