import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { IQHeader } from '@gannettdigital/shared-react-components';
import LocalIQLogo from '../images/logo-localiq.svg';
import { APPOINTMENTS_ID, HEADER_MAIN_LINKS, SUPPORT_ONLY_LIMITED_NAV_LINKS } from './headers/header-constants';
import { setCurrentScreen } from '../shared/navigation/navigation-slice';
import { IS_INTERNAL_LINK_KEY, ORIGIN_LINK_KEY } from '../pages/products/account/account-constants';
import { addDynamicNavConfig } from '../shared/header-utils';
import { DASHBOARD_PATH, SUPPORT_ROLE } from '../shared/app-constants';
import FreeTrialStatusBanner from '../pages/dashboard/FreeTrialStatusBanner';

const RootStyle = styled('div')`
  & header.MuiPaper-root.MuiAppBar-root {
    border-bottom: 1px solid #AEAEAD;
  }
`;

const MainBodyStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: '64px',
  textAlign: 'center',
  fontFamily: 'Unify Sans',
}));

const ContainerStyle = styled('div')(() => (({ theme, addPadding }) => ({
  padding: addPadding ? theme.spacing(3) : 0,
  textAlign: 'left',
  backgroundColor: theme.palette.background.default,
})));

export const NO_LABEL = 'None';

export default function SettingsLayout({ children, addPadding }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessName = useSelector((state) => state.bookings.businessData?.name) || 'Profile';
  const hasListingsSubscription = useSelector((state) => state.dashboard.hasListingsSubscription);
  const isTrialActive = useSelector((state) => state.configuration.trialInfo?.active);
  const headerMainLinks = addDynamicNavConfig(HEADER_MAIN_LINKS, hasListingsSubscription, businessName);

  const handleInternalLinks = (relativeUrl, currentScreen) => {
    if (relativeUrl && currentScreen) {
      dispatch(
        setCurrentScreen({ currentScreen })
      )
      navigate({
        pathname: relativeUrl,
        search: `?${ORIGIN_LINK_KEY}=${window.location.pathname}&${IS_INTERNAL_LINK_KEY}=true`,
      }, { replace: true }) // Required for page monitor to pick up the change
    }
  }

  const handleLogoClick = () => {
    navigate(DASHBOARD_PATH);
  }

  return (
    <RootStyle>
      <IQHeader
        logoSrc={LocalIQLogo}
        logoOnClick={handleLogoClick}
        links={headerMainLinks}
        handleInternalLinks={handleInternalLinks}
      />

      <MainBodyStyle>
        {isTrialActive && <FreeTrialStatusBanner />}
        <ContainerStyle addPadding={addPadding}>
          {children}
        </ContainerStyle>
      </MainBodyStyle>
    </RootStyle>
  );
}

SettingsLayout.propTypes = {
  children: PropTypes.node,
};
