import styled from '@emotion/styled';
import { Button, Card, Typography } from '@mui/material';
import { CardBadge, PrimaryButton } from '../../../../shared/settings-shared-components';

export const CloseModal = styled(Button)(() => ({
  background: 'none',
  border: 'none',
  ':hover': {
    background: 'none'
  }
}));

export const ModalDivider = styled('hr')(() => ({
  background: 'linear-gradient(90deg, #6432BE 0%, #DC4605 100%)',
  height: 1,
  width: '95%',
}));

export const CreditCardHeader = styled(Typography)(() => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: 24,
  lineHeight: '30px',
  padding: 24,
}));

export const CancelButton = styled(PrimaryButton)(() => ({
  marginRight: '1rem'
}));

export const ButtonWrapper = styled('div')(() => ({
  float: 'right',
  margin: '40px 1rem 0 0'
}));

export const CreditCardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Unify Sans Semibold',
  float: 'left',
  marginLeft: '24px',
  marginTop: '22px',
  marginBottom: '8px',
  fontSize: 16,
  [theme.breakpoints.down('sm')]: {
    marginLeft: '24px',
  },
}));

export const HeaderWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const CCSectionWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    marginRight: 0
  },
}));

export const SubscriptionCardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: theme.spacing(1),
  padding: '16px',
  '> .MuiPaper-root': {
    position: 'relative',
  },
}));

export const SubscriptionCardHeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const SubscriptionCardBadgerWrapper = styled(CardBadge)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem'
  }
}));

export const SubscriptionCardHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  '> svg': {
    width: 30,
    height: 30,
  },
  '> h4': {
    fontSize: '24px',
    lineHeight: '30px',
  },

  [theme.breakpoints.down('sm')]: {
    '> h4': {
      fontSize: '20px',
    },
  }
}));

export const ChangeCreditCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  '.change-cc-btn': {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: `underline ${theme.palette.primary.main}`,

    '&:hover': {
      textDecoration: 'none',
    }
  }
}));