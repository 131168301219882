import styled from '@emotion/styled';
import { Dialog, DialogContent } from '@mui/material';
import ProcessingBar from './ProcessingBar';

const ProcessingDialog = styled(Dialog)(() => ({
  // Move the backdrop down the height of the nav bar
  top: 64,

  '& .MuiDialog-paper': {
    width: 500,
    height: 300,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    bottom: 50,
  },
  '& .MuiBackdrop-root': {
    // Move the backdrop down the height of the nav bar
    top: 64,
  },
}));

/**
 * Component for processing modal.
 */
export default function ProcessingModal() {
  // Prevent closing
  const handleModalClose = (ev) => {
    ev.preventDefault();
  };

  return (
    <ProcessingDialog open onClose={handleModalClose}>
      <DialogContent>
        <ProcessingBar processingText="Hang on while we analyze your site…" />
      </DialogContent>
    </ProcessingDialog>
  );
}
