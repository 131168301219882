import {
  Step, StepLabel, Stepper, Typography,
} from '@mui/material';
import { ReactComponent as Step1 } from '../../assets/steps/step1.svg';
import { ReactComponent as Step2 } from '../../assets/steps/step2.svg';
import { ReactComponent as Step3 } from '../../assets/steps/step3.svg';
import GraderStepIcon from './GraderStepIcon';
import { GraderStepsWrapper } from './styles/grader-step-styles';

const steps = [
  {
    prompt: 'Enter your website URL',
    desc: `We'll analyze your website, looking at over a dozen performance metrics across three unique categories. This could take up to two minutes.`,
    icon: <Step1 />,
  },
  {
    prompt: 'Review your results and see how competitors stack up',
    desc: `You'll learn a lot from your report, and you can enter a competitor's URL to see their overall grade as well as how they score in each of the 3 categories.`,
    icon: <Step2 />,
  },
  {
    prompt: 'Improve your presence with very specific action steps',
    desc: 'Take control of your online presence with easy-to-understand feedback, ranked by priority and impact.',
    icon: <Step3 />,
  },
];

/**
 * Display the steps section of the grader input page
 */
export default function GraderSteps() {
  const totalSteps = steps.length;
  return (
    <GraderStepsWrapper>
      <Typography className="stepsTitle">3 Easy Steps</Typography>
      <Stepper orientation="vertical" connector={null}>
        {steps.map((step, index) => {
          const stepNumber = `Step ${index + 1}:`;
          return (
            <Step className="customStep" key={step.prompt}>
              <StepLabel
                className="stepLabelWrapper"
                StepIconComponent={GraderStepIcon}
                StepIconProps={{
                  progress: ((index + 1) / totalSteps),
                  iconElement: step.icon,
                }}
              >
                <Typography className="stepNumber">{stepNumber}</Typography>
                <Typography className="stepTitle">{step.prompt}</Typography>
                <Typography className="stepDescription">{step.desc}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </GraderStepsWrapper>
  );
}
