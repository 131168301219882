import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQFormSelect, ValidationProvider } from '@gannettdigital/shared-react-components';
import TrackingCodeImg from '../../../../images/tracking-code-capture.svg';
import CaptureCodeCard from '../../account/components/cards/CaptureCodeCard';
import CaptureCodeEmailCard from '../../account/components/cards/CaptureCodeEmailCard';
import { generateScriptId } from '../../../../shared/app-utils';
import ActionCard from '../../../../shared/ActionCard';
import { ActionIcons, SUCCESS_TYPE, ERROR_TYPE } from '../../../../shared/app-constants';
import { ActionDescription } from '../../../../shared/settings-shared-components';
import StatusIndicator, { SUCCESS_BACKGROUND, WARNING_BACKGROUND } from '../../../../shared/statusIndicator/StatusIndicator';
import WidgetInstalledIcon from '../../../../images/widget-installed-icon.svg';
import WidgetNotInstalledIcon from '../../../../images/widget-not-installed-icon.svg';
import { WEBSITE_TYPE, WEBSITE_KEY, WORDPRESS_KEY } from '../../account/account-constants';
import { resetCaptureEmailSubmitClick } from '../../account/data/account-slice';

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  }
}));

const CodeTitle = styled(Typography)(() => ({
  fontSize: 32,
  fontFamily: 'Unify Sans Semibold'
}));

const CodeSubTitle = styled(Typography)(() => ({
  fontSize: 20,
  fontFamily: 'Unify Sans Semibold',
  margin: '10px 0 20px 0'
}));

const BodyWrapper = styled('div')(() => ({
  display: 'flex',
  margin: '25px 0',
  gap: '1.2rem'
}));

const CodeBodyText = styled(Typography)(() => ({
  fontSize: 18,
  marginBottom: '24px'
}));

const CodeListItems = styled('li')(() => ({
  fontSize: 18,
}));

const ActionTitle = styled(Typography)(() => ({
  fontSize: 16,
  fontFamily: 'Unify Sans Semibold'
}));

const ActionWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '16px'
}));

const BulletpointWrapper = styled('div')(() => ({
  lineHeight: '27px',
  width: '60%'
}));

const DropDownSelect = styled(IQFormSelect)(({ theme }) => ({
  backgroundColor: theme.palette.common.white
}));

let actionStatus;
let actionTitle;
let actionDescription;

export default function LocalIQCode() {
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);

  const captureCode = useSelector((state) => state.appSettings.trackingCode.code);
  const isInstalled = useSelector((state) => state.appSettings.trackingCode.isInstalled);
  const isEmailSent = useSelector(state => state.account.sentEmail)
  const isSubmitClicked = useSelector(state => state.account.scrollSaveBanner)
  const actionRef = useRef(null);

  const scriptID = captureCode ? generateScriptId(captureCode) : '';
  const captureCodeText = scriptID ?
    `<script type="text/javascript"
src="//cdn.rlets.com/capture_configs${scriptID}" async="async"></script>` : null;

  const schema = yup.object().shape({
    [WEBSITE_KEY]: yup.string(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [WEBSITE_KEY]: WORDPRESS_KEY,
    }))
  });
  const { watch } = methods;
  const website = watch(WEBSITE_KEY);

  if (isEmailSent && isEmailSent !== null) {
    actionStatus = SUCCESS_TYPE
    actionTitle = "Email Instructions Sent!"
    actionDescription = "The instructions for installing LocaliQ Code has been sent to the email address provided."
  } else if (!isEmailSent && isEmailSent !== null) {
    actionStatus = ERROR_TYPE
    actionTitle = "ERROR"
    actionDescription = "Something went wrong. Please try again"
  }

  function dismissActionBanner() {
    setShowBanner(false)
  };

  useEffect(() => {
    if (isEmailSent !== null) {
      setShowBanner(true)
      actionRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
    const dismissTimeout = window.setTimeout(() => {
      dismissActionBanner();
    }, 5000);
    return () => {
      dismissActionBanner();
      clearTimeout(dismissTimeout);
    };
  }, [isEmailSent, isSubmitClicked]);

  useEffect(() => {
    dispatch(resetCaptureEmailSubmitClick())
  }, [isSubmitClicked])

  useEffect(() => {
    dismissActionBanner()
  }, [])

  return (
    <div ref={actionRef}>
      {showBanner && (
        <ActionCard
          id="stripe-action-banner"
          type={actionStatus}
          icons={ActionIcons}
        >
          <ActionWrapper>
            <ActionTitle> {actionTitle} </ActionTitle>
            <ActionDescription> {actionDescription} </ActionDescription>
          </ActionWrapper>
          <Button onClick={dismissActionBanner}> Dismiss </Button>
        </ActionCard>
      )}
      <section>
        <HeaderWrapper>
          <CodeTitle> LocaliQ Code </CodeTitle>
          <StatusIndicator
            mainText={isInstalled ? 'Installed' : 'Not Installed'}
            secondaryText='LocaliQ Code Status'
            icon={isInstalled ? WidgetInstalledIcon : WidgetNotInstalledIcon}
            background={isInstalled ? SUCCESS_BACKGROUND : WARNING_BACKGROUND}
          />
        </HeaderWrapper>
        {isMobile && <div style={{ textAlign: 'center' }}><img src={TrackingCodeImg} alt="tracking code image." /></div>}
        <CodeSubTitle> How it works</CodeSubTitle>
        <CodeBodyText variant='body1'>The LocaliQ Code is a short line of code that enables LocaliQ widgets to display on your website. Without installation, widgets will not display. </CodeBodyText>
        <BodyWrapper>
          {!isMobile && <img src={TrackingCodeImg} alt="tracking code image." />}

          <BulletpointWrapper>
            <ul>
              <CodeListItems> Features that require LocaliQ Code installation are Click-to-Call, Scheduling, Chatbot, and Contact Form. </CodeListItems>
              <CodeListItems> Once installed, widgets can be displayed on your website while also collecting valuable data on visitor interactions and traffic. </CodeListItems>
            </ul>
          </BulletpointWrapper>
        </BodyWrapper>
        <CodeBodyText> If you manage your website, choose the appropriate platform below and proceed with the provided instructions. If someone else manages your website, we can send the instructions via email. </CodeBodyText>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <DropDownSelect
              labelText="What platform do you use to manage your website?"
              items={WEBSITE_TYPE}
              name={WEBSITE_KEY}
              defaultValue={WEBSITE_KEY}
            />
          </FormProvider>
        </ValidationProvider>
        <CaptureCodeCard captureCodeText={captureCodeText} captureCode={captureCode} websiteType={website} />
        <CaptureCodeEmailCard
          scriptID={captureCode}
          source="Settings"
        />
      </section>
    </div>
  );
}