import { of } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import {
  logoutUserAction,
  loadTrackingCodeAction,
  loadTrackingCodeSuccess,
  loadLeadsSuccess,
  loadLeadsError,
  loadTrackingCodeError,
  loadSettingsData,
  loadSettingsDataSuccess,
  loadSettingsDataError,
} from './app-slice';
import {
  NON_DIALOG_PATHS,
  TRACKJS_USERID_METADATA_KEY,
  TRACKJS_USER_INTEXT_METADATA_KEY,
  FREE_TRIAL_KEY,
  BUSINESS_KEY,
  USER_KEY,
  ORDERS_KEY,
  ORDER_ITEMS_KEY,
  BUSINESS_LOCATIONS_KEY,
  WIZARD_KEY,
  PRODUCT_INFO_KEY,
  SCHEDULER_SLUG,
  CALL_TRACKER_SLUG,
  CHATBOT_SLUG,
  BUSINESS_ATTRIBUTES_KEY,
  CONTACT_FORM_SLUG,
  TRIAL_STATUS_ACTIVE,
  TRIAL_STATUS_EXPIRED,
  NOT_FOUND,
} from './app-constants';
import { constructQueryString, hasBundleOrder } from './app-utils';
import { loadedBookingData, loadSchedulerAllAppointmentsAction, loadSchedulerAppointments, loadSchedulerLinksAction } from '../pages/products/bookings/data/bookings-slice';
import { loadedCallSettingsData } from '../pages/products/callSettings/data/call-settings-actions';
import { loadedChatbotSettingsData } from '../pages/products/chatbot/data/chatbot-actions';
import { loadedBusinessAttributes } from './business-attributes-slice';
import { loadedContactFormData } from '../pages/products/contactForm/data/contact-form-actions';
import { SUITE_USER_TYPES, setSuiteUserType, setupUser } from './analytics-utils';
import { redirectAction } from '../redux/shared-actions';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_ACCOUNT_SERVICES_UI_ROOT,
  REACT_APP_MANAGEMENT_UI_ROOT,
  REACT_APP_CLIENT_DASHBOARD_URL,
} = process.env;

const identifyAppcuesUser = (id, firstName, lastName) => {
  if (window.Appcues) {
    window.Appcues.identify(
      id,
      {
        firstName,
        lastName,
      },
    );
  } else {
    console.log('Could not identify the user, window.Appcues does not exist');
  }
};

const setupAppcuesUser = (appcuesUser) => {
  if (window.Appcues) {
    identifyAppcuesUser(appcuesUser.email, appcuesUser.firstName, appcuesUser.lastName);
  } else {
    const MAX_APPCUES_RETRIES = 5;
    let appcuesRetryCounter = 0;
    const appcuesInterval = setInterval(() => {
      identifyAppcuesUser(appcuesUser.email, appcuesUser.firstName, appcuesUser.lastName);
      if (appcuesRetryCounter >= MAX_APPCUES_RETRIES) {
        console.log('Could not load Appcues');
        clearInterval(appcuesInterval);
      }
      appcuesRetryCounter += 1;
    }, 500);
  }
};

/**
 * Using returned settings info, determine the users type
 */
const determineAnalytics = (settingsObj) => {
  const { user, orders, trials } = settingsObj;
  setupUser(user);

  if (hasBundleOrder(orders)) {
    setSuiteUserType(SUITE_USER_TYPES.PAID);
    return;
  }

  if (Array.isArray(trials) && trials.length > 0) {
    const currentTrial = trials[0];
    if (currentTrial.status === TRIAL_STATUS_ACTIVE) {
      setSuiteUserType(SUITE_USER_TYPES.FREE_TRIAL);
      return;
    }
    if (currentTrial.status === TRIAL_STATUS_EXPIRED) {
      setSuiteUserType(SUITE_USER_TYPES.POST_TRIAL);
      return;
    }
  }

  setSuiteUserType(SUITE_USER_TYPES.PRE_TRIAL);
};

const handleGenericError = (error, errorAction) => {
  if (error.status === 401) {
    // Redirect to /logout
    return of(logoutUserAction());
  }

  // Redirect to Not Found page
  if (error.status == 500) {
    return of(redirectAction({ redirectPath: NOT_FOUND }))
  }

  return of(errorAction());
};

// Analyze the settings data success payload and compile
// actions for each product included in the payload
const assembleDataSuccessActions = (payload) => {
  const successActions = [];
  for (let index = 0; index < payload.length; index += 1) {
    const productPayload = payload[index];

    if (productPayload?.productType?.slug === SCHEDULER_SLUG) {
      successActions.push(loadedBookingData(productPayload));
    }

    if (productPayload?.productType?.slug === CALL_TRACKER_SLUG) {
      successActions.push(loadedCallSettingsData(productPayload));
    }

    if (productPayload?.productType?.slug === CHATBOT_SLUG) {
      successActions.push(loadedChatbotSettingsData(productPayload));
    }

    if (productPayload?.productType?.slug === CONTACT_FORM_SLUG) {
      successActions.push(loadedContactFormData(productPayload));
    }
  }
  return successActions;
};

const loadSettingsDataEpic = (action$) => action$.pipe(
  filter(loadSettingsData.match),
  mergeMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}settings/load?${constructQueryString('included', [FREE_TRIAL_KEY, BUSINESS_KEY, BUSINESS_LOCATIONS_KEY,
        BUSINESS_ATTRIBUTES_KEY, USER_KEY, ORDERS_KEY, ORDER_ITEMS_KEY, PRODUCT_INFO_KEY, WIZARD_KEY])}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    mergeMap((response) => {
      let responseActions = [];
      responseActions.push(loadedBusinessAttributes(response.response.business))
      responseActions = responseActions.concat(
        assembleDataSuccessActions(response.response.products),
      );

      responseActions.push(loadSettingsDataSuccess(response.response));
      setupAppcuesUser(response.response.user);
      determineAnalytics(response.response);
      return responseActions;
    }),
    catchError((error) => handleGenericError(error, loadSettingsDataError)),
  )),
);

const redirectToLogout = () => {
  const existingSearchParams = window.location.search;
  const currentPath = window.location.pathname;

  const foundPath = NON_DIALOG_PATHS.find((rootPath) => currentPath.indexOf(rootPath) > -1);
  // If we can't figure out where they are now, send them back to dashboard
  const constructedReturnTo = `${foundPath ? REACT_APP_MANAGEMENT_UI_ROOT + foundPath.slice(1) : REACT_APP_CLIENT_DASHBOARD_URL}`;
  const returnToUrl = `${REACT_APP_ACCOUNT_SERVICES_UI_ROOT}login?${existingSearchParams}&return_to=${constructedReturnTo}`;

  if (window.TrackJS) {
    window.TrackJS.removeMetadata(TRACKJS_USERID_METADATA_KEY);
    window.TrackJS.removeMetadata(TRACKJS_USER_INTEXT_METADATA_KEY);
  }

  window.location.replace(returnToUrl);
};

const logoutUserEpic = (action$) => action$.pipe(
  filter(logoutUserAction.match),
  tap(() => redirectToLogout()),
  ignoreElements(),
);

// On loadSettingsDataSuccess, run the tracking code load
const postUserInfoLoad = (action$) => action$.pipe(
  filter(loadSettingsDataSuccess.match),
  map((action) => loadTrackingCodeAction({ currentUserEmail: action.payload.user.email })),
);

const loadTrackingCodeEpic = (action$) => action$.pipe(
  filter(loadTrackingCodeAction.match),
  mergeMap((action) => ajax({
    url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/capture-code/is-active?email=${action.payload.currentUserEmail}`,
    method: 'GET',
    crossDomain: true,
    withCredentials: true,
  }).pipe(
    map((response) => loadTrackingCodeSuccess(response.response)),
    catchError((error) => (
      of(loadTrackingCodeError(error))
    )),
  )),
);

const loadLeadsEpic = (action$, state$) => action$.pipe(
  filter(loadSettingsDataSuccess.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}data_connect/leads`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => loadLeadsSuccess(response.response)),
    catchError((error) => (
      of(loadLeadsError(error))
    )),
  )),
);

// On loadSettingsDataSuccess, run the appointment type
const loadSchedulerInfoEpic = (action$, state$) => action$.pipe(
  filter(loadSettingsDataSuccess.match),
  filter(() => state$.value.bookings.identifier),
  mergeMap(() => [
    loadSchedulerAllAppointmentsAction(),
    loadSchedulerAppointments({
      page: 0,
      pageSize: 10,
      filter: state$.value.bookings.appointments.type,
    }),
    loadSchedulerLinksAction()
  ])
);

export default [
  loadSchedulerInfoEpic,
  loadSettingsDataEpic,
  logoutUserEpic,
  loadTrackingCodeEpic,
  loadLeadsEpic,
  postUserInfoLoad,
];
