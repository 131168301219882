import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Card, CardContent, CardHeader, Grid, Skeleton } from '@mui/material';
import { DividerRow, DividingLine, GridRow } from '../../../../../shared/settings-shared-components';

const CardWrapper = styled(Card)(() => ({
  border: `1px solid #D7D7D7`,
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.08), 0px 6px 32px rgba(0, 0, 0, 0.11), 0px 8px 4px rgba(0, 0, 0, 0.07)',
  borderRadius: '4px',
}));

const CardHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export default function BusinessDetailsSkeleton() {

  return (
    <CardWrapper>
      <CardHeader title={(
        <CardHeaderWrapper>
          <Skeleton variant='circular' width={20} height={20} />
          <Skeleton variant='rectangular' width={isMobile ? 280 : 364} height={24} />
        </CardHeaderWrapper>
      )} />
      <CardContent>
        <Grid container spacing={2}>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={120} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={120} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <DividerRow paddingBottom={6} item xs={12} />
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={113} height={24} />
            <Skeleton variant='rectangular' width={161} height={24} />
          </GridRow>
          <DividerRow item xs={12}>
            <DividingLine allowMobile />
          </DividerRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={135} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={135} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={33} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={120} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={65} height={24} />
            <Skeleton variant='rectangular' width='100%' height={40} />
          </GridRow>
          <GridRow display='flex' flexDirection='column' gap={1} item sm={6} xs={12}>
            <Skeleton variant='rectangular' width={95} height={24} />
            <Skeleton variant='rectangular' width='50%' height={40} />
          </GridRow>
        </Grid>
      </CardContent>
    </CardWrapper>
  )
}