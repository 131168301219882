import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import NotActivated from '../components/recentActivity/NotActivated';
import Activated from '../components/recentActivity/Activated';
import { useMountEffect } from '../../../shared/mountEffectHook/useMountEffect';
import { loadRecentActivitiesAction } from '../data/dashboard-slice';
import LoadingSkeletonRecentActivityDesktop from '../components/loadingSkeletons/recentActivity/LoadingSkeletonRecentActivityDesktop';
import LoadingSkeletonRecentActivityMobile from '../components/loadingSkeletons/recentActivity/LoadingSkeletonRecentActivityMobile';
import WidgetStatusCard from './WidgetStatusCard';

const StyledRecentActivityCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 5),
  height: '100%',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 3, 2, 3),
  },
}));

const StyledSectionTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '30px',
}));

const StyledTableContent = styled('div')(({ theme, isActivatedWithLeads }) => ({
  border: '1px solid #000000',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: isActivatedWithLeads ? 'unset' : 'center',
  width: isActivatedWithLeads ? '100%' : 'auto',
  borderRadius: '8px',
  margin: theme.spacing(3, 0),
  background: theme.palette.common.white,
  minHeight: '320px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const StyledViewAllLeadsLink = styled('a')(({ theme }) => ({
  display: 'block',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  textDecorationLine: 'underline',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  padding: theme.spacing(1.4, 0),
}));

const { REACT_APP_LEADS_URL } = process.env;

export default function RecentActivityCard() {
  const dispatch = useDispatch();
  const schedulingIsActive = useSelector((state) => state.bookings.toggle.isActive);
  const chatbotIsActive = useSelector((state) => state.chatbot.toggle.isActive);
  const callSettingsIsActive = useSelector((state) => state.callSettings.isWidgetActive);
  const contactFormIsActive = useSelector((state) => state.contactForm.toggle.isActive);
  const leads = useSelector((state) => state.dashboard.leads);
  const leadsAreLoading = useSelector((state) => state.dashboard.leadsAreLoading);

  const isActivated = (
    schedulingIsActive
    || chatbotIsActive
    || callSettingsIsActive
    || contactFormIsActive
  );
  const hasLeads = leads.length > 0;
  const isActivatedWithLeads = isActivated && hasLeads && !leadsAreLoading;

  useMountEffect(() => {
    dispatch(
      loadRecentActivitiesAction(),
    );
  }, []);

  if (leadsAreLoading) {
    return (
      <StyledRecentActivityCard>
        { isMobile
          ? <LoadingSkeletonRecentActivityMobile />
          : <LoadingSkeletonRecentActivityDesktop />}
      </StyledRecentActivityCard>
    );
  }

  return (
    <StyledRecentActivityCard>
      <StyledSectionTitle>Recent Activity</StyledSectionTitle>
      <StyledTableContent isActivatedWithLeads={isActivatedWithLeads}>
          <Grid item md={4} xs={12}> 
            <WidgetStatusCard />
          </Grid>
        {
          !isActivated
            ? <NotActivated />
            : <Activated hasLeads={hasLeads} leads={leads} />
        }
      </StyledTableContent>
      {
        isActivatedWithLeads && <StyledViewAllLeadsLink href={REACT_APP_LEADS_URL}>View All Leads</StyledViewAllLeadsLink>
      }
    </StyledRecentActivityCard>
  );
}
