import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

/**
 * Hook that adds
 * isDirty monitoring to work with the overall save flow.
 * Also handles setting default form values on load/reset
 */
export default function StateChangedHook({
  formKeys = [],
  resetData,
  hasReset,
  onDirty,
  onClean,
  onInvalid,
}) {
  const {
    reset,
    getValues,
    formState,
  } = useFormContext();

  useEffect(() => {
    // Don't let the save button show when invalid fields are present
    // Only valid + changed
    const { isDirty, isValid } = formState;
    if (isDirty && isValid) {
      onDirty();
    } else if (isDirty && !isValid) {
      onInvalid();
    } else {
      onClean();
    }
  }, [formState]);

  useEffect(() => {
    if (hasReset && resetData) {
      const newDefaults = getValues();
      formKeys.forEach((formKey) => {
        newDefaults[formKey] = resetData[formKey];
      });
      reset(newDefaults);
    }
  }, [reset, getValues, formKeys, resetData, hasReset]);

  return (null);
}

StateChangedHook.propTypes = {
  formKeys: PropTypes.array.isRequired,
  resetData: PropTypes.object.isRequired,
  hasReset: PropTypes.bool,
  onDirty: PropTypes.func.isRequired,
  onClean: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
};
