import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const WizardFeatureTitleWrapper = styled('div')(() => (({}) => ({
  display: 'flex',
})));

const WizardFeatureTitleText = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '1.25rem',
  marginLeft: '0.25rem',
  fontFamily: 'Unify Sans',
  fontWeight: 600,
}));

const WizardFeatureTitleImage = styled('img')(({}) => ({
  width: '1rem',
}));

export default function WizardFeatureTitle({icon, text}) {
  return (
    <WizardFeatureTitleWrapper>
      <WizardFeatureTitleImage src={icon} />
      <WizardFeatureTitleText variant='h6'>{text}</WizardFeatureTitleText>
    </WizardFeatureTitleWrapper>
  );
}

WizardFeatureTitle.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};