import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import BookingsCTA from '../../../../shared/BookingsCTA';
import UpcomingAppointmentsDesktop from '../../assets/no-upcoming-appointments-desktop.png';
import UpcomingAppointmentsMobile from '../../assets/no-upcoming-appointments-mobile.png';
import { CONFIG_PATH, WIDGET_SETTINGS_PATH } from '../../../../shared/app-constants';
import { TRIAL_SOURCES, TRIAL_SOURCE_KEY, trackDashboardCardClick } from '../../../../shared/analytics-utils';
import { isEmptyObject } from '../../../graders/website/grader-utils';

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  marginBottom: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export default function NotActivated() {
  const navigate = useNavigate();
  const trialInfo = useSelector((state) => state.configuration.trialInfo);

  const onSetUpMySchedulerClick = () => {
    trackDashboardCardClick('Upcoming Appointments', 'Set up Scheduling');
    sessionStorage.setItem(TRIAL_SOURCE_KEY, TRIAL_SOURCES.DASHBOARD);
    if (isEmptyObject(trialInfo)) {
      navigate(CONFIG_PATH);
    } else {
      navigate(WIDGET_SETTINGS_PATH);
    }
  };

  return (
    <StyledContainer>
      <img src={isMobile ? `${UpcomingAppointmentsMobile}` : `${UpcomingAppointmentsDesktop}`} alt="no appointments img" />
      <StyledText>
        Once you set up scheduling, this is where appointments will appear.
      </StyledText>
      <BookingsCTA
        text="Set Up Scheduling"
        onClickHandler={onSetUpMySchedulerClick}
        style={{
          fontSize: '14px',
          padding: '11px 20px',
        }}
      />
    </StyledContainer>
  );
}
