export const FORWARDING_TYPE_MAP = {
  LOCAL: 'Local',
  TOLLFREE: 'Toll Free',
};

export const LOCAL_TYPE = 'LOCAL';

// Form names
export const BUSINESS_NUMBER_FORM = 'businessNumber';
export const CALL_FORWARDING_FORM = 'callForwarding';
export const CALL_VERIFICATION_FORM = 'callVerification';

// Form keys
export const SELECTED_FORWARDING_NUMBER_KEY = 'selectedTrackingNumber';
export const SELECTED_FORWARDING_UUID_KEY = 'selectedTrackingUuid';
export const SELECTED_FORWARDING_TYPE_KEY = 'selectedTrackingNumberType';
export const CALL_VERIFICATION_ENABLED_KEY = 'callVerify';
export const CALL_VERIFICATION_PROMPT_KEY = 'callVerifyPrompt';
export const NEW_BASE_NUMBER_KEY = 'newBaseNumber';
export const CALL_RECORDING_ACTIVE_KEY = 'callRecording';
export const IS_GENERATING_NUMBERS='isGeneratingNumbers';

// Data keys
export const ORIGINAL_NUMBER = 'originalNumber';
export const CURRENT_FORWARDING_NUMBER = 'currentForwardingNumber';
export const CURRENT_FORWARDING_UUID = 'currentForwardingNumberUuid';
export const CURRENT_FORWARDING_TYPE = 'currentForwardingNumberType';
export const SELECTABLE_FORWARDING_NUMBERS = 'selectableForwardingNumbers';
export const GENERATE_NUMBERS_FAILED = 'generateNumbersFailed';
