import { createSlice } from '@reduxjs/toolkit';
import { COMPETITOR, COMPLETE_GRADER, DIGITAL_ADVERTISING, PRESENCE, RUNNING_GRADER, WEBSITE_QUALITY } from './grader-constants';
import { filterScores } from '../grader-utils';
import { NA, SUB_CATEGORIES_CONFIG } from '../components/GraderSection/data/grader-section-constants';

const DEFAULT_RESULTS = {
  status: null,
  score: null,
  subCategories: null,
  scoreDetails: null,
  domain: null,
};

const findSection = (detailsKey) => {
  if (SUB_CATEGORIES_CONFIG[WEBSITE_QUALITY].indexOf(detailsKey) >= 0) {
    return WEBSITE_QUALITY;
  }

  if (SUB_CATEGORIES_CONFIG[PRESENCE].indexOf(detailsKey) >= 0) {
    return PRESENCE;
  }

  if (SUB_CATEGORIES_CONFIG[DIGITAL_ADVERTISING].indexOf(detailsKey) >= 0) {
    return DIGITAL_ADVERTISING;
  }

  return null;
};
/**
 * Filter and map the details list.
 * Filter out NA returns.
 * Map into a 3 keyed object (low, medium, high) with arrays of details in each
 */
const processDetail = (acc, [key, detail]) => {
  // TODO: filter by
  const newDetail = {};
  const level = detail.optimizationLevel.toLowerCase();
  if (level !== NA) {
    newDetail.level = level;
    newDetail.key = key;

    const section = findSection(key);
    // Skip if unknown section
    if (section !== null) {
      if (!acc[section]) {
        acc[section] = {};
      }

      const sectionLevel = acc[section];
      if (sectionLevel[level]) {
        sectionLevel[level].push(newDetail);
      } else {
        sectionLevel[level] = [newDetail];
      }
    }
  }

  return acc;
};

const readResults = (payload) => {
  const {
    status, overallScore, domain, scoreDetails, subCategories,
  } = payload;

  const alteredDetails = Object.entries(scoreDetails).reduce(processDetail, {});

  return {
    status,
    score: overallScore,
    domain,
    scoreDetails: alteredDetails,
    subCategories: filterScores(subCategories),
  };
};

const initialState = {
  activeSection: WEBSITE_QUALITY,
  isLoadingScore: false,
  isSubmittingGrader: false,
  hasCheckedGrader: false,
  isLoadingCompetitor: false,
  graderResults: DEFAULT_RESULTS,
  competitorResults: DEFAULT_RESULTS,
  redirectResponded: false,
};

const graderSlice = createSlice({
  name: 'grader',
  initialState,
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload.section;
    },
    getGraderScoreSuccess: (state, action) => {
      state.isLoadingScore = false;
      state.hasCheckedGrader = true;

      const { status } = action.payload;
      // Check status when we get a response. We may still be submitting
      state.isSubmittingGrader = status === RUNNING_GRADER;
      if (status === COMPLETE_GRADER) {
        state.graderResults = readResults(action.payload);
      }
    },
    getCompetitorScoreSuccess: (state, action) => {
      state.competitorResults = readResults(action.payload);

      const { status } = action.payload;
      // Check status when we get a response. We may still be submitting
      state.isLoadingCompetitor = status === RUNNING_GRADER;
    },
    competitorInProgress: (state) => {
      state.isLoadingCompetitor = true;
    },
    mainInProgress: (state) => {
      state.isLoadingScore = true;
      state.hasCheckedGrader = true;
    },
    requestNewGrader: (state, action) => {
      state.isSubmittingGrader = true;
    },
    requestNewGraderSuccess: (state, action) => {
      const { status } = action.payload;
      state.graderResults.status = status;

      // This may return complete if the URL has already been graded
      state.isSubmittingGrader = status === RUNNING_GRADER;
      if (status === COMPLETE_GRADER) {
        state.graderResults = readResults(action.payload);
      }
    },
    requestNewGraderFailure: (state, action) => {
      state.isSubmittingGrader = false;
    },
    requestNewGraderLanding: (state) => {
      state.isLoadingScore = true;
      state.hasCheckedGrader = true;
    },
    requestNewGraderLandingSuccess: (state) => {
      state.redirectResponded = true;
    },
    requestNewGraderLandingFailure: (state) => {
      state.redirectResponded = true;
    },
    requestCompetitorScore: (state) => {
      state.isLoadingCompetitor = true;
    },
    requestCompetitorScoreFailure: (state) => {
      state.isLoadingCompetitor = false;
    },
    loadInitialGraders: () => {},
    loadInitialGradersSuccess: (state) => {
      state.hasCheckedGrader = true;
    },
    loadInitialGradersFailure: (state, action) => {
      state.isLoadingScore = false;
      state.hasCheckedGrader = true;
    },
    clearCompetitorGrade: (state) => {
      state.competitorResults = DEFAULT_RESULTS;
    },
    graderCompleteMessage: (state, action) => {
      if (state.isLoadingCompetitor) {
        state.competitorResults = readResults(action.payload);
        state.isLoadingCompetitor = false;
      } else {
        state.graderResults = readResults(action.payload);
        state.isLoadingScore = false;
        state.hasCheckedGrader = true;
        state.isSubmittingGrader = false;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setActiveSection,
  getGraderScoreSuccess,
  requestNewGrader,
  requestNewGraderSuccess,
  requestNewGraderFailure,
  requestCompetitorScore,
  requestNewGraderLanding,
  requestNewGraderLandingSuccess,
  requestNewGraderLandingFailure,
  requestCompetitorScoreFailure,
  getCompetitorScoreSuccess,
  loadInitialGraders,
  loadInitialGradersSuccess,
  loadInitialGradersFailure,
  clearCompetitorGrade,
  graderCompleteMessage,
  competitorInProgress,
  mainInProgress,
} = graderSlice.actions;

export default graderSlice.reducer;
