import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { SectionCard } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { LinkButton } from '../../../../shared/settings-shared-components';

const ConnectedCalendarsTable = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),

  '.MuiTypography-h6': {
    fontSize: '1rem',
  },
  '.MuiTypography-body2': {
    display: 'flex',
    color: '#1665CF',
  },
  '.MuiTypography-body2 > span': {
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: '1.5rem',
    marginLeft: theme.spacing(1),
    borderBottom: '1px solid #1665CF',
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
}));

const AdjustmentWrapper = styled('div')(({ theme }) => ({
  '& .MuiPaper-root': {
    borderColor: '#D7D7D7',
    margin: theme.spacing(3, 0),
    minWidth: 300,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  '& .MuiDivider-root': {
    marginBottom: 12,
  },
}));

const AdjustedLinkBtn = styled(LinkButton)(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 1),
}));

const TitleText = styled(Typography)(() => ({
  fontSize: 20,
  fontFamily: 'Unify Sans SemiBold',
  lineHeight: '25px',
}));

const SmallCalendar = styled('img')(({ theme }) => ({
  width: 24,
  height: 24,
  marginRight: theme.spacing(1),
  verticalAlign: 'sub',
}));

export default function ConnectedCalendar({
  title,
  email,
  connectedOn,
  disconnectCalendar,
  errorBanner,
  calendarIcon,
}) {
  const iconTitle = (
    <TitleText>
      <SmallCalendar src={calendarIcon} alt="calendar" />
      {title}
    </TitleText>
  );

  return (
    <AdjustmentWrapper>
      <SectionCard
        id="connected-google-calendar"
        title={iconTitle}
        bannerSection={errorBanner}
      >
        <ConnectedCalendarsTable>
          <Typography variant="h6" component="h6" sx={{ fontFamily: 'Unify Sans Semibold' }}>{email}</Typography>
          <Typography variant="body1">{`Connected ${connectedOn}`}</Typography>
          <Typography variant="body2">
            <LinkOffIcon />
            <AdjustedLinkBtn id="disconnect-google" onClick={disconnectCalendar}>Disconnect Calendar</AdjustedLinkBtn>
          </Typography>
        </ConnectedCalendarsTable>
      </SectionCard>
    </AdjustmentWrapper>
  );
}

ConnectedCalendar.propTypes = {
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  connectedOn: PropTypes.string.isRequired,
  disconnectCalendar: PropTypes.func.isRequired,
  errorBanner: PropTypes.node,
  calendarIcon: PropTypes.string.isRequired,
};
