import PropTypes from 'prop-types';
import {
  createContext, useEffect, useState,
} from 'react';
import { MAX_RECONNECT_ATTEMPTS, SERVER_TIMEOUT_RETRY_BASE } from './websocket-constants';
import { CustomWebSocket } from './CustomWebSocket';

const {
  REACT_APP_WEB_SOCKET_ROOT,
} = process.env;

const webSocket = new CustomWebSocket(REACT_APP_WEB_SOCKET_ROOT);

export const SocketContext = createContext(webSocket);

/**
 * Provider for the freemium websocket
 */
export default function WebSocketProvider({ children }) {
  const [ws, setWs] = useState(webSocket);
  const [retryCount, setRetryCount] = useState(1);

  useEffect(() => {
    // When the socket closes, attempt to reopen it after timeout
    // Uses exponetial backoff up to 10 reconnects (last wait should be ~51 seconds)
    const onClose = () => {
      if (retryCount <= MAX_RECONNECT_ATTEMPTS) {
        setTimeout(() => {
          setRetryCount(retryCount + 1);
          setWs(new CustomWebSocket(REACT_APP_WEB_SOCKET_ROOT));
        }, SERVER_TIMEOUT_RETRY_BASE * (2 ** retryCount));
      } else {
        throw new Error('Failed to reconnect the websocket');
      }
    };

    ws.addEventListener('close', onClose);

    return () => {
      ws.removeEventListener('close', onClose);
    };
  }, [ws, setWs, setRetryCount]);

  useEffect(() => {
    const onMessage = () => {
      // Reset the retry count when we receive a valid message
      setRetryCount(1);
    };

    ws.addEventListener('message', onMessage);

    return () => {
      ws.removeEventListener('message', onMessage);
    };
  }, [ws, setRetryCount]);

  return (
    <SocketContext.Provider value={ws}>{children}</SocketContext.Provider>
  );
}

WebSocketProvider.propTypes = {
  children: PropTypes.node,
};
