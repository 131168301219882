import { APPOINTMENT_TYPES_KEY } from './data/appointment-types-slice';
import { AVAILABILITY_SECTION, BUSINESS_DETAILS_SECTION, CONVERTED_DAYS_SCHEDULE_KEY, CONVERTED_SAME_SCHEDULE_KEY, CONVERTED_SPECIFIC_DAYS_CLOSED_KEY, SPECIFIC_DAYS_CLOSED_SECTION } from './data/general-settings-slice';
import { ADVANCED_SETTINGS_SECTION } from './data/advanced-settings-slice';
import { BUSINESS_ATTRIBUTES_SECTION } from '../../../shared/app-constants';

// Save configuration
// If you have any part of state you don't want to send to the BE
// add it to skipKeys array
export const SAVE_CONFIGURATION = {
  generalSettings: [
    {
      storeKey: BUSINESS_DETAILS_SECTION,
      saveKey: 'business',
    },
    {
      storeKey: AVAILABILITY_SECTION,
      saveKey: 'availability',
      skipKeys: [CONVERTED_SAME_SCHEDULE_KEY, CONVERTED_DAYS_SCHEDULE_KEY],
    },
    {
      storeKey: SPECIFIC_DAYS_CLOSED_SECTION,
      saveKey: '',
      skipKeys: [CONVERTED_SPECIFIC_DAYS_CLOSED_KEY],
    },
  ],
  advancedSettings: [
    {
      storeKey: ADVANCED_SETTINGS_SECTION,
      saveKey: ADVANCED_SETTINGS_SECTION
    },
  ],
  appointmentTypes: [
    {
      storeKey: APPOINTMENT_TYPES_KEY,
      saveKey: APPOINTMENT_TYPES_KEY,
    },
  ],
  businessAttributes: [
    {
      storeKey: BUSINESS_ATTRIBUTES_SECTION,
      saveKey: BUSINESS_ATTRIBUTES_SECTION,
    }
  ]
};
