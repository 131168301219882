import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IQFormInput } from '@gannettdigital/shared-react-components';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import { InputGridWrapper, StyledDelete } from '../../../../../shared/settings-shared-components';
import { APPOINTMENT_CUSTOM_QUESTIONS_KEY, deleteAppointmentTypeCustomQuestions, updateAppointmentTypeCustomQuestions } from '../../data/appointment-types-slice';

const QuestionGridWrapper = styled(InputGridWrapper)(() => ({
  alignItems: 'flex-end',
  display: 'flex',
  '& .MuiFormControl-root': {
    width: '95%',
  },
}));

/**
 * Component for the custom question input section of an appointment type
 */
export default function CustomQuestionWidget({
  name, question, index, parentLength, cardIndex, onDeleteCallback,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const questionUpdated = useCallback((event, questionIndex) => {
    dispatch(updateAppointmentTypeCustomQuestions({
      fieldName: APPOINTMENT_CUSTOM_QUESTIONS_KEY,
      fieldValue: event.target.value,
      typeIndex: cardIndex,
      questionIndex,
    }));
  });

  const onDeleteQuestionClick = useCallback((ind) => {
    onDeleteCallback(ind);
    dispatch(deleteAppointmentTypeCustomQuestions({
      typeIndex: cardIndex,
      deleteIndex: ind,
    }));
  });

  return (
    <QuestionGridWrapper
      item
      xs={12}
      key={question.id}
      sx={{ mb: index === parentLength - 1 ? theme.spacing(1) : 0 }}
    >
      <IQFormInput
        name={name}
        labelText={`Question ${index + 1}`}
        onBlur={(event) => questionUpdated(event, index)}
      />
      <StyledDelete aria-label="delete" onClick={() => onDeleteQuestionClick(index)}>
        <DeleteOutlineIcon />
      </StyledDelete>
    </QuestionGridWrapper>
  );
}

CustomQuestionWidget.propTypes = {
  name: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  parentLength: PropTypes.number.isRequired,
  cardIndex: PropTypes.number.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
};
