import { ReactComponent as PerformanceAppointmentIcon } from './assets/performance-appointment-icon.svg';
import { ReactComponent as PerformanceChatbotThreadIcon } from './assets/performance-chatbot-thread-icon.svg';
import { ReactComponent as PerformanceFormFillsIcon } from './assets/performance-form-fills-icon.svg';
import { ReactComponent as PerformanceRecorderCallsIcon } from './assets/performance-recorded-calls-icon.svg';
import { ReactComponent as PerformanceWebsiteVisitsIcon } from './assets/performance-wesite-visits-icon.svg';
import { ReactComponent as IncreasingArrowIcon } from './assets/increasing-arrow.svg';
import { ReactComponent as DecreasingArrowIcon } from './assets/decreasing-arrow.svg';

export const CHAT_ACTIVITY = 'ChatEvent';
export const SCHEDULING_ACTIVITY = 'BookingEvent';
export const CALL_ACTIVITY = 'CallEvent';
export const FORM_ACTIVITY = 'FormEvent';

export const KEYWORD_UUID_PARAM_KEY = 'keyword_uuid';
export const ACTIVITY_EVENT_TYPE_DISPLAY_MAP = {
  [CHAT_ACTIVITY]: 'Chatbot Thread',
  [SCHEDULING_ACTIVITY]: 'Appointment Scheduled',
  [CALL_ACTIVITY]: 'Call Recording',
  [FORM_ACTIVITY]: 'Form Submitted',
};
export const LEAD_TYPE_TO_MP = {
  'Chatbot Thread': 'Chatbot',
  'Appointment Scheduled': 'Appointment',
  'Form Submitted': 'Contact Form',
  'Call Recording': 'Call Recording',
};

export const DISMISS_WELCOME_CARD = 'welcome';

export const SETUP_WIDGET_TEMPLATE = 'setup-widget';
export const INSTALL_WIDGET_TEMPLATE = 'install-code';

// Performance increasing or decreasing
export const PERFORMANCE_INCREASING = 'increasing';
export const PERFORMANCE_DECREASING = 'decreasing';

// Performance product names
export const PERFORMANCE_CARD_APPTS_NAME = 'Appointments';
export const PERFORMANCE_CHATBOT_THREADS_NAME = 'Chatbot Threads';
export const PERFORMANCE_FORM_FILLS_NAME = 'Form Fills';
export const PERFORMANCE_RECORDED_CALLS_NAME = 'Recorded Calls';
export const PERFORMANCE_WEBSITE_VISITS_NAME = 'Website Visits';

// Performance icons
export const PERFORMANCE_ICONS = {
  [PERFORMANCE_CARD_APPTS_NAME]: <PerformanceAppointmentIcon />,
  [PERFORMANCE_CHATBOT_THREADS_NAME]: <PerformanceChatbotThreadIcon />,
  [PERFORMANCE_FORM_FILLS_NAME]: <PerformanceFormFillsIcon />,
  [PERFORMANCE_RECORDED_CALLS_NAME]: <PerformanceRecorderCallsIcon />,
  [PERFORMANCE_WEBSITE_VISITS_NAME]: <PerformanceWebsiteVisitsIcon />,
  [PERFORMANCE_INCREASING]: <IncreasingArrowIcon />,
  [PERFORMANCE_DECREASING]: <DecreasingArrowIcon />,
};

export const PERFORMANCE_CARD_DAYS = [7, 14, 30, 90, 180];

export const PERFORMANCE_CARD_DEFAULT_VALUES = [
  {
    name: PERFORMANCE_CARD_APPTS_NAME,
    count: 0,
    trend: 0,
    isUpwardTrend: false,
    isZeroTrend: true,
  },
  {
    name: PERFORMANCE_CHATBOT_THREADS_NAME,
    count: 0,
    trend: 0,
    isUpwardTrend: false,
    isZeroTrend: true,
  },
  {
    name: PERFORMANCE_FORM_FILLS_NAME,
    count: 0,
    trend: 0,
    isUpwardTrend: false,
    isZeroTrend: true,
  },
  {
    name: PERFORMANCE_RECORDED_CALLS_NAME,
    count: 0,
    trend: 0,
    isUpwardTrend: false,
    isZeroTrend: true,
  },
  {
    name: PERFORMANCE_WEBSITE_VISITS_NAME,
    count: 0,
    trend: 0,
    isUpwardTrend: false,
    isZeroTrend: true,
  },
];

export const PERFORMANCE_CARD_DAYS_SELECT = 'performanceCardDays';

export const TURN_ON_ALL_FEATURES_MINI_CARD_KEY = 'widgetFeatures';
export const ADD_LISTINGS_MINI_CARD_KEY = 'premiumListings';
export const ADD_CALENDAR_MINI_CARD_KEY = 'googleCalendar';

export const DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
