import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import CardBackgroundDesktop from '../../assets/upcoming-appointment-card-background-desktop.png';
import CardBackgroundMobile from '../../assets/upcoming-appointment-card-background-mobile.png';
import { ReactComponent as CalendarIcon } from '../../assets/upcoming-appointments-calendar-icon.svg';
import { ReactComponent as TimeIcon } from '../../assets/upcoming-appointments-time-icon.svg';
import { formatAppointmentDisplayDate, getUpcomingAppointmentLastRowInfo } from '../../dashboard-utils';
import { isEmptyObject } from '../../../graders/website/grader-utils';

const { REACT_APP_BOOKING_CRM_URL } = process.env;

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: '8px',
  overflow: 'hidden',
  width: '50%',
  cursor: 'pointer',
  '&.upcoming-appointments-max-two-cards': {
    width: '50%',
  },
  '&.upcoming-appointments-three-cards': {
    width: '31%',
  },
  '&.upcoming-appointments-more-then-three-cards': {
    width: '31%',
  },
  '&:hover': {
    boxShadow: '0px 0px 0px 3px #6982C8',
  },

  [theme.breakpoints.down('md')]: {
    '&.upcoming-appointments-max-two-cards': {
      width: 'unset',
    },
    '&.upcoming-appointments-three-cards': {
      width: 'unset',
    },
    '&.upcoming-appointments-more-then-three-cards': {
      width: 'unset',
      height: 'unset',
    },
  },
}));

const StyledHeadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3),
  minHeight: '117px',
  backgroundImage: `url(${CardBackgroundDesktop})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',

  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${CardBackgroundMobile})`,
    minHeight: '97px',
  },
}));

const StyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '25px',
}));

const StyledMainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const StyledAppointmentTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
}));

const RowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const StyledRowText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',

  '&.blue': {
    color: theme.palette.primary.main,
  },

  '&.grey': {
    color: theme.palette.text.secondary,
  }
}));

export default function SingleUpcomingAppointment({
  name,
  appointmentName,
  date,
  startTime,
  endTime,
  timezone,
  phoneNumber,
  appointmentCardsCount,
  appointmentToken,
  locationType,
  providedAddress,
  virtualMeetingUrl,
}) {
  const displayDate = formatAppointmentDisplayDate(date);
  const businessAddressOne = useSelector((state) => state.account.businessDetails.businessDetails.businessAddressOne);
  const businessAddressTwo = useSelector((state) => state.account.businessDetails.businessDetails.businessAddressTwo);
  const businessCity = useSelector((state) => state.account.businessDetails.businessDetails.city);
  const businessState = useSelector((state) => state.account.businessDetails.businessDetails.stateProvince);
  // Display only fields that are provided (could be a combination of only address + state or address + city and etc.)
  const businessAddressDisplay = [!!businessAddressOne ? businessAddressOne : businessAddressTwo, businessCity, businessState].filter(s => !!s).join(', ');
  const lastRowInfo = getUpcomingAppointmentLastRowInfo(locationType.type, phoneNumber, businessAddressDisplay, providedAddress, virtualMeetingUrl);
  const isLastRowInfoNonEmpty = !isEmptyObject(lastRowInfo) && !!lastRowInfo.value;
  const truncatedAppointmentTypeName = appointmentName?.length > 27 ? `${appointmentName.substring(0, 27)}...` : appointmentName;

  let wrapperClass = null;
  if (appointmentCardsCount <= 2) {
    wrapperClass = 'upcoming-appointments-max-two-cards';
  } else if (appointmentCardsCount === 3) {
    wrapperClass = 'upcoming-appointments-three-cards';
  } else {
    wrapperClass = 'upcoming-appointments-more-then-three-cards';
  }

  const upcomingAppointmentCardClick = () => {
    window.open(`${REACT_APP_BOOKING_CRM_URL}${appointmentToken}`, '_self');
  };

  const handleLastRowInfoClick = (e) => {
    e.stopPropagation();
    if (virtualMeetingUrl) {
      window.open(virtualMeetingUrl, '_blank');
    }
  };

  return (
    <StyledContainer className={wrapperClass} onClick={upcomingAppointmentCardClick}>
      <StyledHeadContainer>
        <StyledName>{name}</StyledName>
      </StyledHeadContainer>
      <StyledMainContainer>
        <StyledAppointmentTitle title={appointmentName}>
          {truncatedAppointmentTypeName}
        </StyledAppointmentTitle>
        <RowWrapper>
          <CalendarIcon />
          <StyledRowText>{displayDate}</StyledRowText>
        </RowWrapper>
        <RowWrapper>
          <TimeIcon />
          <StyledRowText>
            {startTime}
            {' '}
            -
            {' '}
            {endTime}
            {' '}
            {timezone}
          </StyledRowText>
        </RowWrapper>
        {
          isLastRowInfoNonEmpty ? (
            <RowWrapper>
              <img src={lastRowInfo.icon} alt='meeting-type-icon' />
              <StyledRowText onClick={handleLastRowInfoClick} className='blue'>{lastRowInfo.value}</StyledRowText>
            </RowWrapper>
          ) : (null)
        }
      </StyledMainContainer>
    </StyledContainer>
  );
}

SingleUpcomingAppointment.propTypes = {
  name: PropTypes.string,
  appointmentName: PropTypes.string,
  date: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  timezone: PropTypes.string,
  phoneNumber: PropTypes.string,
  appointmentCardsCount: PropTypes.number,
  appointmentToken: PropTypes.string,
  locationType: PropTypes.object,
  providedAddress: PropTypes.string,
  virtualMeetingUrl: PropTypes.string,
};
