import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import AddressAutocompleteSuggestionTemplate from './AddressAutocompleteSuggestionTemplate';
import { getAddressOptions } from './address-autocomplete-slice';
import { debounceTime, fromEvent, map } from 'rxjs';

/**
 * Component to wrap the autocomplete structure. Must be used with a react hook form.
 * Use onAutoCompleteSelected to assign fields back to the  form
 */

const AltTextField = styled(TextField)(({ theme }) => ({
  fontSize: 16,
  fontFamily: 'Unify Sans',
  paddingTop: theme.spacing(1),
  color: theme.palette.text.secondary,
  '& .Mui-error': {
    marginLeft: 0,
    color: theme.palette.error.main,
  },
  '& > .MuiInputBase-root': {
    height: 40,
    borderRadius: 0,
    '& > input.MuiAutocomplete-input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& > fieldset': {
      borderColor: theme.palette.text.secondary,

    },
    '& .MuiAutocomplete-clearIndicator': {
      color: (props) => (props.hasError
        ? theme.palette.error.main : 'inherit'),
    },
  },
}));

const StyledFormLabel = styled('label')(({ error, theme }) => ({
  color: error
    ? theme.palette.error.main : theme.palette.text.primary,
  fontFamily: 'Unify Sans SemiBold',
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  fontWeight: 'bold',
  paddingBottom: theme.spacing(1),
  display: 'inline-block',
  width: '100%',
}));

// TODO: convert to shared component
export default function AutoCompleteAddress({
  defaultAddress,
  onAutoCompleteSelected,
  labelText,
  disabled,
  name = 'businessAddress',
  addressOptions,
  id,
  onAddressChange,
}) {
  const dispatch = useDispatch();
  const [addressInputValue, setAddressInputValue] = useState('');

  const {
    register,
    watch,
    formState: {
      errors,
    },
  } = useFormContext();

  const hasError = !!errors[name];
  const businessAddrWatch = watch(name);

  const onAutocompleteChange = (event, newValue) => {
    // If the value was wiped leave these fields in place.
    if (newValue) {
      onAutoCompleteSelected(newValue);
    }
  };

  // Need this in case they don't select a autocomplete option
  useEffect(() => {
    const autoCompleteUsage = fromEvent(document.getElementById(id), 'blur');
    const result = autoCompleteUsage.pipe(
      map((i) => i.currentTarget.value),
      debounceTime(500),
    ).subscribe((keyValue) => onAddressChange(keyValue));

    return () => result.unsubscribe();
  }, []);

  useEffect(() => {
    // Prevent searches for empty strings
    if (addressInputValue && addressInputValue !== '') {
      // When the address input value changes, dispatch an action to
      // get new suggestions. The epic will handle debouncing these requests
      // to not overload the API.
      // TODO: when preparing for shared component, this will need to be passed in
      dispatch(getAddressOptions({
        query: addressInputValue,
        country: 'us',
      }));
    }
  }, [addressInputValue]);

  useEffect(() => {
    if (businessAddrWatch !== null && businessAddrWatch !== undefined
        && businessAddrWatch !== addressInputValue) {
      setAddressInputValue(businessAddrWatch);
    }
  }, [setAddressInputValue, businessAddrWatch]);

  return (
    <Autocomplete
      id={id}
      autoComplete
      freeSolo
      loadingText="Loading..."
      defaultValue={!defaultAddress ? '' : defaultAddress}
      inputValue={!addressInputValue ? '' : addressInputValue}
      options={addressOptions}
      filterOptions={(x) => x}
      disabled={disabled || false}
      openOnFocus
      onChange={onAutocompleteChange}
      onInputChange={(event, newInputValue) => {
        setAddressInputValue(newInputValue);
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.address)}
      renderInput={(params) => (

        <StyledFormLabel error={hasError}>
          {labelText}
          <AltTextField
            {...params}
            label={null}
            variant="outlined"
            name={name}
            error={hasError}
            disabled={disabled || false}
            helperText={errors[name] ? errors[name].message : ''}
            {...register(name)}
          />
        </StyledFormLabel>

      )}
      renderOption={(htmlOption, dataOption) => (
        <li {...htmlOption} key={dataOption.id}>
          <AddressAutocompleteSuggestionTemplate
            address={dataOption.address}
            name={dataOption.name}
            includeNameInSuggestion={dataOption.includeNameInSuggestion}
            place={dataOption.place}
            region={dataOption.region}
            country={dataOption.country}
          />
        </li>
      )}
    />
  );
}

AutoCompleteAddress.propTypes = {
  defaultAddress: PropTypes.string,
  onAutoCompleteSelected: PropTypes.func,
  labelText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  addressOptions: PropTypes.array,
  id: PropTypes.string,
  onAddressChange: PropTypes.func,
};

AutoCompleteAddress.defaultProps = {
  onAutoCompleteSelected: () => {},
  labelText: 'Street Address',
  disabled: false,
  name: 'businessAddress',
  addressOptions: [],
  id: 'business-address-input',
  onAddressChange: () => {},
};
