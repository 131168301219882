export const EXPIRED_TRIAL_STATUS_CODE = 4004;

// Text
export const CHATBOT_SETTINGS_DIALOG_HEADER_TEXT = 'Chatbot Settings';
export const CHATBOT_NAME = 'Chatbot';

export const EMAIL_CAMPAIGN_FORM = 'emailForm';

export const SENDER_NAME_KEY = 'fromName';
export const SENDER_EMAIL_KEY = 'fromEmail';
export const REPLY_TO_EMAIL_KEY = 'replyToEmail';
export const STREET_ADDRESS_KEY = 'address';
export const STREET_ADDRESS_2_KEY = 'address2';
export const CITY_KEY = 'city';
export const STATE_KEY = 'state';
export const COUNTRY_KEY = 'country';
export const POSTAL_CODE_KEY = 'zip';
export const COPYRIGHT_KEY = 'copyrightMessage';

export const NO_AREA_CODE_ERR = 'No area code';

export const SMS_COMPLETED_PRODUCT_STATUS = 'COMPLETED';

export const EMAIL_FORM_KEYS = [
  SENDER_NAME_KEY,
  SENDER_EMAIL_KEY,
  REPLY_TO_EMAIL_KEY,
  STREET_ADDRESS_KEY,
  STREET_ADDRESS_2_KEY,
  CITY_KEY,
  STATE_KEY,
  COUNTRY_KEY,
  POSTAL_CODE_KEY,
  COPYRIGHT_KEY
];

