import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import { PrimaryButton } from '../../../../shared/settings-shared-components';
import { GraderFooter } from '../styles/grader-input-styles';

/**
 * Display grader input page's footer with scroll to top button
 */
export default function GraderInputFooter({ clickTarget }) {
  const handleClick = () => {
    document.getElementById(clickTarget).scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    document.getElementById(clickTarget).focus();
  };

  return (
    <Container maxWidth="md">
      <GraderFooter className="footerWrapper">
        <Typography className="footerPrompt">
          Improving your website is the first step in reaching more 
          customers and growing your business.
        </Typography>
        <Typography className="footerSubtext">
          Get your custom website and online presence report today for free.
        </Typography>
        <PrimaryButton
          className="scrollButton"
          type="button"
          onClick={handleClick}
        >
          Get Your Free Score
        </PrimaryButton>
      </GraderFooter>
    </Container>
  );
}

GraderInputFooter.propTypes = {
  clickTarget: PropTypes.string.isRequired,
};
