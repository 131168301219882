import { createSlice } from '@reduxjs/toolkit';
import { COLOR_KEY, STEP_STATUS } from './slice-constants';
import { loadWizardConfigSuccess } from './configuration-slice';
import { WIDGET_COLOR_KEY } from '../wizard/wizard-constants';

const WIDGET_COLOR_SLICE_NAME = 'widget_color';

const widgetColorSlice = createSlice({
  name: WIDGET_COLOR_SLICE_NAME,
  initialState: {
    [COLOR_KEY]: '',
    [STEP_STATUS]: '',
  },
  reducers: {
    updateColorField: (state, action) => {
      state[COLOR_KEY] = action.payload[COLOR_KEY];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadWizardConfigSuccess, (state, action) => {
      if (action.payload.length > 0) {
        const { data } = action.payload[0];
        if (data[WIDGET_COLOR_KEY]) {
          state[COLOR_KEY] = data[WIDGET_COLOR_KEY][COLOR_KEY];
        }
      }
    });
  },
});

export const {
  updateColorField,
} = widgetColorSlice.actions;
export default widgetColorSlice.reducer;
