import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import combineSlices from '../../../redux/combine-slices';
import { EMAIL_CAMPAIGNS_PAGE, SMS_PAGE } from '../../../shared/navigation/navigation-config';
import emailCampaignSlice, { updateEmailFooterFailure } from './emailCampaign-slice';
import { EXPIRED_TRIAL_STATUS_CODE } from './tools-constants';
import { saveBookingsFailure } from '../../products/bookings/data/bookings-slice';
import { saveCallRecorderFailure } from '../../products/callSettings/data/call-settings-slice';
import { saveChatbotFailure } from '../../products/chatbot/data/chatbot-slice';
import smsSlice from './sms-slice';

const initialState = {
  showWarningModal: false,
  rootKey: '',
  currentScreen: '',
  expiredError: false,
};

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setShowWarningModal: (state, action) => {
      state.showWarningModal = action.payload.value;
    },
    setCurrentToolsScreen: (state, action) => {
      const { rootKey, currentScreen } = action.payload;
      state.rootKey = rootKey;
      state.currentScreen = currentScreen;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        saveBookingsFailure,
        saveCallRecorderFailure,
        saveChatbotFailure,
        updateEmailFooterFailure,
      ),
      (state, action) => {
        const { response } = action.payload;
        if (response.statusCode === EXPIRED_TRIAL_STATUS_CODE) {
          state.expiredError = true;
        }
      },
    );
  },
});

export const {
  setShowWarningModal,
  setCurrentToolsScreen,
} = toolsSlice.actions;

export default combineSlices(
  toolsSlice.reducer,
  initialState,
  {
    [EMAIL_CAMPAIGNS_PAGE]: emailCampaignSlice,
    [SMS_PAGE]: smsSlice
  },
);
