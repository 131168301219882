import styled from '@emotion/styled';
import SkeletonDesktopRow from './SkeletonDesktopRow';
import SkeletonText from '../SkeletonText';

const StyledTableContent = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.common.black}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'unset',
  width: '100%',
  borderRadius: '8px',
  margin: theme.spacing(3, 0, 2, 0),
  background: theme.palette.common.white,
  minHeight: '320px',
  overflow: 'hidden',
}));

export default function LoadingSkeletonRecentActivityDesktop() {
  return (
    <div>
      <SkeletonText animation="wave" variant="string" width={250} height={30} />
      <StyledTableContent>
        <SkeletonDesktopRow />
        <SkeletonDesktopRow />
        <SkeletonDesktopRow />
        <SkeletonDesktopRow />
        <SkeletonDesktopRow />
      </StyledTableContent>
      <SkeletonText animation="wave" variant="string" width={98} height={30} />
    </div>
  );
}
