import { useMemo } from "react";
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import BookingsCTA from "../../../../../shared/BookingsCTA";
import CustomQuestionInput from "./CustomQuestionInput";
import ChatbotStateChangedHook from "../../../../../shared/stateChangedHook/ChatbotStateChangedHook";
import { CharacterCount } from "../../../../../shared/settings-shared-components";
import {
  CHATBOT_CUSTOM_QUESTION_SECTION,
  CHATBOT_CUSTOM_QUESTION_KEY,
  CHATBOT_CUSTOM_QUESTION_FORM
} from "../../chatbot-constants";
import SectionCard from "../../../../../shared/SectionCard";

const CustomQuestionWrapper = styled(Grid)(({ theme }) => ({
  marginTop: "24px",
  '& button.MuiButton-root': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const schema = yup.object().shape({
  [CHATBOT_CUSTOM_QUESTION_KEY]: yup.array().of(
    yup.object().shape({
      question: yup.string(),
      sequence: yup.number(),
    })
  )
})

const FORM_KEYS = [CHATBOT_CUSTOM_QUESTION_KEY]

export default function ChatbotCustomQuestionsCard() {
  const customQuestions = useSelector(state => state.chatbot.generalSettings[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY])

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [CHATBOT_CUSTOM_QUESTION_KEY]: customQuestions,
    }), [customQuestions]),
  });

  const { control, watch } = methods;

  const chatBotQuestionLength = 150;

  const { fields: customQuestionsList, append, remove } = useFieldArray({
    control: control,
    name: CHATBOT_CUSTOM_QUESTION_KEY,
  });

  const addAnotherQuestion = () => append({ question: '' });
  const onDeleteQuestionClick = (ind) => remove(ind)

  return (
    <SectionCard
      id="chatbot-custom-question-card"
      title="Custom Questions"
      tooltipText="Customize your script by adding up to 3 additional questions if you need specific information."
      showDividingLine={false}>
      <FormProvider {...methods}>
        {customQuestionsList?.map((question, index) => {
          const chatbotQuestionVal = watch(`${CHATBOT_CUSTOM_QUESTION_KEY}[${index}].question`);
          const chatbotQuestionCharCount = chatbotQuestionVal ? chatbotQuestionVal.length : 0;
          return (
            <div key={question.id}>
              <CustomQuestionInput
                id={`chatbot-customQuestion${index}`}
                name={`${CHATBOT_CUSTOM_QUESTION_KEY}[${index}].question`}
                question={question}
                index={index}
                parentLength={customQuestions.length}
                chatBotQuestionLength={chatBotQuestionLength}
                onDeleteCallback={onDeleteQuestionClick}
              />
              <CharacterCount>
                {chatbotQuestionCharCount} / {chatBotQuestionLength}
              </CharacterCount>
            </div>
          )
        })}
        {customQuestionsList.length < 3 && (
          <CustomQuestionWrapper item xs={12}>
            <BookingsCTA
              id="chatbot-add-custom-question-btn"
              text="Add Custom Question"
              variant="outlined"
              startIcon={<AddIcon />}
              onClickHandler={addAnotherQuestion}
            />
          </CustomQuestionWrapper>
        )
        }
        <ChatbotStateChangedHook formName={CHATBOT_CUSTOM_QUESTION_FORM} formKeys={FORM_KEYS} />
      </FormProvider>
    </SectionCard>
  )
}
