import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import NoAccess from "../pages/products/admin/components/NoAccess";
import { WIDGET_SETTINGS_PATH } from "./app-constants";

/**
 * Redirect users based on role (support, admin..etc)
 *
 * @param {string} redirectPath
 */

export default function ProtectedRoute({ redirectPath = WIDGET_SETTINGS_PATH, children, pathname = '' }) {
  const exceptionRoutes = ['/settings/tools/sms'];

  const isSupportRole = useSelector(state => state.appSettings.user?.hasSupportRole);
  const accessPath = useSelector((state) => state.appSettings.redirectPath);
  const hasExceptionRoute = exceptionRoutes?.includes(pathname);

  if (isSupportRole && !hasExceptionRoute) {
    return (
      <>
        <Navigate to={redirectPath} />
        <NoAccess />
      </>
    )
  }

  if (accessPath) {
    return (
      <>
        <Navigate to={accessPath} />
        <NoAccess />
      </>
    )
  }

  return children;
};