import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ReactComponent as GoogleLogo } from '../../images/google-icon-only.svg';
import { ReactComponent as DisabledGoogleLogo } from '../../images/google-icon-only-disabled.svg';

const SignInWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '70%',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));

const CustomButton = styled(Button)(() => ({
  // NOTE: DO NOT CHANGE GOpOGLE BLUE #4285F4
  border: '1px solid #4285F4',
  backgroundColor: '#4285F4',
  borderRadius: 0,
  padding: 0,
  width: '100%',
  justifyContent: 'flex-start',
  '&:hover': {
    backgroundColor: '#4285F4',
  },
  '&.Mui-disabled': {
    borderColor: '#E9E9E9',
    backgroundColor: '#E9E9E9',
  },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Roboto',
  fontSize: 16,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  flex: 1,
}));

/**
 * Customized Google sign in button
 */
export default function GoogleSignInButton({
  onClick, disabled,
}) {
  return (
    <SignInWrapper>
      <CustomButton type="button" variant="text" onClick={onClick} disabled={disabled}>
        {disabled && <DisabledGoogleLogo /> }
        {!disabled && <GoogleLogo /> }
        <ButtonText component="span">Sign in with Google</ButtonText>
      </CustomButton>
    </SignInWrapper>
  );
}

GoogleSignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
