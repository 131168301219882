import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { startFreeTrial } from './data/configuration-slice';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import ConfigurationDialog from './ConfigurationDialog';
import { hasBundleOrder } from '../../shared/app-utils';

const SpinnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

export default function ConfigurationLandingPage() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.appSettings);
  const trialInfo = useSelector((state) => state.configuration.trialInfo);
  const orders = useSelector((state) => state.appSettings.orders);

  useEffect(() => {
    // Only start the free trial if this user has no trial and also
    // has no active bundle order (someone that bought without a trial)
    const hasActiveBundleOrder = hasBundleOrder(orders, true);
    // We check the trialInfo keys length, if it's more than 0 we shouldn't
    // start a free trial as one already exists.  Active or not, expired or not etc.
    // it just indicates one was generated so don't do another one. Finally we
    // still check if a user has a bundle purchase already to avoid starting the trial.
    if (user && trialInfo && Object.keys(trialInfo).length === 0 && !hasActiveBundleOrder) {
      dispatch(
        startFreeTrial(),
      );
    }
  }, [trialInfo, user]);

  if (user) {
    return (
      <>
        <Helmet>
          <title>
            Configuration | LocaliQ
          </title>
        </Helmet>
        <ConfigurationDialog />
      </>
    );
  }

  return (
    <SpinnerWrapper>
      <IQLoadingSpinner />
    </SpinnerWrapper>
  );
}
