import styled from '@emotion/styled';
import BookingsCTA from '../../../../../shared/BookingsCTA';
import SectionCard from '../../../../../shared/SectionCard';

const EmailDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: theme.spacing(1, 0, 0, 0),
}));

export default function EmailNotificationsCard() {
  const EMAIL_FAQ_LINK = 'https://help.localiq.com/en/articles/8202499-localiq-lead-notification-emails';

  const goToEmailFAQs = () => {
    window.open(EMAIL_FAQ_LINK, '_blank');
  };

  return (
    <SectionCard
      id="email-notifications"
      title="Email Notifications"
      description={(
        <EmailDescription>
          Refer to our FAQ for email notification specifics.
          Both you and your customers will receive email notifications automatically.
          <BookingsCTA id="email-fqs-btn" text="Email FAQs" onClickHandler={goToEmailFAQs} variant="contained" />
        </EmailDescription>
      )}
      showDividingLine={false}
    />
  );
}
