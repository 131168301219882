import { DateTime } from 'luxon';
import { compareTime, checkIfSameTime } from '../app-utils';

// Determine whether two time sets overlap
export const determineOverlap = (timeSlots) => {
  if (timeSlots.length > 1) {
    const firstTime = timeSlots[0];
    const secondTime = timeSlots[1];
    if (secondTime.startTime && secondTime.endTime) {
      return compareTime(secondTime.startTime, firstTime.endTime)
        && !compareTime(secondTime.endTime, firstTime.startTime);
    }
  }
  return false;
};

// Whether a timeSlots endTime value is before its startTime
export const endBeforeStart = (timeSlot) => (
  compareTime(timeSlot.endTime, timeSlot.startTime)
);

// Whether a timeSlots startTime is the same as endTime
export const startEqualToEnd = (timeSlot) => (
  checkIfSameTime(timeSlot.startTime, timeSlot.endTime)
);

// Whether a day array contains a day
export const checkDay = (day, days) => (
  days?.indexOf(day) > -1
);

/**
 * Function that transforms 24 hour based time to 12 based time
 * @param {String} time24Based - ex: 15:00:00.000
 * @returns String - ex: 03:00 pm
 */
export const convertTimeFrom24to12Based = (time24Based) => {
  const dateTime24Hour = DateTime.fromFormat(time24Based.slice(0, 5), 'HH:mm');
  const dateTime12Hour = dateTime24Hour.toFormat('hh:mm a');
  return dateTime12Hour.toLowerCase();
};
