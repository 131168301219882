import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import DashboardGrid from './DashboardGrid';
import SettingsLayout from '../../layouts/SettingsLayout';
import {
  CONFIG_PATH,
  REG_TEMPLATE_GROWTH_SUITE,
  WIZARD_STATUS_COMPLETED,
  SOURCE_QUERY_PARAM_KEY,
  SOURCE_CHECKOUT,
} from '../../shared/app-constants';
import { FullPageSpinnerWrapper } from '../../shared/settings-shared-components';
import { TRIAL_SOURCES, TRIAL_SOURCE_KEY } from '../../shared/analytics-utils';
import ProtectedRoute from '../../shared/ProtectedRoute';

export default function DashboardPage() {
  const navigate = useNavigate();
  const regTemplate = useSelector((state) => state.appSettings.user?.regTemplate);
  const userSource = useSelector((state) => state.appSettings.user?.userSource);
  const trials = useSelector((state) => state.account.trials);
  const hasTrials = Array.isArray(trials) && trials.length > 0;
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);
  const wizardStatus = useSelector((state) => state.configuration.status);
  const [searchParams, _setSearchParams] = useSearchParams();
  const [readyToRender, setReadyToRender] = useState(false);

  const redirectToOnboarding = () => {
    navigate({
      pathname: CONFIG_PATH,
    });
  };

  useEffect(() => {
    // TODO: At some point this should be moved to a better method as it grows
    if (loadedSettings) {
      const source = searchParams.get(SOURCE_QUERY_PARAM_KEY);
      // This represents a redirect from marketing (possibly through login/register) to here
      if (regTemplate === REG_TEMPLATE_GROWTH_SUITE && !hasTrials) {
        sessionStorage.setItem(TRIAL_SOURCE_KEY, TRIAL_SOURCES.BUSINESS_CREATE);
        redirectToOnboarding();
      } else if (source === SOURCE_CHECKOUT && wizardStatus !== WIZARD_STATUS_COMPLETED) {
        redirectToOnboarding();
      } else if (userSource === 'EXISTING_USER_MIGRATED' && wizardStatus === '') {
        // If this is a migrated user and they have no wizard status then they have never
        // started a wizard and should be pushed into it automatically.
        redirectToOnboarding();
      } else {
        // Only set ready to render if we aren't redirecting elsewhere
        // This will avoid the dashboard flashing.
        setReadyToRender(true);
      }
    }
  }, [loadedSettings]);

  if (!readyToRender) {
    return (
      <ProtectedRoute>
        <FullPageSpinnerWrapper>
          <IQLoadingSpinner />
        </FullPageSpinnerWrapper>
      </ProtectedRoute>
    );
  }

  return (
    <ProtectedRoute>
      <SettingsLayout>
        <Helmet>
          <title>
            Dashboard | LocaliQ
          </title>
        </Helmet>
        <DashboardGrid />
      </SettingsLayout>
    </ProtectedRoute>
  );
}
