import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography, Grid } from '@mui/material';
import { ReactComponent as ChatBotThreadIcon } from '../../assets/chatbot-thread-icon.svg';
import { ReactComponent as AppointmentScheduleIcon } from '../../assets/appointment-shcedule-icon.svg';
import { ReactComponent as CallRecordingIcon } from '../../assets/call-recording-icon.svg';
import { ReactComponent as FormFillIcon } from '../../assets/form-fill-icon.svg';
import {
  ACTIVITY_EVENT_TYPE_DISPLAY_MAP, CALL_ACTIVITY, CHAT_ACTIVITY, FORM_ACTIVITY, LEAD_TYPE_TO_MP, SCHEDULING_ACTIVITY,
} from '../../dashboard-constants';
import { trackSingleLeadClick } from '../../../../shared/analytics-utils';

const { REACT_APP_LEADS_URL } = process.env;

const StyledSingleLeadContainer = styled(Grid)(({ theme }) => ({
  // TODO: May need modifications if the styling in the DashboardGrid.jsx (GridContainer) changes
  '& .MuiGrid-item': {
    border: `none`,
    '& > div': {
      padding: theme.spacing(0),
    },
  },
  cursor: 'pointer',
  border: `3px solid transparent`,
  '&:hover': {
    border: `3px solid ${theme.palette.info.main}`,
  },

  [theme.breakpoints.down('md')]: {
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
  },
}));

const StyledCellContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const StyledType = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
}));

const StyledTopic = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}));

const StyledDisplayName = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}));

const StyledOccurredAt = styled(Typography)(({ theme }) => ({
  letterSpacing: '0.5px',
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

function getProductIcon(type) {
  if (type === ACTIVITY_EVENT_TYPE_DISPLAY_MAP[CHAT_ACTIVITY]) {
    return <ChatBotThreadIcon />;
  }
  if (type === ACTIVITY_EVENT_TYPE_DISPLAY_MAP[SCHEDULING_ACTIVITY]) {
    return <AppointmentScheduleIcon />;
  }
  if (type === ACTIVITY_EVENT_TYPE_DISPLAY_MAP[CALL_ACTIVITY]) {
    return <CallRecordingIcon />;
  }
  if (type === ACTIVITY_EVENT_TYPE_DISPLAY_MAP[FORM_ACTIVITY]) {
    return <FormFillIcon />;
  }

  return null;
}

export default function SingleLead({
  id,
  type,
  displayName,
  displayTime,
  infoTitle,
  info,
}) {
  const productIcon = getProductIcon(type);

  const singleLeadCardClick = () => {
    trackSingleLeadClick(LEAD_TYPE_TO_MP[type]);
    window.open(`${REACT_APP_LEADS_URL.slice(0, -1)}/${id}`, '_self');
  };

  return (
    <StyledSingleLeadContainer container gap={isMobile ? 1 : 0} md={12} paddingY={2} paddingX={2} onClick={singleLeadCardClick}>
      <StyledCellContainer container item md={4} justifyContent="start">
        {productIcon}
        <StyledType>{type}</StyledType>
      </StyledCellContainer>
      <StyledCellContainer container item md={4}>
        <StyledTopic>
          {infoTitle}
        </StyledTopic>
        <Typography>{info}</Typography>
      </StyledCellContainer>
      <StyledCellContainer container item md={3}>
        <StyledDisplayName>{displayName}</StyledDisplayName>
      </StyledCellContainer>
      <StyledCellContainer container item md={1} justifyContent={isMobile ? 'start' : 'end'}>
        <StyledOccurredAt>{displayTime}</StyledOccurredAt>
      </StyledCellContainer>
    </StyledSingleLeadContainer>
  );
}

SingleLead.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  displayName: PropTypes.string,
  displayTime: PropTypes.string,
  infoTitle: PropTypes.string,
  info: PropTypes.string,
};
