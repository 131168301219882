import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { TableCell, TableRow, Typography } from '@mui/material';
import { addDayAbbreviation } from '../../account-utils';
import { CustomCard } from '../../../../../shared/settings-shared-components';
import { trackDownloadInvoice } from '../../../../../shared/analytics-utils';

const InvoiceMobileWrapper = styled(CustomCard)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '57%',
  },
}));

const ProductName = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontFamily: 'Unify Sans',
  color: theme.palette.common.black,
  display: 'flex',
  justifyContent: 'center'
}));

const CustomDivider = styled('hr')(() => ({
  width: '100%',
  background: '#D7D7D7',
  height: '1px',
  border: '0px'
}));

const MobileRowWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const MobileTableItem = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.common.black
}));

const MobileTableName = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.secondary
}));

const MobileLinkWrapper = styled('div')(() => ({
  marginTop: '1rem'
}));

const InvoiceLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main
}));

export default function InvoiceRowCard({ invoice }) {
  const productName = invoice?.items[0]?.product?.displayName;
  const unixTimeStamp = invoice?.created;
  const date = new Date(unixTimeStamp * 1000);
  const displayDate = new Date(unixTimeStamp * 1000)
    .toDateString()
    .split(" ")
    .slice(1);
  displayDate[0] = date.toLocaleString("default", { month: "long" });
  displayDate[1] = addDayAbbreviation(displayDate[1]) + ",";
  const convertedInvoiceDate = `${displayDate.join(" ")}`;
  const invoiceAmount = `$${(invoice.amount / 100).toFixed(2)}`;

  const getDownloadInvoiceID = (id) => {
    trackDownloadInvoice(id)
  }

  if (isMobile) {
    return (
      <InvoiceMobileWrapper>
        <ProductName>{productName}</ProductName>
        <CustomDivider />
        <MobileRowWrapper>
          <MobileTableName>Invoice Date</MobileTableName>
          <MobileTableItem>{convertedInvoiceDate}</MobileTableItem>
        </MobileRowWrapper>
        <CustomDivider />
        <MobileRowWrapper>
          <MobileTableName>Amount</MobileTableName>
          <MobileTableItem>{invoiceAmount}</MobileTableItem>
        </MobileRowWrapper>
        <MobileLinkWrapper>
          <InvoiceLink
            href={invoice.invoicePdf}
            target="_blank"
            onClick={() => getDownloadInvoiceID(invoice.id)}
          >
            Download Invoice
          </InvoiceLink>
        </MobileLinkWrapper>
      </InvoiceMobileWrapper>
    );
  }

  return (
    <TableRow key={invoice.number}>
      <TableCell style={{ width: 140 }}>
        <ProductName>{productName}</ProductName>
      </TableCell>
      <TableCell style={{ width: 50 }} align="center">
        {convertedInvoiceDate}
      </TableCell>
      <TableCell style={{ width: 100 }} align="center">
        {invoiceAmount}
      </TableCell>
      <TableCell style={{ width: 160 }} align="center">
        <InvoiceLink
          href={invoice.invoicePdf}
          target="_blank"
          onClick={() => getDownloadInvoiceID(invoice.id)}
        >
          Download Invoice
        </InvoiceLink>
      </TableCell>
    </TableRow>
  );
}

InvoiceRowCard.propTypes = {
  invoice: PropTypes.object.isRequired,
};
