import { Typography } from '@mui/material';
import { InputHeaderWrapper } from '../styles/grader-input-styles';
import { ReactComponent as Blob1 } from '../assets/blob1-input.svg';
import { ReactComponent as Blob2 } from '../assets/blob2-input.svg';
import { ReactComponent as Blob3 } from '../assets/blob3-input.svg';
import { ReactComponent as Blob4 } from '../assets/blob4-input.svg';

/**
 * Grader input screen's top level background
 */
export default function GraderInputHeader() {
  return (
    <InputHeaderWrapper>
      <div className="inputHeaderBackground">
        <div className="blur1">
          <Blob1 />
        </div>
        <div className="blur2">
          <Blob2 />
        </div>
        <div className="blur3">
          <Blob3 />
        </div>
        <div className="blur4">
          <Blob4 />
        </div>
      </div>
      <div className="headerContent">
        <Typography>
          Find out if your website is set up for success with Website Grader
        </Typography>
      </div>
    </InputHeaderWrapper>
  );
}
