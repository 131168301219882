import { useNavigate, useSearchParams } from "react-router-dom";
import { IS_INTERNAL_LINK_KEY, ORIGIN_LINK_KEY } from "../../pages/products/account/account-constants";
import { useMountEffect } from "../mountEffectHook/useMountEffect";
import { DASHBOARD_PATH } from "../app-constants";

export default function withOriginLink(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();
    const [searchParams, _setSearchParams] = useSearchParams();

    const onHandleOriginLinkClose = () => {
      const { originLink, isInternal } = JSON.parse(
        window.sessionStorage.getItem(ORIGIN_LINK_KEY)
      );
      window.sessionStorage.removeItem(ORIGIN_LINK_KEY);
  
      if (isInternal) {
        navigate(originLink);
      } else {
        // Using assign leaves history intact so back button will still work
        window.location.assign(originLink);
      }
    }

    useMountEffect(() => {
      const originLinkQuery = searchParams.get(ORIGIN_LINK_KEY);
      const isInternal = searchParams.get(IS_INTERNAL_LINK_KEY) === "true";

      if (originLinkQuery) {
        window.sessionStorage.setItem(
          ORIGIN_LINK_KEY,
          JSON.stringify({ originLink: originLinkQuery, isInternal }),
        );
      } else {
        window.sessionStorage.setItem(
          ORIGIN_LINK_KEY,
          JSON.stringify({ originLink: DASHBOARD_PATH, isInternal: true }),
        );
      }
    });

    const withOriginLinkProps = {...props, onHandleOriginLinkClose};

    return (
      <WrappedComponent {...withOriginLinkProps} />
    )
  }
};
