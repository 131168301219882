import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { IQProgressBar } from '@gannettdigital/shared-react-components';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';
import { displayCalc } from '../../../../../shared/app-utils';
import { SplitColumn } from '../../../../../shared/settings-shared-components';
import {
  ACCOUNT_NAV_STEPS,
  BUSINESS_DETAILS_PAGE,
  CREDIT_CARDS_PAGE,
  INVOICES_PAGE,
  SUBSCRIPTIONS_PAGE,
} from '../../../../../shared/navigation/navigation-config';
import NeedHelp from '../../../../../shared/needHelp/NeedHelp';

const TitleWrapper = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

export default function DialogSkeleton({ currentScreen, isLoading }) {
  return (
    <SplitColumn>
      {!isMobile && (
        <TitleWrapper>
          <Typography variant='h4'>{ACCOUNT_NAV_STEPS[currentScreen].display}</Typography>
        </TitleWrapper>
      )}
      <div
        style={{
          display: displayCalc(currentScreen, BUSINESS_DETAILS_PAGE),
        }}
      >
        <BusinessDetailsSkeleton />
      </div>
      <div
        style={{
          display: displayCalc(currentScreen, SUBSCRIPTIONS_PAGE),
        }}
      >
        <IQProgressBar loading={isLoading} />
      </div>
      <div style={{ display: displayCalc(currentScreen, INVOICES_PAGE) }}>
        <IQProgressBar loading={isLoading} />
      </div>
      <div style={{ display: displayCalc(currentScreen, CREDIT_CARDS_PAGE) }}>
        <IQProgressBar loading={isLoading} />
      </div>
      {isMobile && (
        <NeedHelp
          title='Need Help?'
          content={
            <>
              If you need to to cancel or make changes, contact{' '}
              <a href='#'>support@localiq.com</a> from your
              account-related email address.
            </>
          }
        />
      )}
    </SplitColumn>
  );
}

DialogSkeleton.propTypes = {
  currentScreen: PropTypes.string,
  isLoading: PropTypes.bool,
};
