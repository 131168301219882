import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import NoLeads from './NoLeads';
import SingleLead from './SingleLead';

const StyledActivatedContainer = styled('div')(() => ({
  width: '100%',

  '&.max-leads': {
    display: 'flex',
  }
}));

const StyledLeadsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const StyledEndOfActivityBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'max-content',
  alignItems: 'center',
  gap: theme.spacing(1),
  margin: '24px auto',
}));

const StyledEndOfActivityLine = styled('div')(({ theme }) => ({
  width: '40px',
  border: `1px solid ${theme.palette.action.disabledBackground}`,
}));

const StyledEndOfActivityTxt = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '15px',
  letterSpacing: '1px',
}));

export default function Activated({ hasLeads, leads }) {
  return (
    <StyledActivatedContainer className={leads.length >= 5 ? 'max-leads' : ''}>
      {!hasLeads ? (
        <NoLeads />
      ) : (
        <StyledLeadsContainer>
          {leads.map((lead) => (
            <SingleLead
              key={lead.id}
              {...lead}
            />
          ))}
          {leads.length < 5 && (
            <StyledEndOfActivityBox>
              <StyledEndOfActivityLine />
              <StyledEndOfActivityTxt>
                End of Activity
              </StyledEndOfActivityTxt>
              <StyledEndOfActivityLine />
            </StyledEndOfActivityBox>
          )}
        </StyledLeadsContainer>
      )}
    </StyledActivatedContainer>
  );
}

Activated.propTypes = {
  hasLeads: PropTypes.bool.isRequired,
  leads: PropTypes.array.isRequired,
};
