// Constants connected to data need to be defined here otherwise they cannot be accessed inside the steps
// widget features
export const CALL_TRACKER_FEATURE = 'activateCalltracker';
export const CHAT_FEATURE = 'activateChat';
export const SCHEDULER_FEATURE = 'activateScheduler';
export const CONTACT_FORM_FEATURE = 'activateMessage';
export const STEP_STATUS = 'stepStatus';

// widget color
export const COLOR_KEY = 'color';

// business phone number 
export const BUSINESS_PHONE_NUMBER_KEY = 'businessPhoneNumber';

// call-recorder toggle key
export const CALL_RECORDER = 'callRecording'