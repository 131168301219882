import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SchedulerAppointmentsList from './sections/SchedulerAppointmentsList';
import { loadSchedulerAllAppointmentsAction, loadSchedulerAppointments } from './data/bookings-slice';
import TrialExpiredBanner from '../../../shared/trials/TrialExpiredBanner';
import TrialStartBanner from '../../../shared/trials/TrialStartBanner';
import AppointmentsListener from '../../../shared/hooks/AppointmentsListener';

const BookingsManagementWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
  backgroundColor: theme.palette.background.default,
  '.bookings-mgmt-content': {
    width: '100%',
    backgroundColor: theme.palette.background.default
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  }
}));
export default function BookingsManagement({ handleOpenSettings }) {
  const dispatch = useDispatch();
  const identifier = useSelector((state) => state.bookings.identifier);
  const appointmentsType = useSelector((state) => state.bookings.appointments.type);

  useEffect(() => {
    if (identifier) {
      dispatch(
        loadSchedulerAllAppointmentsAction()
      );
      dispatch(
        loadSchedulerAppointments({
          page: 0,
          pageSize: 10,
          filter: appointmentsType,
        })
      );
    }
  }, [identifier]);

  return (
    <BookingsManagementWrapper>
      <div className='bookings-mgmt-content'>
        <TrialStartBanner />
        <TrialExpiredBanner />
        <AppointmentsListener />
        <SchedulerAppointmentsList
          handleOpenSettings={handleOpenSettings}
        />
      </div>
    </BookingsManagementWrapper>
  )
}

BookingsManagement.propTypes = {
  handleOpenSettings: PropTypes.func.isRequired,
};

