import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { IQFormPhoneInput, SectionCard, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdjustCardWrapper } from '../../../../shared/settings-shared-components';
import { BUSINESS_NUMBER_FORM, CALL_RECORDING_ACTIVE_KEY, NEW_BASE_NUMBER_KEY } from '../call-settings-constants';
import { updateGeneralSettingsField } from '../data/general-settings-slice';
import { isValidUSPhoneNumberLength, PHONE_NUMBER_REGEX } from '../../../../shared/validation-utils';
import CallSettingsStateChangedHook from '../../../../shared/stateChangedHook/CallSettingsStateChangedHook';
import { ReactComponent as InfoIcon } from '../../../../shared/assets/info-icon.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone-light-icon.svg';
import { ReactComponent as ErrorIcon } from '../assets/error-icon.svg';

const InputWrapper = styled('div')(({ theme }) => ({
  '& .MuiFormControl-root': {
    width: 300,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  '& input': {
    maxHeight: 38,
  },
  '& label': {
    color: theme.palette.text.primary,
  },
}));

const CardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .tooltipWrapper': {
    position: 'relative',
    paddingLeft: theme.spacing(1),
    top: 2,
    '& svg': {
      cursor: 'pointer',
      color: '#0046FF',
      height: 18,
      width: 18,
    },
  },
}));

const schema = yup.object().shape({
  [NEW_BASE_NUMBER_KEY]: yup.string().required('Please enter a valid business number.').nullable()
    .test('len', 'Phone number must be 10 digits long', (val) => {
      if (!val) {
        return false;
      }

      return isValidUSPhoneNumberLength(val);
    })
    .matches(PHONE_NUMBER_REGEX, 'Please enter a valid business number.'),
});

const PHONE_INPUT_ID = 'callRecorder-phone-input';
const FORM_KEYS = [NEW_BASE_NUMBER_KEY];

export default function BusinessNumberCard() {
  const dispatch = useDispatch();
  const phoneNumber = useSelector((state) => state.callSettings.generalSettings.originalNumber);
  const {
    [CALL_RECORDING_ACTIVE_KEY]: callRecorderIsActive,
  } = useSelector(
    (state) => state.callSettings.generalSettings,
  );

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [NEW_BASE_NUMBER_KEY]: phoneNumber,
    },
  });

  const { trigger } = methods;

  const handlePhoneChange = async (newNumber) => {
    const validNumber = await trigger(NEW_BASE_NUMBER_KEY);
    if (validNumber) {
      dispatch(updateGeneralSettingsField({
        fieldName: NEW_BASE_NUMBER_KEY,
        fieldValue: newNumber,
      }));
    } else {
      dispatch(updateGeneralSettingsField({
        fieldName: NEW_BASE_NUMBER_KEY,
        fieldValue: null,
      }));
    }
  };

  return (
    <AdjustCardWrapper>
      <SectionCard
        id="business-number-card"
        title="Your Business Number"
        description="Calls will route to your specified business phone number."
        showDividingLine={false}
        shouldHaveLabelTooltip
        tooltipText={`If Call Recording is "On" you will not be able to update or change your business number`}
        tooltipIcon={InfoIcon}
      >
        <CardWrapper>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <InputWrapper>
                <IQFormPhoneInput
                  labelText=""
                  name={NEW_BASE_NUMBER_KEY}
                  id={PHONE_INPUT_ID}
                  fullWidth
                  onChange={handlePhoneChange}
                  disabled={callRecorderIsActive}
                  phoneIcon={<PhoneIcon />}
                  useSchemaError
                  errorIcon={<ErrorIcon />}
                />
              </InputWrapper>
              <CallSettingsStateChangedHook
                formName={BUSINESS_NUMBER_FORM}
                formKeys={FORM_KEYS}
              />
            </FormProvider>
          </ValidationProvider>
        </CardWrapper>
      </SectionCard>
    </AdjustCardWrapper>
  );
}
