import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';
import { Box, Fade, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { adjustScore, determineScoreObject } from '../grader-utils';
import ScoreDisplay from './ScoreDisplay/ScoreDisplay';
import { FADE_TIMING } from '../data/grader-constants';

const SpacerBox = styled(Box)(() => ({
  height: 145,
}));

const ScoreWrapper = styled(Box)(({ score, theme }) => ({
  position: 'relative',
  left: `${score - 50}%`,

  '&::before': {
    content: '""',
    display: 'inline-block',
    zIndex: 2,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 6px',
    borderRadius: 8,
    right: 'auto',
    left: '50%',
    bottom: 20,
    transform: 'translateY(50%) translateX(-50%) rotate(45deg)',
    width: 40,
    height: 40,
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
      bottom: 15,
    },
  },

  [theme.breakpoints.down('md')]: {
    width: 130,
  },
}));

const ScoreBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  fontSize: 32,
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  position: 'relative',
  zIndex: 3,

  [theme.breakpoints.down('md')]: {
    flexFlow: 'column',
    marginBottom: 10,

    '& .numerator': {
      fontSize: 25,
    },
    '& .denominator': {
      fontSize: 16,
    },
  },
}));

const ScoreTextWrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  textAlign: 'center',

  '& .description': {
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    fontSize: 12,
    fontFamily: 'Unify Sans SemiBold',
    lineHeight: '16px',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 8,
      lineHeight: '1.33333',
      whiteSpace: 'nowrap',
    },
  },
  '& .level': {
    textTransform: 'none',
    fontSize: 34,
    fontFamily: 'Unify Sans SemiBold',
    lineHeight: '37px',
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
      lineHeight: '1.08824',
    },
  },

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
  },
}));

/**
 * Score "billboard" component. Displays the score fraction and description/level text
 */
export default function ScoreBillboard({ score }) {
  const { color: scoreColor, level } = determineScoreObject(score);
  const adjustedScore = adjustScore(score);
  const descriptionText = isMobile ? 'Your level' : 'Your online presence level is:';

  if (score === null) {
    return (
      <SpacerBox />
    );
  }
  return (
    <Fade in timeout={FADE_TIMING}>
      <ScoreWrapper score={adjustedScore}>
        <ScoreBox>
          <ScoreDisplay score={score} scoreColor={scoreColor} />
          <ScoreTextWrapper>
            <Typography className="description" component="div">
              {descriptionText}
              <Typography className="level">{level}</Typography>
            </Typography>
          </ScoreTextWrapper>
        </ScoreBox>
      </ScoreWrapper>
    </Fade>
  );
}

ScoreBillboard.propTypes = {
  score: PropTypes.number,
};

ScoreBillboard.defaultProps = {
  score: null,
};
