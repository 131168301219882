import {
  catchError, filter, map, of, switchMap,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { dismissBanner, dismissBannerSuccess, dismissBannerFailure } from './business-attributes-slice';
import { DISMISS_BANNER_SECTION } from './app-constants';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const dismissBannerEpic = (action$, state$) => action$.pipe(
  filter(dismissBanner.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}def-business/attributes`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        [DISMISS_BANNER_SECTION]: {
          ...state$.value.businessAttributes[DISMISS_BANNER_SECTION],
        },
      },
      withCredentials: true,
      crossDomain: true,
    },
  ).pipe(
    map((response) => dismissBannerSuccess(response.response)),
    catchError((error) => of(dismissBannerFailure(error))),
  )),
);

export default [
  dismissBannerEpic,
];
