import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setStateClean, setStateDirty, setStateInvalid } from '../../pages/products/bookings/data/bookings-slice';
import StateChangedHook from './StateChangedHook';

/**
 * Specific state changed implementation for scheduler
 */
export default function SchedulerStateChangedHook({
  formName,
  formKeys = [],
  subsectionKey,
}) {
  const dispatch = useDispatch();
  const resetData = useSelector((state) => (subsectionKey
    ? state.bookings.resetProductInfo[subsectionKey] : state.bookings.resetProductInfo));
  const hasReset = useSelector((state) => state.bookings.hasReset);

  const onDirtyForm = () => {
    dispatch(setStateDirty({ formName }));
  };

  const onCleanForm = () => {
    dispatch(setStateClean({ formName }));
  };

  const onInvalid = () => {
    dispatch(setStateInvalid({ formName }));
  };

  return (
    <StateChangedHook
      formName={formName}
      formKeys={formKeys}
      resetData={resetData}
      hasReset={hasReset}
      onDirty={onDirtyForm}
      onClean={onCleanForm}
      onInvalid={onInvalid}
    />
  );
}

SchedulerStateChangedHook.propTypes = {
  formName: PropTypes.string.isRequired,
  formKeys: PropTypes.array.isRequired,
  subsectionKey: PropTypes.string,
};
