import { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DAYS_AND_TIMES_FORM } from '../bookings-constants';
import {
  END_BEFORE_START,
  START_END_ARE_EQUAL,
  TIME_SLOT_OVERLAP,
  MONDAY_KEY,
  TUESDAY_KEY,
  WEDNESDAY_KEY,
  THURSDAY_KEY,
  FRIDAY_KEY,
  SATURDAY_KEY,
  SUNDAY_KEY,
  TIME_SLOTS_KEY,
  DAY_TIME_SLOTS_KEY
} from '../../../../shared/availability/availability-constants';
import {
  determineOverlap,
  startEqualToEnd,
  endBeforeStart,
  checkDay,
} from '../../../../shared/util/day-time.utils';
import {
  addNewSlotAvailabilitySameSchedule,
  AVAILABILITY_SECTION,
  CONVERTED_DAYS_SCHEDULE_KEY,
  CONVERTED_SAME_SCHEDULE_KEY,
  DAYS_SCHEDULE_KEY,
  deleteAvailabilityDaysSchedule,
  deleteAvailabilitySameSchedule,
  SAME_SCHEDULE_KEY,
  TIMEZONE_KEY,
  SAME_TIME_KEY,
  updateAvailabilityField,
  updateAvailabilityDaysSchedule,
  updateAvailabilitySameScheduleDays,
  updateSelectOnAvailabilityDaysSchedule,
  updateAvailabilitySameSchedule,
} from '../data/general-settings-slice';
import { setStateClean, setStateDirty, setStateInvalid } from '../data/bookings-slice';
import { getLocalTimezone } from '../../../../shared/util/get-local-timezone.utils';
import TimezoneWidget from '../../../../shared/availability/TimezoneWidget';
import SameTime from '../../../../shared/availability/SameTime';
import NonMatchingHours from '../../../../shared/availability/NonMatchingHours';
import MatchingHours from '../../../../shared/availability/MatchingHours';
import SectionCard from '../../../../shared/SectionCard';

// Special css for single line checkboxes and full stretch time slots
const HoursWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  '& > div:first-of-type': {
    width: '100%',
  },
  '& .timeWrapper': {
    width: '100%',
    '& .timeInputWrapper': {
      width: '100%',
      '& div.MuiFormControl-root': {
        width: '100%',
      },
    },
    '& .timeBlockWrapper > div:first-of-type': {
      width: '100%',
    },
  },

  '& .MuiGrid-root.MuiGrid-container.MuiGrid-item.custom': {
    [theme.breakpoints.up('xl')]: {
      // 100% / 7
      maxWidth: '14.2%',
      flexBasis: '14.2%',
    },
  },
}));

function AvailabilityCard() {
  const dispatch = useDispatch();
  const {
    [CONVERTED_SAME_SCHEDULE_KEY]: scheduleValue,
    [SAME_TIME_KEY]: sameValue,
    [CONVERTED_DAYS_SCHEDULE_KEY]: daysValue,
    [TIMEZONE_KEY]: savedTimezone,
  } = useSelector((state) => state.generalSettings[AVAILABILITY_SECTION]);
  const resetData = useSelector((state) => state.bookings.resetProductInfo);
  const hasReset = useSelector((state) => state.bookings.hasReset);

  const selectedDays = useSelector(
    (state) => state.generalSettings[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY].days);

  const schedulesSchema = yup.object().shape({
    startTime: yup.string().required(),
    endTime: yup.string().required(),
  }).test({
    message: END_BEFORE_START,
    test: (val) => {
      if (val) {
        return !endBeforeStart(val);
      }
      return true;
    },
  }).test({
    message: START_END_ARE_EQUAL,
    test: (val) => {
      if (val) {
        return !startEqualToEnd(val);
      }
      return true;
    }
  });

  const daysSchema = {
    selected: yup.boolean(),
    schedules: yup.array().when('selected', {
      is: true,
      then: yup.array().of(schedulesSchema),
    })
      .test({
        message: TIME_SLOT_OVERLAP,
        test: (val) => {
          if (val) {
            return !determineOverlap(val);
          }
          return true;
        },
      }),
  };

  const schema = yup.object().shape({
    [TIMEZONE_KEY]: yup.string().required(),
    [SAME_TIME_KEY]: yup.boolean(),
    [MONDAY_KEY]: yup.boolean(),
    [TUESDAY_KEY]: yup.boolean(),
    [WEDNESDAY_KEY]: yup.boolean(),
    [THURSDAY_KEY]: yup.boolean(),
    [FRIDAY_KEY]: yup.boolean(),
    [SATURDAY_KEY]: yup.boolean(),
    [SUNDAY_KEY]: yup.boolean(),
    [TIME_SLOTS_KEY]: yup.mixed().when(SAME_TIME_KEY, {
      is: true,
      then: yup.array().of(schedulesSchema)
        .test({
          message: TIME_SLOT_OVERLAP,
          test: (val) => {
            if (val) {
              return !determineOverlap(val);
            }
            return true;
          },
        }),
    }),
    [DAY_TIME_SLOTS_KEY]: yup.mixed().when(SAME_TIME_KEY, {
      is: false,
      then: yup.array().of(yup.object().shape(daysSchema)),
    }),
  });

  const getLocalTimezoneValue = useCallback(getLocalTimezone, [Date]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [TIMEZONE_KEY]: savedTimezone || getLocalTimezoneValue(),
      [SAME_TIME_KEY]: sameValue,
      [MONDAY_KEY]: selectedDays ? checkDay(MONDAY_KEY, selectedDays) : true,
      [TUESDAY_KEY]: selectedDays ? checkDay(TUESDAY_KEY, selectedDays) : true,
      [WEDNESDAY_KEY]: selectedDays ? checkDay(WEDNESDAY_KEY, selectedDays) : true,
      [THURSDAY_KEY]: selectedDays ? checkDay(THURSDAY_KEY, selectedDays) : true,
      [FRIDAY_KEY]: selectedDays ? checkDay(FRIDAY_KEY, selectedDays) : true,
      [SATURDAY_KEY]: selectedDays ? checkDay(SATURDAY_KEY, selectedDays) : false,
      [SUNDAY_KEY]: selectedDays ? checkDay(SUNDAY_KEY, selectedDays) : false,
      [TIME_SLOTS_KEY]: scheduleValue,
      [DAY_TIME_SLOTS_KEY]: daysValue,
    },
  });

  const {
    control,
    getValues,
    watch,
    trigger,
    formState,
    reset,
  } = methods;
  const sameTimeWatch = watch(SAME_TIME_KEY, sameValue);

  const {
    fields: timeSlots,
    append: handleAppendTimeSlot,
    remove: handleRemoveTimeSlot,
  } = useFieldArray({
    control,
    name: TIME_SLOTS_KEY,
  });

  // To avoid having 7 field arrays, have a single days keyed field array
  const {
    fields: dayTimeSlots,
  } = useFieldArray({
    control,
    name: DAY_TIME_SLOTS_KEY,
  });

  const addTimeSlot = useCallback(() => {
    handleAppendTimeSlot({
      startTime: '',
      endTime: '',
    });

    // Since we are sending in values, need to update in backend too
    dispatch(addNewSlotAvailabilitySameSchedule({
      fieldName: SAME_SCHEDULE_KEY,
      fieldValue: {
        startTime: '',
        endTime: '',
      },
    }));
  });

  const removeTimeSlot = useCallback((index) => {
    dispatch(deleteAvailabilitySameSchedule({ index }));
    handleRemoveTimeSlot(index);
  });

  const handleTimezoneChange = useCallback((e) => {
    dispatch(updateAvailabilityField({
      fieldName: TIMEZONE_KEY,
      fieldValue: e.target.value,
    }));
  });

  const timeSwitchChanged = useCallback((changedValue) => {
    dispatch(updateAvailabilityField({
      fieldName: SAME_TIME_KEY,
      fieldValue: changedValue,
    }));
  });

  const sameTimeOnChange = useCallback((index, key, value) => {
    const arrayKeyPieces = key.split('.');
    const keyName = arrayKeyPieces[arrayKeyPieces.length - 1];

    dispatch(updateAvailabilitySameSchedule({
      fieldName: SAME_SCHEDULE_KEY,
      fieldValue: {
        index,
        key: keyName,
        value,
      },
    }));
    trigger(TIME_SLOTS_KEY);
  });

  const individualDayDelete = useCallback((dayKey, index) => {
    dispatch(deleteAvailabilityDaysSchedule({
      dayKey: dayKey.toUpperCase(),
      index,
    }));
  });

  const updateDayTimeSlots = useCallback((event, dayKey) => {
    // This is triggered before the form updates, so it is the previous true/false, so flip
    const isSelected = !(event.target.value === 'true');
    dispatch(updateSelectOnAvailabilityDaysSchedule({
      dayKey: dayKey.toUpperCase(),
      isSelected,
    }));
  });

  const individualDayTimeOnChange = useCallback((dayKey, isEnabled, index, key, value) => {
    const arrayKeyPieces = key.split('.');
    const keyName = arrayKeyPieces[arrayKeyPieces.length - 1];

    dispatch(updateAvailabilityDaysSchedule({
      fieldName: DAYS_SCHEDULE_KEY,
      fieldValue: {
        dayKey: dayKey.toUpperCase(),
        index,
        isEnabled,
        key: keyName,
        value,
      },
    }));
    trigger(DAY_TIME_SLOTS_KEY);
  });

  const updateDayOnChange = useCallback((event, key) => {
    // Event has the previous value
    dispatch(updateAvailabilitySameScheduleDays({
      fieldName: key,
      fieldValue: !(event.target.value === 'true'),
    }));
  });

  useEffect(() => {
    // Need a custom hook for this form
    const { dirtyFields, isValid, isDirty } = formState;

    const switchVal = getValues(SAME_TIME_KEY);
    const filterIsDirty = Object.keys(dirtyFields).filter(
      (key) => (switchVal ? key !== DAY_TIME_SLOTS_KEY : key !== TIME_SLOTS_KEY),
    ).length > 0;

    if (isDirty && filterIsDirty && isValid) {
      dispatch(setStateDirty({ DAYS_AND_TIMES_FORM }));
    } else if (isDirty && filterIsDirty && !isValid) {
      dispatch(setStateInvalid({ DAYS_AND_TIMES_FORM }));
    } else {
      dispatch(setStateClean({ DAYS_AND_TIMES_FORM }));
    }
  }, [formState, getValues]);

  useEffect(() => {
    if (hasReset && resetData) {
      const newDefaults = getValues();
      newDefaults[SAME_TIME_KEY] = resetData[AVAILABILITY_SECTION][SAME_TIME_KEY];
      newDefaults[CONVERTED_SAME_SCHEDULE_KEY] = scheduleValue;
      newDefaults[CONVERTED_DAYS_SCHEDULE_KEY] = daysValue;

      const originalDays = resetData[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY][DAYS_SCHEDULE_KEY];
      newDefaults[MONDAY_KEY] = checkDay(MONDAY_KEY, originalDays);
      newDefaults[TUESDAY_KEY] = checkDay(TUESDAY_KEY, originalDays);
      newDefaults[WEDNESDAY_KEY] = checkDay(WEDNESDAY_KEY, originalDays);
      newDefaults[THURSDAY_KEY] = checkDay(THURSDAY_KEY, originalDays);
      newDefaults[FRIDAY_KEY] = checkDay(FRIDAY_KEY, originalDays);
      newDefaults[SATURDAY_KEY] = checkDay(SATURDAY_KEY, originalDays);
      newDefaults[SUNDAY_KEY] = checkDay(SUNDAY_KEY, originalDays);

      reset(newDefaults);
    }
  }, [reset, getValues, resetData, hasReset]);

  return (
    <SectionCard
      id="availability-card"
      title="Availability"
      tooltipText="Pro Tip: Use the +Time Block button to indicate a lunch hour, or any other breaks during the day."
      description="Please set the business hours during which you'd like to accept appointments."
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container gap={3}>
            <Grid container gap={2}>
              <TimezoneWidget
                timezoneKey={TIMEZONE_KEY}
                handleTimezoneChange={handleTimezoneChange}
              />
              <SameTime sameTimeKey={SAME_TIME_KEY} handleSameTimeChange={timeSwitchChanged}/>
            </Grid>
            <HoursWrapper>
              {!sameTimeWatch
                ? (
                  <NonMatchingHours
                    dayTimeSlots={dayTimeSlots}
                    getValues={getValues}
                    individualDayDelete={individualDayDelete}
                    individualDayTimeOnChange={individualDayTimeOnChange}
                    updateDayTimeSlots={updateDayTimeSlots}
                  />
                )
                : (
                  <MatchingHours
                    timeSlots={timeSlots}
                    updateDayOnChange={updateDayOnChange}
                    sameTimeOnChange={sameTimeOnChange}
                    removeTimeSlot={removeTimeSlot}
                    addTimeSlot={addTimeSlot}
                  />
                )}
            </HoursWrapper>
          </Grid>
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
}

export default AvailabilityCard;
