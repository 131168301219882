import { createSlice } from '@reduxjs/toolkit';
import { CONTACT_FORM_SLUG } from '../../../../shared/app-constants';
import { loadedContactFormData } from './contact-form-actions';
import { updateFeatures } from '../../settings/data/widgetSettings-slice';

const initialState = {
  firstActivationDate: '',
  toggle: {
    isActive: false,
    hasError: false,
  },
};

const contactFormSlice = createSlice({
  name: 'contactForm',
  initialState,
  reducers: {
    setAfterWizardComplete: (state, action) => {
      state.toggle.isActive = action.payload.isActive;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadedContactFormData, (state, action) => {
      const {
        active, firstActivationDate,
      } = action.payload;

      state.toggle.isActive = active;
      state.firstActivationDate = firstActivationDate;
    });
    builder.addCase(updateFeatures, (state, action) => {
      if (action.payload.formName === CONTACT_FORM_SLUG) {
        state.toggle.isActive = action.payload.isSelected;
      }
    });
  },
});

export const {
  setAfterWizardComplete,
} = contactFormSlice.actions;
export default contactFormSlice.reducer;
