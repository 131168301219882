import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { convertDateToFormat, convertDateStringToDate } from '../../../../shared/app-utils';
import { SCHEDULER_SLUG } from '../../../../shared/app-constants';
import {
	defaultDaysByDayKey,
	NINE_AM,
	FIVE_PM,
	daysSortLookup
 } from '../../../../shared/availability/availability-constants'
import { loadedBookingData, resetCompleteAction, saveBookingsSuccess } from './bookings-slice';
import { saveWizardStepSuccess } from '../../../configuration/data/configuration-slice';

// Field keys. These keys need to be used both here and in the
// forms themselves to allow for easy save/load
export const BUSINESS_ID_KEY = 'businessId';
export const LOCATION_ID_KEY = 'locationId';
export const BUSINESS_NAME_KEY = 'businessName';
export const ADDRESS_1_KEY = 'businessAddressOne';
export const ADDRESS_2_KEY = 'businessAddressTwo';
export const CITY_KEY = 'city';
export const STATE_KEY = 'stateProvince';
export const ZIP_CODE_KEY = 'postalCode';
export const COUNTRY_KEY = 'country';
export const PHONE_KEY = 'businessPhone';
export const WEBSITE_URL_KEY = 'businessUrl';
export const GMAID_KEY = 'gmaid';
export const SLOGAN_KEY = 'slogan';
export const USER_EMAIL_KEY = 'userEmail';
export const USER_ID_KEY = 'userId';

export const TIMEZONE_KEY = 'timezone';
export const SPECIFIC_DAYS_CLOSED_KEY = 'daysClosed';
export const START_DATE_KEY = 'startDate';
export const END_DATE_KEY = 'endDate';
export const CONVERTED_SPECIFIC_DAYS_CLOSED_KEY = 'convertedDaysClosed';
export const SAME_TIME_KEY = 'sameTime';
export const SAME_SCHEDULE_KEY = 'sameSchedule';
export const DAYS_SCHEDULE_KEY = 'days';
export const CONVERTED_SAME_SCHEDULE_KEY = 'convertedSameSchedule';
export const CONVERTED_DAYS_SCHEDULE_KEY = 'convertedDays';

export const BUSINESS_DETAILS_SECTION = 'business';
export const AVAILABILITY_SECTION = 'availability';
export const SPECIFIC_DAYS_CLOSED_SECTION = 'specificDaysClosed';

const handleScheduleParsing = (
  state,
  timezone,
  sameSchedule,
  days,
  sameTime,
) => {
  state[AVAILABILITY_SECTION][TIMEZONE_KEY] = timezone;
  state[AVAILABILITY_SECTION][SAME_TIME_KEY] = sameTime;

  if (sameSchedule && sameSchedule.schedules) {
    state[AVAILABILITY_SECTION][CONVERTED_SAME_SCHEDULE_KEY] = sameSchedule.schedules;
    state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY] = sameSchedule;
  }

  if (days) {
    let mergedDays = days.reduce((acc, dayObj) => {
      const newObj = { ...dayObj };

      // Title case the name
      newObj.day = dayObj.day.charAt(0).toUpperCase() + dayObj.day.toLowerCase().slice(1);
      newObj.schedules = dayObj.schedules;
      newObj.sortKey = daysSortLookup[dayObj.day];

      acc[newObj.day] = newObj;

      return acc;
    }, defaultDaysByDayKey);
    mergedDays = Object.values(mergedDays).sort((a, b) => a.sortKey - b.sortKey);

    state[AVAILABILITY_SECTION][CONVERTED_DAYS_SCHEDULE_KEY] = mergedDays;
    state[AVAILABILITY_SECTION][DAYS_SCHEDULE_KEY] = days;
  }
};

const generalSettingsSlice = createSlice({
  name: 'general_settings',
  initialState: {
    [BUSINESS_DETAILS_SECTION]: {
      [BUSINESS_ID_KEY]: null,
      [LOCATION_ID_KEY]: null,
      [BUSINESS_NAME_KEY]: null,
      [ADDRESS_1_KEY]: null,
      [ADDRESS_2_KEY]: null,
      [CITY_KEY]: null,
      [STATE_KEY]: null,
      [ZIP_CODE_KEY]: null,
      [COUNTRY_KEY]: null,
      [WEBSITE_URL_KEY]: null,
      [PHONE_KEY]: null,
      [SLOGAN_KEY]: null,
      [GMAID_KEY]: null,
      [USER_EMAIL_KEY]: null,
      [USER_ID_KEY]: null,
    },
    [AVAILABILITY_SECTION]: {
      [TIMEZONE_KEY]: null,
      [SAME_TIME_KEY]: true,
      [SAME_SCHEDULE_KEY]: {},
      [DAYS_SCHEDULE_KEY]: [],
      [CONVERTED_SAME_SCHEDULE_KEY]: [],
      [CONVERTED_DAYS_SCHEDULE_KEY]: [],
    },
    [SPECIFIC_DAYS_CLOSED_SECTION]: {
      [SPECIFIC_DAYS_CLOSED_KEY]: [],
      [CONVERTED_SPECIFIC_DAYS_CLOSED_KEY]: [],
    },
  },
  reducers: {
    updateAvailabilityField: (state, action) => {
      state[AVAILABILITY_SECTION][action.payload.fieldName] = action.payload.fieldValue;
    },
    updateAvailabilitySameScheduleDays: (state, action) => {
      const currentValue = state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY];
      const { fieldValue: onOff, fieldName: dayKey } = action.payload;
      let newValue = [];
      const { days } = currentValue;

      if (onOff) {
        if (Array.isArray(days) && days.length >= 1) {
          newValue = [...days];
          newValue.push(dayKey.toUpperCase());
        } else {
          newValue = [dayKey.toUpperCase()];
        }
      } else if (Array.isArray(days) && days.length >= 1) {
        newValue = days.filter((day) => day !== dayKey.toUpperCase());
      }
      state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY].days = newValue;
    },
    updateAvailabilitySameSchedule: (state, action) => {
      const currentValue = state[AVAILABILITY_SECTION][action.payload.fieldName];
      const saveData = action.payload.fieldValue;
      /**
       * Same schedule has the following format:
       * {
       *    "days": [
       *        "MONDAY",
       *        "TUESDAY"
       *    ],
       *    "schedules": [
       *        {
       *            "startTime": "09:00:00",
       *            "endTime": "18:00:00"
       *        }
       *    ]
       * },
       */
      const { schedules } = currentValue;
      if (Array.isArray(schedules) && schedules.length > 0) {
        const currentField = schedules[saveData.index];
        if (currentField) {
          currentField[saveData.key] = saveData.value;
        } else {
          schedules[saveData.index] = {
            [saveData.key]: saveData.value,
          };
        }
      } else {
        currentValue.schedules = [
          {
            [saveData.key]: saveData.value,
          },
        ];
      }
      state[AVAILABILITY_SECTION][action.payload.fieldName] = currentValue;
    },
    addNewSlotAvailabilitySameSchedule: (state, action) => {
      const currentValue = state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY];
      const { schedules } = currentValue;
      if (schedules.length < 2) {
        schedules.push(action.payload.fieldValue);
      }

      state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY].schedules = schedules;
    },
    deleteAvailabilitySameSchedule: (state, action) => {
      const currentValue = state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY];
      const { schedules } = currentValue;
      const removeIndex = action.payload.index;
      schedules.splice(removeIndex, 1);

      state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY].schedules = schedules;
    },
    updateAvailabilityDaysSchedule: (state, action) => {
      const currentValue = state[AVAILABILITY_SECTION][action.payload.fieldName];
      const saveData = action.payload.fieldValue;
      const dayField = currentValue.find((dayObj) => dayObj.day === saveData.dayKey);
      if (dayField) {
        const currentDayTimeField = dayField.schedules[saveData.index];
        if (currentDayTimeField) {
          currentDayTimeField[saveData.key] = saveData.value;
        } else {
          dayField.schedules.splice(saveData.index, 0, {
            [saveData.key]: saveData.value,
          });
        }
      } else {
        // No existing day data, add first block
        // Build schedule payload
        const schedules = [];
        if (saveData.index === 1) {
          // This indicates they are adding a new timeblock without
          // having edited the defaults. Add 9-5 defaults, then handle user edit.
          schedules.push({
            startTime: NINE_AM.value,
            endTime: FIVE_PM.value,
          });
        }
        // Its possible that they are editing a singular time off the defaults.
        // Add both default values to prevent saving only half a value.
        const newTimePairing = {
          startTime: NINE_AM.value,
          endTime: FIVE_PM.value,
        };

        // If they edit the 2nd value, it will hit the above IF instead of here.
        newTimePairing[saveData.key] = saveData.value;
        schedules.push(newTimePairing);

        currentValue.push({
          day: saveData.dayKey,
          selected: saveData.isEnabled,
          schedules,
        });
      }
      state[AVAILABILITY_SECTION][action.payload.fieldName] = currentValue;
    },
    deleteAvailabilityDaysSchedule: (state, action) => {
      const currentValue = state[AVAILABILITY_SECTION][DAYS_SCHEDULE_KEY];
      const saveData = action.payload;
      const dayField = currentValue.find((dayObj) => dayObj.day === saveData.dayKey);
      if (dayField) {
        const foundTimeBlock = dayField.schedules[saveData.index];
        if (foundTimeBlock) {
          dayField.schedules.splice(saveData.index, 1);
          state[AVAILABILITY_SECTION][DAYS_SCHEDULE_KEY] = currentValue;
        }
      }
    },
    updateSelectOnAvailabilityDaysSchedule: (state, action) => {
      const { dayKey, isSelected } = action.payload;
      const currentValue = state[AVAILABILITY_SECTION][DAYS_SCHEDULE_KEY];

      // Find the day they turned on or off.
      const dayField = currentValue.find((dayObj) => dayObj.day === dayKey);
      if (dayField) {
        dayField.selected = isSelected;
        state[AVAILABILITY_SECTION][DAYS_SCHEDULE_KEY] = currentValue;
      }
    },
    updateBusinessDetailsField: (state, action) => {
      state[BUSINESS_DETAILS_SECTION][action.payload.fieldName] = action.payload.fieldValue;
    },
    updateBusinessDetails: (state, action) => {
      // Merge
      const mergedData = Object.assign(state[BUSINESS_DETAILS_SECTION], action.payload);
      state[BUSINESS_DETAILS_SECTION] = mergedData;
    },
    updateSpecificDaysClosed: (state, action) => {
      const { index, value, type } = action.payload;
      if(type === START_DATE_KEY) {
        state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY][index]
        .startDate = convertDateToFormat(value);
      }

      if(type === END_DATE_KEY) {
        state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY][index]
        .endDate = convertDateToFormat(value);
      }
    },
    removeSpecificDaysClosed: (state, action) => {
      const { index } = action.payload;

      if (index < state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY].length) {
        state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY].splice(index, 1);
      }
    },
    addSpecificDaysClosed: (state) => {
      const currentDate = convertDateToFormat(new Date().toString());

      state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY] =
        state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY].concat({
          startDate: currentDate,
          endDate: currentDate,
        });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetCompleteAction, (state, action) => {
      const { resetData } = action.payload;
      // TODO: if we end up loading a ton of data, can break this into a section + key array based
      // looping assignment util
      state[AVAILABILITY_SECTION][TIMEZONE_KEY] = resetData[AVAILABILITY_SECTION][TIMEZONE_KEY];
    });
    builder.addCase(saveBookingsSuccess, (state, action) => {
      const { availability, daysClosed } = action.payload.data;
      if (availability) {
        const { sameSchedule, days } = availability;

        if (sameSchedule && sameSchedule.schedules) {
          state[AVAILABILITY_SECTION][CONVERTED_SAME_SCHEDULE_KEY] = sameSchedule.schedules;
        }

        if (days) {
          state[AVAILABILITY_SECTION][CONVERTED_DAYS_SCHEDULE_KEY] = days.map((dayObj) => {
            const newObj = { ...dayObj };

            // Title case the name
            newObj.day = dayObj.day.charAt(0).toUpperCase() + dayObj.day.toLowerCase().slice(1);
            newObj.schedules = dayObj.schedules;
            newObj.sortKey = daysSortLookup[dayObj.day];
            return newObj;
          }).sort((a, b) => a.sortKey - b.sortKey);
        }
      }
      if (daysClosed && Array.isArray(daysClosed)) {
        const convertedDays = daysClosed.map(({ startDate, endDate }) => (
          {
            startDate: convertDateStringToDate(startDate),
            endDate: convertDateStringToDate(endDate),
          }
        ));
        state[SPECIFIC_DAYS_CLOSED_SECTION][CONVERTED_SPECIFIC_DAYS_CLOSED_KEY] = convertedDays;
      }
    });
    builder.addCase(loadedBookingData, (state, action) => {
      const { productInfo, productType } = action.payload;
      if (productType && productType.slug === SCHEDULER_SLUG
          && productInfo && productInfo.availability
          && productInfo.daysClosed
          && productInfo.business) {
        const {
          timezone,
          sameSchedule,
          days,
          sameTime,
        } = productInfo.availability;
        state[AVAILABILITY_SECTION][TIMEZONE_KEY] = timezone;
        state[SPECIFIC_DAYS_CLOSED_SECTION][SPECIFIC_DAYS_CLOSED_KEY] = productInfo.daysClosed;
        state[SPECIFIC_DAYS_CLOSED_SECTION][CONVERTED_SPECIFIC_DAYS_CLOSED_KEY] = productInfo.daysClosed
          .map(({ startDate, endDate }) => ({
            startDate: convertDateStringToDate(startDate),
            endDate: convertDateStringToDate(endDate),
          }));
        state[AVAILABILITY_SECTION][SAME_TIME_KEY] = sameTime;

        if (sameSchedule && sameSchedule.schedules) {
          state[AVAILABILITY_SECTION][CONVERTED_SAME_SCHEDULE_KEY] = sameSchedule.schedules;
          state[AVAILABILITY_SECTION][SAME_SCHEDULE_KEY] = sameSchedule;
        }

        if (days) {
          // TODO: remove this object.assign when backend supports prepopulating this
          let mergedDays = days.reduce((acc, dayObj) => {
            const newObj = { ...dayObj };

            // Title case the name
            newObj.day = dayObj.day.charAt(0).toUpperCase() + dayObj.day.toLowerCase().slice(1);
            newObj.schedules = dayObj.schedules;
            newObj.sortKey = daysSortLookup[dayObj.day];

            acc[newObj.day] = newObj;

            return acc;
          }, defaultDaysByDayKey);
          mergedDays = Object.values(mergedDays).sort((a, b) => a.sortKey - b.sortKey);

          state[AVAILABILITY_SECTION][CONVERTED_DAYS_SCHEDULE_KEY] = mergedDays;
          state[AVAILABILITY_SECTION][DAYS_SCHEDULE_KEY] = days;
        }

        const businessData = productInfo.business;

        if (businessData) {
          const mergedData = Object.assign(state[BUSINESS_DETAILS_SECTION], businessData);
          state[BUSINESS_DETAILS_SECTION] = mergedData;
        }
      }
    });
    builder.addCase(saveWizardStepSuccess, (state, action) => {
      // Check and apply any availability changes here
      const { data } = action.payload;
      if (data.steps?.appointmentAvailability) {
        const {
          availabilityTimezone, availabilitySameTime, availabilitySameSchedule, availabilityDays
        } = data.steps.appointmentAvailability;
        handleScheduleParsing(
          state,
          availabilityTimezone,
          availabilitySameSchedule,
          availabilityDays,
          availabilitySameTime,
        );
      }
    });
  },
});

export const {
  updateBusinessDetailsField,
  updateBusinessDetails,
  updateAvailabilityField,
  updateAvailabilitySameSchedule,
  updateAvailabilityDaysSchedule,
  updateSpecificDaysClosed,
  removeSpecificDaysClosed,
  addSpecificDaysClosed,
  updateAvailabilitySameScheduleDays,
  deleteAvailabilitySameSchedule,
  addNewSlotAvailabilitySameSchedule,
  deleteAvailabilityDaysSchedule,
  updateSelectOnAvailabilityDaysSchedule,
  uploadLogo,
  deleteLogo,
} = generalSettingsSlice.actions;
export default generalSettingsSlice.reducer;
