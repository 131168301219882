import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { ReactComponent as PlusIcon } from '../assets/add-appointment-plus-icon.svg';
import NotActivated from '../components/upcomingAppointments/NotActivated';
import Activated from '../components/upcomingAppointments/Activated';
import { loadUpcomingAppointments } from '../data/dashboard-slice';
import LoadingSkeletonUpcomingAppointments from '../components/loadingSkeletons/upcomingAppointments/LoadingSkeletonUpcomingAppointments';
import BookingsCTA from '../../../shared/BookingsCTA';
import { trackDashboardCardClick } from '../../../shared/analytics-utils';
import { modifyUrl, openNewTab } from '../../../shared/app-utils';
import { SCHEDULING_PAGE } from '../../../shared/navigation/navigation-config';

const StyledContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 5),
  height: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 3, 2, 3),
  },
}));

const StyledWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const StyledHead = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const StyledCardName = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '30px',
}));

const StyledMain = styled('div')(({ theme }) => ({
  flexGrow: 1,
  margin: theme.spacing(3, 0, 3, 0),

  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(3, 0, 3, 0),
  },
}));

const StyleViewAllAppointmentsLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  cursor: 'pointer',
}));

const SpinnerWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '400px',
}));

export default function UpcomingAppointmentsCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gmaid = useSelector((state) => state.businessAttributes.gmaid);
  const wizardStatus = useSelector((state) => state.configuration.status);
  const schedulerLink = useSelector((state) => state.bookings.links.main);
  const upcomingAppointments = useSelector((state) => state.dashboard.upcomingAppointments);
  const timezone = useSelector((state) => state.appSettings.currentUserTimezone);
  const linksLoading = useSelector((state) => state.bookings.links.isLoading);
  const appointmentsLoading = useSelector((state) => state.dashboard.upcomingAppointmentsAreLoading);
  const schedulingIsActive = useSelector((state) => state.bookings.toggle.isActive);
  const hasStartedFreeTrial = useSelector((state) => state.configuration.trialInfo?.active);
  const isLoading = linksLoading || appointmentsLoading;
  const isActivated = hasStartedFreeTrial && schedulingIsActive;
  const hasUpcomingAppointments = Object.entries(upcomingAppointments).length > 0;
  const notActivatedWithNoAppointments = !isActivated && !hasUpcomingAppointments;
  const showAddAnAppointmentBtn = schedulingIsActive && hasUpcomingAppointments && !isLoading;

  const onViewAllAppointmentsClick = () => {
    trackDashboardCardClick('Upcoming Appointments','View All Appointments');
    navigate(`/${SCHEDULING_PAGE}`);
  };

  const onAddAnAppointmentClick = () => {
    trackDashboardCardClick('Upcoming Appointments', 'Add Appointment');
    openNewTab(modifyUrl(schedulerLink.url, 'appointments'));
  };

  useEffect(() => {
    if (gmaid) {
      dispatch(
        loadUpcomingAppointments({ gmaid }),
      );
    }
  }, [gmaid, wizardStatus]);

  if (isLoading) {
    return (
      <StyledContainer>
        <LoadingSkeletonUpcomingAppointments />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHead>
          <StyledCardName>Upcoming Appointments</StyledCardName>
          {
            // No point in showing the button twice on the page
            showAddAnAppointmentBtn && (
              <BookingsCTA
                text="Add an Appointment"
                endIcon={<PlusIcon />}
                onClickHandler={onAddAnAppointmentClick}
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  width: isMobile ? '100%' : 'max-content',
                  padding: '11px 20px',
                }}
              />
            )
          }
        </StyledHead>
        <StyledMain>
          {
            isLoading ? (
              <SpinnerWrapper>
                <IQLoadingSpinner />
              </SpinnerWrapper>
            ) : (
              notActivatedWithNoAppointments
                ? <NotActivated />
                : (
                  <Activated
                    timezone={timezone}
                    appointments={upcomingAppointments}
                    onAddAnAppointmentClick={onAddAnAppointmentClick}
                    hasUpcomingAppointments={hasUpcomingAppointments}
                    schedulingIsActive={schedulingIsActive}
                  />
                )
            )
          }
        </StyledMain>
        {
          hasUpcomingAppointments && (
            <StyleViewAllAppointmentsLink
              onClick={onViewAllAppointmentsClick}
            >
              View All Appointments
            </StyleViewAllAppointmentsLink>
          )
        }
      </StyledWrapper>
    </StyledContainer>
  );
}
