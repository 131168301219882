import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SubscriptionCard from './cards/SubscriptionCard';
import TrialsCard from './cards/TrialsCard';
import { TRIAL_PURCHASED_STATUS } from '../account-constants';

const SubscriptionCardListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default function SubscriptionCardList({ subscriptions, trials }) {
  const FREE_TRIAL_SUBSCRIPTION_TITLE = 'Lead Capture Toolkit Free Trial';
  const hasActiveSubscription = trials[0]?.status === TRIAL_PURCHASED_STATUS;
  const hasFreeTrial = (trials !== undefined && trials.length > 0) && !hasActiveSubscription;

  return (
    <SubscriptionCardListWrapper>
      {hasFreeTrial && <TrialsCard trialsInfo={trials} title={FREE_TRIAL_SUBSCRIPTION_TITLE} />}
      {
        subscriptions.map((sub) => (
          <SubscriptionCard key={sub.id} {...sub} />
        ))
      }
    </SubscriptionCardListWrapper>
  );
}

SubscriptionCardList.propTypes = {
  /**
   * List of subscriptions to display
   */
  subscriptions: PropTypes.array.isRequired,

  /**
   * List of trials this user has (could be empty)
   */
  trials: PropTypes.array.isRequired,
};
