import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { PrimaryButton } from './settings-shared-components';
import { ReactComponent as FileCopyIcon } from '../images/copy-icon.svg';
import { ReactComponent as ErrorIcon } from '../images/small-error.svg';
import { trackCaptureCodeCopied } from './analytics-utils';

const LabelText = styled(Typography)(({ theme, error }) => ({
  fontFamily: 'Unify Sans SemiBold',
  fontSize: '18px',
  lineHeight: '24px',
  color: error ? theme.palette.error.main : theme.palette.text.primary,
  letterSpacing: '0.25px',
  paddingBottom: theme.spacing(4),
}));

const ErrorMessage = styled(Typography)(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '9.5px',
  color: !error ? theme.palette.common.black : theme.palette.error.main,
  fontSize: 12,
  paddingTop: '8px',
}));

const CopyInputField = styled('input')(({ theme, error }) => ({
  width: '40%',
  height: 41,
  fontSize: 17,
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.text.primary}`,
  letterSpacing: '0.7px',
  padding: '0 1rem',
  fontFamily: 'inherit',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    boxSizing: 'border-box',
  },
}));

const CopyTextAreaField = styled('textarea')(({ theme, error, height }) => ({
  width: '97%',
  height,
  padding: theme.spacing(1, 2),
  fontSize: 16,
  fontFamily: 'Unify Sans',
  borderColor: error ? theme.palette.error.main : theme.palette.text.primary,
  borderStyle: 'solid',
  borderRadius: 2,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    boxSizing: 'border-box',
    height: 120,
  },
}));

const CopyButton = styled(PrimaryButton)(({ theme }) => ({
  width: 'auto',
  margin: '24px 0',
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Unify Sans Semibold',
  gap: '14px',
  '& svg': {
    fill: theme.palette.common.white,
  },
}));

const CopyIDButton = styled(PrimaryButton)(({ theme }) => ({
  width: '120px',
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Unify Sans Semibold',
  '& svg': {
    fill: theme.palette.common.white,
  },
}));

const CopySuccess = styled(PrimaryButton)(({ theme }) => ({
  width: '220px',
  margin: '24px 0',
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Unify Sans Semibold',
  '& svg': {
    fill: theme.palette.primary.main,
  },
}));

const ScriptWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',
  marginTop: '24px'
}));

const BtnWrapper = styled('div')(({ showBtn }) => ({
  display: showBtn && 'none',
}));

/**
 * A component for an input field with is a copy button.
 * Also has a small Chip display for when copy is clicked.
 * Default to an error case when value not supplied
 */
export default function CopyInput({
  displayText,
  labelText,
  websiteType,
  useTextArea = false,
  height,
  source,
  hasNoCaptureCode
}) {
  const [showChip, setShowChip] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const hasError = !displayText;

  const handleClickIn = (event) => {
    event.target.select();
  };

  const handleCopyClick = () => {
    setShowChip(true);
    navigator.clipboard.writeText(displayText);
    trackCaptureCodeCopied(websiteType, source);
    window.setTimeout(() => {
      setShowChip(false);
    }, 1500);
  };

  useEffect(() => {
    if (displayText) {
      setShowCode(true);
    }
  }, [displayText]);

  return (
    <>
      {labelText && <LabelText>{labelText}</LabelText>}
      {!useTextArea && (
        <ScriptWrapper>
          <CopyInputField type="text" value={displayText} error={hasError} disabled={hasError} readOnly onClick={handleClickIn} />
          {!showChip
            ? (
              <CopyIDButton
                variant="contained"
                disabled={hasError}
                onClick={handleCopyClick}
              >
                Copy ID
                <FileCopyIcon />
              </CopyIDButton>
            )
            : (
              <CopySuccess variant="outlined">
                Code has been copied
                <FileCopyIcon />
              </CopySuccess>
            )}
        </ScriptWrapper>
      )}
      {(useTextArea && showCode) && (
        <CopyTextAreaField
          readOnly
          value={displayText}
          error={hasError}
          disabled={hasError}
          onClick={handleClickIn}
          height={height}
        />
      )}
      {hasNoCaptureCode && (
        <ErrorMessage error={hasNoCaptureCode}>
          Refresh the page to generate Tracking ID.
        </ErrorMessage>
      )}
      {(hasError && showCode) && (
        <ErrorMessage error={hasError}>
          <ErrorIcon />
          Code failed to generate.
        </ErrorMessage>
      )}

      <BtnWrapper showBtn={!useTextArea}>
        {!showChip ? (
          <CopyButton variant="contained" disabled={hasError} onClick={handleCopyClick}>
            Copy LocaliQ Code
            <FileCopyIcon />
          </CopyButton>
        ) : (
          <CopySuccess variant="outlined">
            Code has been copied
            <FileCopyIcon />
          </CopySuccess>
        )}
      </BtnWrapper>
    </>

  );
}

CopyInput.propTypes = {
  displayText: PropTypes.string,
  height: PropTypes.string,
  labelText: PropTypes.node,
  websiteType: PropTypes.string,
  useTextArea: PropTypes.bool,
  source: PropTypes.string,
};
