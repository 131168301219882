import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import { ReactComponent as CircleCheck } from '../assets/complete-wizard-page-circle-check.svg';
import { useMountEffect } from '../../../../shared/mountEffectHook/useMountEffect';
import { useDispatch, useSelector } from 'react-redux';
import { setWizardStatusAction } from '../../data/configuration-slice';
import { WIZARD_STATUS_COMPLETED } from '../../../../shared/app-constants';

const Wrapper = styled(Box)((() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  textAlign: 'center',
  height: '100%',
})));

const CompleteStepTitle = styled(Typography)((({ theme }) => ({
  fontWeight: 600,
  width: '90%',
  fontSize: 32,
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
})));

const CompleteStepSubtitle = styled(Typography)((() => ({
  fontWeight: 400,
  width: '90%',
  fontSize: 18,
  lineHeight: '27px',
  letterSpacing: '0 15px',
})));

export default function SetUpCompleteStep() {
  const dispatch = useDispatch();
  const wizardMetaData = useSelector((state) => state.appSettings.wizardMetaData);

  useMountEffect(() => {
    if (!wizardMetaData || (wizardMetaData && wizardMetaData.status !== WIZARD_STATUS_COMPLETED)) {
      // Only dispatch if not already completed
      dispatch(setWizardStatusAction());
    }
  });

  return (
    <Wrapper>
      <Box>
        <CircleCheck />
      </Box>
      <CompleteStepTitle variant="h3">Nice work! You&#39;re almost there!</CompleteStepTitle>
      <CompleteStepSubtitle variant="body1">You&#39;ve customized your widget! Now let&#39;s get it on your website.</CompleteStepSubtitle>
    </Wrapper>
  );
}
