import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IQFormInput } from '@gannettdigital/shared-react-components';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import { InputGridWrapper, StyledDelete } from '../../../../../shared/settings-shared-components';
import { updateChatbotCustomQuestionField, deleteChatbotCustomQuestions } from '../../data/general-settings-slice';
const QuestionGridWrapper = styled(InputGridWrapper)(() => ({
  marginTop: "1rem",
  alignItems: 'flex-end',
  display: 'flex',
  '& .MuiFormControl-root': {
    width: '95%',
  },
}));

// Component for the custom question input section for chatbot settings
export default function CustomQuestionInput({name, question, index, parentLength, onDeleteCallback, chatBotQuestionLength}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const updateQuestion = (event, order) => {
    const trimmedQuestion = event.target.value.trim();
    dispatch(updateChatbotCustomQuestionField({question: trimmedQuestion, order: order}))
  }

  const onDeleteQuestionClick = useCallback((ind) => {
    onDeleteCallback(ind);
    dispatch(deleteChatbotCustomQuestions({
      deleteIndex: ind,
    }));
  });


  return (
    <QuestionGridWrapper
      item
      xs={12}
      key={question.id}
      sx={{ mb: index === parentLength - 1 ? theme.spacing(1) : 0 }}
    >
      <IQFormInput
        name={name}
        labelText={`Question ${index + 1}`}
        onBlur={(event) => updateQuestion(event, index)}
        maxLength={chatBotQuestionLength}
      />
      <StyledDelete aria-label="delete" onClick={() => onDeleteQuestionClick(index)}>
        <DeleteOutlineIcon />
      </StyledDelete>
    </QuestionGridWrapper>
  );
  
}
