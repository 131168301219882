import { createSlice } from '@reduxjs/toolkit';

const LOCALIQ_SLICE_NAME = 'localiq_code';

const initialState = {
  // This is initializing before wizard-constants, can't 
  // refence the constant here.
  websiteType: 'WORDPRESS',
  emailSent: false,
  dismissModal: false,
}

const localiqCodeSlice = createSlice({
  name: LOCALIQ_SLICE_NAME,
  initialState,
  reducers: {
    setWebsiteType: (state, action) => {
      state.websiteType = action.payload.websiteType;
    },
    dismissToggleModal: (state, action ) => {
      state.dismissModal = action.payload.dismiss;
    },
  }
});

export const {
  setWebsiteType,
  dismissToggleModal
} = localiqCodeSlice.actions;
export default localiqCodeSlice.reducer;