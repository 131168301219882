import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import SettingsLayout from '../../../layouts/SettingsLayout';
import BookingsManagement from './BookingsManagement';
import {
  CONFIG_PATH,
  ENGAGEMENT_WIDGET_SETTINGS_PATH,
} from '../../../shared/app-constants';
import ToggleModal from '../../../shared/modal/ToggleModal';
import SchedulerIcon from '../../../images/scheduler-modal.svg';
import { COMPLETED_STATUS } from '../../configuration/wizard/wizard-constants';

export default function BookingsLandingPage() {
  const isActive = useSelector((state) => state.bookings.toggle.isActive);
  const isToggled = useSelector((state) => state.bookings.toggle.previousToggle);
  const isModalDismissed = useSelector((state) => state.bookings.toggle.dismissModal);
  const wizardStatus = useSelector((state) => state.configuration.status);
  const navigate = useNavigate();

  const handleOpenSettings = () => {
    if (wizardStatus === COMPLETED_STATUS) {
      navigate(ENGAGEMENT_WIDGET_SETTINGS_PATH);
    } else {
      navigate(CONFIG_PATH);
    }
  }
  
  return (
    <SettingsLayout addPadding>
      <Helmet>
        <title>Scheduling | LocaliQ</title>
      </Helmet>
      <BookingsManagement handleOpenSettings={handleOpenSettings} />
      <ToggleModal
        modalIcon={SchedulerIcon}
        titleText="Congratulations! You are now ready to accept appointments."
        nextSteps={['Review your Scheduling link to ensure accuracy.', 'Submit a test appointment.', 'Share your Scheduling link with prospective clients.', 'Install tracking code so the widget can appear on your site.']}
        isOpen={isActive && isToggled === false && !isModalDismissed}
        dismissText="Got It"
      />
    </SettingsLayout>
  );
}
