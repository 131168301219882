import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonText from '../SkeletonText';

const WelcomeCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(6, 14),
  gap: theme.spacing(10),
}));

const WelcomeCardContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const WelcomeCardDescriptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const SkeletonCircular = styled(Skeleton)(({ bgcolor = '#FFFFFF29' }) => ({
  '&.MuiSkeleton-circular': {
    background: bgcolor,
  },
}));

export default function LoadingSkeletonWelcomeCardDesktop() {
  return (
    <WelcomeCardWrapper>
      <SkeletonCircular animation="wave" variant="circular" width={200} height={200} />
      <WelcomeCardContentWrapper>
        <SkeletonText animation="wave" variant="string" width={591} height={40} />
        <WelcomeCardDescriptionWrapper>
          <SkeletonText animation="wave" variant="string" width={920} height={16} />
          <SkeletonText animation="wave" variant="string" width={375} height={16} />
        </WelcomeCardDescriptionWrapper>
        <SkeletonText animation="wave" variant="string" width={156} height={40} />
      </WelcomeCardContentWrapper>
    </WelcomeCardWrapper>
  );
}
