import { Skeleton } from '@mui/material';
import { styled } from '@mui/styles';

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 3, 0, 3),
  gap: theme.spacing(2),
}));

const CardSkeletonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  padding: theme.spacing(1),
  width: '100%',
  boxSizing: 'border-box',
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  alignSelf: 'flex-end',
  display: 'flex',
  gap: theme.spacing(3),
}));

export default function ChangeCreditCardSkeleton() {
  const cards = [...Array(2).keys()];

  return (
    <ContentWrapper>
      <Skeleton variant='rounded' style={{ alignSelf: 'center' }} width='30%' height={30} />
      <Skeleton variant='rounded' width='100%' height={1} />
      <Skeleton variant='rounded' width='60%' height={25} />
      {
        cards.map((index) => (
          <CardSkeletonWrapper key={index}>
            <Skeleton variant='circular' width={18} height={18}  />
            <Skeleton variant='rounded' width={40} height={28}  />
            <Skeleton variant='rounded' width='70%' height={28}  />
            <Skeleton variant='rounded' width='20%' height={28}  />
          </CardSkeletonWrapper>
        ))
      }
      <Skeleton variant='rounded' width='25%' height={18} />
      <ButtonsWrapper>
        <Skeleton style={{ borderRadius: '20px' }} variant='rounded' width={86} height={40} />
        <Skeleton style={{ borderRadius: '20px' }} variant='rounded' width={73} height={40} />
      </ButtonsWrapper>
    </ContentWrapper>
  )
}