import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CardContent, CardHeader, Typography, CardActionArea } from '@mui/material';
import { trackBookingOpen } from '../../../../shared/analytics-utils';
import {
  APPOINTMENT_TYPE_TO_ICON_MAP,
  CANCELLED_APPOINTMENT_BG_COLOR,
  CANCELLED_APPOINTMENT_LABEL,
  RESCHEDULED_APPOINTMENT_BG_COLOR,
  RESCHEDULED_APPOINTMENT_LABEL
} from '../bookings-constants';
import KeyboardArrowRightIcon from "../assets/appointment-right-arrow.svg";
import CalendarTodayIcon from "../assets/appointment-calendar-icon.svg";
import AccessTimeIcon from "../assets/appointment-time-icon.svg"
import { CardBadge } from '../../../../shared/settings-shared-components';

const { REACT_APP_BOOKING_CRM_URL } = process.env;

const AppointmentCardWrapper = styled(CardActionArea)(({ theme, shouldStrikeThrough }) => ({
  position: 'relative',
  border: `2px solid ${theme.palette.action.disabledBackground}`,
  borderLeft: 'none',
  borderRadius: '8px',
  marginTop: '8px',
  background: shouldStrikeThrough ? '#F9F9F9' : '#FFFFFF',
}));

const AppointmentCardHeader = styled(CardHeader)(({ theme, shouldStrikeThrough }) => ({
  textDecoration: shouldStrikeThrough && 'line-through',
  marginLeft: '1rem',
  padding: theme.spacing(2, 0, 1, 2),
  '.MuiTypography-root': {
    fontSize: '16px',
    fontFamily: 'Unify Sans Bold'
  }
}));

const AppointmentCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 1, 2, 2),
  justifyContent: 'space-between',
  ' .MuiSvgIcon-root': {
    paddingRight: theme.spacing(0.5),
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  }
}));

const AppointmentCardDateWrapper = styled.div(({ theme, shouldStrikeThrough }) => ({
  display: 'flex',
  flex: 2,
  borderRight: '1px solid #D7D7D7',
  marginLeft: '1rem',
  textDecoration: shouldStrikeThrough && 'line-through',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    borderRight: 'none',
    borderTop: '1px solid #D7D7D7',
    paddingTop: theme.spacing(1),
  }
}));

const AppointmentCardTimeSpanWrapper = styled('div')(({ theme, shouldStrikeThrough }) => ({
  display: 'flex',
  flex: 1,
  borderRight: '1px solid #D7D7D7',
  textDecoration: shouldStrikeThrough && 'line-through',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'start',
    paddingLeft: 0,
    marginLeft: '1rem',
    borderRight: 'none',
  }
}));

const AppointmentCardAppointmentTypeWrapper = styled('div')(({ theme, shouldStrikeThrough }) => ({
  display: 'flex',
  flex: 2,
  paddingLeft: theme.spacing(5),
  textDecoration: shouldStrikeThrough && 'line-through',

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    marginLeft: '1rem',
  }
}));

const ArrowIconWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5.5),
  right: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    top: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

const UnreadColorStrip = styled('div')(({ theme }) => ({
  height: '100%',
  width: 8,
  background: theme.palette.success.main,
  borderRadius: '5px 0px 0px 5px',
  position: 'absolute',
  top: 0,
  left: 0,
}));

const TopRightBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  top: theme.spacing(3.5),
  right: theme.spacing(8),

  [theme.breakpoints.down('md')]: {
    top: theme.spacing(2.5),
    right: theme.spacing(6),
  }
}));

const AppointmentDate = styled(Typography)(() => ({
  fontSize: 14,
  marginLeft: '.5rem'
}));

const AppointmentTime = styled(Typography)(() => ({
  fontSize: 14,
  marginLeft: '.5rem'
}));

const AppointmentType = styled(Typography)(() => ({
  fontSize: 14,
  marginLeft: '.5rem'
}));

const AppointmentCalendarIcon = styled('img')(({ theme }) => ({
  width: '4%',
  [theme.breakpoints.down('lg')]: {
    width: '8%',
  },
  [theme.breakpoints.down('md')]: {
    width: '4%',
  }
}));

export default function AppointmentCard({ name, date, startTime, endTime, timezone, duration, appointmentName, appointmentType, unread, status, appointmentToken }) {
  const shouldStrikeThrough = status === CANCELLED_APPOINTMENT_LABEL || status === RESCHEDULED_APPOINTMENT_LABEL;
  const topRightBadgeColor = status === CANCELLED_APPOINTMENT_LABEL ? CANCELLED_APPOINTMENT_BG_COLOR : RESCHEDULED_APPOINTMENT_BG_COLOR;
  const timeConverter = useCallback(() => {
    const hours = (duration / 60);
    const rhours = Math.floor(hours);

    if (rhours < 1) {
      return `${duration} min`
    }

    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    if (!rminutes) {
      return `${rhours} h`
    }

    return `${rhours}h ${rminutes} min`
  }, [duration]);

  const trackBookingClick = () => {
    trackBookingOpen(duration, appointmentType);
    window.open(`${REACT_APP_BOOKING_CRM_URL}${appointmentToken}`, '_self')
  };

  return (
    <AppointmentCardWrapper shouldStrikeThrough={shouldStrikeThrough} onClick={trackBookingClick}>
      {unread && (<UnreadColorStrip />)}
      {shouldStrikeThrough && (
        <TopRightBadge>
          <CardBadge color={topRightBadgeColor}>
            <div className="dot"></div>{status}
          </ CardBadge>
        </TopRightBadge>
      )}
      <ArrowIconWrapper>
        <a onClick={trackBookingClick} href={`${REACT_APP_BOOKING_CRM_URL}${appointmentToken}`}><img src={KeyboardArrowRightIcon} alt="right arrow." /> </a>
      </ArrowIconWrapper>
      <AppointmentCardHeader title={name} shouldStrikeThrough={shouldStrikeThrough} />
      <AppointmentCardContent>
        <AppointmentCardDateWrapper shouldStrikeThrough={shouldStrikeThrough}>
          <AppointmentCalendarIcon src={CalendarTodayIcon} alt="calendar icon." />
          <AppointmentDate variant='body1'>
            {
              isMobile ? (
                <>
                  {date}<br />{startTime} - {endTime} {timezone}
                </>
              ) : (
                <>
                  {date}, {startTime} - {endTime} {timezone}
                </>
              )
            }
          </AppointmentDate>
        </AppointmentCardDateWrapper>
        <AppointmentCardTimeSpanWrapper shouldStrikeThrough={shouldStrikeThrough}>
          <img src={AccessTimeIcon} alt="Appointment Duration." />
          <AppointmentTime variant='body1'>{timeConverter()}</AppointmentTime>
        </AppointmentCardTimeSpanWrapper>
        <AppointmentCardAppointmentTypeWrapper shouldStrikeThrough={shouldStrikeThrough}>
          <div>{APPOINTMENT_TYPE_TO_ICON_MAP[appointmentType]?.icon}</div>
          <AppointmentType variant='body1'>{appointmentName}</AppointmentType>
        </AppointmentCardAppointmentTypeWrapper>
      </AppointmentCardContent>
    </AppointmentCardWrapper>
  )
}

AppointmentCard.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  appointmentName: PropTypes.string.isRequired,
  appointmentType: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};
