import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const CircleWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  width: 82,
  height: 82,

  '&:before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    top: '50%',
    left: '50%',
    border: `1px solid ${theme.palette.success.main}`,
    borderRadius: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
  },

  circle: {
    color: theme.palette.success.main,
  },

  '& .styledCircularProgress': {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  '& .iconWrapper': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.info.dark,

    '& svg': {
      height: 'auto',
      width: 40,
      margin: 'auto',
      zIndex: 5,
    },
  },
}));

export const GraderStepsWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(7, 0, 3, 0),
  background: 'transparent',
  maxWidth: 728,
  margin: '0 auto',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 2, 0, 2),
  },

  '& .customStep': {
    '&:not(:last-child)': {
      position: 'relative',
      minHeight: 160,

      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        backgroundColor: '#aeaead',
        width: 3,
        height: 'calc(100% - 96px)',
        left: theme.spacing(5),
        top: 104,

        [theme.breakpoints.down('md')]: {
          top: 96,
        },
      },

      '& .stepLabelWrapper': {
        paddingBottom: theme.spacing(8),
      },
    },
    '& .stepLabelWrapper': {
      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
      },
    },
  },

  '& .stepsTitle': {
    fontSize: 32,
    lineHeight: '1.25',
    fontFamily: 'Unify Sans SemiBold',
    textAlign: 'center',
    color: theme.palette.info.dark,
    marginBottom: theme.spacing(5),
  },

  '& .stepNumber': {
    fontSize: 12,
    fontFamily: 'Unify Sans SemiBold',
    lineHeight: '1.25',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#737373',
  },
  '& .stepTitle': {
    fontSize: 20,
    fontFamily: 'Unify Sans SemiBold',
    lineHeight: '1.25',
    letterSpacing: '0.18px',
    color: theme.palette.info.dark,
    marginBottom: theme.spacing(1),
  },
  '& .stepDescription': {
    lineHeight: '1.5',
    color: theme.palette.info.dark,
  },
}));
