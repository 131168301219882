import { DIGITAL_ADVERTISING, PRESENCE, WEBSITE_QUALITY } from '../../../data/grader-constants';

const {
  REACT_APP_MANAGEMENT_UI_ROOT,
} = process.env;

// Level constants
export const LOW_SCORE = 'foundational';
export const MEDIUM_SCORE = 'intermediate';
export const HIGH_SCORE = 'advanced';

// Alternate levels
export const LOW = 'low';
export const MEDIUM = 'medium';
export const HIGH = 'high';
export const NA = 'not_available';

// Section takeaway constants
export const TAKEAWAYS = {
  [WEBSITE_QUALITY]: {
    [LOW_SCORE]: 'Your website should use more web design best practices to meet visitor expectations. Use the information below to improve your website.',
    [MEDIUM_SCORE]: 'Your website uses some best practices, but not all. Use the information below to identify ways to improve your website to generate more traffic and leads.',
    [HIGH_SCORE]: 'Your website uses all or most best practices for a modern, high-quality website. Address any remaining issues to help generate more traffic and convert it into leads.',
  },
  [PRESENCE]: {
    [LOW_SCORE]: 'There are opportunities to improve your social presence. Use the information below to ensure your customers can connect with you on social media and via your website.',
    [MEDIUM_SCORE]: 'There are opportunities to improve your social presence. Use the information below to ensure your customers can connect with you on social media and via your website.',
    [HIGH_SCORE]: 'Your social presence strong, allowing your customers to navigate between your social profiles and website to find the information they need.',
  },
  [DIGITAL_ADVERTISING]: {
    [LOW_SCORE]: `You're not doing much digital advertising. Use the information below to explore other types of advertising that can help generate revenue for your business.`,
    [MEDIUM_SCORE]: `You're using digital advertising to drive traffic to your website. Use the information below to explore other types of advertising that can help generate revenue for your business.`,
    [HIGH_SCORE]: `You're leveraging multiple digital advertising channels to drive traffic to your website. Make sure you know which channels perform best so you spend your ad budgets wisely.`,
  },
};

// Lookup title and color for score details blocks
export const DETAILS_LEVEL_LOOKUP = {
  [LOW]: {
    bgColor: 'rgba(245, 213, 215, 0.4)',
    title: 'These are your highest priority issues to address.',
  },
  [MEDIUM]: {
    bgColor: 'rgba(255, 235, 195, 0.4)',
    title: 'These pass our tests, but can be optimized further.',
  },
  [HIGH]: {
    bgColor: 'rgba(218, 240, 205, 0.4)',
    title: 'Keep up the great work!',
  },
};

// Order details display order
export const DETAILS_DISPLAY_ORDER = [LOW, MEDIUM, HIGH];

export const SERVICE_TITLE = 'title';
export const SERVICE_DESCRIPTION = 'description';

// Lookup service callout info
export const SERVICE_CALLOUT = {
  [WEBSITE_QUALITY]: {
    [SERVICE_TITLE]: 'LocaliQ Websites',
    [SERVICE_DESCRIPTION]: 'Let our professional designers build you a high quality, mobile-optimized website.',
  },
  [PRESENCE]: {
    [SERVICE_TITLE]: 'LocaliQ Social Media Marketing',
    [SERVICE_DESCRIPTION]: `We'll help manage your social media presence to generate more followers and leads.`,
  },
  [DIGITAL_ADVERTISING]: {
    [SERVICE_TITLE]: 'LOCALIQ DIGITAL ADVERTISING',
    [SERVICE_DESCRIPTION]: 'Our professionals use LocaliQ technology to deliver a high volume of quality leads.',
  },
};

// Section title names to use for each section
export const SECTION_TITLES = {
  [WEBSITE_QUALITY]: 'Website Quality',
  [PRESENCE]: 'Social & Local Presence',
  [DIGITAL_ADVERTISING]: 'Digital Advertising',
};

// Details grouped by sub category
export const SUB_CATEGORIES_CONFIG = {
  [WEBSITE_QUALITY]: [
    'domainAge',
    'ssl',
    'mobile',
    'tablet',
    'alternativeText',
    'amountOfContent',
    'backlinks',
    'brokenLinks',
    'codeQuality',
    'headings',
    'images',
    'pageTitlesAndDescriptions',
    'sitemap',
    'liveChat',
  ],
  [PRESENCE]: [
    'facebook',
    'instagram',
    'twitter',
  ],
  [DIGITAL_ADVERTISING]: [
    'displayAds',
    'paidSearch',
    'retargeting',
  ],
};

export const DETAILS_CONFIG = {
  alternativeText: {
    name: 'Alternative Text',
    explanation: `Alternative text provides a description of an image. It's helpful for users when an image fails to load, and can help improve your search rankings. It's also a legal requirement in some countries.`,
    pass: 'Congratulations, every image we scanned had alternative text implemented.',
    how: 'Be sure that all images have an alternative text element added to them. This information is used by search engines, which can impact rankings, and visually impaired website visitors.',
    recommendation: {
      high: 'Most images have alternative text',
      medium: 'Only some of the images have alternative text',
      low: 'Most images do not have alternative text',
    },
  },
  amountOfContent: {
    name: 'Amount of Content',
    explanation: `The amount of content on a page strongly correlates with a website's rank in search engines. Google penalizes websites with too little content by ranking them lower in search results.`,
    pass: 'Great job, your site has a reasonable amount of text on every page we scanned.',
    how: 'Consider adding more content to your site as pages with a good volume of quality content are likely to rank higher in search engines.',
    recommendation: {
      high: 'Word count is over 100',
      medium: 'n/a',
      low: 'Word count is under 100',
    },
  },
  backlinks: {
    name: 'Backlinks',
    explanation: 'Backlinks are links on other websites that point to your site. Volume and quality of backlinks is an important search engine ranking factor at Google.',
    pass: 'Great job, your site has many backlinks from other websites. ',
    how: 'Most incoming links to a site are beneficial. Try to get your website included in relevant directories, or get involved with relevant forums and drop in a mention to the site every now and then.',
    recommendation: {
      high: 'Backlink count is over 5',
      medium: 'n/a',
      low: 'Backlink count is under 5',
    },
  },
  brokenLinks: {
    name: 'Broken Links',
    explanation: `Broken links can be harmful to a site's user experience and result in lower search engine rankings. Visitors are less likely to trust sites with broken links, which can prevent a purchase or inquiry.`,
    pass: 'Congratulations, we did not find any broken links while scanning the site.',
    how: 'Ensure links on your website point to valid pages or files. Update all broken links, as they can negatively affect your search engine rankings.',
    recommendation: {
      high: 'No broken links were discovered',
      medium: 'n/a',
      low: 'One or more broken links was discovered',
    },
  },
  codeQuality: {
    name: 'Code Quality',
    explanation: 'Using the right code in the right situation ensures the site will render correctly across a wide range of devices. Incorrect code can result in worse search engines rankings and a bad user experience.',
    pass: 'Congratulations, your website is built using modern best practices.',
    how: 'Presentational elements should be avoided in favor of CSS. Also avoid using table elements for layout purposes.',
    recommendation: {
      high: 'Semantic HTML used & no tables detected throughout',
      medium: 'Semantic HTML not used or tables are detected',
      low: 'Tables for layout or presentational HTML used',
    },
  },
  displayAds: {
    name: 'Display Ads',
    explanation: 'We scan the Google Display Network to check for any ads that are pointing to this domain. Small budget campaigns may not be detected. Display ads are great for generating awareness and leads.',
    pass: `Great job, you're already working to build awareness for your business with display ads, which helps drive more leads to your business and improve conversion rates.`,
    how: `Consider running display ads to build awareness. For most business, Display Ads should complement a SEM campaign. If you're not running an SEM campaign, consider starting one before moving to Display.`,
    recommendation: {
      high: 'Business appears to be running display ads',
      medium: 'n/a',
      low: `Business doesn't appear to be using display ads`,
    },
  },
  domainAge: {
    name: 'Domain Age',
    explanation: 'New domains are often considered untrustworthy by search engines like Google. Older websites have more credibility and often rank higher in search results.',
    pass: 'Congratulations, your domain is more than 6 months old. No action needed.',
    how: 'Continue to keep your site on the same domain as this helps with ranking over time.',
    recommendation: {
      high: 'Domain was registered more than 6 months ago',
      medium: 'n/a',
      low: 'Domain was registered less than 6 months ago',
    },
  },
  facebook: {
    name: 'Facebook',
    explanation: 'We scan your website for links to public Facebook pages and record the number of likes. The more likes a Facebook business page has, the more people will see your content.',
    pass: 'Great job, your website links to your Facebook page.',
    how: 'Ensure you have a Facebook page, and your website links to the FB page. Also make sure your page is setup as a business profile and setup as a public page.',
    recommendation: {
      high: 'Linked Facebook page with many page likes',
      medium: 'Linked Facebook page with many page likes',
      low: 'Facebook page is not linked or has very few likes',
    },
  },
  headings: {
    name: 'Headings',
    explanation: 'Headings are HTML that help search engines understand how a website is organized. Well defined headings that are in the correct hierarchy can improve your search engine rankings.',
    pass: 'Congratulations, your website uses well defined headings.',
    how: 'Be sure to use a single H1 heading on every page and make other headings hierarchical, meaning an H2 heading would be below an H1 heading.',
    recommendation: {
      high: 'One H1 tag per page and all headings are in order',
      medium: 'Missing or multiple H1s, or incorrect heading order',
      low: 'Missing or multiple H1s, or incorrect heading order',
    },
  },
  images: {
    name: 'Images',
    explanation: 'All images on a website should be in a web-friendly format. Any images not in the correct format may take a long time to load or fail to display entirely, resulting in a poor user experience.',
    pass: 'Nice work, all images on your website are web friendly.',
    how: 'Make sure that all of the images you use are in a web friendly format, like jpeg, gif or png.  If you need to stretch the image in the browser, use CSS. ',
    recommendation: {
      high: '100% of images tested are web friendly',
      medium: 'Not all images tested are web friendly',
      low: 'Not all images tested are web friendly',
    },
  },
  instagram: {
    name: 'Instagram',
    explanation: 'We detect Instagram accounts that are linked to from your website. Instagram is especially useful for businesses that sell to consumers, as it provides a way to visually engage with your audience.',
    pass: 'Great job, your website links to your Instagram page.',
    how: 'Create an Instagram page and link to it from your website. Instagram accounts are valuable for businesses that provide visually appealing products or services, where they can be showcased.',
    recommendation: {
      high: 'Website linked to Instagram account',
      medium: 'Website not linked to Instagram account',
      low: 'Website not linked to Instagram account',
    },
  },
  mobile: {
    name: 'Mobile',
    explanation: `In some business categories, upwards of 70% of all traffic to websites is from mobile devices. It's critical that your website is user-friendly when viewed on a mobile device.`,
    pass: 'Great job, your website appears to be optimized for mobile phones.',
    how: 'Be sure that the site fits multiple mobile screen widths, has legible text, links are big enough to tap and the viewport is set. The mobile site should have the same content as the desktop version.',
    recommendation: {
      high: 'The website is mobile friendly',
      medium: 'The website is not mobile friendly',
      low: 'The website is not mobile friendly',
    },
  },
  pageTitlesAndDescriptions: {
    name: 'Titles and Descriptions',
    explanation: 'Titles and descriptions are used to inform the user about the content on each page. Unique titles and descriptions also lead to improved search engine rankings.',
    pass: 'Congratulations, every page we scanned had a title and description using best practices.',
    how: 'Make sure that each page title is less than 60 characters and every meta description is between 120 and 160 characters. Titles and descriptions should also be unique for each page.',
    recommendation: {
      high: 'No missing or duplicated titles or descriptions',
      medium: 'Some titles and/or descriptions are missing',
      low: 'Titles and descriptions missing or duplicated',
    },
  },
  paidSearch: {
    name: 'Paid Search',
    explanation: 'We determine if the business is or has recently run Google search ads, based on a combination of sources. Small budgets may not be detected. Search ads are great for driving high quality leads.',
    pass: `Great job, you're driving high intent traffic to your website. Try our advanced Google Grader. We'll grade your campaigns and show you opportunities to save money and improve performance.`,
    how: 'Learn more about our award winning SEM campaigns, and start driving high-intent traffic to your website today.',
    recommendation: {
      high: 'Website used Google Ads in the past 12 months',
      medium: 'Website not used with Google Ads recently',
      low: 'Website not used with Google Ads recently',
    },
  },
  retargeting: {
    name: 'Retargeting',
    explanation: `Retargeting lets you serve ads to users that recently visited the website. We scan for Google and Facebook retargeting. Retargeting is a great way to reach visitors that didn't previously convert.`,
    pass: `Great job, you're already using a form of retargeting.`,
    how: 'Consider running retargeting ads to bring customers back to your website. Retargeting also helps to find new customers similar to your website visitors.',
    recommendation: {
      high: 'A retargeting code was found on the site',
      medium: 'n/a',
      low: 'No retargeting code was found on the website',
    },
  },
  sitemap: {
    name: 'Sitemap',
    explanation: 'A sitemap tell users and search engines how the site is structured and lists all of the pages on the site. It can help get a website indexed by search engines, especially if there are hidden pages. ',
    pass: 'Great job, your sitemap is valid and up-to-date.',
    how: 'Have a valid and up do date sitemap available on your site. The sitemap should be in the root folder of your website and follow the Sitemap protocol.',
    recommendation: {
      high: 'Sitemap found with no issues',
      medium: 'Sitemap found but it has issues',
      low: 'Sitemap is missing or has lots of issues',
    },
  },
  ssl: {
    name: 'SSL',
    explanation: 'SSL is a security protocol that provides enhanced security for website visitors. Visitors are more likely to trust websites with SSL, and search engines provide a ranking boost to websites with SSL.',
    pass: `Great job, you've take steps to ensure a higher level of security for your website visitors.`,
    how: 'You should consider enabling SSL to add more security to your website. ',
    recommendation: {
      high: 'The website uses a valid SSL certificate',
      medium: 'The SSL certificate is not valid or is expired',
      low: 'The website does not use a valid SSL certificate',
    },
  },
  tablet: {
    name: 'Tablet',
    explanation: `In some business categories, upwards of 70% of all traffic to websites is from mobile devices. It's critical that your website is user-friendly when viewed on a mobile device.`,
    pass: 'Great job, your website appears to be optimized for tablets.',
    how: 'Be sure that the site fits multiple mobile screen widths, has legible text, links are big enough to tap and the viewport is set. The mobile site should have the same content as the desktop version.',
    recommendation: {
      high: 'The website is mobile friendly',
      medium: 'The website is not mobile friendly',
      low: 'The website is not mobile friendly',
    },
  },
  twitter: {
    name: 'Twitter',
    explanation: 'We detect Twitter accounts that are linked to from your website. For each account we find, we record the number of tweets and followers. More followers means more visibility for your content.',
    pass: 'Great job, your website links to your Twitter page.',
    how: 'Create a Twitter page and link to it from your website. The website address should also be listed in the information section of the  Twitter account',
    recommendation: {
      high: 'Linked Twitter account with many page likes',
      medium: 'Linked Twitter account with some page likes',
      low: 'Twitter account not linked or low number of likes',
    },
  },
  liveChat: {
    name: 'Live Chat',
    explanation: 'A live chat widget can provide immediate help to site visitors or potential customers. It can automatically (and at any time) answer visitor questions or provide information about your hours & services.',
    pass: 'Nice work, a live chat widget was detected on your site. No further action needed.',
    how: 'Find a live chat widget solution that works for you. There are many options available, including the ',
    link: {
      linkText: 'LocaliQ Chatbot.',
      linkURL: `${REACT_APP_MANAGEMENT_UI_ROOT}settings/tools/chatbot`,
      linkInternal: true,
    },
    recommendation: {
      high: 'The website has a live chat widget',
      medium: 'Website not used with Google Ads recently',
      low: 'The website is missing a live chat widget',
    },
  },
};
