import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../images/close-x-icon.svg';
import { PrimaryButton } from '../../settings-shared-components';
import CancelReasonForm from './CancelReasonForm';
import cancellationModalDesktopBG from './assets/cancellation-modal-desktop-bg-icon.svg';
import cancellationModalMobileBG from './assets/cancellation-modal-mobile-bg-icon.svg';
import { trackInProductCancellationSalvaged, trackInProductCancellationSurveySubmitted } from '../../analytics-utils';
import {
  triggerCancelSubscriptionAction,
  setReasonToCancel,
  setOtherReasonToCancel,
  toggleCancellationSalvageOfferModal,
  setSkipReasons,
} from '../../../pages/products/account/data/subscriptions-slice';
import { CANCELLATION_FEEDBACK_CARD_TYPE } from './cancel-subscription-constants';

const StyledModalWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  border: `1px solid ${theme.palette.common.black}`,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '1070px',
  height: 'auto',
  maxHeight: '95%',
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    position: 'relative',
    flexDirection: 'column',
    maxWidth: '95%',
    maxHeight: '95%',
    top: '21px',
    transform: 'translate(-50%)',
  },
}));

const StyledSidePanelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'black',
  backgroundImage: `url(${cancellationModalDesktopBG})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100%',
  backgroundSize: 'cover',
  height: 'auto',
  width: '100%',
  maxWidth: '370px',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  color: theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${cancellationModalMobileBG})`,
    padding: theme.spacing(4, 0),
    maxWidth: 'unset',
  },
}));

const StyledSidePanelTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '40px',
  color: theme.palette.common.white,
  width: '100%',
  maxWidth: '320px',
  textAlign: 'center',
}));

const StyledSidePanelDescription = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.common.white,
  width: '100%',
  maxWidth: '320px',
  textAlign: 'center',
}));

const StyledMainContentWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  padding: theme.spacing(5, 10),
  height: 'auto',
  width: '100%',
  minWidth: '550px',
  color: theme.palette.common.black,
  overflowY: 'auto',

  [theme.breakpoints.down('md')]: {
    minWidth: 'unset',
    padding: theme.spacing(5),
    width: 'auto',
    position: 'unset',
  },
}));

const StyledMainContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '550px',
  gap: theme.spacing(3),
}));

const StyledFeedbackRequestText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
}));

const StyledButtonsWrapper = styled('footer')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 0, 0, 0),
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const StyledCancelAndSkipBtnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0, 0, 3, 0),
  },
}));

const StyledNeverMindLink = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  textDecoration: 'underline',
  lineHeight: '18px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const StyledCloseCancellationModalButton = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    fill: theme.palette.common.white,
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function CancelSubscriptionModal({ isOpen, toggleModal }) {
  const dispatch = useDispatch();
  const { reasonToCancel, otherReasonToCancel, isEligibleForDiscount } = useSelector((state) => (
    state.account.subscriptions.cancelSubscriptionModal
  ));

  const handleSelectFeedbackOption = (e) => {
    const reasonToCancelValue = e.target.value;
    dispatch(setReasonToCancel({ reason: reasonToCancelValue }));
  };

  const handleTextAreaChange = (e) => {
    const reasonToCancelValue = e.target.value;
    dispatch(setOtherReasonToCancel({ otherReason: reasonToCancelValue }));
  };

  const onSkipClick = () => {
    dispatch(setSkipReasons());
    if (isEligibleForDiscount) {
      dispatch(toggleCancellationSalvageOfferModal({ isOpen: true }));
    } else {
      dispatch(triggerCancelSubscriptionAction({ cardType: CANCELLATION_FEEDBACK_CARD_TYPE }));
    }
  };
  
  const onCancelSubscriptionClick = () => {
    trackInProductCancellationSurveySubmitted(otherReasonToCancel !== '' ? otherReasonToCancel : reasonToCancel);
    if (isEligibleForDiscount) {
      dispatch(toggleCancellationSalvageOfferModal({ isOpen: true }));
    } else {
      dispatch(triggerCancelSubscriptionAction({ cardType: CANCELLATION_FEEDBACK_CARD_TYPE }));
    }
  };

  const onNevermindClick = () => {
    trackInProductCancellationSalvaged(CANCELLATION_FEEDBACK_CARD_TYPE);
    toggleModal();
  }

  return (
    <Modal open={isOpen} onClose={toggleModal} disableAutoFocus>
      <StyledModalWrapper>
        <StyledSidePanelWrapper>
          <StyledSidePanelTitle>We are sorry to see you go.</StyledSidePanelTitle>
          <StyledSidePanelDescription>
            With 20 years of helping small businesses adapt to the
            world of digital marketing, LocaliQ knows how to drive
            leads and turn them into customers.
          </StyledSidePanelDescription>
        </StyledSidePanelWrapper>
        <StyledMainContentWrapper>
          <StyledCloseCancellationModalButton onClick={toggleModal} />
          <StyledMainContent>
            <StyledFeedbackRequestText>
              Before you leave, could you please share your reason with us?
              Your feedback will help us improve the LocaliQ experience.
              Please choose one option below.
            </StyledFeedbackRequestText>
            <CancelReasonForm
              selectFeedbackOption={handleSelectFeedbackOption}
              handleTextAreaChange={handleTextAreaChange}
            />
            <StyledButtonsWrapper>
              <StyledCancelAndSkipBtnWrapper>
                <PrimaryButton
                  onClick={onCancelSubscriptionClick}
                >
                  Cancel Subscription
                </PrimaryButton>
                <PrimaryButton onClick={onSkipClick} variant="outlined">Skip</PrimaryButton>
              </StyledCancelAndSkipBtnWrapper>
              <StyledNeverMindLink onClick={onNevermindClick}>
                Nevermind, I don&apos;t want to cancel
              </StyledNeverMindLink>
            </StyledButtonsWrapper>
          </StyledMainContent>
        </StyledMainContentWrapper>
      </StyledModalWrapper>
    </Modal>

  );
}

CancelSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
