import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import ScoreBar from './components/ScoreBar';
import ScoreBillboard from './components/ScoreBillboard';
import CompetitorScore from './components/CompetitorScore/CompetitorScore';

const ScoreSection = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: 812, // Match width of grader scale
  paddingTop: theme.spacing(5),
  margin: '0 auto',

  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 72px)',
    margin: 0,
  },
}));

/**
 * Score component. Displays grader score and competitor analysis
 */
export default function ScoreWidget({ score }) {
  return (
    <ScoreSection>
      <ScoreBillboard score={score} />
      <ScoreBar score={score} />
      {score && <CompetitorScore />}
    </ScoreSection>
  );
}

ScoreWidget.propTypes = {
  score: PropTypes.number,
};
