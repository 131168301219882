import * as yup from 'yup';
import { IQFormPhoneInput, IQSwitch, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ErrorMessage, PhoneRow, SwitchWrapperOverride } from '../../../../shared/settings-shared-components';
import { ReactComponent as PhoneIcon } from '../assets/business-phone.svg';
import { isValidUSPhoneNumberLength, PHONE_NUMBER_REGEX } from '../../../../shared/validation-utils';
import { saveBusinessPhoneNumber, toggleCallTracker } from '../../data/business-feature.slice';
import WizardFeatureTitle from '../components/WizardFeatureTitle';
import ClickToCallIcon from '../../../../images/call-feature.svg';
import WizardStateChangedHook from '../../../../shared/stateChangedHook/WizardStateChangedHook';
import { BUSINESS_PHONE_STEP_FORM } from '../wizard-constants';
import { StepWrapper } from '../components/wizard-shared-components';
import { CALL_RECORDER } from '../../data/slice-constants';
import { saveWizardStepError } from '../../data/configuration-slice';
import { isEmptyObject } from '../../../graders/website/grader-utils';

const PHONE_NUMBER_KEY = "phoneBusinessNumber"
const TOGGLE_TRACKING = "toggleTracking"

const PhoneWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  height: '100%',
}));


const StyledSwitchWrapper = styled(SwitchWrapperOverride)(({ theme }) => ({
  display: 'flex',
  gap: '10rem',
  alignItems: 'center',
  '& .MuiFormControlLabel-root': {
    margin: 0,
    padding: 0,
    backgroundColor: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 0,
  },
}));

const PhoneNumberTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: '24px',
  marginBottom: '1rem',
}));

const PhoneNumberDesc = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '1.25rem',
  margin: '8px 0 24px 0',
  maxWidth: '700px',
}));

const PhoneNumberRow = styled(PhoneRow, {shouldForwardProp: (prop) => prop !== 'saveErr',
})(({ theme, saveErr }) => ({
  '& .MuiFormControl-root': {
    '& > div': {
      borderColor: saveErr ? theme.palette.error.main : theme.palette.common.black
    },
    '& > div input': {
      color: theme.palette.common.black,
      height: 16,
      width: '100%',
      marginLeft: '.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));

export default function BusinessPhoneStep() {
  const dispatch = useDispatch();
  const businessPhoneNumber = useSelector(
    (state) => state.configuration.businessPhoneNumber.businessPhoneNumber,
  );
  const forwardingNumber = useSelector(
    (state) => state.callSettings.generalSettings.currentForwardingNumber,
  );
  const callTracking = useSelector(state => state.configuration.businessPhoneNumber.callRecording);
  const saveError = useSelector((state) => state.configuration.saveError);

  const schema = yup.object().shape({
    [PHONE_NUMBER_KEY]: yup.string().required('Phone Number is required.').test('len', 'Please enter a valid US phone number. If outside the US, please navigate back and turn \'off\' Click-to-Call.', (val) => {
      if (!val) {
        return false;
      }
      return isValidUSPhoneNumberLength(val);
    }).matches(PHONE_NUMBER_REGEX, 'Phone number is invalid'),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      [PHONE_NUMBER_KEY]: businessPhoneNumber,
      [TOGGLE_TRACKING]: callTracking,
    },
  });

  const { setValue, formState: { errors } } = methods;
  const hasYupErrors = isEmptyObject(errors)

  const updatePhoneNumber = (e) => {
    // checking null b/c IQFormPhoneInput expects a string but ends up 
    // being null if user deletes their input
    if (e === null) {
      setValue(PHONE_NUMBER_KEY, "");
    } else if (e.length === 12) {
      dispatch(saveBusinessPhoneNumber(e));
    }
    
    dispatch(saveWizardStepError({ saveError: false }))
  };

  const toggleCallRecorder = (e) => {
    dispatch(toggleCallTracker(e))
  };

  return (
    <StepWrapper>
      <PhoneWrapper>
        <div>
          <WizardFeatureTitle
            icon={ClickToCallIcon}
            text="Click-To-Call"
          />
          <PhoneNumberTitle> Business Phone Number </PhoneNumberTitle>
          <PhoneNumberDesc>
            Enter the business number where calls from the widget will be received. Click-to-Call is currently only compatible with United States phone numbers.
          </PhoneNumberDesc>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <PhoneNumberRow sm={6} xs={12} md={7} saveErr={saveError}>
                <IQFormPhoneInput
                  name={PHONE_NUMBER_KEY}
                  useSchemaError
                  id="phone-Number"
                  onChange={updatePhoneNumber}
                  phoneIcon={<PhoneIcon />}
                  labelText={'Phone Number (USA Numbers Only)'}
                  placeholder="+555555555555"
                  disabled={!!forwardingNumber}
                />
                {(saveError && hasYupErrors) && <ErrorMessage> Phone Number is required. </ErrorMessage>}
              </PhoneNumberRow>
              <StyledSwitchWrapper>
                <IQSwitch
                  id="call-recorder-toggle"
                  name={TOGGLE_TRACKING}
                  value={CALL_RECORDER}
                  onChange={toggleCallRecorder}
                  label="Track and record calls from widget"
                  tooltipText='Calls to your forwarding number will be directed to your business number and recorded.'
                />
              </StyledSwitchWrapper>
              <WizardStateChangedHook formName={BUSINESS_PHONE_STEP_FORM} invalidOnEmpty />
            </FormProvider>
          </ValidationProvider>
        </div>
      </PhoneWrapper>
    </StepWrapper>
  );
}
