import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const NeedHelpWrapper = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  }
}));

const NeedHelpTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  textTransform: 'uppercase',
  letterSpacing: theme.spacing(0.1),
  paddingBottom: theme.spacing(1),
}));

const NeedHelpContent = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(30),
  lineHeight: '24px',
}));

export default function NeedHelp({ title, content }) {
  return (
    <NeedHelpWrapper>
      <NeedHelpTitle>{title}</NeedHelpTitle>
      <NeedHelpContent>{content}</NeedHelpContent>
    </NeedHelpWrapper>
  )
}

NeedHelp.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
}