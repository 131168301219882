import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import StarIcon from '@mui/icons-material/Star';
import BuildIcon from '@mui/icons-material/Build';
import { DetailWrapper } from './styles/grader-section-styles';
import { DETAILS_CONFIG, HIGH, LOW, MEDIUM } from './data/grader-section-constants';
import { trackGraderRecOpen } from '../../../../../shared/analytics-utils';
import { IS_INTERNAL_LINK_KEY, ORIGIN_LINK_KEY } from '../../../../products/account/account-constants';
import { GRADER_PATH } from '../../../../../shared/app-constants';

const determineIconColor = (theme, level) => {
  switch (level) {
    case LOW:
      return theme.palette.error.main;
    case MEDIUM:
      return theme.palette.warning.main;
    default:
      return '#7dbe50';
  }
};

const buildHow = (details) => {
  if (details.link && Object.keys(details.link).length > 0) {
    const { linkURL, linkText, linkInteral } = details.link;
    const constructedLink = `${linkURL}?${IS_INTERNAL_LINK_KEY}=${linkInteral}&${ORIGIN_LINK_KEY}=${GRADER_PATH}`;
    return (
      <span>
        {details.how}
        <a href={constructedLink}>{linkText}</a>
      </span>
    );
  }
  return details.how;
};

/**
 * An individual expandable accordion section
 */
export default function DetailAccordion({ detail, level }) {
  const [expanded, setExpanded] = useState(false);
  const allDetails = DETAILS_CONFIG[detail.key];
  const theme = useTheme();
  const color = determineIconColor(theme, level);

  const handleChange = () => {
    if (!expanded) {
      trackGraderRecOpen(level, allDetails.name, allDetails.recommendation[level]);
    }
    setExpanded(!expanded);
  };

  if (!allDetails) {
    return <div />;
  }

  let how = null;
  let howTitle = null;
  if (level !== HIGH) {
    how = buildHow(allDetails);
    howTitle = level === LOW ? 'How to Fix This' : 'How to Improve This';
  } else {
    how = allDetails.pass;
    howTitle = 'Keep it up';
  }

  return (
    <DetailWrapper bgColor={color} key={detail.key}>
      <Accordion expanded={expanded} onChange={handleChange} className="detailAccordion">
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          id={`detail-${detail.id}`}
        >
          <Box>
            <Typography className="detailTitle">{allDetails.name}</Typography>
            <Typography className="detailRecommend">{allDetails.recommendation[level]}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="detailsGrid">
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <Typography className="detailGridTitle">
                  <StarIcon />
                  Why This Matters
                </Typography>
                <Typography className="detailGridExplain">{allDetails.explanation}</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className="detailGridTitle">
                  <BuildIcon />
                  {howTitle}
                </Typography>
                <Typography className="detailGridExplain">{how}</Typography>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </DetailWrapper>
  );
}

DetailAccordion.propTypes = {
  detail: PropTypes.object,
  level: PropTypes.string,
};
