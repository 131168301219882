import { createSlice } from "@reduxjs/toolkit";
import { loadWizardConfigSuccess } from "./configuration-slice";
import { BUSINESS_PHONE_NUMBER_KEY, CALL_RECORDER, STEP_STATUS } from "./slice-constants";
import { BUSINESS_PHONE_KEY } from "../wizard/wizard-constants";
import { loadedCallSettingsData } from "../../products/callSettings/data/call-settings-actions";

const businessFeatureSlice = createSlice({
  name: 'business_features',
  initialState: {
    [BUSINESS_PHONE_NUMBER_KEY]: '',
    [CALL_RECORDER]: true,
    [STEP_STATUS]: '',
  },
  reducers: {
    saveBusinessPhoneNumber: (state, action) => {
      state[BUSINESS_PHONE_NUMBER_KEY] = action.payload
    },
    toggleCallTracker: (state, action) => {
      state[CALL_RECORDER] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWizardConfigSuccess, (state, action) => {
      if (action.payload.length > 0) {
        const { data } = action.payload[0];
        if (data[BUSINESS_PHONE_KEY]) {
          state[BUSINESS_PHONE_NUMBER_KEY] = data[BUSINESS_PHONE_KEY][BUSINESS_PHONE_NUMBER_KEY];
        }
      }
    });
    builder.addCase(loadedCallSettingsData, (state, action) => {
      state[BUSINESS_PHONE_NUMBER_KEY] = action.payload?.productInfo?.businessPhoneNumber;
    });
  },
});

export const {
  saveBusinessPhoneNumber,
  toggleCallTracker
} = businessFeatureSlice.actions;
export default businessFeatureSlice.reducer;
