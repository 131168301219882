import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { resetCompleteAction } from './chatbot-slice';
import {
  CHATBOT_NAME_SECTION,
  CHATBOT_NAME_KEY,
  CHATBOT_BOT_TONE_KEY,
  CHATBOT_VISITOR_TEXT_KEY,
  CHATBOT_VISITOR_CALL_KEY,
  CHATBOT_VISITOR_EMAIL_KEY,
  CHATBOT_VISITOR_SECTION,
  CHATBOT_TONE_SECTION,
  CHATBOT_CUSTOM_QUESTION_SECTION,
  CHATBOT_NEED_CUSTOM_QUESTION_KEY,
  CHATBOT_CUSTOM_QUESTION_KEY,
  CHATBOT_CONNECT_SCHEDULING_KEY,
  CHATBOT_CONNECT_SCHEDULING_SECTION,
} from '../chatbot-constants';
import { CHATBOT_SLUG, FREE_TIER, PRO_TIER } from '../../../../shared/app-constants';
import { loadedChatbotSettingsData } from './chatbot-actions';

const generalSettingsSlice = createSlice({
  name: 'general_settings',
  initialState: {
    [CHATBOT_NAME_SECTION]: {
      [CHATBOT_NAME_KEY]: null,
    },
    [CHATBOT_TONE_SECTION]: {
      [CHATBOT_BOT_TONE_KEY]: null
    },
    [CHATBOT_VISITOR_SECTION]: {
      [CHATBOT_VISITOR_CALL_KEY]: false,
      [CHATBOT_VISITOR_EMAIL_KEY]: false,
      [CHATBOT_VISITOR_TEXT_KEY]: false,
    },
    [CHATBOT_CUSTOM_QUESTION_SECTION]: {
      [CHATBOT_NEED_CUSTOM_QUESTION_KEY]: false,
      [CHATBOT_CUSTOM_QUESTION_KEY]: [],
    },
    [CHATBOT_CONNECT_SCHEDULING_SECTION]: {
      [CHATBOT_CONNECT_SCHEDULING_KEY]: false,
    }
  },
  reducers: {
    updateChatVisitorAction: (state, action) => {
      state[CHATBOT_VISITOR_SECTION][action.payload.fieldName] = action.payload.fieldValue;
    },
    updateChatToneField: (state, action) => {
      state[CHATBOT_TONE_SECTION][action.payload.fieldName] = action.payload.fieldValue;
    },
    updateChatbotNameField: (state, action) => {
      const { name } = action.payload;
      state[CHATBOT_NAME_SECTION][CHATBOT_NAME_KEY] = name;
    },
    updateChatbotCustomQuestionField: (state, action) => {
      const { question, order } = action.payload;
      const questionObj = { question: question, sequence: order }
      if(!state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY][order]) {
        state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY].push(questionObj)
      } else state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY][order].question = question;
    },
    updateChatbotSchedulingLinkField: (state, action) => {
      state[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY] = action.payload;
    },
    deleteChatbotCustomQuestions: (state, action) => {
      const { deleteIndex } = action.payload;
      const { customQuestions } = state[CHATBOT_CUSTOM_QUESTION_SECTION]
      customQuestions.splice(deleteIndex, 1);
      customQuestions.map((question, index) => {
        const adjustQuestionOrder = { ...question };
        adjustQuestionOrder.order = index;
        return adjustQuestionOrder;
      });

      state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY] = customQuestions;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetCompleteAction, (state, action) => {
      const { resetData } = action.payload;
      
      state[CHATBOT_NAME_SECTION][CHATBOT_NAME_KEY] = resetData[CHATBOT_NAME_KEY];
      state[CHATBOT_TONE_SECTION][CHATBOT_BOT_TONE_KEY] = resetData[CHATBOT_BOT_TONE_KEY];
      state[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_CALL_KEY] = resetData[CHATBOT_VISITOR_CALL_KEY];
      state[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_EMAIL_KEY] = resetData[CHATBOT_VISITOR_EMAIL_KEY];
      state[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_TEXT_KEY] = resetData[CHATBOT_VISITOR_TEXT_KEY];
      state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY] = resetData[CHATBOT_CUSTOM_QUESTION_KEY];
      if (resetData[CHATBOT_CONNECT_SCHEDULING_SECTION] === null) {
        state[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY] = false;
      } else {
        state[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY] = resetData[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY];
      }
    });
    builder.addCase(loadedChatbotSettingsData, (state, action) => {
      const { productInfo, productType } = action.payload;

      if (productInfo && productType?.slug === CHATBOT_SLUG) {
        state[CHATBOT_NAME_SECTION][CHATBOT_NAME_KEY] = productInfo[CHATBOT_NAME_KEY];
        state[CHATBOT_TONE_SECTION][CHATBOT_BOT_TONE_KEY] = productInfo[CHATBOT_BOT_TONE_KEY];
        state[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_CALL_KEY] = productInfo[CHATBOT_VISITOR_CALL_KEY];
        state[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_EMAIL_KEY] = productInfo[CHATBOT_VISITOR_EMAIL_KEY];
        state[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_TEXT_KEY] = productInfo[CHATBOT_VISITOR_TEXT_KEY];
        state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY] = productInfo[CHATBOT_CUSTOM_QUESTION_KEY];
        if (productInfo[CHATBOT_CONNECT_SCHEDULING_SECTION]) {
          state[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY] = productInfo[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY];
        }

        if (productInfo[CHATBOT_CUSTOM_QUESTION_KEY] === null) {
          state[CHATBOT_CUSTOM_QUESTION_SECTION][CHATBOT_CUSTOM_QUESTION_KEY] = [];
        }
      }
    });
  },
});

export const {
  updateChatToneField,
  updateChatVisitorAction,
  updateChatbotNameField,
  updateChatbotCustomQuestionField,
  updateChatbotSchedulingLinkField,
  deleteChatbotCustomQuestions,
} = generalSettingsSlice.actions;
export default generalSettingsSlice.reducer;
