import {
  CURRENT_FORWARDING_NUMBER,
  CURRENT_FORWARDING_TYPE,
  CURRENT_FORWARDING_UUID,
  GENERATE_NUMBERS_FAILED,
  IS_GENERATING_NUMBERS,
  NEW_BASE_NUMBER_KEY,
  SELECTABLE_FORWARDING_NUMBERS,
} from './call-settings-constants';
import { BUSINESS_ATTRIBUTES_SECTION } from '../../../shared/app-constants';

// Save configuration
export const SAVE_CONFIGURATION = {
  generalSettings: [
    {
      storeKey: null,
      saveKey: '',
      skipKeys: [
        CURRENT_FORWARDING_NUMBER, SELECTABLE_FORWARDING_NUMBERS, CURRENT_FORWARDING_UUID,
        CURRENT_FORWARDING_TYPE, NEW_BASE_NUMBER_KEY, GENERATE_NUMBERS_FAILED, IS_GENERATING_NUMBERS],
    },
  ],
  businessAttributes: [
    {
      storeKey: BUSINESS_ATTRIBUTES_SECTION,
      saveKey: BUSINESS_ATTRIBUTES_SECTION,
    },
  ],
};
