import styled from '@emotion/styled';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Box, Fade, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryButton } from '../../../../../shared/settings-shared-components';
import { clearCompetitorGrade, requestCompetitorScore } from '../../data/grader-slice';
import CompetitorResult from './CompetitorResult';
import CompetitorLoading from './CompetitorLoading';
import { FADE_TIMING } from '../../data/grader-constants';

const CompetitorBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'left',
  margin: '24px auto 0px',
  background: 'rgba(0, 0, 0, 0.55)',
  border: '1px solid rgba(255, 255, 255, 0.35)',
  backdropFilter: 'blur(8px)',
  borderRadius: 8,
  padding: theme.spacing(3),
  width: 'calc(100% - 120px)',

  '& .headline': {
    fontSize: 20,
    lineHeight: `${25 / 20}`,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  '& .form-wrapper': {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    '& .comp-btn': {
      marginLeft: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
      alignItems: 'flex-start',
      width: '100%',

      '& .comp-btn': {
        marginLeft: 0,
        marginTop: theme.spacing(1),
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    width: 'calc(100% - 24px)',
    marginBottom: theme.spacing(5),
  },
}));

const URLWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  flexBasis: '66%',
  maxWidth: '66%',

  '& .MuiFormControl-root label': {
    color: theme.palette.common.white,
    fontSize: 16,
    lineHeight: '1.5',
    letterSpacing: '0.25px',
    paddingBottom: 4,
  },
  '& input': {
    color: theme.palette.common.black,
    borderRadius: 2,
  },
  '& .example': {
    marginTop: theme.spacing(1),
    fontSize: 12,
    height: 20,
  },

  [theme.breakpoints.down('md')]: {
    flexBasis: '100%',
    maxWidth: '100%',
    width: '100%',
  },
}));

const URL_KEY = 'websiteUrl';

/**
 * Handle input and calculating display of competitor score
 */
export default function CompetitorScore() {
  const dispatch = useDispatch();
  const isLoadingCompetitor = useSelector((state) => state.grader.isLoadingCompetitor);
  const compResults = useSelector((state) => state.grader.competitorResults);
  const hasResults = compResults && compResults.score;

  const schema = yup.object().shape({
    [URL_KEY]: yup.string().required('Competitor Website URL is a required field'),
  });

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      [URL_KEY]: '',
    },
  });

  const { handleSubmit, formState: { errors }, setValue } = methods;

  const startCompAnalysis = (formData) => {
    const compURL = formData[URL_KEY];
    dispatch(requestCompetitorScore({
      websiteUrl: compURL,
    }));
    setValue(URL_KEY, '');
  };

  const closeCompResult = () => {
    dispatch(clearCompetitorGrade());
  };

  return (
    <>
      {!isLoadingCompetitor && !hasResults && (
        <Fade in timeout={FADE_TIMING}>
          <CompetitorBox>
            <Typography className="headline">
              See how your online presence compares to your competitor&#39;s.
            </Typography>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(startCompAnalysis)}>
                  <div className="form-wrapper">
                    <URLWrapper>
                      <IQFormInput
                        id="competitorUrl"
                        name={URL_KEY}
                        labelText="Competitor Website URL"
                        fullWidth
                      />
                      {!errors[URL_KEY] && <Typography className="example">https://www.example.com</Typography>}
                    </URLWrapper>
                    <PrimaryButton type="submit" className="comp-btn">See Your Competition&#39;s Score</PrimaryButton>
                  </div>
                </form>
              </FormProvider>
            </ValidationProvider>
          </CompetitorBox>
        </Fade>
      )}
      {hasResults && !isLoadingCompetitor && (
        <CompetitorResult scoreData={compResults} handleClose={closeCompResult} />
      )}
      {isLoadingCompetitor && (
        <CompetitorLoading />
      )}
    </>
  );
}
