import { createAction, createSlice } from '@reduxjs/toolkit';
import { STRIPE_NO_CUSTOMER_ERROR_CODE } from '../account-constants';

const STRIPE_SLICE_NAME = 'stripe';
export const getSetupIntentAction = createAction(`${STRIPE_SLICE_NAME}/getSetupIntent`);

const stripeSlice = createSlice({
  name: STRIPE_SLICE_NAME,
  initialState: {
    clientSecret: '',
    paymentError: null,
    hasNoCustomerError: false,
  },
  reducers: {
    getSetupIntent: (state) => {
      state.hasNoCustomerError = false;
    },
    getSetupIntentSuccess: (state, action) => {
      state.clientSecret = action.payload.clientSecret;
    },
    handleStripeError: (state, action) => {
      if (action.payload?.response?.code === STRIPE_NO_CUSTOMER_ERROR_CODE) {
        state.hasNoCustomerError = true;
      }

      state.paymentError = action.payload.error;
    },
  }
});

export const {
  getSetupIntent,
  getSetupIntentSuccess,
  handleStripeError,
} = stripeSlice.actions;
export default stripeSlice.reducer;