import {
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavigationHooks from '../../shared/navigation/NavigationHooks';
import SettingsDialog from '../../layouts/dialogs/SettingsDialog';
import SplitColumnLayout from '../../layouts/SplitColumnLayout';
import { PaddedNav, SpinnerWrapper } from '../../shared/settings-shared-components';
import CancelModal from '../../shared/modal/CancelModal';
import SideNav from '../../layouts/navBars/SideNav';
import {
  BUNDLE_SLUG,
  LITE_TIER,
  PRO_TIER,
  TOOLS_KEY,
  TOOLS_PATH,
} from '../../shared/app-constants';
import { setShowWarningModal } from './data/tools-slice';
import ToolsSaveWidget from './ToolsSaveWidget';
import { setCurrentScreen } from '../../shared/navigation/navigation-slice';
import DialogFrame from './components/DialogFrame';
import CallSettingsGeneralSettings from '../products/callSettings/sections/CallSettingsGeneralSettings';
import TrialExpiredModal from '../../shared/trials/TrialExpiredModal';
import {
  CLICK_TO_CALL_PAGE,
  EMAIL_CAMPAIGNS_PAGE,
  SCHEDULING_PAGE,
  CHATBOT_PAGE,
  SMS_PAGE,
} from '../../shared/navigation/navigation-config';
import { saveCallSettings } from '../products/callSettings/data/call-settings-actions';
import { closeCallRecorderChangesSaved } from '../products/callSettings/data/call-settings-slice';
import SchedulingSettings from '../products/bookings/sections/SchedulingSettings';
import { closeBookingsChangesSaved, saveBookingsAction } from '../products/bookings/data/bookings-slice';
import EmailCampaignSettings from './sections/EmailCampaignSettings';
import ChatbotToolsPage from './sections/ChatbotToolsPage';
import withOriginLink from '../../shared/hocs/withOriginLink';
import { closeChangesSaved, saveChatbotAction } from '../products/chatbot/data/chatbot-slice';
import { closeEmailChangesSaved, saveEmailCampaignAction } from './data/emailCampaign-slice';
import SMSPage from './sections/SMSPage';
import ProtectedRoute from '../../shared/ProtectedRoute';

const HEADER_TEXT = 'Tools Settings';
function ToolsDialog({ onHandleOriginLinkClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toolsScrollRef = useRef(null);
  const [navItems, setNavItems] = useState([]);
  const [nextScreen, setNextScreen] = useState(null);

  const ACTION_LOOKUP = {
    [SCHEDULING_PAGE]: {
      saveAction: saveBookingsAction({
        tier: PRO_TIER,
      }),
      completeAction: closeBookingsChangesSaved(),
    },
    [CLICK_TO_CALL_PAGE]: {
      saveAction: saveCallSettings({
        slug: BUNDLE_SLUG,
        tier: LITE_TIER,
      }),
      completeAction: closeCallRecorderChangesSaved(),
    },
    [CHATBOT_PAGE]: {
      saveAction: saveChatbotAction({
        tier: PRO_TIER,
      }),
      completeAction: closeChangesSaved(),
    },
    [EMAIL_CAMPAIGNS_PAGE]: {
      saveAction: saveEmailCampaignAction({
        tier: PRO_TIER,
      }),
      completeAction: closeEmailChangesSaved(),
    },
  };

  const { [TOOLS_KEY]: toolNav } = useSelector(
    (state) => state.navigationSettings.sectionListByProduct,
  );

  const showWarningModal = useSelector((state) => state.tools.showWarningModal);
  const rootKey = useSelector((state) => state.tools.rootKey);
  const { currentScreen = SCHEDULING_PAGE } = params;
  const toolsPathName = window.location.pathname;
  const businessInfo = useSelector((state) => state.account.businessDetails?.businessDetails);
  const hasSupportRole = useSelector(state => state.appSettings.user?.hasSupportRole);

  const supportInfo = {
    isSupport: hasSupportRole,
    businessInfo: businessInfo,
  };

  const isDirty = useSelector((state) => state[rootKey]?.isDirty || false);
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);

  const handleProceed = useCallback((proceed) => {
    // User wants to proceed through the reset warning
    if (proceed) {
      // If they selected another screen
      if (nextScreen) {
        dispatch(setCurrentScreen({ currentScreen: nextScreen }));
        navigate(`${TOOLS_PATH}/${nextScreen}`);
      } else {
        // The selected X (close)
        onHandleOriginLinkClose();
      }
    }
    dispatch(setShowWarningModal({
      value: false,
    }));
  });

  // Callback fired when closed with X button
  const handleClose = useCallback(() => {
    if (isDirty) {
      dispatch(setShowWarningModal({
        value: true,
      }));
    } else {
      onHandleOriginLinkClose();
    }
  }, [isDirty]);

  const handleSectionClick = (navKey) => {
    if (isDirty) {
      dispatch(setShowWarningModal({
        value: true,
      }));
      // Prep for proceed
      setNextScreen(navKey);
    } else {
      dispatch(setCurrentScreen({ currentScreen: navKey }));
      navigate(`${TOOLS_PATH}/${navKey}`);
    }

    // Scroll to the top on change
    toolsScrollRef.current.scroll({ top: 0 });
  };

  const handleSave = useCallback((activeScreen) => {
    // Depending on the current screen, trigger the correct save
    const foundAction = ACTION_LOOKUP[activeScreen];
    if (foundAction) {
      dispatch(foundAction.saveAction);
    }
  });

  const handleSaveComplete = useCallback((activeScreen) => {
    const foundAction = ACTION_LOOKUP[activeScreen];
    if (foundAction) {
      dispatch(foundAction.completeAction);
    }
  });

  return (
    <ProtectedRoute pathname={toolsPathName}>
      <SettingsDialog
        open
        header={HEADER_TEXT}
        onClose={handleClose}
        customHeader={(
          <ToolsSaveWidget
            storeRoot={rootKey}
            handleSave={handleSave}
            handleSaveComplete={handleSaveComplete}
          />
        )}
        id="toolsSettings-closeSettings"
        headerId="toolsSettings-title"
        supportInfo={supportInfo}
      >
        <Helmet>
          <title>
            Settings | LocaliQ
          </title>
        </Helmet>
        {!loadedSettings && (
          <SpinnerWrapper>
            <IQLoadingSpinner size={100} />
          </SpinnerWrapper>
        )}
        {loadedSettings && (
          <SplitColumnLayout
            leftSideContent={(
              <PaddedNav>
                <SideNav
                  navItems={navItems}
                  currentSection={currentScreen}
                  product={TOOLS_KEY}
                  showTrackingCodeViewInstructions
                  customOnClick={handleSectionClick}
                />
              </PaddedNav>
            )}
            rightSideContent={(
              <DialogFrame hideBanners={currentScreen === SMS_PAGE || hasSupportRole}>
                {(currentScreen === SMS_PAGE && hasSupportRole)
                  && (
                    <SMSPage />
                  )}
                {currentScreen === SCHEDULING_PAGE && (
                  <SchedulingSettings />
                )}
                {currentScreen === CLICK_TO_CALL_PAGE
                  && (
                    <CallSettingsGeneralSettings />
                  )}
                {currentScreen === EMAIL_CAMPAIGNS_PAGE
                  && (
                    <EmailCampaignSettings />
                  )}
                {currentScreen === CHATBOT_PAGE
                  && (
                    <ChatbotToolsPage />
                  )}
              </DialogFrame>
            )}
            rightContentRef={toolsScrollRef}
          />
        )}
        <CancelModal
          id="settings-discard-modal"
          isOpen={showWarningModal}
          proceedCallback={handleProceed}
        />
        <NavigationHooks
          slug={TOOLS_KEY}
          tier={PRO_TIER}
          productNav={toolNav}
          handleNavCalc={setNavItems}
          open
        />
        <TrialExpiredModal />
      </SettingsDialog>
    </ProtectedRoute>
  );
}

export default withOriginLink(ToolsDialog);

ToolsDialog.propTypes = {
  onHandleOriginLinkClose: PropTypes.func.isRequired,
};
