import styled from "@emotion/styled";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  WIDGET_LOCATION_FORM,
  WIDGET_LOCATION,
  WIDGET_LOCATION_LEFT,
  WIDGET_LOCATION_RIGHT,
  WIDGET_LOCATION_RIGHT_TEXT,
  WIDGET_LOCATION_LEFT_TEXT,
} from "../settings-constants";
import { updateWidgetLocation } from "../data/widgetSettings-slice";
import WidgetSettingsStateChangedHook from "../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook";

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "14px",
}));

const FeatureDiv = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "35px",
}));

const FeatureTitle = styled(Typography)(() => ({ theme }) => ({
  fontSize: 20,
  fontFamily: "Unify Sans Semibold",
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
  },
}));

const RadioWrapper = styled("div")(() => ({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

const RadioWrapperTest = styled(RadioGroup)(() => ({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "24px",
}));

const FORM_KEYS = [WIDGET_LOCATION];

export default function WidgetLocation() {
  const dispatch = useDispatch();
  const widgetLocation = useSelector((state) => state.settings.engagementWidget.widgetLocation);

  const schema = yup.object().shape({
    [WIDGET_LOCATION]: yup.string(),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [WIDGET_LOCATION]: widgetLocation,
    })),
  });

  const { control } = methods;

  const selectedLocation = useWatch({
    control,
    name: WIDGET_LOCATION,
    defaultValue: widgetLocation,
  });

  useEffect(() => {
    dispatch(updateWidgetLocation({ widgetLocation: selectedLocation }));
  }, [selectedLocation]);

  return (
    <>
      <FeatureDiv>
        <FeatureTitle id="widget-location-title"> Location </FeatureTitle>
      </FeatureDiv>
      <FormProvider {...methods}>
        <form>
          <RadioWrapper>
            <FormControl name={WIDGET_LOCATION}>
              <Controller
                control={control}
                name={WIDGET_LOCATION}
                render={({ field }) => (
                  <RadioWrapperTest value={location} {...field}>
                    <FormControlLabel label={WIDGET_LOCATION_RIGHT_TEXT} value={WIDGET_LOCATION_RIGHT} control={<Radio />} />
                    <FormControlLabel label={WIDGET_LOCATION_LEFT_TEXT} value={WIDGET_LOCATION_LEFT} control={<Radio />} />
                  </RadioWrapperTest>
                )}
              />
            </FormControl>
          </RadioWrapper>
        </form>
        <WidgetSettingsStateChangedHook
          formName={WIDGET_LOCATION_FORM}
          formKeys={FORM_KEYS}
          subsectionKey="engagementWidget"
        />
      </FormProvider>
    </>
  );
}
