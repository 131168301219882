import {
  tracker, constants, events, eventProperties,
} from '@gannettdigital/localiq-dms-analytics-tracker';
import {
  CALL_SETTINGS_PATH,
  CALL_TRACKER_SLUG,
  CHATBOT_PATH,
  CHATBOT_SLUG,
  ACCOUNT_PATH,
  FREE_TIER,
  LITE_TIER,
  PREMIUM_LISTINGS_SLUG,
  PRO_REVIEW_TIER,
  PRO_TIER,
  SCHEDULER_PATH,
  OLD_SCHEDULER_PATH,
  SCHEDULER_SLUG,
  USD_CURRENCY,
  CONTACT_US_PATH,
  CONTACT_FORM_PATH,
  CONTACT_FORM_SLUG,
  CONFIG_PATH,
  DASHBOARD_PATH,
  TOOLS_PATH,
  WIDGET_SETTINGS_PATH,
} from './app-constants';
import {
  BOOKINGS_NAV_STEPS,
  CHATBOT_NAV_STEPS,
  ACCOUNT_NAV_STEPS,
  CALL_NAV_STEPS,
  CONTACT_FORM_NAV_STEPS,
  WIDGET_SETTINGS_STEPS,
  TOOL_NAV_STEPS,
} from './navigation/navigation-config';
import { trackProductActivation } from './tag-manager-utils';
import {
  WELCOME_KEY,
  WIDGET_FEATURE_KEY,
  WIDGET_COLOR_KEY,
  APPOINTMENT_SCHEDULE_KEY,
  BUSINESS_PHONE_KEY,
  WIDGET_SETUP_COMPLETE_PAGE_KEY,
  WEBSITE_TYPE_KEY,
  LOCALIQ_CODE_KEY,
} from '../pages/configuration/wizard/wizard-constants';
import { LOW, MEDIUM } from '../pages/graders/website/components/GraderSection/data/grader-section-constants';

const {
  REACT_APP_MIXPANEL_ENABLED,
  REACT_APP_MIXPANEL_PROJECT_TOKEN,
} = process.env;

const MIXPANEL_ENABLE_CONVERTED = REACT_APP_MIXPANEL_ENABLED === 'true';

const SLUG_CONVERSION_MAP = {
  [SCHEDULER_SLUG]: 'Scheduler',  // NOTE: Keep analytics the old value, do not change to Scheduling
  [CHATBOT_SLUG]: 'Chatbot',
  [CALL_TRACKER_SLUG]: 'Call Recorder',
  [CONTACT_FORM_SLUG]: 'Message',
};

const TIER_CONVERSION_MAP = {
  [FREE_TIER]: 'Free',
  [PRO_TIER]: 'Pro',
  [LITE_TIER]: 'Free',
};

const SLUG_TIER_CONVERSION_MAP = {
  [`${CHATBOT_SLUG}_${FREE_TIER}`]: 'Free Chatbot',
  [`${CHATBOT_SLUG}_${PRO_TIER}`]: 'Chatbot Pro',
  [`${PREMIUM_LISTINGS_SLUG}_${PRO_TIER}`]: 'Premium Listings Pro',
  [`${PREMIUM_LISTINGS_SLUG}_${PRO_REVIEW_TIER}`]: 'Premium Listings Pro + Review Management',
  [`${CALL_TRACKER_SLUG}_${LITE_TIER}`]: 'Inbound Call Recorder',
  [`${SCHEDULER_SLUG}_${FREE_TIER}`]: 'Scheduler Free', // NOTE: Keep analytics the old value, do not change to Scheduling
  [`${SCHEDULER_SLUG}_${PRO_TIER}`]: 'Scheduler Pro', // NOTE: Keep analytics the old value, do not change to Scheduling
  [`${CONTACT_FORM_SLUG}_${FREE_TIER}`]: 'Message Free',
  [`${CONTACT_FORM_SLUG}_${PRO_TIER}`]: 'Message Pro',
};

export const STEP_ID_TO_ANALYTICS_STEP_NAME = {
  [WELCOME_KEY]: 'Welcome',
  [WIDGET_FEATURE_KEY]: 'Feature Selection',
  [WIDGET_COLOR_KEY]: 'Color Selection',
  [APPOINTMENT_SCHEDULE_KEY]: 'Scheduling Availability',
  [BUSINESS_PHONE_KEY]: 'Business Phone Number',
  [WIDGET_SETUP_COMPLETE_PAGE_KEY]: 'Features Activated',
  [WEBSITE_TYPE_KEY]: 'Code Step 1',
  [LOCALIQ_CODE_KEY]: 'Code Step 2',
};

export const SUITE_USER_TYPES = {
  PRE_TRIAL: 'Free User: pre trial',
  POST_TRIAL: 'Free User: post trial',
  FREE_TRIAL: 'Free Trialer',
  PAID: 'Paid Subscriber',
};

export const TRIAL_SOURCE_KEY = 'trialSource';

export const TRIAL_SOURCES = {
  DASHBOARD: 'Dashboard',
  SETTINGS: 'Settings',
  BUSINESS_CREATE: 'Business Creation',
};

export const lookupProductConversion = (slug, tier) => (
  SLUG_TIER_CONVERSION_MAP[`${slug}_${tier}`]
);

/**
 * Function to pull the hostname from the current page and
 * return it.
 * @returns string
 */
export const retrieveHostname = () => (
  window.location.hostname
);

/**
 * Function to pull the path from the current page and
 * return it. Trims off the starting /
 * @returns string
 */
export const retrievePageName = () => (
  window.location.pathname.slice(1) === '' ? '/' : window.location.pathname.slice(1)
);

/**
 * Handle initializing mixpanel tracking
 */
export const initializeMixpanel = () => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.init([{
      name: constants.MIXPANEL_VENDOR_NAME,
      token: REACT_APP_MIXPANEL_PROJECT_TOKEN,
    }]);
  }
};

/**
 * Function to handle identifying the user
 */
export const setupUser = (userObj) => {
  if (userObj.email) {
    tracker.identifyUser(userObj.email);
  }
};

/**
 * Register the suite user type as a super prop
 */
export const setSuiteUserType = (suiteType) => {
  tracker.registerSuperProperties({
    [constants.SUITE_USER_TYPE]: suiteType,
  });
};

/**
 * Handle activating a product
 */
export const setActivatedProduct = () => {
  // Registers first product activation if one not currently set
  tracker.safeRegisterUserProperties({
    [constants.DATE_OF_FIRST_PRODUCT_ACTIVATION]: new Date(),
  });
};

/**
 * Set the enabled products user property.
 * @param {string} slug
 * @param {string} tier
 * @param {bool} enable: Whether to enable or disable the slug/tier combo
 */
export const setEnabledProducts = (slug, tier, enable) => {
  const convertedName = lookupProductConversion(slug, tier);
  if (convertedName) {
    if (enable) {
      tracker.appendUserProperty(constants.ENABLED_PRODUCTS, convertedName);
    } else {
      tracker.removeUserProperty(constants.ENABLED_PRODUCTS, convertedName);
    }
  }
};

/**
 * Track the activated product as well as setting activated products
 * in user props.
 * @param {String} slug
 * @param {String} tier
 * @param {String} firstActivationDate
 */
export const trackActivatedProduct = (slug, tier, firstActivationDate) => {
  if (!firstActivationDate) {
    setActivatedProduct();
    tracker.trackEvent(events.ACTIVATED_PRODUCT, {});

    // Also send to GTM
    trackProductActivation(slug, tier, USD_CURRENCY);
  }
};

/**
 * Track the upgrade event
 * @param {string} buttonCopy
 */
export const trackUpgrade = (buttonCopy) => {
  const eventData = {
    [eventProperties.PAGE]: retrievePageName(),
  };

  if (buttonCopy) {
    eventData[eventProperties.BUTTON_COPY] = buttonCopy;
  };

  tracker.trackEvent(events.UPGRADE_CLICKED, eventData);
};

/**
 * Track the calendar connect event
 */
export const trackCalendarConnect = (vendor) => {
  tracker.trackEvent(events.CALENDAR_CONNECTED, {
    [eventProperties.VENDOR]: vendor,
  });
};

/**
 * Track when a new appointment type is added in settings
 */
export const trackAddApptType = () => {
  tracker.trackEvent(events.ADD_APPOINTMENT_TYPE_CLICKED, {});
};

/**
 * Track when the appointment type dropdown changes
 */
export const trackAppointmentTypeChange = (location) => {
  tracker.trackEvent(events.APPOINTMENT_TYPE_CHANGED, {
    [eventProperties.LOCATION]: location,
  });
}

/**
 * Track when a scheduled appointment is opened from the management flow
 * @param {string} duration
 * @param {string} type
 */
export const trackBookingOpen = (duration, type) => {
  // TODO: for now always list view
  tracker.trackEvent(events.APPOINTMENT_CLICKED, {
    [eventProperties.VIEW]: 'List',
    [eventProperties.DURATION]: duration,
    [eventProperties.APPOINTMENT_TYPE_LOCATION]: type,
  });
};

const NAV_SETUP = [
  {
    rootPath: SCHEDULER_PATH,
    steps: Object.values(BOOKINGS_NAV_STEPS),
  },
  {
    rootPath: CALL_SETTINGS_PATH,
    steps: Object.values(CALL_NAV_STEPS),
  },
  {
    rootPath: CHATBOT_PATH,
    steps: Object.values(CHATBOT_NAV_STEPS),
  },
  {
    rootPath: ACCOUNT_PATH,
    steps: Object.values(ACCOUNT_NAV_STEPS),
  },
  {
    rootPath: CONTACT_US_PATH,
    steps: [],
  },
  {
    rootPath: CONTACT_FORM_PATH,
    steps: Object.values(CONTACT_FORM_NAV_STEPS),
  },
  {
    rootPath: TOOLS_PATH,
    steps: Object.values(TOOL_NAV_STEPS),
  },
  {
    rootPath: WIDGET_SETTINGS_PATH,
    steps: Object.values(WIDGET_SETTINGS_STEPS),
  }
];

// Convert the nav setups into URL -> page name key values pairs
// Add root routes in the base object as needed
// (if you want to track landing page e.g.) /scheduler
const FULL_ROUTES = NAV_SETUP.reduce((acc, navObj) => {
  const { rootPath } = navObj;
  navObj.steps.forEach((stepObj) => {
    acc[`${rootPath}/${stepObj.url}`] = stepObj.display;
  });

  return acc;
}, {
  [OLD_SCHEDULER_PATH]: 'Scheduler', // Do not change value to Scheduling
  [SCHEDULER_PATH]: 'Scheduler', // Do not change value to Scheduling
  [TOOLS_PATH]: 'Tools',
  [WIDGET_SETTINGS_PATH]: 'Widget Settings',
  [ACCOUNT_PATH]: 'Account Page',
  [CONTACT_US_PATH]: 'Contact Us Page',
  [CONTACT_FORM_PATH]: 'Contact Form Page',
  [DASHBOARD_PATH]: 'Dashboard',

});

/**
 * Handles sending the page viewed event
 */
export const trackPageView = (fullPath) => {
  const pageName = FULL_ROUTES[fullPath];
  if (pageName) {
    // We are on a new page, so we need to re-register the page name super prop
    tracker.registerSuperProperties({
      [constants.PAGE]: retrievePageName(),
    });

    tracker.trackEvent(events.PAGE_VIEWED, {
      [eventProperties.PAGE_TITLE]: pageName,
    }, null, true);
  }
};

/**
 * Track when scheduler view button is clicked
 */
export const trackSchedulerViewClick = () => {
  tracker.trackEvent(
    events.SCHEDULER_VIEW_CLICKED,
    {},
  );
};

/**
 * Track when scheduler share button is clicked
 */
export const trackSchedulerShareClick = () => {
  tracker.trackEvent(
    events.SCHEDULER_SHARED_CLICKED,
    {},
  );
};


/**
 * Track when scheduler logo upload events
 */
export const trackSchedulerLogoUploadInitiated = (eventType, fileSize) => {
  tracker.trackEvent(
    events.SCHEDULER_LOGO_UPLOAD_INITIATED,
    {
      [eventProperties.LOGO_UPLOAD_METHOD]: eventType,
      [eventProperties.LOGO_UPLOAD_SIZE]: fileSize,
    }
  )
};

export const trackSchedulerLogoDeleted = () => {
  tracker.trackEvent(
    events.SCHEDULER_LOGO_DELETED,
    {}
  )
};

// when user clicks on contact us option
export const trackContactUsEvent = (method) => {
  tracker.trackEvent(events.CONTACT_US_CLICKED, {
    [eventProperties.CONTACT_US_METHOD]: method,
  });
}

// Track when user clicks on 'Download Invoice'
export const trackDownloadInvoice = (invoiceId) => {
  tracker.trackEvent(
    events.DOWNLOAD_INVOICE_CLICKED,
    {
      [eventProperties.DOWNLOAD_INVOICE_ID]: invoiceId,
    }
  )
};

// when user performs action on cc section (e.g. edit, add, delete)
export const trackCreditCardAction = (action) => {
  tracker.trackEvent(events.CC_MANAGEMENT_CLICKED, {
    [eventProperties.CREDIT_CARD_ACTION]: action,
  });
};

export const trackCreditCardAddSuccess = () => {
  tracker.trackEvent(events.CREDIT_CARD_ADDED, {});
};

export const trackCreditCardDeleteSuccess = () => {
  tracker.trackEvent(events.CREDIT_CARD_DELETED, {});
};

export const trackCreditCardUpdateSuccess = () => {
  tracker.trackEvent(events.CREDIT_CARD_UPDATED, {});
};

/**
 * Track when change credit card is clicked for a subscription
 */
export const trackChangeCreditCard = () => {
  tracker.trackEvent(events.CHANGE_CREDIT_CARD_LINK_CLICKED, {});
};

/**
 * Track when cancel subscription is clicked
 */
export const trackInProductCancellationSubscription = () => {
  tracker.trackEvent(events.CANCELLATION_REASON_INITIATED, {});
};

/**
 * Track when cancellation survey is submitted
 */
export const trackInProductCancellationSurveySubmitted = (reason) => {
  tracker.trackEvent(events.CANCELLATION_REASON_SURVEY_SUBMITTED, {
    [eventProperties.CANCELLATION_REASON]: reason
  });
};

/**
 * Track when cancel subscription API call is finished
 */
export const trackInProductCancellationFinished = (cardType) => {
  tracker.trackEvent(events.SUBSCRIPTION_CANCELLED, {
    [eventProperties.CANCELLATION_CARD]: cardType,
  });
};

/**
 * Track when cancel subscription API call is finished
 */
export const trackInProductCancellationSalvaged = (cardType) => {
  tracker.trackEvent(events.SUBSCRIPTION_SALVAGED, {
    [eventProperties.CANCELLATION_CARD]: cardType,
  });
};

export const trackSubscriptionCCUpdated = (subscriptionId) => {
  tracker.trackEvent(events.SUBSCRIPTION_CC_UPDATED, {
    [eventProperties.SUBSCRIPTION_ID]: subscriptionId,
  });
};

export const trackViewInstructionsClicked = () => {
  tracker.trackEvent(events.LOCALIQ_CODE_BANNER_CLICKED, {});
};

// Track localiQ capture code page
export const trackCaptureCodeCopied = (websiteType, source) => {
  tracker.trackEvent(events.LOCALIQ_CAPTURE_CODE_COPIED, {
    [eventProperties.CAPTURE_CODE_COPIED_PLATFORM]: websiteType,
    [eventProperties.SOURCE]: source,
  });
};

export const trackCaptureCodeSupportDocClicked = () => {
  tracker.trackEvent(events.LOCALIQ_CAPTURE_CODE_SUPPORT_DOCUMENT_CLICKED, {});
};

export const trackCaptureCodeInstructionSent = (source) => {
  tracker.trackEvent(events.LOCALIQ_CAPTURE_CODE_INSTRUCTION_SENT, {
    [eventProperties.SOURCE]: source,
  });
};

export const trackConfigWizardSaveAndExit = (stepId) => {
  tracker.trackEvent(events.CONFIG_WIZARD_SAVE_EXIT_CLICKED, {
    [eventProperties.PAGE]: STEP_ID_TO_ANALYTICS_STEP_NAME[stepId],
  });
};

export const trackLearnMoreClick = (source) => {
  tracker.trackEvent(events.LEARN_MORE_CLICKED, {
    [eventProperties.SOURCE]: source,
  });
};

export const trackDashboardCardClick = (card, ctaCopy) => {
  tracker.trackEvent(events.DASHBOARD_CARD_CLICKED, {
    [eventProperties.CARD]: card,
    [eventProperties.CTA_COPY]: ctaCopy,
  });
};

export const trackSingleLeadClick = (leadType) => {
  tracker.trackEvent(events.DASHBOARD_LEAD_CLICKED, {
    [eventProperties.LEAD_TYPE]: leadType
  });
};

export const trackFKTDownload = () => {
  tracker.trackEvent(events.KEYWORDS_DOWNLOADED, {});
};

const convertLevel = (level) => {
  if (level === LOW) {
    return 'red';
  }
  if (level === MEDIUM) {
    return 'yellow';
  }
  return 'green';
};

export const trackGraderRecOpen = (level, title, subtitle) => {
  const priority = convertLevel(level);
  tracker.trackEvent(events.GRADER_RECOMMENDATION_OPENED, {
    [eventProperties.PRIORITY]: priority,
    [eventProperties.TITLE]: title,
    [eventProperties.SUBTITLE]: subtitle,
  });
};

export const trackWizardDone = (stepId) => {
  tracker.trackEvent(events.WIZARD_STEP_COMPLETED, {
    [eventProperties.STEP_COMPLETED]: STEP_ID_TO_ANALYTICS_STEP_NAME[stepId],
  });
};

export const trackNavigationClicked = (labelText) => {
  tracker.trackEvent(events.NAVIGATION_CLICKED, {
    [eventProperties.NAVIGATION_ITEM]: labelText
  });
};