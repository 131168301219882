export function getLocalTimezone(){
  const fullTimezone = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' });
  let tZAbbreviation = fullTimezone.split(' ').pop();

  // We do this check for every zone except MDT
  if (tZAbbreviation.includes('D') && tZAbbreviation !== 'MDT') {
    tZAbbreviation = tZAbbreviation.replace('D', 'S');
  }

  const knownCode = TIMEZONES_BY_CODE[tZAbbreviation];
  let timezoneValue = null;
  if (!knownCode) {
    timezoneValue = EST.value;
  } else {
    timezoneValue = knownCode.value;
  }

  return timezoneValue;
}

export const EST = {
  value: 'America/New_York',
  description: 'Eastern Standard Time (EST)',
  standardCode: 'EST',
};

// Timezone
export const US_TIMEZONES = [
  EST,
  {
    value: 'America/Chicago',
    description: 'Central Standard Time (CST)',
    standardCode: 'CST',
  },
  {
    value: 'America/Denver',
    description: 'Mountain Standard Time (MST)',
    standardCode: 'MST',
  },
  {
    value: 'America/Los_Angeles',
    description: 'Pacific Standard Time (PST)',
    standardCode: 'PST',
  },
  {
    value: 'America/Anchorage',
    description: 'Alaskan Standard Time (AKST)',
    standardCode: 'AKST',
  },
  {
    value: 'Pacific/Honolulu',
    description: 'Hawaii-Aleutian Standard Time (HST)',
    standardCode: 'HST',
  },
  {
    value: 'America/Phoenix',
    description: 'Mountain Daylight Time (MDT)',
    standardCode: 'MDT',
  },
];

export const TIMEZONES_BY_CODE = US_TIMEZONES.reduce((acc, tzObj) => {
  acc[tzObj.standardCode] = tzObj;
  return acc;
}, {});