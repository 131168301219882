import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ReactComponent as ExpandMoreSvg } from '../../assets/appointment-type-expand-more.svg';
import { ReactComponent as ExpandLessSvg } from '../../assets/appointment-type-expand-less.svg';

const ListContainer = styled('div')(({ theme, showBorderBottom }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  borderBottom: showBorderBottom ? `1px solid ${theme.palette.text.secondary}` : 'unset',
}));

const TitleWrapper = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '25px',
  letterSpacing: '0.18px',
}));

/**
 * Component for the view state of an appointment type
 */
export default function AppointmentTypeAccordion({
  name, onExpandMoreCallback, onExpandLessCallback, isEditing, isLast, children,
}) {
  return (
    <>
      <ListContainer showBorderBottom={isLast && !isEditing}>
        <TitleWrapper variant='h5'>
          {name}
        </TitleWrapper>
        {
          isEditing ? (
            <div style={{ cursor: 'pointer' }} onClick={onExpandLessCallback}>
              <ExpandLessSvg />
            </div>
          ) : (
            <div style={{ cursor: 'pointer' }} onClick={onExpandMoreCallback}>
              <ExpandMoreSvg />
            </div>
          )
        }
      </ListContainer>
      {
        isEditing && (
          children
        )
      }
    </>
  );
}

AppointmentTypeAccordion.propTypes = {
  name: PropTypes.string.isRequired,
  onExpandMoreCallback: PropTypes.func.isRequired,
  onExpandLessCallback: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
