import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
  SCHEDULER_FORM_KEY,
  CHATBOT_FORM_KEY,
  CLICKTOCALL_FORM_KEY,
  CONTACTFORM_FORM_KEY,
  WIDGET_FEATURE_KEY,
  WIDGET_COLOR_KEY,
} from '../wizard-constants';
import WidgetFeature from '../../sections/WidgetFeature';
import WidgetPreview from '../components/WidgetPreview';
import { StepWrapper } from '../components/wizard-shared-components';
import WizardFeatureTitle from '../components/WizardFeatureTitle';
import { CALL_TRACKER_FEATURE, CHAT_FEATURE, COLOR_KEY, CONTACT_FORM_FEATURE, SCHEDULER_FEATURE } from '../../data/slice-constants';
import DoubleQuoteIcon from '../../../../images/double-quote.svg';
import { isNotNullOrUndefined } from '../wizard-utils';

const WidgetFeatureWrapper = styled('div')(({ theme }) => ({
  padding: '5rem 7rem 0 7rem',
  width: '50%',
  [theme.breakpoints.down('xl')]: {
    padding: '1rem 6rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 5rem 2rem 5rem',
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 5rem 2rem 5rem',
  },
}));

const WidgetDescription = styled(Typography)((({ theme }) => ({
  margin: '1rem 0 2rem 0',
  fontSize: '18px',
})));

const PreviewWrapper = styled('div')(({ theme }) => ({
  padding: '5rem 0 0 0',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export default function FeatureStep({
  stepData,
}) {
  const isSchedulerFeatureActive = useSelector(
    (state) => state.configuration[WIDGET_FEATURE_KEY][SCHEDULER_FEATURE],
  );
  const isChatFeatureActivate = useSelector(
    (state) => state.configuration[WIDGET_FEATURE_KEY][CHAT_FEATURE],
  );
  const isCallRecorderFeatureActivate = useSelector(
    (state) => state.configuration[WIDGET_FEATURE_KEY][CALL_TRACKER_FEATURE],
  );
  const isContactFormFeatureActivate = useSelector(
    (state) => state.configuration[WIDGET_FEATURE_KEY][CONTACT_FORM_FEATURE],
  );
  const existingTheme = useSelector((state) => state.configuration[WIDGET_COLOR_KEY][COLOR_KEY]);

  const schema = yup.object().shape({
    [SCHEDULER_FORM_KEY]: yup.boolean(),
    [CHATBOT_FORM_KEY]: yup.boolean(),
    [CLICKTOCALL_FORM_KEY]: yup.boolean(),
    [CONTACTFORM_FORM_KEY]: yup.boolean(),
  });

  // Default values is ineffective for making sure these defaults are set correctly
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [SCHEDULER_FORM_KEY]: isSchedulerFeatureActive,
      [CHATBOT_FORM_KEY]: isChatFeatureActivate,
      [CLICKTOCALL_FORM_KEY]: isCallRecorderFeatureActivate,
      [CONTACTFORM_FORM_KEY]: isContactFormFeatureActivate,
    }), [
      isSchedulerFeatureActive,
      isChatFeatureActivate,
      isCallRecorderFeatureActivate,
      isContactFormFeatureActivate,
    ]),
  });

  const { setValue } = methods;

  useEffect(() => {
    if (isNotNullOrUndefined(isSchedulerFeatureActive)) {
      setValue(SCHEDULER_FORM_KEY, isSchedulerFeatureActive);
    }
  }, [isSchedulerFeatureActive]);

  useEffect(() => {
    if (isNotNullOrUndefined(isChatFeatureActivate)) {
      setValue(CHATBOT_FORM_KEY, isChatFeatureActivate);
    }
  }, [isChatFeatureActivate]);

  useEffect(() => {
    if (isNotNullOrUndefined(isCallRecorderFeatureActivate)) {
      setValue(CLICKTOCALL_FORM_KEY, isCallRecorderFeatureActivate);
    }
  }, [isCallRecorderFeatureActivate]);

  useEffect(() => {
    if (isNotNullOrUndefined(isContactFormFeatureActivate)) {
      setValue(CONTACTFORM_FORM_KEY, isContactFormFeatureActivate);
    }
  }, [isContactFormFeatureActivate]);

  return (
    <StepWrapper desktopFlexDirection="row" gapBetweenItems={2}>
      <WidgetFeatureWrapper>
        <WizardFeatureTitle
          icon={DoubleQuoteIcon}
          text="Engagement Widget"
        />
        <Typography variant="h3"> Widget Features </Typography>
        <WidgetDescription>
          Select the features you would like to show on your website.
        </WidgetDescription>
        <FormProvider {...methods}>
          <form>
            <WidgetFeature />
          </form>
        </FormProvider>
      </WidgetFeatureWrapper>
      <PreviewWrapper>
        <WidgetPreview
          isSchedulerOn={isSchedulerFeatureActive}
          isCallTrackerOn={isCallRecorderFeatureActivate}
          isChatOn={isChatFeatureActivate}
          isMessageOn={isContactFormFeatureActivate}
          existingTheme={existingTheme}
        />
      </PreviewWrapper>
    </StepWrapper>
  );
}

FeatureStep.propTypes = {
  stepData: PropTypes.object.isRequired,
};
