import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DesktopGradientBackground from '../assets/browser-preview.svg';
import MobileGradientBackground from '../assets/browser-preview-mobile.svg';

const PreviewWrapper = styled('div')(() => (({ theme }) => ({
  marginRight: '5rem',

  '& .widgetFrame': {
    backgroundImage: `url(${DesktopGradientBackground})`,
    backgroundSize: 'cover',
    height: 471,
    width: 355,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',

    '&.mobileWidgetFrame': {
      backgroundImage: `url(${MobileGradientBackground})`,
      height: 504,
      width: 328,
      backgroundSize: 'cover',
    },

    '& #localiq-widgets-wrapper': {
      position: 'relative !important',
      bottom: theme.spacing(2),
      right: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        bottom: theme.spacing(2),
      },
    },

    '& .spinnerWrapper': {
      margin: '0 auto',
      position: 'relative',
      bottom: '40%',
    },
  },

  [theme.breakpoints.down('xl')]: {
    marginRight: 0,
    padding: theme.spacing(0, 2),
  },
})));

// Baseline widget config
const widgetConfig = {
  position: 'relative',
  previewOnly: true,
  settings: {
    theme: 'SLATEGREY',
    businessName: 'Demo business',
    businessEmail: 'demoBusiness@gannett.com',
    displayOnly: true,
  },
  call: {
    active: true,
    settings: {
      phoneNumber: '+5555555555',
    },
  },
  chat: {
    active: true,
    settings: {
      questions: [],
      chatTitle: 'Demo Chat',
      chatType: 'FREEMIUM',
      defaultHideToast: true,
      defaultOpenChat: false,
      floatChat: true,
      idleTimeout: 300,
      defaultHideIcons: true,
      previewOnly: true,
      schedulerLink: {
        active: false,
      },
    },
  },
  scheduler: {
    active: true,
  },
  message: {
    active: true,
  },
  sms: {
    active: false,
    settings: {
      previewOnly: true,
    },
  },
};

const WIDGET_ID = 'localiq-widgets-wrapper';

export default function WidgetPreview({
  isSchedulerOn,
  isCallTrackerOn,
  isChatOn,
  isMessageOn,
  isSMSOn,
  existingTheme,
  clickableSMS = false,
}) {
  const [showSpinner, setShowSpinner] = useState(true);
  const [widgetNumber, setWidgetNumber] = useState('');
  const businessPhoneNumber = useSelector(
    (state) => state.account.businessDetails.businessDetails.businessPhone,
  );
  const isTrackingNumberLoading = useSelector((state) => state.settings.loadingTrackingNumber);
  const trackingNumber = useSelector((state) => state.settings.trackingNumber);

  const mergedConfig = { ...widgetConfig };
  mergedConfig.selector = '#widgets-preview';

  if (existingTheme) {
    mergedConfig.settings.theme = existingTheme;
  }
  mergedConfig.call.settings.phoneNumber = widgetNumber;
  mergedConfig.scheduler.active = isSchedulerOn;
  mergedConfig.chat.active = isChatOn;
  mergedConfig.call.active = isCallTrackerOn;
  mergedConfig.sms.active = isSMSOn;
  mergedConfig.message.active = isMessageOn;

  if (clickableSMS) {
    mergedConfig.sms.settings.displayOnly = false;
  }

  useEffect(() => {
    // Show loading before change
    setShowSpinner(true);
    // Delete the existing widget div if it exists
    const existingWidgets = document.getElementById(WIDGET_ID);
    if (existingWidgets) {
      existingWidgets.remove();
    }
    // Reload the widgets after 1 second
    const renderTimeout = setTimeout(() => {
      window.LocalIQWidgets(mergedConfig).render();
      setShowSpinner(false);
    }, 1000);

    return () => {
      clearTimeout(renderTimeout);
    };
  }, [isSchedulerOn, isChatOn, isCallTrackerOn, isMessageOn, existingTheme, isSMSOn]);

  useEffect(() => {
    if (trackingNumber) {
      setWidgetNumber(trackingNumber);
    } else if (businessPhoneNumber) {
      setWidgetNumber(businessPhoneNumber);
    } else {
      setWidgetNumber('+5555555555');
    }
  }, [isTrackingNumberLoading, trackingNumber, businessPhoneNumber]);

  return (
    <PreviewWrapper>
      <div
        className={`widgetFrame${isMobile ? ' mobileWidgetFrame' : ''}`}
        id="widgets-preview"
        style={{
          opacity: 1,
        }}
      >
        {showSpinner && (
          <div className="spinnerWrapper">
            <IQLoadingSpinner size={100} />
          </div>
        )}
      </div>
    </PreviewWrapper>
  );
}

WidgetPreview.propTypes = {
  isSchedulerOn: PropTypes.bool,
  isCallTrackerOn: PropTypes.bool,
  isChatOn: PropTypes.bool,
  isMessageOn: PropTypes.bool,
  isSMSOn: PropTypes.bool,
  existingTheme: PropTypes.string,
  clickableSMS: PropTypes.bool,
};
