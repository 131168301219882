import styled from '@emotion/styled';
import SkeletonText from '../SkeletonText';

const StyledCardWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: '8px',
  overflow: 'hidden',
  background: theme.palette.common.white,
  width: '33%',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledCardHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3),
  gap: theme.spacing(1),
  backgroundColor: theme.palette.common.black,
  height: '117px',
}));

const StyledCardBodyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 2),
}));

const StyledCardRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
}));

export default function Card() {
  return (
    <StyledCardWrapper>
      <StyledCardHeadWrapper>
        <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width={148} height={24} />
        <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width={82} height={16} />
      </StyledCardHeadWrapper>
      <StyledCardBodyWrapper>
        <SkeletonText animation="wave" variant="string" width="100%" height={30} />
        <StyledCardRow>
          <SkeletonText animation="wave" variant="string" width={18} height={24} />
          <SkeletonText animation="wave" variant="string" width="100%" height={24} />
        </StyledCardRow>
        <StyledCardRow>
          <SkeletonText animation="wave" variant="string" width={18} height={24} />
          <SkeletonText animation="wave" variant="string" width="100%" height={24} />
        </StyledCardRow>
        <StyledCardRow>
          <SkeletonText animation="wave" variant="string" width={18} height={24} />
          <SkeletonText animation="wave" variant="string" width="100%" height={24} />
        </StyledCardRow>
      </StyledCardBodyWrapper>
    </StyledCardWrapper>
  );
}
