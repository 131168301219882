import { WEBSITE_QUALITY } from '../../../data/grader-constants';
import {
  HIGH_SCORE, LOW_SCORE, MEDIUM_SCORE, TAKEAWAYS,
} from './grader-section-constants';

/**
 * Retrieve the takeaway string for the given score level and product
 * @param {string} level
 * @param {string} section
 */
export const retrieveTakeaways = (level, section) => (
  TAKEAWAYS[section] ? TAKEAWAYS[section][level] : TAKEAWAYS[WEBSITE_QUALITY][MEDIUM_SCORE]
);

/**
 * Determine which score constant matches the given score
 * @param {number} score
 * @returns String
 */
export const determineLevel = (score) => {
  if (score > 66) {
    return HIGH_SCORE;
  }

  if (score > 33) {
    return MEDIUM_SCORE;
  }

  return LOW_SCORE;
};
