import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  IQFileUploader,
  IQFileList,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import {
  BUSINESS_LOGO_SECTION,
  BUSINESS_LOGO_UPLOADED_FILES,
  deleteBusinessLogo,
  uploadBusinessLogo,
} from '../../data/business-details-slice';
import { BUSINESS_LOGO_FORM } from '../../account-constants';
import { trackSchedulerLogoUploadInitiated } from '../../../../../shared/analytics-utils';
import SectionCard from '../../../../../shared/SectionCard';
import AccountStateChangedHook from '../../../../../shared/stateChangedHook/AccountStateChangedHook';
import { toBase64 } from '../../../../../shared/app-utils';

const LogoUploaderContainer = styled('div')(({ theme, addPadding }) => ({
  paddingTop: addPadding ? theme.spacing(3) : 0,

  [theme.breakpoints.down('md')]: {
    margin: addPadding ? 'unset' : theme.spacing(0, -3),
  },
}));

const IMAGE_TYPES = ['.png', '.jpg', '.jpeg', '.gif'];
const MAX_FILE_SIZE_BYTES = 20_000_000;
const FORM_KEYS = [BUSINESS_LOGO_UPLOADED_FILES];

export default function BusinessLogoCard() {
  const dispatch = useDispatch();
  const [eventType, setEventType] = useState('');
  const uploadedFiles = useSelector(
    (state) => state.account.businessDetails[BUSINESS_LOGO_SECTION][BUSINESS_LOGO_UPLOADED_FILES]
  );
  const schema = yup.object().shape({
    [BUSINESS_LOGO_UPLOADED_FILES]: yup.array(),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      [BUSINESS_LOGO_UPLOADED_FILES]: uploadedFiles || [],
    },
  });

  const { setValue, watch } = methods;
  const logoFiles = watch(BUSINESS_LOGO_UPLOADED_FILES);

  const onSuccess = async (files) => {
    setValue(BUSINESS_LOGO_UPLOADED_FILES, [
      {
        id: files[0].path,
        fileName: files[0].path,
        url: URL.createObjectURL(files[0]),
        fileSize: files[0].size,
      },
    ]);
    dispatch(
      uploadBusinessLogo({ files, data: await toBase64(files[0]) }),
    );
  };

  const onDeleteFile = () => {
    dispatch(
      deleteBusinessLogo(),
    );
    setValue(BUSINESS_LOGO_UPLOADED_FILES, [], { shouldDirty: true });
  };

  const onDropCallback = (eventType) => {
    setEventType(eventType);
  };

  useEffect(() => {
    if (eventType && uploadedFiles.length > 0 && !!uploadedFiles[0].size) {
      trackSchedulerLogoUploadInitiated(eventType, uploadedFiles[0].size);
    }
  }, [eventType, uploadedFiles]);

  return (
    <SectionCard
      id='business-logo-card'
      title='Business Logo'
      description={`Enhance your profile by adding your business logo.`}
      showDividingLine={false}
    >
      <LogoUploaderContainer addPadding={!uploadedFiles.length}>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <form>
              {!uploadedFiles.length && (
                <IQFileUploader
                  name={BUSINESS_LOGO_UPLOADED_FILES}
                  documentTypes={[]}
                  videoTypes={[]}
                  imageTypes={IMAGE_TYPES}
                  label='Upload Your Logo'
                  recommendedImgText='64 x 64px'
                  maxFileSize={MAX_FILE_SIZE_BYTES}
                  onSuccess={onSuccess}
                  currentUploadedFiles={uploadedFiles.length}
                  toolTiptext='Match your logo with your business color'
                  supportingText=''
                  onDropCallback={onDropCallback}
                />
              )}
            </form>
            <AccountStateChangedHook
              formName={BUSINESS_LOGO_FORM}
              formKeys={FORM_KEYS}
            />
          </FormProvider>
        </ValidationProvider>
        {logoFiles && logoFiles.length > 0 && !uploadedFiles[0]?.url && (
          <IQFileList files={logoFiles} onDelete={onDeleteFile} />
        )}
        {uploadedFiles &&
          uploadedFiles.length > 0 &&
          !!uploadedFiles[0]?.url && (
            <IQFileList files={uploadedFiles} onDelete={onDeleteFile} />
          )}
      </LogoUploaderContainer>
    </SectionCard>
  );
}
