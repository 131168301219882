import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { IQSwitch } from '@gannettdigital/shared-react-components';
import { SwitchWrapperOverride } from '../settings-shared-components';

const StyledSwitchWrapper = styled(SwitchWrapperOverride)(() => ({
  maxWidth: 350,
  fontSize: 16,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: 'transperant',
  '& .MuiFormControlLabel-root .MuiTypography-root': {
    lineHeight: '27px',
  },
  '& .MuiSwitch-root': {
    marginLeft: 0,
  },
}));

const SameTimeSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  marginBottom: theme.spacing(1),
}));

const MachingHoursText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(-2),
}));

export default function SameTime({ sameTimeKey, sectionTitle, handleSameTimeChange }) {
  return (
    <Grid container item direction="column" gap="8px" xs={12} sm={12} md="auto">
      <SameTimeSectionTitle>{ sectionTitle || 'Matching Hours' }</SameTimeSectionTitle>
      <StyledSwitchWrapper>
        <IQSwitch name={sameTimeKey} size="sm" onChange={handleSameTimeChange} unboxed />
        <MachingHoursText>Selected days have matching hours</MachingHoursText>
      </StyledSwitchWrapper>
    </Grid>
  );
}

SameTime.propTypes = {
  sameTimeKey: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
  handleSameTimeChange: PropTypes.func.isRequired,
};
