import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import WizardComponent from './wizard/WizardComponent';
import { loadWizardConfig, setWizardSteps } from './data/configuration-slice';
import { buildWizardSteps } from './wizard/wizard-utils';
import { ALL_STEP_IDS } from './wizard/wizard-constants';
import {
  ActionIcons,
  ERROR_TYPE,
  BUNDLE_SLUG,
  INITIAL_BUNDLE_TIER,
} from '../../shared/app-constants';
import ActionCard from '../../shared/ActionCard';
import { ActionDescription, ViewInstructionsLink } from '../../shared/settings-shared-components';
import { hasBundleOrder } from '../../shared/app-utils';
import { hasExpiredTrial } from '../../shared/trials/trial-utils';

const {
  REACT_APP_BUY_ONLINE_UI_ROOT,
} = process.env;

export default function ConfigurationDialog() {
  const dispatch = useDispatch();
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);
  const { wizardSteps, loadedWizardConfig } = useSelector((state) => state.configuration);
  const wizardStatus= useSelector((state) => state.configuration.status);
  const features = useSelector((state) => state.configuration.widgetFeatures);
  const trialInfo = useSelector((state) => state.configuration.trialInfo);
  const trials = useSelector((state) => state.account.trials);
  const orders = useSelector((state) => state.appSettings.orders);
  const loadWizardConfigError = useSelector((state) => state.configuration.loadWizardConfigError);
  const startFreeTrialError = useSelector((state) => state.configuration.startFreeTrialError);

  const SpinnerWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }));

  const ErrorWrapper = styled('div')(({}) => ({
    width: '60%',
    margin: '2.5% auto',
    '& a': {
      color: 'black',
    },
  }));

  useEffect(() => {
    if (loadedSettings) {
      // Need a subscription to load the config, ensure that we
      // either have a bundle order or trial
      const hasActiveBundleOrder = hasBundleOrder(orders, true);
      if (hasActiveBundleOrder || (trialInfo && trialInfo.active)) {
        dispatch(loadWizardConfig());
      }
    }
  }, [loadedSettings, trialInfo]);

  useEffect(() => {
    if (loadedWizardConfig && features) {
      dispatch(setWizardSteps({
        steps: buildWizardSteps(ALL_STEP_IDS, features),
        features: features,
        wizardStatus: wizardStatus
      }));
    }
  }, [loadedWizardConfig, features]);

  const refreshPage = () => {
    window.location.reload();
  }

  const handleUpgrade = () => {
    const buyLink = `${REACT_APP_BUY_ONLINE_UI_ROOT}?product=${BUNDLE_SLUG}&tier=${INITIAL_BUNDLE_TIER}`;
    window.open(buyLink);
  }

  const renderErrorBanner = (msg, linkCopy, linkClick) => {
    return (
      <ErrorWrapper>
        <ActionCard
          id="wizard-load-error"
          type={ERROR_TYPE}
          icons={ActionIcons}
        >
          <ActionDescription>
            {msg}
          </ActionDescription>
          <ViewInstructionsLink
            onClick={linkClick}
          >
            {linkCopy}
          </ViewInstructionsLink>
        </ActionCard>
      </ErrorWrapper>
    );
  }

  if (trials && hasExpiredTrial(trials) && !hasBundleOrder(orders, true)) {
    return renderErrorBanner('Your free trial has expired', 'Upgrade', handleUpgrade);
  }

  if (!loadedWizardConfig) {
    return (
      <SpinnerWrapper>
        <IQLoadingSpinner />
      </SpinnerWrapper>
    );
  }

  if (loadWizardConfigError || startFreeTrialError) {
    const loadError = <>The onboarding wizard failed to load. Refresh the page to try again.  If the issue persists, please contact &nbsp;<a href="mailto:mysupport@localiq.com">mysupport@localiq.com</a>.</>;
    return renderErrorBanner(loadError, 'Try Again', refreshPage);
  }

  return (
    <WizardComponent allSteps={wizardSteps} />
  );
}

ConfigurationDialog.propTypes = {
};
