import SectionLayout from '../../../../shared/section/SectionLayout';
import WithDisabled from '../../../tools/components/WithDisabled';
import AvailabilityCard from '../cards/AvailabilityCard';
import { ReactComponent as AlertStatusIconSvg } from '../../../../images/alert-status-icon.svg';
import { RequiredFieldsContainer } from '../../../../shared/settings-shared-components';
import SchedulingLink from '../cards/SchedulingLink';
import AppointmentTypes from './AppointmentTypes';
import FutureBookingsAvailability from '../cards/FutureBookingsAvailability';
import BookingPolicyCustomization from '../cards/BookingPolicyCustomization';
import CalendarIntegrations from './CalendarIntegrations';
import SpecificDaysClosedCard from '../cards/SpecificDaysClosedCard';
import LocationWarningModal from '../cards/components/LocationWarningModal';
import SloganCard from '../cards/SloganCard';

export default function SchedulingSettings({ isDisabled }) {
  return (
    <div>
      <SectionLayout
        headerText="Scheduling"
        headerDescription="Our Scheduling tool allows you to accept, manage, and organize your appointments 24/7."
      />
      <RequiredFieldsContainer>
        <AlertStatusIconSvg />
        Fields marked with an asterisk (*) are required
      </RequiredFieldsContainer>
      <WithDisabled isDisabled={isDisabled}>
        <SchedulingLink />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <AvailabilityCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <SpecificDaysClosedCard />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <AppointmentTypes />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <FutureBookingsAvailability />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <BookingPolicyCustomization />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <CalendarIntegrations />
      </WithDisabled>
      <WithDisabled isDisabled={isDisabled}>
        <SloganCard />
      </WithDisabled>
      <LocationWarningModal />
    </div>
  );
}
