import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { tracker } from '@gannettdigital/localiq-dms-analytics-tracker';
import { resetCompleteAction, sendCaptureEmailAction, sendCaptureEmailFailure, sendCaptureEmailSuccess, triggerResetAction } from './account-slice';
import { BUSINESS_DETAILS_SECTION, BUSINESS_LOGO_SECTION, BUSINESS_LOGO_UPLOADED_FILES, saveBusinessDetailsAction, saveBusinessDetailsFailureAction, saveBusinessDetailsSuccessAction } from './business-details-slice';
import { loadInvoicesAction, loadInvoicesFailureAction, loadInvoicesSuccessAction } from './invoices-slice';
import {
  triggerCancelSubscriptionAction,
  triggerAcceptSalvageOfferAction,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  acceptSalvageOfferSuccess,
  acceptSalvageOfferFailure,
  loadChangePaymentCardsAction,
  loadChangePaymentCardsFailure,
  loadChangePaymentCardsSuccess,
  loadSubscriptionsAction,
  loadSubscriptionsFailureAction,
  loadSubscriptionsSuccess,
  saveChangePaymentAction,
  saveChangePaymentFailure,
  saveChangePaymentSuccess,
} from './subscriptions-slice';
import { loadCreditCardsAction, loadCreditCardsFailure, loadCreditCardsSuccess, getListofErrorsSuccess } from './credit-cards-slice';
import {
  saveEditCreditCard,
  saveEditCreditCardSuccess,
  editStripeError,
  deleteCardError,
  deleteCreditCardSuccess,
  getListofErrorsAction,
  deleteCreditCard,
} from './credit-cards-slice';
import { getSetupIntentAction, getSetupIntentSuccess, handleStripeError } from './stripe-slice';
import { trackSchedulerLogoDeleted, trackSubscriptionCCUpdated } from '../../../../shared/analytics-utils';
import { ORDERS_KEY, ORDER_ITEMS_KEY, PAYMENT_METHODS_KEY, SUBSCRIPTIONS_KEY } from '../../../../shared/app-constants';
import { constructQueryString } from '../../../../shared/app-utils';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const constructSaveBusinessBody = (businessDetails) => {
  const businessDetailsCopy = { ...businessDetails[BUSINESS_DETAILS_SECTION], ...businessDetails[BUSINESS_LOGO_SECTION] };
  delete businessDetailsCopy[BUSINESS_LOGO_UPLOADED_FILES];

  return JSON.stringify({
    affiliateOrder: true,
    ...businessDetailsCopy,
  })
};

const loadSubscriptionsEpic = (action$) => action$.pipe(
  filter(loadSubscriptionsAction.match),
  mergeMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}settings/load?${constructQueryString(
        'included',
        [ORDERS_KEY, ORDER_ITEMS_KEY, PAYMENT_METHODS_KEY, SUBSCRIPTIONS_KEY],
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => loadSubscriptionsSuccess(response.response)),
    catchError((error) => of(loadSubscriptionsFailureAction(error))),
  )),
);

const cancelSubscriptionEpic = (action$, state$) => action$.pipe(
  filter(triggerCancelSubscriptionAction.match),
  mergeMap(() => {
    const {
      selectedSubscriptionId: id,
      reasonToCancel,
      otherReasonToCancel,
      isSkipReasons,
    } = state$.value.account.subscriptions.cancelSubscriptionModal;
    const { email } = state$.value.appSettings.user;

    const bodyData = {
      cancelAtPeriodEnd: true,
      cancellationReason: reasonToCancel,
      otherCancellationReason: otherReasonToCancel,
    };

    if (isSkipReasons) {
      bodyData.cancellationReason = '';
      bodyData.otherCancellationReason = '';
    }

    return ajax(
      {
        url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/subscription/cancel/${id}/${email}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
        crossDomain: true,
        withCredentials: true,
      },
    ).pipe(
      mergeMap((response) => of(
        cancelSubscriptionSuccess(response.response),
        loadSubscriptionsAction(),
      )),
      catchError((error) => of(cancelSubscriptionFailure(error))),
    );
  }),
);

const applyCouponCodeToSubscriptionEpic = (action$, state$) => action$.pipe(
  filter(triggerAcceptSalvageOfferAction.match),
  mergeMap(() => {
    const { selectedSubscriptionId: id } = (
      state$.value.account.subscriptions.cancelSubscriptionModal
    );
    return ajax(
      {
        url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/subscription/retention-discount/${id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        crossDomain: true,
        withCredentials: true,
      },
    ).pipe(
      mergeMap((response) => of(
        acceptSalvageOfferSuccess(response.response),
        loadSubscriptionsAction(),
      )),
      catchError((error) => of(acceptSalvageOfferFailure(error))),
    );
  }),
);

const saveBusinessDetailsEpic = (action$, state$) => action$.pipe(
  filter(saveBusinessDetailsAction.match),
  mergeMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}def-business/with-location/current-user`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Mixpanel-ID': tracker.getTrackingId(),
      },
      body: constructSaveBusinessBody(state$.value.account.businessDetails),
      withCredentials: true,
      crossDomain: true,
    },
  ).pipe(
    map((response) => {
      if (state$.value.account.businessDetails.logoDeleteInitiated) {
        trackSchedulerLogoDeleted();
      }

      return saveBusinessDetailsSuccessAction(response.response)
    }),
    catchError((error) => of(saveBusinessDetailsFailureAction(error))),
  )),
);

const loadUserInvoiceDataEpic = (action$, state$) => action$.pipe(
  filter(loadInvoicesAction.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/invoices/${state$.value.appSettings.user.email}?size=${action.payload.totalDisplayPerPage}&page=${action.payload.currentPage}`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map(response => loadInvoicesSuccessAction(response.response)),
    catchError((error) => of(loadInvoicesFailureAction(error)))
  )),
);

const loadCreditCardsEpic = (action$, state$) => action$.pipe(
  filter(loadCreditCardsAction.match),
  mergeMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/load-payment-methods?allowExpired=true&email=${state$.value.appSettings.user.email}&returnSubscriptions=true&cardAlert=true`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true
    }
  ).pipe(
    map(response => loadCreditCardsSuccess(response.response)),
    catchError((error) => of(loadCreditCardsFailure(error)))
  ))
);

const loadCreditCardsInSubscriptionsEpic = (action$, state$) => action$.pipe(
  filter(loadChangePaymentCardsAction.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/load-payment-methods?allowExpired=false&email=${state$.value.appSettings.user.email}&returnSubscriptions=false`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true
    }
  ).pipe(
    map(response => loadChangePaymentCardsSuccess(response.response)),
    catchError((error) => of(loadChangePaymentCardsFailure(error)))
  ))
);

const changePaymentMethodEpic = (action$) => action$.pipe(
  filter(saveChangePaymentAction.match),
  mergeMap((action) => ajax(
    {
    url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/subscription/${action.payload.subscriptionId}/paymentMethod/${action.payload.paymentMethodId}`,
    method: 'PUT',
    headers: {
      'Mixpanel-ID': tracker.getTrackingId()
    },
    crossDomain: true,
    withCredentials: true,
    }
  ).pipe(
    mergeMap((response) => {
      const subscriptionId = response.response.id;
      trackSubscriptionCCUpdated(subscriptionId)
      return of(saveChangePaymentSuccess(response.response))
    }),
    catchError((error) => of(saveChangePaymentFailure(error)))
  )),
);

const saveChangePaymentSuccessEpic = (action$) => action$.pipe(
  filter(saveChangePaymentSuccess.match),
  map(() => loadSubscriptionsAction())
);

// TODO: Change in the future for more section to filter multiple success save actions
const saveSuccessEpic = (action$, state$) => action$.pipe(
  filter(saveBusinessDetailsSuccessAction.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.account.resetProductInfo,
    })
  )),
);

const resetActionEpic = (action$, state$) => action$.pipe(
  filter(triggerResetAction.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.account.resetProductInfo,
    })
  )),
);

const getSetupIntentEpic = (action$, state$) => action$.pipe(
  filter(getSetupIntentAction.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/setup-intent`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        email: state$.value.appSettings.user.email,
        idempotencyKey: action.payload.uuid
      },
      withCredentials: true,
      crossDomain: true,
    }
  ).pipe(
    map((response) => getSetupIntentSuccess(response.response)),
    catchError((error) => of(handleStripeError(error))),
  )
));

const editCreditCardEpic = (action$) => action$.pipe(
  filter(saveEditCreditCard.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/payment-methods/${action.payload.paymentId}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Mixpanel-ID': tracker.getTrackingId(),
      },
      body: {
        expMonth: action.payload.newCardMonth,
        expYear: action.payload.newCardYear
      },
      withCredentials: true,
      crossDomain: true,
    }
  )
    .pipe(
      map((response) => saveEditCreditCardSuccess(response.response)),
      catchError((error) => of(editStripeError(error))),
    )
));

const deleteCreditCardEpic = (action$) => action$.pipe(
  filter(deleteCreditCard.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}payments/payment-methods/${action.payload.paymentId}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Mixpanel-ID': tracker.getTrackingId(),
      },
      withCredentials: true,
      crossDomain: true,
    }
  )
    .pipe(
      map((response) => deleteCreditCardSuccess(response.response)),
      catchError((error) => of(deleteCardError(error))),
    )
  ));

const getCreditCardErrorListEpic = (action$, state$) => action$.pipe(
  filter(getListofErrorsAction.match),
  mergeMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}shared/alerts/settings/${state$.value.appSettings.user.email}/list/grouped?group=Invoice`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mixpanel-ID': tracker.getTrackingId(),
      },
      withCredentials: true,
      crossDomain: true,
    }
  )
    .pipe(
      map((response) => getListofErrorsSuccess(response.response)),
      catchError((error) => of(deleteCardError(error))),
    )
));

const sendCaptureCodeEmail = (action$, state$) => action$.pipe(
  filter(sendCaptureEmailAction.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/capture-code/send-instructions`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        firstName: state$.value.appSettings.user.firstName,
        email: action.payload.email || null,
        captureCode: action.payload.captureCode || null
      },
      withCredentials: true,
      crossDomain: true,
    }
  )
    .pipe(
      map((response) => sendCaptureEmailSuccess(response.response)),
      catchError((error) => of(sendCaptureEmailFailure(error))),
    )
));

export default [
  loadSubscriptionsEpic,
  saveBusinessDetailsEpic,
  saveSuccessEpic,
  resetActionEpic,
  loadUserInvoiceDataEpic,
  loadCreditCardsEpic,
  loadCreditCardsInSubscriptionsEpic,
  getSetupIntentEpic,
  editCreditCardEpic,
  deleteCreditCardEpic,
  changePaymentMethodEpic,
  saveChangePaymentSuccessEpic,
  getCreditCardErrorListEpic,
  sendCaptureCodeEmail,
  cancelSubscriptionEpic,
  applyCouponCodeToSubscriptionEpic,
];
