import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { dismissToggleModal } from '../../pages/products/bookings/data/bookings-slice';
import { Modal, Typography, Card, Grid } from '@mui/material';
import CaptureCodeEmailCard from '../../pages/products/account/components/cards/CaptureCodeEmailCard';
import { CloseModal } from '../../pages/products/account/components/account-shared-components';
import { ReactComponent as CloseIcon } from "../../images/close-x-icon.svg";
import { useState } from 'react';

const ModalBackground = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  margin: '0 auto',
  textAlign: 'center',
  width: 925,
  top: '10%',
  position: 'relative',
  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
  [theme.breakpoints.down('md')]: {
    width: 377,
  },
}));

const ModalDivider = styled('hr')(() => ({
  background: 'linear-gradient(90deg, #6432BE 0%, #DC4605 100%)',
  height: 1,
  width: '95%',
  margin: 'auto'
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: 'Unify Sans Semibold',
  textAlign: 'center',
  margin: '24px 0',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '14rem',
  [theme.breakpoints.down('md')]: {
    gap: 0,
  },
}));

export default function LocaliQHelpModal({ isOpen, captureCode, dismissModal }) {
  return (
    <Modal open={isOpen} onClose={dismissModal}>
      <ModalBackground>
        <ModalTitle> Get Installation Instructions 
        <CloseModal onClick={dismissModal}><CloseIcon /></CloseModal>
        </ModalTitle>
        <ModalDivider />
        <CaptureCodeEmailCard
          scriptID={captureCode}
          source="Config Wizard"
          modalMode
        />
      </ModalBackground>
    </Modal>
  );
}

LocaliQHelpModal.propTypes = {
  captureCode: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
