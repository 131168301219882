
import {
  useCallback, useEffect, useState, useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import AppointmentTypeCard from '../cards/AppointmentTypeCard';
import {
  APPOINTMENT_TYPES_FORM,
  defaultAppointment,
  WEB_CONFERENCE_PERSONAL_LINK_VALUE,
} from '../bookings-constants';
import BookingsCTA from '../../../../shared/BookingsCTA';
import SchedulerStateChangedHook from '../../../../shared/stateChangedHook/SchedulerStateChangedHook';
import {
  APPOINTMENT_BUFFER_AFTER_KEY,
  APPOINTMENT_BUFFER_BEFORE_KEY,
  APPOINTMENT_CUSTOM_QUESTIONS_KEY,
  APPOINTMENT_DESCRIPTION_KEY,
  APPOINTMENT_DURATION_KEY,
  APPOINTMENT_INFO_KEY,
  APPOINTMENT_LEAD_KEY,
  APPOINTMENT_LOCATION_KEY,
  APPOINTMENT_NAME_KEY,
  APPOINTMENT_TYPES_KEY,
  APPOINTMENT_WEB_CONFERENCE_TYPE_KEY,
  APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY,
  APPOINTMENT_WEB_CONFERENCE_LINK_KEY,
  deleteAppointmentType,
  updateAppointmentTypeField,
} from '../data/appointment-types-slice';
import { trackAddApptType } from '../../../../shared/analytics-utils';
import SectionCard from '../../../../shared/SectionCard';

const FORM_KEYS = [
  APPOINTMENT_TYPES_KEY,
];

export default function AppointmentTypes({ isDisabled }) {
  const [editingAppt, setEditingAppt] = useState(-1);
  const existingAppointmentTypes = useSelector(
    (state) => state.appointmentTypes[APPOINTMENT_TYPES_KEY],
  );
  const saveSuccess = useSelector(
    (state) => state.appointmentTypes[APPOINTMENT_INFO_KEY].saveSuccess,
  );
  const appointmentLinks = useSelector(((state) => state.bookings.links.appointmentTypes));
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const appointmentTypeSchema = yup.object().shape({
    [APPOINTMENT_NAME_KEY]: yup.string().required('Appointment Type Name is required'),
    [APPOINTMENT_DURATION_KEY]: yup.string(),
    [APPOINTMENT_LOCATION_KEY]: yup.string(),
    [APPOINTMENT_DESCRIPTION_KEY]: yup.string(),
    [APPOINTMENT_BUFFER_BEFORE_KEY]: yup.number(),
    [APPOINTMENT_BUFFER_AFTER_KEY]: yup.number(),
    [APPOINTMENT_LEAD_KEY]: yup.number(),
    [APPOINTMENT_CUSTOM_QUESTIONS_KEY]: yup.array(),
    [APPOINTMENT_WEB_CONFERENCE_TYPE_KEY]: yup.string(),
    [APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY]: yup.string()
      .when([APPOINTMENT_LOCATION_KEY, APPOINTMENT_WEB_CONFERENCE_TYPE_KEY], {
        is: (locationType, virtualMeetingType) => (locationType === 'WEB_CONFERENCE' && virtualMeetingType === WEB_CONFERENCE_PERSONAL_LINK_VALUE),
        then: yup.string().required('Platform is required'),
      }),
    [APPOINTMENT_WEB_CONFERENCE_LINK_KEY]: yup.string()
      .when([APPOINTMENT_LOCATION_KEY, APPOINTMENT_WEB_CONFERENCE_TYPE_KEY], {
        is: (locationType, virtualMeetingType) => (locationType === 'WEB_CONFERENCE' && virtualMeetingType === WEB_CONFERENCE_PERSONAL_LINK_VALUE),
        then: yup.string().required('Meeting Link is required')
          .test('link-input', 'Please include a valid web conferencing link.', (input) => input && input.includes('.')),
      }),
  });

  const schema = yup.object().shape({
    [APPOINTMENT_TYPES_KEY]: yup.array().of(appointmentTypeSchema),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [APPOINTMENT_TYPES_KEY]: existingAppointmentTypes,
    },
  });

  const { control, reset } = methods;

  const {
    fields: appointmentTypes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'appointmentTypes',
  });

  const addApptType = useCallback(() => {
    append(defaultAppointment);
    // When this fires, the length isn't updated yet
    setEditingAppt(appointmentTypes.length);
    pageRef.current.scrollIntoView();

    // Track adding a type
    trackAddApptType();

    // Adds the default appointment type when adding the first field
    dispatch(updateAppointmentTypeField({
      fieldName: APPOINTMENT_NAME_KEY,
      fieldValue: defaultAppointment.name,
      typeIndex: appointmentTypes.length,
    }));
  });

  const handleDeleteApptType = useCallback((ind) => {
    remove(ind);
    dispatch(deleteAppointmentType({
      appointmentIndex: ind,
    }));
  });

  const handleEdit = useCallback((ind) => {
    setEditingAppt(ind);
  }, [setEditingAppt]);

  const handleClose = useCallback(() => {
    setEditingAppt(-1);
  }, []);

  useEffect(() => {
    // If we have a single entry, open to edit on load
    if (Array.isArray(existingAppointmentTypes) && existingAppointmentTypes.length === 1) {
      setEditingAppt(0);
    }
  }, [setEditingAppt, existingAppointmentTypes]);

  useEffect(() => {
    if (saveSuccess) {
      setEditingAppt(-1);
      // Manual reset now that the "defaultValues" aka saved values have been changed
      reset({ [APPOINTMENT_TYPES_KEY]: existingAppointmentTypes });
    }
  }, [saveSuccess]);

  return (
    <SectionCard
      title="Appointment Types"
      id="appointment-type-card"
      showDividingLine={false}
    >
      <div style={{ paddingTop: '16px' }} ref={pageRef}>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            {appointmentTypes.map((appointment, index) => {
              const fieldName = `${APPOINTMENT_TYPES_KEY}[${index}]`;
              return (
                <div key={appointment.id}>
                  <AppointmentTypeCard
                    fieldNameRoot={fieldName}
                    cardIndex={index}
                    isEditing={editingAppt === index}
                    isLast={index === appointmentTypes.length - 1}
                    allowDelete={appointmentTypes.length > 1}
                    onEditCallback={() => handleEdit(index)}
                    onCloseCallback={() => handleClose()}
                    onDeleteCallback={() => handleDeleteApptType(index)}
                    appointmentLinks={appointmentLinks}
                    isDisabled={isDisabled}
                  />
                </div>
              );
            })}
            {appointmentTypes.length < 3 && (
              <BookingsCTA
                text="Add Appointment Type"
                startIcon={<AddIcon />}
                variant="outlined"
                disabled={isDisabled}
                onClickHandler={addApptType}
                style={{ marginTop: '16px' }}
              />
            )}
            <SchedulerStateChangedHook
              formName={APPOINTMENT_TYPES_FORM}
              formKeys={FORM_KEYS}
            />
          </FormProvider>
        </ValidationProvider>
      </div>
    </SectionCard>
  );
}
