import { useCallback } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQFormTextArea, ValidationProvider, IQTheme } from '@gannettdigital/shared-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, useTheme } from '@mui/material';
import { CharacterCount } from '../../../../shared/settings-shared-components';
import SchedulerStateChangedHook from '../../../../shared/stateChangedHook/SchedulerStateChangedHook';
import { POLICY_ADVANCED_SETTINGS_FORM, DEFAULT_POLICY_TEXT } from '../bookings-constants';
import {
  ADVANCED_SETTINGS_SECTION,
  POLICY_TEXT_NAME_KEY,
  updateAdvancedSettingsField,
} from '../data/advanced-settings-slice';
import SectionCard from '../../../../shared/SectionCard';

const FORM_KEYS = [POLICY_TEXT_NAME_KEY];
const POLICY_TEXT_MAX_LENGTH = 700;
const CARD_TITLE = 'Appointment Policy Customization';

export default function BookingPolicyCustomization() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { policyText } = useSelector((state) => state[ADVANCED_SETTINGS_SECTION][ADVANCED_SETTINGS_SECTION]);

  const schema = yup.object().shape({
    [POLICY_TEXT_NAME_KEY]: yup.string().required('Policy Text is required'),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [POLICY_TEXT_NAME_KEY]: policyText || DEFAULT_POLICY_TEXT,
    },
  });

  const handleFieldChange = useCallback((fieldKey, newValue) => {
    dispatch(updateAdvancedSettingsField({
      fieldName: fieldKey,
      fieldValue: newValue,
    }));
  }, [dispatch, updateAdvancedSettingsField]);

  const { watch } = methods;
  const policyTextFormVal = watch(POLICY_TEXT_NAME_KEY);
  const policyTextCharCount = (policyTextFormVal ? policyTextFormVal.length : 0);

  return (
    <SectionCard
      id="scheduler-policy-card"
      title={CARD_TITLE}
      tooltipText="Explain your cancellation, rescheduling, or other policy guidelines."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form>
            <Grid container marginTop={theme.spacing(3)}>
              <Grid item xs={12}>
                <IQFormTextArea
                  id="booking_policy_customization"
                  name={POLICY_TEXT_NAME_KEY}
                  labelText="Policy Text *"
                  fontLabelWeight="600"
                  maxLength={POLICY_TEXT_MAX_LENGTH}
                  fullWidth
                  theme={IQTheme}
                  rowCount={5}
                  onBlur={(event) => handleFieldChange(POLICY_TEXT_NAME_KEY, event.target.value)}
                />
                <CharacterCount>
                  {policyTextCharCount}/{POLICY_TEXT_MAX_LENGTH}
                </CharacterCount>
              </Grid>
            </Grid>
          </form>
          <SchedulerStateChangedHook
            formName={POLICY_ADVANCED_SETTINGS_FORM}
            formKeys={FORM_KEYS}
            subsectionKey={ADVANCED_SETTINGS_SECTION}
          />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
};
