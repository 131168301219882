import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SaveCancelWidget from '../../shared/saveCancel/SaveCancelWidget';

/**
 * Save component present at the top level of tools.
 * Will be responsible for saving whatever product is visible currently.
 */
export default function ToolsSaveWidget({
  handleSave,
  handleSaveComplete,
  storeRoot,
}) {
  const currentScreen = useSelector((state) => state.navigationSettings.currentScreen);
  const {
    isDirty,
    isInvalid,
    isSaving,
    showChangesSaved,
  } = useSelector((state) => state[storeRoot] || {
    isDirty: false,
    isInvalid: false,
    isSaving: false,
    showChangesSaved: false,
  });

  const wrapSave = () => {
    handleSave(currentScreen);
  };

  const wrapComplete = () => {
    handleSaveComplete(currentScreen);
  };

  return (
    <SaveCancelWidget
      isInvalid={isInvalid}
      isDirty={isDirty}
      isSaving={isSaving}
      showChangesSaved={showChangesSaved}
      onSave={wrapSave}
      onSaveComplete={wrapComplete}
      id="tools"
    />
  );
}

ToolsSaveWidget.propTypes = {
  storeRoot: PropTypes.string,
  handleSave: PropTypes.func,
  handleSaveComplete: PropTypes.func,
};
