import { of } from 'rxjs';
import {
  catchError, filter, map, mergeMap, switchMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { SAVE_CONFIGURATION } from '../call-settings-config';
import { constructBody } from '../../../../shared/app-utils';
import {
  resetCallRecorderCompleteAction,
  saveCallRecorderFailure,
  toggleCallRecorderStatus,
  toggleCallRecorderStatusFailure,
  toggleCallRecorderStatusSuccess,
} from './call-settings-slice';
import { CALL_TRACKER_SLUG } from '../../../../shared/app-constants';
import { saveCallSettings, saveCallSettingsSuccess } from './call-settings-actions';
import { CALL_VERIFICATION_ENABLED_KEY, CALL_VERIFICATION_PROMPT_KEY } from '../call-settings-constants';
import { setEnabledProducts, trackActivatedProduct } from '../../../../shared/analytics-utils';
import { triggerResetAction } from '../../../../redux/shared-actions';
import { generateForwardingNumbers, generateForwardingNumbersFailure, generateForwardingNumbersSuccess } from './general-settings-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const SAVE_KEYS = Object.keys(SAVE_CONFIGURATION);

const generateTrackingNumbersEpic = (action$, state$) => action$.pipe(
  filter(generateForwardingNumbers.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}call-tracking/phone/provision`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        advertiserId: state$.value.callSettings.advertiserId,
        originalNumber: action.payload.originalNumber,
        callVerify: state$.value.callSettings.generalSettings[CALL_VERIFICATION_ENABLED_KEY],
        businessName: state$.value.callSettings.generalSettings[CALL_VERIFICATION_PROMPT_KEY],
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => generateForwardingNumbersSuccess(response.response)),
    catchError((error) => (
      of(generateForwardingNumbersFailure(error))
    )),
  )),
);

/**
 * Wrap the general body construction with call recorder specific keys and config
 * @param {Object} stateObj
 * @returns Object
 */
const constructCallRecorderBody = (stateObj) => (
  constructBody(
    SAVE_KEYS,
    SAVE_CONFIGURATION,
    { ...stateObj.callSettings, businessAttributes: stateObj.businessAttributes },
  )
);

const saveCallRecorderEpic = (action$, state$) => action$.pipe(
  filter(saveCallSettings.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${CALL_TRACKER_SLUG}/${action.payload.tier}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: Object.assign(constructCallRecorderBody(state$.value), {
        isActive: action.payload.isActive,
      }),
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    mergeMap((response) => {
      const actionList = [];
      actionList.push(saveCallSettingsSuccess(response.response));

      if (action.payload.activate) {
        actionList.push(toggleCallRecorderStatus({
          isActive: true,
        }));
      }

      return actionList;
    }),
    catchError((error) => (
      of(saveCallRecorderFailure(error))
    )),
  )),
);

// Repack the reset action with a piece of state
const resetActionEpic = (action$, state$) => action$.pipe(
  filter(triggerResetAction.match),
  map(() => (
    resetCallRecorderCompleteAction({
      resetData: state$.value.callSettings.resetProductInfo,
    })
  )),
);

const saveCallSettingsSuccessEpic = (action$, state$) => action$.pipe(
  filter(saveCallSettingsSuccess.match),
  map(() => (
    resetCallRecorderCompleteAction({
      resetData: state$.value.callSettings.resetProductInfo,
    })
  )),
);

const toggleCallRecorderStatusEpic = (action$, state$) => action$.pipe(
  filter(toggleCallRecorderStatus.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${CALL_TRACKER_SLUG}/${state$.value.callSettings.tier}/active/${action.payload.isActive}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => {
      if (action.payload.isActive) {
        trackActivatedProduct(
          CALL_TRACKER_SLUG,
          state$.value.callSettings.tier,
          state$.value.callSettings.firstActivationDate,
        );
      }
      setEnabledProducts(
        CALL_TRACKER_SLUG,
        state$.value.callSettings.tier,
        action.payload.isActive,
      );
      return toggleCallRecorderStatusSuccess(response.response);
    }),
    catchError((error) => (
      of(toggleCallRecorderStatusFailure(error))
    )),
  )),
);

export default [
  generateTrackingNumbersEpic,
  saveCallRecorderEpic,
  resetActionEpic,

  saveCallSettingsSuccessEpic,
  toggleCallRecorderStatusEpic,
];
