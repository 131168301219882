import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import { IQFormInput, IQFormPhoneInput, IQFormSelect, IQInputWebURL, SectionCard, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as RequiredSvg } from '../../assets/required-fields-icon.svg';
import {
  BUSINESS_CITY_KEY,
  BUSINESS_COUNTRY_KEY,
  BUSINESS_DETAILS_SECTION,
  BUSINESS_EMAIL_KEY,
  BUSINESS_NAME_KEY,
  BUSINESS_PHONE_NUMBER_KEY,
  BUSINESS_POSTAL_CODE_KEY,
  BUSINESS_STATE_KEY,
  BUSINESS_STREET_ADDRESS_ONE_KEY,
  BUSINESS_STREET_ADDRESS_TWO_KEY,
  BUSINESS_WEBSITE_URL_KEY, 
  updateBusinessDetails, 
  updateBusinessDetailsField} from '../../data/business-details-slice';
import { WEBSITE_URL_REGEX, isValidUSPhoneNumberLength, PHONE_NUMBER_REGEX } from '../../../../../shared/validation-utils';
import { DividerRow, DividingLine, GridRow, PhoneRow } from '../../../../../shared/settings-shared-components';
import AutoCompleteAddress from '../../../../../shared/autoComplete/AutoCompleteAddress';
import AccountStateChangedHook from '../../../../../shared/stateChangedHook/AccountStateChangedHook';
import { BUSINESS_DETAILS_FORM } from '../../account-constants';
import { selectCountryOptions, US_KEY } from '../../../../../shared/country-constants';
import { BUSINESS_PHONE_ID } from '../../../../../shared/app-constants';


const SectionCardDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: `-${theme.spacing(1.2)}`,
  marginBottom: theme.spacing(3),
  gap: theme.spacing(1.1),
  fontSize: '1em',
}));

const WebsiteUrlLowerText = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  fontWeight: 400,
  fontSize: 12,
}));

const FORM_KEYS = [
  BUSINESS_NAME_KEY,
  BUSINESS_EMAIL_KEY,
  BUSINESS_WEBSITE_URL_KEY,
  BUSINESS_PHONE_NUMBER_KEY,
  BUSINESS_STREET_ADDRESS_ONE_KEY,
  BUSINESS_STREET_ADDRESS_TWO_KEY,
  BUSINESS_CITY_KEY,
  BUSINESS_STATE_KEY,
  BUSINESS_COUNTRY_KEY,
  BUSINESS_POSTAL_CODE_KEY,
];

export default function BusinessDetailsCard() {
  const dispatch = useDispatch();
  const addressOptions = useSelector((state) => state.addressAutocomplete.addressOptions);
  const businessDataDefaults = useSelector((state) => state.account.businessDetails[BUSINESS_DETAILS_SECTION]);
  const currentUserEmail = useSelector((state) => state.appSettings?.user?.email);
  const schema = yup.object().shape({
    [BUSINESS_NAME_KEY]: yup.string().required('Business Name is required'),
    [BUSINESS_EMAIL_KEY]: yup.string(),
    [BUSINESS_WEBSITE_URL_KEY]: yup.string().required('Website URL is required').matches(WEBSITE_URL_REGEX, 'Business Website URL is invalid'),
    [BUSINESS_PHONE_NUMBER_KEY]: yup.lazy((value) => (value
      ? yup.string().test('len', 'Business Phone Number must be 10 digits long', (val) => {
        if (!val) {
          return false;
        }
        return isValidUSPhoneNumberLength(val);
      }).matches(PHONE_NUMBER_REGEX, 'Business Phone Number is invalid')
      : yup.mixed().notRequired())),
    [BUSINESS_STREET_ADDRESS_ONE_KEY]: yup.string(),
    [BUSINESS_STREET_ADDRESS_TWO_KEY]: yup.string(),
    [BUSINESS_CITY_KEY]: yup.string(),
    [BUSINESS_STATE_KEY]: yup.string(),
    [BUSINESS_COUNTRY_KEY]: yup.string(),
    [BUSINESS_POSTAL_CODE_KEY]: yup.string().when(BUSINESS_COUNTRY_KEY, {
      is: (val) => val === US_KEY,
      then: yup.string().required('Zip code is required').typeError('Zip code is required').matches(/^\d{5}$/, 'Zip code must be 5 digits'),
    }),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [BUSINESS_NAME_KEY]: businessDataDefaults[BUSINESS_NAME_KEY] || '',
      [BUSINESS_EMAIL_KEY]: currentUserEmail || '',
      [BUSINESS_WEBSITE_URL_KEY]: businessDataDefaults[BUSINESS_WEBSITE_URL_KEY] || '',
      [BUSINESS_PHONE_NUMBER_KEY]: businessDataDefaults[BUSINESS_PHONE_NUMBER_KEY] || null,
      [BUSINESS_STREET_ADDRESS_ONE_KEY]: businessDataDefaults[BUSINESS_STREET_ADDRESS_ONE_KEY] || '',
      [BUSINESS_STREET_ADDRESS_TWO_KEY]: businessDataDefaults[BUSINESS_STREET_ADDRESS_TWO_KEY] || '',
      [BUSINESS_CITY_KEY]: businessDataDefaults[BUSINESS_CITY_KEY] || '',
      [BUSINESS_STATE_KEY]: businessDataDefaults[BUSINESS_STATE_KEY] || '',
      [BUSINESS_COUNTRY_KEY]: businessDataDefaults[BUSINESS_COUNTRY_KEY] || '',
      [BUSINESS_POSTAL_CODE_KEY]: businessDataDefaults[BUSINESS_POSTAL_CODE_KEY] || '',
    }))
  });

  const { setValue } = methods;

  const handleFieldChange = (fieldName, fieldValue) => {
    dispatch(
      updateBusinessDetailsField({ fieldName, fieldValue })
    )
  };

  const handleAutocompleteSelected = (data) => {
    if (data) {
      const {
        postalCode,
        place,
        country,
        region,
        address,
        address2,
      } = data;
      setValue(BUSINESS_POSTAL_CODE_KEY, postalCode);
      setValue(BUSINESS_CITY_KEY, place);
      setValue(BUSINESS_COUNTRY_KEY, country);
      setValue(BUSINESS_STATE_KEY, country === 'USA' ? region : '');
      setValue(BUSINESS_STREET_ADDRESS_ONE_KEY, address);
      setValue(BUSINESS_STREET_ADDRESS_TWO_KEY, address2);
    
      dispatch(updateBusinessDetails(
        {
          [BUSINESS_STREET_ADDRESS_ONE_KEY]: address,
          [BUSINESS_STREET_ADDRESS_TWO_KEY]: address2 || '',
          [BUSINESS_CITY_KEY]: place || '',
          [BUSINESS_STATE_KEY]: region || '',
          [BUSINESS_POSTAL_CODE_KEY]: postalCode || '',
          [BUSINESS_COUNTRY_KEY]: country || '',
        },
      ));
    }
  };


  return (
    <SectionCard 
        description={
        <SectionCardDescription>
          <RequiredSvg />
          <Typography variant='body2'>Fields marked with an asterisk (*) are required.</Typography>
        </SectionCardDescription>}
        showDividingLine={false}>
      <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <form>
              <Grid container spacing={2}>
                <GridRow item sm={6} xs={12}>
                    <IQFormInput
                      id='business-name-input'
                      name={BUSINESS_NAME_KEY}
                      labelText='Business Name *'
                      fontLabelWeight='bold'
                      fullWidth
                      onBlur={(e) => handleFieldChange(BUSINESS_NAME_KEY, e.target.value)}
                    />
                </GridRow>
                <GridRow item sm={6} xs={12}>
                  <IQFormInput
                    id='business-email'
                    name={BUSINESS_EMAIL_KEY}
                    labelText='Email Address *'
                    disabled
                    fullWidth={false} />
                </GridRow>
                <DividerRow paddingBottom={5} item xs={12} />
                <GridRow item sm={6} xs={12}>
                  <IQInputWebURL
                    id='business-website-url'
                    name={BUSINESS_WEBSITE_URL_KEY}
                    label='Website URL *'
                    fullWidth
                    required
                    onBlur={(e) => handleFieldChange(BUSINESS_WEBSITE_URL_KEY, e.target.value)}
                  />
                  <WebsiteUrlLowerText variant='body2'>Your Website Grader+ will be updated to use this URL</WebsiteUrlLowerText>
                </GridRow>
                <PhoneRow marginTop={-0.5} item sm={6} xs={12}>
                  <IQFormPhoneInput
                      id={BUSINESS_PHONE_ID}
                      name={BUSINESS_PHONE_NUMBER_KEY}
                      labelText='Phone Number'
                      onChange={(newValue) => handleFieldChange(BUSINESS_PHONE_NUMBER_KEY, newValue)}
                      fullWidth={false}
                  />
                </PhoneRow>
                <DividerRow item xs={12}>
                  <DividingLine allowMobile />
                </DividerRow>
                <GridRow item sm={6} xs={12}>
                  <AutoCompleteAddress
                    id='business-street-address-1'
                    name={BUSINESS_STREET_ADDRESS_ONE_KEY}
                    labelText='Street Address 1'
                    onAutoCompleteSelected={handleAutocompleteSelected}
                    addressOptions={addressOptions}
                    onAddressChange={(newVal) => handleFieldChange(BUSINESS_STREET_ADDRESS_ONE_KEY, newVal)}
                  />
                </GridRow>
                <GridRow marginTop={0.5} item sm={6} xs={12}>
                  <IQFormInput
                    id='business-street-address-2'
                    name={BUSINESS_STREET_ADDRESS_TWO_KEY}
                    labelText='Street Address 2'
                    onBlur={(event) => handleFieldChange(BUSINESS_STREET_ADDRESS_TWO_KEY, event.target.value)}
                  />
                </GridRow>
                <DividerRow item xs={12} />
                <GridRow item sm={6} xs={12}>
                  <IQFormInput
                    id='business-city'
                    name={BUSINESS_CITY_KEY}
                    labelText='City'
                    onBlur={(e) => handleFieldChange(BUSINESS_CITY_KEY, e.target.value)}
                  />
                </GridRow>
                <GridRow item sm={6} xs={12}>
                  <IQFormInput
                    id='business-state'
                    name={BUSINESS_STATE_KEY}
                    labelText='State/Province'
                    onBlur={(e) => handleFieldChange(BUSINESS_STATE_KEY, e.target.value)}
                  />
                </GridRow>
                <DividerRow item xs={12} />
                <GridRow item sm={6} xs={12}>
                  <IQFormSelect
                     id='business-country'
                     name={BUSINESS_COUNTRY_KEY}
                     labelText='Country'
                     items={selectCountryOptions}
                     onChange={(e) => handleFieldChange(BUSINESS_COUNTRY_KEY, e.target.value)}
                  />
                </GridRow>
                <GridRow item sm={3} xs={12}>
                  <IQFormInput
                    id='business-postal-code'
                    name={BUSINESS_POSTAL_CODE_KEY}
                    labelText='Postal Code'
                    onBlur={(e) => handleFieldChange(BUSINESS_POSTAL_CODE_KEY, e.target.value)}
                  />
                </GridRow>
              </Grid>
            </form>
            <AccountStateChangedHook
              formName={BUSINESS_DETAILS_FORM}
              formKeys={FORM_KEYS}
              subsectionKey={BUSINESS_DETAILS_SECTION}
            />
          </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
}