import { Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CopyInput from '../../../../../shared/CopyInput';
import SectionCard from '../../../../../shared/SectionCard';

const DividerLine = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  marginBottom: '24px',
}));

const StepTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontFamily: 'Unify Sans Semibold',
}));

const StepBody = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  letterSpacing: ".2px",
  paddingTop: '26px'
}));

export default function OtherCaptureCodeCard({ captureCodeText, stepTitle, stepText, websiteType, hasSectionCard = true, source, hasNoCaptureCode }) {
  if (!hasSectionCard) {
    return (
      <>
        <StepTitle> 1. {stepTitle} </StepTitle>
        <StepBody style={{ marginBottom: '24px' }}> {stepText} </StepBody>
        <DividerLine />
        <StepTitle> 2. Add your unique Tracking ID</StepTitle>
        <StepBody>
          Configure your module by copying and pasting your unique Tracking ID.
          <CopyInput
            displayText={captureCodeText}
            websiteType={websiteType}
            source={source}
            hasNoCaptureCode={hasNoCaptureCode}
          />
        </StepBody>
      </>
    )
  }

  return (
    <SectionCard showDividingLine={false}>
      <StepTitle> 1. {stepTitle} </StepTitle>
      <StepBody style={{ marginBottom: '24px' }}> {stepText} </StepBody>
      <DividerLine />
      <StepTitle> 2. Add your unique Tracking ID</StepTitle>
      <StepBody>
        Configure your module by copying and pasting your unique Tracking ID.
        <CopyInput
          displayText={captureCodeText}
          websiteType={websiteType}
          source="Settings"
        />
      </StepBody>
    </SectionCard>
  )
}

OtherCaptureCodeCard.propTypes = {
  captureCodeText: PropTypes.string.isRequired,
  source: PropTypes.string,
};
