import * as yup from 'yup';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';
import {
  Adornment,
  IQFormInput,
  IQSwitch,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CALL_VERIFICATION_ENABLED_KEY, CALL_VERIFICATION_PROMPT_KEY, CALL_VERIFICATION_FORM } from '../../call-settings-constants';
import { updateGeneralSettingsField } from '../../data/general-settings-slice';
import CallSettingsStateChangedHook from '../../../../../shared/stateChangedHook/CallSettingsStateChangedHook';
import { Bolded, DescriptionText, HeaderText } from '../../../../../shared/settings-shared-components';
import { ReactComponent as VerifiedIcon } from '../../assets/verified-icon.svg';

const InputWrapper = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '& .MuiFormGroup-root > .MuiFormControlLabel-root': {
    padding: 0,
    border: 'none',
    maxWidth: 300,
    paddingBottom: theme.spacing(3),
    '& .MuiTypography-root': {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .MuiSwitch-sizeSmall': {
        marginLeft: 0,
      },
      '& .MuiSwitch-switchBase': {
        padding: '1.5px',
      },
    },
  },
  '& .MuiFormControl-root': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& label': {
      fontWeight: 600,
    },
    '& div': {
      maxWidth: 300,
      minWidth: 300,
      [theme.breakpoints.down('md')]: {
        minWidth: 'auto',
      },
    },
  },
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 0),
  '& .verification-description': {
    paddingTop: theme.spacing(3),
  },
}));

const generateLabel = (enabled) => (
  <span>
    Call Verification is&nbsp;
    <Bolded>{enabled ? 'Enabled' : 'Disabled'}</Bolded>
  </span>
);

const FORM_KEYS = [CALL_VERIFICATION_ENABLED_KEY, CALL_VERIFICATION_PROMPT_KEY];
const VERIFICATION_DESC = `Call verification minimizes the number of wrong numbers and robocalls your business receives. When this is 'ENABLED' calls will be answered with a recording of your business name before transferring to your forwarding number.`;

export default function CallVerificationComponent() {
  const dispatch = useDispatch();
  const callPrompt = useSelector(
    (state) => state.callSettings.generalSettings[CALL_VERIFICATION_PROMPT_KEY],
  );
  const rawVerifyValue = useSelector(
    (state) => state.callSettings.generalSettings[CALL_VERIFICATION_ENABLED_KEY],
  );
  const hasVerify = rawVerifyValue === null ? true : rawVerifyValue;
  const callVerificationLabel = generateLabel(hasVerify);

  const schema = yup.object().shape({
    [CALL_VERIFICATION_ENABLED_KEY]: yup.bool(),
    [CALL_VERIFICATION_PROMPT_KEY]: yup.string(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [CALL_VERIFICATION_ENABLED_KEY]: hasVerify,
      [CALL_VERIFICATION_PROMPT_KEY]: callPrompt,
    })),
  });

  const { control } = methods;
  const verifyValue = useWatch({
    control,
    name: CALL_VERIFICATION_ENABLED_KEY,
    defaultValue: hasVerify,
  });

  const handleSwitchChange = (newValue) => {
    dispatch(updateGeneralSettingsField({
      fieldName: CALL_VERIFICATION_ENABLED_KEY,
      fieldValue: newValue,
    }));
  };

  const handlePromptChange = (event) => {
    dispatch(updateGeneralSettingsField({
      fieldName: CALL_VERIFICATION_PROMPT_KEY,
      fieldValue: event.target.value,
    }));
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <HeaderWrapper>
          <HeaderText component="div">Call Verification</HeaderText>
          <DescriptionText component="div" className="verification-description">{VERIFICATION_DESC}</DescriptionText>
        </HeaderWrapper>
        <InputWrapper>
          <IQSwitch
            id="callRecorder-activation-toggle"
            name={CALL_VERIFICATION_ENABLED_KEY}
            label={callVerificationLabel}
            onChange={handleSwitchChange}
            size={isMobile ? 'sm' : 'md'}
          />
          {verifyValue && (
            <IQFormInput
              id="callRecorder-businessName"
              labelText="Business Name"
              name={CALL_VERIFICATION_PROMPT_KEY}
              onBlur={handlePromptChange}
              maxLength={64}
              adornmentIcon={<VerifiedIcon />}
              adornmentPosition={Adornment.START_ADORNMENT}
            />
          )}
        </InputWrapper>
        <CallSettingsStateChangedHook
          formName={CALL_VERIFICATION_FORM}
          formKeys={FORM_KEYS}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
