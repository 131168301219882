import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

const SchedulerContentSkeletonHeader = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '> span': {
    borderRadius: '4px',
  },
  '> div': {
    width: '35%',
  }
}));

const SchedulerContentSkeletonCard = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  border: '2px solid rgba(0, 0, 0, 0.04)',
  borderRadius: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '> span': {
    borderRadius: '4px',
  },
  '.skeleton-card-header': {
    marginBottom: theme.spacing(2),
  },
}));

export default function AppointmentListSkeleton() {
  const cards = [...Array(4).keys()];

  return (
    <>
      <SchedulerContentSkeletonHeader>
        <Skeleton variant="text" width="25%" height={40} />
        <Skeleton variant="text" width="75%" height={25} />
      </SchedulerContentSkeletonHeader>
      <div style={{flex: 1}}>
        {
          cards.map((key) => (
            <SchedulerContentSkeletonCard key={key}>
              <div className="skeleton-card-header">
                <Skeleton variant="text" width={258} height={25} />
                <Skeleton variant="text" width={129} height={27} />
              </div>
              <Skeleton variant="text" width="100%" height={19} />
              <Skeleton variant="text" width="100%" height={19} />
              <Skeleton variant="text" width="50%" height={19} />
            </SchedulerContentSkeletonCard>
          ))
        }
      </div>
    </>
  )
}
