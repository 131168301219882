import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { closeWarningModal, triggerResetAction } from '../../../../redux/shared-actions';
import {
  FREE_TIER,
  SCHEDULER_SLUG,
} from '../../../../shared/app-constants';
import { loadSettingsDataSuccess } from '../../../../shared/app-slice';
import { convertAppointmentDate, convertAppointmentStatus, convertDateStringToDate } from '../../../../shared/app-utils';
import { BUSINESS_DETAILS_SECTION } from './general-settings-slice';
import { setWizardStatusSuccess } from '../../../configuration/data/configuration-slice';
import { loadUpcomingAppointmentsSuccess } from '../../../dashboard/data/dashboard-slice';
import { updateFeatures } from '../../settings/data/widgetSettings-slice';
import parseNewLine from '../../../../shared/parse-new-line-utils';

export const saveBookingsAction = createAction('bookings/save');
export const toggleBookingsStatusAction = createAction('bookings/toggleBookingsStatus');
export const loadSchedulerAppointmentsAction = createAction('scheduler/loadSchedulerAppointments');
export const loadSchedulerAllAppointmentsAction = createAction('scheduler/loadSchedulerAllAppointments');
export const loadSchedulerLinksAction = createAction('scheduler/loadSchedulerLinks');
export const sharedAlertsFailure = createAction('scheduler/sharedAlertsFailure');
export const loadedBookingData = createAction('scheduler/loadedBookingData');

const handleResponse = (product, existingResetInfo) => {
  const productInfo = { ...product };

  if (productInfo.business) {
    const mergedData = Object.assign(
      productInfo[BUSINESS_DETAILS_SECTION],
      productInfo.business,
    );
    productInfo[BUSINESS_DETAILS_SECTION] = mergedData;
  }

  return Object.assign(existingResetInfo, productInfo);
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState: {
    tier: FREE_TIER,
    dirtySections: [],
    invalidSections: [],
    isInvalid: false,
    isDirty: false,
    showWarningModal: false,
    showChangesSaved: false,
    resetProductInfo: {},
    currentUserEmail: null,
    businessData: null,
    firstActivationDate: null,
    hasReset: false,
    identifier: null,
    isSaving: false,
    saveHasError: false,
    hasData: false,
    alerts: {
      listOfAlertIDs: [],
      type: "",
      bannerType: "",
      alertMessage: "",
      alertDetails: "",
      isRead: false
    },
    toggle: {
      isActive: null,
      hasError: false,
      previousToggle: null,
      dismissModal: false
    },
    appointments: {
      content: null,
      totalElements: null,
      totalPages: null,
      firstPage: false,
      lastPage: false,
      isLoading: false,
      type: 'UPCOMING',
      timezone: 'America/New_York',
      hasError: false,
      unformatted: null,
      hasAnyAppointments: false,
      hasChanges: false,
    },
    links: {
      main: null,
      appointmentTypes: null,
      hasError: false,
      isLoading: false,
    },
  },
  reducers: {
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySections.indexOf(dirtyForm) === -1) {
        state.dirtySections.push(dirtyForm);
        state.isDirty = true;
      }

      // If this form is now dirty, it can't be invalid anymore
      state.invalidSections = state.invalidSections.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;
      state.dirtySections = state.dirtySections.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySections.length > 0;

      state.invalidSections = state.invalidSections.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSections.indexOf(invalidForm) === -1) {
        state.invalidSections.push(invalidForm);
        state.isInvalid = true;
      }
    },
    save: (state) => {
      state.isSaving = true;
      state.saveHasError = false;
    },
    dismissBanner: (state) => {
      state.alerts.isRead = true;
    },
    saveBookingsSuccess: (state, action) => {
      // Clear out the isDirty state
      state.isDirty = false;
      state.dirtySections = [];
      state.showChangesSaved = true;
      state.isSaving = false;

      // Shouldn't have been able to make it here in this state but just in case
      state.isInvalid = false;
      state.invalidSections = [];

      // Update the reset cache
      state.resetProductInfo = Object.assign(state.resetProductInfo, action.payload.data);

      const daysClosed = action.payload.data.daysClosed;
      if (daysClosed && Array.isArray(daysClosed)) {
        const convertedDays = daysClosed.map(({ startDate, endDate }) => (
          {
            startDate: convertDateStringToDate(startDate),
            endDate: convertDateStringToDate(endDate),
          }
        ));
        state.resetProductInfo.daysClosed = convertedDays;
      }
      state.resetProductInfo.slogan = parseNewLine(action.payload.data.advancedSettings.slogan)
    },
    saveBookingsFailure: (state) => {
      state.isSaving = false;
      state.saveHasError = true;
    },
    cancelSave: (state) => {
      state.showWarningModal = state.isDirty;
    },
    closeBookingsChangesSaved: (state) => {
      state.showChangesSaved = false;
    },
    toggleBookingsStatusSuccess: (state, action) => {
      state.toggle.isActive = action.payload.active;
      state.toggle.previousToggle = !action.payload.active
    },
    toggleBookingsStatusFailure: (state) => {
      state.toggle.hasError = true;
    },
    dismissToggleModal: (state, action ) => {
      state.toggle.dismissModal = action.payload.dismiss;
    },
    changeSchedulerTimezone: (state, action) => {
      const { timezone } = action.payload;
      state.appointments.content = state.appointments.unformatted
        .map((appt) => convertAppointmentDate(appt, timezone, state.appointments.timezone))
        .reduce((groups, appt) => {
          const date = appt.groupDate;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(appt);
          return groups;
        }, {})
      state.appointments.timezone = timezone;
    },
    changeSchedulerAppointmentsType: (state, action) => {
      state.appointments.type = action.payload.type;
    },
    loadSchedulerAppointments: (state) => {
      state.appointments.isLoading = true;
    },
    loadSchedulerAppointmentsSuccess: (state, action) => {
      const { content, totalElements, totalPages, first, last } = action.payload;

      state.appointments.unformatted = content
        .map(convertAppointmentStatus);
      state.appointments.content = state.appointments.unformatted
        .map((appt) => convertAppointmentDate(appt, state.appointments.timezone, 'UTC'))
        .reduce((groups, appt) => {
          const date = appt.groupDate;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(appt);
          return groups;
        }, {})
      state.appointments.totalElements = totalElements;
      state.appointments.totalPages = totalPages;
      state.appointments.firstPage = first;
      state.appointments.lastPage = last;
      state.appointments.isLoading = false;
      state.appointments.hasError = false;
      state.appointments.hasAnyAppointments = state.appointments.hasAnyAppointments || content.length > 0;
    },
    loadSharedAlert: (state, action) => {
      if (action.payload.length > 0) {
        action.payload.forEach(alert => {
          state.alerts.listOfAlertIDs.push(alert.id)
        })

        const { type, read, message, details } = action.payload[0];

        state.alerts.type = type;
        state.alerts.isRead = read;
        state.alerts.alertMessage = message;
        state.alerts.alertDetails = details;
      }
    },
    loadSchedulerAppointmentsFailure: (state) => {
      state.appointments.hasError = true;
      state.appointments.isLoading = false;
    },
    setSchedulerAppointmentsChanges: (state, action) => {
      state.appointments.hasChanges = action.payload.hasChanges;
    },
    loadSchedulerLinks: (state) => {
      state.links.isLoading = true;
    },
    loadSchedulerLinksSuccess: (state, action) => {
      const { loadApp, appointmentTypes } = action.payload;
      state.links.main = loadApp;
      state.links.appointmentTypes = appointmentTypes;
      state.links.hasError = false;
      state.links.isLoading = false;
    },
    loadSchedulerLinksFailure: (state) => {
      state.links.hasError = true;
      state.links.isLoading = false;
    },
    resetCompleteAction: (state) => {
      state.hasReset = true;
    },
    loadSchedulerAllAppointmentsSuccess: (state, action) => {
      const { content } = action.payload;
      state.appointments.hasAnyAppointments = content.length > 0;
    },
    setAfterWizardComplete: (state, action) => {
      state.toggle.isActive = action.payload.isActive;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const {
        business, user,
      } = action.payload;

      if (user && user.email) {
        state.currentUserEmail = user.email;
      }

      if (business) {
        state.businessData = business;
      }
    });
    builder.addCase(loadedBookingData, (state, action) => {
      const {
        productInfo, productType, identifier, active, firstActivationDate,
      } = action.payload;

      state.hasData = true;
      // Reset data needs to be cleaned to conform to the redux data model.
      if (productInfo) {
        state.resetProductInfo = handleResponse(productInfo, state.resetProductInfo);
      }

      state.toggle.isActive = active;
      state.identifier = identifier;
      state.firstActivationDate = firstActivationDate;

      if (productType) {
        state.tier = productType.tier;
      }
    });
    builder.addCase(triggerResetAction, (state) => {
      state.hasReset = false;
    });
    builder.addCase(closeWarningModal, (state, action) => {
      state.showWarningModal = false;
      if (action.payload.forceClose) {
        state.isDirty = false;
        state.dirtySections = [];
        state.isInvalid = false;
        state.invalidSections = [];
      }
    });
    builder.addCase(loadUpcomingAppointmentsSuccess, (state, action) => {
      if (action.payload.urls) {
        const { loadApp, appointmentTypes } = action.payload.urls;
        state.links.main = loadApp;
        state.links.appointmentTypes = appointmentTypes;
      }
     }
    );
    builder.addCase(updateFeatures, (state, action) => {
      if (action.payload.formName === SCHEDULER_SLUG) {
        state.toggle.isActive = action.payload.isSelected;
      }
    });
    builder.addMatcher(
      isAnyOf(toggleBookingsStatusSuccess, saveBookingsSuccess),
      (state, action) => {
        const {
          productInfo, active, identifier, firstActivationDate,
        } = action.payload;

        if (productInfo) {
          state.resetProductInfo = handleResponse(productInfo, state.resetProductInfo);
        }

        state.toggle.isActive = active;
        state.identifier = identifier;
        state.firstActivationDate = firstActivationDate;
      },
    );
  },
});

export const {
  save,
  saveBookingsSuccess,
  saveBookingsFailure,
  setStateDirty,
  setStateClean,
  setStateInvalid,
  cancelSave,
  dismissBanner,
  closeBookingsChangesSaved,
  toggleBookingsStatusSuccess,
  toggleBookingsStatusFailure,
  resetCompleteAction,
  loadSharedAlert,
  loadSchedulerAppointments,
  loadSchedulerAppointmentsSuccess,
  loadSchedulerAppointmentsFailure,
  changeSchedulerAppointmentsType,
  changeSchedulerTimezone,
  setSchedulerAppointmentsChanges,
  loadSchedulerLinksSuccess,
  loadSchedulerLinksFailure,
  dismissToggleModal,
  loadSchedulerAllAppointmentsSuccess,
  setAfterWizardComplete,
} = bookingsSlice.actions;
export default bookingsSlice.reducer;
