import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { useEffect } from 'react';
import { PrimaryButton } from '../settings-shared-components';
import { withSnackbar } from '../snackbar/withSnackbar';

const WidgetContainer = styled('div')(({ theme }) => ({
  marginRight: 30,
  '@keyframes bounceIn': {
    '0%,20%,40%,60%,80%,to': {
      animationTimingFunction: 'cubic-bezier(.215,.61,.355,1)',
    },
    '0%': {
      opacity: 0,
      transform: 'scale3d(.3,.3,.3)',
    },
    '20%': {
      transform: 'scale3d(1.1,1.1,1.1)',
    },
    '40%': {
      transform: 'scale3d(.9,.9,.9)',
    },
    '60%': {
      opacity: 1,
      transform: 'scale3d(1.03,1.03,1.03)',
    },
    '80%': {
      transform: 'scale3d(.97,.97,.97)',
    },
    to: {
      opacity: 1,
      transform: 'scaleX(1)',
    },
  },
  '& button': {
    animationName: 'bounceIn',
    animationDuration: '1s',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    marginRight: 0,
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    '& button': {
      flexBasis: '100%',
    },
  },
}));

function SaveCancelWidget({
  snackbarShowMessage, isDirty, isSaving, showChangesSaved, onSave, onSaveComplete, isInvalid, id
}) {
  const theme = useTheme();

  useEffect(() => {
    if (showChangesSaved) {
      snackbarShowMessage('Your changes have been saved.');
      onSaveComplete();
    }
  }, [showChangesSaved]);

  if (isDirty && !isInvalid) {
    return (
      <WidgetContainer>
        <PrimaryButton variant="contained" onClick={onSave} disabled={isSaving} id={`${id}-saveButton`}>
          <span>{ isSaving ? 'Saving...' : 'Save' }</span>
          {
            isSaving && (
              <div style={{ marginLeft: theme.spacing(1) }}>
                <IQLoadingSpinner size={20} />
              </div>
            )
          }
        </PrimaryButton>
      </WidgetContainer>
    );
  }

  return (null);
}

SaveCancelWidget.propTypes = {
  snackbarShowMessage: PropTypes.func,
  isDirty: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  showChangesSaved: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveComplete: PropTypes.func.isRequired,
};

export default withSnackbar(SaveCancelWidget);
