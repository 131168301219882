import {
  CHATBOT_CONNECT_SCHEDULING_SECTION, CHATBOT_CUSTOM_QUESTION_SECTION,
  CHATBOT_NAME_SECTION, CHATBOT_TONE_SECTION,
  CHATBOT_VISITOR_SECTION,
} from '../products/chatbot/chatbot-constants';

export const SAVE_CONFIGURATION = {
  generalSettings: [
    {
      storeKey: CHATBOT_NAME_SECTION,
      saveKey: '',
    },
    {
      storeKey: CHATBOT_TONE_SECTION,
      saveKey: '',
    },
    {
      storeKey: CHATBOT_VISITOR_SECTION,
      saveKey: '',
    },
    {
      storeKey: CHATBOT_CUSTOM_QUESTION_SECTION,
      saveKey: '',
    },
    {
      storeKey: CHATBOT_CONNECT_SCHEDULING_SECTION,
      saveKey: CHATBOT_CONNECT_SCHEDULING_SECTION,
    },
  ],
};
