import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import blobs from '../assets/blobs.svg';
import blobsMobile from '../assets/blobs-mobile.svg';

const ContainerDiv = styled('div')(({ theme }) => ({
  background: `url(${blobs})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRight: `1px solid ${theme.palette.common.black}`,
  [theme.breakpoints.down('md')]: {
    background: `url(${blobsMobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '30%',
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
}));

const TextWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 5, '75px', 5),

  '& .text-container': {
    color: theme.palette.common.white,
    fontSize: 24,
    lineHeight: '150%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

export default function SidePanel({ children }) {
  return (
    <ContainerDiv>
      <TextWrapper>
        <div className="text-container">
          {children}
        </div>
      </TextWrapper>
    </ContainerDiv>
  );
}

SidePanel.propTypes = {
  children: PropTypes.node,
};
