import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontFamily: 'Unify Sans SemiBold',
  color: theme.palette.common.black,
  paddingTop: theme.spacing(2),
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontFamily: 'Unify Sans',
  paddingTop: theme.spacing(1),
}));

export default function SectionLayout({
  headerText, headerDescription = '', name, errorBanner, id,
}) {
  return (
    <>
      <SectionHeader id={id} name={name}>{headerText}</SectionHeader>
      {errorBanner && errorBanner}
      {headerDescription && <SectionDescription>{headerDescription}</SectionDescription>}
    </>
  );
}

SectionLayout.propTypes = {
  headerText: PropTypes.string.isRequired,
  headerDescription: PropTypes.string,
  name: PropTypes.string,
  errorBanner: PropTypes.node,
  id: PropTypes.string,
};
