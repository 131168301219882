import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IQLabelTooltip, IQTheme } from '@gannettdigital/shared-react-components';

const InlineWrapper = styled('div')(({ theme }) => ({
  '& .MuiTypography-root': {
    display: 'inline-block',
  },
  '& .MuiChip-root': {
    marginRight: theme.spacing(1),
  },
}));

export default function ProLabeledSubHeader({ children, tooltipText }) {
  return (
    <InlineWrapper>
      <IQLabelTooltip
        tooltipText={tooltipText}
        labelText={children}
        hasError={false}
        theme={IQTheme}
        required={false}
      />
    </InlineWrapper>
  );
}

ProLabeledSubHeader.propTypes = {
  children: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

