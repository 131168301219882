import { useSelector } from 'react-redux';
import GraderHeader from './GraderHeader';
import GraderBody from './GraderBody';
import { isEmptyObject } from './grader-utils';

/**
 * Website grader page
 */
export default function WebsiteGraderResults() {
  const { score, subCategories } = useSelector((state) => state.grader.graderResults);
  const hasScores = !isEmptyObject(subCategories);

  return (
    <>
      <GraderHeader score={score} />
      {hasScores && <GraderBody categories={subCategories} /> }
    </>
  );
}
