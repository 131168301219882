import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const FrameWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 0),
  flex: 1,
}));

export default function StepFrame({ children }) {
  return (
    <FrameWrapper>
      {children}
    </FrameWrapper>
  );
}

StepFrame.propTypes = {
  children: PropTypes.node,
};
