import { combineEpics } from 'redux-observable';
import appEpics from '../shared/app-epics';
import chatbotEpics from '../pages/products/chatbot/data/chatbot-epics';
import addressAutocompleteEpics from '../shared/autoComplete/address-autocomplete-epics';
import bookingsEpics from '../pages/products/bookings/data/bookings-epics';
import googleCalendarEpics from '../pages/products/bookings/data/calendar-integration-epics';
import callSettingsEpics from '../pages/products/callSettings/data/call-settings-epics';
import adminEpics from '../pages/products/admin/data/admin-epics';
import accountEpics from '../pages/products/account/data/account-epics';
import configurationEpics from '../pages/configuration/data/configuration-epics';
import toolsEpics from '../pages/tools/data/tools-epics';
import widgetSettingsEpics from '../pages/products/settings/data/widgetSettings-epics';
import graderEpics from '../pages/graders/website/data/grader-epics';
import dashboardEpics from '../pages/dashboard/data/dashboard-epics';
import businessAttributesEpics from '../shared/business-attributes-epics';

export default combineEpics(
  ...appEpics,
  ...chatbotEpics,
  ...addressAutocompleteEpics,
  ...bookingsEpics,
  ...googleCalendarEpics,
  ...callSettingsEpics,
  ...adminEpics,
  ...accountEpics,
  ...configurationEpics,
  ...toolsEpics,
  ...widgetSettingsEpics,
  ...graderEpics,
  ...dashboardEpics,
  ...businessAttributesEpics,
);
