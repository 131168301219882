/**
 * Generates the 3 color bars used in the score bar.
 * @param {number} score
 * @returns String[]
 */
export const determineScoreColorBar = (score) => {
  // Default white on score not loaded
  if (score === null) {
    return ['#ffffff', '#ffffff', '#ffffff'];
  }

  if (score > 66) {
    return ['#7dbe50', '#7dbe50', '#7dbe50'];
  }

  if (score > 33) {
    return ['#ffd000', '#ffd000', '#ffffff'];
  }

  return ['#be1e3c', '#ffffff', '#ffffff'];
};

/**
 * Determine score based information
 * @param {number} score
 * @returns Object - contains color and presence level
 */
export const determineScoreObject = (score) => {
  const colorObj = {
    color: '#be1e3c',
    level: 'Foundational',
  };

  if (score > 66) {
    colorObj.color = '#7dbe50';
    colorObj.level = 'Advanced';
  } else if (score > 33) {
    colorObj.color = '#ffd000';
    colorObj.level = 'Intermediate';
  }

  return colorObj;
};

/**
 * Filter out null score categories
 * @param {Obj} categories
 * @returns Obj
 */
export const filterScores = (categories) => {
  const cats = Object.entries(categories);
  return cats.reduce((acc, [key, scoreObj]) => {
    if (scoreObj && Number.isSafeInteger(scoreObj.subScore)) {
      acc[key] = scoreObj.subScore;
    }
    return acc;
  }, {});
};

/**
 * Check if an object is empty or null/undefined
 * @param {Obj} obj
 * @returns
 */
export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Trim down the given url to not display http(s)://www.
 * @param {String} url
 */
export const trimDisplayUrl = (url) => (
  url.replace('www.', '').replace('http://', '').replace('https://', '')
);

/**
 * Adjust the score to not reach the extreme ends of the rounded bar
 * @param {number} score
 * @returns number
 */
export const adjustScore = (score) => {
  if (score === 100) {
    return 98;
  }

  if (score === 0) {
    return 5;
  }

  return score;
};
