import { createAction, createSlice } from '@reduxjs/toolkit';
import { loadSettingsDataSuccess } from '../../../shared/app-slice';
import { EMAIL_CAMPAIGNS_PAGE } from '../../../shared/navigation/navigation-config';
import { SENDER_EMAIL_KEY, SENDER_NAME_KEY, REPLY_TO_EMAIL_KEY, STREET_ADDRESS_KEY, STREET_ADDRESS_2_KEY, CITY_KEY, STATE_KEY, COUNTRY_KEY, POSTAL_CODE_KEY, COPYRIGHT_KEY } from "../data/tools-constants";

export const saveEmailCampaignAction = createAction('emailCampaign/save');

const initialState = {
  isLoading: false,
  showChangesSaved: false,
  errorSaving: false,
  hasReset: false,
  resetProductInfo: {},
  dirtySections: [],
  invalidSections: [],
  isInvalid: false,
  isDirty: false,
  emailInfo: {
    [SENDER_NAME_KEY]: "",
    [SENDER_EMAIL_KEY]: "",
    [REPLY_TO_EMAIL_KEY]: "",
    [STREET_ADDRESS_KEY]: "",
    [STREET_ADDRESS_2_KEY]: "",
    [CITY_KEY]: "",
    [STATE_KEY]: "",
    [COUNTRY_KEY]: "",
    [POSTAL_CODE_KEY]: "",
    [COPYRIGHT_KEY]: ""
  }
};

const emailCampaignSlice = createSlice({
  name: 'emailcampaign',
  initialState,
  reducers: {
    updateEmailFooter: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      state.emailInfo[fieldName] = fieldValue;
    },
    updateEmailFooterEpic: (state, action) => { },
    updateEmailFooterSuccess: (state, action) => {
      state.showChangesSaved = true;
    },
    updateEmailFooterFailure: (state, action) => {
      state.errorSaving = true;
    },
    closeEmailChangesSaved: (state) => {
      state.showChangesSaved = false;
    },
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySections.indexOf(dirtyForm) === -1) {
        state.dirtySections = [dirtyForm];
        state.isDirty = true;
      }

      state.invalidSections = state.invalidSections.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;

      state.dirtySections = state.dirtySections.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySections.length > 0;

      state.invalidSections = state.invalidSections.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSections.indexOf(invalidForm) === -1) {
        state.invalidSections.push(invalidForm);
        state.isInvalid = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const { products } = action.payload;

      const info = products.filter(el => {
        if (el.offeringType === "EmailCampaignPro") {
          return el.productInfo
        }
      })

      // Otherwise going to assign everything undefined
      if (Array.isArray(info) && info.length >= 1) {
        const {
          fromName,
          fromEmail,
          replyToEmail,
          copyrightMessage,
          address,
          address2,
          city,
          state: emailState,
          country,
          zip,
        } = info[0].productInfo;

        state.emailInfo[SENDER_NAME_KEY] = fromName;
        state.emailInfo[SENDER_EMAIL_KEY] = fromEmail;
        state.emailInfo[REPLY_TO_EMAIL_KEY] = replyToEmail;
        state.emailInfo[COPYRIGHT_KEY] = copyrightMessage;
        state.emailInfo[STREET_ADDRESS_KEY] = address;
        state.emailInfo[STREET_ADDRESS_2_KEY] = address2;
        state.emailInfo[CITY_KEY] = city;
        state.emailInfo[STATE_KEY] = emailState;
        state.emailInfo[COUNTRY_KEY] = country;
        state.emailInfo[POSTAL_CODE_KEY] = zip;
      }
    });
  },
});

export const {
  updateEmailFooterEpic,
  updateEmailFooter,
  updateEmailFooterSuccess,
  closeEmailChangesSaved,
  updateEmailFooterFailure,
  setStateDirty,
  setStateClean,
  setStateInvalid
} = emailCampaignSlice.actions;

export default emailCampaignSlice.reducer;