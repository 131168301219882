import { trackNavigationClicked } from "./analytics-utils";

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

/**
 * Check if an object is empty or null/undefined
 * @param {Obj[]} links - current links array
 * @param {Boolean} hasListingsSubscription - determines whether the user has an active premium listings subscription
 * @param {String} businessName - the current user business name
 */
export function addDynamicNavConfig(links, hasListingsSubscription, businessName) {
  const toolsDropdownId = links.find((l) => l.id === 'tools');
  let displayLinks = links;

  if (hasListingsSubscription) {
    const listingsLink = toolsDropdownId.dropdownLinks.find((tD) => tD.id === 'listings');
    listingsLink.url = `${REACT_APP_GATEWAY_EXT_ROOT}settings/premium-listings-login`;
  } else {
    const toolsDropDown = toolsDropdownId.dropdownLinks.filter((link) => link.id !== 'listings');

    displayLinks.forEach((link, i) => {
      if (link.id === 'tools') {
        displayLinks[i].dropdownLinks = [...toolsDropDown];
      }
    });
  };

  if (businessName) {
    const businessDropdown = links.find((l) => l.id === 'business-name-links');
    businessDropdown.labelText = businessName;
  }

  // Attach onClick events to all links (for MP use the label Text as nav property)
  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    if (!!link.dropdownLinks) {
      for (let j = 0; j < link.dropdownLinks.length; j++) {
        const dropDownLink = link.dropdownLinks[j];
        dropDownLink.cbOnClick = () => {
          trackNavigationClicked(dropDownLink.labelText);
        };
      }
    } else {
      link.cbOnClick = () => {
        trackNavigationClicked(link.labelText);
      };
    }
  }

  return displayLinks;
};