import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import WarningModal from './WarningModal';
import { closeWarningModal as closeDefault, triggerResetAction as triggerDefault } from '../../redux/shared-actions';

const titleText = 'You have unsaved changes.';
const promptText = 'Are you sure you want to leave settings?';
const noBtnText = 'No, stay here';
const yesBtnText = 'Yes';
export default function CancelModal({
  id,
  closeWarningModal = closeDefault,
  triggerResetAction = triggerDefault,
  isOpen = false,
  proceedCallback = () => {},
}) {
  const dispatch = useDispatch();

  const yesClick = useCallback(() => {
    dispatch(closeWarningModal({
      forceClose: true,
    }));
    if (triggerResetAction) {
      dispatch(triggerResetAction());
    }
    proceedCallback(true);
  });

  const noClick = useCallback(() => {
    dispatch(closeWarningModal({
      forceClose: false,
    }));
    proceedCallback(false);
  });

  return (
    <WarningModal
      id={id}
      isOpen={isOpen}
      titleText={titleText}
      promptText={promptText}
      leftInteractText={noBtnText}
      rightInteractText={yesBtnText}
      leftClickCallback={noClick}
      rightClickCallback={yesClick}
    />
  );
}

CancelModal.propTypes = {
  id: PropTypes.string,
  closeWarningModal: PropTypes.func,
  triggerResetAction: PropTypes.func,
  isOpen: PropTypes.bool,
  proceedCallback: PropTypes.func,
};
