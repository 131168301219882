import { createAction, createSlice } from '@reduxjs/toolkit';
import { STRIPE_KEY } from '../account-constants';

export const loadInvoicesAction = createAction('invoices/loadInvoices');
export const loadInvoicesSuccessAction = createAction('invoices/loadInvoicesSuccess');
export const loadInvoicesFailureAction = createAction('invoices/loadInvoicesFailure');

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    totalPages: 0,
    invoices: [],
    totalDisplayPerPage: 10,
    hasInvoices: false,
    isLoading: false,
    currentPage: 0,
    hasError: null,
    errMsg: null
  },
  reducers: {
    loadInvoices: (state) => {
      state.isLoading = true;
    },
    loadInvoicesSuccess: (state, action) => {
      const {items, pages, current} = action.payload;
      const paidInvoices = items.filter(item => item.amount > 0);

      // TODO: fix on backend. Temp fix for release
      if(items.length === state.totalDisplayPerPage) {
        state.totalPages = 1
      } else {
        state.totalPages = pages;
      }

      state.invoices = paidInvoices;
      state.currentPage = current;

      if(items && items.length > 0) {
        state.hasInvoices = true;
      }

      state.isLoading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadInvoicesFailureAction, (state, action) => {
      let errMsg;
      
      if(action.payload.response.code.includes(STRIPE_KEY)) {
        errMsg = STRIPE_KEY
      }

      state.isLoading = false;
      state.hasError = true;
      state.errMsg = errMsg;
    });
  },
});

export const {
  loadInvoices,
  loadInvoicesSuccess,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;