import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SocketContext } from '../websocket/WebSocketProvider';
import { checkAppointmentCancelledMessage, checkAppointmentCreatedMessage, checkAppointmentRescheduledMessage } from '../websocket/websocket-utils';
import { setSchedulerAppointmentsChanges } from '../../pages/products/bookings/data/bookings-slice';

/**
 * Hook for listening to all appointments messages (created, rescheduled, cancelled)
 */
export default function AppointmentsListener() {
  const dispatch = useDispatch();
  const messageSocket = useContext(SocketContext);

  const appointmentListenerCb = useCallback((message) => {
    const hasCreatedAppointment = checkAppointmentCreatedMessage(message);
    const hasRescheduledAppointment = checkAppointmentRescheduledMessage(message);
    const hasCancelledAppointment = checkAppointmentCancelledMessage(message);

    if (hasCreatedAppointment || hasRescheduledAppointment || hasCancelledAppointment) {
      dispatch(
        setSchedulerAppointmentsChanges({ hasChanges: true })
      );
    }
  });

  useEffect(() => (
    messageSocket.addMessageListener(appointmentListenerCb)
  ), [messageSocket]);

  return null;
}
