import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CardContent, CardHeader, Typography } from '@mui/material';
import {
  ACTIVE_SUBSCRIPTION_COLOR, ACTIVE_SUBSCRIPTION_STATUS, CANCELED_SUBSCRIPTION_COLOR,
  EXPIRED_SUBSCRIPTION_STATUS,
} from '../../account-constants';
import { ReactComponent as BundleIcon } from '../../assets/bundle-sub-icon.svg';
import { Bolded, PrimaryButton } from '../../../../../shared/settings-shared-components';
import {
  SubscriptionCardWrapper, SubscriptionCardHeaderContent, SubscriptionCardBadgerWrapper,
  SubscriptionCardHeaderWrapper, ChangeCreditCardWrapper,
} from '../account-shared-components';
import { hasExpiredTrial } from '../../../../../shared/trials/trial-utils';
import { BUNDLE_SLUG, INITIAL_BUNDLE_TIER } from '../../../../../shared/app-constants';
import { trackUpgrade } from '../../../../../shared/analytics-utils';

const SubscriptionCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2, 0, 7.5),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: theme.spacing(0, 2.5),
    gap: theme.spacing(3),
  },
}));

const {
  REACT_APP_BUY_ONLINE_UI_ROOT,
} = process.env;

const buildEndDate = (hasExpired, trial) => {
  if (trial) {
    const dateObj = hasExpired ? trial.realEndDate : trial.endDate;
    return new Date(dateObj).toLocaleDateString() || '';
  }
  return '';
};

const handlePurchase = () => {
  const buyLink = `${REACT_APP_BUY_ONLINE_UI_ROOT}?product=${BUNDLE_SLUG}&tier=${INITIAL_BUNDLE_TIER}`;
  trackUpgrade();
  window.open(buyLink, '_self');
};

export default function TrialsCard({ trialsInfo, title }) {
  const hasExpired = hasExpiredTrial(trialsInfo);
  const status = !hasExpired ? ACTIVE_SUBSCRIPTION_STATUS : EXPIRED_SUBSCRIPTION_STATUS;
  const statusColor = !hasExpired ? ACTIVE_SUBSCRIPTION_COLOR : CANCELED_SUBSCRIPTION_COLOR;
  const formattedExpDate = buildEndDate(hasExpired, trialsInfo[0]);
  const expireString = `Your Free Trial ${hasExpired ? 'Expired' : 'Expires'} on `;

  return (
    <SubscriptionCardWrapper>
      <CardHeader title={(
        <SubscriptionCardHeaderContent>
          <SubscriptionCardHeaderWrapper>
            <BundleIcon />
            <Typography variant="h4">{title}</Typography>
          </SubscriptionCardHeaderWrapper>
          <SubscriptionCardBadgerWrapper color={statusColor}>
            <div className="dot" />
            {status}
          </SubscriptionCardBadgerWrapper>
        </SubscriptionCardHeaderContent>
      )}
      />
      <SubscriptionCardContent>
        <Typography variant="body1">
          <Bolded>{expireString}</Bolded>
          {formattedExpDate}
        </Typography>
        <ChangeCreditCardWrapper>
          <PrimaryButton onClick={handlePurchase}> Subscribe </PrimaryButton>
        </ChangeCreditCardWrapper>
      </SubscriptionCardContent>
    </SubscriptionCardWrapper>
  );
}

TrialsCard.propTypes = {
  trialsInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};
