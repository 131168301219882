import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { IQButtonLink } from '@gannettdigital/shared-react-components';
import ActionCard from './ActionCard';

export const AppBarStyled = styled(AppBar)`
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 0px;
  vertical-align: middle;
  justify-content: space-between;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #D7D7D7;
  background: #FFFFFF;
`;

export const StyledSettingsPageHeader = styled(Typography)`
  text-align: center;
  color: #03334E;
  size: 40px;
  line-height: 50px;
  font-weight: 600;
`;

export const StyledSettingsPageDescription = styled(Typography)`
  text-align: center;
  color: #404040;
  size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
`;

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: `${theme.spacing(5, 0, 8)} !important`,
  justifyContent: 'center',
  '& .MuiButton-root': {
    margin: `${theme.spacing(0, 1)} !important`,
  },
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    '& .MuiButton-root': {
      flex: '1 0 45%',
    },
    '& .MuiButton-root:nth-child(3)': {
      marginTop: `${theme.spacing(2)} !important`,
    },
  },
}));

export const CaptureCodeWrapper = styled('div')(({ theme }) => ({
  marginTop: `${theme.spacing(5)}!important`,
}));

export const StyledLinkText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: block;
`;

export const StyledLink = styled(IQButtonLink)`
  padding: 0;
  margin-top: -4px;
  padding-left: 4px;
`;

export const StyledInitialLink = styled(IQButtonLink)`
  padding: 0;
  margin-top: -4px;
  padding-right: 4px;
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "20px",
  padding: '11px 45px',
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.primary.main,
  boxShadow: ' 0px 1px 3px rgba(0, 0, 0, 0.2)',
  fontFamily: 'Unify Sans Semibold',
  '&:hover': {
    backgroundColor: "#F5F8FF",
    border: "1px solid #0046FF",
  },
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  '&.MuiButton-contained:hover': {
    backgroundColor: '#0035C2',
    borderColor: '#0035C2',
    color: theme.palette.common.white,
    boxShadow: 'box-shadow: 0px 1px 3px 0px #00000033',
  },
  "&.Mui-disabled": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    border: "1px solid #0046FF",
    opacity: "0.38",
  },
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  fontFamily: 'Unify Sans',
  textTransform: 'none',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderRadius: "20px",
  padding: '11px 20px',
  cursor: 'pointer',
  '&.MuiButton-root': {
    backgroundColor: `${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    fontSize: 14,
  },
  '&.MuiButton-root:hover': {
    backgroundColor: `#0035C2`,
    color: theme.palette.common.white,
    fontSize: 14,
  },

  // Outlined version
  '&.MuiButton-outlinedPrimary': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    paddingTop: '10px',
    paddingBottom: '10px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#F5F8FF',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },

  '&.Mui-disabled': {
    border: `1px solid ${theme.palette.primary.main}`,
    opacity: '0.38',
  },

  '&.Mui-disabled.MuiButton-contained': {
    color: theme.palette.common.white,
    backgroundColor: `rgb(0 70 255 / 38%)`,
  },

  '&.Mui-disabled.MuiButton-outlinedPrimary': {
    // 38% opacity main color
    color: '#839DF4',
    backgroundColor: '#F4F4F4',
    borderColor: '#839DF4',
  },
}));

export const DividingLine = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'allowMobile',
})(({ allowMobile, theme }) => ({
  borderColor: '#D7D7D7',
  margin: theme.spacing(3, 0),
  [theme.breakpoints.down('md')]: {
    display: allowMobile ? 'block' : 'none',
  },
}));

export const CharacterCount = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontFamily: 'Roboto',
  fontWeight: 400,
  lineHeight: '16px',
  paddingTop: theme.spacing(1),
}));

export const SwitchWrapperOverride = styled('div')(({ theme }) => ({
  '& .MuiFormControlLabel-root': {
    border: 0,
    userSelect: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export const GridRow = styled(Grid)(({ theme }) => ({
  '& .MuiFormControl-root': {
    width: '100%',
    '& label': {
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
  },
}));

export const SubHeader = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  fontFamily: 'Unify Sans SemiBold',
}));

export const StyledDelete = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
}));

export const CustomCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: "8px",
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0 , 0, 0),
  height: 'fit-content',
}));

export const LinkButton = styled(Button)(() => ({
  textTransform: 'none',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

export const InputGridWrapper = styled(Grid)(({ theme }) => ({
  '& .MuiFormControl-root': {
    width: '100%',
    '& label': {
      paddingBottom: theme.spacing(1),
      fontFamily: 'Unify Sans SemiBold',
    },
  },
}));

export const DesktopMenuItemButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }) => ({
  position: 'relative',
  fontFamily: 'Unify Sans',
  fontSize: 16,
  lineHeight: '24px',
  padding: theme.spacing(2, 2),
  color: theme.palette.background.default,
  textTransform: 'none',
  cursor: 'pointer',

  '&:hover': {
    background: theme.palette.common.black,
  },
  '&:focus': {
    background: theme.palette.common.black,
  },
  '&:before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.success.main,
    opacity: isActive ? 1 : 0,
    transition: '.25s opacity ease-in-out',
  },
}));

export const SplitColumn = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
}));

export const PhoneText = styled(Typography)(() => ({
  fontFamily: 'Unify Sans',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
}));

export const UpgradeToProText = styled(StyledLinkText)(({ theme }) => ({
  div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: 'absolute',
  bottom: 15,
  left: 280,
  [theme.breakpoints.down('lg')]: {
    bottom: 'unset',
    left: 'unset',
  },
}));

export const NavColumn = styled(SplitColumn)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  [theme.breakpoints.down('md')]: {
    overflowY: 'visible',
  },
}));

export const PaddedNav = styled(NavColumn)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(3),
  },
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '12px',
}));

export const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 0,
  minWidth: '400px',
  [theme.breakpoints.down('md')]: {
    minWidth: 'auto',
  },
}));

export const InputTextPrimary = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  display: 'inline-block',
  fontFamily: 'Unify Sans Semibold',
}));

export const InputTextSecondary = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: '16px',
  paddingTop: '12px',
}));

export const ActionHeader = styled('span')(({ theme }) => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: 16,
}));

export const MobileMenuItemButton = styled(DesktopMenuItemButton)(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(1, 0),
  fontWeight: 300,
  width: '100%',
  height: 'auto',
  justifyContent: 'flex-start',
  '&:before': {
    height: 2,
  },
}));

export const AdjustedAppBar = styled(AppBarStyled)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderBottom: 'none',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const MenuDivider = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root.MuiDivider-fullWidth': {
    borderColor: theme.palette.action.disabledBackground,
    margin: 0,
  },
}));


export const NavLink = styled(Link)(({ selected, theme }) => ({
  fontFamily: selected ? 'Unify Sans SemiBold' : 'Unify Sans',
  fontSize: 18,
  lineHeight: '27px',
  color: theme.palette.primary.main,
  cursor: selected ? 'auto' : 'pointer',
  textDecoration: selected ? 'none' : 'underline',
  textDecorationColor: theme.palette.primary.main,
  textUnderlineOffset: selected ? "6px" : "2px"
}));

export const NavLinkWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 3, 0),
  display: "flex"
}));

export const CardBadge = styled('div')(({ theme, color }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '1px 8px 0.5px 8px',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.common.black,
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  borderRadius: '16px',
  '.dot': {
    height: '10px',
    width: '10px',
    marginRight: '0.3rem',
    backgroundColor: color,
    borderRadius: '50%',
    display: 'inline-block',
  }
}));

export const PhoneRow = styled(Grid)(({ theme }) => ({
  '& .MuiFormControl-root': {
    width: '100%',
    '& label': {
      color: theme.palette.text.primary,
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
    '& > div input': {
      height: 36,
      paddingLeft: theme.spacing(1),
    },
  },
  '& #business-phone': {
    padding: theme.spacing(0.1, 1),


    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
    }
  },
}));

export const DividerRow = styled(Grid)(({ theme }) => ({
  '&.MuiGrid-root.MuiGrid-item': {
    width: '100%',
    paddingTop: 0,
    marginBottom: `-${theme.spacing(2)}`,
  },
}));

export const WidgetActionCardWrapper = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  width: '285px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const LoadingCard = styled(Card)(({ theme }) => ({
  borderRadius: 4,
  border: '1px solid #D7D7D7',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  minWidth: 250,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ModalBackground = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  margin: '0 auto',
  textAlign: 'center',
  width: 925,
  top: '10%',
  position: 'relative',
  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    width: 600,
  },
  [theme.breakpoints.down('sm')]: {
    width: 370,
  },
}));

export const ActionDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
}));

export const StripeActionCard = styled(ActionCard)(() => ({
  width: '95%',
  marginTop: '28px',
}));

export const BasicLinearProgress = styled(LinearProgress)(() => ({
  '&.MuiLinearProgress-root': {
    height: 4,
    boxShadow: 'none',
    backgroundColor: '#AEAEAD',
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: '#AEAEAD',
  },
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#AEAEAD',
  },
}));

export const ColorLinearProgress = styled(LinearProgress)(() => ({
  '&.MuiLinearProgress-root': {
    height: 4,
    boxShadow: 'none',
    backgroundColor: '#AEAEAD',
  },
  '&.MuiLinearProgress-colorPrimary': {
    background: `linear-gradient(to right, rgb(100, 50, 190), rgb(220, 70, 5))`,
  },
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#AEAEAD',
  },
}));

export const AdjustCardWrapper = styled('div')(({ theme }) => ({
  '& .MuiCardHeader-content label': {
    fontFamily: 'Unify Sans SemiBold',
    '& svg': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
      position: 'relative',
      top: 2,
    },
    '& button .MuiTypography-root': {
      // Target the label in mobile (when it becomes a button)
      fontFamily: 'Unify Sans SemiBold',
    },
  },
  '& .MuiTypography-root.MuiCardHeader-subheader': {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiCardContent-root > div': {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
  },
}));

export const Bolded = styled('span')(() => ({
  fontFamily: 'Unify Sans Bold',
}));

export const SettingsSwitchWrapper = styled('div')(({ theme }) => ({
  '& .MuiFormGroup-root > .MuiFormControlLabel-root': {
    padding: 0,
    border: 'none',
    maxWidth: 350,
    '& .MuiTypography-root': {
      fontSize: 16,
      '& label': {
        maxHeight: 'none',
      },
      [theme.breakpoints.down('md')]: {
        letterSpacing: '-0.5px',
      },
    },
    '& svg': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      position: 'relative',
      marginLeft: theme.spacing(1),
      verticalAlign: 'bottom',
      bottom: 2,
      [theme.breakpoints.down('md')]: {
        verticalAlign: 'middle',
        height: 18,
        width: 18,
        marginLeft: 4,
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .MuiSwitch-root': {
        marginLeft: 0,
      },
      '& .MuiSwitch-switchBase': {
        padding: '1.5px',
      },
    },
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: '#AEAEAD',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: '27px',
}));

export const HeaderText = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '25px',
  letterSpacing: '0.18px',
  fontFamily: 'Unify Sans SemiBold',
  flexBasis: '100%',
}));

export const SectionLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'unset',
  }
}));

export const ViewInstructionsLink = styled('a')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 14,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export const RequiredFieldsContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  paddingTop: theme.spacing(1),
  fontSize: '14px',
  lineHeight: '21px',
}));

export const FrameBannerCard = styled(Card)(({ theme, BannerGradient, MobileBannerGradient }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: '8px',
  background: `url(${BannerGradient})`,
  backgroundSize: 'cover',
  color: theme.palette.common.white,

  '& .MuiCardHeader-root': {
    padding: theme.spacing(3, 3, 0, 3),
    fontSize: 20,
  },

  '& .MuiCardContent-root': {
    padding: theme.spacing(0, 3, 3, 3),
    fontSize: 18,
    display: 'flex',
    gap: '16px',

    '& .bodyWrapper': {
      paddingTop: theme.spacing(2),
    },
  },

  [theme.breakpoints.down('md')]: {
    background: `url(${MobileBannerGradient})`,
    backgroundSize: '100% 100%',
    '& .MuiCardContent-root': {
      fontSize: 18,
      display: 'flex',
      flexFlow: 'column',
    },
  },
}));

export const ExpiredCardContent = styled(CardContent)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const UnderlineAnchor = styled('a')(({ theme, fontSize, fontColor }) => ({
  fontSize: fontSize,
  color: fontColor || theme.palette.primary.main
}));

export const SpinnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

export const FullPageSpinnerWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));
