/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import queryString from 'query-string';
import i18n from './i18n/i18n';
import { loadSettingsData } from './shared/app-slice';
import {
  CHATBOT_PATH,
  CALL_SETTINGS_PATH,
  OLD_CALL_RECORDER_PATH,
  SCHEDULER_PATH,
  OLD_SCHEDULER_PATH,
  OLD_CHATBOT_PATH,
  ACCOUNT_PATH,
  CONTACT_US_PATH,
  OUTLOOK_AUTH_CALLBACK_PATH,
  CONTACT_FORM_PATH,
  CONFIG_PATH,
  WIDGET_SETTINGS_PATH,
  TOOLS_PATH,
  LISTINGS_GRADER_PATH,
  DASHBOARD_PATH,
  GRADER_PATH,
  GRADER_REDIRECT,
  PUBLIC_PATHS,
  NOT_FOUND,
} from './shared/app-constants';
import { initializeGTM } from './shared/util/tag-manager-utils';
import { initializeMixpanel } from './shared/analytics-utils';
import OutlookAuthRedirect from './pages/products/bookings/OutlookAuthRedirect';
import ContactUsPage from './pages/contactus/ContactUsPage';
import AccountLandingPage from './pages/products/account/AccountLandingPage';
import BookingsLandingPage from './pages/products/bookings/BookingsLandingPage';
import ConfigurationLandingPage from './pages/configuration/ConfigurationLandingPage';
import WidgetSettingsDialog from './pages/products/settings/WidgetSettingsDialog';
import ToolsDialog from './pages/tools/ToolsDialog';
import ListingsGrader from './pages/graders/listings-grader/ListingsGrader';
import DashboardPage from './pages/dashboard/DashboardPage';
import GraderLanding from './pages/graders/website/GraderLanding';
import GraderRedirect from './pages/graders/website/GraderRedirect';
import LocationChangeHook from './shared/location-change-hook';
import ScrollToTop from './shared/scroll-to-top';
import RedirectLanding from './pages/products/redirect/RedirectLanding';
import { isUserAuthenticated } from './shared/app-utils';
import FeatureActivationListener from './shared/hooks/FeatureActivationListener';
import GraderCompleteListener from './shared/hooks/GraderCompleteListener';
import ProtectedRoute from './shared/ProtectedRoute';
import WizardSubmitListener from './shared/hooks/WizardSubmitListener';
import NotFoundPage from './shared/notFound/NotFoundPage';

const {
  REACT_APP_ENABLE_TRACKJS,
  REACT_APP_TRACKJS_TOKEN,
  REACT_APP_TRACKJS_PROJECT,
} = process.env;

function App() {
  const baseUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    // Remove token param from url, not even used but sent if the user
    // came directly from the login app
    window.history.replaceState({}, document.title, queryString.exclude(document.location.href, ['token', 'authToken']));

    // Load User & User Orders & Business Info if we expect them to be authed OR
    // they are currently authed
    const isAuthed = isUserAuthenticated();
    if (PUBLIC_PATHS.indexOf(window.location.pathname) === -1 || isAuthed) {
      dispatch(loadSettingsData());
    }

    // Fire off initialization when this loads to ensure access to the given platforms.
    initializeGTM();

    // Initialize TrackJS for UI Error tracking
    if (REACT_APP_ENABLE_TRACKJS === 'true') {
      window.TrackJS && window.TrackJS.install({
        token: REACT_APP_TRACKJS_TOKEN,
        application: REACT_APP_TRACKJS_PROJECT,
      });
    }
  }, []);


  useEffect(() => {
    initializeMixpanel();
  }, []);

  return (
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename={baseUrl}>
          <LocationChangeHook />
          <ScrollToTop />
          {/**
            * Have this sit at the top level so the channel can survive redirects
            * and service multiple pages
          */}
          <GraderCompleteListener />
          <FeatureActivationListener />
          <WizardSubmitListener />
          <Routes>
            <Route path={`${SCHEDULER_PATH}/*`} element={<ProtectedRoute > <BookingsLandingPage /> </ProtectedRoute>} />
            <Route path={`${OLD_SCHEDULER_PATH}/*`} element={<ProtectedRoute > <BookingsLandingPage /> </ProtectedRoute>} />
            <Route path={`${CALL_SETTINGS_PATH}/*`} element={<ProtectedRoute > <RedirectLanding /> </ProtectedRoute>} />
            <Route path={`${OLD_CALL_RECORDER_PATH}/*`} element={<ProtectedRoute > <RedirectLanding /> </ProtectedRoute>} />
            <Route path={`${CHATBOT_PATH}/*`} element={<ProtectedRoute > <RedirectLanding /> </ProtectedRoute>} />
            <Route path={OLD_CHATBOT_PATH} element={<ProtectedRoute > <RedirectLanding /> </ProtectedRoute>} />
            <Route path={`${CONTACT_FORM_PATH}/*`} element={<ProtectedRoute > <RedirectLanding /> </ProtectedRoute>} />
            <Route path={OUTLOOK_AUTH_CALLBACK_PATH} element={<ProtectedRoute > <OutlookAuthRedirect /> </ProtectedRoute>} />
            {/* <Route path={ADMIN_PATH} element={<AdminPanel />} /> */}
            <Route path={ACCOUNT_PATH} element={<ProtectedRoute > <AccountLandingPage /> </ProtectedRoute>} />
            <Route path={`${ACCOUNT_PATH}/:currentScreen`} element={<ProtectedRoute > <AccountLandingPage /> </ProtectedRoute>} />
            <Route path={`${ACCOUNT_PATH}/*`} element={<ProtectedRoute > <AccountLandingPage /> </ProtectedRoute>} />
            <Route path={CONFIG_PATH} element={<ProtectedRoute > <ConfigurationLandingPage /> </ProtectedRoute>} />
            <Route path={`${CONFIG_PATH}/*`} element={<ProtectedRoute > <ConfigurationLandingPage /> </ProtectedRoute>} />
            <Route path={LISTINGS_GRADER_PATH} element={<ProtectedRoute > <ListingsGrader /> </ProtectedRoute>} />

            <Route path={TOOLS_PATH} element={<ToolsDialog />} />
            <Route path={`${TOOLS_PATH}/:currentScreen`} element={<ToolsDialog />} />
            <Route path={`${TOOLS_PATH}/*`} element={<ToolsDialog />} />
            {/* Dashboard routes */}
            <Route path={DASHBOARD_PATH} element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
            <Route path="/" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} exact />
            {/* Grader routes */}
            <Route path={GRADER_PATH} element={<ProtectedRoute > <GraderLanding /> </ProtectedRoute>} />
            <Route path={GRADER_REDIRECT} element={<ProtectedRoute > <GraderRedirect /> </ProtectedRoute>} />

            <Route path={CONTACT_US_PATH} element={<ProtectedRoute ><ContactUsPage /></ProtectedRoute>} />
            <Route path={`${CONTACT_US_PATH}/*`} element={<ProtectedRoute ><ContactUsPage /></ProtectedRoute>} />
            <Route path={`${WIDGET_SETTINGS_PATH}/:currentScreen`} element={<WidgetSettingsDialog />} />
            <Route path={`${WIDGET_SETTINGS_PATH}/*`} element={<WidgetSettingsDialog />} />
            <Route path={WIDGET_SETTINGS_PATH} element={<WidgetSettingsDialog />} />
            <Route path={NOT_FOUND} element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </I18nextProvider>
    </CookiesProvider>
  );
}

export default App;