import { useEffect } from 'react';
import styled from "@emotion/styled";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setWebsiteType } from "../../data/localiqCode-slice";
import { StepWrapper } from "../components/wizard-shared-components";
import { DRUPAL_KEY, JOOMLA_KEY, STANDARD_SITE_KEY, WORDPRESS_KEY } from "../wizard-constants";
import WizardFeatureTitle from '../components/WizardFeatureTitle';
import CodeIcon from '../../../../images/localiq-code-icon.svg';
import { Bolded } from '../../../../shared/settings-shared-components';

const WebsiteTypeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection:'column',
  gap: theme.spacing(3),
  height: "auto",
}));

const WebsiteDescription = styled(Typography)(() => (({ theme }) => ({
  fontSize: '18px',
  textAlign: 'left'
})));

const WebsiteQuestion = styled(Typography)(() => (({ theme }) => ({
  fontSize: '16px',
  textAlign: 'left',
  fontFamily: 'Unify Sans Semibold',
})));

const RadioWrapper = styled('div')(() => (({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '12px'
})));

export default function WebsiteType() {
  const dispatch = useDispatch();
  const websiteType = useSelector((state) => state.configuration.localiQCode.websiteType);
  const isTrackingCodeActive = useSelector((state) => state.appSettings.trackingCode?.isInstalled);

  function selectWebsite(e) {
    dispatch(setWebsiteType({ websiteType: e.target.value }));
  }

  useEffect(() => {
    sessionStorage.setItem("websiteType", websiteType);
  }, [websiteType]);

  return (
    <StepWrapper desktopFlexDirection='column' gapBetweenItems={2}>
      <WebsiteTypeWrapper>
        <WizardFeatureTitle
          icon={CodeIcon}
          text='LocaliQ Code'
        />
        <Typography variant='h3'> Website Type </Typography>
        <WebsiteDescription>
          Select the platform you use to manage your website.
          { isTrackingCodeActive && (<span><br/><Bolded>Already Installed?</Bolded> Click Save & Exit to begin using your widgets</span>)}
        </WebsiteDescription>
      <WebsiteQuestion>
        <RadioWrapper>
          <FormControl>
            <RadioGroup
              defaultValue={WORDPRESS_KEY}
              value={websiteType}
              onChange={selectWebsite}
            >
              <FormControlLabel label="WordPress site" value={WORDPRESS_KEY} control={<Radio />} />
              <FormControlLabel label="Drupal site" value={DRUPAL_KEY} control={<Radio />} />
              <FormControlLabel label="Joomla site" value={JOOMLA_KEY} control={<Radio />} />
              <FormControlLabel label="None/Other" value={STANDARD_SITE_KEY} control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </RadioWrapper>
      </WebsiteQuestion>
      </WebsiteTypeWrapper>
    </StepWrapper>
  )
}