import { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoadingFowarding, updateFeatures } from "../../pages/products/settings/data/widgetSettings-slice";
import { checkCallRecorderMessage, checkSettingsMessage } from "../websocket/websocket-utils";
import { SocketContext } from "../websocket/WebSocketProvider";

export default function FeatureActivationListener() {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const getCallRecorderInfo = useCallback((message) => {
    if (checkCallRecorderMessage(message)) {
      dispatch(setLoadingFowarding({ data: message.data, action: message.action }))
    }
  });

  const getSettingsInfo = useCallback((message) => {
    if (checkSettingsMessage(message)) {
      dispatch(updateFeatures({ formName: message.data.data.slug, isSelected: message.data.data.active }));
    }
  });

  useEffect(() => {
    const removeGetCall = socket.addMessageListener(getCallRecorderInfo);
    const removeGetSettings = socket.addMessageListener(getSettingsInfo);

    return () => {
      removeGetCall();
      removeGetSettings();
    }
  }, [socket]);


  return null;
}