import { createTheme } from '@mui/material';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';

// Add any v2 theme overrides here
const themeOverrides = {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            fontFamily: 'Unify Sans SemiBold',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F5F8FF',
          },
          '&.Mui-selected': {
            backgroundColor: '#0046FF !important',
            color: '#FFFFFF',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#0035C2',
            color: '#FFFFFF',
          },
        },
      },
    },
  },
};

export const DefaultTheme = createTheme(IQThemeV2, themeOverrides);
