import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { getStatusString } from '../../dashboard-utils';
import { ReactComponent as VerifiedIcon } from '../../assets/widget-status-verified-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/widget-status-warning-icon.svg';

const StyledStatusWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  direction: 'row',
  wrap: 'nowrap',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledLine = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  [theme.breakpoints.down('md')]: {
    border: `0.5px solid ${theme.palette.action.disabledBackground}`,
  },
  margin: theme.spacing(1, 0, 0, 0),
}));

const StyledLink = styled('div')(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 600,
  cursor: 'pointer',
  padding: theme.spacing(1.4, 0),
  width: 'max-content',
}));

const StyledContent = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
}));

export default function WidgetStatusRow({
  rowTitle,
  status,
  linkLabel,
  clickHandler,
  navKey,
}) {
  const statusStr = getStatusString(status, navKey);

  return (
    <Grid container item display="flex" direction="column">
      <Grid container item display="flex" direction="row" justifyContent="space-between">
        <StyledContent>{rowTitle}</StyledContent>
        <StyledStatusWrapper>
          <StyledContent>{statusStr}</StyledContent>
          {status ? <VerifiedIcon /> : <WarningIcon />}
        </StyledStatusWrapper>
      </Grid>
      <StyledLine />
      <StyledLink onClick={() => clickHandler(navKey)}>{linkLabel}</StyledLink>
    </Grid>
  );
}

WidgetStatusRow.propTypes = {
  rowTitle: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  linkLabel: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  navKey: PropTypes.string.isRequired,
};
