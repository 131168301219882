import { createAction, createSlice } from '@reduxjs/toolkit';
import combineSlices from '../../../redux/combine-slices';
import widgetFeatureSlice from './widget-feature.slice';
import businessFeatureSlice from './business-feature.slice';
import widgetColorSlice from './widget-color.slice';
import localiqCodeSlice from './localiqCode-slice';
import appointmentScheduleSlice from './appointment-schedule.slice';
import { DEFAULT_WELCOME_STEP, TRIAL_STARTED_STEP } from '../wizard/wizard-constants';
import { loadSettingsDataSuccess } from '../../../shared/app-slice';
import { convertTimestampToUTCDateString } from '../../../shared/app-utils';
import { buildWizardSteps } from '../wizard/wizard-utils';
import {
  ALL_STEP_IDS,
  WIZARD_STEP_SAVING_STATUS_IN_PROGRESS,
  WIZARD_STEP_SAVING_STATUS_SUCCESS,
  WIZARD_STEP_SAVING_STATUS_FAILURE,
  WEBSITE_TYPE_KEY,
  TRIAL_END_BEHAVIOR_PAUSE,
  COMPLETED_STATUS,
} from '../wizard/wizard-constants';
import {
  BUNDLE_SLUG,
  INITIAL_BUNDLE_TIER,
} from '../../../shared/app-constants';
import { updateFeaturesSuccess } from '../../products/settings/data/widgetSettings-slice';

const CONFIGURATION_SLICE_NAME = 'configuration';
export const startFreeTrial = createAction(`${CONFIGURATION_SLICE_NAME}/startFreeTrial`);

const initialState = {
  dirtySteps: [],
  isDirty: false,
  invalidSteps: [],
  isInvalid: false,
  isLoading: false,
  wizardSteps: {},
  wizardStepSavingStatus: null,
  currentStep: null,
  trialInfo: {},
  isLoadingSteps: false,
  status: '',
  submitError: false,
  startFreeTrialError: false,
  loadWizardConfigError: false,
  savedStep: '',
  loadedWizardConfig: false,
  exitAfterSave: false,
  saveError: false
};

const configurationSlice = createSlice({
  name: CONFIGURATION_SLICE_NAME,
  initialState,
  reducers: {
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySteps.indexOf(dirtyForm) === -1) {
        state.dirtySteps.push(dirtyForm);
        state.isDirty = true;
      }

      state.invalidSteps = state.invalidSteps.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSteps.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;
      state.dirtySteps = state.dirtySteps.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySteps.length > 0;

      state.invalidSteps = state.invalidSteps.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSteps.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSteps.indexOf(invalidForm) === -1) {
        state.invalidSteps.push(invalidForm);
        state.isInvalid = true;
      }
    },
    setWizardSteps: (state, action) => {
      const { steps, features, wizardStatus } = action.payload;
      state.wizardSteps = steps.reduce((acc, step, ind) => {
        // checking to see if a step requires a feature to be active
        if (step.requirement) {
          if (features[step.requirement]) {
            acc[step.id] = step;
            step.orderIndex = ind + 1;
          }
        } else {
          acc[step.id] = step;
          step.orderIndex = ind + 1;
        }

        if (state.currentStep.id === step.id) {
          // If this is the current step, update the reference so the index matches
          state.currentStep.orderIndex = step.orderIndex;
        }

        // If user saved & exit but toggled feature in engagement widget, automatically
        // set status to complete. If attempted to go back to wizard, sends them to localiq page
        if (wizardStatus === COMPLETED_STATUS) {
          action.payload.steps.forEach(el => {
            if (el.id === WEBSITE_TYPE_KEY) {
              state.currentStep = el
            }
          });
        }

        return acc;
      }, {});
    },
    triggerStep: (state, action) => {
      const { stepId } = action.payload;
      state.currentStep = state.wizardSteps[stepId];
    },
    startFreeTrialSuccess: (state, action) => {
      state.trialInfo.active = true;
      state.trialInfo.status = 'ACTIVE';
      state.trialInfo.startDate = convertTimestampToUTCDateString(action.payload.trialStart);
      state.trialInfo.endDate = convertTimestampToUTCDateString(action.payload.trialEnd);
      state.trialInfo.endBehavior = action.payload.trialEndBehavior;
      state.trialInfo.slug = BUNDLE_SLUG; // TODO: Someday pull from action.payload.items array
      state.trialInfo.tier = INITIAL_BUNDLE_TIER;
      state.startFreeTrialError = false;
    },
    startFreeTrialFailure: (state, action) => {
      state.startFreeTrialError = true;
    },
    saveWizardStep: (state, action) => {
      state.exitAfterSave = action.payload.exitAfterSave ? true : false;
      state.wizardStepSavingStatus = WIZARD_STEP_SAVING_STATUS_IN_PROGRESS;
      state.saveError = false
    },
    saveWizardStepError: (state, action) => {
      state.saveError = action.payload.saveError
    },
    saveWizardStepSuccess: (state, action) => {
      state.wizardStepSavingStatus = WIZARD_STEP_SAVING_STATUS_SUCCESS;
    },
    saveWizardStepFailure: (state, action) => {
      state.wizardStepSavingStatus = WIZARD_STEP_SAVING_STATUS_FAILURE;
    },
    resetSaveStatus: (state, action) => {
      // Clear save status and wizard config loaded
      // to always properly refresh and calculate the correct step
      // info when reopening the wizard.
      state.wizardStepSavingStatus = null;
      state.loadedWizardConfig = false;
    },
    loadWizardConfig: (state, action) => {
      state.isLoading = true;
      if (!state.currentStep) {
        state.currentStep = DEFAULT_WELCOME_STEP;
      }
    },
    loadWizardConfigSuccess: (state, action) => {
      const savedStep = action.payload[0]?.data?.currentStep;
      state.status = action.payload[0].status;

      state.isLoading = false;
      state.loadWizardConfigError = false;
      // NOTE: We keep loadedWizardConfig because it's always false until loaded
      // vs isLoading which is false, then true, then false.
      state.loadedWizardConfig = true;

      // If we have a savedStep persist it, then match up the step to set as the current
      // This is usually the savedStep + 1 index.  However, if we are already at the max
      // step it would just be the max index.
      if (savedStep) {
        let savedStepIndex = 0;
        const builtSteps = buildWizardSteps(ALL_STEP_IDS, state.widgetFeatures);
        for (let i = 0; i < builtSteps.length; i++) {
          const builtStep = builtSteps[i];
          if (builtStep.id === savedStep) {
            // Also we want to line the savedStep up with what we really show to easily send someone
            // back to what is really displayed as the 'savedStep'.  This means we need to
            // grab the next step id.. or the max if we are already at the end.
            state.savedStep = (i === builtSteps.length - 1) ? builtStep.id : builtSteps[i + 1].id;
            savedStepIndex = i;
            break;
          }
        }
        state.currentStep = builtSteps[savedStepIndex === (builtSteps.length - 1) ? savedStepIndex : savedStepIndex + 1];
      }
    },
    loadWizardConfigFailure: (state, action) => {
      state.loadWizardConfigError = true;
    },
    setWizardStatusAction: (state, action) => {
      state.submitError = false;
    },
    setWizardStatusSuccess: (state, action) => {
      state.status = COMPLETED_STATUS;
    },
    saveWizardStepCompletedFailure: (state) => {
      state.submitError = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      if (Array.isArray(action.payload.trials) && action.payload.trials.length > 0) {
        const { trials } = action.payload;
        const trialInfo = trials[0];
        state.trialInfo = trialInfo;

        // TODO: In the future this should load wherever the user was previously
        if (trialInfo.active && trialInfo.endBehavior === TRIAL_END_BEHAVIOR_PAUSE) {
          state.currentStep = TRIAL_STARTED_STEP;
        }
      }

      if (Array.isArray(action.payload.wizards) && action.payload.wizards.length > 0) {
        const wizard = action.payload.wizards[0];
        state.status = wizard.status;
      }
    });
    builder.addCase(updateFeaturesSuccess, (state) => {
      // Force completed status on update features success
      state.status = COMPLETED_STATUS;
    });
  },
});

export const {
  setStateClean,
  setStateDirty,
  setStateInvalid,
  setWizardSteps,
  triggerStep,
  saveWizardStep,
  saveWizardStepSuccess,
  saveWizardStepFailure,
  loadWizardConfig,
  loadWizardConfigSuccess,
  loadWizardConfigFailure,
  startFreeTrialSuccess,
  startFreeTrialFailure,
  setLoadingStep,
  setWizardStatusAction,
  setWizardStatusSuccess,
  saveWizardStepCompletedFailure,
  resetSaveStatus,
  saveWizardStepError
} = configurationSlice.actions;

export default combineSlices(
  configurationSlice.reducer,
  initialState,
  {
    widgetFeatures: widgetFeatureSlice,
    businessPhoneNumber: businessFeatureSlice,
    widgetColor: widgetColorSlice,
    localiQCode: localiqCodeSlice,
    appointmentAvailability: appointmentScheduleSlice,
  },
);
