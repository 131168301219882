import styled from '@emotion/styled';
import { Box, Fade } from '@mui/material';
import ProcessingBar from '../Processing/ProcessingBar';
import { FADE_TIMING } from '../../data/grader-constants';

const LoadingWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 5, 0),
  maxWidth: 350,
  marginTop: theme.spacing(2),
}));

/**
 * Handle showing the competitor result loading state
 */
export default function CompetitorLoading() {
  return (
    <Fade in timeout={FADE_TIMING}>
      <LoadingWrapper>
        <ProcessingBar processingText="Hang on while we analyze your competitor's site..." />
      </LoadingWrapper>
    </Fade>
  );
}
