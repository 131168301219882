import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationProvider, IQCheckbox } from '@gannettdigital/shared-react-components';
import { ErrorMessage } from '../../../../../shared/settings-shared-components';
import ChatbotStateChangedHook from '../../../../../shared/stateChangedHook/ChatbotStateChangedHook';
import { updateChatVisitorAction } from '../../data/general-settings-slice';
import { minimumNumCheck } from '../../../../../shared/app-utils';
import {
  CHATBOT_VISITOR_CALL_KEY,
  CHATBOT_VISITOR_EMAIL_KEY,
  CHATBOT_VISITOR_TEXT_KEY,
  CHATBOT_VISITOR_FORM,
  CHATBOT_VISITOR_SECTION,
  CALL_VISITORS,
  EMAIL_VISITORS,
  TEXT_VISITORS,
} from '../../chatbot-constants';
import SectionCard from '../../../../../shared/SectionCard';

const CheckWrapper = styled('div')(({ theme, visible }) => ({
  '& .MuiFormControlLabel-root': {
    border: `1px solid ${visible ? theme.palette.error.main : 'transparent'}`,
    borderRadius: '2px'
  },
}));

const FORM_KEYS = [
  CHATBOT_VISITOR_CALL_KEY,
  CHATBOT_VISITOR_EMAIL_KEY,
  CHATBOT_VISITOR_TEXT_KEY,
];

const schema = yup.object().shape({
  [CHATBOT_VISITOR_CALL_KEY]: yup.boolean(),
  [CHATBOT_VISITOR_EMAIL_KEY]: yup.boolean(),
  [CHATBOT_VISITOR_TEXT_KEY]: yup.boolean(),
}).test({
  test: (val) => minimumNumCheck(val, 2),
});

export default function ChatbotVisitorContactCard() {
  const dispatch = useDispatch();
  const selectedVisitorCall = useSelector(
    (state) => state.chatbot.generalSettings[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_CALL_KEY],
  );
  const selectedVisitorEmail = useSelector(
    (state) => state.chatbot.generalSettings[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_EMAIL_KEY],
  );
  const selectedVisitorText = useSelector(
    (state) => state.chatbot.generalSettings[CHATBOT_VISITOR_SECTION][CHATBOT_VISITOR_TEXT_KEY],
  );

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [CHATBOT_VISITOR_CALL_KEY]: selectedVisitorCall,
      [CHATBOT_VISITOR_EMAIL_KEY]: selectedVisitorEmail,
      [CHATBOT_VISITOR_TEXT_KEY]: selectedVisitorText,
    })),
  });

  const {
    formState,
    setError,
    clearErrors,
  } = methods;
  const { isValid } = formState;

  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (isValid) {
      setError(name);
    } else clearErrors(name);

    dispatch(updateChatVisitorAction({ fieldName: name, fieldValue: checked }))
  };

  return (
    <SectionCard
      id="chatbot-tool-tone-card"
      title="Follow Up Method"
      tooltipText="Choose 2-3 ways that you will get in touch with leads."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods} >
          <div style={{ marginTop: '24px' }}></div>
          <CheckWrapper visible={!isValid && !selectedVisitorCall}>
            <IQCheckbox label={CALL_VISITORS} field={CHATBOT_VISITOR_CALL_KEY} onChange={handleChange} />
          </CheckWrapper>
          <CheckWrapper visible={!isValid && !selectedVisitorEmail}>
            <IQCheckbox label={EMAIL_VISITORS} field={CHATBOT_VISITOR_EMAIL_KEY} onChange={handleChange} />
          </CheckWrapper>
          <CheckWrapper visible={!isValid && !selectedVisitorText}>
            <IQCheckbox label={TEXT_VISITORS} field={CHATBOT_VISITOR_TEXT_KEY} onChange={handleChange} />
          </CheckWrapper>
          {!isValid && (<ErrorMessage> Must have a minimum of 2 checked </ErrorMessage>)}
          <ChatbotStateChangedHook formName={CHATBOT_VISITOR_FORM} formKeys={FORM_KEYS} />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
}