import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Input } from '@mui/material';
import {
  PrimaryButton, ModalBackground, StripeActionCard, ActionDescription,
} from '../settings-shared-components';
import { ReactComponent as CloseIcon } from '../../images/close-x-icon.svg';
import { toggleEditModal, saveEditCreditCard, getListofErrorsAction } from '../../pages/products/account/data/credit-cards-slice';
import { ActionIcons, BACKDROP_CLICK } from '../app-constants';
import { EDIT_EXPIRED_CARD, EDIT_EXPIRED_MONTH, EDIT_EXPIRED_YEAR } from '../../pages/products/account/account-constants';
import {
  CloseModal,
  ModalDivider,
  CreditCardHeader,
  CCSectionWrapper,
  HeaderWrapper,
  CreditCardTitle,
  CancelButton,
  ButtonWrapper,
  TitleWrapper,
} from '../../pages/products/account/components/account-shared-components';

const CardElementWrapper = styled('div')(({ theme }) => ({
  width: '10%',
  [theme.breakpoints.down('md')]: {
    width: '15%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '30%',
  },
}));

const CardWrapper = styled('div')(({ theme }) => ({
  padding: '5px 24px',
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  borderRadius: '2px',
  display: 'flex',
  width: '90%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '24px',
  [theme.breakpoints.down('md')]: {
    width: '84%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '73%',
  },
}));

const CreditCardInputMonth = styled('input')(({ theme }) => ({
  fontFamily: 'Unify Sans',
  border: 'none',
  fontSize: 16,
  width: '23%',
  '&::placeholder': {
    color: theme.palette.common.black,
  },
  '&::-webkit-outer-spin-button': {
    display: 'none',
  },
  '&::-webkit-inner-spin-button': {
    display: 'none',
  },
}));

const CreditCardInputYear = styled('input')(({ theme }) => ({
  fontFamily: 'Unify Sans',
  border: 'none',
  fontSize: 16,
  width: '50%',
  '&::placeholder': {
    color: theme.palette.common.black,
  },
  '&::-webkit-outer-spin-button': {
    display: 'none',
  },
  '&::-webkit-inner-spin-button': {
    display: 'none',
  },
}));

// Allow 0-9 or empty
const onlyNumRegex = /^(\s*|[0-9]+)$/;

const isValidChanged = (newVal, oldVal) => {
  if (newVal === '' || newVal === null || newVal === undefined) {
    return false;
  }

  return newVal !== oldVal;
};

export default function EditCreditCardModal({ isOpen, selectedCard }) {
  const dispatch = useDispatch();
  const cardNumber = useSelector((state) => state.account.creditCards.editCardNumber);
  const cardMonth = useSelector((state) => state.account.creditCards.editCardMonth);
  const cardYear = useSelector((state) => state.account.creditCards.editCardYear);
  const paymentId = useSelector((state) => state.account.creditCards.editPaymentId);
  const editCardSuccessful = useSelector((state) => state.account.creditCards.editCardSuccess);

  const [newCardMonth, setCardMonth] = useState('');
  const [newCardYear, setCardYear] = useState('');
  const [err, setErr] = useState('');

  const hasChanges = (isValidChanged(newCardMonth, cardMonth)
    || isValidChanged(newCardYear, cardYear)) && newCardMonth && newCardYear;

  const dismissModal = (ev, reason) => {
    if (reason === BACKDROP_CLICK) {
      return;
    }
    dispatch(toggleEditModal({ toggleEditModal: false }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(saveEditCreditCard({
      paymentId,
      newCardMonth: newCardMonth || cardMonth,
      newCardYear: newCardYear || cardYear,
    }));
    selectedCard(paymentId, true);
  }

  const monthChange = (e) => {
    const onlyNumbers = e.target.value.match(onlyNumRegex);

    if (onlyNumbers) {
      setCardMonth(e.target.value);
    }
  };

  const yearChange = (e) => {
    const onlyNumbers = e.target.value.match(onlyNumRegex);

    if (onlyNumbers) {
      setCardYear(e.target.value);
    }
  };

  useEffect(() => {
    if (editCardSuccessful) {
      dismissModal();
    } else {
      if (newCardMonth && !editCardSuccessful) {
        setErr(EDIT_EXPIRED_MONTH);
      }

      if (newCardYear && !editCardSuccessful) {
        setErr(EDIT_EXPIRED_YEAR);
      }

      if (newCardMonth && newCardYear && !editCardSuccessful) {
        setErr(EDIT_EXPIRED_CARD);
      }
    }
    dispatch(getListofErrorsAction());
  }, [editCardSuccessful]);

  // Set month and year based off backend
  useEffect(() => {
    if (cardMonth) {
      setCardMonth(cardMonth);
    }
  }, [cardMonth, setCardMonth]);

  useEffect(() => {
    if (cardYear) {
      setCardYear(cardYear);
    }
  }, [cardYear, setCardYear]);

  return (
    <Modal open={isOpen} onClose={dismissModal}>
      <ModalBackground>
        <HeaderWrapper>
          <TitleWrapper>
            <CreditCardHeader> Edit Credit Card</CreditCardHeader>
          </TitleWrapper>
          <div>
            <CloseModal onClick={dismissModal}><CloseIcon /></CloseModal>
          </div>
        </HeaderWrapper>
        <ModalDivider />
        {err && (
          <StripeActionCard
            id="stripe-error-modal-action-banner"
            type="ERROR_TYPE"
            icons={ActionIcons}
          >
            <ActionDescription> {err} </ActionDescription>
          </StripeActionCard>
        )}
        <form onSubmit={handleSubmit}>
          <CCSectionWrapper>
            <CreditCardTitle> Card Number</CreditCardTitle>
            <CardWrapper>
              <Input name="credit-card" value={`**** **** **** ${cardNumber}`} disabled disableUnderline="true" />
              <CardElementWrapper>
                <CreditCardInputMonth name="expiration-month" onChange={monthChange} type="text" value={newCardMonth} minLength={0} maxLength={2} /> /
                <CreditCardInputYear name="expiration-year" onChange={yearChange} type="text" value={newCardYear} minLength={0} maxLength={4} />
              </CardElementWrapper>
            </CardWrapper>
          </CCSectionWrapper>
          <ButtonWrapper>
            <CancelButton className="stripe-save-btn" variant="outlined" onClick={dismissModal}>
              Cancel
            </CancelButton>
            <PrimaryButton className="stripe-save-btn" variant="contained" type="submit" disabled={!hasChanges}>
              Save
            </PrimaryButton>
          </ButtonWrapper>
        </form>
      </ModalBackground>
    </Modal>
  );
}

EditCreditCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedCard: PropTypes.func.isRequired,
};
