import {
  ALL_STEPS_BY_ID,
  CURRENT_STEP_KEY,
  FORMS_BY_STEP,
  STEPS_KEY,
  WELCOME_KEY,
  WIDGET_FEATURE_KEY,
  WIDGET_COLOR_KEY,
  APPOINTMENT_SCHEDULE_KEY,
  BUSINESS_PHONE_KEY,
  WIDGET_SETUP_COMPLETE_PAGE_KEY,
  WEBSITE_TYPE_KEY,
  LOCALIQ_CODE_KEY,
} from './wizard-constants';
import WebsiteType from './steps/WebsiteType';
import BusinessPhoneStep from './steps/BusinessPhoneStep';
import FeatureStep from './steps/FeatureStep';
import WelcomeStep from './steps/WelcomeStep';
import WidgetColorStep from './steps/WidgetColorStep';
import AppointmentScheduleStep from './steps/AppointmentScheduleStep';
import LocaliQCodeStep from './steps/LocaliQCodeStep';
import SetUpCompleteStep from './steps/SetUpCompleteStep'

/**
 * Builds the steps for the wizard given the array of keys.
 * TODO: For now this is a direct reference of keys. In the future can be converted to
 * an intermediary key set that translates to a set of steps
 */

export const buildWizardSteps = (keySet, features) => {
  let previousStep = null;
  const buildSteps = [];

  for (let ind = 0; ind < keySet.length; ind += 1) {
    // need to check requirements before adding to build steps
    let requirementPassed = true;
    const stepKey = keySet[ind];
    const foundStep = { ...ALL_STEPS_BY_ID[stepKey] };

    if (foundStep?.requirement) {
      requirementPassed = features[foundStep.requirement];
    }

    if (previousStep && requirementPassed) {
      foundStep.previousStep = previousStep.id;
      previousStep.nextStep = foundStep.id;
    }

    // Re-set the previousStep on each iteration
    if (requirementPassed) {
      previousStep = foundStep;
      buildSteps.push(foundStep);
    }
  }
  return buildSteps;
};

export const constructConfigBody = (currentStep, stateObj, stateKey, saveConfig) => {
  const saveState = {};
  const stateSelectors = [saveConfig[currentStep]];

  stateSelectors.forEach((selector) => {
    saveState[selector] = stateObj[stateKey][selector];
  });

  const saveBody = {
    [CURRENT_STEP_KEY]: currentStep,
    [STEPS_KEY]: saveState,
  };
  return saveBody;
};

export const generateIdempotencyKey = (name, userId, additionalData) => {
  let idempotencyKey = `${name}-${userId}`;
  if (additionalData) { idempotencyKey = `${idempotencyKey}-${additionalData}`; }
  if (idempotencyKey.length > 255) {
    throw new Error(`Error, idempotencyKey was too long.  Must be less than 255 characters, this was ${idempotencyKey.length}`);
  }

  return idempotencyKey;
};

export const isNotNullOrUndefined = (val) => (
  val !== null && val !== undefined
);

export const checkInvalidStep = (stepId, invalidForms) => {
  const stepForms = FORMS_BY_STEP[stepId];
  if (stepForms && invalidForms.length > 0) {
    for (let index = 0; index < stepForms.length; index += 1) {
      const form = stepForms[index];
      const foundForm = invalidForms.includes(form);
      if (foundForm) {
        return true;
      }
    }
  }
  return false;
};

export const getMainPanelForStep = (stepKey) => {
  switch(stepKey) {
    case WELCOME_KEY:
      return WelcomeStep;
    case WIDGET_FEATURE_KEY:
      return FeatureStep;
    case WIDGET_COLOR_KEY:
      return WidgetColorStep;
    case APPOINTMENT_SCHEDULE_KEY:
      return AppointmentScheduleStep;
    case BUSINESS_PHONE_KEY:
      return BusinessPhoneStep;
    case WIDGET_SETUP_COMPLETE_PAGE_KEY:
      return SetUpCompleteStep;
    case WEBSITE_TYPE_KEY:
      return WebsiteType;
    case LOCALIQ_CODE_KEY:
      return LocaliQCodeStep;
    default:
      return WelcomeStep;
  }
}
