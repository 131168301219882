
export const TIME_SLOTS_KEY = 'timeSlots';
export const DAY_TIME_SLOTS_KEY = 'dayTimeSlots';

// Day keys
export const MONDAY_KEY = 'MONDAY';
export const TUESDAY_KEY = 'TUESDAY';
export const WEDNESDAY_KEY = 'WEDNESDAY';
export const THURSDAY_KEY = 'THURSDAY';
export const FRIDAY_KEY = 'FRIDAY';
export const SATURDAY_KEY = 'SATURDAY';
export const SUNDAY_KEY = 'SUNDAY';

// Array of all day keys
export const All_DAY_KEYS = [
  MONDAY_KEY, TUESDAY_KEY,
  WEDNESDAY_KEY, THURSDAY_KEY,
  FRIDAY_KEY, SATURDAY_KEY, SUNDAY_KEY,
];

export const daysSortLookup = {
  [MONDAY_KEY]: 1,
  [TUESDAY_KEY]: 2,
  [WEDNESDAY_KEY]: 3,
  [THURSDAY_KEY]: 4,
  [FRIDAY_KEY]: 5,
  [SATURDAY_KEY]: 6,
  [SUNDAY_KEY]: 7,
};

// Error Messages
export const TIME_SLOT_OVERLAP = 'Time slot overlap';
export const END_BEFORE_START = 'End before start';
export const START_END_ARE_EQUAL = 'Start and end are the same';

// Closed days error constants
export const END_DATE_BEFORE_START_DATE = 'Start date can\'t start after end date';

// Common range constants
export const NINE_AM = { value: '09:00:00', description: '9:00 am' };
export const FIVE_PM = { value: '17:00:00', description: '5:00 pm' };

export const TIME_VALUES = [
  { value: '00:00:00', description: '12:00 am' },
  { value: '00:30:00', description: '12:30 am' },
  { value: '01:00:00', description: '1:00 am' },
  { value: '01:30:00', description: '1:30 am' },
  { value: '02:00:00', description: '2:00 am' },
  { value: '02:30:00', description: '2:30 am' },
  { value: '03:00:00', description: '3:00 am' },
  { value: '03:30:00', description: '3:30 am' },
  { value: '04:00:00', description: '4:00 am' },
  { value: '04:30:00', description: '4:30 am' },
  { value: '05:00:00', description: '5:00 am' },
  { value: '05:30:00', description: '5:30 am' },
  { value: '06:00:00', description: '6:00 am' },
  { value: '06:30:00', description: '6:30 am' },
  { value: '07:00:00', description: '7:00 am' },
  { value: '07:30:00', description: '7:30 am' },
  { value: '08:00:00', description: '8:00 am' },
  { value: '08:30:00', description: '8:30 am' },
  NINE_AM,
  { value: '09:30:00', description: '9:30 am' },
  { value: '10:00:00', description: '10:00 am' },
  { value: '10:30:00', description: '10:30 am' },
  { value: '11:00:00', description: '11:00 am' },
  { value: '11:30:00', description: '11:30 am' },
  { value: '12:00:00', description: '12:00 pm' },
  { value: '12:30:00', description: '12:30 pm' },
  { value: '13:00:00', description: '1:00 pm' },
  { value: '13:30:00', description: '1:30 pm' },
  { value: '14:00:00', description: '2:00 pm' },
  { value: '14:30:00', description: '2:30 pm' },
  { value: '15:00:00', description: '3:00 pm' },
  { value: '15:30:00', description: '3:30 pm' },
  { value: '16:00:00', description: '4:00 pm' },
  { value: '16:30:00', description: '4:30 pm' },
  FIVE_PM,
  { value: '17:30:00', description: '5:30 pm' },
  { value: '18:00:00', description: '6:00 pm' },
  { value: '18:30:00', description: '6:30 pm' },
  { value: '19:00:00', description: '7:00 pm' },
  { value: '19:30:00', description: '7:30 pm' },
  { value: '20:00:00', description: '8:00 pm' },
  { value: '20:30:00', description: '8:30 pm' },
  { value: '21:00:00', description: '9:00 pm' },
  { value: '21:30:00', description: '9:30 pm' },
  { value: '22:00:00', description: '10:00 pm' },
  { value: '22:30:00', description: '10:30 pm' },
  { value: '23:00:00', description: '11:00 pm' },
  { value: '23:30:00', description: '11:30 pm' },
];

export const defaultDaySlots = [{
  day: 'Monday',
  selected: true,
  schedules: [{
    startTime: NINE_AM.value,
    endTime: FIVE_PM.value,
  }],
},
{
  day: 'Tuesday',
  selected: true,
  schedules: [{
    startTime: NINE_AM.value,
    endTime: FIVE_PM.value,
  }],
},
{
  day: 'Wednesday',
  selected: true,
  schedules: [{
    startTime: NINE_AM.value,
    endTime: FIVE_PM.value,
  }],
},
{
  day: 'Thursday',
  selected: true,
  schedules: [{
    startTime: NINE_AM.value,
    endTime: FIVE_PM.value,
  }],
},
{
  day: 'Friday',
  selected: true,
  schedules: [{
    startTime: NINE_AM.value,
    endTime: FIVE_PM.value,
  }],
},
{
  day: 'Saturday',
  selected: false,
  schedules: [{
    startTime: '',
    endTime: '',
  }],
},
{
  day: 'Sunday',
  selected: false,
  schedules: [{
    startTime: '',
    endTime: '',
  }],
}];

export const defaultDaysByDayKey = defaultDaySlots.reduce((acc, dayObj) => {
  acc[dayObj.day] = dayObj;
  return acc;
}, {});
