import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { IQFormSelect } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { GridRow } from '../settings-shared-components';
import { US_TIMEZONES } from '../util/get-local-timezone.utils';

const TimeZoneSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  marginBottom: theme.spacing(1),
}));

export default function TimezoneWidget({ timezoneKey , sectionTitle, handleTimezoneChange }){
  return(
		<Grid container item direction="column" xs={12} sm={12} md={"auto"}>
      <TimeZoneSectionTitle>{ sectionTitle || "Time Zone" }</TimeZoneSectionTitle>
      <form>
        <Grid container>
          <GridRow>
            <IQFormSelect
              sx={{ background: '#FFFF' }}
              name={timezoneKey}
              items={US_TIMEZONES}
              adornmentIcon={<PublicIcon />}
              onChange={handleTimezoneChange}
            />
          </GridRow>
        </Grid>
      </form>
    </Grid>
  );
}

TimezoneWidget.propTypes = {
  timezoneKey: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
  handleTimezoneChange: PropTypes.func.isRequired,
};
