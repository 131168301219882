import { createSlice } from '@reduxjs/toolkit';
import { HTML_TYPE } from '../../../../shared/admin-constants';
import { closeDetailsAction } from './admin-shared-actions';

const initialState = {
  isLoading: false,
  chatHTMLTranscript: null,
  chatTextTranscript: null,
};

const adminChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    retrieveChatTranscript: (state) => {
      state.isLoading = true;
      state.chatHTMLTranscript = null;
      state.chatTextTranscript = null;
    },
    retrieveChatTranscriptSuccess: (state, action) => {
      if (action.payload.type === HTML_TYPE) {
        const { data } = action.payload;
        // Confim we have real data
        const bodyInd = data.indexOf('<body>');
        const bodyEnd = data.indexOf('</body>');
        state.chatHTMLTranscript = bodyEnd - bodyInd > 6 ? data : null;
      } else {
        state.chatTextTranscript = action.payload.data;
      }
      state.isLoading = false;
    },
    retrieveChatTranscriptFailure: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeDetailsAction, (state, action) => {
      state.chatHTMLTranscript = null;
      state.chatTextTranscript = null;
    });
  },
});

export const {
  retrieveChatTranscript,
  retrieveChatTranscriptSuccess,
  retrieveChatTranscriptFailure,
} = adminChatSlice.actions;
export default adminChatSlice;
