import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQColorWidget, ValidationProvider } from '@gannettdigital/shared-react-components';
import { COLOR_TO_THEME, COLOR_WIDGET_COLORS, DEFAULT_WIDGET_COLOR, THEME_TO_COLOR } from '../../../../shared/app-constants';
import WidgetPreview from '../components/WidgetPreview';
import { StepWrapper } from '../components/wizard-shared-components';
import { updateColorField } from '../../data/widget-color.slice';
import { CALL_TRACKER_FEATURE, CHAT_FEATURE, COLOR_KEY, CONTACT_FORM_FEATURE, SCHEDULER_FEATURE } from '../../data/slice-constants';
import { WIDGET_COLOR_KEY } from '../wizard-constants';
import WizardFeatureTitle from '../components/WizardFeatureTitle';
import DoubleQuoteIcon from '../../../../images/double-quote.svg';

const WidgetColorStepContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  maxWidth: '360px',
  padding: '0 7rem',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 1),
    maxWidth: 'unset',
  },
}));

const WidgetColorStepTitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '30px',
}));

const WidgetColorStepDescription = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '24px',
}));

export default function WidgetColorStep({
  stepData
}) {
  const { sideText } = stepData;
  const dispatch = useDispatch();
  const {
    [SCHEDULER_FEATURE]: isSchedulerOn,
    [CALL_TRACKER_FEATURE]: isCallTrackerOn,
    [CHAT_FEATURE]: isChatOn,
    [CONTACT_FORM_FEATURE]: isMessageOn,
  } = useSelector(
    (state) => state.configuration.widgetFeatures,
  );
  const existingTheme = useSelector((state) => state.configuration[WIDGET_COLOR_KEY][COLOR_KEY]);
  const schema = yup.object().shape({
    [COLOR_KEY]: yup.string(),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [COLOR_KEY]: THEME_TO_COLOR[existingTheme] || DEFAULT_WIDGET_COLOR,
    })),
  });

  const handleColorChange = (e) => {
    const theme = COLOR_TO_THEME[e.target.value];
    dispatch(
      updateColorField({ [COLOR_KEY]: theme })
    );
  };

  return (
    <StepWrapper desktopFlexDirection="row" gapBetweenItems={2}>
      <WidgetColorStepContent>
        <WizardFeatureTitle
          icon={DoubleQuoteIcon}
          text="Engagement Widget"
        />
        <WidgetColorStepTitle variant="h4">
          Widget Color
        </WidgetColorStepTitle>
        <WidgetColorStepDescription variant="body1">
          Select a color for your widgets and scheduling site.
        </WidgetColorStepDescription>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <form>
              <IQColorWidget
                name={COLOR_KEY}
                colors={COLOR_WIDGET_COLORS}
                onChange={handleColorChange}
                optionsPerRow={4}
              />
            </form>
          </FormProvider>
        </ValidationProvider>
      </WidgetColorStepContent>
      <WidgetPreview
        isSchedulerOn={isSchedulerOn}
        isCallTrackerOn={isCallTrackerOn}
        isChatOn={isChatOn}
        isMessageOn={isMessageOn}
        existingTheme={existingTheme}
      />
    </StepWrapper>
  );
}

WidgetColorStep.propTypes = {
  stepData: PropTypes.object.isRequired,
};
