import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ReactComponent as RecentActivityTemplate } from '../../assets/recent-activity-template.svg';
import { ReactComponent as RecentActivityTemplateMobile } from '../../assets/recent-activity-template-mobile.svg';
import { CONFIG_PATH } from '../../../../shared/app-constants';
import { TRIAL_SOURCES, TRIAL_SOURCE_KEY, trackDashboardCardClick } from '../../../../shared/analytics-utils';

const FullWidthWrapper = styled('div')(({ theme }) => ({
  width: '100%',
}));

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(7),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const trackSetupClick = () => {
  trackDashboardCardClick('Recent Activity', 'set up your widget');
  sessionStorage.setItem(TRIAL_SOURCE_KEY, TRIAL_SOURCES.DASHBOARD);
};

export default function NotActivated() {
  return (
    <FullWidthWrapper>
      <StyledContainer>
        { isMobile ? <RecentActivityTemplateMobile /> : <RecentActivityTemplate /> }
        <Typography fontSize="18px" width={isMobile ? '90%' : '70%'} textAlign="center">
          Once you&nbsp;
          <StyledLink to={CONFIG_PATH} onClick={trackSetupClick}>set up your widget,</StyledLink>
          &nbsp;your leads will appear here.
        </Typography>
      </StyledContainer>
    </FullWidthWrapper>
  );
}
