import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const { REACT_APP_STRIPE_API_LIVE_KEY, REACT_APP_STRIPE_API_TEST_KEY } = process.env;

// Judging by the docs, these 2 promises should be defined outside the component
// https://stripe.com/docs/stripe-js/react
const stripePromise = loadStripe(REACT_APP_STRIPE_API_LIVE_KEY);
const stripeTestPromise = loadStripe(REACT_APP_STRIPE_API_TEST_KEY);

export default function withStripe(WrappedComponent) {
  return (props) => {
    const userObj = useSelector((state) => state.appSettings.user);
    const withStripeProps = { ...props };
    const hasNoCustomerError = useSelector((state) => state.account.stripe.hasNoCustomerError);

    return (
      hasNoCustomerError ? (
        <Elements stripe={userObj && userObj.internal ? stripeTestPromise : stripePromise}>
          <WrappedComponent />
        </Elements>
      ) : (
        (stripePromise) && <Elements stripe={userObj && userObj.internal ? stripeTestPromise : stripePromise}>
          <WrappedComponent {...withStripeProps} />
        </Elements>
      )
    )
  }
};