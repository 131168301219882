import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

const SkeletonText = styled(Skeleton)(({ bgcolor = '#0000000A' }) => ({
  '&.MuiSkeleton-string': {
    borderRadius: '4px',
    background: bgcolor,
  },
}));

export default SkeletonText;