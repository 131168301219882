import { createSlice } from '@reduxjs/toolkit';
import { resetCallRecorderCompleteAction } from './call-settings-slice';
import { CALL_TRACKER_SLUG } from '../../../../shared/app-constants';
import { loadSettingsDataSuccess } from '../../../../shared/app-slice';
import {
  CALL_VERIFICATION_ENABLED_KEY,
  CALL_VERIFICATION_PROMPT_KEY,
  CURRENT_FORWARDING_NUMBER,
  CURRENT_FORWARDING_TYPE,
  CURRENT_FORWARDING_UUID,
  ORIGINAL_NUMBER,
  SELECTABLE_FORWARDING_NUMBERS,
  SELECTED_FORWARDING_NUMBER_KEY,
  SELECTED_FORWARDING_TYPE_KEY,
  SELECTED_FORWARDING_UUID_KEY,
  NEW_BASE_NUMBER_KEY,
  CALL_RECORDING_ACTIVE_KEY,
  LOCAL_TYPE,
  GENERATE_NUMBERS_FAILED,
  IS_GENERATING_NUMBERS,
} from '../call-settings-constants';
import { loadedCallSettingsData, saveCallSettings, saveCallSettingsSuccess } from './call-settings-actions';
import { setWizardStatusSuccess } from '../../../configuration/data/configuration-slice';

const generalSettingsSlice = createSlice({
  name: 'general_settings',
  initialState: {
    [ORIGINAL_NUMBER]: null,
    [NEW_BASE_NUMBER_KEY]: null,
    [CALL_VERIFICATION_ENABLED_KEY]: true,
    [CURRENT_FORWARDING_NUMBER]: null,
    [CURRENT_FORWARDING_UUID]: null,
    [CURRENT_FORWARDING_TYPE]: null,
    [SELECTED_FORWARDING_NUMBER_KEY]: null,
    [SELECTED_FORWARDING_TYPE_KEY]: null,
    [SELECTED_FORWARDING_UUID_KEY]: null,
    [SELECTABLE_FORWARDING_NUMBERS]: [],
    [CALL_VERIFICATION_PROMPT_KEY]: null,
    [GENERATE_NUMBERS_FAILED]: false,
    [CALL_RECORDING_ACTIVE_KEY]: false,
    [IS_GENERATING_NUMBERS]: false,
  },
  reducers: {
    updateGeneralSettingsField: (state, action) => {
      state[action.payload.fieldName] = action.payload.fieldValue;
      // If the user sets a new biz number, reset any selected forwarding fields
      if (action.payload.fieldName === NEW_BASE_NUMBER_KEY) {
        state[SELECTED_FORWARDING_NUMBER_KEY] = null;
        state[SELECTED_FORWARDING_TYPE_KEY] = null;
        state[SELECTED_FORWARDING_UUID_KEY] = null;
        state[SELECTABLE_FORWARDING_NUMBERS] = null;
      }
    },
    generateForwardingNumbers: (state, action) => {
      state[IS_GENERATING_NUMBERS] = true;
      state[CALL_RECORDING_ACTIVE_KEY] = true;
      state[GENERATE_NUMBERS_FAILED] = false;
      if (!action.payload) {
        action.payload = {};
      }
      if (!action.payload.originalNumber) {
        action.payload.originalNumber = state[ORIGINAL_NUMBER];
      }
    },
    generateForwardingNumbersSuccess: (state, action) => {
      let foundLocal = false;
      const trackingNumbers = action.payload;
      state[IS_GENERATING_NUMBERS] = false;
      state[SELECTABLE_FORWARDING_NUMBERS] = trackingNumbers.reduce((acc, number) => {
        const baseObj = {
          type: number.numberType,
          trackingNumber: number.trackingNumber,
          trackingUuId: number.trackingUuId,
        };

        if (!foundLocal && baseObj.type === LOCAL_TYPE) {
          foundLocal = true;
          baseObj.isRecommended = true;
        }
        acc.push(baseObj);
        return acc;
      }, []);
    },
    generateForwardingNumbersFailure: (state) => {
      state[IS_GENERATING_NUMBERS] = false;
      state[SELECTABLE_FORWARDING_NUMBERS] = [];
      state[GENERATE_NUMBERS_FAILED] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetCallRecorderCompleteAction, (state, action) => {
      const { resetData } = action.payload;
      let adjustedPhone = resetData.originalNumber || '';
      if (adjustedPhone && adjustedPhone.indexOf('+1') === -1) {
        adjustedPhone = `+1${adjustedPhone}`;
      }

      state[ORIGINAL_NUMBER] = adjustedPhone;
      state[CURRENT_FORWARDING_NUMBER] = resetData.selectedTrackingNumber;
      state[CURRENT_FORWARDING_UUID] = resetData.selectedTrackingUuid;
      state[CURRENT_FORWARDING_TYPE] = resetData.selectedTrackingNumberType;
      state[SELECTED_FORWARDING_NUMBER_KEY] = resetData.selectedTrackingNumber;
      state[SELECTED_FORWARDING_UUID_KEY] = resetData.selectedTrackingUuid;
      state[SELECTED_FORWARDING_TYPE_KEY] = resetData.selectedTrackingNumberType;
      state[CALL_VERIFICATION_PROMPT_KEY] = resetData.callVerifyPrompt;
      state[CALL_VERIFICATION_ENABLED_KEY] = resetData.callVerify === null ? true : resetData.callVerify;
      // Can return null, which the component doesn't like
      state[CALL_RECORDING_ACTIVE_KEY] = resetData.callRecording || false;
    });
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const { business } = action.payload;
      if (business) {
        if (!state[CALL_VERIFICATION_PROMPT_KEY]) {
          state[CALL_VERIFICATION_PROMPT_KEY] = business.name || '';
        }
        let adjustedPhone = business.businessPhone || '';
        if (adjustedPhone && adjustedPhone.indexOf('+1') === -1) {
          adjustedPhone = `+1${adjustedPhone}`;
        }
        if (!state[ORIGINAL_NUMBER] || state[ORIGINAL_NUMBER] === '') {
          state[ORIGINAL_NUMBER] = adjustedPhone;
        }
      }
    });
    builder.addCase(loadedCallSettingsData, (state, action) => {
      const { productInfo, productType } = action.payload;
      if (productType && productType.slug === CALL_TRACKER_SLUG) {
        let adjustedPhone = productInfo.originalNumber;
        if (adjustedPhone && adjustedPhone.indexOf('+1') === -1) {
          adjustedPhone = `+1${adjustedPhone}`;
        }

        state[ORIGINAL_NUMBER] = adjustedPhone;
        state[CURRENT_FORWARDING_NUMBER] = productInfo.selectedTrackingNumber;
        state[CURRENT_FORWARDING_UUID] = productInfo.selectedTrackingUuid;
        state[CURRENT_FORWARDING_TYPE] = productInfo.selectedTrackingNumberType;
        state[SELECTED_FORWARDING_NUMBER_KEY] = productInfo.selectedTrackingNumber;
        state[SELECTED_FORWARDING_UUID_KEY] = productInfo.selectedTrackingUuid;
        state[SELECTED_FORWARDING_TYPE_KEY] = productInfo.selectedTrackingNumberType;
        state[CALL_VERIFICATION_PROMPT_KEY] = productInfo.callVerifyPrompt;
        state[CALL_VERIFICATION_ENABLED_KEY] = productInfo.callVerify === null ? true : productInfo.callVerify;
        // Can return null, which the component doesn't like
        state[CALL_RECORDING_ACTIVE_KEY] = productInfo.callRecording || false;
      }
    });
    builder.addCase(saveCallSettingsSuccess, (state, action) => {
      const { data } = action.payload;
      // Update current and selected
      state[CURRENT_FORWARDING_NUMBER] = data.selectedTrackingNumber;
      state[CURRENT_FORWARDING_UUID] = data.selectedTrackingUuid;
      state[CURRENT_FORWARDING_TYPE] = data.selectedTrackingNumberType;
      state[SELECTED_FORWARDING_NUMBER_KEY] = data.selectedTrackingNumber;
      state[SELECTED_FORWARDING_UUID_KEY] = data.selectedTrackingUuid;
      state[SELECTED_FORWARDING_TYPE_KEY] = data.selectedTrackingNumberType;
    });
    builder.addCase(saveCallSettings, (state) => {
      // If we don't have a og number, attempt to assign from new base
      if (state[NEW_BASE_NUMBER_KEY] && state[NEW_BASE_NUMBER_KEY] !== state[ORIGINAL_NUMBER]) {
        state[ORIGINAL_NUMBER] = state[NEW_BASE_NUMBER_KEY];
      }
      // Remove country code from OG number on any save
      if (state[ORIGINAL_NUMBER]) {
        const newPhone = state[ORIGINAL_NUMBER].replace('+1', '');
        state[ORIGINAL_NUMBER] = newPhone;
      }

      // Null indicates no interaction, but we want default to be ON
      if (state[CALL_VERIFICATION_ENABLED_KEY] === null && state[CALL_VERIFICATION_PROMPT_KEY]) {
        state[CALL_VERIFICATION_ENABLED_KEY] = true;
      }
    });
    // TODO: remove after #926 gets merged
    // https://github.com/GannettDigital/localiq-dms-product-settings-mgmnt/pull/926
    builder.addCase(setWizardStatusSuccess, (state, action) => {
      state[ORIGINAL_NUMBER] = action.payload.wizard.steps?.businessPhoneNumber?.businessPhoneNumber;
    });
  },
});

export const {
  updateGeneralSettingsField,
  generateForwardingNumbers,
  generateForwardingNumbersSuccess,
  generateForwardingNumbersFailure,
} = generalSettingsSlice.actions;
export default generalSettingsSlice.reducer;
