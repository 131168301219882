import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import WarningModal from '../../../../../shared/modal/WarningModal';
import { GENERAL_SETTINGS_PAGE } from '../../../../../shared/navigation/navigation-config';
import { setCurrentScreen } from '../../../../../shared/navigation/navigation-slice';
import { ACCOUNT_PATH, TOOL_PATH_SCHEDULER } from '../../../../../shared/app-constants';
import { APPOINTMENT_INFO_KEY, updateLocationWarning } from '../../data/appointment-types-slice';

/**
 * Warning modal for incomplete business location info
 */
export default function LocationWarningModal() {
  const titleText = 'This Appointment Type requires your business address.';
  const promptText = 'You can add this in Business Settings.';
  const warningOpen = useSelector(
    (state) => state.appointmentTypes[APPOINTMENT_INFO_KEY].showLocationWarning,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cancelCallback = useCallback(() => {
    dispatch(updateLocationWarning(false));
  });

  const goToGeneral = useCallback(() => {
    dispatch(updateLocationWarning(false));
    navigate(`${ACCOUNT_PATH}?originLink=${TOOL_PATH_SCHEDULER}&isInternal=true`);
  });

  return (
    <WarningModal
      isOpen={warningOpen}
      titleText={titleText}
      promptText={promptText}
      leftInteractText="Cancel"
      rightInteractText="Business Settings"
      leftClickCallback={cancelCallback}
      rightClickCallback={goToGeneral}
    />
  );
}
