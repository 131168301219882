import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dismissErrorBannerAction,
} from '../data/google-calendar-slice';
import { ERROR_TYPE, WARNING_TYPE } from '../../../../shared/app-constants';
import { GOOGLE_CALENDAR_SCOPE } from '../bookings-constants';
import BannerCard from './BannerCard';

/**
 * Component for displaying the banner errors relates to Google Calendar
 */
export default function GoogleCalendarErrors() {
  const dispatch = useDispatch();
  const selectedScope = useSelector((state) => state.googleCalendar.scope);
  const showErrorBanner = useSelector((state) => state.googleCalendar.showErrorBanner);

  const hasCalendarRights = useCallback(() => {
    if (selectedScope) {
      const params = selectedScope.split(' ');
      const hasReadOrPublishRights = params.some((p) => p === GOOGLE_CALENDAR_SCOPE);
      return hasReadOrPublishRights;
    }

    return false;
  }, [selectedScope]);

  const dismissErrorBanner = useCallback(() => {
    dispatch(
      dismissErrorBannerAction(),
    );
  }, [dispatch, dismissErrorBannerAction]);

  return (
    <>
      <BannerCard
        id="google-calender-error-banner"
        title="Google Connection Failed"
        description="Please try again."
        showBanner={showErrorBanner}
        dismissBanner={dismissErrorBanner}
        bannerType={ERROR_TYPE}
      />
      <BannerCard
        id="google-calender-warning-banner"
        description="We are not able to read or publish to your Google Calendar. Reconnect again & accept permissions."
        showBanner={selectedScope && !hasCalendarRights()}
        bannerType={WARNING_TYPE}
      />
    </>
  );
}
