import { createAction, createSlice } from '@reduxjs/toolkit';

export const loadCreditCardsAction = createAction('credit_cards/loadCreditCards');

const creditCardsSlice = createSlice({
  name: 'credit_cards',
  initialState: {
    isLoading: false,
    cards: [],
    editCardNumber: '',
    editCardMonth: '',
    editCardYear: '',
    editPaymentId: '',
    deletePaymentId: '',
    openEditModal: false,
    openDeleteModal: false,
    showActionBanner: false,
    editCardSuccess: null,
    addCardSuccess: null,
    deleteCardSuccess: false,
    listOfStripeErrors: [],
    getListOfErrors: false,
    bannerText: '',
    bannerType: ''
  },
  reducers: {
    loadCreditCards: (state) => {
      state.isLoading = true;
      state.deleteCardSuccess = false;
    },
    loadCreditCardsFailure: (state) => {
      state.isLoading = false;
    },
    editStripeError: (state) => {
      state.editCardSuccess = false;
    },
    deleteCardError: (state) => {
      state.deleteCardSuccess = false;
    },
    addCreditCardSuccess: (state, action) => {
      state.addCardSuccess = action.payload.status;
    },
    setActionBanner: (state, action) => {
      state.bannerText = action.payload.bannerText;
      state.bannerType = action.payload.bannerType;
    },
    getEditCardInfo: (state, action) => {
      state.editCardNumber = action.payload.cardNumber;
      state.editCardMonth = action.payload.cardMonth;
      state.editCardYear = action.payload.cardYear;
      state.editPaymentId = action.payload.paymentId;
    },
    getDeletePaymentId: (state, action) => {
      state.deletePaymentId = action.payload.paymentId;
    },
    deleteCreditCardSuccess: (state) => {
      state.deleteCardSuccess = true;
    },
    resetSuccess: (state) => {
      state.deleteCardSuccess = null;
      state.addCardSuccess = null;
      state.editCardSuccess = null;
    },
    toggleEditModal: (state, action) => {
      state.openEditModal = action.payload.toggleEditModal;
    },
    toggleDeleteModal: (state, action) => {
      state.openDeleteModal = action.payload.toggleDeleteModal;
    },
    toggleActionBanner: (state, action) => {
      state.showActionBanner = action.payload.toggleActionBanner;
    },
    saveEditCreditCard: () => { },
    saveEditCreditCardSuccess: (state, action) => {
      if (action.payload.customerId) {
        state.editCardSuccess = true;
      }
      state.cards.map(card => {
        if (card.paymentMethodId === action.payload.paymentMethodId) {
          card.expMonth = action.payload.expMonth;
          card.expYear = action.payload.expYear;
        }
        return card;
      })
    },
    deleteCreditCard: () => { },
    loadCreditCardsSuccess: (state, action) => {
      state.isLoading = false;
      state.cards = action.payload.map((cc) => ({
        ...cc,
        subscriptions: cc.appliedInSubscriptions
          .reduce((allSubs, sub) => {
            const subItems = sub.items;
            if (Array.isArray(subItems) && subItems.length > 0) {
              allSubs.push(
                {
                  id: sub.items[0]?.product?.id,
                  displayName: sub.items[0]?.product?.displayName,
                  slug: sub.items[0]?.product?.slug
                }
              )
            }

            return allSubs;
          }, [])
      }));

      if (state.cards.length > 0) {
        state.cards.sort((cardA, cardB) => (
          cardB.appliedInSubscriptions.length - cardA.appliedInSubscriptions.length
        ));
      }
    },
    loadCreditCardsFailure: (state) => {
      state.isLoading = false;
    },
    getListofErrorsAction: (state, action) => { },
    getListofErrorsSuccess: (state, action) => {
      let returnedErrors = action.payload.PAYMENT_FAILED;
      
      if (returnedErrors) {
        returnedErrors = returnedErrors.map((errorObj) => {
          errorObj.properties = JSON.parse(errorObj.properties);
          return errorObj;
        });
        state.listOfStripeErrors = returnedErrors;
      } else {
        state.listOfStripeErrors = [];
      }
    },
    getListofErrorsError: (state, action) => {
      state.getListOfErrors = true;
    },
    extraReducers: (_builder) => { },
  }
});

export const {
  loadCreditCards,
  loadCreditCardsSuccess,
  loadCreditCardsFailure,
  getEditCardInfo,
  setActionBanner,
  toggleEditModal,
  saveEditCreditCard,
  getEditCreditCard,
  saveEditCreditCardSuccess,
  editStripeError,
  deleteCardError,
  deleteCreditCard,
  addCreditCardSuccess,
  toggleDeleteModal,
  toggleActionBanner,
  getDeletePaymentId,
  deleteCreditCardSuccess,
  getListofErrorsAction,
  getListofErrorsSuccess,
  getListofErrorsError,
  resetSuccess
} = creditCardsSlice.actions;
export default creditCardsSlice.reducer;