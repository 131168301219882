import { ACTIVATION, GRADER, PRODUCT_DATA_UPDATED, REQUEST_DONE, SETTINGS_UPDATE, APPOINTMENT, CANCEL, CREATE,  RESCHEDULE, SUBMIT, WIZARD } from './websocket-constants';

/**
 * Determine whether a given message is of the matching
 * type and action. If yes, return the message, if not return null.
 * Returns truthy values in order to be used as a filter.
 * @param {Obj} message
 * @param {String} eventType
 * @param {String} actionName
 * @returns
 */
export const checkMessage = (message, eventType, actionName) => {
  if (message.eventType === eventType && message.action === actionName) {
    return message;
  }
  return null;
};

// Wrapper for filtering grader request done messages
export const checkGraderMessage = (message) => (
  checkMessage(message, GRADER, REQUEST_DONE)
);

export const checkCallRecorderMessage = (message) => (
  checkMessage(message, PRODUCT_DATA_UPDATED, ACTIVATION)
);

export const checkSettingsMessage = (message) => (
  checkMessage(message, PRODUCT_DATA_UPDATED, SETTINGS_UPDATE)
)

// Wrapper for filtering appointment created messages
export const checkAppointmentCreatedMessage = (message) => (
  checkMessage(message, APPOINTMENT, CREATE)
);

// Wrapper for filtering appointment rescheduled messages
export const checkAppointmentRescheduledMessage = (message) => (
  checkMessage(message, APPOINTMENT, RESCHEDULE)
);

// Wrapper for filtering appointment cancelled messages
export const checkAppointmentCancelledMessage = (message) => (
  checkMessage(message, APPOINTMENT, CANCEL)
);
// Wrapper for filtering wizard submit messages
export const checkWizardMessage = (message) => (
  checkMessage(message, WIZARD, SUBMIT)
);
