import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import SideNav from './SideNav';
import NeedHelp from '../../shared/needHelp/NeedHelp';
import { ACCOUNT_SLUG, SUPPORT_EMAIL } from '../../shared/app-constants';

const AccountNavWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
  '& nav > div': {
    // Override normal nav scrolling since this nav is quite different
    maxHeight: 'inherit',
  },
}));


export default function AccountNav({ navItems, currentScreen }) {
  return (
    <AccountNavWrapper>
      <nav>
        <SideNav navItems={[...navItems]} currentSection={currentScreen} product={ACCOUNT_SLUG} />
      </nav>
      {
        !isMobile && (
          <NeedHelp title='Need Help?' content={(
            <>To cancel your subscription or for general inquiries, contact <a target="_blank" href={`mailto:${SUPPORT_EMAIL}?subject=LocaliQ Assistance`}>{SUPPORT_EMAIL}</a>.</>
          )} />
        )
      }
    </AccountNavWrapper>
  )
}

AccountNav.propTypes = {
  navItems: PropTypes.array.isRequired,
  currentSection: PropTypes.string,
};

