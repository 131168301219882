import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { constructBody } from '../../../../shared/app-utils';
import {
  resetCompleteAction,
  saveChatbotAction,
  saveChatbotSuccess,
  saveChatbotFailure,
} from './chatbot-slice';
import { CHATBOT_SLUG } from '../../../../shared/app-constants';
import { toggleBookingsStatusSuccess } from '../../bookings/data/bookings-slice';
import { emptyAction } from '../../../../shared/app-slice';
import { SAVE_CONFIGURATION } from '../../../tools/tools-config';
import { triggerResetAction } from '../../../../redux/shared-actions';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const SAVE_KEYS = Object.keys(SAVE_CONFIGURATION);

/**
 * Wrap the general body construction with booking specific keys and config
 * @param {Object} stateObj
 * @returns Object
 */
const constructChatbotBody = (stateObj) => (
  constructBody(
    SAVE_KEYS,
    SAVE_CONFIGURATION,
    { ...stateObj.chatbot, businessAttributes: stateObj.businessAttributes },
  )
);

const saveChatbotEpic = (action$, state$) => action$.pipe(
  filter(saveChatbotAction.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${CHATBOT_SLUG}/${action.payload.tier}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: constructChatbotBody(state$.value),
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => saveChatbotSuccess(response.response)),
    catchError((error) => (
      of(saveChatbotFailure(error))
    )),
  )),
);

// Turn off scheduler integration when scheduler is turned off
const triggerSchedulerReconcile = (action$, state$) => action$.pipe(
  filter(toggleBookingsStatusSuccess.match),
  filter((action) => action.payload.active === false
    && state$.value.chatbot.scheduler.isActive),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/${CHATBOT_SLUG}/${state$.value.chatbot.tier}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        schedulerLink: { active: false }
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map(() => emptyAction()),
    catchError(() => (
      of(emptyAction())
    )),
  )),
);

// Repack the reset action with a piece of state
const resetActionEpic = (action$, state$) => action$.pipe(
  filter(triggerResetAction.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.chatbot.resetProductInfo,
    })
  )),
);

const saveSuccessEpic = (action$, state$) => action$.pipe(
  filter(saveChatbotSuccess.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.chatbot.resetProductInfo,
    })
  )),
);

export default [
  saveChatbotEpic,
  resetActionEpic,
  saveSuccessEpic,
  triggerSchedulerReconcile,
];
