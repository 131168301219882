import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(13),
  width: '100%',
}));

const StyledLeftWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  flexGrow: 1,
}));

const StyledTitleAreaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledDescriptionAreaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledButtonAreaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

const StyledRightWrapper = styled('div')(() => ({}));

const SkeletonRectangular = styled(Skeleton)(({ bgcolor = '#FFFFFF29' }) => ({
  '&.MuiSkeleton-rectangular': {
    borderRadius: '4px',
    background: bgcolor,
  },
}));

export default function LoadingSkeletonCriticalActionDesktop() {
  return (
    <StyledWrapper>
      <StyledLeftWrapper>
        <StyledTitleAreaWrapper>
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width={142} height={16} />
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width={454} height={40} />
        </StyledTitleAreaWrapper>
        <StyledDescriptionAreaWrapper>
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width="100%" height={16} />
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width="100%" height={16} />
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width="40%" height={16} />
        </StyledDescriptionAreaWrapper>
        <StyledButtonAreaWrapper>
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width={184} height={40} />
          <SkeletonText animation="wave" bgcolor="#FFFFFF29" variant="string" width={184} height={40} />
        </StyledButtonAreaWrapper>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <SkeletonRectangular animation="wave" bgcolor="#FFFFFF29" variant="rectangular" width={312} height={195} />
      </StyledRightWrapper>
    </StyledWrapper>
  );
}
