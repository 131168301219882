import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogContentText } from '@mui/material';
import { hasExpiredTrial } from '../../shared/trials/trial-utils';
import warningIcon from '../../images/warning-icon.svg';
import { ButtonWrapper, PrimaryButton } from '../../shared/settings-shared-components';
import { DASHBOARD_PATH } from '../../shared/app-constants';

const ExpiredDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 600,
    minHeight: 175,
    textAlign: 'center',
  },
  '& .MuiDialogContentText-root': {
    color: theme.palette.common.black,
    fontSize: 18,
  },
}));

const TopColorBar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  height: 8,
  width: '100%',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

/**
 * Banner to show when a user's trial has expired
 */
export default function TrialExpiredModal() {
  const navigate = useNavigate();
  const expiredError = useSelector((state) => state.tools.expiredError);

  const handleModalClose = (ev) => {
    ev.preventDefault();
  };

  const redirectToDashboard = () => {
    navigate(DASHBOARD_PATH);
  }

  return (
    <ExpiredDialog open={expiredError} onClose={handleModalClose}>
      <TopColorBar />
      <DialogContent>
        <IconWrapper>
          <img src={warningIcon} alt="warning-icon" />
        </IconWrapper>
        <DialogContentText>
          Oh no! Looks like your free trial expired.
          <ButtonWrapper>
            <PrimaryButton onClick={redirectToDashboard}>
              Back to Dashboard
            </PrimaryButton>
          </ButtonWrapper>
        </DialogContentText>
      </DialogContent>
    </ExpiredDialog>
  );
}

TrialExpiredModal.propTypes = {

};
