import SectionLayout from "../../../shared/section/SectionLayout";
import { ReactComponent as AlertStatusIconSvg } from '../../../images/alert-status-icon.svg';
import { RequiredFieldsContainer } from "../../../shared/settings-shared-components";
import WithDisabled from "../components/WithDisabled";
import EmailCampaign from "../components/EmailCampaign";

export default function EmailCampaignSettings({ isDisabled }) {
  return (
    <div>
      <SectionLayout
        headerText="Email Campaigns"
        headerDescription="The settings below are used in email footers and are required to send email campaigns."
        name="emailCampaignSettings"
      />
      <RequiredFieldsContainer>
        <AlertStatusIconSvg />Fields marked with an asterisk (*) are required
      </RequiredFieldsContainer>
      <WithDisabled isDisabled={isDisabled}>
        <EmailCampaign />
      </WithDisabled>

    </div>
  );
}
