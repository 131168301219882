import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledOptionWrapper = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  cursor: 'pointer',
  width: 'max-content',

  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));

const StyledInput = styled('input')(() => ({
  cursor: 'pointer',
}));

const StyledLabel = styled('span')(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.25px',
}));

const StyledTextAreaWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0, 0, 0, 4),
  gap: theme.spacing(1),
}));

const StyledTextArea = styled('textarea')(({ theme }) => ({
  resize: 'none',
  padding: theme.spacing(1, 2),
  height: '64px',
}));

const StyledWordsCountWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));

const StyledCount = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
}));

const REASONS = {
  1: 'Product is too expensive.',
  2: 'I need features you don\'t have.',
  3: 'Product did not help with my business.',
  4: 'I can\'t figure out how to use the products.',
  5: 'Other',
};
const MAX_CHARACTERS = 200;

export default function CancelReasonForm({ selectFeedbackOption, handleTextAreaChange }) {
  const cancelReasonString = useSelector((state) => (
    state.account.subscriptions.cancelSubscriptionModal.reasonToCancel
  ));
  const otherReasonToCancel = useSelector((state) => (
    state.account.subscriptions.cancelSubscriptionModal.otherReasonToCancel
  ));

  return (
    <StyledWrapper>

      {Object.entries(REASONS).map(([key, reason]) => (
        <StyledOptionWrapper key={`cancel-reason-wrapper-${key}`} htmlFor={`cancel-reason-${key}`} onChange={selectFeedbackOption}>
          <StyledInput
            type="radio"
            id={`cancel-reason-${key}`}
            name="reason-to-cancel"
            value={reason}
            defaultChecked={cancelReasonString === reason}
          />
          <StyledLabel>{reason}</StyledLabel>
        </StyledOptionWrapper>
      ))}

      {cancelReasonString === 'Other' && (
        <StyledTextAreaWrapper>
          <StyledTextArea
            value={otherReasonToCancel}
            onChange={handleTextAreaChange}
            maxLength={MAX_CHARACTERS}
          />
          <StyledWordsCountWrapper>
            <StyledCount>{otherReasonToCancel.length}</StyledCount>
            <StyledCount>/</StyledCount>
            <StyledCount>{MAX_CHARACTERS}</StyledCount>
          </StyledWordsCountWrapper>
        </StyledTextAreaWrapper>
      )}

    </StyledWrapper>
  );
}

CancelReasonForm.propTypes = {
  selectFeedbackOption: PropTypes.func.isRequired,
  handleTextAreaChange: PropTypes.func,
};
