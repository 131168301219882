import styled from '@emotion/styled';
import { Card } from '@mui/material';

export const ResultWrapper = styled(Card)(({ score, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  borderRadius: 8,
  maxWidth: 368,
  padding: theme.spacing(3, 2, 2),
  position: 'relative',
  left: `${score - 50}%`,
  overflow: 'initial',
  marginTop: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
  },

  '&::before': {
    content: '""',
    display: 'inline-block',
    zIndex: 1,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px -2px 4px',
    borderRadius: 8,
    right: 'auto',
    left: '50%',
    top: -32,
    transform: 'translateY(50%) translateX(-50%) rotate(45deg)',
    width: 35,
    height: 35,
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
      top: -25,
    },
  },

  '&::after': {
    position: 'absolute',
    content: '""',
    top: 0,
    left: '41%',
    width: 80,
    height: 20,
    background: theme.palette.common.white,
    borderRadius: 8,
    zIndex: 5,
  },

  '& .overallScore': {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    maxWidth: 'calc(100% - 32px)',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      maxWidth: '100%',
      alignItems: 'center',
      paddingBottom: 0,
    },

    '& .scoreDisplayBox': {
      height: 56,
      width: 56,
      minWidth: 56,
      borderRadius: 4,

      [theme.breakpoints.down('md')]: {
        width: 96,
        height: 96,
        borderRadius: 8,
      },

      '& .numerator': {
        fontSize: 32,
        [theme.breakpoints.down('md')]: {
          fontSize: 42,
        },
      },
      '& .denominator': {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
          fontSize: 20,
        },
      },
    },

    '& .scoreDescription': {
      minWidth: 272,
      paddingLeft: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        minWidth: 'auto',
        paddingLeft: 0,
        textAlign: 'center',
        paddingTop: theme.spacing(1),
      },

      '& .compLink': {
        fontFamily: 'Unify Sans SemiBold',
        color: theme.palette.primary.main,
        fontSize: 32,
        textDecoration: 'none',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: -10,

        [theme.breakpoints.down('md')]: {
          fontSize: 12,
          marginTop: 0,
        },

        '& a': {
          color: theme.palette.primary.main,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        '&:hover': {
          textDecoration: 'underline',
        },
      },

      '& .levelDescription': {
        fontSize: 12,
        color: '#737373',
        textTransform: 'uppercase',
        marginTop: theme.spacing(1),
        lineHeight: '1.25',
        letterSpacing: '1px',
        fontFamily: 'Unify Sans SemiBold',
        [theme.breakpoints.down('md')]: {
          fontSize: 8,
          color: theme.palette.info.dark,
          '& .level': {
            textTransform: 'none',
            fontSize: 11,
          },
        },
      },
    },
    '& .scoreCancel': {
      color: '#0000008a',
      maxWidth: 1,
      position: 'relative',
      top: '-22px',
      '& .MuiButtonBase-root.MuiIconButton-root': {
        padding: 4,
      },

      '& svg': {
        height: 20,
        width: 20,
      },

      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        zIndex: 6,
        maxWidth: 'none',
        right: 0,
      },
    },
  },

  '& hr': {
    borderColor: '#aeaead',
    borderWidth: '0px 0px thin',
    borderStyle: 'solid',
    margin: 0,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  '& .scoreBreakdown': {
    width: '100%',
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    '& .scoreBox': {
      width: '33%',
      flexBasis: '33%',
      padding: theme.spacing(1, 1, 0),
      fontFamily: 'Unify Sans SemiBold',
      textAlign: 'center',
      fontSize: 24,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& .scoreTitle': {
        color: '#737373',
        fontFamily: 'Unify Sans SemiBold',
        lineHeight: '1.08333',
        padding: '4px 0px',
        maxWidth: 85,
        letterSpacing: '0.15px',
        fontSize: 12,
      },
    },
  },
}));
