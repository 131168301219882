import {
  ACCOUNT_PATH,
  WIDGET_SETTINGS_PATH,
  TOOLS_PATH,
  SCHEDULER_PATH,
  DASHBOARD_PATH,
  GRADER_PATH,
} from '../../shared/app-constants';
import {
  ACCOUNT_NAV_STEPS,
  BUSINESS_DETAILS_PAGE,
  DASHBOARD_PAGE,
  GRADER_PAGE,
  ENGAGEMENT_WIDGET_PAGE,
  SCHEDULING_PAGE,
  TOOL_NAV_STEPS,
  WIDGET_SETTINGS_STEPS,
} from '../../shared/navigation/navigation-config';
import ExpandIcon from '../../images/external-link.svg';

export const CONTACT_US = 'contact_us';

const {
  REACT_APP_ACCOUNT_SERVICES_UI_ROOT,
  REACT_APP_MANAGEMENT_UI_ROOT,
  REACT_APP_ACCOUNT_FAQS_URL,
  REACT_APP_LEADS_URL,
  REACT_APP_CONTACTS_URL,
  REACT_APP_LISTINGS_URL,
} = process.env;

export const APPOINTMENTS_ID = 'appointments';

export const HEADER_MAIN_LINKS = [
  {
    id: 'dashboard',
    labelText: 'Dashboard',
    isInternal: true,
    isRootUrl: true,
    relativeUrl: DASHBOARD_PATH,
    currentScreen: DASHBOARD_PAGE,
  },
  {
    id: 'contacts',
    labelText: 'Contacts',
    isInternal: false,
    dropdownLinks: [
      {
        id: 'contact-activity',
        labelText: 'Contact Activity',
        url: REACT_APP_LEADS_URL,
        isInternal: false,
      },
      {
        id: 'contact-list',
        labelText: 'Contact List',
        url: REACT_APP_CONTACTS_URL,
        isInternal: false,
      }
    ]
  },
  {
    id: 'tools',
    labelText: 'Tools',
    dropdownLinks: [
      {
        id: 'scheduling',
        labelText: 'Scheduling',
        isInternal: true,
        relativeUrl: SCHEDULER_PATH,
        currentScreen: SCHEDULING_PAGE,
      },
      {
        id: 'listings',
        labelText: 'Listings',
        url: REACT_APP_LISTINGS_URL,
        rightIconUrl: ExpandIcon,
        isInternal: false,
        newTab: true,
      },
      {
        id: 'website-grader',
        labelText: 'Website Grader',
        relativeUrl: GRADER_PATH,
        isInternal: true,
        currentScreen: GRADER_PAGE,
      },
    ],
  },
  {
    id: 'business-name-links',
    labelText: 'Business Name',
    dropdownLinks: [
      {
        id: 'localiq-settings-link-id',
        labelText: 'LocaliQ Settings',
        relativeUrl: `${WIDGET_SETTINGS_PATH}/${WIDGET_SETTINGS_STEPS[ENGAGEMENT_WIDGET_PAGE].url}`,
        currentScreen: ENGAGEMENT_WIDGET_PAGE,
        isInternal: true,
      },
      {
        id: 'tools-settings-link-id',
        labelText: 'Tools Settings',
        relativeUrl: `${TOOLS_PATH}/${TOOL_NAV_STEPS[SCHEDULING_PAGE].url}`,
        currentScreen: SCHEDULING_PAGE,
        isInternal: true,
      },
      {
        id: 'account-management-link-id',
        labelText: 'Account Management',
        relativeUrl: `${ACCOUNT_PATH}/${ACCOUNT_NAV_STEPS[BUSINESS_DETAILS_PAGE].url}`,
        currentScreen: BUSINESS_DETAILS_PAGE,
        isInternal: true,
      },
      {
        id: 'faqs-link-id',
        labelText: 'FAQs',
        url: REACT_APP_ACCOUNT_FAQS_URL,
        isInternal: false,
        newTab: true,
        rightIconUrl: ExpandIcon,
      },
      {
        id: 'contact-us-link-id',
        labelText: 'Contact Us',
        url: `${REACT_APP_MANAGEMENT_UI_ROOT}${CONTACT_US}`,
        isInternal: true,
        relativeUrl: `/${CONTACT_US}`,
        currentScreen: CONTACT_US,
      },
      {
        id: 'logout-link-id',
        labelText: 'Log out',
        url: `${REACT_APP_ACCOUNT_SERVICES_UI_ROOT}logout`,
        isInternal: false,
      },
    ],
    isOnRightSide: true,
  }
];

// Available nav for support. might want to compile based on roles later if more restriction 
export const SUPPORT_ONLY_LIMITED_NAV_LINKS = [
  {
    id: 'business-name-links',
    labelText: 'Business Name',
    dropdownLinks: [
      {
        id: 'localiq-settings-link-id',
        labelText: 'LocaliQ Settings',
        relativeUrl: `${WIDGET_SETTINGS_PATH}/${WIDGET_SETTINGS_STEPS[ENGAGEMENT_WIDGET_PAGE].url}`,
        currentScreen: ENGAGEMENT_WIDGET_PAGE,
        isInternal: true,
      },
      {
        id: 'logout-link-id',
        labelText: 'Log out',
        url: `${REACT_APP_ACCOUNT_SERVICES_UI_ROOT}logout`,
        isInternal: false,
      },
    ],
    isOnRightSide: true,
  }
];