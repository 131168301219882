import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import { IQFormInput, IQFormSelect, IQInputWebURL, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import SectionCard from "../../../shared/SectionCard";
import { CharacterCount, DividerRow, DividingLine, GridRow, PhoneRow } from '../../../shared/settings-shared-components';
import { selectCountryOptions } from '../../../shared/country-constants';
import { CITY_KEY, COPYRIGHT_KEY, COUNTRY_KEY, EMAIL_CAMPAIGN_FORM, EMAIL_FORM_KEYS, POSTAL_CODE_KEY, REPLY_TO_EMAIL_KEY, SENDER_EMAIL_KEY, SENDER_NAME_KEY, STATE_KEY, STREET_ADDRESS_2_KEY, STREET_ADDRESS_KEY } from "../data/tools-constants";
import { updateEmailFooter } from '../data/emailCampaign-slice';
import { EMAIL_CAMPAIGNS_PAGE } from '../../../shared/navigation/navigation-config';
import EmailCampaignStateChangedHook from '../../../shared/stateChangedHook/EmailCampaignStateChangedHook';

const CharCount = styled(CharacterCount)(({ theme }) => ({
  float: 'right'
}));

const senderNameLength = 30;
const senderEmailLength = 30;
const replyEmailLength = 30;
const copyrightLength = 200;

export default function EmailCampaign() {
  const dispatch = useDispatch();
  const emailInfo = useSelector(state => state.tools[EMAIL_CAMPAIGNS_PAGE].emailInfo)

  const schema = yup.object().shape({
    [SENDER_NAME_KEY]: yup.string().required('Sender Name is required'),
    [SENDER_EMAIL_KEY]: yup.string().required('Sender Email is required').email('Invalid Email address'),
    [REPLY_TO_EMAIL_KEY]: yup.string().required('Reply Email is required').email('Invalid Email address'),
    [STREET_ADDRESS_KEY]: yup.string().required(),
    [STREET_ADDRESS_2_KEY]: yup.string(),
    [CITY_KEY]: yup.string().required(),
    [STATE_KEY]: yup.string().required(),
    [COUNTRY_KEY]: yup.string(),
    [POSTAL_CODE_KEY]: yup.string().required(),
    [COPYRIGHT_KEY]: yup.string().required(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [SENDER_NAME_KEY]: emailInfo[SENDER_NAME_KEY] || "",
      [SENDER_EMAIL_KEY]: emailInfo[SENDER_EMAIL_KEY] || '',
      [REPLY_TO_EMAIL_KEY]: emailInfo[REPLY_TO_EMAIL_KEY] || '',
      [STREET_ADDRESS_KEY]: emailInfo[STREET_ADDRESS_KEY] || '',
      [STREET_ADDRESS_2_KEY]: emailInfo[STREET_ADDRESS_2_KEY] || '',
      [CITY_KEY]: emailInfo[CITY_KEY] || '',
      [STATE_KEY]: emailInfo[STATE_KEY] || '',
      [COUNTRY_KEY]: emailInfo[COUNTRY_KEY] || '',
      [POSTAL_CODE_KEY]: emailInfo[POSTAL_CODE_KEY] || '',
      [COPYRIGHT_KEY]: emailInfo[COPYRIGHT_KEY] || '',
    }))
  });

  const { watch } = methods;

  const handleFieldChange = (fieldName, fieldValue) => {
    dispatch(updateEmailFooter({ fieldName, fieldValue }))
  };

  const senderNameVal = watch(SENDER_NAME_KEY);
  const senderEmailVal = watch(SENDER_EMAIL_KEY);
  const replyEmailVal = watch(REPLY_TO_EMAIL_KEY);
  const copyrightlVal = watch(COPYRIGHT_KEY);

  const senderNameCharCount = senderNameVal ? senderNameVal.length : 0
  const senderEmailCharCount = senderEmailVal ? senderEmailVal.length : 0
  const replyEmailCharCount = replyEmailVal ? replyEmailVal.length : 0
  const copyrightCharCount = copyrightlVal ? copyrightlVal.length : 0

  return (
    <SectionCard
      showDividingLine={false}>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <GridRow item sm={6} xs={12}>
              <IQFormInput
                id='sender-name-input'
                name={SENDER_NAME_KEY}
                labelText='Sender Name *'
                fontLabelWeight='bold'
                fullWidth
                onBlur={(e) => handleFieldChange(SENDER_NAME_KEY, e.target.value)}
              />
              <CharCount>
                {senderNameCharCount} / {senderNameLength}
              </CharCount>
            </GridRow>
            <DividerRow paddingBottom={2} item xs={12} />
            <GridRow item sm={6} xs={12}>
              <IQInputWebURL
                id='sender-email-address'
                name={SENDER_EMAIL_KEY}
                label='Sender Email Address *'
                fullWidth
                onBlur={(e) => handleFieldChange(SENDER_EMAIL_KEY, e.target.value)}
              />
              <CharCount>
                {senderEmailCharCount} / {senderEmailLength}
              </CharCount>
            </GridRow>
            <PhoneRow item sm={6} xs={12}>
              <IQFormInput
                id='reply-to-email'
                name={REPLY_TO_EMAIL_KEY}
                labelText='Reply-to Email *'
                fullWidth={false}
                onBlur={(e) => handleFieldChange(REPLY_TO_EMAIL_KEY, e.target.value)}
              />
              <CharCount>
                {replyEmailCharCount} / {replyEmailLength}
              </CharCount>
            </PhoneRow>
            <DividerRow item xs={12}>
              <DividingLine allowMobile />
            </DividerRow>
            <GridRow item sm={6} xs={12}>
              <IQFormInput
                id='street-address-1'
                name={STREET_ADDRESS_KEY}
                labelText='Street Address 1 *'
                onBlur={(e) => handleFieldChange(STREET_ADDRESS_KEY, e.target.value)}
              />
            </GridRow>
            <GridRow item sm={6} xs={12}>
              <IQFormInput
                id='street-address-2'
                name={STREET_ADDRESS_2_KEY}
                labelText='Street Address 2'
                onBlur={(event) => handleFieldChange(STREET_ADDRESS_2_KEY, event.target.value)}
              />
            </GridRow>
            <DividerRow item xs={12} />
            <GridRow item sm={6} xs={12}>
              <IQFormInput
                id='business-city'
                name={CITY_KEY}
                labelText='City *'
                onBlur={(e) => handleFieldChange(CITY_KEY, e.target.value)}
              />
            </GridRow>
            <GridRow item sm={6} xs={12}>
              <IQFormInput
                id='business-state'
                name={STATE_KEY}
                labelText='State/Province *'
                onBlur={(e) => handleFieldChange(STATE_KEY, e.target.value)}
              />
            </GridRow>
            <DividerRow item xs={12} />
            <GridRow item sm={6} xs={12}>
              <IQFormSelect
                id='business-country'
                name={COUNTRY_KEY}
                labelText='Country'
                items={selectCountryOptions}
                onChange={(e) => handleFieldChange(COUNTRY_KEY, e.target.value)}
              />
            </GridRow>
            <GridRow item sm={3} xs={12}>
              <IQFormInput
                id='business-postal-code'
                name={POSTAL_CODE_KEY}
                labelText='Postal Code *'
                onBlur={(e) => handleFieldChange(POSTAL_CODE_KEY, e.target.value)}
              />
            </GridRow>
            <DividerRow item xs={12}>
              <DividingLine allowMobile />
            </DividerRow>
            <GridRow item sm={12} xs={12}>
              <IQFormInput
                id='copyright'
                name={COPYRIGHT_KEY}
                labelText='Copyright *'
                fullWidth
                onBlur={(e) => handleFieldChange(COPYRIGHT_KEY, e.target.value)}
              />
              <CharCount>
                {copyrightCharCount} / {copyrightLength}
              </CharCount>
            </GridRow>
          </Grid>
          <EmailCampaignStateChangedHook
            formName={EMAIL_CAMPAIGN_FORM}
            formKeys={EMAIL_FORM_KEYS}
          />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
};