import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import outlookCalenderSvg from '../assets/outlook-calendar.svg';
import ConnectedCalendar from './ConnectedCalendar';
import MicrosoftSignInButton from '../../../../shared/signInButton/MicrosoftSignInButton';
import { authRequestProgress, authSessionEnded } from '../data/outlook-calendar-slice';
import { updateCalendarIntegrationAction } from '../data/calendar-actions';
import { OUTLOOK_TYPE } from '../bookings-constants';
import { convertDateToFormat } from '../../../../shared/app-utils';
import CalendarConnectCard from './components/CalendarConnectCard';
import { LoadingCard } from '../../../../shared/settings-shared-components';

export default function OutlookCalendar() {
  const dispatch = useDispatch();
  const { isLoading, isConnected, isAuthModalOpen } = useSelector((state) => state.outlookCalendar);
  const {
    email,
    connectedOn,
  } = useSelector((state) => state.outlookCalendar.calendarIntegrationData);
  const showLoading = isLoading || isAuthModalOpen;
  const googleConnected = useSelector((state) => state.googleCalendar.isConnected);
  const googleInProgress = useSelector((state) => state.googleCalendar.isLoading);
  const tooltipText = googleConnected ? 'Only one connected calendar allowed at a time' : `You'll only have to do this once. 1-click sync.`;

  const authRequestStarted = useCallback(() => {
    dispatch(authRequestProgress({
      isLoading: true,
    }));
  });

  const authComplete = useCallback((authData) => {
    dispatch(updateCalendarIntegrationAction({
      type: OUTLOOK_TYPE,
      connectedDate: convertDateToFormat(new Date().toString()),
      accountKey: authData.value,
    }));
  });

  const authEnded = useCallback(() => {
    // The user exited the auth modal.
    dispatch(authSessionEnded());
  });

  const disconnectCalendar = useCallback(() => {
    dispatch(
      updateCalendarIntegrationAction({ type: OUTLOOK_TYPE }),
    );
  }, [dispatch, updateCalendarIntegrationAction]);

  return (
    <>
      {isConnected && !showLoading
        && (
          <ConnectedCalendar
            title="Connected Calendar"
            calendarIcon={outlookCalenderSvg}
            email={email}
            connectedOn={connectedOn}
            disconnectCalendar={disconnectCalendar}
          />
        )}
      {showLoading
        && (
          <LoadingCard style={{ padding: '24px' }}>
            <IQLoadingSpinner size={100} />
          </LoadingCard>
        )}
      {!isConnected && !showLoading
        && (
          <CalendarConnectCard
            headerText="Connect your Outlook Calendar"
            calendarImg={outlookCalenderSvg}
            calendarButton={(
              <MicrosoftSignInButton
                onClick={authRequestStarted}
                onComplete={authComplete}
                onEnded={authEnded}
                disabled={googleConnected || googleInProgress}
              />
            )}
            disabled={googleConnected || googleInProgress}
          />
        )}
    </>
  );
}
