import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { loadedBookingData, resetCompleteAction, saveBookingsAction, saveBookingsSuccess, toggleBookingsStatusSuccess } from './bookings-slice';
import { defaultAppointment } from '../bookings-constants';

// Field keys. These keys need to be used both here and in the
// forms themselves to allow for easy save/load
export const APPOINTMENT_NAME_KEY = 'name';
export const APPOINTMENT_DURATION_KEY = 'duration';
export const APPOINTMENT_LOCATION_KEY = 'location';
export const APPOINTMENT_DESCRIPTION_KEY = 'description';
export const APPOINTMENT_CUSTOM_QUESTIONS_KEY = 'customQuestions';
export const APPOINTMENT_BUFFER_BEFORE_KEY = 'bufferTimeBefore';
export const APPOINTMENT_BUFFER_AFTER_KEY = 'bufferTimeAfter';
export const APPOINTMENT_LEAD_KEY = 'leadTime';
export const APPOINTMENT_WEB_CONFERENCE_TYPE_KEY = 'virtualMeetingType';
export const APPOINTMENT_WEB_CONFERENCE_LINK_KEY = 'virtualMeetingUrl';
export const APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY = 'virtualMeetingVendor';

// Section key
export const APPOINTMENT_TYPES_KEY = 'appointmentTypes';
export const APPOINTMENT_INFO_KEY = 'appointmentInfo';

const cleanApptType = (apptType) => (
  { ...defaultAppointment, ...apptType }
);

const resetMeetingData = (appt, resetData) => {
  const resetTypeData = resetData.find((resetType) => resetType.identifier === appt.identifier);
  const newAppt = { ...appt };
  if (resetTypeData) {
    delete newAppt[APPOINTMENT_WEB_CONFERENCE_TYPE_KEY];
    delete newAppt[APPOINTMENT_WEB_CONFERENCE_LINK_KEY];
    delete newAppt[APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY];

    if (resetTypeData[APPOINTMENT_WEB_CONFERENCE_TYPE_KEY]) {
      newAppt[APPOINTMENT_WEB_CONFERENCE_TYPE_KEY] = resetTypeData[APPOINTMENT_WEB_CONFERENCE_TYPE_KEY];
    }
    if (resetTypeData[APPOINTMENT_WEB_CONFERENCE_LINK_KEY]) {
      newAppt[APPOINTMENT_WEB_CONFERENCE_LINK_KEY] = resetTypeData[APPOINTMENT_WEB_CONFERENCE_LINK_KEY];
    }
    if (resetTypeData[APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY]) {
      newAppt[APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY] = resetTypeData[APPOINTMENT_WEB_CONFERENCE_PLATFORM_KEY];
    }
  }
  return newAppt;
};

const appointmentTypesSlice = createSlice({
  name: 'appointment_types',
  initialState: {
    [APPOINTMENT_TYPES_KEY]: null,
    [APPOINTMENT_INFO_KEY]: {
      saveSuccess: false,
      showLocationWarning: false,
    },
  },
  reducers: {
    updateLocationWarning: (state, action) => {
      state[APPOINTMENT_INFO_KEY].showLocationWarning = action.payload;
    },
    updateAppointmentTypeField: (state, action) => {
      const { fieldName, fieldValue, typeIndex, resetData } = action.payload;
      const existingAppts = state[APPOINTMENT_TYPES_KEY];

      // See if we are in update existing or create new situation
      if (existingAppts[typeIndex]) {
        let modifiedAppt = { ...existingAppts[typeIndex] };
        if (fieldName === APPOINTMENT_LOCATION_KEY
            && resetData) {
          // Reset the meeting data
          modifiedAppt = resetMeetingData(modifiedAppt, resetData.appointmentTypes);
        }
        modifiedAppt[fieldName] = fieldValue;
        state[APPOINTMENT_TYPES_KEY][typeIndex] = modifiedAppt;
      } else {
        const newAppt = { ...defaultAppointment };
        newAppt[fieldName] = fieldValue;
        state[APPOINTMENT_TYPES_KEY].splice(typeIndex, 0, newAppt);
      }
    },
    deleteAppointmentType: (state, action) => {
      const { appointmentIndex } = action.payload;
      const currentAppointmentTypes = state[APPOINTMENT_TYPES_KEY];

      // If we have saved it in the past, remove it.
      if (currentAppointmentTypes[appointmentIndex]) {
        state[APPOINTMENT_TYPES_KEY].splice(appointmentIndex, 1);
      }
    },
    deleteAppointmentTypeCustomQuestions: (state, action) => {
      const { typeIndex, deleteIndex } = action.payload;
      const editedAppt = { ...state[APPOINTMENT_TYPES_KEY][typeIndex] };

      if (editedAppt) {
        const questions = editedAppt[APPOINTMENT_CUSTOM_QUESTIONS_KEY];
        if (Array.isArray(questions) && questions.length > 0) {
          const newQuestions = [...questions];
          // Remove the question
          newQuestions.splice(deleteIndex, 1);
          // Remap the order of the remaining questions
          newQuestions.map((question, ind) => {
            const adjustedQuestion = { ...question };
            adjustedQuestion.order = ind;
            return adjustedQuestion;
          });
          editedAppt[APPOINTMENT_CUSTOM_QUESTIONS_KEY] = newQuestions;
          state[APPOINTMENT_TYPES_KEY][typeIndex] = editedAppt;
        }
      }
    },
    updateAppointmentTypeCustomQuestions: (state, action) => {
      const {
        typeIndex, fieldName, fieldValue, questionIndex,
      } = action.payload;
      const editedAppt = state[APPOINTMENT_TYPES_KEY][typeIndex];

      if (editedAppt) {
        const questions = editedAppt[fieldName];
        // If we have questions
        if (Array.isArray(questions) && questions.length > 0) {
          // If this question already exists
          if (questions[questionIndex]) {
            questions[questionIndex].question = fieldValue;
          } else {
            // Insert at index
            questions.splice(questionIndex, 0, {
              order: questionIndex,
              question: fieldValue,
            });
          }
        } else {
          // Add new question
          editedAppt[fieldName] = [{
            order: questionIndex,
            question: fieldValue,
          }];
        }
      } else {
        // Can this happen
        console.log('No type, adding question');
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveBookingsSuccess, (state) => {
      state[APPOINTMENT_INFO_KEY].saveSuccess = true;
    });
    builder.addCase(toggleBookingsStatusSuccess, (state, action) => {
      const { data } = action.payload;
      const appointmentTypesReturned = data.appointmentTypes;
      const currentApptTypes = [...state[APPOINTMENT_TYPES_KEY]];
      // Unify any returned IDs. Only fill in blank identifiers. Rely on NAME MATCH.
      // Don't want to full overwrite because changes may be in progress (unlike reset)
      if (appointmentTypesReturned) {
        const lookupAppt = appointmentTypesReturned.reduce((acc, appt) => {
          acc[appt.name] = appt;
          return acc;
        }, {});
        const updatedAppts = currentApptTypes.map((currentAppt) => {
          const apptTypeToUpdate = currentAppt;
          if (!apptTypeToUpdate.identifier && lookupAppt[apptTypeToUpdate.name]) {
            apptTypeToUpdate.identifier = lookupAppt[apptTypeToUpdate.name].identifier;
          }
          return apptTypeToUpdate;
        });
        state[APPOINTMENT_TYPES_KEY] = updatedAppts;
      }
    });
    builder.addCase(resetCompleteAction, (state, action) => {
      const { resetData } = action.payload;

      // This gets us the identifier for free on save
      state[APPOINTMENT_TYPES_KEY] = resetData.appointmentTypes;
    });
    builder.addCase(saveBookingsAction, (state) => {
      state[APPOINTMENT_INFO_KEY].saveSuccess = false;
    });
    builder.addCase(loadedBookingData, (state, action) => {
      const { productInfo } = action.payload;
      state[APPOINTMENT_TYPES_KEY] = [];
      if (productInfo && Array.isArray(productInfo.appointmentTypes)
        && productInfo.appointmentTypes.length > 0) {
        state[APPOINTMENT_TYPES_KEY] = productInfo.appointmentTypes.map(cleanApptType);
      } else {
        state[APPOINTMENT_TYPES_KEY] = [defaultAppointment];
      }
    });
  },
});

export const {
  updateAppointmentTypeField,
  deleteAppointmentType,
  updateAppointmentTypeCustomQuestions,
  deleteAppointmentTypeCustomQuestions,
  updateLocationWarning,
} = appointmentTypesSlice.actions;
export default appointmentTypesSlice.reducer;
