import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import SettingsLayout from '../../../layouts/SettingsLayout';
import { loadInitialGraders } from './data/grader-slice';
import ProcessingModal from './components/Processing/ProcessingModal';
import WebsiteGraderResults from './WebsiteGraderResults';
import WebsiteGraderInput from './WebsiteGraderInput';
import { ERROR_GRADER } from './data/grader-constants';

const MainLoadWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(5),
}));

/**
 * Website grader page
 */
export default function GraderLanding() {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.appSettings.user?.email);
  const {
    isLoadingScore, isSubmittingGrader, hasCheckedGrader,
  } = useSelector((state) => state.grader);
  const { status } = useSelector((state) => state.grader.graderResults);
  const showProcessing = isLoadingScore || isSubmittingGrader;
  const showInput = !showProcessing
    && (status === ERROR_GRADER || status === null);

  useEffect(() => {
    if (email) {
      dispatch(loadInitialGraders({
        email,
      }));
    }
  }, [dispatch, email]);

  if (!email || !hasCheckedGrader) {
    return (
      <SettingsLayout>
        <MainLoadWrapper>
          <IQLoadingSpinner size={100} />
        </MainLoadWrapper>
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout>
      <Helmet>
        <title>
          Grader | LocaliQ
        </title>
      </Helmet>
      {showInput && <WebsiteGraderInput />}
      {!showInput && <WebsiteGraderResults />}
      {showProcessing && <ProcessingModal />}
    </SettingsLayout>
  );
}
