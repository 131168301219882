import { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Unify Sans',
  '& .MuiPaper-root.MuiDialog-paper': {
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.08), 0px 6px 32px rgba(0, 0, 0, 0.11), 0px 8px 4px rgba(0, 0, 0, 0.07)',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      overflow: 'auto',
    },
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: 'none',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 3),
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '0 0 1.5px 0',
  background: theme.palette.text.primary,
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: 32,
  lineHeight: '50px',
  color: theme.palette.common.black,
  padding: theme.spacing(2, 0, 0, 0),
  [theme.breakpoints.down('md')]: {
    fontSize: 28,
    lineHeight: '35px',
  },
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexFlow: 'column',
    padding: theme.spacing(0, 3),
  },
}));

const SupportText = styled(Typography)(({ theme }) => ({
  flex: 4,
  color: theme.palette.common.black,
  marginBottom: '10px',
  fontFamily: 'Unify Sans Semibold',
}));

export default function SettingsDialog({
  header,
  open = false,
  onClose,
  customHeader = null,
  children,
  id,
  supportInfo,
  headerId,
}) {
  const theme = useTheme();
  const handleClose = useCallback(() => {
    onClose();
  });

  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <StyledAppBar position={isMobile ? 'sticky' : 'relative'}>
        <Toolbar sx={{ pl: { xs: 0 }, pr: { xs: 0 }, pb: { xs: theme.spacing(2), md: 0 }, flexWrap: 'wrap', alignItems: 'flex-end' }}>
          <HeaderText id={headerId} sx={{ flex: 1 }}>{header}</HeaderText>
          {supportInfo?.isSupport && <SupportText>{supportInfo?.businessInfo?.businessName} | {supportInfo?.businessInfo?.gmaid}</SupportText>}
          {!isMobile && customHeader}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ pb: { xs: '4px', md: theme.spacing(1) } }}
          >
            <CloseIcon id={id} />
          </IconButton>
          {isMobile && customHeader}
        </Toolbar>
        <StyledDivider />
      </StyledAppBar>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </StyledDialog>
  );
}

SettingsDialog.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customHeader: PropTypes.node,
  children: PropTypes.node,
  id: PropTypes.string,
  headerId: PropTypes.string,
};
