import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { IQFormSelect } from '@gannettdigital/shared-react-components';
import { BUFFER_TIMES, LEAD_TIMES } from '../../bookings-constants';
import {
  APPOINTMENT_BUFFER_AFTER_KEY,
  APPOINTMENT_BUFFER_BEFORE_KEY,
  APPOINTMENT_LEAD_KEY,
  updateAppointmentTypeField,
} from '../../data/appointment-types-slice';
import { InputGridWrapper } from '../../../../../shared/settings-shared-components';

/**
 * Component for the timing input section of an appointment type
 */
export default function TimingInputWidget({
  fieldNameRoot, cardIndex,
}) {
  const dispatch = useDispatch();

  const handleTimingChange = useCallback((event, key) => {
    dispatch(updateAppointmentTypeField({
      fieldName: key,
      fieldValue: event.target.value,
      typeIndex: cardIndex,
    }));
  });

  return (
    <Grid item container spacing={{ xs: 2, sm: 6 }}>
      <InputGridWrapper item xs={12} sm={4}>
        <IQFormSelect
          tooltipText="Add travel or prep time before each appointment."
          labelText="Buffer Time Before"
          items={BUFFER_TIMES}
          name={`${fieldNameRoot}.${APPOINTMENT_BUFFER_BEFORE_KEY}`}
          onChange={(e) => handleTimingChange(e, APPOINTMENT_BUFFER_BEFORE_KEY)}
        />
      </InputGridWrapper>
      <InputGridWrapper item xs={12} sm={4}>
        <IQFormSelect
          tooltipText="Add wrap-up or rest time after each appointment."
          labelText="Buffer Time After"
          items={BUFFER_TIMES}
          name={`${fieldNameRoot}.${APPOINTMENT_BUFFER_AFTER_KEY}`}
          onChange={(e) => handleTimingChange(e, APPOINTMENT_BUFFER_AFTER_KEY)}
        />
      </InputGridWrapper>
      <InputGridWrapper item xs={12} sm={4}>
        <IQFormSelect
          tooltipText="This is the time you'd like to block off before your first available appointment."
          labelText="Lead Time"
          items={LEAD_TIMES}
          name={`${fieldNameRoot}.${APPOINTMENT_LEAD_KEY}`}
          onChange={(e) => handleTimingChange(e, APPOINTMENT_LEAD_KEY)}
        />
      </InputGridWrapper>
    </Grid>
  );
}

TimingInputWidget.propTypes = {
  fieldNameRoot: PropTypes.string.isRequired,
  cardIndex: PropTypes.number.isRequired,
};
