import * as yup from 'yup';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { IQSwitch, ValidationProvider } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { CHATBOT_CONNECT_SCHEDULING_FORM, CHATBOT_CONNECT_SCHEDULING_SECTION, CHATBOT_CONNECT_SCHEDULING_KEY } from '../../chatbot-constants';
import { updateChatbotSchedulingLinkField } from '../../data/general-settings-slice';
import SectionCard from '../../../../../shared/SectionCard';
import { FREE_TIER, PRO_TIER } from '../../../../../shared/app-constants';
import ChatbotStateChangedHook from '../../../../../shared/stateChangedHook/ChatbotStateChangedHook';

const FormWrapper = styled('form')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const SwitchWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  '& .MuiFormControlLabel-root': {
    border: 'unset',
  },
  '& .MuiFormControlLabel-root .MuiSwitch-root': {
    margin: theme.spacing(0, 1, 0, 0),
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
}));

const WarningWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const FORM_KEYS = [
  CHATBOT_CONNECT_SCHEDULING_KEY,
];

export default function ConnectToSchedulingCard() {
  const dispatch = useDispatch();
  const chatTier = useSelector((state) => state.chatbot.tier);
  const schedulerTier = useSelector((state) => state.chatbot.scheduler.tier);
  const schedulerIsActive = useSelector((state) => state.chatbot.scheduler.isActive);
  const isLinked = useSelector((state) => state.chatbot.generalSettings[CHATBOT_CONNECT_SCHEDULING_SECTION][CHATBOT_CONNECT_SCHEDULING_KEY]);
  const isChatFreeTier = chatTier === FREE_TIER;
  const showWarning = !isChatFreeTier && (!schedulerIsActive || schedulerTier === FREE_TIER);
  const schema = yup.object().shape({
    [CHATBOT_CONNECT_SCHEDULING_KEY]: yup.bool(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [CHATBOT_CONNECT_SCHEDULING_KEY]: isLinked,
    }), [isLinked]),
  });

  const toggleConnectToScheduling = (value) => {
    dispatch(
      updateChatbotSchedulingLinkField(value),
    );
  };

  return (
    <SectionCard
      id="connect-to-scheduling-card"
      title="Connect to Scheduling"
      tooltipText="Allow your Chatbot to link to your Scheduling tool. Your Scheduling feature must be activated for this feature to work."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <FormWrapper>
            <SwitchWrapper>
              <IQSwitch
                id="connect-to-scheduling-switch"
                name={CHATBOT_CONNECT_SCHEDULING_KEY}
                label="Enable Scheduling in Chatbot"
                onChange={toggleConnectToScheduling}
                disabled={showWarning}
              />
            </SwitchWrapper>
            {
              showWarning && (
                <WarningWrapper>
                  <Typography variant="body1">
                    {
                      schedulerTier === PRO_TIER ? `Please turn on Scheduler first` : ''
                    }
                  </Typography>
                </WarningWrapper>
              )
            }
          </FormWrapper>
          <ChatbotStateChangedHook
            formName={CHATBOT_CONNECT_SCHEDULING_FORM}
            formKeys={FORM_KEYS}
          />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
}
