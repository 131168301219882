import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Button, CircularProgress, Grid, Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import { ActionDescription, InputGridWrapper, PrimaryButton } from '../../../shared/settings-shared-components';
import SectionCard from '../../../shared/SectionCard';
import { generateProvision, resetError, setAreaCode } from '../data/sms-slice';
import ActionCard from '../../../shared/ActionCard';
import { formatPhoneNumberUtil } from '../../../shared/app-utils';
import { ActionIcons, ERROR_TYPE } from '../../../shared/app-constants';
import { ReactComponent as PhoneIcon } from '../../products/callSettings/assets/phone-light-icon.svg';
import { ReactComponent as FileCopyIcon } from '../../../images/blue-copy.svg';

const SMS_AREA_CODE = 'smsAreaCode';

const CopyButton = styled(PrimaryButton)(({ theme }) => ({
  width: '120px',
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Unify Sans Semibold',
  '& svg': {
    fill: theme.palette.common.white,
  },
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    backgroundColor: `rgb(0 70 255 / 38%)`,
    color: theme.palette.common.white
  },
}));

const CopySuccess = styled(PrimaryButton)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: 'Unify Sans Semibold',
  '& svg': {
    fill: theme.palette.primary.main,
  },
}));

const AdjustedButtonWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  '& button.MuiButton-root': {
    marginTop: 6,
    padding: '7px 30px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      width: '100%',
    },
  },
}));

const ActionWrapper = styled('div')(() => ({
  marginBottom: '24px'
}));

const SpinnerWrapper = styled('div')(() => ({
  marginLeft: '16px'
}));

const GeneratedNumberWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  marginTop: '24px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const HAS_NUMBER_TEXT = 'The unique phone number for SMS messaging is below.';
const NO_NUMBER_TEXT = 'Enter an area code to create a unique phone number for SMS messaging.';

export default function SMSAreaCode() {
  const smsPhoneNumber = useSelector((state) => state.tools.sms.smsInfo.areaCode);
  const hasError = useSelector((state) => state.tools.sms.error);
  const isGenerating = useSelector((state) => state.tools.sms.generating);
  const generatedNumber = useSelector((state) => state.tools.sms.generatedNumber);
  const [showChip, setShowChip] = useState(false);

  const formattedNumber = formatPhoneNumberUtil(generatedNumber);

  const dispatch = useDispatch();
  const schema = yup.object().shape({
    [SMS_AREA_CODE]: yup.string().required("Area code can't be empty").nullable()
      .test('maxDigits', 'Number field must have exactly 3 digits', (val) => {
        if (!val) {
          return false;
        }

        return String(val).length === 3;
      })
  });

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      [SMS_AREA_CODE]: smsPhoneNumber || '',
    }
  });

  const { handleSubmit, watch } = methods;
  const phoneNum = watch(SMS_AREA_CODE);

  const getProvisionNumber = (formData) => {
    const areaCode = formData[SMS_AREA_CODE];
    dispatch(setAreaCode({ value: areaCode }));
    dispatch(generateProvision());
  };

  const onlyNumRegex = /^(\s*|[0-9]+)$/;
  const onlyNumbers = phoneNum?.match(onlyNumRegex);

  const handleCopyClick = () => {
    setShowChip(true);
    navigator.clipboard.writeText(formattedNumber);
    window.setTimeout(() => {
      setShowChip(false);
    }, 1500);
  };

  useEffect(() => {
    dispatch(resetError());
  }, [phoneNum]);

  return (
    <SectionCard
      id="sms-area-code-card"
      title="Provision SMS Number"
      description={generatedNumber ? HAS_NUMBER_TEXT : NO_NUMBER_TEXT}
      showDividingLine={false}
      bannerSection={hasError && (
        <ActionWrapper>
          <ActionCard
            id="sms-action-banner"
            type={ERROR_TYPE}
            icons={ActionIcons}
          >
            <ActionDescription id="sms-area-code-error-msg">
              No match to that area code. Please try again or use a different area code.
            </ActionDescription>
          </ActionCard>
        </ActionWrapper>
      )}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(getProvisionNumber)}>
            {generatedNumber
              ? (
                <GeneratedNumberWrapper>
                  <PhoneIcon />
                  <Typography>
                    {formattedNumber}
                  </Typography>
                  {!showChip ? (
                    <CopyButton variant="outlined" disabled={hasError} onClick={handleCopyClick}>
                      <FileCopyIcon />
                      <span style={{ marginRight: '10px' }}>Copy</span>
                    </CopyButton>
                  ) : (
                    <CopySuccess variant="outlined">
                      Code has been copied
                    </CopySuccess>
                  )}
                </GeneratedNumberWrapper>
              )
              : (
                <Grid container item sm={9} spacing={3}>
                  <InputGridWrapper item xs={12} sm={3} sx={{ marginTop: '24px' }}>
                    <IQFormInput
                      id="sms-area-code"
                      name={SMS_AREA_CODE}
                      maxLength={3}
                      labelText="Area Code"
                      textId="sms-area-code-label"
                    />
                  </InputGridWrapper>
                  <AdjustedButtonWrapper item xs={12} sm={4}>
                    <SubmitBtn variant="contained" type="submit" id="sms-area-code-submit-btn" disabled={(!onlyNumbers || phoneNum?.length !== 3 || isGenerating)}>
                      Submit {(isGenerating && !hasError) && <SpinnerWrapper><LoadingSpinner size="1rem" /></SpinnerWrapper>}
                    </SubmitBtn>
                  </AdjustedButtonWrapper>
                </Grid>
              )}
          </form>
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
}

