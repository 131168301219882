import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Divider, Typography } from '@mui/material';
import SettingsLayout from '../../layouts/SettingsLayout';
import { CustomCard, LinkButton } from '../../shared/settings-shared-components';
import ContactSkeleton from './ContactSkeleton';
import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER, SCHEDULE_A_CALL, CONTACT_TIMES } from "../../shared/app-constants";
import LeftPic from "../../images/left-contact-us.png"
import OrangeArrow from "../../images/orange-arrow.svg"
import EmailIcon from "../../images/contact-email-us.svg";
import ScheduleCallIcon from "../../images/contact-schedule-icon.svg";
import CallUsIcon from "../../images/call-us-icon.svg";
import TextUsIcon from "../../images/text-us-icon.svg";
import { trackContactUsEvent, trackPageView } from "../../shared/analytics-utils";

export const ContactWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const HeaderImageWrapper = styled('div')(({ theme }) => ({
  width: '30%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));

export const HeaderListWrapper = styled('div')(({ theme }) => ({
  marginLeft: '3rem',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    padding: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

const ContactImage = styled('img')(() => ({
  width: '9.5rem',
}));

const CardWrapper = styled(CustomCard)(({ theme }) => ({
  width: 688,
  padding: 0,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));

export const ContactHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.common.black}`,
  padding: '24px 24px 4px 24px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '8px',
  },
}));

const ContactHeader = styled(Typography)(({ isMobile }) => ({
  fontSize: 20,
  fontFamily: 'Unify Sans Semibold',
  marginLeft: isMobile && '1rem',
  textAlign: isMobile && 'start',
}));

const ListWrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyleImage: `url(${OrangeArrow})`,
  paddingLeft: '1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: '0 16px',
  },
}));

const ListItem = styled('li')(() => ({
  marginBottom: '8px',
  fontSize: 16,
  lineHeight: '24px',
}));

export const ContactSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 24px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const CardTitle = styled(Typography)(() => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: 20,
}));

const CardSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  fontFamily: 'Unify Sans Semibold',
}));

const CardLink = styled(LinkButton)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Unify Sans Semibold',
}));

const Icon = styled('img')(() => ({
  marginLeft: '.5rem',
}));

export const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.action.disabledBackground,
  width: '93.5%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '86.5%',
  },
}));

const ContactTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: 'Unify Sans Semibold',
  textAlign: 'center',
  marginRight: '35rem',
  [theme.breakpoints.down('md')]: {
    marginRight: '31rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '38%',
  },
}));

const SectionMobileDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '11px',
  },
}));

const contactList = [
  'Find the right products for your business',
  'Find out how to set up, use and get the most out of your LocaliQ products',
  'Get help adding, editing, or cancelling subscriptions',
];

const subjectLine = 'LocaliQ Assistance';

export default function ContactUsPage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      const loc = window.location;
      if (loc && loc.pathname) {
        trackPageView(loc.pathname);
      }
    }
  }, [loading]);

  return (
    <SettingsLayout addPadding>
      {!loading ? (
        (
          <>
            <ContactTitle> Contact Us </ContactTitle>
            <ContactWrapper>
              <CardWrapper>
                <ContactHeaderWrapper>
                  <HeaderImageWrapper>
                    {isMobile && <ContactHeader isMobile={isMobile}> Speak with a LocaliQ representative </ContactHeader>}
                    <ContactImage src={LeftPic} />
                  </HeaderImageWrapper>
                  <HeaderListWrapper>
                    {!isMobile && <ContactHeader isMobile={isMobile}> Speak with a LocaliQ representative </ContactHeader>}
                    <ListWrapper>
                      {contactList.map(point => (
                        <ListItem> {point} </ListItem>
                      ))}
                    </ListWrapper>
                  </HeaderListWrapper>
                </ContactHeaderWrapper>
                <ContactSection>
                  <SectionMobileDiv>
                    <CardTitle> Email Us </CardTitle>
                  </SectionMobileDiv>
                  <CardLink href={`mailto:${SUPPORT_EMAIL}?subject=${subjectLine}`} target="_blank" onClick={() => trackContactUsEvent("email")} > {SUPPORT_EMAIL} <Icon src={EmailIcon} /> </CardLink>
                </ContactSection>
                <CustomDivider />
                <ContactSection>
                  <SectionMobileDiv>
                    <CardTitle> Text Us </CardTitle>
                    <CardSubtitle> {CONTACT_TIMES} </CardSubtitle>
                  </SectionMobileDiv>
                  <CardLink href="sms:8182740225" onClick={() => trackContactUsEvent("text")} > {SUPPORT_PHONE_NUMBER} <Icon src={TextUsIcon} /></CardLink>
                </ContactSection>
              </CardWrapper>
            </ContactWrapper>
          </>
        )) : <ContactSkeleton />}
    </SettingsLayout>
  );
}
