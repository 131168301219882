import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { ActionCard } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { Pagination, Table, TableBody, TableCell, TableContainer, Paper, TableRow, TableHead, Typography } from '@mui/material';
import { CustomCard, WidgetActionCardWrapper } from '../../../../shared/settings-shared-components';
import EmptyStateCard from '../../../../shared/emptyState/EmptyStateCard';
import { ERROR_TYPE, ActionIcons } from '../../../../shared/app-constants';
import { loadInvoicesAction } from '../data/invoices-slice';
import { STRIPE_KEY } from '../account-constants';
import PaymentNotification from '../components/PaymentNotification';
import InvoiceRowCard from '../components/cards/InvoiceRowCard';

const InvoiceWrapper = styled(CustomCard)(({ theme, emptyState, isMobile }) => ({
  height: emptyState ? '500px' : '200%',
  padding: isMobile ? '0' : '16px 0',
  backgroundColor: isMobile && theme.palette.background.default,
  margin: isMobile && 0,
  border: isMobile && 'none',
  boxShadow: isMobile && 'none',
}));

const TableWrapper = styled(TableContainer)(({ theme, isMobile }) => ({
  backgroundColor: isMobile && theme.palette.background.default,
  border: 'none',
  boxShadow: 'none'
}));

const InvoiceTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: 'Unify Sans Semibold',
  color: theme.palette.common.black,
  marginBottom: 21
}));

const ProductNameCell = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontFamily: 'Unify Sans Semibold',
  color: theme.palette.common.black
}));

const InvoiceInnerTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontFamily: 'Unify Sans Semibold',
  color: theme.palette.common.black,
  marginLeft: '2rem'
}));

const ErrorWrapper = styled(WidgetActionCardWrapper)(() => ({
  width: '100%'
}));

const ActionDescription = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center'
}));

const StyledPagination = styled(Pagination)(({ hidePagination }) => ({
  marginRight: '1rem',
  "& .MuiPaginationItem-root.Mui-disabled": {
    display: 'none'
  },
  "& .MuiPaginationItem-root": {
    display: hidePagination && 'none'
  },
  "& .MuiPagination-text": {
    display: 'none'
  },
  "& .MuiPagination-ul": {
    justifyContent: 'flex-end',
    marginTop: '1rem'
  },
  "& .MuiPagination-ul li:nth-of-type(n+2):nth-last-of-type(n+2)": {
    display: 'none'
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Next'",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-of-type button::after": {
    content: "'Previous'",
    marginLeft: "8px",
  },
}));

export default function Invoices() {
  const dispatch = useDispatch();
  const invoices = useSelector(state => state.account.invoices.invoices);
  const hasError = useSelector(state => state.account.invoices.hasError);
  const totalPages = useSelector(state => state.account.invoices.totalPages);
  const currentPage = useSelector(state => state.account.invoices.currentPage);
  const errCode = useSelector(state => state.account.invoices.errMsg);
  const isLoading = useSelector(state => state.account.invoices.isLoading);

  const isStripeErr = errCode === STRIPE_KEY;
  const hasInvoices = invoices.length > 0;
  const [page, setPage] = useState(0);

  const handlePageChange = useCallback((e, newPage) => {
    dispatch(loadInvoicesAction({
      currentPage: newPage - 1,
      totalDisplayPerPage: 10,
    }),
    );
    setPage(newPage);
  }, [currentPage]);

  return (
    <section>
      {(hasError && !isStripeErr) && (
        <ErrorWrapper>
          <ActionCard
            id="error"
            type={ERROR_TYPE}
            icons={ActionIcons}
          >
            <ActionDescription>Unable to load your invoices at this time. Please try again later.</ActionDescription>
          </ActionCard>
        </ErrorWrapper>
      )}
      {(hasInvoices) && <InvoiceTitle>Invoices</InvoiceTitle>}
      <PaymentNotification showCTA />
      <InvoiceWrapper emptyState={!hasInvoices} isMobile={isMobile} >
        {!hasInvoices && <InvoiceInnerTitle>Invoices</InvoiceInnerTitle>}
        {hasInvoices && (
          <>
            <TableWrapper component={Paper} isMobile={isMobile}>
              <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                {!isMobile && (
                  <TableHead>
                    <TableRow>
                      <ProductNameCell align="center">Product</ProductNameCell>
                      <ProductNameCell align="center">Invoice Date</ProductNameCell>
                      <ProductNameCell align="center">Amount</ProductNameCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {hasInvoices && invoices.map(invoice => <InvoiceRowCard invoice={invoice} />)}
                </TableBody>
              </Table>
            </TableWrapper>
            <StyledPagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              range={totalPages}
              hidePagination={totalPages === 1}
            />
          </>
        )}
        {(!hasInvoices && !isLoading) && <EmptyStateCard text="This is where your invoices will live." />}
      </InvoiceWrapper>
    </section>
  )
}