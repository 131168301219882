import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import googleCalenderSvg from '../assets/google-calendar.svg';
import { connectGoogleCalendarAction, googleAuthStatus } from '../data/google-calendar-slice';
import { GOOGLE_TYPE, INITIAL_SCOPE } from '../bookings-constants';
import ConnectedCalendar from './ConnectedCalendar';
import { updateCalendarIntegrationAction } from '../data/calendar-actions';
import CalendarConnectCard from './components/CalendarConnectCard';
import { LoadingCard } from '../../../../shared/settings-shared-components';
import GoogleSignInButton from '../../../../shared/signInButton/GoogleSignInButton';

const {
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env;

export default function GoogleCalendar() {
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.googleCalendar.isConnected);
  const isLoading = useSelector((state) => state.googleCalendar.isLoading);
  const {
    email,
    connectedOn,
  } = useSelector((state) => state.googleCalendar.calendarIntegrationData);
  const outlookInProgress = useSelector((state) => state.outlookCalendar.isAuthModalOpen);
  const outlookConnected = useSelector((state) => state.outlookCalendar.isConnected);

  const connectGoogleCalendar = useCallback((response) => {
    const { code, scope } = response;
    dispatch(connectGoogleCalendarAction({
      code,
      scope,
    }));
  }, [dispatch, connectGoogleCalendarAction]);

  const disconnectGoogleCalendar = useCallback(() => {
    dispatch(
      updateCalendarIntegrationAction({ type: GOOGLE_TYPE }),
    );
  }, [dispatch, updateCalendarIntegrationAction]);

  const googleErrorHandler = useCallback(() => {
    // Failure or they closed the modal early
    dispatch(googleAuthStatus({
      isActive: false,
    }));
  }, [dispatch, googleAuthStatus]);

  const initializeClient = useCallback(() => {
    dispatch(googleAuthStatus({
      isActive: true,
    }));
    const client = google.accounts.oauth2.initCodeClient({
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      scope: INITIAL_SCOPE,
      redirect_uri: 'postmessage',
      ux_mode: 'popup',
      callback: connectGoogleCalendar,
      error_callback: googleErrorHandler,
    });

    client.requestCode();
  }, [dispatch, connectGoogleCalendar, googleAuthStatus]);

  return (
    <>
      {isConnected && !isLoading
        && (
          <ConnectedCalendar
            title="Connected Calendar"
            email={email}
            connectedOn={connectedOn}
            disconnectCalendar={disconnectGoogleCalendar}
            calendarIcon={googleCalenderSvg}
          />
        )}
      {isLoading
        && (
          <LoadingCard style={{ padding: '24px' }}>
            <IQLoadingSpinner size={100} />
          </LoadingCard>
        )}
      {!isConnected && !isLoading
        && (
          <CalendarConnectCard
            headerText="Connect your Google Calendar"
            calendarImg={googleCalenderSvg}
            calendarButton={(
              <GoogleSignInButton
                onClick={initializeClient}
                disabled={outlookConnected || outlookInProgress}
              />
            )}
            disabled={outlookConnected || outlookInProgress}
          />
        )}
    </>
  );
}
