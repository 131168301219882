import styled from '@emotion/styled';
import SkeletonText from '../SkeletonText';

const WelcomeCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5, 3),
  gap: theme.spacing(3),
}));

const WelcomeCardHeadWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const WelcomeCardContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export default function LoadingSkeletonWelcomeCardMobile() {
  return (
    <WelcomeCardWrapper>
      <WelcomeCardHeadWrapper>
        <SkeletonText animation="wave" variant="string" width={260} height={40} />
        <SkeletonText animation="wave" variant="string" width={183} height={40} />
      </WelcomeCardHeadWrapper>
      <WelcomeCardContentWrapper>
        <SkeletonText animation="wave" variant="string" width="100%" height={16} />
        <SkeletonText animation="wave" variant="string" width="100%" height={16} />
        <SkeletonText animation="wave" variant="string" width="100%" height={16} />
        <SkeletonText animation="wave" variant="string" width="100%" height={16} />
        <SkeletonText animation="wave" variant="string" width={92} height={16} />
      </WelcomeCardContentWrapper>
      <SkeletonText animation="wave" variant="string" width={156} height={40} />
    </WelcomeCardWrapper>
  );
}
