import {
  IQFormTextArea,
  SectionCard,
  IQTheme,
  ValidationProvider,
} from "@gannettdigital/shared-react-components";
import styled from '@emotion/styled';
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { CharacterCount } from "../../../../../shared/settings-shared-components";
import { AUTO_REPLY_FORM, AUTO_REPLY_KEY } from "../../settings-constants";
import { updateAutoResponseText } from "../../data/notifications-slice";
import WidgetSettingsStateChangedHook from "../../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook";

const TextWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const FORM_KEYS = [ AUTO_REPLY_KEY ];

export default function AutoReplyCard() {
  const dispatch = useDispatch();
  const businessName = useSelector((state) => state.account.businessDetails.businessDetails.businessName);
  const autoReplyText = useSelector((state) => state.settings.notifications.customWelcomeMessage);

  const schema = yup.object().shape({
    [AUTO_REPLY_KEY]: yup.string().required('This field cannot be empty.'),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      [AUTO_REPLY_KEY]: autoReplyText,
    },
  });

  const handleFieldChange = (e) => {
    dispatch(updateAutoResponseText(e.target.value))
  }

  const { watch, formState: {errors} } = methods;
  const autoReplyValue = watch(AUTO_REPLY_KEY);
  const autoReplyValueCharCount = autoReplyValue ? autoReplyValue.length : 0;

  return (
    <SectionCard
      id="auto-reply-message"
      title="Auto-Reply Messages"
      description="Auto-reply messages are and automatic response that is sent the first time a contact messages (SMS) your business."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form>
            <Grid container marginTop="24px">
              <Grid item xs={12}>
                <IQFormTextArea
                  id="auto-reply-text"
                  name={AUTO_REPLY_KEY}
                  fontLabelWeight="600"
                  maxLength={350}
                  fullWidth
                  placeholder={`Thank you for reaching out to ${businessName}! We will be in touch shortly. Text STOP to opt-out.`}
                  theme={IQTheme}
                  rowCount={3}
                  onBlur={(event) =>
                    handleFieldChange(event)
                  }
                />
                <CharacterCount>
                  <TextWrapper>{errors?.customWelcomeMessage && errors?.customWelcomeMessage?.message}</TextWrapper>
                  {autoReplyValueCharCount} /350
                </CharacterCount>
              </Grid>
            </Grid>
            <WidgetSettingsStateChangedHook
              formName={AUTO_REPLY_FORM}
              formKeys={FORM_KEYS}
              subsectionKey="notificationsSettings"
            />
          </form>
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
}
