import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Modal,
  Typography,
  useTheme,
  Card
} from '@mui/material';
import warningIcon from '../../images/warning-icon.svg';

const ModalBackground = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  margin: '0 auto',
  textAlign: 'center',
  width: 600,
  top: '30%',
  position: 'relative',
  borderRadius: "8px",
  border: `1px solid ${theme.palette.common.black}`,
  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    width: 377,
  },
}));

const TopColorBar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  height: 8,
  width: '100%',
}));


const NoButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Unify Sans SemiBold',
  color: theme.palette.text.primary,
  fontSize: 14,
  lineHeight: '18px',
  textTransform: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'transparent',
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: "1%",
  },
}));

const YesButton = styled(NoButton)(({ theme }) => ({
  textDecoration: 'none',
  borderColor: theme.palette.text.primary,
  padding: theme.spacing('11px', '20px'),
  borderRadius: "20px",
  border: `1px solid ${theme.palette.text.primary}`,
  '&:hover': {
    background: 'inherit',
    textDecoration: 'none',
    borderColor: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },
}));

const ButtonWrapper = styled('div')(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 20px"
  },
}));

export default function WarningModal({
  titleText,
  promptText,
  leftInteractText,
  rightInteractText,
  isOpen = false,
  leftClickCallback = () => { },
  rightClickCallback = () => { },
  ...props
}) {
  const theme = useTheme();
  const { id } = props;

  return (
    <Modal id={id} open={isOpen} {...props}>
      <ModalBackground>
        <TopColorBar />
        <Box sx={{ mt: theme.spacing(6), mb: theme.spacing(4) }}>
          <img src={warningIcon} alt="warning-icon" id={`${id}-warning-icon`} />
        </Box>
        <Typography
          id={`${id}-warning-title`}
          sx={{
            fontFamily: 'Unify Sans SemiBold',
            fontSize: 24,
            lineHeight: '30px',
            maxWidth: 450,
            margin: '0 auto',
          }}
        >
          {titleText}
        </Typography>
        <Typography
          id={`${id}-warning-description`}
          sx={{
            fontSize: 16,
            lineHeight: '24px',
            pt: theme.spacing(2),
            pb: theme.spacing(3),
          }}
        >
          {promptText}
        </Typography>
        <ButtonWrapper>
          <NoButton sx={{ mr: theme.spacing(4) }} type="button" variant="text" onClick={leftClickCallback} id={`${id}-left-interaction-btn`}>
            {leftInteractText}
          </NoButton>
          <YesButton type="button" variant="outlined" onClick={rightClickCallback} id={`${id}-right-interaction-btn`}>
            {rightInteractText}
          </YesButton>
        </ButtonWrapper>
      </ModalBackground>
    </Modal>
  );
}

WarningModal.propTypes = {
  titleText: PropTypes.string.isRequired,
  promptText: PropTypes.string.isRequired,
  leftInteractText: PropTypes.string.isRequired,
  rightInteractText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  leftClickCallback: PropTypes.func,
  rightClickCallback: PropTypes.func,
};
