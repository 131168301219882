import { createAction, createSlice } from '@reduxjs/toolkit';
import combineSlices from '../../../../redux/combine-slices';
import notificationsSlice, { NOTIFICATIONS_SETTINGS_SECTION, SMS_NOTIFICATIONS_SECTION, saveNotificationsSuccess, NOTIFICATIONS_SLICE_NAME, saveNotificationsFailure } from './notifications-slice';
import { APPT_REMINDER_SMS, APPT_RESCHEDULE_CANCEL_SMS, CALLTRACKER_VALUE, CALL_TRACKER_WIDGET, CHATBOT_WIDGET, CHAT_VALUE, MESSAGE_VALUE, MESSAGE_WIDGET, NEW_APPT_SMS, NOTIFICATION_EMAILS_KEY, SCHEDULER_VALUE, SCHEDULER_WIDGET, SMS_MESSAGES_DASH, SMS_PHONE_NUMBER_KEY, SMS_UNANSWERED_NOTIFICATIONS, SMS_VALUE, SMS_WIDGET, WIDGET_LOCATION, WIDGET_LOCATION_RIGHT, SMS_LEAD_NOTIFICATIONS_SMS, SMS_LEAD_NOTIFICATIONS_EMAIL } from '../settings-constants';
import { loadSettingsDataSuccess } from '../../../../shared/app-slice';
import { WIDGET_COLOR_KEY, WIDGET_FEATURE_KEY } from '../../../configuration/wizard/wizard-constants';
import { COLOR_KEY, THEME_TO_COLOR } from '../../../../shared/app-constants';
import { setWizardStatusSuccess } from '../../../configuration/data/configuration-slice';
import { CALL_TRACKER_FEATURE, CHAT_FEATURE, CONTACT_FORM_FEATURE, SCHEDULER_FEATURE } from '../../../configuration/data/slice-constants';
import { ACTIVATION } from '../../../../shared/websocket/websocket-constants';
import { closeWarningModal } from '../../../../redux/shared-actions';

export const saveEngagementWidgetAction = createAction(`${NOTIFICATIONS_SLICE_NAME}/saveWidget`);

const initialState = {
  isSaving: false,
  dirtySections: [],
  isDirty: false,
  invalidSections: [],
  isInvalid: false,
  showChangesSaved: false,
  hasReset: false,
  hasError: false,
  showWarningModal: false,
  loadingTrackingNumber: false,
  trackingNumber: '',
  initialColor: '',
  engagementWidget: {
    [WIDGET_COLOR_KEY]: '',
    [WIDGET_LOCATION]: WIDGET_LOCATION_RIGHT,
    [WIDGET_FEATURE_KEY]: {
      [SCHEDULER_VALUE]: false,
      [CHAT_VALUE]: false,
      [CALLTRACKER_VALUE]: false,
      [MESSAGE_VALUE]: false,
      [SMS_VALUE]: false,
    },
  },
  resetProductInfo: {
    [SMS_NOTIFICATIONS_SECTION]: {
      [NEW_APPT_SMS]: false,
      [APPT_REMINDER_SMS]: false,
      [APPT_RESCHEDULE_CANCEL_SMS]: false,
      [SMS_MESSAGES_DASH]: false,
      [SMS_LEAD_NOTIFICATIONS_SMS]: false,
      [SMS_LEAD_NOTIFICATIONS_EMAIL]: false,
      [SMS_UNANSWERED_NOTIFICATIONS]: false,
    },
    [NOTIFICATIONS_SETTINGS_SECTION]: {
      [NOTIFICATION_EMAILS_KEY]: [],
      [SMS_PHONE_NUMBER_KEY]: [],
    },
    engagementWidget: {
      [WIDGET_COLOR_KEY]: '',
      [WIDGET_LOCATION]: WIDGET_LOCATION_RIGHT,
      [WIDGET_FEATURE_KEY]: {
        [SCHEDULER_VALUE]: false,
        [CHAT_VALUE]: false,
        [CALLTRACKER_VALUE]: false,
        [MESSAGE_VALUE]: false,
        [SMS_VALUE]: false,
      },
    },
  },
};

const widgetSettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLoadingFowarding: (state, action) => {
      if (action.payload.action === ACTIVATION) {
        state.loadingTrackingNumber = true;
        const selectedTrackingNum = action.payload.data.data.selectedTrackingNumber;
        if (selectedTrackingNum) {
          state.trackingNumber = selectedTrackingNum;
          state.loadingTrackingNumber = false;
        }
      }
    },
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySections.indexOf(dirtyForm) === -1) {
        state.dirtySections.push(dirtyForm);
        state.isDirty = true;
      }

      state.invalidSections = state.invalidSections.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;
      state.dirtySections = state.dirtySections.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySections.length > 0;

      state.invalidSections = state.invalidSections.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSections.indexOf(invalidForm) === -1) {
        state.invalidSections.push(invalidForm);
        state.isInvalid = true;
      }
    },
    resetCompleteAction: (state) => {
      state.hasReset = true;
    },
    closeChangesSaved: (state) => {
      state.showChangesSaved = false;
    },
    updateFeatures: (state, action) => {
      state.engagementWidget[WIDGET_FEATURE_KEY][action.payload.formName] = action.payload.isSelected;
    },
    updateColorField: (state, action) => {
      state.engagementWidget[WIDGET_COLOR_KEY] = action.payload[COLOR_KEY];
    },
    updateWidgetLocation: (state, action) => {
      state.engagementWidget[WIDGET_LOCATION] = action.payload[WIDGET_LOCATION];
    },
    setShowWarningModal: (state, action) => {
      state.showWarningModal = action.payload.value;
    },
    cancelSave: (state) => {
      state.hasReset = false;
      state.showWarningModal = state.isDirty;
    },
    updateFeaturesSuccess: (state, action) => {
      state.isSaving = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveEngagementWidgetAction, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(setWizardStatusSuccess, (state, action) => {
    // TODO: remove after #926 gets merged
    // https://github.com/GannettDigital/localiq-dms-product-settings-mgmnt/pull/926
      state.engagementWidget[WIDGET_FEATURE_KEY][SCHEDULER_VALUE] = action.payload.wizard.steps[WIDGET_FEATURE_KEY][SCHEDULER_FEATURE]
      state.engagementWidget[WIDGET_FEATURE_KEY][CHAT_VALUE] = action.payload.wizard.steps[WIDGET_FEATURE_KEY][CHAT_FEATURE]
      state.engagementWidget[WIDGET_FEATURE_KEY][CALLTRACKER_VALUE] = action.payload.wizard.steps[WIDGET_FEATURE_KEY][CALL_TRACKER_FEATURE]
      state.engagementWidget[WIDGET_FEATURE_KEY][MESSAGE_VALUE] = action.payload.wizard.steps[WIDGET_FEATURE_KEY][CONTACT_FORM_FEATURE]
      state.engagementWidget[WIDGET_COLOR_KEY] = action.payload.wizard.steps.widgetColor.color;
      state.engagementWidget.initialColor = action.payload.wizard.steps.widgetColor.color;
    });
    builder.addCase(closeWarningModal, (state, action) => {
      state.showWarningModal = false;
      
      if (action.payload.forceClose) {
        state.hasReset = true;
        state.isDirty = false;
        state.dirtySections = [];
      }
    });
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const WIDGET_PRODUCTS = [SCHEDULER_WIDGET, CHATBOT_WIDGET, CALL_TRACKER_WIDGET, MESSAGE_WIDGET, SMS_WIDGET];

      if (action.payload.products.length > 0) {
        action.payload.products.forEach((product) => {
          const productName = product.productType.slug;
          const trackingNum = product.productInfo.selectedTrackingNumber;

          if (WIDGET_PRODUCTS.includes(product.offeringType)) {
            state.engagementWidget[WIDGET_FEATURE_KEY][productName] = product.active;
            state.resetProductInfo.engagementWidget[WIDGET_FEATURE_KEY][productName] = product.active;
          }

          if(product.offeringType === CALL_TRACKER_WIDGET && trackingNum) {
            state.trackingNumber = trackingNum;
          }
        });
      }

      if (action.payload?.business?.attributes) {
        const { attributes } = action.payload.business;

        // Set color from attributes
        state.engagementWidget[WIDGET_COLOR_KEY] = attributes.theme;
        state.engagementWidget.initialColor = attributes.theme;

        state.engagementWidget[WIDGET_LOCATION] = attributes.widgetLocation || WIDGET_LOCATION_RIGHT;

        // Populate reset state from loaded values
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][NEW_APPT_SMS] = attributes[NEW_APPT_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][APPT_REMINDER_SMS] = attributes[APPT_REMINDER_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][APPT_RESCHEDULE_CANCEL_SMS] = attributes[APPT_RESCHEDULE_CANCEL_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_MESSAGES_DASH] = attributes[SMS_MESSAGES_DASH] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_UNANSWERED_NOTIFICATIONS] = attributes[SMS_UNANSWERED_NOTIFICATIONS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_SMS] = attributes[SMS_LEAD_NOTIFICATIONS_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_EMAIL] = attributes[SMS_LEAD_NOTIFICATIONS_EMAIL] || false;
      
        state.resetProductInfo.engagementWidget[WIDGET_COLOR_KEY] = THEME_TO_COLOR[attributes.theme];
        state.resetProductInfo.engagementWidget[WIDGET_LOCATION] = attributes.widgetLocation || WIDGET_LOCATION_RIGHT;


        if (attributes[SMS_PHONE_NUMBER_KEY] && attributes[SMS_PHONE_NUMBER_KEY].length > 0) {
          state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY] = attributes[SMS_PHONE_NUMBER_KEY].map((pN) => ({ phone: pN }));
        } else {
          state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY] = [{ phone: '' }];
        }

        if (attributes.leadNotifications && attributes.leadNotifications.length > 0) {
          state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = attributes.leadNotifications.map((e) => ({ email: e }));
        } else {
          const { user } = action.payload;
          state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = [{ email: user.email }];
        }
      } else {
        const { user } = action.payload;
        state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = [{ email: user.email }];
      }
    });

    builder.addCase(saveNotificationsSuccess, (state, action) => {
      state.showChangesSaved = true;
      state.isSaving = false;

      if (action.payload?.attributes) {
        const { attributes } = action.payload;
        const widgetActivation = attributes.activation;

        if (attributes.theme) {
          state.engagementWidget[WIDGET_COLOR_KEY] = attributes.theme;
          state.engagementWidget.initialColor = attributes.theme;
          state.resetProductInfo.engagementWidget[WIDGET_COLOR_KEY] = THEME_TO_COLOR[attributes.theme];
        }

        if(attributes.widgetLocation) {
          state.engagementWidget[WIDGET_LOCATION] = attributes.widgetLocation;
          state.resetProductInfo.engagementWidget[WIDGET_LOCATION] = attributes.widgetLocation;
        }

        if (widgetActivation) {
          state.engagementWidget[WIDGET_FEATURE_KEY][SCHEDULER_VALUE] = widgetActivation[SCHEDULER_VALUE];
          state.engagementWidget[WIDGET_FEATURE_KEY][CHAT_VALUE] = widgetActivation[CHAT_VALUE];
          state.engagementWidget[WIDGET_FEATURE_KEY][CALLTRACKER_VALUE] = widgetActivation[CALLTRACKER_VALUE];
          state.engagementWidget[WIDGET_FEATURE_KEY][MESSAGE_VALUE] = widgetActivation[MESSAGE_VALUE];
          state.engagementWidget[WIDGET_FEATURE_KEY][SMS_VALUE] = widgetActivation[SMS_VALUE];
          // widget feature reset info
          state.resetProductInfo.engagementWidget[WIDGET_FEATURE_KEY][SCHEDULER_VALUE] = widgetActivation[SCHEDULER_VALUE];
          state.resetProductInfo.engagementWidget[WIDGET_FEATURE_KEY][CHAT_VALUE] = widgetActivation[CHAT_VALUE];
          state.resetProductInfo.engagementWidget[WIDGET_FEATURE_KEY][CALLTRACKER_VALUE] = widgetActivation[CALLTRACKER_VALUE];
          state.resetProductInfo.engagementWidget[WIDGET_FEATURE_KEY][MESSAGE_VALUE] = widgetActivation[MESSAGE_VALUE];
          state.resetProductInfo.engagementWidget[WIDGET_FEATURE_KEY][SMS_VALUE] = widgetActivation[SMS_VALUE];
        }

        if (attributes.leadNotifications && attributes.leadNotifications.length > 0) {
          state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][NOTIFICATION_EMAILS_KEY] = attributes.leadNotifications.map((e) => ({ email: e }))
        }

        if (attributes[SMS_PHONE_NUMBER_KEY] && attributes[SMS_PHONE_NUMBER_KEY].length > 0) {
          state.resetProductInfo[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY] = attributes[SMS_PHONE_NUMBER_KEY].map((pN) => ({ phone: pN }));
        }
        
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][NEW_APPT_SMS] = attributes[NEW_APPT_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][APPT_REMINDER_SMS] = attributes[APPT_REMINDER_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][APPT_RESCHEDULE_CANCEL_SMS] = attributes[APPT_RESCHEDULE_CANCEL_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_MESSAGES_DASH] = attributes[SMS_MESSAGES_DASH] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_UNANSWERED_NOTIFICATIONS] = attributes[SMS_UNANSWERED_NOTIFICATIONS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_SMS] = attributes[SMS_LEAD_NOTIFICATIONS_SMS] || false;
        state.resetProductInfo[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_EMAIL] = attributes[SMS_LEAD_NOTIFICATIONS_EMAIL] || false;
      }
    });
    builder.addCase(saveNotificationsFailure, (state, action) => {
      state.hasError = true;
    });
  },
});

export const {
  setStateDirty,
  setStateClean,
  setStateInvalid,
  closeChangesSaved,
  resetCompleteAction,
  updateFeatures,
  updateColorField,
  setShowWarningModal,
  cancelSave,
  updateFeaturesSuccess,
  setLoadingFowarding,
  updateWidgetLocation
} = widgetSettingsSlice.actions;
export default combineSlices(
  widgetSettingsSlice.reducer,
  initialState,
  {
    notifications: notificationsSlice,
  }
);