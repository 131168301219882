import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import PerformanceCardRow from '../components/performance/PerformanceCardRow';
import {
  PERFORMANCE_CARD_APPTS_NAME,
  PERFORMANCE_CARD_DAYS,
  PERFORMANCE_CHATBOT_THREADS_NAME,
  PERFORMANCE_FORM_FILLS_NAME,
  PERFORMANCE_RECORDED_CALLS_NAME,
  PERFORMANCE_WEBSITE_VISITS_NAME,
} from '../dashboard-constants';
import { useMountEffect } from '../../../shared/mountEffectHook/useMountEffect';
import { loadPerformanceAction } from '../data/dashboard-slice';
import DropDown from '../components/performance/DropDown';
import LoadingSkeletonPerformance from '../components/loadingSkeletons/performance/LoadingSkeletonPerformance';

const StyledContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(4, 5, 2, 5),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 3, 0, 3),
  },
}));

const StyledHead = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
  marginBottom: theme.spacing(3),
}));

const StyledSectionTitle = styled('div')(() => ({
  flexGrow: 1,
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '30px',
}));

const StyledMain = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function PerformanceCard({ wizardFeatures, trackingCodeCheck }) {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(PERFORMANCE_CARD_DAYS[2]);
  const [showDropDown, setShowDropDown] = useState(false);
  const performanceData = useSelector((state) => state.dashboard.performance);
  const performanceIsLoading = useSelector((state) => state.dashboard.performanceIsLoading);
  const trackingCodeIsLoading = useSelector((state) => state.appSettings.trackingCode.isLoading);
  const schedulingWidgetToggle = useSelector((state) => state.bookings.toggle.isActive);
  const chatbotWidgetToggle = useSelector((state) => state.chatbot.toggle.isActive);
  const callSettingsWidgetToggle = useSelector((state) => state.callSettings.isWidgetActive);
  const messageWidgetToggle = useSelector((state) => state.contactForm.toggle.isActive);

  const WIDGET_IS_ACTIVATED_MAP = {
    [PERFORMANCE_CARD_APPTS_NAME]: wizardFeatures.activeScheduler || schedulingWidgetToggle,
    [PERFORMANCE_CHATBOT_THREADS_NAME]: wizardFeatures.activeChatbot || chatbotWidgetToggle,
    [PERFORMANCE_RECORDED_CALLS_NAME]: wizardFeatures.activateCalltracker || callSettingsWidgetToggle,
    [PERFORMANCE_FORM_FILLS_NAME]: wizardFeatures.activateMessage || messageWidgetToggle,
    [PERFORMANCE_WEBSITE_VISITS_NAME]: !trackingCodeCheck,
  };
  const isLoading = performanceIsLoading || trackingCodeIsLoading;

  // select day from dropdown handler
  const onSelectDay = (i) => {
    dispatch(
      loadPerformanceAction({ days: Number(PERFORMANCE_CARD_DAYS[i]) }),
    );
    setSelectedDay(PERFORMANCE_CARD_DAYS[i]);
  };

  // toggle dropdown when clicked just the dropdown
  const onDropDownClick = (e) => {
    e.stopPropagation();
    setShowDropDown((prev) => !prev);
  };

  // click away handler for closing dropdown
  const dropDownClickAwayHandler = () => {
    setShowDropDown(false);
  };

  useMountEffect(() => {
    const DEFAULT_PERFORMANCE_DAY = PERFORMANCE_CARD_DAYS[2];

    dispatch(
      loadPerformanceAction({ days: DEFAULT_PERFORMANCE_DAY }),
    );

    // click away handler for closing dropdown
    document.addEventListener('click', dropDownClickAwayHandler);
  });

  useEffect(() => {
    return function cleanup() {
      document.removeEventListener('click', dropDownClickAwayHandler);
    }
  }, []);

  if (isLoading) {
    return (
      <StyledContainer>
        <LoadingSkeletonPerformance />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledHead>
        <StyledSectionTitle>Performance</StyledSectionTitle>
        <DropDown
          onDropDownClick={onDropDownClick}
          onSelectDay={onSelectDay}
          selectedDay={selectedDay}
          showDropDown={showDropDown}
        />
      </StyledHead>
      <StyledMain>
        {
          performanceData.map((data, i) => (
            <PerformanceCardRow
              key={`performance-card-data-${i + 1}`}
              isActivated={WIDGET_IS_ACTIVATED_MAP[data.name]}
              {...data}
            />
          ))
        }
      </StyledMain>
    </StyledContainer>
  );
}

PerformanceCard.propTypes = {
  wizardFeatures: PropTypes.object,
  trackingCodeCheck: PropTypes.bool,
};
