import { CALL_TRACKER_FEATURE, SCHEDULER_FEATURE } from '../data/slice-constants';

export const CURRENT_STEP_KEY = 'currentStep';
export const STEPS_KEY = 'steps';

// Step keys
export const WELCOME_KEY = 'welcomeStep';
export const WIDGET_FEATURE_KEY = 'widgetFeatures';
export const WIDGET_COLOR_KEY = 'widgetColor';
export const APPOINTMENT_SCHEDULE_KEY = 'appointmentAvailability';
export const BUSINESS_PHONE_KEY = 'businessPhoneNumber';
export const WIDGET_SETUP_COMPLETE_PAGE_KEY = 'widgetSetupCompletePage';

// Section 2 keys
export const WEBSITE_TYPE_KEY = 'websiteType'
export const LOCALIQ_CODE_KEY = 'localiqCode'

// Statuses
export const TRIAL_END_BEHAVIOR_PAUSE = 'pause';
export const COMPLETED_STATUS = 'COMPLETED'; //User reached the wizard complete page and automatically changes status to COMPLETE
export const CANCELLED_STATUS = 'CANCELLED'; //The trial ended or the customer canceled the subscription
export const DRAFT_STATUS = 'DRAFT'; //User is still in the wizard step 1. Have not reached complete page

export const ALL_STEP_IDS = [
  WELCOME_KEY,
  WIDGET_FEATURE_KEY,
  WIDGET_COLOR_KEY,
  APPOINTMENT_SCHEDULE_KEY,
  BUSINESS_PHONE_KEY,
  WIDGET_SETUP_COMPLETE_PAGE_KEY,
  WEBSITE_TYPE_KEY,
  LOCALIQ_CODE_KEY,
];

export const SAVE_CONFIGURATION = {
  [WIDGET_FEATURE_KEY]: WIDGET_FEATURE_KEY,
  [WIDGET_COLOR_KEY]: WIDGET_COLOR_KEY,
  [APPOINTMENT_SCHEDULE_KEY]: APPOINTMENT_SCHEDULE_KEY,
  [BUSINESS_PHONE_KEY]: BUSINESS_PHONE_KEY,
};

// Step constants
export const WELCOME_STEP = {
  id: WELCOME_KEY,
  sideText: <><p>Congratulations on starting your free 30-day trial! Setup should only take a few minutes.</p><p>You can make changes to any of your selections later and customize advanced options in Settings.</p></>,
};

export const WIDGET_FEATURE = {
  id: WIDGET_FEATURE_KEY,
  sideText: <><p>The widgets will display on your website once you've installed LocaliQ Code.</p><p>In the next steps, we will ask for information that will be used for the widgets selected.</p></>,
};

export const WIDGET_COLOR_STEP = {
  id: WIDGET_COLOR_KEY,
  sideText: 'This will be the color of your widgets and scheduling site. Select a color that goes best with your brand.',
};

export const APPOINTMENT_SCHEDULE_STEP = {
  id: APPOINTMENT_SCHEDULE_KEY,
  sideText: 'The times selected will be used for the Scheduling tool so that your customers can only select appointment times that work for you.',
  requirement: [SCHEDULER_FEATURE]
};

export const BUSINESS_PHONE_STEP = {
  id: BUSINESS_PHONE_KEY,
  sideText: 'This phone number will be used for calls placed through the Click-to-Call feature.',
  requirement: [CALL_TRACKER_FEATURE]
};

const WEBSITE_TYPE_STEP = {
  id: WEBSITE_TYPE_KEY,
  sideText: 'LocaliQ Code is a short line of code that allows the widget to display. It\'s quick and easy to add to any website.',
  hideBackButton: true
};

const LOCALIQ_INSTRUCTION_STEP = {
  id: LOCALIQ_CODE_KEY,
  sideText: 'Once you\'ve added the LocaliQ Code to your website, your widgets will display within 5 minutes.  New leads will start to show up in your dashboard as they are captured.',
}

const WIDGET_SETUP_COMPLETE_PAGE_STEP = {
  id: WIDGET_SETUP_COMPLETE_PAGE_KEY,
  sideText: 'Remember, you can make changes to any of your selections later and customize advanced options in Settings.',
  completeWizardStep: true
};

const ALL_STEPS = [
  WELCOME_STEP,
  WIDGET_FEATURE,
  WIDGET_COLOR_STEP,
  APPOINTMENT_SCHEDULE_STEP,
  BUSINESS_PHONE_STEP,
  WEBSITE_TYPE_STEP,
  LOCALIQ_INSTRUCTION_STEP,
  WIDGET_SETUP_COMPLETE_PAGE_STEP,
];

// Don't show Save & Exist on these steps
export const STEPS_WITHOUT_SAVING = [
  WELCOME_KEY,
];

export const ALL_STEPS_BY_ID = ALL_STEPS.reduce((acc, step) => {
  acc[step.id] = step;

  return acc;
}, {});

export const DEFAULT_WELCOME_STEP = {
  id: WELCOME_KEY,
  sideText: <><p>Congratulations on starting your free 30-day trial! Setup should only take a few minutes.</p><p>You can make changes to any of your selections later in Settings.</p></>,
  nextStep: WIDGET_FEATURE_KEY,
  orderIndex: 1,
};

export const TRIAL_STARTED_STEP = {
  ...WIDGET_FEATURE,
  nextStep: WIDGET_COLOR_KEY,
  orderIndex: 2,
};

// Feature keys
export const SCHEDULER_FORM_KEY = 'scheduler-feature';
export const CHATBOT_FORM_KEY = 'chatbot-feature';
export const CLICKTOCALL_FORM_KEY = 'clicktocall-feature';
export const CONTACTFORM_FORM_KEY = 'contactForm-feature';

export const DRUPAL_KEY = "DRUPAL";
export const JOOMLA_KEY = "JOOMLA";
export const WORDPRESS_KEY = "WORDPRESS";
export const STANDARD_SITE_KEY = "OTHER_WEBSITE";

// Form names
// TODO: if adding a new form, add to FORMS_BY_STEP below
export const APPOINTMENT_SCHEDULE_STEP_FORM = 'appointmentScheduleStepForm';
export const BUSINESS_PHONE_STEP_FORM = 'businessPhoneStepForm';

// Step to form name
export const FORMS_BY_STEP = {
  [APPOINTMENT_SCHEDULE_KEY]: [APPOINTMENT_SCHEDULE_STEP_FORM],
  [BUSINESS_PHONE_KEY]: [BUSINESS_PHONE_STEP_FORM],
};

// Wizard Step Saving Statuses
export const WIZARD_STEP_SAVING_STATUS_IN_PROGRESS = 'SAVING';
export const WIZARD_STEP_SAVING_STATUS_SUCCESS = 'SUCCESS';
export const WIZARD_STEP_SAVING_STATUS_FAILURE = 'FAILURE';
