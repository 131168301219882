import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useWatch, Controller } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { updateChatToneField } from '../../data/general-settings-slice';
import ChatbotStateChangedHook from '../../../../../shared/stateChangedHook/ChatbotStateChangedHook';
import SectionCard from '../../../../../shared/SectionCard';
import {
  CHATBOT_PRO_NEUTRAL_VALUE,
  CHATBOT_PRO_FRIENDLY_VALUE,
  CHATBOT_PRO_PROFESSIONAL_VALUE,
  CHATBOT_TONE_FORM,
  CHATBOT_TONE_SECTION,
  CHATBOT_BOT_TONE_KEY,
  CHATBOT_NEUTRAL_TONE,
  CHATBOT_FRIENDLY_TONE,
  CHATBOT_PROFESSIONAL_TONE,
} from '../../chatbot-constants';

const FORM_KEYS = [
  CHATBOT_BOT_TONE_KEY,
];

export default function ChatbotToneCard() {
  const dispatch = useDispatch();
  const savedTone = useSelector(
    (state) => state.chatbot.generalSettings[CHATBOT_TONE_SECTION][CHATBOT_BOT_TONE_KEY]
  );
  const defaultTone = savedTone || CHATBOT_PRO_NEUTRAL_VALUE;

  const schema = yup.object().shape({
    [CHATBOT_BOT_TONE_KEY]: yup.string(),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [CHATBOT_BOT_TONE_KEY]: defaultTone,
    })),
  });

  const { control, setValue } = methods;

  const selectedTone = useWatch({
    control,
    name: CHATBOT_BOT_TONE_KEY,
    defaultValue: defaultTone,
  });

  useEffect(() => {
    if (selectedTone && selectedTone !== savedTone) {
      dispatch(updateChatToneField({ fieldName: CHATBOT_BOT_TONE_KEY, fieldValue: selectedTone }));
    }
    setValue(CHATBOT_BOT_TONE_KEY, selectedTone);
  }, [selectedTone]);

  return (
    <SectionCard
      id="chatbot-tool-tone-card"
      title="Chatbot Tone"
      tooltipText="Select the tone of voice used by your Chatbot in conversations with visitors."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <FormControl name={CHATBOT_BOT_TONE_KEY}>
            <Controller
              control={control}
              name={CHATBOT_BOT_TONE_KEY}
              render={({ field }) => (
                <RadioGroup value={selectedTone} {...field} sx={{ marginTop: '14px'}}>
                  <FormControlLabel name={CHATBOT_PRO_NEUTRAL_VALUE} label={CHATBOT_NEUTRAL_TONE} value={CHATBOT_PRO_NEUTRAL_VALUE} control={<Radio />} />
                  <FormControlLabel name={CHATBOT_PRO_FRIENDLY_VALUE} label={CHATBOT_FRIENDLY_TONE} value={CHATBOT_PRO_FRIENDLY_VALUE} control={<Radio />} />
                  <FormControlLabel name={CHATBOT_PRO_PROFESSIONAL_VALUE} label={CHATBOT_PROFESSIONAL_TONE} value={CHATBOT_PRO_PROFESSIONAL_VALUE} control={<Radio />} />
                </RadioGroup>
              )}
            />
          </FormControl>
          <ChatbotStateChangedHook formName={CHATBOT_TONE_FORM} formKeys={FORM_KEYS} />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
}