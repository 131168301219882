import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import BookingsCTA from '../../../../shared/BookingsCTA';
import { modifyUrl, openNewTab, hasRestrictedAccess } from '../../../../shared/app-utils';
import EmptyStateCard from '../../../../shared/emptyState/EmptyStateCard';
import { ReactComponent as EmptyStateDesktopSvg } from '../assets/list-view-empty-state-desktop.svg';
import { ReactComponent as EmptyStateMobileSvg } from '../assets/list-view-empty-state-mobile.svg';

export default function SchedulerListEmptyCard({ handleOpenSettings }) {
  const schedulerUrl = useSelector((state) => state.bookings.links.main);
  const firstActivationDate = useSelector((state) => state.bookings.firstActivationDate);
  const hasBeenActivated = !!firstActivationDate;

  const trials = useSelector((state) => state.account.trials);
  const orders = useSelector((state) => state.appSettings.orders);
  const disablePageElements = hasRestrictedAccess(trials, orders);

  const emptyStateBtn = !hasBeenActivated
    ? <BookingsCTA
        text='Configure & Launch'
        onClickHandler={handleOpenSettings} 
        variant= "contained"
        disabled={disablePageElements}
      />
    : <BookingsCTA
        startIcon={<AddIcon />} 
        text='Add a Booking'
        disabled={!schedulerUrl || disablePageElements}
        onClickHandler={() => openNewTab(modifyUrl(schedulerUrl.url, 'appointments'))} 
      />

  return (
     <EmptyStateCard
        text={(<>This is where your upcoming and past <br/> appointments will live.</>)}
        emptyStateBtn={emptyStateBtn}
        EmptyStateDesktopSvg={EmptyStateDesktopSvg}
        EmptyStateMobileSvg={EmptyStateMobileSvg}
     />
  )
}

SchedulerListEmptyCard.propTypes = {
  handleOpenSettings: PropTypes.func.isRequired,
};
