import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { triggerResetAction } from '../../../redux/shared-actions';
import { CHATBOT_PAGE, CLICK_TO_CALL_PAGE, EMAIL_CAMPAIGNS_PAGE, SCHEDULING_PAGE, SMS_PAGE } from '../../../shared/navigation/navigation-config';
import { setCurrentScreen } from '../../../shared/navigation/navigation-slice';
import { resetCompleteAction } from '../../products/settings/data/widgetSettings-slice';
import { saveEmailCampaignAction, updateEmailFooterFailure, updateEmailFooterSuccess } from './emailCampaign-slice';
import { generateProvision, generateProvisionFailed, generateProvisionSuccess, saveProvisionNumberFailed, saveProvisionNumberSuccess } from './sms-slice';
import { NO_AREA_CODE_ERR } from './tools-constants';
import { setCurrentToolsScreen } from './tools-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_DMS_INTERACTION_ROOT
} = process.env;

const updateToolsState = (action$) => action$.pipe(
  filter(setCurrentScreen.match),
  map((action) => {
    const { currentScreen } = action.payload;
    let rootKey = '';

    switch (currentScreen) {
      case SMS_PAGE:
        rootKey = 'sms';
        break;
      case SCHEDULING_PAGE:
        rootKey = 'bookings';
        break;
      case CHATBOT_PAGE:
        rootKey = 'chatbot';
        break;
      case CLICK_TO_CALL_PAGE:
        rootKey = 'callSettings';
        break;
      case EMAIL_CAMPAIGNS_PAGE:
        rootKey = 'emailCampaign';
        break;
      default:
        break;
    }
    return setCurrentToolsScreen({
      rootKey,
      currentScreen,
    });
  }),
);

const saveEmailCampaignEpic = (action$, state$) => action$.pipe(
  filter(saveEmailCampaignAction.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/email_campaign/${action.payload.tier}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: state$.value.tools.emailCampaign.emailInfo,
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => updateEmailFooterSuccess(response.response)),
    catchError((error) => (
      of(updateEmailFooterFailure(error))
    )),
  )),
);

// generates a list of provision code. change size later for when user chooses
// what phone number they want. 
const getSMSProvisionNumber = (action$, state$) => action$.pipe(
  filter(generateProvision.match),
  mergeMap((action) => ajax(
    {
      url: `${REACT_APP_DMS_INTERACTION_ROOT}interactions/numbers/list`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: state$.value.tools.sms.smsInfo,
      withCredentials: true,
    },
  ).pipe(
    map((response) => {
      if (response && Array.isArray(response.response) && response.response[0]) {
        return generateProvisionSuccess(response.response)
      } else {
        return generateProvisionFailed(NO_AREA_CODE_ERR)
      }
    }),
    catchError((error) => (
      of(generateProvisionFailed(error))
    )),
  )),
);

// save provision code so it can load along with other data on load
const saveSMSProvisionNumber = (action$, state$) => action$.pipe(
  filter(generateProvisionSuccess.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}product/data/sms/pro`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: { "phoneNumber": state$.value.tools.sms.generatedNumber },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => saveProvisionNumberSuccess(response.response),
      catchError((error) => (
        of(saveProvisionNumberFailed(error))
      )),
    ))),
);


// Repack the reset action with a piece of state
const resetActionEpic = (action$, state$) => action$.pipe(
  filter(triggerResetAction.match),
  map(() => (
    resetCompleteAction({
      resetData: state$.value.settings.resetProductInfo,
    })
  )),
);

export default [
  updateToolsState,
  saveEmailCampaignEpic,
  resetActionEpic,
  getSMSProvisionNumber,
  saveSMSProvisionNumber
];
