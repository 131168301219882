import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import SkeletonText from '../SkeletonText';

const StyledRowWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',

  '& .MuiGrid-item': {
    border: `none`,
  },
}));

const StyledCellWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(0.5),
  margin: theme.spacing(2.5),
}));

const StyledFirstCellWrapper = styled(StyledCellWrapper)(() => ({}));
const StyledSecondCellWrapper = styled(StyledCellWrapper)(() => ({}));
const StyledThirdCellWrapper = styled(StyledCellWrapper)(() => ({}));
const StyledForthCellWrapper = styled(StyledCellWrapper)(() => ({
  justifyContent: 'flex-end',
}));

export default function SkeletonDesktopRow() {
  return (
    <StyledRowWrapper container>
      <StyledFirstCellWrapper container item md={4} lg={4}>
        <SkeletonText animation="wave" variant="string" width={18} height={24} />
        <SkeletonText animation="wave" variant="string" width={156} height={24} />
      </StyledFirstCellWrapper>
      <StyledSecondCellWrapper container item md={4} lg={4}>
        <SkeletonText animation="wave" variant="string" width={46} height={24} />
        <SkeletonText animation="wave" variant="string" width={128} height={24} />
      </StyledSecondCellWrapper>
      <StyledThirdCellWrapper container item md={3} lg={3}>
        <SkeletonText animation="wave" variant="string" width={394} height={24} />
      </StyledThirdCellWrapper>
      <StyledForthCellWrapper container item md={1} lg={1}>
        <SkeletonText animation="wave" variant="string" width={81} height={24} />
      </StyledForthCellWrapper>
    </StyledRowWrapper>
  );
}
