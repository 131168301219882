import { IQLabelTooltip, IQTheme } from '@gannettdigital/shared-react-components';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  CardContent,
  CardHeader,
} from '@mui/material';
import { CustomCard, DividingLine } from './settings-shared-components';

const CustomHeader = styled(CardHeader)(({ theme }) => ({
  letterSpacing: '0.18px',
  padding: 0,
  '& .MuiCardHeader-title': {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '25px',
    letterSpacing: '0.18px',
    // Can be a p for tooltip or label without
    '& p': {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '25px',
      letterSpacing: '0.18px',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'none',
      },
    },
    '& label': {
      fontSize: 20,
      fontFamily: 'Unify Sans Semibold',
      lineHeight: '25px',
      letterSpacing: '0.18px',
      color: theme.palette.common.black,
      [theme.breakpoints.down('md')]: {
        maxHeight: 'none',
      },
    },
  },
  '& .MuiCardHeader-subheader': {
    color: theme.palette.common.black,
    fontSize: 16,
    lineHeight: '24px',
    paddingTop: theme.spacing(1),
  },
}));

const CustomContent = styled(CardContent)(({ theme }) => ({
  paddingLeft: 0,
  paddingTop: 0,
  padding: 0,
  '&.MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
  },
}));

export default function SectionCard({
  id,
  title,
  description = null,
  tooltipText = '',
  children,
  showDividingLine = true,
  shouldHaveLabelTooltip = true && tooltipText !== '',
  hasError = false,
  bannerSection = null,
}) {
  return (
    <CustomCard id={id}>
      {bannerSection && bannerSection}
      <CustomHeader
        title={
          shouldHaveLabelTooltip
            ? (
              <IQLabelTooltip
                required={false}
                hasError={hasError}
                theme={IQTheme}
                tooltipText={tooltipText}
                labelText={title}
              />
            )
            : title
          }
        subheader={description}
        titleTypographyProps={{
          id: `${id}-title`,
        }}
        subheaderTypographyProps={{
          id: `${id}-description`,
        }}
      />
      {showDividingLine ? <DividingLine allowMobile /> : null }
      <CustomContent>
        {children}
      </CustomContent>
    </CustomCard>
  );
}

SectionCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.node.isRequired,
  ]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipText: PropTypes.string,
  children: PropTypes.node,
  showDividingLine: PropTypes.bool,
  shouldHaveLabelTooltip: PropTypes.bool,
  bannerSection: PropTypes.node,
  hasError: PropTypes.bool,
};
