import { Grid, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ValidationProvider,
  IQFormTextArea,
  IQTheme,
} from '@gannettdigital/shared-react-components';
import { SLOGAN_KEY } from '../data/general-settings-slice';
import { CharacterCount } from '../../../../shared/settings-shared-components';
import {
  ADVANCED_SETTINGS_SECTION,
  updateAdvancedSettingsField,
} from '../data/advanced-settings-slice';
import SectionCard from '../../../../shared/SectionCard';
import SchedulerStateChangedHook from '../../../../shared/stateChangedHook/SchedulerStateChangedHook';
import parseNewLine from '../../../../shared/parse-new-line-utils';

const FORM_KEYS = [SLOGAN_KEY];
const FORM_NAME = 'sloganForm';

export default function SloganCard() {
  const slogan = useSelector((state) => state[ADVANCED_SETTINGS_SECTION][ADVANCED_SETTINGS_SECTION][SLOGAN_KEY]);
  const parsedSlogan = slogan ? parseNewLine(slogan) : slogan

  const theme = useTheme();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    [SLOGAN_KEY]: yup.string().max(200, 'Slogan cannot exceed 200 characters'),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [SLOGAN_KEY]: parsedSlogan || '',
    },
  });

  const handleFieldChange = (fieldKey, newValue) => {
    dispatch(updateAdvancedSettingsField({
      fieldName: fieldKey,
      fieldValue: newValue,
    }));
  };

  const {
    setValue,
    watch,
  } = methods;

  const sloganWatch = watch(SLOGAN_KEY);
  const sloganCharCount = sloganWatch ? sloganWatch.length : 0;

  return (
    <SectionCard
      id="scheduling-slogan-card"
      title="Slogan"
      tooltipText="This is visible on your Scheduling link. Include your business slogan, tagline, or a short description."
      showDividingLine={false}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form>
            <Grid container marginTop={theme.spacing(3)}>
              <Grid item xs={12}>
                <IQFormTextArea
                  id="slogan-input"
                  name={SLOGAN_KEY}
                  maxLength={150}
                  fullWidth
                  theme={IQTheme}
                  rowCount={3}
                  onBlur={(event) => handleFieldChange(SLOGAN_KEY, event.target.value)}
                />
                <CharacterCount>
                  {sloganCharCount}
                  /150
                </CharacterCount>
              </Grid>
            </Grid>
          </form>
          <SchedulerStateChangedHook
            formName={FORM_NAME}
            formKeys={FORM_KEYS}
          />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
}