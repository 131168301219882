import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { Typography } from '@mui/material';
import { authRequestFailure, saveOutlookAuth } from './data/outlook-calendar-slice';
import { OUTLOOK_AUTH_FINISHED_EVENT } from '../../../shared/app-constants';
import { PrimaryButton } from '../../../shared/settings-shared-components';
import { OUTLOOK_ACCESS_ERROR_TYPE } from './bookings-constants';

const DEFAULT_ERROR = 'Failed to authenticate. Please close the window and try again!';
const AUTH_ERROR = 'Not all permissions were accepted. Please try again & accept permissions.';

export default function OutlookAuthRedirect() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isAuthed, authFailure, authError } = useSelector((state) => state.outlookCalendar);
  const email = useSelector((state) => state.outlookCalendar.calendarIntegrationData.email);
  const errorMessage = authError && authError === OUTLOOK_ACCESS_ERROR_TYPE
    ? AUTH_ERROR : DEFAULT_ERROR;

  const handleCloseClick = () => {
    window.close();
  };

  useEffect(() => {
    const foundQuery = queryString.extract(window.location.href);
    const qpObj = queryString.parse(foundQuery);
    if (qpObj && qpObj.code) {
      dispatch(saveOutlookAuth({
        code: qpObj.code,
      }));
    } else if (qpObj && qpObj.error) {
      dispatch(authRequestFailure({
        errorCode: qpObj.error,
      }));
    }
  }, []);

  useEffect(() => {
    if (isAuthed && email) {
      window.opener.postMessage({ key: OUTLOOK_AUTH_FINISHED_EVENT, value: email });
    }
  }, [isAuthed, email]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: authFailure ? 'top' : 'center',
      height: '750px',
    }}
    >
      {!authFailure && (
        <>
          <Typography variant="h4" sx={{ paddingBottom: theme.spacing(6) }}>Finalizing authentication...</Typography>
          <IQLoadingSpinner size={100} />
        </>
      )}
      {authFailure && (
        <div style={{ maxWidth: '50%', paddingTop: '48px', textAlign: 'center' }}>
          <Typography variant="h4">{errorMessage}</Typography>
          <PrimaryButton onClick={handleCloseClick} sx={{ marginTop: theme.spacing(3) }}>
            Close Window
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
