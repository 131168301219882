import styled from '@emotion/styled';
import { Card, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as SmallLeaf } from '../assets/small-leaf.svg';
import { ReactComponent as MediumLeaf } from '../assets/medium-leaf.svg';
import { ReactComponent as LargeLeaf } from '../assets/large-leaf.svg';
import { determineScoreColorBar } from '../grader-utils';

const BarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 812,
  maxHeight: 75,

  '& .scoreCard': {
    position: 'relative',
    margin: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    boxShadow: 'none',
    inset: 0,
    border: `8px solid ${theme.palette.common.white}`,
    borderRadius: 120,
    zIndex: 1,
    width: '100%',
  },
  '& .scoreBar': {
    width: '100%',
    flexWrap: 'nowrap',
    gap: '4px',
    zIndex: '-1',
    [theme.breakpoints.down('md')]: {
      maxHeight: 46,
    },
  },

  [theme.breakpoints.down('md')]: {
    width: '85%',
  },
}));

const GridSection = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'scoreColor',
})(({ scoreColor, theme }) => ({
  backgroundColor: scoreColor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  minHeight: 60,
  '& svg': {
    transform: 'scale(0.66)',
  },

  '&.unfilled': {
    color: '#AEAEAD',
    border: '1px solid #AEAEAD',
    '&.end': {
      borderTopRightRadius: 120,
      borderBottomRightRadius: 120,
    },
    '&.start': {
      borderTopLeftRadius: 120,
      borderBottomLeftRadius: 120,
    },
  },
  [theme.breakpoints.down('md')]: {
    minHeight: 46,
  },
}));

/**
 * Score bar component. Displays color coded 3 part score bar
 */
export default function ScoreBar({ score }) {
  const scoreColors = determineScoreColorBar(score);
  return (
    <BarWrapper>
      <Card className="scoreCard">
        <Grid container className="scoreBar">
          <GridSection item className={`section ${score === null && 'unfilled'} start`} xs={4} scoreColor={scoreColors[0]}>
            <SmallLeaf />
          </GridSection>
          <GridSection item className={`section ${score <= 33 && 'unfilled'}`} xs={4} scoreColor={scoreColors[1]}>
            <MediumLeaf />
          </GridSection>
          <GridSection item className={`section ${score <= 66 && 'unfilled'} end`} xs={4} scoreColor={scoreColors[2]}>
            <LargeLeaf />
          </GridSection>
        </Grid>
      </Card>
    </BarWrapper>
  );
}

ScoreBar.propTypes = {
  score: PropTypes.number,
};

ScoreBar.defaultProps = {
  score: null,
};
