import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Modal, Typography } from '@mui/material';
import { PrimaryButton } from '../../settings-shared-components';
import cancellationModalDesktopBG from './assets/cancellation-modal-desktop-bg-icon.svg';
import { ReactComponent as GiftBoxIcon } from './assets/gift-box-icon.svg';
import { ReactComponent as CloseIcon } from '../../../images/close-x-icon.svg';
import { triggerCancelSubscriptionAction, triggerAcceptSalvageOfferAction } from '../../../pages/products/account/data/subscriptions-slice';
import { DISCOUNT_CARD_TYPE } from './cancel-subscription-constants';

const StyledModalWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  border: `1px solid ${theme.palette.common.black}`,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '1070px',
  height: 'auto',
  maxHeight: '95%',
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    position: 'relative',
    flexDirection: 'column',
    maxWidth: '95%',
    maxHeight: '95%',
    top: '21px',
    transform: 'translate(-50%)',
  },
}));

const StyledSidePanelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'black',
  backgroundImage: `url(${cancellationModalDesktopBG})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100%',
  backgroundSize: 'cover',
  height: 'auto',
  width: '100%',
  maxWidth: '370px',
  padding: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${cancellationModalDesktopBG})`,
    padding: theme.spacing(4, 0),
    maxWidth: 'unset',
  },
}));

const StyledSidePanelInnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  color: theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
    maxWidth: 'unset',
  },
}));

const StyledSidePanelTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '40px',
  color: theme.palette.common.white,
  width: '100%',
  maxWidth: '320px',
  textAlign: 'center',
}));

const StyledSpecialOfferLabel = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '15px',
  color: theme.palette.success.main,
  letterSpacing: '1px',
  textTransform: 'uppercase',
}));

const StyledMainContentWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  padding: theme.spacing(5, 10),
  height: 'auto',
  width: '100%',
  minWidth: '550px',
  color: theme.palette.common.black,
  overflowY: 'auto',

  [theme.breakpoints.down('md')]: {
    minWidth: 'unset',
    padding: theme.spacing(5),
    width: 'auto',
    position: 'unset',
  },
}));

const StyledMainContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '550px',
  gap: theme.spacing(3),
}));

const StyledOfferText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}));

const StyledButtonsWrapper = styled('footer')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 0, 0, 0),
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const StyledAcceptAndCancelBtnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0, 0, 3, 0),
  },
}));

const StyledCloseCancellationModalButton = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    fill: theme.palette.common.white,
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function CancellationSalvageOfferModal({ isOpen, toggleModal }) {
  const dispatch = useDispatch();

  const onAcceptOfferClick = () => {
    dispatch(triggerAcceptSalvageOfferAction({ cardType: DISCOUNT_CARD_TYPE }));
  };
  const onCancelSubscriptionClick = () => {
    dispatch(triggerCancelSubscriptionAction({ cardType: DISCOUNT_CARD_TYPE }));
  };

  return (
    <Modal open={isOpen} onClose={toggleModal} disableAutoFocus>
      <StyledModalWrapper>
        <StyledSidePanelWrapper>
          <StyledSidePanelInnerWrapper>
            <StyledSidePanelTitle>
              How about
              <span>
                &nbsp;
                50% off for 3 months
              </span>
              , to stick around?
            </StyledSidePanelTitle>
            <GiftBoxIcon />
            <StyledSpecialOfferLabel>
              Special Offer
            </StyledSpecialOfferLabel>
          </StyledSidePanelInnerWrapper>
        </StyledSidePanelWrapper>

        <StyledMainContentWrapper>
          <StyledCloseCancellationModalButton onClick={toggleModal} />
          <StyledMainContent>
            <StyledOfferText>
              Don&apos;t cancel just yet. Stay with us and get
              <span>
                &nbsp;
                50% off for 3 months
              </span>
              .
            </StyledOfferText>
            <StyledButtonsWrapper>
              <StyledAcceptAndCancelBtnWrapper>
                <PrimaryButton onClick={onAcceptOfferClick}>Accept Offer</PrimaryButton>
                <PrimaryButton onClick={onCancelSubscriptionClick} variant="outlined">Cancel my Subscription</PrimaryButton>
              </StyledAcceptAndCancelBtnWrapper>
            </StyledButtonsWrapper>

          </StyledMainContent>
        </StyledMainContentWrapper>

      </StyledModalWrapper>
    </Modal>
  );
}

CancellationSalvageOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
