// Engagement Widget Keys
export const ENGAGEMENT_WIDGETS_KEY = 'engagementWidget';
export const WIDGET_FEATURES_KEY = 'widgetFeatures';
export const COLOR_FORM = 'colorForm';
export const WIDGET_FEATURES_FORM = 'widgetFeatures';
export const WIDGET_LOCATION_FORM = 'widgetLocation';

// Links
export const SETTINGS_TOOL_SCHEDULE_LINK = '/settings/tools/scheduling';
export const SETTINGS_TOOL_CHATBOT_LINK = '/settings/tools/chatbot';
export const SETTINGS_TOOL_CLICKTOCALL_LINK = '/settings/tools/click-to-call';
export const LEARN_MORE_LINK = "https://localiq.com/products/lead-capture-toolkit"

export const CHAT_VALUE = 'chat';
export const SCHEDULER_VALUE = 'scheduler';
export const CALLTRACKER_VALUE = 'calltracker';
export const MESSAGE_VALUE = 'message';
export const SMS_VALUE = 'sms';

// SMS Notifications Keys
export const SMS = "SMS";
export const EMAIL = 'Email';
export const NEW_APPT_SMS = 'newAppointmentConfirmationSMS';
export const APPT_REMINDER_SMS = 'appointmentReminderSMS';
export const APPT_RESCHEDULE_CANCEL_SMS = 'rescheduleOrCancelSMS';
export const SMS_FORM = 'smsForm';

// SMS Notifications Keys
export const SMS_MESSAGES_DASH = 'newSMSNotificationOnNewMessage';
export const SMS_LEAD_NOTIFICATIONS_SMS = 'newSMSLeadNotificationsSMS';
export const SMS_LEAD_NOTIFICATIONS_EMAIL = 'newSMSLeadNotificationsEmail';
export const SMS_UNANSWERED_NOTIFICATIONS = 'smsUnansweredMessageReminder';
export const SMS_DASH_FORM = 'smsDashForm';

// Notification Settings Keys
export const NOTIFICATION_EMAILS_KEY = 'notificationEmails';
export const SMS_PHONE_NUMBER_KEY = 'phoneNumberSMS';
export const NOTIFICATIONS_FORM = 'notificationsForm';

export const SCHEDULER_WIDGET = "SchedulerPro";
export const CHATBOT_WIDGET = "DIYChatbotPro";
export const CALL_TRACKER_WIDGET = "CallTracking";
export const MESSAGE_WIDGET = "MessagePro";
export const SMS_WIDGET = "SMSPro";

export const AUTO_REPLY_KEY = 'customWelcomeMessage';
export const AUTO_REPLY_FORM = 'autoReplyTextForm';

export const HELP_TEXT_NOTIFICATIONS = "If you have questions related to SMS, please contact your LocaliQ customer representative or email us at";
export const WIDGET_LOCATION = "widgetLocation";
export const WIDGET_LOCATION_LEFT = "BOTTOM_LEFT";
export const WIDGET_LOCATION_RIGHT = "BOTTOM_RIGHT";
export const WIDGET_LOCATION_RIGHT_TEXT = "Bottom Right";
export const WIDGET_LOCATION_LEFT_TEXT = "Bottom Left";
