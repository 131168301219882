import {
  CALL_TRACKER_SLUG,
  CHATBOT_SLUG,
  PREMIUM_LISTINGS_SLUG,
  SCHEDULER_SLUG,
  PRO_TIER,
  LITE_TIER,
  PRO_REVIEW_TIER,
} from '../../../shared/app-constants';
import { ReactComponent as PremiumListingsIcon } from './assets/premium-listings-sub-icon.svg';
import { ReactComponent as CallTrackerIcon } from './assets/call-recorder-sub-icon.svg';
import { ReactComponent as ChatbotIcon } from './assets/chatbot-sub-icon.svg';
import { ReactComponent as SchedulerIcon } from './assets/scheduler-sub-icon.svg';
import { ReactComponent as SubBundleIcon } from './assets/sub-lead-capture-toolkit-bundle.svg';

import { ReactComponent as VisaCardIcon } from './assets/visa-card-icon.svg';
import { ReactComponent as MasterCardCardIcon } from './assets/mastercard-card-icon.svg';
import { ReactComponent as AmexCardIcon } from './assets/amex-card-icon.svg';
import { ReactComponent as DinersClubCardIcon } from './assets/diners-club-card-icon.svg';
import { ReactComponent as DiscoverCardIcon } from './assets/discover-card-icon.svg';
import { ReactComponent as JcbCardIcon } from './assets/jcb-card-icon.svg';
import { ReactComponent as UnionPayCardIcon } from './assets/union-pay-card-icon.svg';

import { ReactComponent as PremiumListingsCardIcon } from './assets/premium-listings-card-icon.svg';
import { ReactComponent as CallTrackerCardIcon } from './assets/call-tracker-card-icon.svg';
import { ReactComponent as ChatbotCardIcon } from './assets/chatbot-card-icon.svg';
import { ReactComponent as SchedulerCardIcon } from './assets/scheduler-card-icon.svg';
import { ReactComponent as BundleCardIcon } from './assets/bundle-card-icon.svg';

import AnyWebsite from '../../../images/any-website.svg'
import DrupalIcon from '../../../images/drupal-icon.svg'
import WordpressIcon from '../../../images/wordpress-icon.svg'
import JoomlaIcon from '../../../images/joomla-icon.svg'

// Generic Constants
export const MY_ACCOUNT_HEADER_TEXT = 'Account Management';
export const ORIGIN_LINK_KEY = 'originLink';
export const IS_INTERNAL_LINK_KEY = 'isInternal';
export const STRIPE_KEY = "Stripe" || "stripe"

// Subscription Section Constants
export const MONTHLY_INTERVAL = 'month';
export const YEARLY_INTERVAL = 'year';
export const BUNDLE_ICON_KEY = 'bundle';
export const ACTIVE_SUBSCRIPTION_STATUS = 'active';
export const CANCELED_SUBSCRIPTION_STATUS = 'canceled';
export const EXPIRED_SUBSCRIPTION_STATUS = 'expired';
export const INCOMPLETE_SUBSCRIPTION_STATUS = 'incomplete';
export const ACTIVE_SUBSCRIPTION_COLOR = '#008059';
export const CANCELED_SUBSCRIPTION_COLOR = '#901F27';
export const NEXT_PAYMENT_TEXT = 'Next Payment';
export const CANCELS_ON_TEXT = 'Subscription ends';

export const TRIAL_PURCHASED_STATUS = 'PURCHASED';

export const LOAD_SUBSCRIPTIONS_FAILED = '[ERROR] Load Subscriptions';
export const LOAD_SUBSCRIPTIONS_FAILED_TEXT = 'Unable to load your subscriptions at this time. Please try again later.';
export const CHANGE_PAYMENT_SUCCESS = '[SUCCESS] Changed Payment';
export const CHANGE_PAYMENT_SUCCESS_TEXT = 'Your payment method has been updated.';
export const CANCEL_SUBSCRIPTION_SUCCESS = '[SUCCESS] Cancel Subscription';
export const ACCEPT_SALVAGE_OFFER_SUCCESS = '[SUCCESS] Accept Salvage Offer';
export const ACCEPT_SALVAGE_OFFER_SUCCESS_TEXT = 'Congratulations! Your 50% Off for 3 Months Promotion is Applied!';
export const CANCEL_CURRENT_DATE_REPLACEMENT_TEXT = '[current date]';
export const CANCEL_END_DATE_REPLACEMENT_TEXT = '[end of billing date]';
export const CANCELED_SUB_TYPE_STRING = '[cancelled sub type]';
export const CANCEL_SUBSCRIPTION_SUCCESS_TEXT = `Your subscription has been cancelled on ${CANCEL_CURRENT_DATE_REPLACEMENT_TEXT}. You can continue to access your ${CANCELED_SUB_TYPE_STRING} until ${CANCEL_END_DATE_REPLACEMENT_TEXT}.`;
export const CANCEL_SUBSCRIPTION_FAILED = '[ERROR] Cancel Subscription';
export const CANCEL_SUBSCRIPTION_FAILED_TEXT = 'An error has occurred, please try again later.';
export const CHANGE_PAYMENT_SUCCESS_DISMISS_MILLISECONDS = 5000;
export const CHANGE_PAYMENT_FAILURE_TEXT = 'We ran into an error. Please try again.';

// Card Brands
export const AMERICAN_EXPRESS_STRIPE_BRAND = 'amex';
export const VISA_STRIPE_BRAND = 'visa';
export const MASTERCARD_STRIPE_BRAND = 'mastercard';
export const DINERS_STRIPE_BRAND = 'diners';
export const DISCOVER_STRIPE_BRAND = 'discover';
export const JCB_STRIPE_BRAND = 'jcb';
export const UNION_PAY_STRIPE_BRAND = 'unionpay';

// Stripe Constants
export const STRIPE_NO_CUSTOMER_ERROR_CODE = 'exception.stripe.customer.not-found';

// Form Constants
export const BUSINESS_DETAILS_FORM = 'businessDetailsForm';
export const BUSINESS_LOGO_FORM = 'businessLogoForm';

// Config Constants
export const SUBSCRIPTION_DISPLAY_TITLE_CONFIG = {
  [CHATBOT_SLUG]: {[PRO_TIER]: `Chatbot Pro`},
  [SCHEDULER_SLUG]: {[PRO_TIER]: `Scheduling Pro`},
  [CALL_TRACKER_SLUG]: {[LITE_TIER]: 'Call Recorder'},
  [PREMIUM_LISTINGS_SLUG]: {
    [PRO_TIER]: 'Premium Listings Pro',
    [PRO_REVIEW_TIER]: 'Premium Listings Pro + Review Management',
  },
};

// Map Constants
export const SUBSCRIPTION_TO_ICON_MAP = {
  [SCHEDULER_SLUG]: <SchedulerIcon />,
  [CHATBOT_SLUG]: <ChatbotIcon />,
  [CALL_TRACKER_SLUG]: <CallTrackerIcon />,
  [PREMIUM_LISTINGS_SLUG]: <PremiumListingsIcon />,
  [BUNDLE_ICON_KEY]: <SubBundleIcon />,
};

export const CARD_SUBSCRIPTION_TO_ICON_MAP = {
  [SCHEDULER_SLUG]: <SchedulerCardIcon />,
  [CHATBOT_SLUG]: <ChatbotCardIcon />,
  [CALL_TRACKER_SLUG]: <CallTrackerCardIcon />,
  [PREMIUM_LISTINGS_SLUG]: <PremiumListingsCardIcon />,
  [BUNDLE_ICON_KEY]: <BundleCardIcon />
};

export const INTERVAL_DISPLAY_NAME_MAP = {
  [MONTHLY_INTERVAL]: 'monthly',
  [YEARLY_INTERVAL]: 'yearly',
};

export const CARD_BRAND_TO_ICON_MAP = {
  [VISA_STRIPE_BRAND]: <VisaCardIcon />,
  [MASTERCARD_STRIPE_BRAND]: <MasterCardCardIcon />,
  [AMERICAN_EXPRESS_STRIPE_BRAND]: <AmexCardIcon />,
  [DINERS_STRIPE_BRAND]: <DinersClubCardIcon />,
  [DISCOVER_STRIPE_BRAND]: <DiscoverCardIcon />,
  [JCB_STRIPE_BRAND]: <JcbCardIcon />,
  [UNION_PAY_STRIPE_BRAND]: <UnionPayCardIcon />,
}

export const HAS_CARD_DESCRIPTION = "Credit Cards cannot be deleted if they are tied to an active subscription. To change a credit card tied to a subscription, add a new card then change your card on file in the subscription tab.";
export const HAS_NO_CARD_DESCRIPTION = "Your credit cards will live here.";

export const ADD_CC_SUCCESS_BANNER = "Your credit card has been added.";
export const ADD_CC_FAILURE_BANNER = "Your credit card could not be added.";
export const EDIT_CC_SUCCESS_BANNER = "Your credit card has been updated.";
export const EDIT_CC_FAILURE_BANNER = "Your credit card could not be updated.";
export const DELETE_CC_SUCCESS_BANNER = "Your credit card has been deleted.";
export const DELETE_CC_FAILURE_BANNER = "Your credit card could not be deleted.";

export const EDIT_CARD_SUCCESS = "Edit Card Success";
export const EDIT_CARD_FAILURE = "Edit Card Failed";
export const ADD_CARD_SUCCESS = "Add Card Success";
export const ADD_CARD_FAILURE = "Add Card Failed";
export const DELETE_CARD_SUCCESS = "Delete Card Success";
export const DELETE_CARD_FAILURE = "Delete Card Failed";

export const EXPIRED_CARD = "expired_card";
export const INSUFFICIENT_FUNDS = "insufficient_funds";
export const INCORRECT_NUMBER = "incorrect_number";
export const INCORRECT_CVC = "incorrect_cvc";
export const INVALID_ACCOUNT = "invalid_account";
export const DO_NOT_HONOR = "do_not_honor";
export const TRANSACTION_NOT_ALLOWED = "transaction_not_allowed"

export const EDIT_EXPIRED_MONTH = "Your card's expiration month is invalid."
export const EDIT_EXPIRED_YEAR = "Your card's expiration year is invalid."
export const EDIT_EXPIRED_CARD = "Please check your expiration date and try again."

export const OTHER_WEBSITE = { value: 'OTHER_WEBSITE', description: 'None/Other', icon: AnyWebsite };
export const DRUPAL = { value: 'DRUPAL', description: 'Drupal', icon: DrupalIcon };
export const JOOMLA = { value: 'JOOMLA', description: 'Joomla', icon: JoomlaIcon};
export const WORDPRESS = { value: 'WORDPRESS', description: 'Wordpress', icon: WordpressIcon };

export const DRUPAL_KEY = "DRUPAL"
export const JOOMLA_KEY = "JOOMLA"
export const WORDPRESS_KEY = "WORDPRESS"
export const ANY_SITE = "OTHER_WEBSITE"

export const WEBSITE_TYPE = [
  WORDPRESS,
  DRUPAL,
  JOOMLA,
  OTHER_WEBSITE,
];

export const WEBSITE_KEY = 'WEBSITE';

export const JOOMLA_SUPPORT_LINK = "https://extensions.joomla.org/extension/reachedge"
export const WORDPRESS_SUPPORT_LINK = "https://wordpress.org/plugins/reachedge"
export const DRUPAL_SUPPORT_LINK = "https://drupal.org/project/reachedge"