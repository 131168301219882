import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { PrimaryButton } from '../../settings-shared-components';
import CreditCardRadio from './CreditCardRadio';

const FormWrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CreditCardRadioList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

const FormActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  }
}));

const AddNewPaymentMethodLink = styled(Link)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: `underline ${theme.palette.primary.main}`,
  
  '&:hover': {
    textDecoration: 'none',
  },

  [theme.breakpoints.down('md')]: {
    alignSelf: 'flex-start',
  }
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    alignSelf: 'flex-end',
  }
}));

export const SELECTED_CARD_ID = 'selectedPaymentMethodId';

export default function ChangeCreditCardForm({ 
  currentPaymentMethodId,
  allCreditCards,
  addPaymentLinkClickHandler,
  changePaymentInfoSubmitHandler,
  toggleModal,
  isSaving,
  creditCardsNavPath,
}) {
  const schema = yup.object().shape({
    [SELECTED_CARD_ID]: yup.string().required(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [SELECTED_CARD_ID]: currentPaymentMethodId
    }
  });
  const { handleSubmit, register, watch } = methods;
  const selectedPaymentMethodId = watch(SELECTED_CARD_ID);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(changePaymentInfoSubmitHandler)}>
          <CreditCardRadioList>
            {allCreditCards.map((cc) => (
              <CreditCardRadio key={cc.paymentMethodId} creditCard={cc} register={register} selectedPaymentMethodId={selectedPaymentMethodId} />
            ))}
          </CreditCardRadioList>
          <FormActionsWrapper>
            <AddNewPaymentMethodLink to={creditCardsNavPath} onClick={addPaymentLinkClickHandler} variant='body1'>Add a New Payment Method</AddNewPaymentMethodLink>
            <ButtonWrapper>
              <PrimaryButton variant="outlined" onClick={toggleModal} >
                Cancel
              </PrimaryButton>
              <PrimaryButton variant="contained" type='submit' disabled={isSaving}>
                Save
              </PrimaryButton>
            </ButtonWrapper>
          </FormActionsWrapper>
        </FormWrapper>
      </FormProvider>
    </ValidationProvider>
  )
}

ChangeCreditCardForm.propTypes = {
  currentPaymentMethodId: PropTypes.string.isRequired,
  allCreditCards: PropTypes.array.isRequired,
  addPaymentLinkClickHandler: PropTypes.func.isRequired,
  changePaymentInfoSubmitHandler: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  creditCardsNavPath: PropTypes.string.isRequired,
};
