import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Modal, Typography } from '@mui/material';
import ChangeCreditCardForm from './ChangeCreditCardForm';
import ChangeCreditCardSkeleton from './ChangeCreditCardSkeleton';
import {
  ActionDescription,
  ModalBackground,
  StripeActionCard,
} from '../../settings-shared-components';
import { ReactComponent as CloseIcon } from '../../../images/close-x-icon.svg';
import { CREDIT_CARDS_PAGE } from '../../navigation/navigation-config';
import { setCurrentScreen } from '../../navigation/navigation-slice';
import { ERROR_TYPE, ACCOUNT_PATH } from '../../app-constants';
import { saveChangePaymentAction } from '../../../pages/products/account/data/subscriptions-slice';
import {
  CloseModal,
  CreditCardHeader,
  ModalDivider,
  TitleWrapper,
} from '../../../pages/products/account/components/account-shared-components';

const ModalWrapper = styled(Modal)(() => ({
  '.MuiPaper-root': {
    maxWidth: '825px',
    '&:focus-visible': {
      outline: 'unset',
    }
  },
}));

const HeaderWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 3, 0, 3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const Subtitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  alignSelf: 'flex-start',
}));

export default function ChangeCreditCardModal({ isOpen, toggleModal }) {
  const dispatch = useDispatch();
  const creditCardsNavPath = `${ACCOUNT_PATH}/${CREDIT_CARDS_PAGE}`;
  const isLoading = useSelector(
    (state) => state.account.subscriptions.changePaymentModal.isLoading
  );
  const allCreditCards = useSelector(
    (state) => state.account.subscriptions.changePaymentModal.cards
  );
  const currentPaymentMethodId = useSelector(
    (state) =>
      state.account.subscriptions.changePaymentModal.currentPaymentMethodId
  );
  const selectedSubscriptionId = useSelector(
    (state) =>
      state.account.subscriptions.changePaymentModal.selectedSubscriptionId
  );
  const isSaving = useSelector(
    (state) => state.account.subscriptions.changePaymentModal.isSaving
  );
  const stripeErrorMsg = useSelector(
    (state) => state.account.subscriptions.changePaymentModal.stripeErrorMsg
  );

  const addPaymentLinkClickHandler = () => {
    dispatch(setCurrentScreen({ currentScreen: CREDIT_CARDS_PAGE }));
    toggleModal();
  };

  const changePaymentInfoSubmitHandler = ({ selectedPaymentMethodId }) => {
    dispatch(
      saveChangePaymentAction({
        subscriptionId: selectedSubscriptionId,
        paymentMethodId: selectedPaymentMethodId,
      })
    );
  };

  return (
    <ModalWrapper open={isOpen} onClose={toggleModal}>
      <ModalBackground>
        {isLoading ? (
          <ChangeCreditCardSkeleton />
        ) : (
          <>
            <HeaderWrapper>
              <TitleWrapper>
                <CreditCardHeader>Change Credit Card</CreditCardHeader>
              </TitleWrapper>
              <div>
                <CloseModal onClick={toggleModal}>
                  <CloseIcon />
                </CloseModal>
              </div>
            </HeaderWrapper>
            <ModalDivider />
            {stripeErrorMsg && (
              <StripeActionCard
                id='change-payment-failed-banner'
                type={ERROR_TYPE}
              >
                <ActionDescription>{stripeErrorMsg}</ActionDescription>
              </StripeActionCard>
            )}
            <ContentWrapper>
              <Subtitle variant='body1'>
                Select a Payment Method for this Subscription
              </Subtitle>
              <ChangeCreditCardForm
                currentPaymentMethodId={currentPaymentMethodId}
                allCreditCards={allCreditCards}
                addPaymentLinkClickHandler={addPaymentLinkClickHandler}
                changePaymentInfoSubmitHandler={changePaymentInfoSubmitHandler}
                toggleModal={toggleModal}
                isSaving={isSaving}
                creditCardsNavPath={creditCardsNavPath}
              />
            </ContentWrapper>
          </>
        )}
      </ModalBackground>
    </ModalWrapper>
  );
}

ChangeCreditCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
