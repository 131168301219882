import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import SkeletonMobileRow from './SkeletonMobileRow';
import SkeletonText from '../SkeletonText';

const StyledTableContent = styled('div')(({ theme }) => ({
  border: '1px solid #000000',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'unset',
  width: '100%',
  borderRadius: '8px',
  margin: theme.spacing(3, 0, 2, 0),
  background: theme.palette.common.white,
  minHeight: '320px',
  overflow: 'hidden',
}));

export default function LoadingSkeletonRecentActivityMobile() {
  return (
    <div>
      <SkeletonText animation="wave" variant="string" width={194} height={30} />
      <StyledTableContent>
        <SkeletonMobileRow />
        <SkeletonMobileRow />
        <SkeletonMobileRow />
        <SkeletonMobileRow />
        <SkeletonMobileRow isLastRow />
      </StyledTableContent>
      <SkeletonText animation="wave" variant="string" width={98} height={30} />
    </div>
  );
}
