import { createAction, createSlice } from '@reduxjs/toolkit';
import { loadSettingsData, loadSettingsDataSuccess } from '../../../shared/app-slice';
import { convertAppointmentDate, formatTimestampToRelativeTime, getProductFromProducts, timezoneAbbrLookup } from '../../../shared/app-utils';
import { PREMIUM_LISTINGS_SLUG } from '../../../shared/app-constants';
import { ACTIVITY_EVENT_TYPE_DISPLAY_MAP, PERFORMANCE_CARD_DEFAULT_VALUES, CALL_ACTIVITY } from '../dashboard-constants';
import { getActivityInfo, getActivityInfoTitle } from '../dashboard-utils';
import { COMPLETED_STATUS } from '../../configuration/wizard/wizard-constants';

const DASHBOARD_SLICE_NAME = 'dashboard';

export const loadRecentActivitiesAction = createAction(`${DASHBOARD_SLICE_NAME}/loadRecentActivities`);
export const loadPerformanceAction = createAction(`${DASHBOARD_SLICE_NAME}/loadPerformance`);
export const loadUpcomingAppointments = createAction(`${DASHBOARD_SLICE_NAME}/loadUpcomingAppointments`);

const dashboardSlice = createSlice({
  name: DASHBOARD_SLICE_NAME,
  initialState: {
    loadedData: false,
    hasListingsSubscription: false,
    performance: PERFORMANCE_CARD_DEFAULT_VALUES,
    performanceIsLoading: false,
    leads: [],
    leadsAreLoading: false,
    upcomingAppointments: [],
    upcomingAppointmentsAreLoading: false,
    criticalActionTemplate: null,
  },
  reducers: {
    loadPerformance: (state) => {
      state.performanceIsLoading = true;
    },
    loadPerformanceSuccess: (state, action) => {
      state.performanceIsLoading = false;
      const { indicators } = action.payload;
      if (indicators.length > 0) {
        state.performance = state.performance.map((p) => {
          const foundIndicator = indicators.find((i) => i.name === p.name);

          // Backup in case the names from the API change for some reason
          if (foundIndicator) {
            return {
              name: foundIndicator.name,
              count: foundIndicator.count,
              trend: Math.round(Math.abs(foundIndicator.trend)),
              isZeroTrend: foundIndicator.trend === 0,
              isUpwardTrend: foundIndicator.trend > 0,
            }
          } else {
            return p;
          }
        })
      }
    },
    loadPerformanceFailure: (state) => {
      state.performanceIsLoading = false;
    },
    loadRecentActivities: (state, _action) => {
      state.leadsAreLoading = true;
    },
    loadRecentActivitiesSuccess: (state, action) => {
      // Default to UTC if outside US
      const timezone = timezoneAbbrLookup(action.payload.currentUserTimezone) ?? 'UTC';

      state.leads = action.payload.contact_interactions.map((activity) => ({
        id: activity.id,
        type: ACTIVITY_EVENT_TYPE_DISPLAY_MAP[activity.event_type],
        displayName: activity?.event_type === CALL_ACTIVITY
          ? activity?.contact?.phone_numbers[0]?.phone_number
          : activity?.contact?.display_name,
        displayTime: formatTimestampToRelativeTime(activity.occurred_at),
        infoTitle: getActivityInfoTitle(activity.event_type),
        info: getActivityInfo(activity, timezone),
      }));
      state.leadsAreLoading = false;
    },
    loadRecentActivitiesFailure: (state, _action) => {
      state.leadsAreLoading = false;
    },
    loadUpcomingAppointments: (state) => {
      state.upcomingAppointmentsAreLoading = true;
    },
    loadUpcomingAppointmentsSuccess: (state, action) => {
      const { content, currentUserTimezone } = action.payload;
      let timezone = timezoneAbbrLookup(currentUserTimezone) !== null ? currentUserTimezone : 'America/New_York';
      state.upcomingAppointmentsAreLoading = false;
      state.upcomingAppointments = content
        .map((appt) => convertAppointmentDate(appt, timezone, 'UTC'));
    },
    loadUpcomingAppointmentsFailure: (state) => {
      state.upcomingAppointmentsAreLoading = false;
    },
    setNewCriticalActionTemplate: (state, action) => {
      state.criticalActionTemplate = action.payload.template;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsData, (state) => {
      state.loadedSettings = false;
    });
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const { products } = action.payload;
      const premiumListingsProduct = getProductFromProducts(products, PREMIUM_LISTINGS_SLUG);
      state.hasListingsSubscription = premiumListingsProduct !== null && premiumListingsProduct.status === COMPLETED_STATUS;
      state.loadedData = true;
    });
  },
});

export const {
  loadUpcomingAppointmentsSuccess,
  loadUpcomingAppointmentsFailure,
  loadPerformance,
  loadPerformanceSuccess,
  loadPerformanceFailure,
  loadRecentActivities,
  loadRecentActivitiesSuccess,
  loadRecentActivitiesFailure,
  dismissWelcomeSuccess,
  dismissWelcomeFailure,
  dismissWelcome,
  setNewCriticalActionTemplate,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
