import styled from '@emotion/styled';
import SkeletonText from '../SkeletonText';

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexFlow: 'column',
  },
}));

export default function LoadingSkeletonFreeTrialStatusBanner() {
  return (
    <StyledWrapper>
      <SkeletonText animation="wave" variant="string" bgcolor="#FFFFFF29" width={326} height={27} />
      <SkeletonText animation="wave" variant="string" bgcolor="#FFFFFF29" width={133} height={40} />
    </StyledWrapper>
  );
}
