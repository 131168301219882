import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BasicLinearProgress, PrimaryButton } from '../../../../shared/settings-shared-components';

const FooterDiv = styled('div')(({ theme }) => ({
  width: '100%',

  '& .footer-content': {
    '& button': {
      marginTop: '16px'
    },
    padding: theme.spacing(0, 3, 2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    '& .footer-steps': {
      fontSize: 16,
      color: '#716F6F'
    },
    '& .footer-step-wrapper': {
      marginTop: '8px'
    },
    '& .hidden': {
      visibility: 'hidden',
    },
  },
}));

const GradientWrapper = styled('div')(({ progress }) => ({
  '& .MuiLinearProgress-bar': {
    background: `linear-gradient(90deg, #0046FF ${100 - (progress)}%, #F03C4B ${100 - (progress / 2)}%, #FF6437 ${200 - progress}%)`,
  },
}));

export default function Footer({
  onNext, onBack, onDone, showBack, showNext, showDone, progress, disableNext, currentIndex, totalSteps
}) {
  return (
    <FooterDiv>
      <GradientWrapper className="footer-gradient" progress={progress}>
        <BasicLinearProgress
          variant="determinate"
          value={progress}
        />
      </GradientWrapper>
      <div className="footer-content">
        <PrimaryButton className={!showBack && 'hidden'} variant="outlined" onClick={onBack} type="button">Back</PrimaryButton>
        <div className="footer-step-wrapper">
          <Typography className="footer-steps"> Step {currentIndex} of {totalSteps} </Typography>
        </div>
        {!showDone ? <PrimaryButton disabled={disableNext} className={!showNext && 'hidden'} variant="contained" onClick={onNext} type="button">Next</PrimaryButton>
          : <PrimaryButton onClick={onDone} type="button">Done</PrimaryButton>}
      </div>
    </FooterDiv>
  );
}

Footer.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  showBack: PropTypes.bool,
  showNext: PropTypes.bool,
  progress: PropTypes.number,
  disableNext: PropTypes.bool,
};
