import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ColorLinearProgress } from '../../../../../shared/settings-shared-components';

const ProcessingWrapper = styled('div')(({ theme }) => ({
  '& .loading-description': {
    fontSize: 18,
    lineHeight: '1.5',
    textAlign: 'center',
    letterSpacing: '0.5px',
    marginBottom: theme.spacing(2),
  },
  '& .loading-title': {
    fontFamily: 'Unify Sans SemiBold',
  },
  '& .progress-wrapper': {
    margin: theme.spacing(2, 0),
    '& .MuiLinearProgress-root.MuiLinearProgress-colorPrimary': {
      maxWidth: 305,
      margin: '0 auto',
    },
  },
}));

/**
 * Component for processing bar with text.
 */
export default function ProcessingBar({ processingText }) {
  return (
    <ProcessingWrapper>
      <Typography className="loading-description loading-title">
        {processingText}
      </Typography>
      <div className="progress-wrapper">
        <ColorLinearProgress color="primary" />
      </div>
      <Typography className="loading-description">
        This could take up to 2 minutes.
      </Typography>
    </ProcessingWrapper>
  );
}

ProcessingBar.propTypes = {
  processingText: PropTypes.string.isRequired,
};
