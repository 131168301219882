import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccountSettingsDialog from './AccountSettingsDialog';
import { setCurrentScreen } from '../../../shared/navigation/navigation-slice';
import { ACCOUNT_NAV_STEPS, BUSINESS_DETAILS_PAGE, getNavStepsByUrl } from '../../../shared/navigation/navigation-config';
import { useMountEffect } from '../../../shared/mountEffectHook/useMountEffect';
import { ACCOUNT_PATH, PRO_TIER } from '../../../shared/app-constants';
import { loadSectionData } from './account-utils';
import withOriginLink from '../../../shared/hocs/withOriginLink';

function AccountLandingPage({ onHandleOriginLinkClose }) {
  const dispatch = useDispatch();
  const userEmail = useSelector(state => state.appSettings.user?.email);
  const { currentScreen =  BUSINESS_DETAILS_PAGE } = useParams();
  const isBusinessDetailsLoading = useSelector((state) => state.account.businessDetails.isLoading);
  const areSubscriptionsLoading = useSelector((state) => state.account.subscriptions.isLoading);
  const areInvoicesLoading = useSelector((state) => state.account.invoices.isLoading);
  const areCreditCardsLoading = useSelector((state) => state.account.creditCards.isLoading);
  const isLoading = areSubscriptionsLoading || areInvoicesLoading || isBusinessDetailsLoading || areCreditCardsLoading;

  // check to see if user has any paid subscriptions
  const hasPaidBookings = useSelector((state) => state.bookings.tier === PRO_TIER);
  const hasPaidCallRecorder = useSelector((state) => state.callSettings.tier === PRO_TIER);
  const hasPaidChatbot = useSelector((state) => state.chatbot.tier === PRO_TIER);
  const hasPaidPremListings = useSelector((state) => state.appSettings.activatedProducts.length > 0);
  const hasPaidProduct = hasPaidBookings || hasPaidCallRecorder || hasPaidChatbot || hasPaidPremListings;

  const handleOnClose = () => {
    onHandleOriginLinkClose();
  };

  useMountEffect(() => {
    const pathName = window.location.pathname;
    const sectionPath = pathName.replace(`${ACCOUNT_PATH}/`, '');
    const foundStep = getNavStepsByUrl(ACCOUNT_NAV_STEPS)[sectionPath];

    if (foundStep) {
      dispatch(
        setCurrentScreen({ currentScreen: foundStep.key })
      );
    } else {
      dispatch(
        setCurrentScreen({ currentScreen: BUSINESS_DETAILS_PAGE })
      );
    }
  });

  useEffect(() => {
    if (userEmail) {
      loadSectionData(currentScreen, dispatch, hasPaidProduct);
    }
  }, [userEmail, currentScreen]);

  return (
    <div>
      <AccountSettingsDialog
        isLoading={isLoading}
        currentScreen={currentScreen}
        onClose={handleOnClose}
      />
    </div>
  );
}

export default withOriginLink(AccountLandingPage);

AccountLandingPage.propTypes = {
  onHandleOriginLinkClose: PropTypes.func.isRequired,
};

