import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SectionTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '1rem',
  paddingBottom: theme.spacing(2),
  '> h4': {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px'
  },

  [theme.breakpoints.down('md')]: {
    '> h4': {
      fontSize: '20px',
      lineHeight: '25px',
    }
  }
}));

export default function SectionTitle({ title, actionButton, showButton }) {
  return (
    <SectionTitleWrapper>
      <Typography variant='h4'>
          {title}
      </Typography>
      {(actionButton && showButton) && actionButton}
    </SectionTitleWrapper>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
