import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import ForwardingNumberViewComponent from './ForwardingNumberViewComponent';
import ForwardingNumberEditComponent from './ForwardingNumberEditComponent';
import {
  CALL_RECORDING_ACTIVE_KEY,
  CURRENT_FORWARDING_NUMBER,
  GENERATE_NUMBERS_FAILED,
  NEW_BASE_NUMBER_KEY,
  SELECTABLE_FORWARDING_NUMBERS,
  SELECTED_FORWARDING_NUMBER_KEY,
  SELECTED_FORWARDING_TYPE_KEY,
  SELECTED_FORWARDING_UUID_KEY,
} from '../../call-settings-constants';
import { generateForwardingNumbers, updateGeneralSettingsField } from '../../data/general-settings-slice';
import CallVerificationComponent from './CallVerificationComponent';
import ActionCard from '../../../../../shared/ActionCard';
import { ERROR_TYPE } from '../../../../../shared/app-constants';
import { StyledDivider } from '../../../../../shared/settings-shared-components';
import { COMPLETED_STATUS } from '../../../../configuration/wizard/wizard-constants';

const VIEW_DISPLAY = 'view';
const EDIT_DISPLAY = 'edit';

const InlineBannerWrapper = styled('div')(({ theme }) => ({
  flex: '1 0',
  marginTop: theme.spacing(3),
  '& > .MuiPaper-root': {
    marginBottom: 0,
    '& > div': {
      minHeight: 91,
      '& div:last-of-type': {
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          alignItems: 'flex-start',
        },
      },
    },
  },
}));

const MainSection = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& .MuiDivider-root': {
      margin: theme.spacing(3, 0),
    },
  },
}));

export default function CallRecordingOptionsSection() {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [currentDisplay, setCurrentDisplay] = useState(VIEW_DISPLAY);
  const isGeneratingNumbers = useSelector((state) => state.callSettings.generalSettings.isGeneratingNumbers);
  const {
    [NEW_BASE_NUMBER_KEY]: editingBaseNumber,
    [GENERATE_NUMBERS_FAILED]: generateFailed,
    [CURRENT_FORWARDING_NUMBER]: currentForwardingNumber,
    [SELECTABLE_FORWARDING_NUMBERS]: availableNumbers,
    [CALL_RECORDING_ACTIVE_KEY]: callRecorderEnabled,
  } = useSelector((state) => state.callSettings.generalSettings);
  const showChangesSaved = useSelector((state) => state.callSettings.showChangesSaved);
  const isWizardComplete = useSelector((state) => state.configuration.status === COMPLETED_STATUS);
  const trackingNumber = useSelector((state) => state.settings.trackingNumber);

  const hasAvailableNums = Array.isArray(availableNumbers) && availableNumbers.length > 0;
  const showVerify = ((!isGeneratingNumbers && hasAvailableNums) || currentForwardingNumber || trackingNumber);

  const showSingleForwardingState = (currentForwardingNumber || isGeneratingNumbers || hasAvailableNums || (callRecorderEnabled && isWizardComplete));
  const handleEdit = useCallback(() => {
    dispatch(generateForwardingNumbers());
    setCurrentDisplay(EDIT_DISPLAY);
  });

  const handleForwardingChange = useCallback((newValue) => {
    dispatch(updateGeneralSettingsField({
      fieldName: SELECTED_FORWARDING_UUID_KEY,
      fieldValue: newValue.trackingUuId,
    }));
    dispatch(updateGeneralSettingsField({
      fieldName: SELECTED_FORWARDING_NUMBER_KEY,
      fieldValue: newValue.trackingNumber,
    }));
    dispatch(updateGeneralSettingsField({
      fieldName: SELECTED_FORWARDING_TYPE_KEY,
      fieldValue: newValue.type,
    }));

    setIsSaving(true);
  });

  useEffect(() => {
    // TODO: is this right?
    // A way to go back to view state after save, may need to be more complex.
    if (showChangesSaved && isSaving && currentDisplay === EDIT_DISPLAY) {
      setCurrentDisplay(VIEW_DISPLAY);
      setIsSaving(false);
    }
  }, [showChangesSaved, currentDisplay, isSaving]);

  useEffect(() => {
    if (editingBaseNumber === null) {
      setCurrentDisplay(VIEW_DISPLAY);
    }
  }, [editingBaseNumber]);

  useEffect(() => {
    if (isGeneratingNumbers) {
      setCurrentDisplay(EDIT_DISPLAY);
    }
  }, [isGeneratingNumbers]);

  useEffect(() => {
    if (generateFailed) {
      setCurrentDisplay(VIEW_DISPLAY);
    }
  }, [generateFailed]);

  return (
    <>
      {showSingleForwardingState && (
        <MainSection>
          <StyledDivider />
          {currentDisplay === VIEW_DISPLAY && (
            <ForwardingNumberViewComponent onEdit={handleEdit} />
          )}
          {currentDisplay === EDIT_DISPLAY && (
            <ForwardingNumberEditComponent
              onChange={handleForwardingChange}
            />
          )}
        </MainSection>
      )}
      {generateFailed && (
        <InlineBannerWrapper>
          <ActionCard
            id="forwarding-generation-failed"
            type={ERROR_TYPE}
          >
            <Typography sx={{ fontFamily: 'Unify Sans SemiBold', width: '100%' }}>
              Unable to Load Forwarding Numbers
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              No new numbers are available at this time.  Please try again later.
            </Typography>
          </ActionCard>
        </InlineBannerWrapper>
      )}
      {showVerify && <CallVerificationComponent />}
    </>
  );
}
