import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import NoUpcomingAppointments from './NoUpcomingAppointments';
import SingleUpcomingAppointment from './SingleUpcomingAppointment';
import { timezoneAbbrLookup } from '../../../../shared/app-utils';

const StyledWrapper = styled(Grid)(() => ({
  height: '100%',
}));

const StyledCardsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  '&.upcoming-appointments-wrap-items': {
    flexWrap: 'wrap',
  },
  '&.upcoming-appointments-no-wrap-items': {
    flexWrap: 'nowrap',
  },
}));

export default function Activated({ timezone, appointments, onAddAnAppointmentClick, hasUpcomingAppointments, schedulingIsActive }) {
  const appointmentCardsCount = appointments.length;
  const hasAppointments = appointments && appointmentCardsCount > 0;

  return (
    <StyledWrapper>
      {!hasUpcomingAppointments
        ? <NoUpcomingAppointments onAddAnAppointmentClick={onAddAnAppointmentClick} schedulingIsActive={schedulingIsActive} />
        : (
          <StyledCardsWrapper className={appointmentCardsCount > 3 ? 'upcoming-appointments-wrap-items' : 'get-the-most-no-wrap-items'}>
            {hasAppointments && appointments.map((appt) => (
               <SingleUpcomingAppointment
                 key={appt.id}
                 name={appt.name}
                 date={appt.appointmentStartDate}
                 startTime={appt.startTime}
                 endTime={appt.endTime}
                 appointmentName={appt.appointmentType.name}
                 timezone={timezoneAbbrLookup(timezone) || 'EST'}
                 phoneNumber={appt.phoneNumber}
                 appointmentCardsCount={appointmentCardsCount}
                 appointmentToken={appt.token}
                 locationType={appt.appointmentType.locationType}
                // Display only fields that are provided (could be a combination of only address + state or address + city and etc.)
                 providedAddress={[!!appt.address1 ? appt.address1 : appt.address2, appt.city, appt.state].filter(s => !!s).join(', ')}
                 virtualMeetingUrl={appt.appointmentType.virtualMeetingUrl}
               />
             ))}
          </StyledCardsWrapper>
        )}
    </StyledWrapper>
  );
}

Activated.propTypes = {
  appointments: PropTypes.array,
  onAddAnAppointmentClick: PropTypes.func,
  hasUpcomingAppointments: PropTypes.bool,
  schedulingIsActive: PropTypes.bool,
};
