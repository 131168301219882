import { SMS_PAGE } from '../../../shared/navigation/navigation-config';
import SectionLayout from '../../../shared/section/SectionLayout';
import SMSAreaCode from '../components/SMSAreaCode';

export default function SMSPage() {
  return (
    <>
      <SectionLayout
        id="sms-section-title"
        headerText="SMS Settings"
        name={SMS_PAGE}
      />
      <SMSAreaCode />
    </>
  );
}
