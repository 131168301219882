import { createSlice } from '@reduxjs/toolkit';
import businessDetailsSlice, {
  BUSINESS_CITY_KEY,
  BUSINESS_COUNTRY_KEY,
  BUSINESS_DETAILS_SECTION,
  BUSINESS_NAME_KEY,
  BUSINESS_PHONE_NUMBER_KEY,
  BUSINESS_POSTAL_CODE_KEY,
  BUSINESS_STATE_KEY,
  BUSINESS_STREET_ADDRESS_ONE_KEY,
  BUSINESS_STREET_ADDRESS_TWO_KEY,
  BUSINESS_WEBSITE_URL_KEY,
  SLOGAN_KEY,
  saveBusinessDetailsAction,
  saveBusinessDetailsFailureAction,
  saveBusinessDetailsSuccessAction
} from './business-details-slice';
import invoicesSlice from './invoices-slice';
import subscriptionsSlice from './subscriptions-slice';
import creditCardsSlice from './credit-cards-slice';
import combineSlices from '../../../../redux/combine-slices';
import { loadSettingsDataSuccess } from '../../../../shared/app-slice';
import stripeSlice from './stripe-slice';
import { BUNDLE_SLUG, INITIAL_BUNDLE_TIER } from '../../../../shared/app-constants';
import { convertTimestampToUTCDateString } from '../../../../shared/app-utils';
import { startFreeTrialSuccess } from '../../../configuration/data/configuration-slice';

const initialState = {
  isSaving: false,
  dirtySections: [],
  isDirty: false,
  invalidSections: [],
  isInvalid: false,
  showChangesSaved: false,
  showWarningModal: false,
  hasReset: false,
  resetProductInfo: {
    [BUSINESS_DETAILS_SECTION]: null,
  },
  sentEmail: null,
  trials: null,
  scrollSaveBanner: false
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setStateDirty: (state, action) => {
      const dirtyForm = action.payload.formName;
      if (state.dirtySections.indexOf(dirtyForm) === -1) {
        state.dirtySections.push(dirtyForm);
        state.isDirty = true;
      }

      state.invalidSections = state.invalidSections.filter((item) => item !== dirtyForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateClean: (state, action) => {
      const cleanForm = action.payload.formName;
      state.dirtySections = state.dirtySections.filter((item) => item !== cleanForm);
      state.isDirty = state.dirtySections.length > 0;

      state.invalidSections = state.invalidSections.filter((item) => item !== cleanForm);
      state.isInvalid = state.invalidSections.length > 0;
    },
    setStateInvalid: (state, action) => {
      const invalidForm = action.payload.formName;
      if (state.invalidSections.indexOf(invalidForm) === -1) {
        state.invalidSections.push(invalidForm);
        state.isInvalid = true;
      }
    },
    cancelSave: (state) => {
      state.hasReset = false;
      state.showWarningModal = state.isDirty;
    },
    closeWarningModal: (state, action) => {
      state.showWarningModal = false;
      if (action.payload.forceClose) {
        state.isDirty = false;
        state.dirtySections = [];
      }
    },
    closeChangesSaved: (state) => {
      state.showChangesSaved = false;
    },
    triggerResetAction: (state) => {
      state.hasReset = false;
    },
    resetCompleteAction: (state) => {
      state.hasReset = true;
    },
    sendCaptureEmailAction: () => { },
    sendCaptureEmailSuccess: (state) => {
      state.sentEmail = true;
    },
    sendCaptureEmailFailure: (state) => {
      state.sentEmail = false;
    },
    captureEmailSubmitClick: (state) => {
      state.scrollSaveBanner = true;
    },
    resetCaptureEmailSubmitClick: (state) => {
      state.scrollSaveBanner = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: Might need to change to addMatcher with isAnyOf when there are more sections with saving
    builder.addCase(saveBusinessDetailsAction, (state) => {
      state.isSaving = true;
    });
    builder.addCase(saveBusinessDetailsSuccessAction, (state, action) => {
      state.isDirty = false;
      state.dirtySections = [];
      state.showChangesSaved = true;
      state.isSaving = false;
      state.isInvalid = false;
      state.invalidSections = [];

      // Update the reset cache
      const { business, location } = action.payload;
      state.resetProductInfo[BUSINESS_DETAILS_SECTION] = {
        [BUSINESS_NAME_KEY]: business[BUSINESS_NAME_KEY] || '',
        [BUSINESS_WEBSITE_URL_KEY]: business[BUSINESS_WEBSITE_URL_KEY] || '',
        [BUSINESS_PHONE_NUMBER_KEY]: business[BUSINESS_PHONE_NUMBER_KEY] || null,
        [BUSINESS_STREET_ADDRESS_ONE_KEY]: location[BUSINESS_STREET_ADDRESS_ONE_KEY] || '',
        [BUSINESS_STREET_ADDRESS_TWO_KEY]: location[BUSINESS_STREET_ADDRESS_TWO_KEY] || '',
        [BUSINESS_CITY_KEY]: location[BUSINESS_CITY_KEY] || '',
        [BUSINESS_STATE_KEY]: location[BUSINESS_STATE_KEY] || '',
        [BUSINESS_COUNTRY_KEY]: location[BUSINESS_COUNTRY_KEY] || '',
        [BUSINESS_POSTAL_CODE_KEY]: location[BUSINESS_POSTAL_CODE_KEY] || '',
      }
    });
    builder.addCase(saveBusinessDetailsFailureAction, (state) => {
      state.isSaving = false;
    });
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const { business, trials } = action.payload;
      state.trials = trials;

      if (business) {
        const {
          name, businessUrl, businessPhone, locations, slogan
        } = business;
        const {
          address, address2, city, province, postalCode, country,
        } = locations[0];
        state.resetProductInfo[BUSINESS_DETAILS_SECTION] = {
          [BUSINESS_NAME_KEY]: name || '',
          [BUSINESS_WEBSITE_URL_KEY]: businessUrl || '',
          [BUSINESS_PHONE_NUMBER_KEY]: businessPhone || null,
          [BUSINESS_STREET_ADDRESS_ONE_KEY]: address || '',
          [BUSINESS_STREET_ADDRESS_TWO_KEY]: address2 || '',
          [BUSINESS_CITY_KEY]: city || '',
          [BUSINESS_STATE_KEY]: province || '',
          [BUSINESS_COUNTRY_KEY]: country || '',
          [BUSINESS_POSTAL_CODE_KEY]: postalCode || '',
          [SLOGAN_KEY]: slogan || '',
        };
      }
    });
    builder.addCase(startFreeTrialSuccess, (state, action) => {
      let trial = {};
      trial.active = true;
      trial.status = 'ACTIVE';
      trial.startDate = convertTimestampToUTCDateString(action.payload.trialStart);
      trial.endDate = convertTimestampToUTCDateString(action.payload.trialEnd);
      trial.slug = BUNDLE_SLUG;
      trial.tier = INITIAL_BUNDLE_TIER;
      trial.endBehavior = action.payload.trialEndBehavior;
      if (!state.trials) { state.trials = []; };
      state.trials.push(trial);
    });
  },
});

export const {
  setStateDirty,
  setStateClean,
  setStateInvalid,
  cancelSave,
  closeChangesSaved,
  triggerResetAction,
  resetCompleteAction,
  closeWarningModal,
  sendCaptureEmailAction,
  sendCaptureEmailSuccess,
  sendCaptureEmailFailure,
  captureEmailSubmitClick,
  resetCaptureEmailSubmitClick
} = accountSlice.actions;
export default combineSlices(
  accountSlice.reducer,
  initialState,
  {
    businessDetails: businessDetailsSlice,
    subscriptions: subscriptionsSlice,
    invoices: invoicesSlice,
    creditCards: creditCardsSlice,
    stripe: stripeSlice,
  }
);