import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { IQCheckbox } from '@gannettdigital/shared-react-components';
import { useTheme } from '@emotion/react';

export default function SameTimeCheckbox({ dayLabel, dayKey, onChange }) {
  const theme = useTheme();
  return (
    <Grid className="custom" container item xs={6} sm={3} md={3} lg={3}>
      <IQCheckbox
        checkboxBorderColor={theme.palette.info.dark}
        label={dayLabel}
        field={dayKey}
        onChange={(event) => onChange(event, dayKey)}
      />
    </Grid>
  );
}

SameTimeCheckbox.propTypes = {
  dayLabel: PropTypes.string.isRequired,
  dayKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
