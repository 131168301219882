import PropTypes from 'prop-types';
import {
  CircularProgress,
} from '@mui/material';
import { CircleWrapper } from './styles/grader-step-styles';

/**
 * Display a grader stepper icon
 */
export default function GraderStepIcon({ progress, iconElement }) {
  return (
    <CircleWrapper>
      <CircularProgress
        className="styledCircularProgress"
        variant="determinate"
        value={progress * 100}
        size={82}
        thickness={2}
      />
      <div className="iconWrapper">
        {iconElement}
      </div>
    </CircleWrapper>
  );
}

GraderStepIcon.propTypes = {
  progress: PropTypes.number,
  iconElement: PropTypes.node,
};
