import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { setStateDirty, setStateClean, setStateInvalid } from "../../pages/configuration/data/configuration-slice";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function WizardStateChangedHook({
  formName,
  invalidOnEmpty = false,
}) {
  const dispatch = useDispatch();
  const { formState } = useFormContext();
  const { isDirty, isValid } = formState;

  const onDirtyForm = () => {
    dispatch(
      setStateDirty({ formName })
    );
  }

  const onCleanForm = () => {
    dispatch(
      setStateClean({ formName })
    );
  }

  const onInvalidForm = () => {
    dispatch(
      setStateInvalid({ formName })
    );
  }

  useEffect(() => {
    const { isDirty, isValid } = formState;
    if (isDirty && isValid) {
      onDirtyForm();
    } else if ((isDirty || invalidOnEmpty) && !isValid) {
      onInvalidForm();
    } else {
      onCleanForm();
    }
  }, [isDirty, isValid]);

  return (null);
}

WizardStateChangedHook.propTypes = {
  formName: PropTypes.string.isRequired,
  // Set to true if you need to use the invalid state to disable
  // something on page load (e.g. disable save on first load)
  invalidOnEmpty: PropTypes.bool,
};
