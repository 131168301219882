import PropTypes from 'prop-types';
import DetailAccordion from './DetailAccordion';

/**
 * Display a score detail block: A color coded section with
 * expandable details blocks for each area
 */
export default function ScoreDetailBlock({ level, details }) {
  return (
    <div>
      {details.map((detail) => (
        <DetailAccordion key={detail.key} detail={detail} level={level} />
      ))}
    </div>
  );
}

ScoreDetailBlock.propTypes = {
  level: PropTypes.string,
  details: PropTypes.array,
};
