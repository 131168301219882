import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SaveCancelWidget from '../../../shared/saveCancel/SaveCancelWidget';
import { SECTION_TO_SAVE_ACTION_CONFIG } from './settings-config';
import { closeChangesSaved } from './data/widgetSettings-slice';

export default function WidgetSettingsSaveCancelWidget({ currentScreen }) {
  const dispatch = useDispatch();
  const {
    isSaving,
    isInvalid,
    isDirty,
    showChangesSaved,
  } = useSelector((state) => state.settings);
  const saveAction = SECTION_TO_SAVE_ACTION_CONFIG[currentScreen];

  const handleSave = useCallback(() => {
    if (saveAction) {
      dispatch(
        saveAction()
      );
    }
  }, [saveAction]);

  const handleSaveComplete = () => {
    dispatch(
      closeChangesSaved()
    );
  };

  return (
    <SaveCancelWidget
      id="widget-settings"
      isInvalid={isInvalid}
      isDirty={isDirty}
      isSaving={isSaving}
      showChangesSaved={showChangesSaved}
      onSave={handleSave}
      onSaveComplete={handleSaveComplete}
    />
  );
}

WidgetSettingsSaveCancelWidget.propTypes = {
  currentScreen: PropTypes.string,
};
