import { createSlice, createAction } from '@reduxjs/toolkit';
import { lookupProductConversion } from './analytics-utils';
import {
  ORDER_STATUS_SUBMITTED,
  TRACKJS_USERID_METADATA_KEY,
  TRACKJS_USER_INTEXT_METADATA_KEY,
  BUNDLE_SLUG,
  INITIAL_BUNDLE_TIER,
  SUPPORT_ROLE,
} from './app-constants';
import { redirectAction } from '../redux/shared-actions';

export const logoutUserAction = createAction('app_settings/logout');
export const loadTrackingCodeAction = createAction('app_settings/loadTrackingCode');
export const emptyAction = createAction('app_settings/emptyAction');

const appSlice = createSlice({
  name: 'app_settings',
  initialState: {
    user: null,
    wizardMetaData: null,
    orders: [],
    loadedSettings: false,
    isLoadingSettingsData: false,
    redirectPath: null,
    activatedProducts: [],
    leadsCount: 0,
    trackingCode: {
      code: null,
      isInstalled: false,
      isLoading: false,
      hasCheckedCodeStatus: false,
      firstCaptureCodeActivation: null,
    },
    // Auto-detect user timezone
    currentUserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  reducers: {
    loadSettingsData: (state) => {
      state.loadedSettings = false;
      state.isLoadingSettingsData = true;
    },
    loadSettingsDataSuccess: (state, action) => {
      state.orders = action.payload.orders;
      state.user = {... action.payload.user, hasSupportRole: action.payload.user?.role && action.payload.user?.role.includes(SUPPORT_ROLE) };
      state.loadedSettings = true;
      state.isLoadingSettingsData = false;

      if (action.payload.wizards) {
        // For now look for the bundle wizard data only, some day we may
        // have more to process / check
        for (let i = 0; i < action.payload.wizards.length; i++) {
          const wizard = action.payload.wizards[i];
          if (wizard.slug === BUNDLE_SLUG && wizard.tier === INITIAL_BUNDLE_TIER) {
            state.wizardMetaData = wizard;
            break;
          }
        }
      }

      if (window.TrackJS && action.payload.user) {
        TrackJS.addMetadata(TRACKJS_USERID_METADATA_KEY, action.payload.user.id);
        TrackJS.addMetadata(TRACKJS_USER_INTEXT_METADATA_KEY, action.payload.user.internal ? 'internal' : 'external');
      }

      const { orders } = action.payload;
      const convertedProds = [];
      if (orders) {
        orders.forEach((userOrder) => {
          if (userOrder.status === ORDER_STATUS_SUBMITTED) {
            // Only set activated products as part of SUBMITTED orders, only
            // SUBMITTED are considered activated.
            for (let j = 0; j < userOrder.items.length; j += 1) {
              const orderItem = userOrder.items[j];
              const { productType } = orderItem;
              const itemSlug = productType.slug;
              const itemTier = productType.tier;

              if (orderItem.active) {
                const foundProd = lookupProductConversion(
                  itemSlug,
                  itemTier,
                );
                // If lookup failed, record as slug and tier
                if (!foundProd) {
                  convertedProds.push(`${itemSlug} ${itemTier}`);
                } else {
                  convertedProds.push(foundProd);
                }
              }
            }
          }
        });
      }
      state.activatedProducts = convertedProds;

      if (action.payload.business) {
        const { business } = action.payload;
        state.trackingCode.firstCaptureCodeActivation = business?.firstCaptureCodeActivation;
      }
    },
    loadSettingsDataError: (state) => {
      state.loadedSettings = false;
      state.user = null;
      state.isLoadingSettingsData = false;
    },
    loadLeadsSuccess: (state, action) => {
      const { unread } = action.payload;
      if (unread) {
        // Events is the total number of leads in the unread section
        state.leadsCount = unread.events;
      }
    },
    loadLeadsError: () => {},
    loadTrackingCode: (state) => {
      state.trackingCode.isLoading = true;
    },
    loadTrackingCodeSuccess: (state, action) => {
      const { code, active } = action.payload;
      state.trackingCode.code = code;
      state.trackingCode.isInstalled = active;
      state.trackingCode.isLoading = false;
      state.trackingCode.hasCheckedCodeStatus = true;
    },
    loadTrackingCodeError: (state) => {
      state.trackingCode.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(redirectAction, (state, action) => {
      state.redirectPath = action.payload.redirectPath;
    });
  },
});

export const {
  loadSettingsData,
  loadSettingsDataSuccess,
  loadSettingsDataError,
  loadLeadsSuccess,
  loadLeadsError,
  loadTrackingCode,
  loadTrackingCodeSuccess,
  loadTrackingCodeError,
} = appSlice.actions;
export default appSlice.reducer;
