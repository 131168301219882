import { createSlice, createAction } from '@reduxjs/toolkit';

export const getAddressOptions = createAction('addressAutocomplete/getAddressOptions');

const addressAutocompleteSlice = createSlice({
  name: 'address_autocomplete',
  initialState: {
    addressOptions: [],
    addressOptionsFailure: false,
  },
  reducers: {
    getAddressOptionsSuccess: (state, action) => {
      state.addressOptions = action.payload.suggestions || [];
    },
    getAddressOptionsFailure: (state, action) => {
      state.addressOptionsFailure = true;
    },
  },
});

export const {
  getAddressOptionsSuccess,
  getAddressOptionsFailure,
} = addressAutocompleteSlice.actions;
export default addressAutocompleteSlice.reducer;
