import { createAction, createSlice } from '@reduxjs/toolkit';
import { OUTLOOK_TYPE } from '../bookings-constants';
import { updateCalendarIntegrationAction, updateCalendarIntegrationActionSuccess } from './calendar-actions';
import { loadedBookingData } from './bookings-slice';

export const saveOutlookAuth = createAction('outlookCalendar/saveAuth');

const outlookCalendarSlice = createSlice({
  name: 'outlookCalendar',
  initialState: {
    isConnected: false,
    isLoading: false,
    isAuthed: false,
    isAuthModalOpen: false,
    calendarIntegrationData: {
      email: null,
      connectedOn: null,
    },
    showErrorBanner: false,
    authFailure: false,
    authError: null,
  },
  reducers: {
    authRequestProgress: (state) => {
      state.isAuthModalOpen = true;
    },
    authRequestSuccess: (state, action) => {
      state.isAuthed = true;
      state.calendarIntegrationData.email = action.payload.accountKey;
    },
    updateCalendarIntegrationFailure: (state) => {
      state.showErrorBanner = true;
      state.isLoading = false;
    },
    dismissErrorBanner: (state) => {
      state.showErrorBanner = false;
    },
    authRequestFailure: (state, action) => {
      state.authFailure = true;
      state.authError = action.payload ? action.payload.errorCode : null;
    },
    authSessionEnded: (state) => {
      state.isAuthModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCalendarIntegrationAction, (state, action) => {
      if (action.payload.type === OUTLOOK_TYPE) {
        state.isLoading = true;
      }
    });
    builder.addCase(updateCalendarIntegrationActionSuccess, (state, action) => {
      const { payload } = action;
      if (payload.type === OUTLOOK_TYPE) {
        const { calendarIntegration } = action.payload.data;
        if (calendarIntegration.length && calendarIntegration[0].accountKey) {
          state.calendarIntegrationData = {
            email: calendarIntegration[0].accountKey,
            connectedOn: calendarIntegration[0].connectedDate,
          };
          state.isConnected = true;
          state.isLoading = false;
        } else {
          // Success (disconnected)
          state.calendarIntegrationData = {
            email: null,
            connectedOn: null,
          };
          state.isConnected = false;
          state.isLoading = false;
        }
      }
    });
    builder.addCase(loadedBookingData, (state, action) => {
      const { payload } = action;

      // If the integration is already done on load
      if (
        payload.productInfo
        && payload.productInfo.calendarIntegration
        && payload.productInfo.calendarIntegration.length !== 0
        && payload.productInfo.calendarIntegration[0].type === OUTLOOK_TYPE) {
        const { calendarIntegration } = payload.productInfo;

        state.calendarIntegrationData = {
          email: calendarIntegration[0].accountKey,
          connectedOn: calendarIntegration[0].connectedDate,
        };
        state.isConnected = !!calendarIntegration[0].accountKey;
      }
    });
  },
});

export const {
  authRequestProgress,
  authRequestSuccess,
  dismissErrorBanner,
  authRequestFailure,
  authSessionEnded,
} = outlookCalendarSlice.actions;
export default outlookCalendarSlice.reducer;
