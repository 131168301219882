import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkGraderMessage } from '../websocket/websocket-utils';
import { graderCompleteMessage } from '../../pages/graders/website/data/grader-slice';
import { SocketContext } from '../websocket/WebSocketProvider';

/**
 * Hook for listening to the grader complete message and processing the response
 */
export default function GraderCompleteListener() {
  const dispatch = useDispatch();
  const messageSocket = useContext(SocketContext);

  const graderFinished = useCallback((message) => {
    if (checkGraderMessage(message)) {
      dispatch(graderCompleteMessage(message.data));
    }
  });

  useEffect(() => (
    messageSocket.addMessageListener(graderFinished)
  ), [messageSocket]);

  return null;
}
