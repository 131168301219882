import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function AddressAutocompleteSuggestionTemplate({
  address, name, place, region, country, includeNameInSuggestion,
}) {

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon
          sx={{
            color: (theme) => theme.palette.text.secondary,
            marginRight: (theme) => theme.spacing(2),
          }}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="body2" color="textPrimary">
          {includeNameInSuggestion && name ? `${name}, ${address}` : address}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {place ? `${place}, ` : ''}
          {' '}
          {region ? `${region}, ` : ''}
          {' '}
          {country || ''}
        </Typography>
      </Grid>
    </Grid>
  );
}

AddressAutocompleteSuggestionTemplate.propTypes = {
  address: PropTypes.string.isRequired,
  name: PropTypes.string,
  place: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.string,
  includeNameInSuggestion: PropTypes.bool,
};
