import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import { formatPhoneNumberUtil } from '../../../../../shared/app-utils';
import { HeaderText, PhoneText, PrimaryButton } from '../../../../../shared/settings-shared-components';
import { CALL_RECORDING_ACTIVE_KEY, CURRENT_FORWARDING_NUMBER } from '../../call-settings-constants';
import { ReactComponent as PhoneIcon } from '../../assets/phone-icon.svg';
import { COMPLETED_STATUS } from '../../../../configuration/wizard/wizard-constants';
import ForwardingNumberLoadingComponent from './ForwardingNumberLoadingComponent';

const ViewWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(4),
  },
}));

const PhoneAreaBox = styled(Box)(({ theme }) => ({
  flexBasis: '100%',
  display: 'flex',
  paddingLeft: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    paddingLeft: 0,
  },
  '& .phone-input': {
    display: 'flex',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      paddingLeft: theme.spacing(2),
    },
  },
  '& .phone-button': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      marginLeft: 0,
      '& button': {
        marginTop: theme.spacing(4),
        width: '100%',
      },
    },
  },
}));

/**
 * Handle the view state of the forwarding number card
 */
export default function ForwardingNumberViewComponent({ onEdit }) {
  const {
    [CURRENT_FORWARDING_NUMBER]: currentForwardingNumber,
    [CALL_RECORDING_ACTIVE_KEY]: callRecorderEnabled,
  } = useSelector((state) => state.callSettings.generalSettings);
  const trackingNumber = useSelector((state) => state.settings.trackingNumber);
  const isWizardComplete = useSelector((state) => state.configuration.status === COMPLETED_STATUS);

  return (
    <ViewWrapper>
      <HeaderText component="div">Forwarding Number</HeaderText>
      {(isWizardComplete && !trackingNumber && callRecorderEnabled) && (
        <ForwardingNumberLoadingComponent singleNum={true} />
      )}
      {(currentForwardingNumber || trackingNumber) && (
        <PhoneAreaBox>
          <div className="phone-input">
            <PhoneIcon />
            <PhoneText component="div">{formatPhoneNumberUtil(currentForwardingNumber || trackingNumber)}</PhoneText>
          </div>
          <div className="phone-button">
            <PrimaryButton variant="outlined" startIcon={<EditIcon />} onClick={onEdit}>Change</PrimaryButton>
          </div>
        </PhoneAreaBox>
      )}
    </ViewWrapper>
  );
}

ForwardingNumberViewComponent.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
