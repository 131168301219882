import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  useCallback,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SettingsDialog from '../../../layouts/dialogs/SettingsDialog';
import SideNav from '../../../layouts/navBars/SideNav';
import SplitColumnLayout from '../../../layouts/SplitColumnLayout';
import {
  PRO_TIER,
  WIDGET_SETTINGS_SLUG,
  WIDGET_SETTINGS_PATH,
} from '../../../shared/app-constants';
import { ENGAGEMENT_WIDGET_PAGE, LOCALIQ_CODE_PAGE, NOTIFICATIONS_PAGE } from '../../../shared/navigation/navigation-config';
import NavigationHooks from '../../../shared/navigation/NavigationHooks';
import { PaddedNav, SpinnerWrapper } from '../../../shared/settings-shared-components';
import EngagementWidget from './sections/EngagementWidget';
import LocalIQCode from './sections/LocaliQCode';
import Notifications from './sections/Notifications';
import WidgetSettingsSaveCancelWidget from './WidgetSettingsSaveCancelWidget';
import withOriginLink from '../../../shared/hocs/withOriginLink';
import { setCurrentScreen } from '../../../shared/navigation/navigation-slice';
import { cancelSave, resetCompleteAction, setShowWarningModal } from './data/widgetSettings-slice';
import { closeWarningModal } from '../../../redux/shared-actions';
import CancelModal from '../../../shared/modal/CancelModal';
import DialogFrame from '../../tools/components/DialogFrame';
import { triggerResetAction } from '../account/data/account-slice';

const HEADER_TEXT = 'LocaliQ Settings';

const WidgetSettingNavWrapper = styled(PaddedNav)(({ theme }) => ({
  marginRight: theme.spacing(9),
}));

function WidgetSettingsDialog({ onHandleOriginLinkClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [navItems, setNavItems] = useState([]);
  const [nextScreen, setNextScreen] = useState(null);

  const { [WIDGET_SETTINGS_SLUG]: settingsNav } = useSelector(
    (state) => state.navigationSettings.sectionListByProduct,
  );
  const { currentScreen = ENGAGEMENT_WIDGET_PAGE } = useParams();

  const showWarningModal = useSelector((state) => state.settings.showWarningModal);
  const isDirty = useSelector((state) => state.settings.isDirty);
  const isSaving = useSelector((state) => state.settings.isSaving);
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);
  const hasSupportRole = useSelector((state) => state.appSettings.user?.hasSupportRole);
  const businessInfo = useSelector((state) => state.account.businessDetails?.businessDetails);

  const supportInfo = {
    isSupport: hasSupportRole,
    businessInfo: businessInfo,
  };

  const handleProceed = useCallback((proceed) => {
    // User wants to proceed through the reset warning
    if (proceed) {
      if (nextScreen) {
        dispatch(setCurrentScreen({ currentScreen: nextScreen }));
        navigate(`${WIDGET_SETTINGS_PATH}/${nextScreen}`);
        dispatch(resetCompleteAction())
      } else {
        // The selected X (close)
        onHandleOriginLinkClose();
        dispatch(resetCompleteAction())
      }
    }
    dispatch(setShowWarningModal({
      value: false,
    }));
  });

  const handleSectionClick = (navKey) => {
    if (isDirty || isSaving) {
      dispatch(setShowWarningModal({
        value: true,
      }));
      setNextScreen(navKey);
    } else {
      dispatch(setCurrentScreen({ currentScreen: navKey }));
      navigate(`${WIDGET_SETTINGS_PATH}/${navKey}`);
    }
    scrollRef.current.scroll({ top: 0 });
  };

  const handleOnClose = useCallback(() => {
    if (isDirty) {
      setNextScreen(null)
      dispatch(cancelSave());
    } else {
      onHandleOriginLinkClose();
    }
  }, [isDirty]);

  const helpText = <> If you have questions related to SMS, please contact your LocaliQ customer representative or email us at <a target="_blank" href="mailto:clientsuccess@localiq.com">clientsuccess@localiq.com</a></>

  return (
    <SettingsDialog
      open
      header={HEADER_TEXT}
      onClose={handleOnClose}
      customHeader={<WidgetSettingsSaveCancelWidget currentScreen={currentScreen} />}
      id="widgetSettings-closeSettings"
      supportInfo={supportInfo}
      headerId="widgetSettings-title"
    >
      <Helmet>
        <title>
          Settings | LocaliQ
        </title>
      </Helmet>
      {!loadedSettings && (
        <SpinnerWrapper>
          <IQLoadingSpinner size={100} />
        </SpinnerWrapper>
      )}
      {loadedSettings && (
        <SplitColumnLayout
          leftSideContent={(
            <WidgetSettingNavWrapper>
              <SideNav
                navItems={navItems}
                currentSection={currentScreen}
                product={WIDGET_SETTINGS_SLUG}
                customOnClick={handleSectionClick}
              />
            </WidgetSettingNavWrapper>
          )}
          needHelpText={helpText}
          rightSideContent={(
            <DialogFrame hideBanners={currentScreen === LOCALIQ_CODE_PAGE || hasSupportRole}>
              {currentScreen === ENGAGEMENT_WIDGET_PAGE && (
                <EngagementWidget />
              )}
              {currentScreen === LOCALIQ_CODE_PAGE && (
                <LocalIQCode />
              )}
              {currentScreen === NOTIFICATIONS_PAGE && (
                <Notifications />
              )}
            </DialogFrame>
          )}
          rightContentRef={scrollRef}
        />
      )}
      <CancelModal
        closeWarningModal={closeWarningModal}
        triggerResetAction={triggerResetAction}
        isOpen={showWarningModal}
        proceedCallback={handleProceed}
      />
      <NavigationHooks
        slug={WIDGET_SETTINGS_SLUG}
        tier={PRO_TIER}
        productNav={settingsNav || []}
        handleNavCalc={setNavItems}
        open
      />
    </SettingsDialog>
  );
}

export default withOriginLink(WidgetSettingsDialog);

WidgetSettingsDialog.propTypes = {
  onHandleOriginLinkClose: PropTypes.func.isRequired,
};
