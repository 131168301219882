import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../shared/settings-shared-components';
import { BUNDLE_SLUG, INITIAL_BUNDLE_TIER } from '../../shared/app-constants';
import { calculateNumberOfDaysLeft } from '../../shared/app-utils';
import { trackDashboardCardClick, trackUpgrade } from '../../shared/analytics-utils';
import LoadingSkeletonFreeTrialStatusBanner from './components/loadingSkeletons/freeTrialStatusBanner/LoadingSkeletonFreeTrialStatusBanner';

const {
  REACT_APP_BUY_ONLINE_UI_ROOT,
} = process.env;

export const FreeTrailBannerWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  padding: theme.spacing(2, 3),

  '& .upgrade-button': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },

  [theme.breakpoints.down('md')]: {
    flexFlow: 'column',
  },
}));

const BoldText = styled('span')(() => ({
  fontFamily: 'Unify Sans Semibold',
}));

export default function FreeTrialStatusBanner() {
  const trialInfo = useSelector((state) => state.configuration.trialInfo);
  const isLoadingSettingsData = useSelector((state) => state.appSettings.isLoadingSettingsData);

  const handleUpgrade = () => {
    trackDashboardCardClick('Free Trial Banner', 'Upgrade Now');
    trackUpgrade();
    const buyLink = `${REACT_APP_BUY_ONLINE_UI_ROOT}?product=${BUNDLE_SLUG}&tier=${INITIAL_BUNDLE_TIER}`;
    window.open(buyLink, '_self');
  };

  if (!trialInfo) { return null; }

  if (isLoadingSettingsData) {
    return (
      <FreeTrailBannerWrapper>
        <LoadingSkeletonFreeTrialStatusBanner />
      </FreeTrailBannerWrapper>
    );
  }

  return (
    <FreeTrailBannerWrapper>
      <div>
        You have
        <BoldText>
          &nbsp;
          {calculateNumberOfDaysLeft(trialInfo.endDate)}
          {' '}
          days&nbsp;
        </BoldText>
        {' '}
        left in your trial.
      </div>
      <PrimaryButton className="upgrade-button" type="button" onClick={handleUpgrade}>
        Upgrade Now
      </PrimaryButton>
    </FreeTrailBannerWrapper>
  );
}
