import styled from '@emotion/styled';
import { Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasExistingNumber',
})(({ theme, hasExistingNumber }) => ({
  gap: theme.spacing(2),
  width: hasExistingNumber ? '75%' : '100%',
  flexWrap: 'nowrap',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

const LoadingBox = styled(Grid)(({ theme }) => ({
  border: '1px solid #AEAEAD',
  display: 'flex',
  borderRadius: '2px',
  padding: theme.spacing(2),

  // Adjust the skeleton
  '& .MuiSkeleton-root.MuiSkeleton-rectangular': {
    borderRadius: '4px',
  },
}));

/**
 * Handle the loading state of the forwarding number card
 */
export default function ForwardingNumberLoadingComponent({ hasExistingNumber = false, singleNum = false }) {
  const numberBlocks = singleNum ? [...Array(1).keys()] : Array.from({ length: hasExistingNumber ? 2 : 3 }, (_, ind) => ind);
  return (
    <LoadingWrapper container hasExistingNumber={hasExistingNumber}>
      {numberBlocks.map((num) => (
        <LoadingBox key={num} item container flexDirection="row" gap={1} xs={12} md={3}>
          <Grid item>
            <Skeleton variant="rectangular" height={18} width={18} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" height={16} width={42} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" height={26} width={120} />
          </Grid>
        </LoadingBox>
      ))}
    </LoadingWrapper>
  );
}

ForwardingNumberLoadingComponent.propTypes = {
  hasExistingNumber: PropTypes.bool.isRequired,
};
