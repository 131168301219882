import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ActionCard } from '@gannettdigital/shared-react-components';
import {VALID_TYPES, ActionIcons} from "../../../../shared/app-constants"
import { LinkButton } from '../../../../shared/settings-shared-components';

const StyledActionCard = styled(ActionCard)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  position: 'relative',
}));

const BannerContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const BannerTitle = styled('span')(() => ({
  fontSize: 16,
  fontFamily: 'Unify Sans Semibold',
  lineHeight: '2rem',
}));

const BannerDescription = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: 14,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const BannerDismiss = styled(LinkButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(6),
  color: theme.palette.text.primary,
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 0),
    position: 'static',
    right: 0,
    alignSelf: 'flex-start',
    marginLeft: '-5px',
  },
}));

export default function BannerCard({
  id, showBanner, title, bannerType, description, dismissBanner, autoDismissInMs, width,
}) {
  useEffect(() => {
    let dismissId;

    if (showBanner && autoDismissInMs) {
      dismissId = setTimeout(dismissBanner, autoDismissInMs);
    }

    return () => {
      if (dismissId) {
        clearInterval(dismissId);
      }
    };
  }, [showBanner]);

  return showBanner && (
    <StyledActionCard id={id} type={bannerType} icons={ActionIcons} style={{ width: width ? `${width}px` : '100%' }}>
      <BannerContainer>
        {title && <BannerTitle>{title}</BannerTitle>}
        <BannerDescription>{description}</BannerDescription>
        { dismissBanner && (
          <BannerDismiss onClick={dismissBanner}>Dismiss</BannerDismiss>
        )}
      </BannerContainer>
    </StyledActionCard>
  );
}

BannerCard.propTypes = {
  id: PropTypes.string.isRequired,
  showBanner: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bannerType: PropTypes.oneOf(VALID_TYPES).isRequired,
  dismissBanner: PropTypes.func,
  autoDismissInMs: PropTypes.number, // Milliseconds to dismiss the banner
  width: PropTypes.number,
};
