import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { IQSwitch } from '@gannettdigital/shared-react-components';
import { SwitchWrapperOverride } from "../../../shared/settings-shared-components";
import SchedulingIcon from '../../../images/scheduler-feature.svg';
import ChatbotIcon from '../../../images/chatbot-feature.svg';
import CallRecorderIcon from '../../../images/call-feature.svg';
import ContactFormIcon from '../../../images/contactForm-modal.svg';

import { CHATBOT_FORM_KEY, CLICKTOCALL_FORM_KEY, CONTACTFORM_FORM_KEY, SCHEDULER_FORM_KEY } from '../wizard/wizard-constants';
import { saveCallTrackerFeature, saveChatbotFeature, saveContactFormFeature, saveSchedulerFeature } from '../data/widget-feature.slice';

const FeatureWrapper = styled('div')(() => (({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '1rem 0',
})));

const FeatureInfoWrapper = styled('div')(() => (({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  alignItems: 'center',
})));

const FeatureText = styled('div')(() => (({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
})));

const StyledSwitchWrapper = styled(SwitchWrapperOverride)(({ theme }) => ({
  '& .MuiFormControlLabel-root': {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function WidgetFeature() {
  const dispatch = useDispatch();
  const toggleSchedulerFeature = (e) => {
    dispatch(saveSchedulerFeature(e));
  };

  const toggleChatbotFeature = (e) => {
    dispatch(saveChatbotFeature(e));
  };

  const toggleClickCallFeature = (e) => {
    dispatch(saveCallTrackerFeature(e));
  };

  const toggleContactFormFeature = (e) => {
    dispatch(saveContactFormFeature(e));
  };

  return (
    <>
      <FeatureWrapper>
        <FeatureInfoWrapper>
          <img src={SchedulingIcon} style={{ width: '2rem' }} alt="scheduling" />
          <FeatureText>
            <Typography variant="h5"> Scheduling </Typography>
            <Typography> Visitors can schedule appointments. </Typography>
          </FeatureText>
        </FeatureInfoWrapper>
        <StyledSwitchWrapper>
          <IQSwitch
            id="scheduler-widget-feature"
            name={SCHEDULER_FORM_KEY}
            value="scheduler"
            onChange={toggleSchedulerFeature}
          />
        </StyledSwitchWrapper>
      </FeatureWrapper>
      <hr />
      <FeatureWrapper>
        <FeatureInfoWrapper>
          <img src={ChatbotIcon} style={{ width: '2rem' }} alt="chatbot" />
          <FeatureText>
            <Typography variant="h5"> Chatbot </Typography>
            <Typography> Engage visitors and answer questions. </Typography>
          </FeatureText>
        </FeatureInfoWrapper>
        <StyledSwitchWrapper>
          <IQSwitch
            id="chatbot-widget-feature"
            name={CHATBOT_FORM_KEY}
            value="chatbot"
            onChange={toggleChatbotFeature}
          />
        </StyledSwitchWrapper>
      </FeatureWrapper>
      <hr />
      <FeatureWrapper>
        <FeatureInfoWrapper>
          <img src={CallRecorderIcon} style={{ width: '2rem' }} alt="call-recorder" />
          <FeatureText>
            <Typography variant="h5"> Click-to-Call (USA Only) </Typography>
            <Typography> Connect with your visitors by phone. </Typography>
          </FeatureText>
        </FeatureInfoWrapper>
        <StyledSwitchWrapper>

          <IQSwitch
            id="clicktocall-widget-feature"
            name={CLICKTOCALL_FORM_KEY}
            onChange={toggleClickCallFeature}
          />
        </StyledSwitchWrapper>

      </FeatureWrapper>
      <hr />
      <FeatureWrapper>
        <FeatureInfoWrapper>
          <img src={ContactFormIcon} style={{ width: '2rem' }} alt="contact-form" />
          <FeatureText>
            <Typography variant="h5"> Contact Form </Typography>
            <Typography> Collect leads from incoming messages. </Typography>
          </FeatureText>
        </FeatureInfoWrapper>
        <StyledSwitchWrapper>
          <IQSwitch
            id="message-widget-feature"
            name={CONTACTFORM_FORM_KEY}
            value="Contact Form"
            onChange={toggleContactFormFeature}
          />
        </StyledSwitchWrapper>
      </FeatureWrapper>
    </>
  );
}
