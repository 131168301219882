import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { ReactComponent as CopyIcon } from './assets/copy-icon.svg';
import { ReactComponent as ExportIcon } from './assets/export-icon.svg';
import { copyToClipboard, modifyUrl, openNewTab } from '../../shared/app-utils';
import { withSnackbar } from '../../shared/snackbar/withSnackbar';
import { SCHEDULER_NAME } from '../../pages/products/bookings/bookings-constants';
import { trackSchedulerShareClick, trackSchedulerViewClick } from '../../shared/analytics-utils';


const SchedulerLinkItemWrapper = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  fontSize: 16,
  cursor: 'default',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  '& svg': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: {
    '& .actions': {
      display: 'flex',
      gap: theme.spacing(2.5),
      width: 'calc(100% - 32px)',
      justifyContent: 'flex-start',
      marginLeft: '16px'
    },
  },
  [theme.breakpoints.down('md')]: {
    '.actions': {
      display: 'flex',
      gap: theme.spacing(2.5),
      ' .MuiSvgIcon-root': {
        width: '14px',
        height: '14px',
        color: 'rgba(22, 101, 207, 1)',
        cursor: 'pointer',
      },
    },
  },
}));

function SchedulerLinkItem({ title, url, snackbarShowMessage }) {
  const onClickNewTab = useCallback(() => {
    openNewTab(modifyUrl(url, "scheduler_link"));
    trackSchedulerViewClick();
  }, [url]);

  const onClickCopyToClipboard = useCallback(() => {
    copyToClipboard(modifyUrl(url, "scheduler_link"));
    snackbarShowMessage(`Your ${SCHEDULER_NAME} link has been copied to your clipboard and is ready to be shared.`);
    trackSchedulerShareClick();
  }, [url]);

  return (
    <SchedulerLinkItemWrapper disableRipple>
      <div className="title">
        {title}
      </div>
      <div className="actions">
        <ExportIcon onClick={onClickNewTab} />
        <CopyIcon onClick={onClickCopyToClipboard} />
      </div>
    </SchedulerLinkItemWrapper>
  );
}

export default withSnackbar(SchedulerLinkItem);

SchedulerLinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  snackbarShowMessage: PropTypes.func,
};
