import { MESSAGE_RECEIVED, MESSAGE_SENT, SOCKET_NOT_AVAILABLE } from './websocket-constants';

/**
 * Child class of websocket to add any additional functionality
*/
export class CustomWebSocket extends WebSocket {
  // Only send the message if channel is open, otherwise notify consumer
  safeSend = (data) => {
    if (this.OPEN) {
      this.send(JSON.stringify(data));
      return {
        message: MESSAGE_SENT,
      };
    }
    return {
      message: SOCKET_NOT_AVAILABLE,
    };
  };

  removeMessageListener = (callbackFunc) => {
    this.removeEventListener(MESSAGE_RECEIVED, callbackFunc);
  };

  /**
   * Custom listener specifically for our messages. Parses the data and passes
   * to the given callbackFunc. Gives the callbackFunc the data in the object format
   * sent from the backend
   */
  addMessageListener = (callbackFunc) => {
    this.addEventListener(
      MESSAGE_RECEIVED,
      (message) => {
        const parsedData = JSON.parse(message?.data);
        if (parsedData) {
          callbackFunc(parsedData);
        } else {
          // If for some reason we failed to parse, just return the message as is
          callbackFunc(message);
        }
      },
    );
    return () => {
      this.removeMessageListener(callbackFunc);
    };
  };
}
