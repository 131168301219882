import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography } from '@mui/material';
import { PrimaryButton, ModalBackground } from '../settings-shared-components';
import { ReactComponent as CloseIcon } from "../../images/close-x-icon.svg"
import { CloseModal, ModalDivider, CreditCardHeader, CancelButton, ButtonWrapper, HeaderWrapper, CCSectionWrapper, TitleWrapper } from '../../pages/products/account/components/account-shared-components';
import { toggleDeleteModal, deleteCreditCard, toggleActionBanner } from '../../pages/products/account/data/credit-cards-slice';
import { BACKDROP_CLICK } from '../app-constants';

const CardDescriptionWrapper = styled(Typography)(() => ({
  padding: '5px 24px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left'
}));

const CreditCardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Unify Sans Semibold',
  float: 'left',
  marginLeft: '24px',
  marginTop: '22px',
  marginBottom: '8px',
  fontSize: 16,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

export default function DeleteCreditCardModal({ isOpen }) {
  const dispatch = useDispatch();
  const paymentId = useSelector((state) => state.account.creditCards.deletePaymentId);

  function dismissModal(ev, reason) {
    if (reason === BACKDROP_CLICK) {
      return;
    }
    dispatch(toggleDeleteModal({ toggleDeleteModal: false }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(toggleActionBanner({ toggleActionBanner: false }))

    dispatch(deleteCreditCard({
      paymentId: paymentId,
    }));

    dismissModal()
  }

  return (
    <Modal open={isOpen} onClose={dismissModal}>
      <ModalBackground>
        <HeaderWrapper>
          <TitleWrapper >
            <CreditCardHeader> Delete Credit Card</CreditCardHeader>
          </TitleWrapper>
          <div>
            <CloseModal onClick={dismissModal}><CloseIcon /></CloseModal>
          </div>
        </HeaderWrapper>
        < ModalDivider />
        <form onSubmit={handleSubmit}>
          <CCSectionWrapper>
            <CreditCardTitle> Are you sure you want to delete this credit card? </CreditCardTitle>
            <CardDescriptionWrapper>
              Your card will be deleted, but can be added again if needed.
            </CardDescriptionWrapper>
          </CCSectionWrapper>
          <ButtonWrapper>
            <CancelButton className="stripe-save-btn" variant="outlined" onClick={dismissModal} >
              No
            </CancelButton>
            <PrimaryButton className="stripe-save-btn" variant="contained" type="submit" >
              Yes, Delete Credit Card
            </PrimaryButton>
          </ButtonWrapper>
        </form>
      </ModalBackground>
    </Modal>
  );
}

DeleteCreditCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};
