import { Children, cloneElement, isValidElement } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import TrialExpiredBanner from '../../../shared/trials/TrialExpiredBanner';
import TrialStartBanner from '../../../shared/trials/TrialStartBanner';
import { hasRestrictedAccess } from '../../../shared/app-utils';

const ToolsContent = styled('div')(({ theme, hasBanner }) => ({
  marginTop: hasBanner ? theme.spacing(3) : 0,
  '& .childContent': {
    paddingTop: theme.spacing(2),
  },
}));

/**
 * Frame for displaying dialog content.
 * Will pass the isDisabled param to all child nodes.
 */
export default function DialogFrame({ hideBanners = false, children }) {
  const trials = useSelector((state) => state.account.trials);
  const orders = useSelector((state) => state.appSettings.orders);
  const disablePageElements = hasRestrictedAccess(trials, orders);

  // Take passed children and inject whether it should be disabled
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { isDisabled: disablePageElements });
    }
    return child;
  });

  return (
    <ToolsContent hasBanner={disablePageElements}>
      {!hideBanners && (
        <>
          <TrialExpiredBanner />
          <TrialStartBanner />
        </>
      )}
      <div className="childContent">
        {childrenWithProps}
      </div>
    </ToolsContent>
  );
}

DialogFrame.propTypes = {
  hideBanners: PropTypes.bool,
  children: PropTypes.node,
};
