import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { store } from './redux/store';
import App from './app';
import { DefaultTheme } from './themes/DefaultTheme';
import './index.css';
import WebSocketProvider from './shared/websocket/WebSocketProvider';

const rootElement = document.getElementById('root');

// eslint-disable-next-line function-paren-newline
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={DefaultTheme}>
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </ThemeProvider>
  </Provider>, rootElement);
