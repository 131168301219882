import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { isMobile } from 'react-device-detect';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from '@emotion/styled';
import { IQDatePicker, ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { SPECIFIC_DAYS_CLOSED_FORM } from '../bookings-constants';
import BookingsCTA from '../../../../shared/BookingsCTA';
import SchedulerStateChangedHook from '../../../../shared/stateChangedHook/SchedulerStateChangedHook';
import { DividingLine, GridRow, ErrorMessage } from '../../../../shared/settings-shared-components';
import { compareDateRange } from '../../../../shared/app-utils';
import {
  addSpecificDaysClosed,
  removeSpecificDaysClosed,
  updateSpecificDaysClosed,
  SPECIFIC_DAYS_CLOSED_KEY,
  SPECIFIC_DAYS_CLOSED_SECTION,
  CONVERTED_SPECIFIC_DAYS_CLOSED_KEY,
  START_DATE_KEY,
  END_DATE_KEY,
} from '../data/general-settings-slice';
import { END_DATE_BEFORE_START_DATE } from '../../../../shared/availability/availability-constants'
import SectionCard from '../../../../shared/SectionCard';

const DatePickerGroup = styled('div', { shouldForwardProp: (prop) => prop !== 'hasError'})(({ theme, hasError }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
  },
  '> svg': {
    margin: theme.spacing(1, 0, 0, 2.5),
    cursor: 'pointer',
    marginTop: '1.25rem',
  },
  '& .MuiFormControl-root label': {
    paddingBottom: 0,
  },
  '& .MuiFormControl-root div:first-of-type': {
    marginRight: '4px',
    color: hasError ? theme.palette.error.main : 'inherit',
    borderColor: hasError ? theme.palette.error.main : 'inherit',
  },
}));

const FORM_KEYS = [SPECIFIC_DAYS_CLOSED_KEY];
function SpecificDaysClosedCard() {
  const dispatch = useDispatch();
  const prePopulateDays = useSelector(
    (state) => state
      .generalSettings[SPECIFIC_DAYS_CLOSED_SECTION][CONVERTED_SPECIFIC_DAYS_CLOSED_KEY],
  );

  const schema = yup.object().shape({
    [SPECIFIC_DAYS_CLOSED_KEY]: yup.array().of(yup.object().shape({
      [START_DATE_KEY]: yup.string().required('Start Date is required!'),
      [END_DATE_KEY]: yup.string().required('End Date is required!'),
    })).max(40, 'Cannot add more than 40 days closed'),
    [SPECIFIC_DAYS_CLOSED_KEY]: yup.array().of(yup.object().shape({
      [START_DATE_KEY]: yup.date().default(() => new Date()),
      [END_DATE_KEY]: yup.date(),
    }).test({
      message: END_DATE_BEFORE_START_DATE,
      test: (date) => {
        if (date) {
          return !compareDateRange(date.startDate, date.endDate);
        }
        return true;
      },
    })),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      [SPECIFIC_DAYS_CLOSED_KEY]: prePopulateDays,
    }), [prePopulateDays]),
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: SPECIFIC_DAYS_CLOSED_KEY,
  });

  const { setError, clearErrors, formState: { errors } } = methods;

  const handleOnChange = (value, index, type) => {
    const dateValues = methods.getValues().daysClosed[index];
    const { startDate, endDate } = dateValues;
    const isValidDateRange = compareDateRange(startDate, endDate);

    if (isValidDateRange) {
      setError(`${SPECIFIC_DAYS_CLOSED_KEY}[${index}]`);
    } else clearErrors(`${SPECIFIC_DAYS_CLOSED_KEY}[${index}]`);

    dispatch(updateSpecificDaysClosed({
      index,
      value,
      type,
    }));
  };

  const handleOnRemove = useCallback((index) => {
    remove(index);
    dispatch(removeSpecificDaysClosed({ index }));
  }, [removeSpecificDaysClosed, remove]);

  const handleOnAdd = useCallback(() => {
    const payload = { startDate: new Date(), endDate: new Date() };
    append(payload);
    dispatch(addSpecificDaysClosed());
  }, [addSpecificDaysClosed, append]);

  return (
    <SectionCard
      id="specific-days-closed-card"
      title="Specific Days Closed"
      showDividingLine={false}
      tooltipText="Block out time for holidays, vacations, or any other days during which you do not want to accept appointments."
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form style={{ marginTop: fields.length > 0 ? 0 : '24px' }}>
            <Grid container direction="column">
              <GridRow width={isMobile ? '100%' : '36.3%'}>
                {!!fields.length && <DividingLine allowMobile />}
                {
                  fields.map((item, index) => {
                    const invalidDate = errors?.daysClosed?.[index];
                    return (
                      <div key={item.id}>
                        <DatePickerGroup hasError={invalidDate}>
                          <IQDatePicker
                            id={`${SPECIFIC_DAYS_CLOSED_KEY}${item.id}`}
                            dateFormat="MMM dd, yyyy"
                            name={`${SPECIFIC_DAYS_CLOSED_KEY}.${index}.${START_DATE_KEY}`}
                            onChange={(value) => handleOnChange(value, index, START_DATE_KEY)}
                            disablePast
                            disabled
                            labelText="From"
                          />
                          <IQDatePicker
                            id={`${SPECIFIC_DAYS_CLOSED_KEY}${item.id}`}
                            dateFormat="MMM dd, yyyy"
                            name={`${SPECIFIC_DAYS_CLOSED_KEY}.${index}.${END_DATE_KEY}`}
                            onChange={(value) => handleOnChange(value, index, END_DATE_KEY)}
                            disablePast
                            disabled
                            labelText="To"
                          />
                          <DeleteOutlineIcon onClick={() => handleOnRemove(index)} />
                        </DatePickerGroup>
                        {invalidDate && (
                          <ErrorMessage>{END_DATE_BEFORE_START_DATE}</ErrorMessage>
                        )}
                      </div>
                    );
                  })
                }
                {!!fields.length && <DividingLine allowMobile />}
              </GridRow>
              <GridRow item lg={4} md={6} sm={12} xs={12}>
                <BookingsCTA
                  text="Add Days Closed"
                  onClickHandler={handleOnAdd}
                  startIcon={<AddIcon />}
                />
              </GridRow>
            </Grid>
          </form>
          <SchedulerStateChangedHook
            formName={SPECIFIC_DAYS_CLOSED_FORM}
            formKeys={FORM_KEYS}
          />
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  );
}

export default SpecificDaysClosedCard;
