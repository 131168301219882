import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { IQLabelTooltip, IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import {
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import WidgetStatusRow from '../components/widgetStatus/WidgetStatusRow';
import LoadingSkeletonWidgetStatus from '../components/loadingSkeletons/widgetStatus/LoadingSkeletonWidgetStatus';
import { setCurrentScreen } from '../../../shared/navigation/navigation-slice';
import { WIDGET_SETTINGS_PATH } from '../../../shared/app-constants';
import { trackDashboardCardClick } from '../../../shared/analytics-utils';
import { LOCALIQ_CODE_PAGE, ENGAGEMENT_WIDGET_PAGE, WIDGET_SETTINGS_STEPS } from '../../../shared/navigation/navigation-config';

const WidgetStatusContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  background: theme.palette.common.white,
  '& .MuiGrid-item': {
    border: `none`,
  },
}));

const StyledGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== 'isMobile' })(({ theme, isMobile }) => ({
  margin: isMobile ? theme.spacing(2, 3) : theme.spacing(4, 5),
}));

const StyledWidgetStatus = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isMobile' })(({ isMobile }) => ({
  fontSize: isMobile ? '20px' : '24px',
  fontWeight: 600,
  lineHeight: '30px',
}));

export default function WidgetStatusCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schedulingIsActive = useSelector((state) => state.bookings.toggle.isActive);
  const chatbotIsActive = useSelector((state) => state.chatbot.toggle.isActive);
  const callSettingsIsActive = useSelector((state) => state.callSettings.isWidgetActive);
  const contactFormIsActive = useSelector((state) => state.contactForm.toggle.isActive);
  const { isInstalled, isLoading } = useSelector(
    (state) => state.appSettings.trackingCode,
  );

  const activateWidgetPath = `${WIDGET_SETTINGS_PATH}/${WIDGET_SETTINGS_STEPS[ENGAGEMENT_WIDGET_PAGE].url}`;
  const activatelocalIQCodePath = `${WIDGET_SETTINGS_PATH}/${WIDGET_SETTINGS_STEPS[LOCALIQ_CODE_PAGE].url}`;

  const widgetStatus = (
    schedulingIsActive || chatbotIsActive || callSettingsIsActive || contactFormIsActive
  );
  const localIQCodeStatus = isInstalled;

  // set the currentScreen in redux store and then navigate
  const onLinkClickHandler = (navKey) => {
    dispatch(setCurrentScreen({ currentScreen: navKey }));
    if (navKey === LOCALIQ_CODE_PAGE) {
      trackDashboardCardClick('Widget Status', 'See all Feature Settings');
      navigate(activatelocalIQCodePath);
    } else if (navKey === ENGAGEMENT_WIDGET_PAGE) {
      trackDashboardCardClick('Widget Status', 'Copy LocaliQ Code');
      navigate(activateWidgetPath);
    }
  };

  if (isLoading) {
    return (
      <WidgetStatusContainer container>
        <LoadingSkeletonWidgetStatus />
      </WidgetStatusContainer>
    );
  }

  return (
    <WidgetStatusContainer container>
      {isLoading
        ? (
          <SpinnerWrapper>
            <IQLoadingSpinner />
          </SpinnerWrapper>
        )
        : (
          <StyledGrid container item direction="column" gap="29px" isMobile={isMobile}>
            <Grid container item alignItems="center" gap="8px">
              <StyledWidgetStatus isMobile={isMobile}>Widget Status</StyledWidgetStatus>
              <IQLabelTooltip
                tooltipText="On/off status of your features; Scheduling, Chatbot, Click-to-Call, and Contact Form."
                required={false}
              />
            </Grid>
            <WidgetStatusRow
              navKey={ENGAGEMENT_WIDGET_PAGE}
              rowTitle="Widget Status"
              linkLabel="See all Feature Settings"
              status={widgetStatus}
              clickHandler={onLinkClickHandler}
            />
            <WidgetStatusRow
              navKey={LOCALIQ_CODE_PAGE}
              rowTitle="LocaliQ Code Status"
              linkLabel="Copy LocaliQ Code"
              status={localIQCodeStatus}
              clickHandler={onLinkClickHandler}
            />
          </StyledGrid>
        )}
    </WidgetStatusContainer>
  );
}
