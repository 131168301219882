import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import SchedulerLinkItem from "../../../../layouts/navBars/SchedulerLinkItem";
import SectionCard from "../../../../shared/SectionCard";
import { SCHEDULER_NAME } from "../bookings-constants";

export default function SchedulingLink() {
  const links = useSelector((state) => state.bookings.links);
  const { main, appointmentTypes } = links;

  return (
    <SectionCard
      id="scheduling-link-card"
      title="Your Scheduling Links"
      tooltipText="Accept appointments by sharing these links directly with customers or through other channels."
      showDividingLine={false}
    >
      {main && (
        <div style={{ marginTop: '8px' }}>
          <SchedulerLinkItem
            title={`Main ${SCHEDULER_NAME} Page`}
            url={main?.url}
          />
          <Divider />
        </div>
      )}
      {appointmentTypes?.map(({ identifier, name, url }, i) => {
        const lastApptType = (i === appointmentTypes.length - 1);
        return (
          <>
          <SchedulerLinkItem
            key={identifier}
            title={name}
            url={url}
          />
          {!lastApptType && <Divider />}
        </>
        )
      })}
    </SectionCard>
  )
}