import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ACTIVE_SUBSCRIPTION_COLOR, ACTIVE_SUBSCRIPTION_STATUS, CANCELED_SUBSCRIPTION_STATUS, CANCELED_SUBSCRIPTION_COLOR, CANCELS_ON_TEXT, INTERVAL_DISPLAY_NAME_MAP, SUBSCRIPTION_TO_ICON_MAP, NEXT_PAYMENT_TEXT } from '../../account-constants';
import { loadChangePaymentCards, toggleChangePaymentModal, toggleCancelSubscriptionModal } from '../../data/subscriptions-slice';
import { ReactComponent as BundleIcon } from '../../assets/bundle-sub-icon.svg';
import { ReactComponent as ChangeCreditCardIcon } from '../../assets/change-credit-card-icon.svg';
import { ReactComponent as CancelSubscriptionIcon } from '../../assets/cancel-subscription.svg';
import { formatNextPaymentTimestampShort } from '../../account-utils';
import { Bolded, CardBadge } from '../../../../../shared/settings-shared-components';
import { trackChangeCreditCard, trackInProductCancellationSubscription } from '../../../../../shared/analytics-utils';

const SubscriptionCardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: theme.spacing(1),
  '> .MuiPaper-root': {
    position: 'relative',
  },
}));

const SubscriptionCardHeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  }
}));

const SubscriptionCardBadgerWrapper = styled(CardBadge)(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   alignSelf: 'flex-start',
   [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
  }
}));

const SubscriptionCardHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  '> svg': {
    width: 30,
    height: 30,
  },
  '> h4': {
    fontSize: '24px',
    lineHeight: '30px',
  },

  [theme.breakpoints.down('sm')]: {
    '> h4': {
      fontSize: '20px',
    },
  }
}));

const SubscriptionCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 7.5),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: theme.spacing(0, 2.5),
    gap: theme.spacing(3),
  }
}));

const PriceInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }
}));

const PriceInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(0),
  }
}));

const PaymentInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  '> svg': {
    marginTop: theme.spacing(0.5),
  }
}));

const CreditCardInfoWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const NextPaymentInfoWrapper = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(4),
}));

const LinksWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ChangeCreditCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  '.change-cc-btn': {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: `underline ${theme.palette.primary.main}`,

    '&:hover': {
      textDecoration: 'none',
    }
  }
}));

const CancelSubscriptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  '.cancel-sub-btn': {
    color: '#F03C4B',
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: `underline #F03C4B`,

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default function SubscriptionCard({ 
  id,
  subType,
  title,
  totalPrice,
  cardBrand,
  lastFourDigits,
  expMonth,
  expYear,
  paymentMethodId,
  nextPaymentDateTimestamp,
  interval,
  cancelAt,
  isCancelled,
  cancellable,
  eligibleForDiscount,
  isDiscount,
  discountDescription,
}) {
  const dispatch = useDispatch();
  const isActive = !isCancelled;
  const willBeCanceled = !!cancelAt;
  const nextPaymentDateDisplayText = willBeCanceled ? CANCELS_ON_TEXT : NEXT_PAYMENT_TEXT;


  const cardBrandDisplayName = useMemo(() => {
    if (cardBrand) {
      return `${cardBrand[0].toUpperCase()}${cardBrand.slice(1)}`
    }
    
    return '';
  }, [cardBrand]);
  
  // Formatting for next payment date should be for example March 23rd, 2023
  const nextPaymentDateDisplay = useMemo(() => {

    if (willBeCanceled) {
      return formatNextPaymentTimestampShort(cancelAt);
    }

    if (nextPaymentDateTimestamp) {
      return formatNextPaymentTimestampShort(nextPaymentDateTimestamp);
    }

    return '';
  }, [nextPaymentDateTimestamp, willBeCanceled]);

  // Formatting for expiration date should be MM/YY . Example: 03/23 , 12/23
  const expMonthDisplay = useMemo(() => {
    if (expMonth) {
      return expMonth.padStart(2, '0');
    }

    return '';
  }, [expMonth]);

  const expYearDisplay = useMemo(() => {
    if (expYear) {
      return expYear.slice(2);
    }

    return '';
  }, [expYear]);

  const totalPriceDisplay = useMemo(() => {
    if (totalPrice) {
      return totalPrice.toFixed(2);
    }

    return 0;
  }, [totalPrice]);

  const SubIcon = SUBSCRIPTION_TO_ICON_MAP[subType] 
    ? SUBSCRIPTION_TO_ICON_MAP[subType]
    : <BundleIcon />;
  const statusColor = isActive ? ACTIVE_SUBSCRIPTION_COLOR : CANCELED_SUBSCRIPTION_COLOR;
  const statusText = isActive ? ACTIVE_SUBSCRIPTION_STATUS : CANCELED_SUBSCRIPTION_STATUS;

  const getSubscriptionType = () => {
    let type = '';
    if (title.toLowerCase().includes('toolkit')) {
      type = 'toolkit';
    } else if (title.toLowerCase().includes('listings')) {
      type = 'listings';
    }

    return type;
  };

  const openChangePaymentModal = () => {
    trackChangeCreditCard();
    dispatch(
      toggleChangePaymentModal({
        subscriptionId: id,
        paymentMethodId,
      }),
    );
    dispatch(
      loadChangePaymentCards(),
    );
  };

  const openCancelSubscriptionModal = () => {
    getSubscriptionType();
    trackInProductCancellationSubscription();
    dispatch(toggleCancelSubscriptionModal({
      subscriptionId: id,
      isEligibleForDiscount: eligibleForDiscount,
      type: getSubscriptionType(),
    }));
  };

  return (
    <SubscriptionCardWrapper>
      <CardHeader title={(
        <SubscriptionCardHeaderContent>
          <SubscriptionCardHeaderWrapper>
            {SubIcon}
            <Typography variant='h4'>{title}</Typography>
          </SubscriptionCardHeaderWrapper>
          <SubscriptionCardBadgerWrapper color={statusColor}>
            <div className="dot"></div>
            {statusText}
          </SubscriptionCardBadgerWrapper>
        </SubscriptionCardHeaderContent>
      )} />
      <SubscriptionCardContent>
        <PriceInfoWrapper>
          <PriceInfo>
            <Typography variant='body1'><Bolded>${totalPriceDisplay}/{interval}</Bolded></Typography>
            <Typography variant='body1'>Billed {INTERVAL_DISPLAY_NAME_MAP[interval]}</Typography>
          </PriceInfo>
          { isActive && isDiscount && (
            <SubscriptionCardBadgerWrapper color={statusColor}>
              <div className="dot"></div>
              {discountDescription.toUpperCase()}
            </SubscriptionCardBadgerWrapper>
          )}
        </PriceInfoWrapper>
        <PaymentInfoWrapper>
          <CreditCardInfoWrapper variant='body1'>
            <CreditCardIcon />
            <Bolded>{cardBrandDisplayName}</Bolded> ending in **** {lastFourDigits}
            {!isMobile &&  <><Bolded>Expires</Bolded>{expMonthDisplay}/{expYearDisplay}</>}
          </CreditCardInfoWrapper>
          {isMobile && (
            <NextPaymentInfoWrapper variant='body1'>
              <Bolded>Expires</Bolded> {expMonthDisplay}/{expYearDisplay}
            </NextPaymentInfoWrapper>
          )}
          {
            nextPaymentDateDisplay && (
              <NextPaymentInfoWrapper variant='body1'>
                <Bolded>{nextPaymentDateDisplayText}</Bolded> on {nextPaymentDateDisplay}
              </NextPaymentInfoWrapper>
            )
          }
        </PaymentInfoWrapper>
        <LinksWrapper>
          {
            isActive && (
              <ChangeCreditCardWrapper onClick={openChangePaymentModal}>
                <ChangeCreditCardIcon />
                <Typography className="change-cc-btn" variant="body1">Change Credit Card</Typography>
              </ChangeCreditCardWrapper>
            )
          }
          {cancellable && (
            <CancelSubscriptionWrapper onClick={openCancelSubscriptionModal}>
              <CancelSubscriptionIcon />
              <Typography className="cancel-sub-btn" variant="body1">Cancel Subscription</Typography>
            </CancelSubscriptionWrapper>
          )}
        </LinksWrapper>
      </SubscriptionCardContent>
    </SubscriptionCardWrapper>
  );
}

SubscriptionCard.propTypes = {
  id: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalPrice: PropTypes.number.isRequired,
  cardBrand: PropTypes.string.isRequired,
  lastFourDigits: PropTypes.string.isRequired,
  expMonth: PropTypes.string.isRequired,
  expYear: PropTypes.string.isRequired,
  paymentMethodId: PropTypes.string.isRequired,
  nextPaymentDateTimestamp: PropTypes.string,
  interval: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cancelAt: PropTypes.number,
  canceledAt: PropTypes.number,
  isCancelled: PropTypes.bool,
  cancellable: PropTypes.bool,
  eligibleForDiscount: PropTypes.bool,
  isDiscount: PropTypes.bool,
  discountDescription: PropTypes.string,
};
