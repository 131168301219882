import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { determineScoreObject } from '../../grader-utils';
import { ReactComponent as SmallLeaf } from '../../assets/small-leaf.svg';
import { ReactComponent as MediumLeaf } from '../../assets/medium-leaf.svg';
import { ReactComponent as LargeLeaf } from '../../assets/large-leaf.svg';
import { ScoreWrapper } from './styles/grader-section-styles';

/**
 * Look up icon by score
 * @param {number} score
 */
const determineIcon = (score) => {
  if (score > 66) {
    return LargeLeaf;
  }
  if (score > 33) {
    return MediumLeaf;
  }
  return SmallLeaf;
};

/**
 * Displays the score badge based on the given level
 */
export default function ScoreBadge({ score }) {
  const { color, level } = determineScoreObject(score);
  const LeafIcon = determineIcon(score);
  return (
    <ScoreWrapper scoreColor={color}>
      <div className="blockWrapper">
        <div className="badgeCircle" />
        <div className="badgeImageWrapper">
          <LeafIcon />
        </div>
        <div className="badgeTextWrapper">
          <Typography className="badgeText">{level}</Typography>
        </div>
      </div>
    </ScoreWrapper>
  );
}

ScoreBadge.propTypes = {
  score: PropTypes.number,
};
