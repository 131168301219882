import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import {
  getCompetitorScoreSuccess,
  getGraderScoreSuccess,
  loadInitialGraders,
  requestCompetitorScore,
  requestCompetitorScoreFailure,
  requestNewGrader, requestNewGraderFailure, requestNewGraderSuccess,
  requestNewGraderLanding, requestNewGraderLandingFailure,
  requestNewGraderLandingSuccess, loadInitialGradersSuccess,
  loadInitialGradersFailure, competitorInProgress,
  mainInProgress,
} from './grader-slice';
import {
  COMPETITOR, COMPLETE_GRADER, EXTERNAL, IN_PRODUCT, MAIN, RUNNING_GRADER,
} from './grader-constants';
import { emptyAction } from '../../../../shared/app-slice';

const {
  REACT_APP_GATEWAY_EXT_ROOT,
} = process.env;

const loadLatestGradersEpic = (action$) => action$.pipe(
  filter(loadInitialGraders.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/grader/scores/latest?email=${action.payload.email}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    mergeMap((response) => {
      const { main, competitor } = response.response;
      if (main.status === RUNNING_GRADER) {
        // If we are still running, just confirm we checked and wait for websocket
        return of(mainInProgress());
      }
      const actionList = [
        getGraderScoreSuccess(main),
      ];
      if (competitor && competitor.status === COMPLETE_GRADER) {
        actionList.push(getCompetitorScoreSuccess(competitor));
      } else if (competitor && competitor.status === RUNNING_GRADER) {
        // Flag that we have an in progress competitor and let websocket handle the response
        actionList.push(competitorInProgress());
      }
      return actionList;
    }),
    catchError((error) => (
      of(loadInitialGradersFailure(error))
    )),
  )),
);

const requestNewGraderEpic = (action$, state$) => action$.pipe(
  filter(requestNewGrader.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/grader/request`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        email: state$.value.appSettings.user.email,
        websiteUrl: action.payload.websiteUrl,
        type: MAIN,
        source: IN_PRODUCT,
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => requestNewGraderSuccess(response.response)),
    catchError((error) => (
      of(requestNewGraderFailure(error))
    )),
  )),
);

const requestNewGraderLandingEpic = (action$) => action$.pipe(
  filter(requestNewGraderLanding.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/grader/request`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        email: action.payload.email,
        websiteUrl: action.payload.websiteUrl,
        type: MAIN,
        source: EXTERNAL,
      },
      crossDomain: true,
      withCredentials: false,
    },
  ).pipe(
    map((response) => requestNewGraderLandingSuccess(response.response)),
    catchError((error) => (
      of(requestNewGraderLandingFailure(error))
    )),
  )),
);

const requestCompetitorScoreEpic = (action$, state$) => action$.pipe(
  filter(requestCompetitorScore.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/grader/request`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        email: state$.value.appSettings.user.email,
        websiteUrl: action.payload.websiteUrl,
        type: COMPETITOR,
        source: IN_PRODUCT,
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    // Let websocket handle "success" by message
    map(() => emptyAction()),
    catchError((error) => (
      of(requestCompetitorScoreFailure(error))
    )),
  )),
);

export default [
  requestNewGraderEpic,
  requestCompetitorScoreEpic,
  requestNewGraderLandingEpic,
  loadLatestGradersEpic,
];
