import styled from '@emotion/styled';
import Card from './Card';
import SkeletonText from '../SkeletonText';

const StyledSectionTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  maxWidth: '581px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'unset',
    justifyContent: 'unset',
    gap: theme.spacing(0),
    maxWidth: 'unset',
  },
}));

const StyledCardsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: theme.spacing(3),
  margin: theme.spacing(5, 0, 0, 0),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

export default function LoadingSkeletonGetTheMostCards() {
  return (
    <div>
      <StyledSectionTitleWrapper>
        <SkeletonText animation="wave" variant="string" width="100%" height={30} />
      </StyledSectionTitleWrapper>
      <StyledCardsWrapper>
        <Card />
        <Card />
        <Card />
      </StyledCardsWrapper>
    </div>
  );
}
