import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  BUNDLE_ICON_KEY,
  CHANGE_PAYMENT_FAILURE_TEXT,
  CHANGE_PAYMENT_SUCCESS,
  INCOMPLETE_SUBSCRIPTION_STATUS,
  LOAD_SUBSCRIPTIONS_FAILED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
  ACCEPT_SALVAGE_OFFER_SUCCESS,
  CANCELED_SUBSCRIPTION_STATUS,
} from '../account-constants';
import { createSubscriptionTitle } from '../account-utils';
import { trackInProductCancellationFinished, trackInProductCancellationSalvaged } from '../../../../shared/analytics-utils';

export const loadSubscriptionsAction = createAction('subscriptions/loadSubscriptions');
export const loadSubscriptionsFailureAction = createAction('subscriptions/loadSubscriptionsFailure');
export const loadChangePaymentCardsAction = createAction('subscriptions/loadChangePaymentCards');
export const saveChangePaymentAction = createAction('subscriptions/saveChangePayment');
export const triggerCancelSubscriptionAction = createAction('subscriptions/triggerCancelSubscription');
export const triggerAcceptSalvageOfferAction = createAction('subscriptions/triggerAcceptSalvageOffer');

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    isLoading: false,
    list: [],
    bannerType: '',
    cancelledSubType: '', // toolkit || listings
    subCanceledAtTimestamp: null,
    endOfBillingDateTimestamp: null,
    changePaymentModal: {
      isOpen: false,
      isLoading: false,
      isSaving: false,
      cards: [],
      selectedSubscriptionId: '',
      currentPaymentMethodId: '',
      stripeErrorMsg: '',
    },
    cancelSubscriptionModal: {
      isSubscriptionModalOpen: false,
      isOfferModalOpen: false,
      reasonToCancel: '',
      otherReasonToCancel: '',
      selectedSubscriptionId: '',
      isEligibleForDiscount: false,
      isSkipReasons: false,
      cardType: '',
      type: '', // toolkit || listings
    },
  },
  reducers: {
    loadSubscriptions: (state) => {
      state.isLoading = true;
    },
    loadSubscriptionsSuccess: (state, action) => {
      state.isLoading = false;
      state.cancelSubscriptionModal = {
        ...state.cancelSubscriptionModal,
        isSubscriptionModalOpen: false,
        isOfferModalOpen: false,
        isEligibleForDiscount: false,
        reasonToCancel: '',
        otherReasonToCancel: '',
        selectedSubscriptionId: '',
        isSkipReasons: false,
        type: '',
      };

      if (action?.payload?.orders) {
        const { orders } = action.payload;
        state.list = orders
          .filter((order) => order.subscription
            && order?.subscription?.status !== INCOMPLETE_SUBSCRIPTION_STATUS)
          .map((order) => {
            let paymentMethod = {};
            if (order?.subscription?.canceledAt) {
              state.subCanceledAtTimestamp = order?.subscription?.canceledAt;
            }
            if (order?.subscription?.cancelAt) {
              state.endOfBillingDateTimestamp = order?.subscription?.cancelAt;
            }
            if (order.paymentMethods?.length > 0) {
              paymentMethod = order?.paymentMethods
                .find((pm) => pm?.paymentMethodId === order?.subscription?.defaultPaymentMethodId);
            }
            // If we have an upcoming invoice use that, we should show the "next" invoice price.
            // If for some reason the upcoming invoice is missing (cancelled subscription etc.)
            // then use the latest invoice to still show the last price
            const subscriptionPrice = order?.subscription.upcomingInvoice
              ? order.subscription.upcomingInvoice.amount : order?.subscription?.invoice?.amount;

            const isCancelled = order?.subscription?.cancelAtPeriodEnd
              || order?.subscription?.status === CANCELED_SUBSCRIPTION_STATUS;
            return {
              id: order?.subscription?.subscriptionId,
              subType: !!order?.bundleSlug ? BUNDLE_ICON_KEY : order?.items[0]?.productType?.slug,
              title: createSubscriptionTitle(order?.items, order?.bundleSlug),
              totalPrice: subscriptionPrice / 100,
              cardBrand: paymentMethod?.brand,
              lastFourDigits: paymentMethod?.last4,
              expMonth: paymentMethod?.expMonth,
              expYear: paymentMethod?.expYear,
              paymentMethodId: paymentMethod?.paymentMethodId,
              nextPaymentDateTimestamp: order?.subscription?.upcomingInvoice?.created,
              interval: Array.isArray(order?.subscription?.items) ? order?.subscription?.items[0]?.price?.interval : '',
              status: order?.subscription?.status,
              cancelAt: order?.subscription?.cancelAt,
              canceledAt: order?.subscription?.canceledAt,
              isCancelled,
              cancellable: order?.subscription?.cancellable,
              eligibleForDiscount: order?.subscription?.eligibleForDiscount || false,
              isDiscount: !!order?.subscription?.discount,
              discountDescription: order?.subscription?.discountDescription || '',
            };
          });
      }
    },
    loadSubscriptionsFailure: (state) => {
      state.isLoading = false;
      state.bannerType = LOAD_SUBSCRIPTIONS_FAILED;
    },
    toggleChangePaymentModal: (state, action) => {
      if (action?.payload?.subscriptionId && action?.payload?.paymentMethodId) {
        state.changePaymentModal.selectedSubscriptionId = action.payload.subscriptionId;
        state.changePaymentModal.currentPaymentMethodId = action.payload.paymentMethodId;
      }

      if (state.changePaymentModal.stripeErrorMsg) {
        state.changePaymentModal.stripeErrorMsg = '';
      }

      state.changePaymentModal.isOpen = !state.changePaymentModal.isOpen;
    },
    loadChangePaymentCards: (state) => {
      state.changePaymentModal.isLoading = true;
    },
    loadChangePaymentCardsSuccess: (state, action) => {
      const cards = action.payload;
      // Currently used card should be placed first
      const usedCardIndex = cards
        .findIndex((cc) => cc.paymentMethodId === state.changePaymentModal.currentPaymentMethodId);
      if (usedCardIndex !== -1) {
        const usedCard = cards.splice(usedCardIndex, 1)[0];
        cards.unshift(usedCard);
      }
      state.changePaymentModal.cards = cards;
      state.changePaymentModal.isLoading = false;
    },
    loadChangePaymentCardsFailure: (state) => {
      state.changePaymentModal.isLoading = false;
    },
    saveChangePayment: (state) => {
      state.changePaymentModal.isSaving = true;
      state.changePaymentModal.stripeErrorMsg = '';
      state.bannerType = '';
    },
    saveChangePaymentSuccess: (state) => {
      state.changePaymentModal.isSaving = false;
      state.changePaymentModal.isOpen = false;
      state.bannerType = CHANGE_PAYMENT_SUCCESS;
    },
    saveChangePaymentFailure: (state) => {
      state.changePaymentModal.isSaving = false;
      state.changePaymentModal.stripeErrorMsg = CHANGE_PAYMENT_FAILURE_TEXT;
    },
    resetActionBanner: (state) => {
      state.bannerType = '';
    },
    toggleCancelSubscriptionModal: (state, action) => {
      const subscriptionId = action?.payload?.subscriptionId;
      const isEligibleForDiscount = action?.payload?.isEligibleForDiscount;

      state.cancelSubscriptionModal.isSubscriptionModalOpen = (
        !state.cancelSubscriptionModal.isSubscriptionModalOpen
      );
      state.cancelSubscriptionModal.type = action?.payload?.type || '';
      state.cancelSubscriptionModal.isOfferModalOpen = false;
      state.cancelSubscriptionModal.selectedSubscriptionId = subscriptionId || '';
      state.cancelSubscriptionModal.isEligibleForDiscount = isEligibleForDiscount || false;
    },
    toggleCancellationSalvageOfferModal: (state) => {
      state.cancelSubscriptionModal.isOfferModalOpen = (
        !state.cancelSubscriptionModal.isOfferModalOpen
      );
      state.cancelSubscriptionModal.isSubscriptionModalOpen = false;
    },
    setReasonToCancel: (state, action) => {
      state.cancelSubscriptionModal.reasonToCancel = action.payload.reason;
      state.cancelSubscriptionModal.otherReasonToCancel = '';
    },
    setOtherReasonToCancel: (state, action) => {
      state.cancelSubscriptionModal.otherReasonToCancel = action.payload.otherReason;
    },
    resetCancelSubscriptionModalData: (state) => {
      state.cancelSubscriptionModal.reasonToCancel = '';
      state.cancelSubscriptionModal.otherReasonToCancel = '';
      state.cancelSubscriptionModal.selectedSubscriptionId = '';
      state.cancelSubscriptionModal.isEligibleForDiscount = false;
      state.cancelSubscriptionModal.isSkipReasons = false;
    },
    triggerCancelSubscription: (state, action) => {
      state.cancelSubscriptionModal.cardType = action.payload.cardType;
    },
    cancelSubscriptionSuccess: (state) => {
      state.cancelledSubType = state.cancelSubscriptionModal.type;
      state.bannerType = CANCEL_SUBSCRIPTION_SUCCESS;
      trackInProductCancellationFinished(state.cancelSubscriptionModal.cardType);
      state.cancelSubscriptionModal.cardType = '';
    },
    cancelSubscriptionFailure: (state) => {
      state.bannerType = CANCEL_SUBSCRIPTION_FAILED;
      state.cancelSubscriptionModal = {
        ...state.cancelSubscriptionModal,
        isSubscriptionModalOpen: false,
        isOfferModalOpen: false,
        isEligibleForDiscount: false,
        reasonToCancel: '',
        otherReasonToCancel: '',
        selectedSubscriptionId: '',
        isSkipReasons: false,
        cardType: '',
      };
    },
    setSkipReasons: (state) => {
      state.cancelSubscriptionModal.isSkipReasons = true;
    },
    triggerAcceptSalvageOffer: (state, action) => {
      state.cancelSubscriptionModal.cardType = action.payload.cardType;
    },
    acceptSalvageOfferSuccess: (state) => {
      state.bannerType = ACCEPT_SALVAGE_OFFER_SUCCESS;
      trackInProductCancellationSalvaged(state.cancelSubscriptionModal.cardType);
      state.cancelSubscriptionModal.cardType = '';
    },
    acceptSalvageOfferFailure: (state) => {
      state.bannerType = CANCEL_SUBSCRIPTION_FAILED;
      state.cancelSubscriptionModal = {
        ...state.cancelSubscriptionModal,
        isSubscriptionModalOpen: false,
        isOfferModalOpen: false,
        isEligibleForDiscount: false,
        reasonToCancel: '',
        otherReasonToCancel: '',
        selectedSubscriptionId: '',
        isSkipReasons: false,
        cardType: '',
      };
    },

  },
  extraReducers: (_builder) => {}
});

export const {
  loadSubscriptions,
  loadSubscriptionsSuccess,
  loadSubscriptionsFailure,
  toggleChangePaymentModal,
  toggleCancelSubscriptionModal,
  toggleCancellationSalvageOfferModal,
  loadChangePaymentCards,
  loadChangePaymentCardsSuccess,
  loadChangePaymentCardsFailure,
  saveChangePayment,
  saveChangePaymentSuccess,
  saveChangePaymentFailure,
  resetActionBanner,
  setReasonToCancel,
  setOtherReasonToCancel,
  resetCancelSubscriptionModalData,
  triggerCancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  triggerAcceptSalvageOffer,
  acceptSalvageOfferSuccess,
  acceptSalvageOfferFailure,
  setSkipReasons,
} = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
