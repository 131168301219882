import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationProvider, IQCheckbox } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import SectionCard from '../../../../../shared/SectionCard';
import { NOTIFICATIONS_SETTINGS_SECTION, SMS_NOTIFICATIONS_SECTION, updateSmsDashNotifications } from '../../data/notifications-slice';
import { SMS_DASH_FORM, SMS, SMS_PHONE_NUMBER_KEY, SMS_UNANSWERED_NOTIFICATIONS, SMS_MESSAGES_DASH, SMS_LEAD_NOTIFICATIONS_SMS, EMAIL, SMS_LEAD_NOTIFICATIONS_EMAIL } from '../../settings-constants';
import { GridRow } from '../../../../../shared/settings-shared-components';
import { isValidUSPhoneNumberLength } from '../../../../../shared/validation-utils';
import WidgetSettingsStateChangedHook from '../../../../../shared/stateChangedHook/WidgetSettingsStateChangedHook';

const CommunicationTitle = styled(Typography)(() => ({
  fontFamily: 'Unify Sans Semibold',
  fontSize: 18,
  lineHeight: '27px'
}));

const DescriptionWrapper = styled('div')(({ theme }) => ({
  width: '70%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: '12px'
  }
}));

const CheckboxWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const GridWrapper = styled(GridRow)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    flexDirection: 'column'
  }
}));

const FORM_KEYS = [
  SMS_MESSAGES_DASH,
  SMS_LEAD_NOTIFICATIONS_SMS,
  SMS_LEAD_NOTIFICATIONS_EMAIL,
  SMS_UNANSWERED_NOTIFICATIONS,
];

export default function SMSNotificationDashCard() {
  const dispatch = useDispatch();
  const smsNewMessage = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][SMS_MESSAGES_DASH]);
  const smsUnansweredNotifications = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][SMS_UNANSWERED_NOTIFICATIONS]);
  const smsLeadNotificationsSms = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_SMS]);
  const smsLeadNotificationsEmail = useSelector((state) => state.settings.notifications[SMS_NOTIFICATIONS_SECTION][SMS_LEAD_NOTIFICATIONS_EMAIL]);
  const smsPhones = useSelector((state) => state.settings.notifications[NOTIFICATIONS_SETTINGS_SECTION][SMS_PHONE_NUMBER_KEY]);
  const smsPhone = smsPhones[0]?.phone || '';

  const schema = yup.object().shape({
    [SMS_MESSAGES_DASH]: yup.boolean(),
    [SMS_LEAD_NOTIFICATIONS_SMS]: yup.boolean(),
    [SMS_LEAD_NOTIFICATIONS_EMAIL]: yup.boolean(),
    [SMS_UNANSWERED_NOTIFICATIONS]: yup.boolean(),
  }).test(({
    message: 'Phone number missing',
    test: (val) => {
      if (val[SMS_MESSAGES_DASH] || val[SMS_LEAD_NOTIFICATIONS_SMS] || val[SMS_UNANSWERED_NOTIFICATIONS]) {
        return smsPhone && isValidUSPhoneNumberLength(smsPhone);
      }
      return true;
    },
  }));

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      [SMS_MESSAGES_DASH]: smsNewMessage,
      [SMS_LEAD_NOTIFICATIONS_SMS]: smsLeadNotificationsSms,
      [SMS_LEAD_NOTIFICATIONS_EMAIL]: smsLeadNotificationsEmail,
      [SMS_UNANSWERED_NOTIFICATIONS]: smsUnansweredNotifications,
    }
  });

  const { trigger } = methods;

  const handleInputOnChange = (event) => {
    const { value, name } = event.target;
    const isSelected = value !== 'true';

    dispatch(
      updateSmsDashNotifications({ isSelected, value, fieldName: name })
    )
  };

  useEffect(() => {
    trigger();
  }, [smsPhone]);

  return (
    <SectionCard
      id='sms-notifications-support'
      title='SMS Notifications for SMS (Dash)'
      description="Select if your advertiser would like to receive SMS notifications for new SMS leads."
      showDividingLine={false}>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form style={{ marginTop: '24px' }}>
            <Grid container spacing={2}>
            <GridWrapper item>
                <DescriptionWrapper>
                  <CommunicationTitle>New SMS Message</CommunicationTitle>
                  <Typography> Receive real-time notifications for every message received within a conversation. </Typography>
                </DescriptionWrapper>
                <CheckboxWrapper>
                  <IQCheckbox
                    label={SMS}
                    field={SMS_MESSAGES_DASH}
                    onChange={handleInputOnChange}
                  />
                </CheckboxWrapper>
              </GridWrapper>

              <GridWrapper item>
                <DescriptionWrapper>
                  <CommunicationTitle>New SMS Lead Notifications</CommunicationTitle>
                  <Typography>Notification for any new SMS leads.</Typography>
                </DescriptionWrapper>
                <CheckboxWrapper>
                  <IQCheckbox
                    label={EMAIL}
                    field={SMS_LEAD_NOTIFICATIONS_EMAIL}
                    onChange={handleInputOnChange}
                  />
                  <IQCheckbox
                    label={SMS}
                    field={SMS_LEAD_NOTIFICATIONS_SMS}
                    onChange={handleInputOnChange}
                  />
                </CheckboxWrapper>
              </GridWrapper>
              <GridWrapper item>
                <DescriptionWrapper>
                  <CommunicationTitle>Reminder for Unanswered Notifications</CommunicationTitle>
                  <Typography>Notification for any unanswered messages.</Typography>
                </DescriptionWrapper>
                <CheckboxWrapper>
                  <IQCheckbox
                    label={SMS}
                    field={SMS_UNANSWERED_NOTIFICATIONS}
                    onChange={handleInputOnChange}
                  />
                </CheckboxWrapper>
              </GridWrapper>
            </Grid>
            <WidgetSettingsStateChangedHook
              formName={SMS_DASH_FORM}
              formKeys={FORM_KEYS}
              subsectionKey={SMS_NOTIFICATIONS_SECTION}
            />
          </form>
        </FormProvider>
      </ValidationProvider>
    </SectionCard>
  )
}