import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import AnimatedScoreDisplay from './ScoreDisplay/AnimatedScoreDisplay';

const CustomButton = styled(Button)(({ selected, theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.common.white,
  padding: '24px 16px 16px',
  width: '33.333%',
  borderBottom: `8px solid ${theme.palette.common.white}`,
  borderColor: selected ? theme.palette.primary.main : theme.palette.common.white,
  borderRadius: 0,
  transition: 'border-bottom 0.25s ease-in-out 0s, background-color 0.25s ease-in-out 0s',
  color: theme.palette.text.primary,

  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },

  '& .scoreDisplayBox': {
    height: 64,
    width: 64,
    borderWidth: 3,
    borderRadius: '2.5px',

    '& .numerator': {
      fontSize: 36,
    },
    '& .denominator': {
      fontSize: 13,
    },
  },

  '& .scoreWrapper': {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  '& .metrics': {
    width: '100%',
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

/**
 * Clickable "button" that represents the section selector
 */
export default function SectionButton({
  score, isSelected, title, metricCount, onClick,
}) {
  const metricDescription = `${metricCount} Performance Metric${metricCount > 1 ? 's' : ''} Analyzed`;
  return (
    <CustomButton selected={isSelected} onClick={onClick}>
      <Box className="scoreWrapper">
        <AnimatedScoreDisplay score={score} />
        {title}
      </Box>
      <Box className="metrics">
        {metricDescription}
      </Box>
    </CustomButton>
  );
}

SectionButton.propTypes = {
  score: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  title: PropTypes.node,
  metricCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};
