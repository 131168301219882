import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ReactComponent as Blob1 } from './assets/blob1.svg';
import { ReactComponent as Blob2 } from './assets/blob2.svg';
import { ReactComponent as Blob3 } from './assets/blob3.svg';
import { ReactComponent as Blob4 } from './assets/blob4.svg';
import ScoreWidget from './ScoreWidget';

const HeaderWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: 609,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  color: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
  },
}));

const HeaderBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  inset: 0,
  backgroundColor: theme.palette.common.black,

  '& .blur1': {
    position: 'absolute',
    transform: 'translate(-50%, -50%) rotate(10deg) scale(1)',
    transformOrigin: 'center center',
    opacity: 1,
    filter: 'blur(123px)',
    color: 'rgb(0, 70, 255)',
    top: '35%',
    left: '0%',
  },
  '& .blur2': {
    position: 'absolute',
    transform: 'translate(-50%, -50%) rotate(45deg) scale(1.75)',
    transformOrigin: 'center center',
    opacity: 1,
    filter: 'blur(60px)',
    color: 'rgb(255, 100, 55)',
    top: '110%',
    left: '96%',
  },
  '& .blur3': {
    position: 'absolute',
    transform: 'translate(-50%, -50%) rotate(180deg) scale(1)',
    transformOrigin: 'center center',
    opacity: 0.8,
    filter: 'blur(60px)',
    color: 'rgb(0, 70, 255)',
    top: '105%',
    left: '35%',
  },
  '& .blur4': {
    position: 'absolute',
    transform: 'translate(-50%, -50%) rotate(0deg) scale(1)',
    transformOrigin: 'center center',
    opacity: 0.8,
    filter: 'blur(60px)',
    color: 'rgb(255, 100, 55)',
    top: '105%',
    left: '55%',
  },
}));

const HeaderContent = styled('div')(({ theme }) => ({
  maxWidth: 'calc(100% - 144px)',
  position: 'relative',
  zIndex: 2,
  width: '100%',
  filter: 'blur(0px)',
  color: theme.palette.common.white,
  transition: 'filter 0.25s ease-in-out 0s',
  padding: theme.spacing(5, 3, 0),
  [theme.breakpoints.down('md')]: {
    maxWidth: 'none',
    flexGrow: 1,
    minWidth: 0,
    padding: theme.spacing(5, 3, 0),
  },

  '& .title': {
    fontSize: 32,
    fontFamily: 'Unify Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
  },
}));

/**
 * Handle displaying the header area of the grader
 * (blob background, title, score info)
 */
export default function GraderHeader({ score }) {
  return (
    <HeaderWrapper>
      <HeaderBackground>
        <div className="blur1">
          <Blob1 />
        </div>
        <div className="blur2">
          <Blob2 />
        </div>
        <div className="blur3">
          <Blob3 />
        </div>
        <div className="blur4">
          <Blob4 />
        </div>
      </HeaderBackground>
      <HeaderContent>
        <Typography className="title">Website Grader +</Typography>
        <ScoreWidget score={score} />
      </HeaderContent>
    </HeaderWrapper>
  );
}

GraderHeader.propTypes = {
  score: PropTypes.number,
};
