import styled from "@emotion/styled";
import { Button, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IQLoadingSpinner } from "@gannettdigital/shared-react-components";
import ActionCard from "../../../../shared/ActionCard";
import { ActionIcons, ERROR_TYPE, SUCCESS_TYPE } from "../../../../shared/app-constants";
import { generateScriptId } from "../../../../shared/app-utils";
import CopyInput from "../../../../shared/CopyInput";
import LocaliQHelpModal from "../../../../shared/modal/LocaliQHelpModal";
import { ActionDescription, Bolded } from "../../../../shared/settings-shared-components";
import { OTHER_WEBSITE } from "../../../products/account/account-constants";
import { getStepInfo } from "../../../products/account/account-utils";
import OtherCaptureCodeCard from "../../../products/account/components/cards/OtherCaptureCode";
import { resetCaptureEmailSubmitClick } from "../../../products/account/data/account-slice";
import { dismissToggleModal } from "../../data/localiqCode-slice";
import { StepWrapper } from "../components/wizard-shared-components";
import WizardFeatureTitle from '../components/WizardFeatureTitle';
import CodeIcon from '../../../../images/localiq-code-icon.svg';

const DividerLine = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  marginBottom: '24px',
}));

const StepTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontFamily: 'Unify Sans Semibold',
  margin: '5px 0 20px 0',
}));

const StepTwoBody = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  letterSpacing: ".2px",
  margin: '15px 0',
}));

const BoldText = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  fontFamily: 'Unify Sans Semibold',
  padding: '0 4px',
}));

const MainWrapper = styled('div')(({ theme, hasBanner }) => ({
  paddingTop: hasBanner ? '7%' : '13%',
  display: 'flex',
  margin: 'auto',
  width: '70%',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
    margin: 'unset',
    width: '100%',
    flexDirection: 'column',
  },
}));

const LocalIQCodeWrapper = styled(StepWrapper)(() => ({
  alignItems: 'flex-start',
}));

const HelpLink = styled('a')(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Unify Sans Semibold',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: "pointer"
}));

const ActionWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
}));

const CaptureCodeErrorWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  padding: '8px'
}));

const ActionTitle = styled(Typography)(() => ({
  fontSize: 16,
  fontFamily: 'Unify Sans Semibold'
}));

const ActionCardWrapper = styled('div')(({ theme }) => ({
  width: '90%',
  margin: '40px auto 0 auto',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  '& button': {
    color: theme.palette.common.black,
    textDecoration: 'underline'
  },
}));

const SpinnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

let actionStatus;
let actionTitle;
let actionDescription;

export default function LocaliQCodeStep() {
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);
  const [showCaptureBanner, setShowCaptureBanner] = useState(false);
  const [open, setOpen] = useState(false);

  const captureCode = useSelector((state) => state.appSettings.trackingCode.code);
  const isCaptureCodeLoading = useSelector((state) => state.appSettings.trackingCode.isLoading);

  const websiteType = sessionStorage.getItem("websiteType");
  const scriptID = captureCode ? generateScriptId(captureCode) : '';
  const captureCodeText = scriptID ?
    `<script type="text/javascript" src="//cdn.rlets.com/capture_configs${scriptID}" async="async"></script>` : null;
  const stepInfo = getStepInfo(websiteType);
  const isOtherWebsite = websiteType === OTHER_WEBSITE;
  const isDismissModal = useSelector(state => state.configuration.localiQCode.dismissModal)
  const isEmailSent = useSelector(state => state.account.sentEmail);
  const isSubmitClicked = useSelector(state => state.account.scrollSaveBanner);
  const isTrackingCodeActive = useSelector((state) => state.appSettings.trackingCode?.isInstalled);
  const hasNoCaptureCode = !captureCode && !isCaptureCodeLoading;

  const toggleHelpModal = () => {
    setOpen(true)
    dispatch(dismissToggleModal({ dismiss: false }))
  };

  function dismissActionBanner() {
    setShowBanner(false)
  };

  function dismissCaptureBanner() {
    setShowCaptureBanner(false)
  };

  function refreshPage() {
    location.reload();
  };

  function dismissModal() {
    setOpen(false)
    dispatch(dismissToggleModal({ dismiss: true }))
  };

  if (isEmailSent && isEmailSent !== null) {
    actionStatus = SUCCESS_TYPE
    actionTitle = "Email Sent!"
  } else if (!isEmailSent && isEmailSent !== null) {
    actionStatus = ERROR_TYPE
    actionTitle = "ERROR"
    actionDescription = "Something went wrong. Please try again"
  }

  useEffect(() => {
    if (isEmailSent !== null) {
      setShowBanner(true)
    }
  }, [isEmailSent, isSubmitClicked]);

  useEffect(() => {
    dispatch(resetCaptureEmailSubmitClick())
  }, [isSubmitClicked]);

  useEffect(() => {
    setShowBanner(false)
  }, []);

  useEffect(() => {
    if (hasNoCaptureCode) {
      setShowCaptureBanner(true)
    }
  }, [captureCode, isCaptureCodeLoading])

  return (
    <>
      {showBanner && (
        <ActionCardWrapper>
          <ActionCard
            id="localiq-email-banner"
            type={actionStatus}
            icons={ActionIcons}
          >
            <ActionWrapper>
              <ActionTitle> {actionTitle} </ActionTitle>
              <ActionDescription> {actionDescription} </ActionDescription>
            </ActionWrapper>
            <Button onClick={dismissActionBanner}> Dismiss </Button>
          </ActionCard>
        </ActionCardWrapper>
      )}
      {showCaptureBanner && (
        <ActionCardWrapper>
          <ActionCard
            id="capturecode-banner"
            type={ERROR_TYPE}
            icons={ActionIcons}
          >
            <CaptureCodeErrorWrapper>
              <ActionDescription>
                An error occured while trying to generate your capture code. <br />
                Please refresh the page to try again.
              </ActionDescription>
            </CaptureCodeErrorWrapper>
            <ButtonWrapper>
              <Button onClick={refreshPage}> Refresh </Button>
              <Button onClick={dismissCaptureBanner}> Dismiss </Button>
            </ButtonWrapper>
          </ActionCard>
        </ActionCardWrapper>
      )}
      {!isCaptureCodeLoading ? (
        <MainWrapper hasBanner={showBanner || showCaptureBanner}>
          <LocalIQCodeWrapper desktopFlexDirection='column' gapBetweenItems={2}>
            <WizardFeatureTitle
              icon={CodeIcon}
              text='LocaliQ Code'
            />
            <Typography variant='h3' > Custom Code </Typography>
            {isTrackingCodeActive && (<span><br /><Bolded>Already Installed?</Bolded> Click Save & Exit to begin using your widgets</span>)}
            {(!isOtherWebsite && !!stepInfo) ?
              <div style={{ width: '100%' }}>
                <OtherCaptureCodeCard
                  hasSectionCard={false}
                  websiteType={websiteType}
                  captureCodeText={captureCode}
                  stepTitle={stepInfo.stepTitle}
                  stepText={stepInfo.stepText}
                  source="Config Wizard"
                  hasNoCaptureCode={hasNoCaptureCode}
                />
              </div>
              : (
                <div style={{ width: '100%' }}>
                  <StepTitle> 1. Copy this Code</StepTitle>
                  <CopyInput
                    displayText={captureCodeText}
                    websiteType={websiteType}
                    useTextArea
                    height="70px"
                    source="Config Wizard"
                    hasNoCaptureCode={hasNoCaptureCode}
                  />
                  <DividerLine />
                  <StepTitle> 2. Paste it into your website</StepTitle>
                  <StepTwoBody>
                    Paste your LocaliQ Code before the closing
                    <BoldText>&lt;/head&gt;</BoldText>
                    tag.
                  </StepTwoBody>
                </div>
              )}
            <LocaliQHelpModal isOpen={open && !isDismissModal} captureCode={captureCode} dismissModal={dismissModal} />
            <HelpLink onClick={toggleHelpModal} > Need help getting set up ?</HelpLink>
          </LocalIQCodeWrapper>
        </MainWrapper>
      ) : (
        <SpinnerWrapper>
          <IQLoadingSpinner />
        </SpinnerWrapper>
      )}
    </>
  )
}