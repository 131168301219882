import { createSlice } from '@reduxjs/toolkit';
import {
  CALL_TRACKER_SLUG,
  CHATBOT_SLUG, 
  CONTACT_FORM_SLUG, 
  WIDGET_SETTINGS_SLUG, 
  FREE_TIER, 
  PRO_TIER, 
  SCHEDULER_SLUG, 
  TOOLS_KEY,
  SUPPORT_ROLE,
} from '../app-constants';
import { loadSettingsDataSuccess } from '../app-slice';
import { convertProductToNav } from './navigation-config';

const navigationSlice = createSlice({
  name: 'navigation_settings',
  initialState: {
    sectionListByProduct: {},
    currentScreen: null,
  },
  reducers: {
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload.currentScreen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingsDataSuccess, (state, action) => {
      const isSupport = action.payload.user?.role?.includes(SUPPORT_ROLE);
      const navLists = {};
      const { orders } = action.payload.orders;
      if (Array.isArray(orders) && orders.length > 0) {
        const orderedProducts = orders[0].items;
        orderedProducts.forEach((product) => {
          const { productType } = product;
          if (productType) {
            navLists[productType.slug] = convertProductToNav(productType.slug, productType.tier);
          }
        });
        navLists[SCHEDULER_SLUG] = convertProductToNav(SCHEDULER_SLUG, PRO_TIER);
        navLists[CHATBOT_SLUG] = convertProductToNav(CHATBOT_SLUG, PRO_TIER);
        navLists[CALL_TRACKER_SLUG] = convertProductToNav(CALL_TRACKER_SLUG, PRO_TIER);
        navLists[CONTACT_FORM_SLUG] = convertProductToNav(CONTACT_FORM_SLUG, PRO_TIER);
        navLists[WIDGET_SETTINGS_SLUG] = convertProductToNav(WIDGET_SETTINGS_SLUG, PRO_TIER);
        //TODO: BOP-3171 all this will move
        navLists[TOOLS_KEY] = convertProductToNav(TOOLS_KEY, FREE_TIER, isSupport);
        state.sectionListByProduct = navLists;
      } else {
        navLists[SCHEDULER_SLUG] = convertProductToNav(SCHEDULER_SLUG, FREE_TIER);
        navLists[CHATBOT_SLUG] = convertProductToNav(CHATBOT_SLUG, FREE_TIER);
        navLists[CALL_TRACKER_SLUG] = convertProductToNav(CALL_TRACKER_SLUG, FREE_TIER);
        navLists[CONTACT_FORM_SLUG] = convertProductToNav(CONTACT_FORM_SLUG, FREE_TIER);
        navLists[WIDGET_SETTINGS_SLUG] = convertProductToNav(WIDGET_SETTINGS_SLUG, PRO_TIER);
        navLists[TOOLS_KEY] = convertProductToNav(TOOLS_KEY, FREE_TIER, isSupport);
        //TODO: BOP-3171 all this will move
        state.sectionListByProduct = navLists;
      }
    });
  },
});

export const {
  setCurrentScreen,
} = navigationSlice.actions;
export default navigationSlice.reducer;
