import { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SocketContext } from "../websocket/WebSocketProvider";
import { checkWizardMessage } from "../websocket/websocket-utils";
import { setAfterWizardComplete as activateScheduling } from "../../pages/products/bookings/data/bookings-slice";
import { setAfterWizardComplete as activateCallSettings } from "../../pages/products/callSettings/data/call-settings-slice";
import { setAfterWizardComplete as activateChatbot } from "../../pages/products/chatbot/data/chatbot-slice";
import { setAfterWizardComplete as activateMessage } from "../../pages/products/contactForm/data/contactForm-slice";

/**
 * Hook for listening to the wizard submit message and processing the response
 */
export default function WizardSubmitListener() {
  const dispatch = useDispatch();
  const messageSocket = useContext(SocketContext);

  const wizardSubmitFinished = useCallback((message) => {
    if (checkWizardMessage(message)) {
      const parsedData = JSON.parse(message.data.content);

      dispatch(
        activateScheduling({ isActive: parsedData?.steps?.widgetFeatures?.activateScheduler })
      );
      dispatch(
        activateCallSettings({
          isActive: parsedData?.steps?.widgetFeatures?.activateCalltracker,
          businessPhoneNumber: parsedData?.steps?.businessPhoneNumber?.businessPhoneNumber
        })
      );
      dispatch(
        activateChatbot({ isActive: parsedData?.steps?.widgetFeatures?.activateChat })
      );
      dispatch(
        activateMessage({ isActive: parsedData?.steps?.widgetFeatures?.activateMessage })
      );
    }
  });

  useEffect(() => {
    messageSocket.addMessageListener(wizardSubmitFinished);
  }, [messageSocket]);

  return null;
}