import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as FreeKeywordIcon } from '../assets/free-keyword-icon.svg';
import FreeKeywordBackgroundIconDesktop from '../assets/free-keyword-tool-background-desktop.png';
import FreeKeywordBackgroundIconMobile from '../assets/free-keyword-tool-background-mobile.png';
import { PrimaryButton } from '../../../shared/settings-shared-components';
import { trackFKTDownload, trackDashboardCardClick } from '../../../shared/analytics-utils';

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${FreeKeywordBackgroundIconDesktop})`,
  backgroundSize: '100% 100%',

  '&.critical-action-variant': {
    backgroundImage: `url(${FreeKeywordBackgroundIconMobile})`,
  },

  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${FreeKeywordBackgroundIconMobile})`,
  },
}));

const StyledCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(5),
  padding: theme.spacing(8, 15),

  '&.critical-action-variant': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(5),
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(5),
  },
}));

const StyledContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '30px',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
}));

const StyledDescription = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '23px',
}));

const StyledDownloadButton = styled(PrimaryButton)(({ theme }) => ({
  width: 'max-content',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  padding: theme.spacing(1.2, 2.5),
  marginTop: theme.spacing(2),
  cursor: 'pointer',
}));

const trackDownloads = () => {
  trackFKTDownload();
  trackDashboardCardClick('Free Keyword Tool', 'Download Keywords');
}

export default function FreeKeywordResultCard({ linkToDownload, hasCriticalActionTemplate }) {
  return (
    <StyledGrid className={hasCriticalActionTemplate ? 'critical-action-variant' : ''} item lg={hasCriticalActionTemplate ? 3 : 12} md={12} xs={12}>
      <StyledCardContainer className={hasCriticalActionTemplate ? 'critical-action-variant' : ''}>
        <FreeKeywordIcon />
        <StyledContentWrapper>
          <StyledTitle>Say hello to your shiny new keywords</StyledTitle>
          <StyledDescription>
            Download your free keywords and start enhancing your marketing campaigns today.
          </StyledDescription>
          <StyledDownloadButton type="submit" onClick={trackDownloads} href={linkToDownload}>Download Keywords</StyledDownloadButton>
        </StyledContentWrapper>
      </StyledCardContainer>
    </StyledGrid>
  );
}

FreeKeywordResultCard.propTypes = {
  linkToDownload: PropTypes.string,
  hasCriticalActionTemplate: PropTypes.bool,
};
