import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material'; 
import styled from '@emotion/styled';
import { ReactComponent as DefaultEmptyStateDesktopSvg } from '../../images/empty-state-desktop-background.svg';
import { ReactComponent as DefaultEmptyStateMobileSvg } from '../../images/empty-state-mobile-background.svg';

const EmptyStateWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  gap: theme.spacing(2),
  '> .text': {
    textAlign: 'center',
    marginTop: '-130px',
  },

  [theme.breakpoints.down('sm')]: {
    '> .text': {
      marginTop: 0,
    },
  }
}));

const EmptyStateImgWrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '6rem',

  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  }
}));

export default function EmptyStateCard({ text, emptyStateBtn = null, EmptyStateDesktopSvg = DefaultEmptyStateDesktopSvg, EmptyStateMobileSvg = DefaultEmptyStateMobileSvg}) {
  return (
    <EmptyStateWrapper>
      <EmptyStateImgWrapper>
        {isMobile ? <EmptyStateMobileSvg /> : <EmptyStateDesktopSvg />}
      </EmptyStateImgWrapper>
      <Typography className='text' variant='body1'>{text}</Typography>
      {emptyStateBtn}
    </EmptyStateWrapper>
  )
};

EmptyStateCard.propTypes = {
  text: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
  emptyStateBtn: PropTypes.node,
  EmptyStateDesktopSvg: PropTypes.object,
  EmptyStateMobileSvg: PropTypes.object,
};
