import {
  SCHEDULER_SLUG, PRO_TIER, CHATBOT_SLUG, ACCOUNT_SLUG,
  CALL_TRACKER_SLUG, WIDGET_SETTINGS_SLUG, CONTACT_FORM_SLUG, TOOLS_KEY,
} from '../app-constants';

// Page name keys
//TODO: most of these will be removed as part of BOP-3171
export const GENERAL_SETTINGS_PAGE = 'generalSettings';
export const APPOINTMENT_TYPES_PAGE = 'appointmentTypes';
export const CALENDAR_INT_PAGE = 'calendarIntegration';
export const WIDGET_CAPTURE_PAGE = 'widgetCaptureCode';
export const ADVANCED_SETTINGS_PAGE = 'advancedSettings';
export const NOTIFICATION_REMINDERS_PAGE = 'notificationsReminders';
export const BUSINESS_DETAILS_PAGE = 'business-details';
export const SUBSCRIPTIONS_PAGE = 'subscriptions';
export const INVOICES_PAGE = 'invoices';
export const CREDIT_CARDS_PAGE = 'credit-cards';
export const ENGAGEMENT_WIDGET_PAGE = 'engagement-widget';
export const LOCALIQ_CODE_PAGE = 'localiq-code';
export const CONTACT_FORM_PAGE = 'contact-form';
export const WIDGET_SETTINGS_PAGE = 'widgetSettings';
export const NOTIFICATIONS_PAGE = 'notifications-settings';
export const GRADER_PAGE = 'grader';

// Dashboard page key
export const DASHBOARD_PAGE = 'dashboard';

// Tools page keys
export const SCHEDULING_PAGE = 'scheduling';
export const CHATBOT_PAGE = 'chatbot';
export const CLICK_TO_CALL_PAGE = 'click-to-call';
export const EMAIL_CAMPAIGNS_PAGE = 'email-campaign';
export const SMS_PAGE = 'sms';

export const BOOKINGS_NAV_STEPS = {
  [GENERAL_SETTINGS_PAGE]: {
    key: GENERAL_SETTINGS_PAGE,
    display: 'General Settings',
    url: 'general',
  },
  [APPOINTMENT_TYPES_PAGE]: {
    key: APPOINTMENT_TYPES_PAGE,
    display: 'Appointment Types',
    url: 'appointment_types',
  },
  [CALENDAR_INT_PAGE]: {
    key: CALENDAR_INT_PAGE,
    display: 'Calendar Integration',
    url: 'calendar_integrations',
  },
  [WIDGET_CAPTURE_PAGE]: {
    key: WIDGET_CAPTURE_PAGE,
    display: 'Widget & Tracking Code',
    url: 'widget_capture_code',
  },
  [ADVANCED_SETTINGS_PAGE]: {
    key: ADVANCED_SETTINGS_PAGE,
    display: 'Advanced Settings',
    url: 'advanced',
  },
};

export const CHATBOT_NAV_STEPS = {
  [GENERAL_SETTINGS_PAGE]: {
    key: GENERAL_SETTINGS_PAGE,
    display: 'General Settings',
    url: 'general',
  },
  [NOTIFICATION_REMINDERS_PAGE]: {
    key: NOTIFICATION_REMINDERS_PAGE,
    display: 'Notifications and Reminders',
    url: 'notifications_reminders',
  },
  [WIDGET_CAPTURE_PAGE]: {
    key: WIDGET_CAPTURE_PAGE,
    display: 'Widget & Tracking Code',
    url: 'widget_capture_code',
  },
};

export const ACCOUNT_NAV_STEPS = {
  [BUSINESS_DETAILS_PAGE]: {
    key: BUSINESS_DETAILS_PAGE,
    display: 'Business Details',
    url: 'business-details',
  },
  [SUBSCRIPTIONS_PAGE]: {
    key: SUBSCRIPTIONS_PAGE,
    display: 'Subscriptions',
    url: 'subscriptions',
  },
  [INVOICES_PAGE]: {
    key: INVOICES_PAGE,
    display: 'Invoices',
    url: 'invoices',
  },
  [CREDIT_CARDS_PAGE]: {
    key: CREDIT_CARDS_PAGE,
    display: 'Credit Cards',
    url: 'credit-cards',
  },
};

export const CALL_NAV_STEPS = {
  [GENERAL_SETTINGS_PAGE]: {
    key: GENERAL_SETTINGS_PAGE,
    display: 'General Settings',
    url: 'general',
  },
  [WIDGET_CAPTURE_PAGE]: {
    key: WIDGET_CAPTURE_PAGE,
    display: 'Widget & Tracking Code',
    url: 'widget-settings',
  },
};

export const CONTACT_FORM_NAV_STEPS = {
  [GENERAL_SETTINGS_PAGE]: {
    key: GENERAL_SETTINGS_PAGE,
    display: 'General Settings',
    url: 'general-settings',
  },
  [WIDGET_SETTINGS_PAGE]: {
    key: WIDGET_SETTINGS_PAGE,
    display: 'Widget Settings',
    url: 'widget-settings',
  },
};

export const WIDGET_SETTINGS_STEPS = {
  [ENGAGEMENT_WIDGET_PAGE]: {
    key: ENGAGEMENT_WIDGET_PAGE,
    display: 'Widget',
    url: 'engagement-widget',
  },
  [LOCALIQ_CODE_PAGE]: {
    key: LOCALIQ_CODE_PAGE,
    display: 'LocaliQ Code',
    url: 'localiq-code',
  },
  [NOTIFICATIONS_PAGE]: {
    key: NOTIFICATIONS_PAGE,
    display: 'Notifications',
    url: 'notifications-settings',
  }
};

export const TOOL_NAV_STEPS = {
  [SCHEDULING_PAGE]: {
    key: SCHEDULING_PAGE,
    display: 'Scheduling',
    url: 'scheduling',
  },
  [CHATBOT_PAGE]: {
    key: CHATBOT_PAGE,
    display: 'Chatbot',
    url: 'chatbot',
  },
  [CLICK_TO_CALL_PAGE]: {
    key: CLICK_TO_CALL_PAGE,
    display: 'Click-to-Call',
    url: 'click-to-call',
  },
  [EMAIL_CAMPAIGNS_PAGE]: {
    key: EMAIL_CAMPAIGNS_PAGE,
    display: 'Email Campaigns',
    url: 'email-campaigns',
  },
  [GRADER_PAGE]: {
    key: GRADER_PAGE,
    display: 'Website Grader',
    url: 'grader',
  }
};

export const TOOL_NAV_SUPPORT_STEPS = {
  [SMS_PAGE]: {
    key: SMS_PAGE,
    display: 'SMS',
    url: 'sms',
  },
  [SCHEDULING_PAGE]: {
    key: SCHEDULING_PAGE,
    display: 'Scheduling',
    url: 'scheduling',
  },
  [CHATBOT_PAGE]: {
    key: CHATBOT_PAGE,
    display: 'Chatbot',
    url: 'chatbot',
  },
  [CLICK_TO_CALL_PAGE]: {
    key: CLICK_TO_CALL_PAGE,
    display: 'Click-to-Call',
    url: 'click-to-call',
  },
  [EMAIL_CAMPAIGNS_PAGE]: {
    key: EMAIL_CAMPAIGNS_PAGE,
    display: 'Email Campaigns',
    url: 'email-campaigns',
  },
  [GRADER_PAGE]: {
    key: GRADER_PAGE,
    display: 'Website Grader',
    url: 'grader',
  }
};

export const getNavStepsByUrl = (navSteps) => Object.values(navSteps).reduce((acc, step) => {
  acc[step.url] = step;
  return acc;
}, {});

// TODO: This will change with other products/tiers
//TODO: rework
const tempDefault = [GENERAL_SETTINGS_PAGE, APPOINTMENT_TYPES_PAGE, ADVANCED_SETTINGS_PAGE,
  CALENDAR_INT_PAGE, WIDGET_CAPTURE_PAGE];
const chatbotDefault = [GENERAL_SETTINGS_PAGE, NOTIFICATION_REMINDERS_PAGE, WIDGET_CAPTURE_PAGE];
const accountDefault = [BUSINESS_DETAILS_PAGE, SUBSCRIPTIONS_PAGE, INVOICES_PAGE, CREDIT_CARDS_PAGE];
const callSettingsDefault = [GENERAL_SETTINGS_PAGE, WIDGET_CAPTURE_PAGE];
const contactFormDefault = [GENERAL_SETTINGS_PAGE, WIDGET_SETTINGS_PAGE];
const widgetSettingsDefault = [ENGAGEMENT_WIDGET_PAGE, LOCALIQ_CODE_PAGE, NOTIFICATIONS_PAGE];

const toolsDefault = [SCHEDULING_PAGE, CHATBOT_PAGE, CLICK_TO_CALL_PAGE];
const toolsSupportDefault = [SMS_PAGE, SCHEDULING_PAGE, CHATBOT_PAGE, CLICK_TO_CALL_PAGE];

export const convertProductToNav = (slug, tier, isSupport) => {
  switch (slug) {
    case SCHEDULER_SLUG:
      if (tier === PRO_TIER) {
        return tempDefault;
      }
      return tempDefault;
    case CHATBOT_SLUG:
      return chatbotDefault;
    case ACCOUNT_SLUG:
      return accountDefault;
    case CALL_TRACKER_SLUG:
      return callSettingsDefault;
    case WIDGET_SETTINGS_SLUG:
      return widgetSettingsDefault;
    case CONTACT_FORM_SLUG:
      return contactFormDefault;
    case TOOLS_KEY:
      return isSupport ? toolsSupportDefault : toolsDefault;
    default:
      return tempDefault;
  }
};
