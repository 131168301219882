import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { IQFormSelect } from '@gannettdigital/shared-react-components';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import BookingsCTA from '../BookingsCTA';
import {
  START_END_ARE_EQUAL, END_BEFORE_START, TIME_SLOT_OVERLAP, TIME_VALUES,
} from './availability-constants';
import { StyledDelete } from '../settings-shared-components';

const MainDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasError',
})(({ theme, hasError }) => ({
  '& .MuiOutlinedInput-root': {
    color: hasError ? theme.palette.error.main : 'inherit',
    '&:hover': {
      '& fieldset': {
        borderColor: hasError ? theme.palette.error.main : 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: hasError ? theme.palette.error.main : 'inherit',
      },
    },
    '& .MuiInputAdornment-root': {
      color: hasError ? theme.palette.error.main : theme.palette.text.primary,
    },
    '&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: hasError ? theme.palette.error.main : theme.palette.text.primary,
    },
    '&.Mui-focused svg': {
      color: hasError ? theme.palette.error.main : theme.palette.text.primary,
    },
    '& svg': {
      color: hasError ? theme.palette.error.main : 'inherit',
    },
    '& fieldset': {
      borderColor: hasError ? theme.palette.error.main : 'inherit',
    },
    '&.Mui-focused fieldset': {
      borderColor: hasError ? theme.palette.error.main : 'inherit',
    },
  },
}));

const TimeButtonWrapper = styled('div')(({ theme, isMobile }) => ({
  display: 'inline-block',
  gap: '16px',
  marginLeft: isMobile ? 0 : theme.spacing(2),
  '& .MuiButton-root': {
    marginTop: isMobile ? '5px' : 0,
    padding: theme.spacing('6px', 3),
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    marginLeft: 0,
    width: '100%',
    '& .MuiButton-root': {
      margin: theme.spacing(2, 0),
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: 0,
    width: '100%',
    '& .MuiButton-root': {
      margin: theme.spacing(2, 0),
    },
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: '100%',
    '& .MuiButton-root': {
      margin: theme.spacing(2, 0),
    },
  },
}));

const TimeBlockWrapper = styled('div')(({ isMobile }) => ({
  display: 'flex',
  direction: 'column',
  alignItems: 'center',
  width: isMobile ? '100%' : 'auto',
}));

const TimeWrapper = styled('div')(({ theme }) => ({
  display: 'inline-block',
  '& .MuiSelect-select': {
    minWidth: 102, // 150 - 48 of padding
    padding: theme.spacing(1, 2),
  },
  '& label': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    flexGrow: 1,
    '& .MuiSelect-select': {
      minWidth: 'auto',
      paddingLeft: 4,
    },
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      paddingLeft: 4,
    },
    '& .MuiFormControl-root': {
      width: '95%',
    },
  },
}));

const ToWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(0, 2),
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '1px',
}));

const errorMsgLookup = (errorMsg) => {
  switch (errorMsg) {
    case TIME_SLOT_OVERLAP:
      return 'Time blocks cannot overlap';
    case END_BEFORE_START:
      return 'Start time cannot be after End time or vice versa';
    case START_END_ARE_EQUAL:
      return 'Choose different start and end times';
    default:
      return 'Time blocks cannot overlap';
  }
};

export default function StartEndTimeSelector({
  id,
  startName,
  endName,
  startTime,
  endTime,
  showAddBtn = false,
  showDelete = false,
  onAddClick = () => {},
  onDeleteClick = () => {},
  onChange = () => {},
  errorCode = null,
}) {
  const theme = useTheme();

  return (
    <>
      <MainDiv className="timeBlockWrapper" key={id} style={{ display: 'flex', flexWrap: isMobile && !showDelete ? 'wrap' : 'nowrap', gap: '16px' }} hasError={errorCode}>
        <TimeBlockWrapper isMobile={isMobile}>
          <TimeWrapper className="timeInputWrapper">
            <IQFormSelect
              sx={{ background: '#FFFF' }}
              name={startName}
              items={TIME_VALUES}
              defaultValue={startTime}
              adornmentIcon={isMobile ? null : <AccessTimeIcon />}
              MenuProps={{ PaperProps: { sx: { maxHeight: 390 } }, MenuListProps: { sx: { '& .MuiMenuItem-root.Mui-disabled': { opacity: 0 } } } }}
              onChange={(e) => onChange(startName, e.target.value)}
              fullWidth={isMobile}
            />
          </TimeWrapper>
          <ToWrapper>TO</ToWrapper>
          <TimeWrapper className="timeInputWrapper">
            <IQFormSelect
              sx={{ background: '#FFFF' }}
              name={endName}
              items={TIME_VALUES}
              defaultValue={endTime}
              adornmentIcon={isMobile ? null : <AccessTimeIcon />}
              MenuProps={{ PaperProps: { sx: { maxHeight: 390 } }, MenuListProps: { sx: { '& .MuiMenuItem-root.Mui-disabled': { opacity: 0 } } } }}
              onChange={(e) => onChange(endName, e.target.value)}
              fullWidth={isMobile}
            />
          </TimeWrapper>
        </TimeBlockWrapper>
        {showAddBtn && (
          <TimeButtonWrapper isMobile={isMobile}>
            <BookingsCTA
              variant="outlined"
              onClickHandler={onAddClick}
              startIcon={<AddIcon />}
              fullWidth={isMobile}
              text="Add Time Block"
            />
          </TimeButtonWrapper>
        )}
        {showDelete && (
          <StyledDelete sx={{ marginLeft: isMobile ? '-16px' : '16px' }} aria-label="delete" onClick={onDeleteClick}>
            <DeleteOutlineIcon htmlColor="blue" />
          </StyledDelete>
        )}
      </MainDiv>
      {errorCode && (
        <Typography variant="caption" color={theme.palette.error.main} lineHeight={2}>
          {errorMsgLookup(errorCode)}
        </Typography>
      )}
    </>
  );
}

StartEndTimeSelector.propTypes = {
  id: PropTypes.string.isRequired,
  startName: PropTypes.string.isRequired,
  endName: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  showAddBtn: PropTypes.bool,
  showDelete: PropTypes.bool,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onChange: PropTypes.func,
  errorCode: PropTypes.string,
};
