import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import BookingsCTA from '../../../../shared/BookingsCTA';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '50%',
  '&.get-the-most-max-two-cards': {
    width: '50%',
  },
  '&.get-the-most-more-then-two-cards': {
    width: '32%',
  },
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: theme.spacing(1),
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    '&.get-the-most-max-two-cards': {
      width: 'unset',
    },
    '&.get-the-most-more-then-two-cards': {
      width: 'unset',
    },
  },
}));

const StyledHead = styled('div')(({ theme, BG }) => ({
  backgroundImage: `url(${BG})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100%',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '220px',
  borderBottom: `1px solid ${theme.palette.common.black}`,
}));

const StyledImageWrapper = styled('div')(() => ({
  width: '240px',
  height: '140px',
  margin: '0 auto',
}));

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
}));

const StyledMain = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 1, 2),
  },
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '25px',
  letterSpacing: '0.18',
}));

const StyledSubText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0, 3, 0),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 0),
  },
}));

const StyledButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(1.5),
  },
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  textDecoration: 'underline',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export default function MiniCard({
  cardKey,
  dismissClickHandler,
  buttonClickHandler,
  BG,
  BGImage,
  title,
  text,
  buttonText,
  cardsCount,
}) {
  const onDismissClick = () => {
    dismissClickHandler(cardKey);
  };

  return (
    <StyledContainer className={cardsCount <= 2 ? 'get-the-most-max-two-cards' : 'get-the-most-more-then-two-cards'}>
      <StyledHead BG={BG}>
        <StyledImageWrapper>
          <StyledImage src={BGImage} alt="background icon" />
        </StyledImageWrapper>
      </StyledHead>
      <StyledMain>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubText>
          {text}
        </StyledSubText>
        <StyledButtons>
          <BookingsCTA
            text={buttonText}
            onClickHandler={buttonClickHandler}
            style={{ padding: '11px 20px' }}
          />
          <StyledLink onClick={onDismissClick}>Dismiss</StyledLink>
        </StyledButtons>
      </StyledMain>
    </StyledContainer>
  );
}

MiniCard.propTypes = {
  cardKey: PropTypes.string,
  buttonClickHandler: PropTypes.func,
  dismissClickHandler: PropTypes.func,
  BG: PropTypes.string,
  BGImage: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  cardsCount: PropTypes.number,
};
