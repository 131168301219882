import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import SectionLayout from '../../../../shared/section/SectionLayout';
import GoogleCalendar from '../cards/GoogleCalendar';
import GoogleCalendarErrors from '../cards/GoogleCalendarErrors';
import OutlookCalendar from '../cards/OutlookCalendar';
import OutlookCalendarErrors from '../cards/OutlookCalendarErrors';
import SectionCard from '../../../../shared/SectionCard';
import { CALENDAR_QUERY_SECTION_NAME, CALENDAR_QUERY_SECTION_VALUE } from '../bookings-constants';

const CalendarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  '& > div': {
    width: 'auto',
  },
  [theme.breakpoints.down('lg')]: {
    gap: 0,
    flexFlow: 'column',
    '& > div': {
      width: 'auto',
    },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
  },
}));

const ErrorBlock = styled('div')(({ theme }) => ({
  '& > div:nth-of-type(1)': {
    marginBottom: theme.spacing(1),
  },
}));

export default function CalendarIntegrations() {
  const [searchParams] = useSearchParams();
  const sectionRef = useRef(null);
  const querySectionValue = searchParams.get(CALENDAR_QUERY_SECTION_NAME);

  useEffect(() => {
    if (querySectionValue === CALENDAR_QUERY_SECTION_VALUE) {
      sectionRef.current.scrollIntoView();
    }
  }, [querySectionValue]);

  return (
    <div ref={sectionRef}>
      <SectionCard
        title="Calendar Integrations"
        tooltipText="Eliminate calendar conflicts and see all of your appointments in one place."
        showDividingLine={false}
      >
        <SectionLayout
          name="calendarIntegrations"
          errorBanner={(
            <ErrorBlock>
              <GoogleCalendarErrors />
              <OutlookCalendarErrors />
            </ErrorBlock>
          )}
        />
        <CalendarWrapper>
          <GoogleCalendar />
          <OutlookCalendar />
        </CalendarWrapper>
      </SectionCard>
    </div>
  );
}
