import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material';
import SettingsLayout from '../../../layouts/SettingsLayout';
import './listings_grader_styles.scss';

export default function ListingsGrader() {
	const UBERALL_PUBLIC_KEY = 'fYUIHWQE8vN3gfPPAtkDDWRLYu9I4aoShpIrZYsHoPi7sxSmoGbZK8yn1i817x5n';
	const theme = useTheme();
	const { REACT_APP_BUY_ONLINE_UI_HOST } = process.env;

	const DIRECTORIES = [
	  'APPLE_MAPS',
	  'FACEBOOK',
	  'FOURSQUARE',
	  'GOOGLE_MAPS',
	  'WAZE',
	  'UBER',
	  'GOOGLE',
	  'SIRI',
	  'GOOGLE_ASSISTANT',
	  'CYLEX',
	  'FIND_OPEN',
	  'BING',
	  'HOTFROG',
	  'NEXT_DOOR',
	  'MICROSOFT_CORTANA',
	  'INSIDER_PAGES',
	  'ELOCAL',
	  'EZLOCAL',
	  'JUDYS_BOOK',
	  'CITYSEARCH',
	];

	return (
		<SettingsLayout>
			<Helmet>
	        	<script type="text/javascript" src="https://uberall.com/assets/status-check-embed.js" />
	      	</Helmet>
	      	<div
		        id="styleOverridesId"
		        className="ub_status_check_widget"
		        data-showtable="true"
		        data-showgraph="true"
		        data-showtopinfo="true"
		        data-pdfexport="true"
		        data-showdirectorygrid="true"
		        data-urlbase="https://uberall.com"
		        data-ctabuttontext="Buy Now"
		        data-ctatext="Manage and boost your online visibility with Listings Pro."
		        data-ctalink={`${REACT_APP_BUY_ONLINE_UI_HOST}select-plan?product=premium_listings`}
		        data-headertext="Get your free listings health check."
		        data-countries="US"
		        data-directories={DIRECTORIES.join(',')}
		        data-language="EN"
		        data-key={UBERALL_PUBLIC_KEY}
		        data-recentsearches="false"
				data-bgcolor={theme.palette.common.black}
				data-buttontextcolor={theme.palette.common.white}
		        data-textcolor={theme.palette.common.black}
		        data-contactphone=""
		        data-contactemail=""
		        data-sharelink="true"
	      	>
	        	<div className="ub_circle_progress">
	        	</div>
	      	</div>
      	</SettingsLayout>
	);

};