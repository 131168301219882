import { Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CopyInput from '../../../../../shared/CopyInput';
import SectionCard from '../../../../../shared/SectionCard';
import { OTHER_WEBSITE } from '../../account-constants';
import OtherCaptureCodeCard from './OtherCaptureCode';
import { getStepInfo } from '../../account-utils';

const BoldText = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  fontFamily: 'Unify Sans Semibold',
  padding: '0 4px',
}));

const TightList = styled('ul')(() => ({
  margin: 0,
  paddingInlineStart: '25px',
}));

const DividerLine = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  marginBottom: '24px',
}));

const StepTwoTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontFamily: 'Unify Sans Semibold',
}));

const StepTwoBody = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  letterSpacing: ".2px",
  lineHeight: '27px'
}));

export default function CaptureCodeCard({ captureCodeText, websiteType, captureCode }) {
  const stepInfo = getStepInfo(websiteType);

  if(websiteType !== OTHER_WEBSITE && stepInfo) {
    return <OtherCaptureCodeCard websiteType={websiteType} captureCodeText={captureCode} stepTitle={stepInfo.stepTitle} stepText={stepInfo.stepText} />
  }

  return (
    <SectionCard showDividingLine={false}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
      <CopyInput
        displayText={captureCodeText}
        labelText="1. Copy the Code"
        websiteType={websiteType}
        useTextArea
        source="Settings"
      />
      </div>
      <DividerLine />
      <StepTwoTitle> 2. Paste the Code</StepTwoTitle>

      <StepTwoBody sx={{ paddingTop: '26px' }}>
        Paste your LocaliQ Code before the closing
        <BoldText>&lt;/head&gt;</BoldText>
        tag.
        <TightList>
          <li>
            Look for either the index.html, head.html, or a module
            containing the closing
            <BoldText>&lt;/head&gt;</BoldText>
            tag.
          </li>
          <li>
            If each page has a different
            <BoldText>&lt;head&gt;</BoldText>
            tag, you will want to paste it into each one.
          </li>
        </TightList>
      </StepTwoBody>
    </SectionCard>
  );
}

CaptureCodeCard.propTypes = {
  trackingID: PropTypes.string.isRequired,
};
