import { createSlice } from '@reduxjs/toolkit';
import {
  CAPTURE_CODE_FILTER,
  DATE_FILTER,
  PROD_FILTER,
  ACTIVE_FILTER,
  TEST_USER_FILTER,
} from '../../../../shared/admin-constants';

const initialState = {
  isLoading: false,
  businesses: [],
  rowLimit: 10,
  page: 0,
  searchTerm: '',
  resultTotal: 0,
  prodSlugFilter: null,
  prodTierFilter: null,
  activationDateFilter: null,
  captureCodeFilter: null,
  activeFilter: null,
  testUserFilter: true,
  sortColumn: 'createdAt',
  sortDirection: 'desc',
};

const adminBusinessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    retrieveBusinessData: (state) => {
      state.isLoading = true;
      state.businesses = [];
    },
    retrieveBusinessDataSuccess: (state, action) => {
      state.businesses = action.payload.content;
      state.isLoading = false;
      state.resultTotal = action.payload.resultTotal;
    },
    retrieveBusinessDataFailure: (state, action) => {
      state.isLoading = false;
    },
    updateSearchTerm: (state, action) => {
      state.searchTerm = action.payload.searchTerm;
    },
    updateRowLimit: (state, action) => {
      state.rowLimit = action.payload.rowLimit;
    },
    updatePage: (state, action) => {
      state.page = action.payload.page;
    },
    updateFilter: (state, action) => {
      const { type } = action.payload;
      if (type === PROD_FILTER) {
        const { filter } = action.payload;
        if (filter) {
          state.prodTierFilter = filter.tier;
          state.prodSlugFilter = filter.slug;
        } else {
          state.prodTierFilter = null;
          state.prodSlugFilter = null;
        }
      } else if (type === DATE_FILTER) {
        state.activationDateFilter = action.payload.filter;
      } else if (type === CAPTURE_CODE_FILTER) {
        state.captureCodeFilter = action.payload.filter;
      } else if (type === ACTIVE_FILTER) {
        state.activeFilter = action.payload.filter;
      } else if (type === TEST_USER_FILTER) {
        state.testUserFilter = action.payload.filter;
      }
    },
    updateSort: (state, action) => {
      if (action.payload.sortDirection !== null) {
        state.sortColumn = action.payload.sortColumn;
        state.sortDirection = action.payload.sortDirection;
      } else {
        // 3rd state sort "clear" is null, so return to default
        state.sortColumn = 'createdAt';
        state.sortDirection = 'desc';
      }
    },
  },
});

export const {
  retrieveBusinessData,
  retrieveBusinessDataSuccess,
  retrieveBusinessDataFailure,
  updateSearchTerm,
  updateRowLimit,
  updatePage,
  updateFilter,
  updateSort,
} = adminBusinessSlice.actions;
export default adminBusinessSlice;
