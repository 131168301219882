// Baseline retry length for server timeout in ms
export const SERVER_TIMEOUT_RETRY_BASE = 50;
// How many times to try to reconnect the socket
export const MAX_RECONNECT_ATTEMPTS = 10;

// Socket status message
export const SOCKET_NOT_AVAILABLE = 'Socket not available';
export const MESSAGE_SENT = 'Message sent';

// Socket events
export const MESSAGE_RECEIVED = 'message';

// Action names
export const REQUEST_DONE = 'REQUEST_DONE';
export const ACTIVATION = 'ACTIVATION'
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';

// Event types
export const PRODUCT_DATA_UPDATED = 'PRODUCT_DATA_UPDATED';
export const CREATE = 'CREATE';
export const RESCHEDULE = 'RESCHEDULE';
export const CANCEL = 'CANCEL';
export const GRADER = 'GRADER';
export const APPOINTMENT = 'APPOINTMENT';
export const SUBMIT = 'SUBMIT';
export const WIZARD = 'WIZARD';