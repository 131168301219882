import { formatDurationToRelativeTime, formatTimestampToCustomFormat } from "../../shared/app-utils";
import { CALL_ACTIVITY, CHAT_ACTIVITY, INSTALL_WIDGET_TEMPLATE, SCHEDULING_ACTIVITY, SETUP_WIDGET_TEMPLATE, FORM_ACTIVITY, DAYS } from "./dashboard-constants";
import { LOCALIQ_CODE_PAGE, ENGAGEMENT_WIDGET_PAGE } from '../../shared/navigation/navigation-config';
import EngagementImg from './assets/engagement-widget.svg';
import PasteWidgetImg from './assets/paste-widget.svg';
import PhoneIcon from './assets/upcoming-appt-phone-icon.svg';
import BusinessAddressIcon from './assets/upcoming-appt-business-address-icon.svg';
import ProvidedAddressIcon from './assets/upcoming-appt-provided-address-icon.svg';
import WebConfIcon from './assets/upcoming-appt-web-conf-icon.svg';
import { CONFIG_PATH, LOCALIQ_CODE_SETTINGS_PATH } from '../../shared/app-constants';
import { OFFICE_LOCATION, ONLINE_LOCATION, PHONE_LOCATION, SITE_LOCATION } from "../products/bookings/bookings-constants";

export const getActivityInfo = (activity, timezone) => {
  switch (activity.event_type) {
    case CHAT_ACTIVITY:
      const formattedChatTime = formatTimestampToCustomFormat(activity.occurred_at);
      let chatTimeSplitted = formattedChatTime.split(' ');
      chatTimeSplitted.splice(1, 0, 'on');
      return `${chatTimeSplitted.join(' ')} ${timezone}`;
    case SCHEDULING_ACTIVITY:
      const formattedStartTime = formatTimestampToCustomFormat(activity?.booking?.extra_fields?.booking_time, true);
      let startTimeSplitted = formattedStartTime.split(' ');
      startTimeSplitted.splice(1, 0, 'on');
      return `${startTimeSplitted.join(' ')} ${timezone}`;
    case CALL_ACTIVITY:
      return formatDurationToRelativeTime(activity?.call?.duration);
    case FORM_ACTIVITY:
      const formattedFormTime = formatTimestampToCustomFormat(activity.occurred_at);
      let formTimeSplitted = formattedFormTime.split(' ');
      formTimeSplitted.splice(1, 0, 'on');
      return `${formTimeSplitted.join(' ')} ${timezone}`;
    default:
      return '';
  }
};

export const getActivityInfoTitle = (type) => {
  switch (type) {
    case CHAT_ACTIVITY:
      return 'Generated:';
    case SCHEDULING_ACTIVITY:
      return 'Start Time:';
    case CALL_ACTIVITY:
      return 'Call Length:';
    case FORM_ACTIVITY:
      return 'Submitted:';
    default:
      return '';
  }
};

export const determineContent = (template) => {
  let title = null;
  let description = null;

  switch (template) {
    case 'keywordtool':
      title = 'We have a lot more to offer than keywords';
      description = `Welcome to LocaliQ, we’re happy to have you! In addition to reviewing keywords, we also have a collection of widgets to help you turn website traffic into valuable leads and customers.`;
      break;
    case 'grader':
      title = 'We do a lot more than grade websites';
      description = `Welcome to LocaliQ, we’re happy to have you! In addition to grading websites, we also have a collection of widgets to help you turn website traffic into valuable leads and customers.`;
      break;
    case 'growthsuite':
      title = 'Welcome to LocaliQ!';
      description = `We're happy to have you! Get started by setting up your engagement widget below.
        The widget makes it easy to gather leads and nurture them into customers.`;
      break;
    case 'listings':
      title = 'We do a lot more than manage listings';
      description = `Welcome to LocaliQ, we’re happy to have you! In addition to managing listings, we also have a collection of widgets to help you turn website traffic into valuable leads and customers.`;
      break;
    default:
      title = 'Welcome to LocaliQ!';
      description = `We're happy to have you! Get started by setting up your widgets.
       These widgets will turn website traffic into valuable leads and customers.`;
  }

  return {
    title,
    description,
  };
};

export const determineCriticalActionContent = (template, isWizardCompleted) => {
  let title = null;
  let ctaText = null;
  let ctaLink = null;
  let widgetImg = null;
  let showLearnMore = false;

  switch (template) {
    case SETUP_WIDGET_TEMPLATE:
      title = 'Set Up Your Widgets Now';
      ctaText = 'Set Up Widgets in Under 5 Minutes';
      ctaLink = `${CONFIG_PATH}`;
      widgetImg = EngagementImg;
      showLearnMore = true;
      break;
    case INSTALL_WIDGET_TEMPLATE:
      title = 'Add Widgets To Your Site';
      ctaText = 'Get LocaliQ Code';
      ctaLink = isWizardCompleted ? `${LOCALIQ_CODE_SETTINGS_PATH}` : `${CONFIG_PATH}`;
      widgetImg = PasteWidgetImg;
      showLearnMore = false;
      break;
    default:
      break;
  }

  return {
    title,
    ctaText,
    ctaLink,
    widgetImg,
    showLearnMore,
  };
};

// get widget status string
export function getStatusString(status, navKey) {
  if (ENGAGEMENT_WIDGET_PAGE === navKey) {
    if (status) {
      return 'On';
    }
    return 'Off';
  }
  if (LOCALIQ_CODE_PAGE === navKey) {
    if (status) {
      return 'Installed';
    }
    return 'Not Installed';
  }
  return null;
};

export function getPerformanceFontSize(number) {
  if (number < 100) {
    return 40;
  }
  if (number < 1_000) {
    return 27;
  }
  if (number < 10_000) {
    return 20;
  }
  return 15;
};

// Should return 02/22/2012 format
export function formatAppointmentDisplayDate(date) {
  const formattedDate = new Date(date);
  const dayString = DAYS[formattedDate.getDay()];
  const dateNumber = formattedDate.getDate() < 10 ? `0${formattedDate.getDate()}` : formattedDate.getDate();
  const monthNumber = formattedDate.getMonth() + 1 < 10 ? `0${formattedDate.getMonth() + 1}` : formattedDate.getMonth() + 1;
  const displayDate = `${dayString} ${monthNumber}/${dateNumber}/${`${formattedDate.getFullYear()}`.slice(2)}`;

  return displayDate;
};

export const getUpcomingAppointmentLastRowInfo = (type, phoneNumber, businessAddress, providedAddress, virtualMeetingUrl) => {
  let meetingUrl = virtualMeetingUrl;
  if (!meetingUrl) {
    meetingUrl = 'Business provided link';
  } else if (meetingUrl.length > 45) {
    meetingUrl = `${meetingUrl.slice(0, 45)}...`;
  }

  switch (type) {
    case PHONE_LOCATION.value:
      return {
        value: phoneNumber,
        icon: PhoneIcon,
      };
    case OFFICE_LOCATION.value:
      return {
        value: businessAddress,
        icon: BusinessAddressIcon,
      };
    case SITE_LOCATION.value:
      return {
        value: providedAddress,
        icon: ProvidedAddressIcon,
      };
    case ONLINE_LOCATION.value:
      return {
        value: meetingUrl,
        icon: WebConfIcon,
      };
    default:
      return {};
  }
};