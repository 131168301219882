import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react'
import styled from '@emotion/styled';
import { ReactComponent as CloseIcon } from "../../images/close-x-icon.svg";
import { dismissToggleModal } from '../../pages/products/bookings/data/bookings-slice';
import {
  Modal,
  Typography,
  Button,
  Card,
  Grid,
  ListItem,
} from '@mui/material';

const ModalBackground = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  margin: '0 auto',
  textAlign: 'center',
  width: 925,
  top: '10%',
  position: 'relative',
  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    width: 377,
  },
}));

const DismissText = styled(Button)(({ theme }) => ({
  fontFamily: 'Unify Sans SemiBold',
  color: '#1665CF',
  fontSize: 20,
  marginTop: 20,
  textTransform: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'transparent',
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: "1%",
  },
}));

const CloseModal = styled(Button)(() => ({
  background: 'none',
  border: 'none',
  margin: '1rem 0',
  ':hover': {
    background: 'none'
  }
}));

const NextStepsList = styled(ListItem)(() => ({
  display: 'list-item',
  fontSize: 18,
  fontFamily: 'Unify Sans',
  paddingBottom: 0
}));

const ModalDivider = styled('hr')(() => ({
  background: 'linear-gradient(90deg, #6432BE 0%, #DC4605 100%)',
  height: 1,
  width: '95%',
  marginTop: '1.2rem'
}));

const ModalTitle = styled('span')(() => ({
  textAlign: 'center',
  color: '#404040',
  wordWrap: 'normal',
  fontSize: 32,
}));

const NextStepsTitle = styled(Typography)(() => ({
  fontSize: 24,
  fontFamily: 'Unify Sans Semibold',
  textAlign: 'center',
  marginBottom: '1rem'
}));

const ModalHeaderWrapper = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '0 40px',
  gap: 16,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center'
  },
}));

const ModalImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: 0
  },
}));

const TitleWrapper = styled(Grid)(() => ({
  '&.MuiGrid-root.MuiGrid-item': {
    maxWidth: '100%'
  },
}));

const ListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export default function ToggleModal({
  modalIcon,
  titleText,
  nextSteps,
  isOpen,
  dismissText,
  ...props
}) {
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);

  function dismissModal() {
    setActive(!isOpen)
    dispatch(dismissToggleModal({ dismiss: true }))
  }

  useEffect(() => {
    const processingTimeout = window.setTimeout(() => {
      setActive(isOpen);
    }, 2000);
    return () => {
      setActive(false)
      clearTimeout(processingTimeout)
    };
  }, [isOpen]);

  return (
    <Modal open={active} {...props}>
      <ModalBackground>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <CloseModal onClick={dismissModal}><CloseIcon /></CloseModal>
        </Grid>
        <ModalHeaderWrapper container>
          <div>
            <ModalImage src={modalIcon} />
          </div>
          <TitleWrapper item xs={9} >
            <ModalTitle>{titleText}</ModalTitle>
          </TitleWrapper>
        </ModalHeaderWrapper>
        <ModalDivider />
        <ListWrapper>
          <NextStepsTitle>
            Here's what you should do next:
          </NextStepsTitle>
          <div>
            {nextSteps.map(steps => (
              <NextStepsList>
                <span>{steps}</span>
              </NextStepsList>
            ))}
          </div>
        </ListWrapper>
        <DismissText type="button" variant="text" onClick={dismissModal}>
          {dismissText}
        </DismissText>
      </ModalBackground>
    </Modal>
  );
}

ToggleModal.propTypes = {
  modalIcon: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  nextSteps: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  dismissText: PropTypes.string,
};
