import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import GradientBackground from '../assets/critical-action-background.svg';
import { CardBadge, PrimaryButton } from '../../../shared/settings-shared-components';
import { ReactComponent as WarningIcon } from '../assets/warning.svg';
import { determineCriticalActionContent } from '../dashboard-utils';
import { INSTALL_WIDGET_TEMPLATE, SETUP_WIDGET_TEMPLATE } from '../dashboard-constants';
import LoadingSkeletonCriticalActionDesktop from '../components/loadingSkeletons/criticalAction/LoadingSkeletonCriticalActionDesktop';
import LoadingSkeletonCriticalActionMobile from '../components/loadingSkeletons/criticalAction/LoadingSkeletonCriticalActionMobile';
import {
  trackLearnMoreClick, trackDashboardCardClick, TRIAL_SOURCE_KEY, TRIAL_SOURCES,
} from '../../../shared/analytics-utils';
import { LEARN_MORE_LINK } from '../../products/settings/settings-constants';

const CriticalActionCardGrid = styled(Grid)(() => ({
  backgroundImage: `url(${GradientBackground})`,
  backgroundSize: 'cover',
}));

const CriticalActionCardLoadingGrid = styled(Grid)(({ theme }) => ({
  background: theme.palette.common.black,
  color: 'white',
  padding: theme.spacing(7, 13),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 3),
  },
}));

const SubscriptionCardBadgerWrapper = styled(CardBadge)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-start',
  width: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
  }
}));

const WelcomeCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(7, 15),
  position: 'relative',
  color: theme.palette.common.white,
  alignItems: 'center',
  gap: '120px',
  '& .body-wrapper': {
    '& .welcome-title': {
      fontFamily: 'Unify Sans SemiBold',
      fontSize: 32,
      lineHeight: '40px',
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
    '& .welcome-description': {
      fontSize: 18,
      lineHeight: '27px',
      padding: theme.spacing(2, 0),
    },
  },

  '& .close-button': {
    padding: 0,
    marginTop: `-${theme.spacing(2)}`,
    '& svg': {
      height: 30,
      width: 30,
    },
  },

  '& .bold-text': {
    fontFamily: 'Unify Sans Semibold',
  },

  '& .button-wrapper': {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },

  '& .learn-more': {
    fontSize: '14px',
    color: theme.palette.common.white
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: '15px',
    '&&.MuiBox-root': {
      padding: theme.spacing(5, 3),
    },

    '& .body-wrapper': {
      paddingLeft: 0,
    },

    '& .image': {
      width: '335px',
      height: '200px',
    },
  },
}));

const installWidgetDescription = (
    <>
      LocaliQ Code is a short line of code that enables our widgets to display on your website. Without installing, widgets will not display.
    </>
);

const setupWidgetDescription = (
  <>
    This is the <span className='bold-text'>most important action</span> you can take. These widgets allow you to generate leads, answer questions from your website visitors, and schedule appointments.
  </>
);

export default function CriticalActionCard({
  userTemplate,
  hasFKTLink,
  isWizardCompleted,
  trackingCodeIsLoading,
}) {
  const navigate = useNavigate();
  const {
    title, ctaText, ctaLink, widgetImg, showLearnMore,
  } = determineCriticalActionContent(userTemplate, isWizardCompleted);

  const handleDismiss = () => {
    trackDashboardCardClick('Critical Action Card', ctaText);
    if (userTemplate === SETUP_WIDGET_TEMPLATE) {
      sessionStorage.setItem(TRIAL_SOURCE_KEY, TRIAL_SOURCES.DASHBOARD);
    }
    navigate(ctaLink);
  };

  const handleLearnMoreClick = () => {
    trackLearnMoreClick('Dashboard');
  };

  if (trackingCodeIsLoading) {
    return (
      <CriticalActionCardLoadingGrid item lg={hasFKTLink ? 9 : 12} md={12} xs={12}>
        {isMobile
          ? <LoadingSkeletonCriticalActionMobile />
          : <LoadingSkeletonCriticalActionDesktop />}
      </CriticalActionCardLoadingGrid>
    );
  }

  return (
    <CriticalActionCardGrid item lg={hasFKTLink ? 9 : 12} md={12} xs={12}>
      <WelcomeCardContainer>
        <div className="body-wrapper">
          <SubscriptionCardBadgerWrapper >
            <WarningIcon />
            <span className="bold-text">CRITICAL ACTION</span>
          </SubscriptionCardBadgerWrapper>
          <Typography className="welcome-title">
            {title}
          </Typography>
          <Typography className="welcome-description">
            {userTemplate === INSTALL_WIDGET_TEMPLATE ? installWidgetDescription : setupWidgetDescription}
          </Typography>
          <div className='button-wrapper'>
            <PrimaryButton type="button" variant="contained" onClick={handleDismiss}>
              {ctaText}
            </PrimaryButton>
            {showLearnMore && (<a href={LEARN_MORE_LINK} target="_blank" className="learn-more" onClick={handleLearnMoreClick}> Learn More </a>)}
          </div>
        </div>
        <div>
          <img className="image" src={widgetImg} alt="engagement widget image." />
        </div>
      </WelcomeCardContainer>
    </CriticalActionCardGrid>
  );
}

CriticalActionCard.propTypes = {
  userTemplate: PropTypes.string,
  hasFKTLink: PropTypes.bool,
  isWizardCompleted: PropTypes.bool,
  trackingCodeIsLoading: PropTypes.bool,
};
