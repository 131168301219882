import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import ScoreDetailBlock from './ScoreDetailBlock';
import { ScoreDetailsSectionWrapper, ScoreDetailsWrapper } from './styles/grader-section-styles';
import { DETAILS_DISPLAY_ORDER, DETAILS_LEVEL_LOOKUP } from './data/grader-section-constants';

/**
 * Display all score detail blocks
 */
export default function ScoreDetails({ section }) {
  const details = useSelector((state) => state.grader.graderResults.scoreDetails);
  const sectionDetails = details[section];
  return (
    <ScoreDetailsSectionWrapper>
      {sectionDetails && DETAILS_DISPLAY_ORDER.map((level) => {
        const detailsArr = sectionDetails[level];
        const { bgColor, title } = DETAILS_LEVEL_LOOKUP[level];
        if (Array.isArray(detailsArr) && detailsArr.length > 0) {
          return (
            <ScoreDetailsWrapper bgColor={bgColor} key={level}>
              <Grid container>
                <Grid item md={4} xs={12}>
                  <Typography className="detailsTitle">{title}</Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <ScoreDetailBlock details={detailsArr} level={level} />
                </Grid>
              </Grid>
            </ScoreDetailsWrapper>
          );
        }
        return <div />;
      })}
    </ScoreDetailsSectionWrapper>
  );
}

ScoreDetails.propTypes = {
  section: PropTypes.string,
};
