import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../../../../images/logo-localiq.svg';
import { LinkButton } from '../../../../shared/settings-shared-components';

const HeaderDiv = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'fixed', //TODO: fixed or absolute?

  '& .header-content': {
    padding: theme.spacing(2, 3),
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      '& button.MuiButton-textPrimary': {
        color: theme.palette.common.white,
        padding: 0,
      },
    },

    '& svg.localiq-logo': {
      height: 24,
      width: 'auto',
      [theme.breakpoints.down('md')]: {
        height: 20,
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    position: 'inherit',
    background: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.white}`,
    height: 56,
  },
}));

export default function Header({ onSaveAndExit, showSaveExit }) {
  return (
    <HeaderDiv>
      <div className="header-content">
        <Logo className="localiq-logo" />
        {
          showSaveExit && (
            <div>
              <LinkButton onClick={onSaveAndExit}>Save & Exit</LinkButton>
            </div>
          )
        }
      </div>
    </HeaderDiv>
  );
}

Header.propTypes = {
  onSaveAndExit: PropTypes.func.isRequired,
  showSaveExit: PropTypes.bool.isRequired,
};
