import { Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ReactComponent as WarningIcon } from '../images/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../images/error-icon.svg';
import { ReactComponent as InfoIcon } from '../images/informational-icon.svg';
import { ReactComponent as SuccessIcon } from '../images/success-icon.svg';
import { SUCCESS_TYPE, ERROR_TYPE, INFO_TYPE, WARNING_TYPE, VALID_TYPES } from './app-constants';

const BasicCard = styled(Card)(({ theme }) => ({
  margin: '0 auto',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.text.primary}`,
  boxShadow: 'none',
}));

const ColorBar = styled('div')(({ bgColor, theme }) => ({
  minWidth: theme.spacing(1),
  backgroundColor: bgColor,
  borderRadius: '2px 0 0 2px',
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(.5, 2),
  alignItems: 'center',
  flex: 1
}));

const Message = styled(Typography)(({ theme }) => ({
  fontFamily: 'Unify Sans',
  fontSize: 14,
  lineHeight: '25px',
  textAlign: 'left',
  paddingLeft: theme.spacing(2),
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const IconImageWrapper = styled('div')(({ colorFill }) => ({
  '& svg': {
    height: 32,
    width: 32,
    '& path': {
      fill: colorFill,
    },
  },
}));

/**
 * Component for a card representing an action taken
 * (reset password result, error, success etc)
 *
 * See below prop types for param info
 */
export default function ActionCard({
  id,
  type,
  children,
  ...props
}) {
  const theme = useTheme();
  let selectedIcon = null;
  let typeColor = theme.palette.primary.dark;

  switch (type) {
    case SUCCESS_TYPE:
      typeColor = '#7DBE50';
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <SuccessIcon alt="success-icon" id={`${id}-success-icon`} />
        </IconImageWrapper>
      );
      break;
    case ERROR_TYPE:
      typeColor = '#F03C4B';
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <ErrorIcon alt="error-icon" id={`${id}-error-icon`} />
        </IconImageWrapper>
      );
      break;
    case INFO_TYPE:
      typeColor = theme.palette.secondary.main;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <InfoIcon alt="info-icon" id={`${id}-info-icon`} />
        </IconImageWrapper>
      );
      break;
    case WARNING_TYPE:
      typeColor = theme.palette.warning.main;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <WarningIcon alt="warning-icon" id={`${id}-warning-icon`} />
        </IconImageWrapper>
      );
      break;
    default:
      break;
  }

  return (
    <BasicCard
      id={id}
      {...props}
    >
      <div style={{ display: 'flex' }}>
        <ColorBar bgColor={typeColor} />
        <MessageWrapper>
          {selectedIcon}
          <Message component="div">{children}</Message>
        </MessageWrapper>
      </div>
    </BasicCard>
  );
}

ActionCard.propTypes = {
  /**
   * Unique ID of this component
   */
  id: PropTypes.string.isRequired,

  /**
   * Message to display in the banner
   */
  children: PropTypes.node.isRequired,

  /**
   * The type to determine the styling of the banner
   */
  type: (props, propName, componentName) => {
    const passedType = props[propName];
    if (VALID_TYPES.indexOf(passedType) <= -1) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Check VALID_TYPES for allowable type values.`,
      );
    }
  },
};

ActionCard.defaultProps = {
  type: SUCCESS_TYPE,
};
