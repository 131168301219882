import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentScreen } from './navigation-slice';
import {
  CALL_SETTINGS_PATH, CALL_TRACKER_SLUG, CHATBOT_PATH, CHATBOT_SLUG,
  SCHEDULER_PATH, SCHEDULER_SLUG, TOOLS_KEY, TOOLS_PATH, WIDGET_SETTINGS_PATH, WIDGET_SETTINGS_SLUG,
  CONTACT_FORM_PATH, CONTACT_FORM_SLUG,
} from '../app-constants';
import {
  BOOKINGS_NAV_STEPS, CALL_NAV_STEPS, CHATBOT_NAV_STEPS,
  CONTACT_FORM_NAV_STEPS, ENGAGEMENT_WIDGET_PAGE, GENERAL_SETTINGS_PAGE, getNavStepsByUrl,
  SCHEDULING_PAGE, TOOL_NAV_STEPS, TOOL_NAV_SUPPORT_STEPS, WIDGET_SETTINGS_STEPS,
} from './navigation-config';

const determineNavStep = (slug, isSupport) => {
  let navSteps = null;
  let rootPath = null;
  let defaultPage = null;
  switch (slug) {
    case CALL_TRACKER_SLUG:
      navSteps = CALL_NAV_STEPS;
      rootPath = CALL_SETTINGS_PATH;
      defaultPage = GENERAL_SETTINGS_PAGE;
      break;
    case SCHEDULER_SLUG:
      navSteps = BOOKINGS_NAV_STEPS;
      rootPath = SCHEDULER_PATH;
      defaultPage = GENERAL_SETTINGS_PAGE;
      break;
    case CHATBOT_SLUG:
      navSteps = CHATBOT_NAV_STEPS;
      rootPath = CHATBOT_PATH;
      defaultPage = GENERAL_SETTINGS_PAGE;
      break;
    case CONTACT_FORM_SLUG:
      navSteps = CONTACT_FORM_NAV_STEPS;
      rootPath = CONTACT_FORM_PATH;
      defaultPage = GENERAL_SETTINGS_PAGE;
      break;
    case WIDGET_SETTINGS_SLUG:
      navSteps = WIDGET_SETTINGS_STEPS;
      rootPath = WIDGET_SETTINGS_PATH;
      defaultPage = ENGAGEMENT_WIDGET_PAGE;
      break;
    case TOOLS_KEY:
      navSteps = isSupport ? TOOL_NAV_SUPPORT_STEPS : TOOL_NAV_STEPS;
      rootPath = TOOLS_PATH;
      defaultPage = SCHEDULING_PAGE;
      break;
    default:
      break;
  }

  return {
    navSteps,
    rootPath,
    defaultPage,
  };
};

/**
 * Component to handle the navigation based useEffects that are needed per dialog
 */
export default function NavigationHooks({
  slug, tier, productNav, handleNavCalc, open,
}) {
  const currentScreen = useSelector((state) => state.navigationSettings.currentScreen);
  const loadedSettings = useSelector((state) => state.appSettings.loadedSettings);
  const user = useSelector((state) => state.appSettings.user);
  const hasSupportRole = useSelector(state => state.appSettings.user?.hasSupportRole);
  const dispatch = useDispatch();

  const { navSteps, rootPath, defaultPage } = determineNavStep(slug, hasSupportRole);

  useEffect(() => {
    if (productNav && productNav.length > 0) {
      handleNavCalc(productNav.map((navKey) => {
        const lookupStep = navSteps[navKey];
        return {
          text: lookupStep?.display,
          key: lookupStep?.key,
          onClick: () => {
            dispatch(setCurrentScreen({ currentScreen: navKey }));
          },
        };
      }));
    }
  }, [productNav]);

  useEffect(() => {
    if (currentScreen && open) {
      const lookupStep = navSteps[currentScreen];
      // Replace state to avoid back button. Don't trigger if we are already there (deep link)
      if (lookupStep && `${rootPath}/${lookupStep.url}` !== window.location.pathname) {
        window.history.replaceState(null, lookupStep.display, `${rootPath}/${lookupStep.url}`);
      }
    }
  }, [currentScreen, open]);

  useEffect(() => {
    const pathName = window.location.pathname;
    // Wait until load is done before deep link
    if (pathName && (loadedSettings)) {
      const newPath = pathName.replace(`${rootPath}/`, '');
      const foundStep = getNavStepsByUrl(navSteps)[newPath];
      if (foundStep) {
        dispatch(setCurrentScreen({ currentScreen: foundStep.key }));
      } else {
        dispatch(setCurrentScreen({ currentScreen: defaultPage }));
      }
    }
  }, [loadedSettings, user]);

  return null;
}

NavigationHooks.propTypes = {
  slug: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  productNav: PropTypes.array,
  handleNavCalc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
