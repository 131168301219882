import { createAction, createSlice } from '@reduxjs/toolkit';
import { GOOGLE_TYPE } from '../bookings-constants';
import { updateCalendarIntegrationActionSuccess, updateCalendarIntegrationAction } from './calendar-actions';
import { loadedBookingData } from './bookings-slice';

export const connectGoogleCalendarAction = createAction('google_calendar/connectGoogleCalendar');
export const dismissErrorBannerAction = createAction('google_calendar/dismissErrorBanner');

const googleCalendarSlice = createSlice({
  name: 'google_calendar',
  initialState: {
    isConnected: false,
    isLoading: false,
    calendarIntegrationData: {
      email: null,
      connectedOn: null,
    },
    showErrorBanner: false,
    scope: null,
  },
  reducers: {
    googleAuthStatus: (state, action) => {
      state.isLoading = action.payload.isActive;
    },
    connectGoogleCalendar: (state, action) => {
      state.isLoading = true;
      state.scope = action.payload.scope;
    },
    updateCalendarIntegrationFailure: (state) => {
      state.showErrorBanner = true;
      state.isLoading = false;
    },
    connectGoogleCalendarFailure: (state) => {
      state.showErrorBanner = true;
      state.isLoading = false;
    },
    dismissErrorBanner: (state) => {
      state.showErrorBanner = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCalendarIntegrationAction, (state, action) => {
      if (action.payload.type === GOOGLE_TYPE) {
        state.isLoading = true;
      }
    });
    builder.addCase(updateCalendarIntegrationActionSuccess, (state, action) => {
      const { calendarIntegration } = action.payload.data;

      if (action.payload.type === GOOGLE_TYPE) {
        if (calendarIntegration.length && calendarIntegration[0].accountKey) {
          state.calendarIntegrationData = {
            email: calendarIntegration[0].accountKey,
            connectedOn: calendarIntegration[0].connectedDate,
          };
          state.isConnected = true;
          state.isLoading = false;
        } else {
          state.calendarIntegrationData = {
            email: null,
            connectedOn: null,
          };
          state.isConnected = false;
          state.isLoading = false;
          state.scope = null;
        }
      }
    });
    builder.addCase(loadedBookingData, (state, action) => {
      const { payload } = action;

      // If the integration is already done on load
      if (
        payload.productInfo
        && payload.productInfo.calendarIntegration
        && payload.productInfo.calendarIntegration.length !== 0
        && payload.productInfo.calendarIntegration[0].type === GOOGLE_TYPE) {
        const { calendarIntegration } = payload.productInfo;

        state.calendarIntegrationData = {
          email: calendarIntegration[0].accountKey,
          connectedOn: calendarIntegration[0].connectedDate,
        };
        state.isConnected = true;
      }
    });
  },
});

export const {
  googleAuthStatus,
  connectGoogleCalendar,
  connectGoogleCalendarFailure,
  updateCalendarIntegrationFailure,
} = googleCalendarSlice.actions;
export default googleCalendarSlice.reducer;
