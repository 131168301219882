import { catchError, filter, map, of, switchMap } from "rxjs";
import { ajax } from "rxjs/ajax";
import { 
  loadPerformanceAction, 
  loadPerformanceFailure, 
  loadPerformanceSuccess, 
  loadRecentActivitiesAction, 
  loadRecentActivitiesFailure, 
  loadRecentActivitiesSuccess,
  loadUpcomingAppointments,
  loadUpcomingAppointmentsSuccess,
  loadUpcomingAppointmentsFailure,
} from "./dashboard-slice";

const {
  REACT_APP_GATEWAY_EXT_ROOT,
  REACT_APP_GATEWAY_CALENDAR_ROOT,
} = process.env;

const loadPerformanceEpic = (action$) => action$.pipe(
  filter(loadPerformanceAction.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}settings/dashboard/performance?days=${action.payload.days}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => loadPerformanceSuccess((response.response))),
    catchError((error) => of(loadPerformanceFailure(error))),
  )),
);

const loadRecentActivitiesEpic = (action$, state$) => action$.pipe(
  filter(loadRecentActivitiesAction.match),
  switchMap(() => ajax(
    {
      url: `${REACT_APP_GATEWAY_EXT_ROOT}integrations/lips/contact_interactions?page=1&size=5`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map((response) => loadRecentActivitiesSuccess({ ...response.response, currentUserTimezone: state$.value.appSettings.currentUserTimezone })),
    catchError((error) => of(loadRecentActivitiesFailure(error))),
  )),
);

const loadUpcomingAppointmentsEpic = (action$, state$) => action$.pipe(
  filter(loadUpcomingAppointments.match),
  switchMap((action) => ajax(
    {
      url: `${REACT_APP_GATEWAY_CALENDAR_ROOT}calendar/appointments?onlyActive=true&`
       + `page=0&size=3&gmaid=${action.payload.gmaid}&sort=appointmentStartDate%2Casc&`
       + `filter=UPCOMING&returnUrls=true`,
      method: 'GET',
      crossDomain: true,
      withCredentials: true,
    },
  ).pipe(
    map(response => loadUpcomingAppointmentsSuccess({...response.response, currentUserTimezone: state$.value.appSettings.currentUserTimezone})),
    catchError((error) => (
      of(loadUpcomingAppointmentsFailure(error))
    )),
  )),
);

export default [
  loadRecentActivitiesEpic,
  loadPerformanceEpic,
  loadUpcomingAppointmentsEpic,
];
