import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Grid, useTheme } from '@mui/material';
import { SubHeader } from '../settings-shared-components';
import {
  MONDAY_KEY,
  TUESDAY_KEY,
  WEDNESDAY_KEY,
  THURSDAY_KEY,
  FRIDAY_KEY,
  SATURDAY_KEY,
  SUNDAY_KEY,
  END_BEFORE_START,
  START_END_ARE_EQUAL,
  TIME_SLOT_OVERLAP,
  TIME_SLOTS_KEY,
} from './availability-constants';
import SameTimeCheckbox from './SameTimeCheckbox';
import StartEndTimeSelector from './StartEndTimeSelector';

export default function MatchingHours({
  updateDayOnChange,
  removeTimeSlot,
  sameTimeOnChange,
  timeSlots,
  addTimeSlot,
  sectionTitle,
}) {
  const theme = useTheme();

  const { formState: { errors } } = useFormContext();
  const timeSlotErrors = errors && errors[TIME_SLOTS_KEY];

  return (
    <div>
      <Grid container direction="column" gap={3} marginTop="-4px">
        <SubHeader>{ sectionTitle || 'Weekly Schedule' }</SubHeader>
        <Grid container>
          <Grid container direction="column" width="90%">
            <Grid container item direction="row">
              <SameTimeCheckbox dayLabel="Monday" dayKey={MONDAY_KEY} onChange={updateDayOnChange} />
              <SameTimeCheckbox dayLabel="Tuesday" dayKey={TUESDAY_KEY} onChange={updateDayOnChange} />
              <SameTimeCheckbox dayLabel="Wednesday" dayKey={WEDNESDAY_KEY} onChange={updateDayOnChange} />
              <SameTimeCheckbox dayLabel="Thursday" dayKey={THURSDAY_KEY} onChange={updateDayOnChange} />
              <SameTimeCheckbox dayLabel="Friday" dayKey={FRIDAY_KEY} onChange={updateDayOnChange} />
              <SameTimeCheckbox dayLabel="Saturday" dayKey={SATURDAY_KEY} onChange={updateDayOnChange} />
              <SameTimeCheckbox dayLabel="Sunday" dayKey={SUNDAY_KEY} onChange={updateDayOnChange} />
            </Grid>
          </Grid>
        </Grid>
        <div>
          <SubHeader>Hours</SubHeader>
          {timeSlots.map((timeSlot, index) => {
            const fieldName = `timeSlots[${index}]`;
            const showAddBtn = index === timeSlots.length - 1 && timeSlots.length < 2;
            const { id, startTime, endTime } = timeSlot;
            const showDelete = index <= 2 && !showAddBtn;
            const hasEndError = timeSlotErrors && errors.timeSlots[index]
              && errors.timeSlots[index].message === END_BEFORE_START;
            const hasEndAndStartSameError = timeSlotErrors && errors.timeSlots[index]
              && errors.timeSlots[index].message === START_END_ARE_EQUAL;
            const hasOverlapError = timeSlotErrors
              && timeSlotErrors.message === TIME_SLOT_OVERLAP;
            let errorCode = null;
            if (hasOverlapError && index === 1) {
              errorCode = TIME_SLOT_OVERLAP;
            } else if (hasEndError) {
              errorCode = END_BEFORE_START;
            } else if (hasEndAndStartSameError) {
              errorCode = START_END_ARE_EQUAL;
            }

            return (
              <div className="timeWrapper" key={id} style={{ paddingTop: theme.spacing(2) }}>
                <StartEndTimeSelector
                  id={id}
                  startName={`${fieldName}.startTime`}
                  endName={`${fieldName}.endTime`}
                  startTime={startTime}
                  endTime={endTime}
                  showAddBtn={showAddBtn}
                  showDelete={showDelete}
                  onAddClick={addTimeSlot}
                  errorCode={errorCode}
                  onDeleteClick={() => removeTimeSlot(index)}
                  onChange={(key, value) => sameTimeOnChange(index, key, value)}
                />
              </div>
            );
          })}
        </div>
      </Grid>
    </div>
  );
}

MatchingHours.propTypes = {
  updateDayOnChange: PropTypes.func.isRequired,
  addTimeSlot: PropTypes.func.isRequired,
  removeTimeSlot: PropTypes.func.isRequired,
  sameTimeOnChange: PropTypes.func.isRequired,
  timeSlots: PropTypes.array.isRequired,
  sectionTitle: PropTypes.string,
};
